import { useEffect } from "react";
import { connect } from "react-redux";
import { generateEmptyJawabans } from "../../../Utils";
import {
  mapDispatchToPropsTes,
  mapStateToPropsTes,
} from "../../../Utils/reduxMap";
import Petunjuk from "../../Components/Petunjuk";
import Soal from "./Soal";

const TpaOneHundredFour = ({
  currentPetunjuk,
  currentTes,
  replaceJawabans,
}) => {
  useEffect(() => generateEmptyJawabans(replaceJawabans), [replaceJawabans]);

  return (
    <>
      {currentPetunjuk === 116 && <Petunjuk jumlahSoal={20} jumlahMenit={20} />}
      {currentPetunjuk !== 116 && currentTes.tesId === 116 && <Soal />}
    </>
  );
};

export default connect(
  mapStateToPropsTes,
  mapDispatchToPropsTes
)(TpaOneHundredFour);
