import React from 'react';

const HeaderFirst = () => {
  return (
    <React.Fragment>
      <th colSpan="4" style={{ verticalAlign: 'middle' }}>
        CFIT3B(RS)
      </th>
      <th rowSpan="3" style={{ verticalAlign: 'middle' }}>
        IQ CFIT
      </th>
      <th rowSpan="3" style={{ verticalAlign: 'middle' }}>
        KLASIFIKASI
      </th>
    </React.Fragment>
  );
};

export default HeaderFirst;
