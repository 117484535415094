import React from 'react';

const HeaderSecond = () => {
  return (
    <React.Fragment>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        PRESTASI
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        KATEGORI PRESTASI
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        KETELITIAN
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        KATEGORI KETELITIAN
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        KONSISTENSI
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        KATEGORI KONSISTENSI
      </th>
    </React.Fragment>
  );
};

export default HeaderSecond;
