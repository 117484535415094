import { useState } from "react";
import Benar from "../../../../Components/PetunjukMiddle/Benar";
import Footer from "../../../../Components/PetunjukMiddle/Footer";
import PetunjukMiddleContainer from "../../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import Salah from "../../../../Components/PetunjukMiddle/Salah";
import PilihanGambar from "../../../../Components/PilihanGambar";
import PilihanWrapper from "../../../../Components/PilihanWrapper";
import SoalImage from "../../../../Components/SoalImage";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  namaFile,
  next,
  previous,
  arrPilihan,
  kunci,
  pesanBenar,
}) => {
  const [answer, setAnswer] = useState("");

  const abjad = ["A", "B", "C", "D", "E"];

  return (
    <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
      <SoalImage src={`/images/tes/gatb/gatb3/${namaFile}`} alt="Contoh Soal" />
      <PilihanWrapper col={4}>
        {arrPilihan?.map((label, index) => (
          <PilihanGambar
            alt="label"
            path="/images/tes/gatb/gatb3"
            label={label}
            key={index}
            onClick={() => setAnswer(label)}
            answer={answer}
            abjad={abjad[index]}
          />
        ))}
      </PilihanWrapper>
      <Benar
        isShow={answer === kunci}
        htmlString={pesanBenar}
        textAlign="justify"
      />
      <Salah isShow={answer !== kunci && answer !== ""} />
      <Footer
        min={1}
        max={4}
        previous={previous}
        next={next}
        condition={answer === kunci}
      />
    </PetunjukMiddleContainer>
  );
};

export default PetunjukMiddle;
