import Left from "./Left";
import Right from "./Right";
import styles from "./SectionOne.module.css";

const SectionOne = () => {
  return (
    <section className={styles.container}>
      <Left />
      <Right />
    </section>
  );
};

export default SectionOne;
