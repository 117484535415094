import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Anda menghadapi 5 dadu; a, b, c, d, e. Pada masing-masing dadu
          terdapat 6 tanda yang berbeda. Tiga diantaranya dapat kita lihat.
        </p>
        <br />
        <p>
          Persoalan yang kelak dihadapi, masing-masing memperlihatkan dadu
          dengan posisi yang berbeda dan Anda diminta untuk menemukan, dadu mana
          yang sesuai. Dadu dapat diputar, dibalik, atau diputar dan dibalikkan,
          sehingga bisa saja sebuah tanda baru akan terlihat.
        </p>
        <br />
        <p>
          Perhatikan contoh soal untuk memahami soal yang akan Anda kerjakan.
        </p>
        <br />
        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        next={nextHandler}
        previous={previousHandler}
        namaFile="petunjuk1-istr8.png"
        soal="Persoalan ada pada kotak merah, pada contoh berikut jawaban yang benar adalah dadu A. Perhatikan langkah mendapatkannya di bagian bawah."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        next={nextHandler}
        previous={previousHandler}
        namaFile="petunjuk2-istr8.png"
        soal="Persoalan ada pada kotak merah, pada contoh berikut jawaban yang benar adalah dadu E. Perhatikan langkah mendapatkannya di bagian bawah."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        next={nextHandler}
        previous={previousHandler}
        namaFile="petunjuk3-istr8.png"
        soal="Persoalan ada pada kotak merah, pada contoh berikut jawaban yang benar adalah dadu B. Perhatikan langkah mendapatkannya di bagian bawah."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={4}
        next={nextHandler}
        previous={previousHandler}
        namaFile="petunjuk4-istr8.png"
        soal="Persoalan ada pada kotak merah, pada contoh berikut jawaban yang benar adalah dadu D. Perhatikan langkah mendapatkannya di bagian bawah."
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={5}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
