import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { API } from '../../services/API';
import { convertToWIB } from '../../utils/timeUtils';
import TopSection from '../TopSection';
import Input from './Input';
import styles from './ProjectEditContent.module.css';
import Select from './Select';

const ProjectEditContent = ({ isAdmin }) => {
  const { id } = useParams();
  const history = useHistory();

  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');

  const [moduls, setModuls] = useState([]);
  const [selectedModul, setSelectedModul] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // State
  const [nama, setNama] = useState('');
  const [deskripsi, setDeskripsi] = useState('');
  const [tanggalMulai, setTanggalMulai] = useState(
    new Date().toISOString().split('.')[0]
  );
  const [tanggalSelesai, setTanggalSelesai] = useState('');
  const [isLaporanUmum, setIsLaporanUmum] = useState('');
  const [isCuttingPoint, setIsCuttingPoint] = useState('');
  const [pengawasan, setPengawasan] = useState('');
  const [standar, setStandar] = useState('');

  const submitHandler = async (e) => {
    try {
      e.preventDefault();
      if (errorMessage) {
        setSelectedModul('');
        return;
      }
      let data;
      if (isAdmin) {
        data = {
          clientId: selectedClient,
          nama,
          deskripsi,
          cam: pengawasan === 'ya' ? true : false,
          tglMulai: tanggalMulai,
          tglSelesai: tanggalSelesai,
          modulId: selectedModul,
          isLaporanUmum,
          isCuttingPoint: isCuttingPoint === 'ya' ? true : false,
        };
      } else {
        data = {
          nama,
          deskripsi,
          cam: pengawasan === 'ya' ? true : false,
          tglMulai: tanggalMulai,
          tglSelesai: tanggalSelesai,
          modulId: selectedModul,
          isLaporanUmum,
          isCuttingPoint: isCuttingPoint === 'ya' ? true : false,
        };
      }

      await API.patch(`/project/${id}`, data);
      history.push(`/project/detail/${id}`);
    } catch (error) {
      console.log(error.response);
    }
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    history.push(`/project/detail/${id}`);
  };

  useEffect(() => {
    const getClients = async () => {
      try {
        const res = await API.get('/client');

        setClients(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getModuls = async () => {
      try {
        const res = await API.get('/moduls');

        setModuls(res.data.modulAndTes[0].reverse());
      } catch (error) {
        console.log(error);
      }
    };

    const getProjectDetail = async () => {
      try {
        const res = await API.get(`/project/${id}`);

        const data = res.data.data;

        // console.log(data);

        setNama(data.nama);
        setDeskripsi(data.deskripsi || '');
        setTanggalMulai(
          new Date(convertToWIB(data.tglMulai)).toISOString().split('.')[0]
        );
        setTanggalSelesai(
          new Date(convertToWIB(data.tglSelesai)).toISOString().split('.')[0]
        );
        setSelectedClient(data.clientId);
        setSelectedModul(data.modulId);
        setIsLaporanUmum(data.isLaporanUmum);
        setIsCuttingPoint(data.isCuttingPoint ? 'ya' : 'tidak');
        setPengawasan(data.cam ? 'ya' : 'tidak');
      } catch (error) {
        console.log(error);
        console.log("This should redirect to home '/'");
      }
    };

    if (isAdmin) {
      getClients();
    }
    getModuls();
    getProjectDetail();
  }, [id, isAdmin]);

  return (
    <div className={styles.container}>
      <TopSection />

      <div className={styles.mainSection}>
        <p className={styles.path}>
          <span className={styles.pathLink} onClick={() => history.push('/')}>
            Beranda
          </span>{' '}
          <span
            className={styles.pathLink}
            onClick={() => history.push('/project')}
          >
            / Project
          </span>{' '}
          <span className={styles.pathNow}>/ Edit Project</span>
        </p>
        <form className={styles.body} onSubmit={submitHandler}>
          {isAdmin && (
            <Select
              state={selectedClient}
              setState={setSelectedClient}
              name="selectedClient"
              label="Client"
            >
              <option value=""></option>
              {clients?.map((client, index) => (
                <option key={index} value={client.id}>
                  {client.nama}
                </option>
              ))}
            </Select>
          )}
          <Input
            state={nama}
            setState={setNama}
            label="Nama Project"
            name="nama"
            type="text"
          />
          <Input
            state={deskripsi}
            name="deskripsi"
            setState={setDeskripsi}
            label="Deskripsi"
            type="text"
            length={255}
            required={false}
          />
          <Input
            state={tanggalMulai}
            name="tanggalMulai"
            setState={setTanggalMulai}
            label="Tanggal Mulai"
            type="datetime-local"
          />
          <Input
            state={tanggalSelesai}
            name="tanggalSelesai"
            setState={setTanggalSelesai}
            label="Tanggal Selesai"
            type="datetime-local"
          />
          <Select
            state={isLaporanUmum}
            setState={setIsLaporanUmum}
            name="isLaporanUmum"
            label="Laporan Umum"
          >
            <option value=""></option>
            <option value="1">Ya</option>
            <option value="0">Tidak</option>
          </Select>
          <Select
            state={isCuttingPoint}
            setState={setIsCuttingPoint}
            name="isCuttingPoint"
            label="Cutting Point"
            required={false}
          >
            <option value=""></option>
            <option value="ya">Ya</option>
            <option value="tidak">Tidak</option>
          </Select>

          {errorMessage}
          <Select
            state={selectedModul}
            setState={setSelectedModul}
            name="modul"
            label="Modul"
            moduls={[moduls.map((modul) => modul.id), moduls]}
            setErrorModul={setErrorMessage}
            errorModul={errorMessage}
          >
            <option value=""></option>
            {moduls?.map((modul, index) => (
              <option key={index} value={modul.id}>
                {modul.nama}
              </option>
            ))}
          </Select>
          <Select
            state={pengawasan}
            setState={setPengawasan}
            name="pengawasan"
            label="Wajibkan Fitur Pengawasan"
          >
            <option value=""></option>
            <option value="ya">Ya</option>
            <option value="tidak">Tidak</option>
          </Select>
          <Select
            state={standar}
            setState={setStandar}
            name="standar"
            label="Standar Nilai Aspek yang Digunakan"
            required={false}
          >
            <option value=""></option>
            <option value="tidakAda">Tidak Ada</option>
          </Select>
          <div>
            (<span className="text-danger">*</span>){' '}
            <span className="fs-6 text-muted">required field.</span>
          </div>
          <div className={styles.actionWrapper}>
            <button className={styles.cancel} onClick={cancelHandler}>
              Batalkan
            </button>
            <button className={styles.simpan} type="submit">
              Simpan
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: state?.user?.isAdmin,
    clientId: state?.user?.user?.client?.id,
  };
};

export default connect(mapStateToProps, null)(ProjectEditContent);
