import { useState } from "react";
import PetunjukLast from "../../../../Components/PetunjukLast";
import PetunjukContainer from "../../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>Perhatikan contoh untuk memahami soal yang akan Anda kerjakan.</p>
        <br />
        <p>
          Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        namaFile="contoh-cfit3a6.png"
        kunci="b"
        pesanBenar='Contoh diatas, pola yang TEPAT yang harus diisi dalam kotak tersebut adalah pola pada kotak "b", mengapa? Karena pada kotak b apabila disusun akan sama persis dengan kotak-kotak yang ada diatasnya. Jadi jawaban untuk contoh di atas adalah "b".'
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        namaFile="contoh-cfit3a7.png"
        kunci="c"
        pesanBenar='Jawaban yang tepat untuk contoh di atas adalah "c", dimana jika gambar kotak c ditempatkan pada kotak kosong tersebut, maka gambar tersebut serupa dengan kotak diatasnya yaitu menghadap ke kanan tetapi berwarna abu-abu seperti halnya kotak yang berada di sebelah kirinya.'
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        namaFile="contoh-cfit3a8.png"
        kunci="f"
        pesanBenar="Pada contoh ini jawaban yang tepat adalah <strong>f</strong>."
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={4}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
