import { useState } from "react";
import PetunjukLast from "../../Components/PetunjukLast";
import PetunjukContainer from "../../Containers/PetunjukContainer";
import PetunjukMiddle from "./PetunjukMiddle";
import PetunjukZero from "../../Containers/PetunjukZero";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihan = [
    ["6,5", "7,7", "7,6", "8", "7"],
    ["15%", "20%", "25%", "50%", "75%"],
  ];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Perhatikan contoh soal untuk memahami soal yang akan Anda kerjakan.
        </p>
        <br />
        <p>
          Tes terdiri dari persoalan-persoalan terdiri dari kalimat dan pilihlah
          satu jawaban dari lima alternatif jawaban yang tersedia.
        </p>
        <br />
        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="Pada tahun 1961 berapa orang yang berusia antara 10-19 tahun?"
        kunci="7,7"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="<b>Jawaban Anda Benar!</b><br/>Pada tahun 1961 usia antara 10-19 tahun adalah : <br/> 3,7 untuk kategori 10-14 tahun<br/>4 untuk kategori 15-19 tahun<br/>= 3,7 + 4<br/>= <b>7,7</b>"
        namaFile="petunjuk_numerik40_1.png"
        arrPilihan={arrPilihan[0]}
      />

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="Berapa Prosentase dari calon perwira kategori baik sekali yang tidak lulus ujian akhir?"
        kunci="25%"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="<b>Jawaban Anda Benar!</b><br/>Batas lulus = 40.<br/>Kategori Baik sekali dengan nilai < 40 adalah 4 (dengan nilai 0-19) ditambah 21 (dengan nilai 20-39) 4+21 = 25.<br/>Berarti yang tidak lulus dari 100 calon perwira dengan kategori baik sekali.<br/>= 25/100 &times; 100%<br/>= <b>25%</b>"
        namaFile="petunjuk_numerik40_2.png"
        arrPilihan={arrPilihan[1]}
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
