import React from 'react';

const HeaderSecond = () => {
  return (
    <React.Fragment>
      <th colSpan="3" style={{ verticalAlign: 'middle' }}>
        Tiga Kode Domain
      </th>
    </React.Fragment>
  );
};

export default HeaderSecond;
