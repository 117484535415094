import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihanOne = ["01a.jpg", "01b.jpg", "01c.jpg", "01d.jpg", "01e.jpg"];
  const arrPilihanTwo = ["02a.jpg", "02b.jpg", "02c.jpg", "02d.jpg", "02e.jpg"];
  const arrPilihanThree = [
    "03a.jpg",
    "03b.jpg",
    "03c.jpg",
    "03d.jpg",
    "03e.jpg",
  ];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Terdapat Bentuk Geometri yang terpotong menjadi beberapa bagian. Jika
          potongan-potongan tersebut disatukan, maka dapat membentuk salah satu
          dari pilihan jawaban yang ada.
        </p>
        <br />
        <p>
          Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        namaFile="01.jpg"
        arrPilihan={arrPilihanOne}
        previous={previousHandler}
        next={nextHandler}
        kunci="01a.jpg"
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        namaFile="02.jpg"
        arrPilihan={arrPilihanTwo}
        previous={previousHandler}
        next={nextHandler}
        kunci="02e.jpg"
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        namaFile="03.jpg"
        arrPilihan={arrPilihanThree}
        previous={previousHandler}
        next={nextHandler}
        kunci="03d.jpg"
      />
      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={4}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
