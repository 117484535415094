import { connect } from 'react-redux';
import styles from './Soal.module.css';
// import stopwatch from "../../../../assets/psikotes/stopwatch.png";
import Pilihan from '../Pilihan';
import { useEffect, useState, useCallback } from 'react';
import {
  changeCurrentPetunjuk,
  changeCurrentSoal,
  changeCurrentTes,
  replaceJawabans,
} from '../../../../redux/Peserta/peserta-actions';
import { kumpulkanJawabanToAPI } from '../../Utils/kumpulkanJawaban';
import SoalContainer from '../../Containers/SoalContainer';
import { nextTesHandlerUtil } from '../../Utils';
import { API } from '../../../../services/API';
import { useHistory } from 'react-router-dom';

const Soal = ({
  currentSoal,
  currentTes,
  replaceJawabans,
  changeCurrentSoal,
  changeCurrentTes,
  changeCurrentPetunjuk,
}) => {
  const [answer, setAnswer] = useState([]);
  const [soalIndex, setSoalIndex] = useState(0);
  const [soalKrae, setSoalKrae] = useState('');
  const [currentSesi, setCurrentSesi] = useState(-1);
  const [currentSesiAnswer, setCurrentSesiAnswer] = useState({});
  const [seconds, setSeconds] = useState(0);
  const [isShowSelesaiModal, setIsShowSelesaiModal] = useState(false);
  const history = useHistory();

  const nextTesHandler = useCallback(() => {
    const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
    if (currentTes?.urutan >= psikotesLS?.modul?.urutanTes?.length) {
      kumpulkanJawabanToAPI();
      //route to '/psikotes/selesai' <-- semacam page outro
      return;
    }

    psikotesLS.currentTes =
      psikotesLS.modul.urutanTes[currentTes.urutan + 1 - 1];
    psikotesLS.currentSoal = psikotesLS.currentTes.tes.soals[0];
    psikotesLS.currentPetunjuk = psikotesLS.currentTes.tes.id;

    localStorage.setItem('psikotesTalenta', JSON.stringify(psikotesLS));
    changeCurrentTes(psikotesLS.currentTes);
    changeCurrentSoal(psikotesLS.currentSoal);
    changeCurrentPetunjuk(psikotesLS.currentPetunjuk);
  }, [
    changeCurrentPetunjuk,
    changeCurrentSoal,
    changeCurrentTes,
    currentTes.urutan,
  ]);

  useEffect(() => {
    const arrayCurrentSoal = [...currentSoal.soal.split('')];
    const strSoalKrae = `${arrayCurrentSoal[soalIndex]} + ${
      arrayCurrentSoal[soalIndex + 1]
    } = ?`;
    setSoalKrae(strSoalKrae);

    // console.log('soal krae', strSoalKrae);
  }, [currentSoal, soalIndex]);

  useEffect(() => {
    const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
    const jawabanKrae = psikotesLS?.jawabans?.filter(
      (jawaban) => jawaban.soalId === currentSoal.id
    )[0];

    if (jawabanKrae?.jawaban.length > 0) {
      setAnswer(jawabanKrae?.jawaban);
      setCurrentSesi(
        jawabanKrae?.jawaban[jawabanKrae?.jawaban.length - 1]?.sesi
      );
      setCurrentSesiAnswer(
        jawabanKrae?.jawaban[jawabanKrae?.jawaban.length - 1]
      );
      return;
    }

    setCurrentSesi(1);
    setCurrentSesiAnswer({
      sesi: 1,
      count: 0,
      benar: 0,
      salah: 0,
    });
    setAnswer([
      {
        sesi: 1,
        count: 0,
        benar: 0,
        salah: 0,
      },
    ]);
  }, [currentSoal.id]);

  useEffect(() => {
    setTimeout(async () => {
      if (seconds < 15) {
        setSeconds(seconds + 1);
      } else {
        const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
        const jawabanKrae = psikotesLS?.jawabans?.filter(
          (jawaban) => jawaban.soalId === currentSoal.id
        )[0];
        if (jawabanKrae?.jawaban.length < 50) {
          // console.log('ini jalan dua kali');
          setCurrentSesiAnswer({
            sesi: jawabanKrae?.jawaban.length + 1,
            count: 1,
            benar: 0,
            salah: 0,
          });
          setAnswer((c) => [
            ...c,
            {
              sesi: jawabanKrae?.jawaban.length + 1,
              count: 1,
              benar: 0,
              salah: 0,
            },
          ]);
          setCurrentSesi((c) => c + 1);
        } else {
          const psikotesLS = JSON.parse(
            localStorage.getItem('psikotesTalenta')
          );
          const psikotesStatusSelesai = JSON.parse(
            localStorage.getItem('statusTesSelesai')
          );

          let previousTesId =
            psikotesLS.modul.urutanTes[psikotesLS?.currentTes.urutan - 1]
              ?.tesId;
          //diisi
          // console.log('next tes....');

          // 1. bagian di bawah ini di comment
          // setIsShowSelesaiModal(true);

          // 2. di ganti dengan ini
          // Kraepelin tidak boleh ada modal confirm (cancel/ok);
          /*
          Apabila ada tombol cancel maka modal akan di hide lalu tes kraepelin bisa lanjut. Karena kraepelin hanya selesai dalam 50 sesi dan tidak boleh
          ada sesi tambahan oleh karena itu langsung direct saja ke next tes atau akhiri tes.
          */
          const response = await nextTesHandlerUtil(
            changeCurrentTes,
            changeCurrentSoal,
            changeCurrentPetunjuk
          );

          if (response === false) {
            history.push('/psikotes/finish');
          }

          if (typeof psikotesStatusSelesai.statusTesSelesai === 'number') {
            psikotesStatusSelesai.statusTesSelesai = [
              psikotesStatusSelesai.statusTesSelesai,
            ];
          }
          if (
            psikotesStatusSelesai.statusTesSelesai.indexOf(previousTesId) === -1
          ) {
            if (psikotesLS.peserta.project.isCuttingPoint) {
              await API.post('/jawabanPerTes', {
                pesertaId: psikotesLS.peserta.id,
                jawabans: psikotesLS.jawabans,
                tesId: previousTesId,
              }).catch((err) => localStorage.setItem('internetProblem', true));
            }

            psikotesStatusSelesai.statusTesSelesai = [
              ...psikotesStatusSelesai.statusTesSelesai,
              previousTesId,
            ];

            localStorage.setItem(
              'statusTesSelesai',
              JSON.stringify(psikotesStatusSelesai)
            );

            if (navigator.onLine) {
              await API.patch('/edit-status-tes-selesai', {
                kodeTes: psikotesLS.aktivasi,
                statusTesSelesai: JSON.stringify(
                  psikotesStatusSelesai.statusTesSelesai
                ),
              });
            }
          }
        }

        setSeconds(0);
        clearTimeout();
      }
    }, 1000);
    return () => {
      clearTimeout();
    };
  }, [seconds, nextTesHandler, currentSoal.id]);

  useEffect(() => {
    const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
    // console.log(psikotesLS);
    psikotesLS.jawabans = [...psikotesLS.jawabans].map((jawaban) => {
      if (jawaban.soalId === psikotesLS.currentSoal.id) {
        return {
          pesertaId: psikotesLS.peserta.id,
          tesId: psikotesLS.currentTes.tesId,
          soalId: psikotesLS.currentSoal.id,
          nomor: psikotesLS.currentSoal.nomor,
          jawaban: answer,
        };
      }

      return jawaban;
    });

    localStorage.setItem('psikotesTalenta', JSON.stringify(psikotesLS));
    replaceJawabans(psikotesLS.jawabans);
  }, [answer, replaceJawabans]);

  // const nextSoalHandler = () => {
  //   const psikotesLS = JSON.parse(localStorage.getItem("psikotesTalenta"));
  //   psikotesLS.currentSoal =
  //     psikotesLS.currentTes.tes.soals[currentSoal.nomor + 1 - 1];
  //   localStorage.setItem("psikotesTalenta", JSON.stringify(psikotesLS));

  //   changeCurrentSoal(psikotesLS.currentSoal);
  // };

  const generateProgress = () => {
    const strProgress = (((currentSesi - 1) / 50) * 100).toString();
    return strProgress.substring(
      0,
      strProgress.indexOf('.') < 0
        ? strProgress.length
        : strProgress.indexOf('.')
    );
  };

  const jawabHandler = (option) => {
    // console.log(currentSesiAnswer);
    const arrayCurrentSoal = [...currentSoal.soal.split('')];
    const hasil = (
      +arrayCurrentSoal[soalIndex] + +arrayCurrentSoal[soalIndex + 1]
    ).toString();
    const kunci = hasil[hasil?.length - 1];
    let newCurrentSesiAnswer = {};
    if (+kunci === +option) {
      newCurrentSesiAnswer = {
        sesi: currentSesi,
        count: currentSesiAnswer?.count + 1,
        benar: currentSesiAnswer?.benar + 1,
        salah: currentSesiAnswer?.salah,
      };
    } else {
      newCurrentSesiAnswer = {
        sesi: currentSesi,
        count: currentSesiAnswer?.count + 1,
        benar: currentSesiAnswer?.benar,
        salah: currentSesiAnswer?.salah + 1,
      };
    }
    const newAnswer = [...answer].map((item) => {
      if (item.sesi === currentSesi) {
        return newCurrentSesiAnswer;
      }
      return item;
    });
    setAnswer(newAnswer);
    setCurrentSesiAnswer(newCurrentSesiAnswer);
    setSoalIndex(soalIndex + 1);
  };

  // Debugging
  // useEffect(() => {
  //   console.log('==============================');
  //   console.log(currentSesiAnswer, 'currentSesiAnswer');
  //   console.log(currentSesi, 'currentSesi');
  //   console.log(soalKrae, 'soalKrae');
  //   console.log(soalIndex, 'soalIndex');
  //   console.log(answer, 'answer');
  //   console.log('==============================');
  // }, [currentSesiAnswer, currentSesi, soalKrae, soalIndex, answer]);

  let displayNamaTes = 'PERFORMANCE POTENTIAL TEST';
  return (
    <div className={styles.container}>
      <SoalContainer
        isShowSelesaiModal={isShowSelesaiModal}
        setIsShowSelesaiModal={setIsShowSelesaiModal}
      >
        <header>
          <div className={styles.leftHeader}>
            <p className={styles.title}>Progress Test</p>
            <p className={styles.subtitle}>{displayNamaTes}</p>
          </div>

          <div className={styles.progressWrapper}>
            <p>{generateProgress()}%</p>
            <div className={styles.progressBackground}>
              <div
                className={styles.progressFill}
                style={{ width: `${generateProgress()}%` }}
              ></div>
            </div>
          </div>

          <div className={styles.waktu}>
            {/* <img src={stopwatch} alt="waktu" /> */}
            <p className={styles.waktuValue}>{seconds}</p>
          </div>
        </header>
        <div className={styles.content}>
          {/* <p className={styles.nomorTitle}>Soal Nomor {currentSoal?.nomor}</p> */}
          <p
            className={styles.soal}
            dangerouslySetInnerHTML={{ __html: soalKrae }}
          />
          <div className={styles.pilihanWrapper}>
            {currentSoal?.pilihan?.map((option) => (
              <Pilihan
                key={option}
                label={option}
                onClick={() => jawabHandler(option)}
                answer={answer}
              />
            ))}
          </div>
        </div>
      </SoalContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentTes: state.peserta?.currentTes,
    currentSoal: state.peserta?.currentSoal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    replaceJawabans: (jawabans) => dispatch(replaceJawabans(jawabans)),
    changeCurrentSoal: (currentSoal) =>
      dispatch(changeCurrentSoal(currentSoal)),
    changeCurrentTes: (currentTes) => dispatch(changeCurrentTes(currentTes)),
    changeCurrentPetunjuk: (currentPetunjuk) =>
      dispatch(changeCurrentPetunjuk(currentPetunjuk)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Soal);
