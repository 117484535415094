import { connect } from "react-redux";
import check from "../../../../assets/psikotes/check.png";
import checkFilled from "../../../../assets/psikotes/check_filled.png";
import { pilihanOnClickHandler } from "../../Utils";
import { mapDispatchToPropsPilihan } from "../../Utils/reduxMap";
import styles from "./PilihanGambar.module.css";

const PilihanGambar = ({
  label,
  onClick,
  answer,
  alt,
  path,
  replaceJawabans,
  changeCurrentSoal,
  setIsShowSelesaiModal,
  abjad,
}) => {
  return (
    <div
      className={styles.pilihanItem}
      style={{
        backgroundColor: answer === label ? "#3BC2AA" : "#e5e8ee",
      }}
      onClick={() => {
        onClick
          ? onClick()
          : pilihanOnClickHandler(
              label,
              replaceJawabans,
              changeCurrentSoal,
              setIsShowSelesaiModal
            );
      }}
    >
      <img
        src={answer === label ? checkFilled : check}
        alt="check"
        className={styles.check}
      />
      {abjad && <p>{abjad}</p>}
      <img
        src={`${path}/${label}`}
        alt={alt}
        className={styles.pilihanGambar}
        draggable={false}
      />
    </div>
  );
};

export default connect(null, mapDispatchToPropsPilihan)(PilihanGambar);
