import { useState } from 'react';
import { connect } from 'react-redux';
import { API } from '../../../services/API';
import TextAreaGroup from '../Components/DataDiri/TextAreaGroup';
import DataDiriContainer from '../Components/DataDiri/DataDiriContainer';
import ButtonKirim from '../Components/DataDiri/ButtonKirim';
import DataDiriForm from '../Components/DataDiri/DataDiriForm';
import SectionWrapper from '../Components/DataDiri/SectionWrapper';
import {
  mapDispatchToPropsDataDiri,
  mapStateToPropsDataDiri,
} from '../Utils/reduxMap';
import DataDiriTitle from '../Components/DataDiri/DataDiriTitle';
import SoalContainer from '../Containers/SoalContainer';

const SelfAssessmentSingkat = ({
  changeCurrentPetunjuk,
  changeCurrentSoal,
  changeCurrentTes,
}) => {
  //assessment
  const [assessmentOne, setAssessmentOne] = useState('');
  const [assessmentTwo, setAssessmentTwo] = useState('');
  const [assessmentThree, setAssessmentThree] = useState('');
  const [assessmentFour, setAssessmentFour] = useState('');
  const [assessmentFive, setAssessmentFive] = useState('');
  const [assessmentSix, setAssessmentSix] = useState('');

  const [isShowSelesaiModal, setIsShowSelesaiModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    submitToAPI();
  };

  const submitToAPI = async () => {
    const data = {
      selfAssessmentSingkat: {
        assessmentOne,
        assessmentTwo,
        assessmentThree,
        assessmentFour,
        assessmentFive,
        assessmentSix,
      },
    };

    try {
      // console.log(data);
      // Upload to API
      const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
      if (navigator.onLine) {
        await API.patch(`/peserta/${psikotesLS.peserta.id}`, data);
      }else {
        localStorage.setItem('selfAssessmentSingkat', JSON.stringify(data));
      }

      // nextTesHandlerUtil(
      //   changeCurrentTes,
      //   changeCurrentSoal,
      //   changeCurrentPetunjuk
      // );
      setIsShowSelesaiModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DataDiriContainer>
      <SoalContainer
        isShowSelesaiModal={isShowSelesaiModal}
        setIsShowSelesaiModal={setIsShowSelesaiModal}
      >
        <DataDiriTitle title="Self Assessment" />

        <DataDiriForm onSubmit={handleSubmit}>
          <SectionWrapper variant="col">
            <TextAreaGroup
              label="1. Silakan gambarkan diri Anda secara singkat"
              id="assessmentOne"
              state={assessmentOne}
              setState={setAssessmentOne}
            />
            <TextAreaGroup
              label="2. Sebutkan hal yang menjadi prestasi terbesar bagi Anda"
              id="assessmentTwo"
              state={assessmentTwo}
              setState={setAssessmentTwo}
            />
            <TextAreaGroup
              label="3. Dalam hal apa Anda sulit mengambil keputusan? Jelaskan!"
              id="assessmentThree"
              state={assessmentThree}
              setState={setAssessmentThree}
            />
            <TextAreaGroup
              label="4. Sebutkan kesulitan yang Anda alami dalam pekerjaan saat ini dan apa yang akan Anda lakukan untuk mengatasinya!"
              id="assessmentFour"
              state={assessmentFour}
              setState={setAssessmentFour}
            />
            <TextAreaGroup
              label="5. Sebutkan 3 kelebihan pada diri Anda yang menurut Anda dapat mendukung Anda dalam bekerja lebih baik!"
              id="assessmentFive"
              state={assessmentFive}
              setState={setAssessmentFive}
            />
            <TextAreaGroup
              label="6. Sebutkan hal yang masih perlu dikembangkan dari sikap kerja Anda!"
              id="assessmentSix"
              state={assessmentSix}
              setState={setAssessmentSix}
            />

            <ButtonKirim />
          </SectionWrapper>
        </DataDiriForm>
      </SoalContainer>
    </DataDiriContainer>
  );
};

export default connect(
  mapStateToPropsDataDiri,
  mapDispatchToPropsDataDiri
)(SelfAssessmentSingkat);
