import PetunjukLast from "../../Components/PetunjukLast";
import PetunjukContainer from "../../Containers/PetunjukContainer";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const index = 0;

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukLast
        isModal={isModal}
        selectedIndex={index}
        showWhenIndex={0}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
