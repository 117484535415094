import { useEffect } from "react";
import { connect } from "react-redux";
import { generateEmptyJawabans } from "../../../Utils";
import {
  mapDispatchToPropsTes,
  mapStateToPropsTes,
} from "../../../Utils/reduxMap";
import Petunjuk from "../../Components/Petunjuk";
import Soal from "./Soal";

const TpaOneHundredOne = ({ currentPetunjuk, currentTes, replaceJawabans }) => {
  useEffect(() => generateEmptyJawabans(replaceJawabans), [replaceJawabans]);
  return (
    <>
      {currentPetunjuk === 113 && <Petunjuk jumlahMenit={30} jumlahSoal={30} />}
      {currentPetunjuk !== 113 && currentTes.tesId === 113 && <Soal />}
    </>
  );
};

export default connect(
  mapStateToPropsTes,
  mapDispatchToPropsTes
)(TpaOneHundredOne);
