import { useState } from 'react';
import { connect } from 'react-redux';
import SearchBar from './SearchBar';
import styles from './TopSection.module.css';
import UserDropdown from './UserDropdown';

const TopSection = ({ isAdmin, page }) => {
  const [isShowDropdown, setIsShowDropdown] = useState(false);

  return (
    <section className={styles.topSection}>
      {page === 'project' && <SearchBar />}

      <div
        className={
          page === 'project' ? styles.roleWrapper : styles.roleWrapper2
        }
      >
        <button
          className={styles.roleSection}
          onClick={() => setIsShowDropdown(true)}
        >
          <p>{isAdmin ? 'Admin Talenta' : 'Administrator'}</p>
          <div className={styles.arrowDown}></div>
        </button>

        <UserDropdown
          onHide={() => setIsShowDropdown(false)}
          isShow={isShowDropdown}
        />
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: state?.user?.isAdmin,
  };
};

export default connect(mapStateToProps, null)(TopSection);
