import styles from './InputGroup.module.css';

const InputGroup = ({
  label,
  id,
  type,
  state,
  setState,
  required = true,
  maxLength = 50,
}) => {
  let psikotesTalenta = localStorage.getItem('psikotesTalenta');
  let dataMappingPegawai = localStorage.getItem('dataMappingPegawai');
  psikotesTalenta = psikotesTalenta && JSON.parse(psikotesTalenta);

  const dataMappingPegawaiTesId = 121;
  let saveData;

  if (dataMappingPegawai) {
    dataMappingPegawai = JSON.parse(dataMappingPegawai);
    saveData = dataMappingPegawai;
  }

  const changeHandler = (e) => {
    if (psikotesTalenta?.currentTes?.tesId === dataMappingPegawaiTesId) {
      saveData = { ...saveData, [id]: e.target.value };

      localStorage.setItem('dataMappingPegawai', JSON.stringify(saveData));
    }

    return setState(e.target.value);
  };
  return (
    <div className={styles.inputGroup}>
      <label htmlFor={id}>{label}</label>
      <br />
      <input
        type={type}
        id={id}
        required={required}
        value={state}
        onChange={changeHandler}
        maxLength={maxLength}
      />
    </div>
  );
};

export default InputGroup;
