import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { API } from '../../services/API';
import TopSection from '../TopSection';
import Input from './Input';
import styles from './ProjectTambahContent.module.css';
import Select from './Select';

const ProjectTambahContent = ({ isAdmin, clientId }) => {
  const history = useHistory();

  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');

  const [moduls, setModuls] = useState([]);
  const [selectedModul, setSelectedModul] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // State
  const [nama, setNama] = useState('');
  const [deskripsi, setDeskripsi] = useState('');
  const [tanggalMulai, setTanggalMulai] = useState(
    new Date(new Date().setHours(new Date().getHours() + 7))
      .toISOString()
      .split('.')[0]
  );
  const [tanggalSelesai, setTanggalSelesai] = useState('');
  const [pengawasan, setPengawasan] = useState('');
  const [isLaporanUmum, setIsLaporanUmum] = useState('');
  const [isCuttingPoint, setIsCuttingPoint] = useState('');
  const [standar, setStandar] = useState('');

  const submitHandler = async (e) => {
    try {
      e.preventDefault();
      if (errorMessage) {
        setSelectedModul('');
        return;
      }
      let data;
      if (isAdmin) {
        data = {
          clientId: selectedClient,
          nama,
          deskripsi,
          cam: pengawasan === 'ya' ? true : false,
          isLaporanUmum,
          isCuttingPoint: isCuttingPoint === 'ya' ? true : false,
          tglMulai: tanggalMulai,
          tglSelesai: tanggalSelesai,
          modulId: selectedModul,
          pembuat: 'Talenta',
        };

        const dataCredit = {
          clientId: selectedClient,
          modulId: selectedModul,
          tglTopUp: new Date(),
          total: 100,
          terpakai: 0,
          approve: true,
          buktiTransfer: null,
          totalHarga: 0,
        };

        await API.post('/credit', dataCredit);
      } else {
        data = {
          clientId,
          nama,
          deskripsi,
          cam: pengawasan === 'ya' ? true : false,
          isLaporanUmum,
          isCuttingPoint: isCuttingPoint === 'ya' ? true : false,
          tglMulai: tanggalMulai,
          tglSelesai: tanggalSelesai,
          modulId: selectedModul,
          pembuat: 'Perusahaan',
        };
      }

      await API.post('/project', data);
      history.push('/project');
    } catch (error) {
      console.log(error);
    }
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    history.push('/project');
  };

  useEffect(() => {
    const getModuls = async () => {
      try {
        if (isAdmin) {
          const res = await API.get('/moduls');
          setModuls(res.data.modulAndTes[0]?.reverse());
        } else {
          const res = await API.get(`/moduls-from-credit/${clientId}`);
          setModuls(res.data.data?.reverse());
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getClients = async () => {
      try {
        const res = await API.get('/client');

        setClients(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    if (isAdmin) {
      getClients();
    }
    getModuls();
    // eslint-disable-next-line
  }, [isAdmin]);

  return (
    <div className={styles.container}>
      <TopSection />

      <div className={styles.mainSection}>
        <p className={styles.path}>
          <span className={styles.pathLink} onClick={() => history.push('/')}>
            Beranda
          </span>{' '}
          <span
            className={styles.pathLink}
            onClick={() => history.push('/project')}
          >
            / Project
          </span>{' '}
          <span className={styles.pathNow}>/ Tambah Project</span>
        </p>

        <form className={styles.body} onSubmit={submitHandler}>
          {isAdmin && (
            <Select
              state={selectedClient}
              setState={setSelectedClient}
              name="selectedClient"
              label="Client"
            >
              <option value=""></option>
              {clients?.map((client, index) => (
                <option key={index} value={client.id}>
                  {client.nama}
                </option>
              ))}
            </Select>
          )}

          <Input
            state={nama}
            setState={setNama}
            label="Nama Project"
            name="nama"
            type="text"
          />

          <Input
            state={deskripsi}
            name="deskripsi"
            setState={setDeskripsi}
            label="Deskripsi"
            type="text"
            length={255}
            required={false}
          />

          <Input
            state={tanggalMulai}
            name="tanggalMulai"
            setState={setTanggalMulai}
            label="Tanggal Mulai"
            type="datetime-local"
          />

          <Input
            state={tanggalSelesai}
            name="tanggalSelesai"
            setState={setTanggalSelesai}
            label="Tanggal Selesai"
            type="datetime-local"
          />

          <Select
            state={isLaporanUmum}
            setState={setIsLaporanUmum}
            name="isLaporanUmum"
            label="Laporan Umum"
          >
            <option value=""></option>
            <option value="1">Ya</option>
            <option value="0">Tidak</option>
          </Select>

          <Select
            state={isCuttingPoint}
            setState={setIsCuttingPoint}
            name="isCuttingPoint"
            label="Cutting Point"
            required={false}
          >
            <option value=""></option>
            <option value="ya">Ya</option>
            <option value="tidak">Tidak</option>
          </Select>

          {errorMessage}
          <Select
            state={selectedModul}
            setState={setSelectedModul}
            name="modul"
            label="Modul"
            moduls={[moduls.map((modul) => modul.id), moduls]}
            setErrorModul={setErrorMessage}
            errorModul={errorMessage}
          >
            <option value=""></option>
            {moduls?.map((modul, index) => (
              <option key={index} value={isAdmin ? modul?.id : modul?.modulId}>
                {isAdmin ? modul?.nama : modul?.modul?.nama}
              </option>
            ))}
          </Select>

          <Select
            state={pengawasan}
            setState={setPengawasan}
            name="pengawasan"
            label="Wajibkan Fitur Pengawasan"
          >
            <option value=""></option>
            <option value="ya">Ya</option>
            <option value="tidak">Tidak</option>
          </Select>

          <Select
            state={standar}
            setState={setStandar}
            name="standar"
            label="Standar Nilai Aspek yang Digunakan"
            required={false}
          >
            <option value=""></option>
            <option value="tidakAda">Tidak Ada</option>
          </Select>

          <div>
            (<span className="text-danger">*</span>){' '}
            <span className="fs-6 text-muted">required field.</span>
          </div>

          <div className={styles.actionWrapper}>
            <button className={styles.cancel} onClick={cancelHandler}>
              Batalkan
            </button>
            <button className={styles.simpan} type="submit">
              Simpan
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: state?.user?.isAdmin,
    clientId: state?.user?.user?.client?.id,
  };
};

export default connect(mapStateToProps, null)(ProjectTambahContent);
