import styles from "./Pilihan.module.css";

const Pilihan = ({ label, onClick, answer }) => {
  return (
    <div
      className={styles.pilihanItem}
      style={{
        backgroundColor: answer === label ? "#3BC2AA" : "#e5e8ee",
      }}
      onClick={onClick}
    >
      <p dangerouslySetInnerHTML={{ __html: label }} />
    </div>
  );
};

export default Pilihan;
