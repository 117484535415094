import { useState } from "react";
import PetunjukLast from "../../Components/PetunjukLast";
import PetunjukContainer from "../../Containers/PetunjukContainer";
import PetunjukZero from "../../Containers/PetunjukZero";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Pada tes kali ini pada tiap persoalan diawali dengan suatu
          naskah/bacaan singkat. Baca dengan baik dan pahami naskah tersebut
          kemudian pilihlah salah satu jawaban yang dianggap sesuai dengan isi
          naskah yang ada di atasnya. Tugas Anda hanya memilih satu jawaban yang
          dianggap benar.
        </p>
        <br />
        <p>
          Tiap persoalan hanya terdapat satu jawaban yang dianggap benar. Waktu
          yang tersedia untuk mengerjakan tes ini 30 menit. Tes ini terdiri dari
          7 (tujuh) naskah yang terdiri dari 26 soal.
        </p>
        <br />
        <p>Tes memiliki batas waktu bekerja dengan cepat dan teliti.</p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={1}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
