import { connect } from 'react-redux';
import UbahUrutanTes from '../../components/UbahUrutanTes';
import SideBar from '../../components/SideBar';
import styles from '../Dashboard/Dashboard.module.css';

const UbahUrutanTesPage = ({ isAdmin, clientId }) => {
  return (
    <div className={styles.container}>
      <SideBar />

      <UbahUrutanTes />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: state.user.isAdmin,
    clientId: state?.user?.user?.client?.id,
  };
};

export default connect(mapStateToProps, null)(UbahUrutanTesPage);
