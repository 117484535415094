import styles from "./Table.module.css";
import { matchSorter } from "match-sorter";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import TableHeader from "../../TableHeader";
import Pagination from "../../Pagination";
import { useHistory } from "react-router";


function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const history = useHistory();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 4 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination,
    useRowSelect
  );

  // Render the UI for your table
  return (
    <>
      <TableHeader
        pageSize={pageSize}
        setPageSize={setPageSize}
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilterState={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
        title="Laporan Status Penyelesaian"
      />
      
      <div className={styles.overflowX}>
        <table {...getTableProps()} className={styles.table}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className={styles.tableHeader}
              >
                {headerGroup.headers.map((column, index) => {
                  return (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className={styles.rowItem}>
                  {row.cells.map((cell, index) => {
                    if (index === 3) {
                      return (
                        <td {...cell.getCellProps()} className={styles.tdStatus}>
                          {row.original.statusPenyelesaian === null && (
                            <p className={styles.belum}>Belum</p>
                          )}
                          {row.original.statusPenyelesaian === 1 && (
                            <p className={styles.proses}>Proses</p>
                          )}
                          {row.original.statusPenyelesaian === 2 && (
                            <p className={styles.sudah}>Sudah</p>
                          )}
                        </td>

                      );
                    }
                    if (index === 4) {
                      return (
                        <td {...cell.getCellProps()} className={styles.cellItem}>
                          <button className={styles.detailButton}
                          onClick={() =>
                            history.push(
                              `/project/detail/${row.original.projectId}`
                            )}
                            >
                            {cell.value}
                            <span>
                              <FontAwesomeIcon icon={faArrowRight} />
                            </span>
                          </button>
                        </td>
                      );
                    }
                    return (
                      <td {...cell.getCellProps()} className={styles.cellItem}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

      <Pagination
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
      />
      </div>
    </>
  );
}

export default Table;
