import { useEffect } from 'react';
import { connect } from 'react-redux';
import { generateEmptyJawabans } from '../../Utils';
import {
  mapDispatchToPropsTes,
  mapStateToPropsTes,
} from '../../Utils/reduxMap';
import Petunjuk from '../Components/Petunjuk';
import Soal from './Soal';

const AKBranchManagerUsedCarMultifinance = ({
  currentPetunjuk,
  currentTes,
  replaceJawabans,
}) => {
  useEffect(() => {
    generateEmptyJawabans(replaceJawabans, 'Tuliskan analisa Anda di sini.');
  }, [replaceJawabans]);

  const menit = 50;
  return (
    <>
      {currentPetunjuk === 185 && <Petunjuk menit={menit} />}
      {currentPetunjuk !== 185 && currentTes.tesId === 185 && (
        <Soal menit={menit} />
      )}
    </>
  );
};

export default connect(
  mapStateToPropsTes,
  mapDispatchToPropsTes
)(AKBranchManagerUsedCarMultifinance);
