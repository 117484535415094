import { useEffect, useState } from 'react';
import { API } from '../../../../services/API';

let timer;

const Waktu = ({ duration, onTimesup }) => {
  const [count, setCount] = useState(duration);
  const [renderTimes, setRenderTimes] = useState(0);

  const fmtMSS = (s) => {
    // const ms = s * 1000;
    // const days = Math.floor(s / (24 * 60 * 60));
    let hours = Math.floor((s % (24 * 60 * 60)) / (60 * 60));
    let minutes = Math.floor((s % (60 * 60)) / 60);
    let seconds = Math.floor(s % 60);

    if (minutes.toString().length < 2) {
      minutes = '0' + minutes.toString();
    }
    if (seconds.toString().length < 2) {
      seconds = '0' + seconds.toString();
    }
    if (hours.toString().length < 2) {
      hours = '0' + hours.toString();
    }

    return `${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    async function run() {
      if (renderTimes === 1 && count > 0) {
        const psikotesWaktu = JSON.parse(localStorage.getItem('psikotesWaktu'));

        timer = setInterval(() => {
          setCount((c) => {
            if (c === 0) {
              psikotesWaktu.sisaWaktu = 0;
              clearInterval(timer);

              return 0;
            }

            psikotesWaktu.sisaWaktu = c - 1;
            return c - 1;
          });
          if (psikotesWaktu.sisaWaktu >= 0) {
            localStorage.setItem(
              'psikotesWaktu',
              JSON.stringify(psikotesWaktu)
            );
          }
        }, 1000);
        setRenderTimes(2);
      }

      if (renderTimes === 0) {
        setRenderTimes(1);
      }

      if (count === 0) {
        const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
        const psikotesStatusSelesai = JSON.parse(
          localStorage.getItem('statusTesSelesai')
        );

        let previousTesId =
          psikotesLS.modul.urutanTes[psikotesLS?.currentTes.urutan - 1]?.tesId;

        const psikotesWaktu = JSON.parse(localStorage.getItem('psikotesWaktu'));
        psikotesWaktu.sisaWaktu = 0;
        localStorage.setItem('psikotesWaktu', JSON.stringify(psikotesWaktu));
        // console.log(onTimesup);
        onTimesup();
        clearInterval(timer);

        if (typeof psikotesStatusSelesai.statusTesSelesai === 'number') {
          psikotesStatusSelesai.statusTesSelesai = [
            psikotesStatusSelesai.statusTesSelesai,
          ];
        }
        if (
          psikotesStatusSelesai.statusTesSelesai.indexOf(previousTesId) === -1
        ) {
          if (psikotesLS.peserta.project.isCuttingPoint) {
            await API.post('/jawabanPerTes', {
              pesertaId: psikotesLS.peserta.id,
              jawabans: psikotesLS.jawabans,
              tesId: previousTesId,
            }).catch((err) => localStorage.setItem('internetProblem', true));
          }

          psikotesStatusSelesai.statusTesSelesai = [
            ...psikotesStatusSelesai.statusTesSelesai,
            previousTesId,
          ];

          localStorage.setItem(
            'statusTesSelesai',
            JSON.stringify(psikotesStatusSelesai)
          );

          if (navigator.onLine) {
            await API.patch('/edit-status-tes-selesai', {
              kodeTes: psikotesLS.aktivasi,
              statusTesSelesai: JSON.stringify(
                psikotesStatusSelesai.statusTesSelesai
              ),
            });
          }
        }
      }
    }
    run();
  }, [count, renderTimes, onTimesup]);

  useEffect(() => {
    return () => {
      clearInterval(timer);
      const psikotesWaktu = JSON.parse(localStorage.getItem('psikotesWaktu'));
      if (psikotesWaktu) {
        psikotesWaktu.sisaWaktu = 0;
        localStorage.setItem('psikotesWaktu', JSON.stringify(psikotesWaktu));
      }
    };
  }, []);

  return <>{fmtMSS(count)}</>;
};

export default Waktu;
