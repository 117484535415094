import { connect } from "react-redux";
import { changeCurrentSoal } from "../../../../redux/Peserta/peserta-actions";
import styles from "./ButtonSoal.module.css";

const ButtonSoal = ({
  currentTes,
  currentSoal,
  setIsShowSelesaiModal,
  changeCurrentSoal,
  importFunc,
}) => {
  const nextSoalHandler = () => {
    const psikotesLS = JSON.parse(localStorage.getItem("psikotesTalenta"));
    psikotesLS.currentSoal =
      psikotesLS.currentTes.tes.soals[currentSoal?.nomor + 1 - 1];
    localStorage.setItem("psikotesTalenta", JSON.stringify(psikotesLS));

    changeCurrentSoal(psikotesLS.currentSoal);
  };

  return (
    <div className={styles.buttonSoal}>
      {currentTes.tes.soals.length === currentSoal?.nomor ? (
        <button
          onClick={() => setIsShowSelesaiModal(true)}
          style={{ backgroundColor: "#3bc2aa" }}
        >
          Selesai
        </button>
      ) : (
        <button onClick={importFunc ? importFunc : nextSoalHandler}>
          Jawab
        </button>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentTes: state.peserta?.currentTes,
  currentSoal: state.peserta?.currentSoal,
});

const mapDispatchToProps = (dispatch) => ({
  changeCurrentSoal: (currentSoal) => dispatch(changeCurrentSoal(currentSoal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonSoal);
