import React from 'react';

export default function TBodyRows({ datas }) {
  // console.log({ datas });
  datas.cfit3b = datas.cfit3b.reverse();
  return (
    <>
      {/* DATA DIRI PESERTA */}
      {datas.pesertas.length &&
        datas.pesertas?.map((peserta, indexPeserta) => {
          let selfAssesment = JSON.parse(peserta.selfAssessmentSingkat);
          // console.log('TIKI', datas.tiki[indexPeserta]?.[0]?.allTiki);
          let tglMulai = peserta?.project?.tglMulai || '-';
          let tglLahir = peserta?.tglLahir || '-';

          return (
            <tr>
              <React.Fragment key={indexPeserta}>
                <td>{indexPeserta + 1}</td>
                <td>{tglMulai}</td>
                <td>{peserta?.kode ? peserta.kode : '-'}</td>
                <td>{peserta?.nama ? peserta.nama : '-'}</td>
                <td>{peserta?.namaPanggilan ? peserta.namaPanggilan : '-'}</td>
                <td>{peserta?.nik ? peserta.nik : '-'}</td>
                <td>{peserta?.telp ? peserta.telp : '-'}</td>
                <td>{peserta?.email ? peserta.email : '-'}</td>
                <td>{peserta?.pendidikan ? peserta.pendidikan : '-'}</td>
                <td>{peserta?.jurusan ? peserta.jurusan : '-'}</td>
                <td>{tglLahir}</td>
                <td>{peserta.perusahaan}</td>
                <td>{peserta?.posisi ? peserta.posisi : '-'}</td>
              </React.Fragment>

              {/* TIKI */}
              {datas.tiki?.length ? (
                datas.tiki[indexPeserta]?.[0]?.allTiki?.map((data, index) => {
                  if (index <= 3)
                    return (
                      <React.Fragment key={index}>
                        <td>{data?.ws ? data.ws : '-'}</td>
                        <td>{data?.rs ? data.rs : '-'}</td>
                      </React.Fragment>
                    );
                })
              ) : (
                <>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </>
              )}

              {datas.tiki?.length ? (
                <>
                  <td>
                    {datas.tiki[indexPeserta]?.[0]?.jumlah
                      ? datas.tiki[indexPeserta]?.[0]?.jumlah
                      : '-'}
                  </td>
                  <td>
                    {datas.tiki[indexPeserta]?.[0]?.iq_kelompok_total
                      ? datas.tiki[indexPeserta]?.[0].iq_kelompok_total
                      : '-'}
                  </td>
                  <td>
                    {datas.tiki[indexPeserta]?.[0]?.klasifikasi_iq_total
                      ? datas.tiki[indexPeserta]?.[0].klasifikasi_iq_total
                      : '-'}
                  </td>
                </>
              ) : (
                <>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </>
              )}

              {datas.tiki?.length ? (
                datas.tiki[indexPeserta]?.[0]?.allF?.map((f, index) => (
                  <React.Fragment key={index}>
                    <td>{f ? f : '-'}</td>
                  </React.Fragment>
                ))
              ) : (
                <>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </>
              )}

              {/* KRAEPELIN */}
              {datas.kraepelin?.length ? (
                <>
                  <td>
                    {datas.kraepelin[indexPeserta]?.[0]?.benar
                      ? datas.kraepelin[indexPeserta]?.[0].benar
                      : '-'}
                  </td>
                  <td>
                    {datas.kraepelin[indexPeserta]?.[0]?.kategoriBenar
                      ? datas.kraepelin[indexPeserta]?.[0].kategoriBenar
                      : '-'}
                  </td>
                  <td>
                    {datas.kraepelin[indexPeserta]?.[0]?.rerata
                      ? datas.kraepelin[indexPeserta]?.[0].rerata
                      : '-'}
                  </td>
                  <td>
                    {datas.kraepelin[indexPeserta]?.[0]?.kategoriRerata
                      ? datas.kraepelin[indexPeserta]?.[0].kategoriRerata
                      : '-'}
                  </td>
                  <td>
                    {datas.kraepelin[indexPeserta]?.[0]?.salah
                      ? datas.kraepelin[indexPeserta]?.[0].salah
                      : '-'}
                  </td>
                  <td>
                    {datas.kraepelin[indexPeserta]?.[0]?.kategoriTeliti
                      ? datas.kraepelin[indexPeserta]?.[0].kategoriTeliti
                      : '-'}
                  </td>
                  <td>
                    {datas.kraepelin[indexPeserta]?.[0]?.averageD
                      ? datas.kraepelin[indexPeserta]?.[0].averageD
                      : '-'}
                  </td>
                  <td>
                    {datas.kraepelin[indexPeserta]?.[0]?.kategoriKonsistensi
                      ? datas.kraepelin[indexPeserta]?.[0].kategoriKonsistensi
                      : '-'}
                  </td>
                  <td>
                    {datas.kraepelin[indexPeserta]?.[0]?.ketahanan
                      ? datas.kraepelin[indexPeserta]?.[0].ketahanan
                      : '-'}
                  </td>
                  <td>
                    {datas.kraepelin[indexPeserta]?.[0]?.kategoriKetahanan
                      ? datas.kraepelin[indexPeserta]?.[0].kategoriKetahanan
                      : '-'}
                  </td>
                </>
              ) : (
                <>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </>
              )}

              {/* CFIT3B */}
              {datas.cfit3b?.length ? (
                <>
                  {/* TOTAL RSNYA */}
                  <td>
                    {datas.cfit3b[indexPeserta]?.[0]?.[0]?.rs
                      ? datas.cfit3b[indexPeserta]?.[0]?.[0].rs
                      : '-'}
                  </td>
                  {/* TOTAL RSNYA */}

                  <td>
                    {datas.cfit3b[indexPeserta]?.[0]?.[indexPeserta]?.iq
                      ? datas.cfit3b[indexPeserta]?.[0]?.[indexPeserta].iq
                      : '-'}
                  </td>

                  {datas.cfit3b[indexPeserta]?.[0]?.[indexPeserta]?.iq < 70 ? (
                    <td>Mentally Defective</td>
                  ) : datas.cfit3b[indexPeserta]?.[0]?.[indexPeserta]?.iq <
                    81 ? (
                    <td>Borderline</td>
                  ) : datas.cfit3b[indexPeserta]?.[0]?.[indexPeserta]?.iq <
                    113 ? (
                    <td>Average</td>
                  ) : datas.cfit3b[indexPeserta]?.[0]?.[indexPeserta]?.iq <
                    121 ? (
                    <td>High Average</td>
                  ) : datas.cfit3b[indexPeserta]?.[0]?.[indexPeserta]?.iq >=
                    121 ? (
                    <td>Superior</td>
                  ) : (
                    <td>-</td>
                  )}
                </>
              ) : (
                <>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </>
              )}
            </tr>
          );
        })}
    </>
  );
}
