import { useState } from 'react';
import PetunjukLast from '../../../Components/PetunjukLast';
import PetunjukContainer from '../../../Containers/PetunjukContainer';
import PetunjukZero from '../../../Containers/PetunjukZero';
import PetunjukMiddle from './PetunjukMiddle';

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihanOne = ['2', '3', '5', '9', 't.a'];
  const arrPilihanTwo = ['6', '7', '9', '8', 't.a'];
  const arrPilihanThree = ['2', '3', '6', '5', 't.a'];
  const arrPilihanFour = ['2', '4', '9', '12', 't.a'];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>Tes berikut ini terdiri atas soal-soal berhitung.</p>
        <br />
        <p>
          Setiap soal disertai dengan lima kemungkinan jawaban a, b, c, d, dan
          e. Salah satu diantaranya merupakan jawaban dari soal tersebut.
        </p>
        <p>
          Maksud dari kemungkinan kelima atau e yaitu t.a., adalah bahwa jawaban
          dari soal yang bersangkutan tidak terdapat diantara angka-angka
          jawaban yang tersedia.
        </p>
        <br />

        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="9 - 4 = ..."
        arrPilihan={arrPilihanOne}
        kunci="5"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban anda benar, dimana <strong>9 di kurang 4</strong> adalah <strong> 5</strong>. silahkan klik tombol lanjutkan"
      />

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="4 x 2 = ..."
        arrPilihan={arrPilihanTwo}
        kunci="8"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban anda benar, dimana <strong>4 di kali 2</strong> adalah <strong> 8</strong>. silahkan klik tombol lanjutkan"
      />

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        soal="2 + 4 = ..."
        arrPilihan={arrPilihanThree}
        kunci="6"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban anda benar, dimana <strong>2 di tambah 4</strong> adalah <strong> 6</strong>. silahkan klik tombol lanjutkan"
      />

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={4}
        soal="6 : 3 = ..."
        arrPilihan={arrPilihanFour}
        kunci="2"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban anda benar, dimana <strong>6 di bagi 3</strong> adalah <strong> 2</strong>. silahkan klik tombol lanjutkan"
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={5}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
