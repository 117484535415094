import styles from "./SectionEight.module.css";
import Left from "./Left";
import Right from "./Right";

const SectionEight = () => {
  return (
    <div className={styles.container}>
      <Left />
      <Right />
    </div>
  );
};

export default SectionEight;
