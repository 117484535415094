import React from 'react';

import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const BreadcrumbSection = () => {
  return (
    <Breadcrumb className="mt-3">
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/', className: 'text-decoration-none' }}>
        Beranda
      </Breadcrumb.Item>
      <Breadcrumb.Item
        linkAs={Link}
        linkProps={{ to: '/analisa-kasus', className: 'text-decoration-none' }}
      >
        Analisa Kasus
      </Breadcrumb.Item>
      <Breadcrumb.Item active>Buat Baru</Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default BreadcrumbSection;
