import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import DaftarKredensialContent from "../../components/DaftarKredensialContent";
import SideBar from "../../components/SideBar";
import { API } from "../../services/API";
import { tanggalLengkap } from "../../utils/tanggal";
import styles from "./DaftarKredensial.module.css";

const DaftarKredensial = ({ isAdmin, clientId }) => {
  const { projectId } = useParams() || false;

  const [credentials, setCredentials] = useState([]);

  useEffect(() => {
    const getCredentialsForAdmin = async () => {
      try {
        const uri = `/credentials/?clientId=${
          clientId ? clientId : ""
        }&projectId=${projectId ? projectId : ""}`;

        const res = await API.get(uri);
        const credentialsMapping = [...res.data.credentials].map(
          (peserta, index) => ({
            ...peserta,
            nomor: index + 1,
            entryDate: tanggalLengkap(peserta.entryDate),
          })
        );
        setCredentials(credentialsMapping);
      } catch (error) {
        console.log(error);
      }
    };

    getCredentialsForAdmin();
  }, [isAdmin, clientId, projectId]);

  return (
    <div className={styles.container}>
      <SideBar />
      <DaftarKredensialContent credentials={credentials} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: state.user.isAdmin,
    clientId: state?.user?.user?.client?.id,
  };
};

export default connect(mapStateToProps, null)(DaftarKredensial);
