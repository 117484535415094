import styles from './ButtonSoal.module.css';

const ButtonSoal = () => {
  return (
    <div className={styles.buttonSoal}>
      <button style={{ backgroundColor: '#3bc2aa' }}>Selesai</button>
    </div>
  );
};

export default ButtonSoal;
