import React from 'react';

const HeaderSecond = () => {
  return (
    <React.Fragment>
      <th colSpan="4" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        RS
      </th>
      <th colSpan="4" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        WS
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        JUMLAH
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        IQ KELOMPOK TOTAL
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        KLASIFIKASI IQ TOTAL
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        IQ BETA
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        KLASIFIKASI IQ BETA
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        F1
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        F2
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        F3
      </th>
    </React.Fragment>
  );
};

export default HeaderSecond;
