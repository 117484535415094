import React from 'react';

import TheadColumns from '../TheadColumns';
import TBodyRows from './TBodyRows';
import styles from './Table.module.css';

const TableTAF = ({ projectId, tableRef, formatLaporanExcel, datas }) => {
  return (
    <div>
      <table ref={tableRef} className={styles.tableRekap} border="2">
        <thead>
          <tr></tr>
          <TheadColumns formatLaporanExcel={formatLaporanExcel} />
        </thead>
        <tbody>
          <TBodyRows datas={datas} />
        </tbody>
      </table>
    </div>
  );
};

export default TableTAF;
