import { connect } from "react-redux";
import { useEffect, useState } from "react";
import PetunjukModal from "../../../Components/PetunjukModal";
import Petunjuk from "../Petunjuk";
import SoalHeader from "../../../Components/SoalHeader";
import Navigation from "../../../Components/Navigation";
import { setJawabansLS, setSisaWaktuLS } from "../../../Utils";
import SoalContainer from "../../../Containers/SoalContainer";
import SoalContent from "../../../Containers/SoalContent";
import { mapStateToPropsSoal } from "../../../Utils/reduxMap";
import styles from "./Soal.module.css";
import {
  changeCurrentSoal,
  replaceJawabans,
} from "../../../../../redux/Peserta/peserta-actions";

const Soal = ({
  currentSoal,
  currentTes,
  replaceJawabans,
  changeCurrentSoal,
}) => {
  const [answer, setAnswer] = useState("00");
  const [sisaWaktu, setSisaWaktu] = useState(-1);
  const [isShowSelesaiModal, setIsShowSelesaiModal] = useState(false);
  const [isShowPetunjukModal, setIsShowPetunjukModal] = useState(false);

  const [answerOne, setAnswerOne] = useState("0");
  const [answerTwo, setAnswerTwo] = useState("0");

  useEffect(() => {
    setSisaWaktuLS(currentTes?.tes?.waktu, setSisaWaktu);
  }, [currentTes?.tes?.waktu]);

  useEffect(() => {
    setAnswer("00");
    setAnswerOne("0");
    setAnswerTwo("0");

    const psikotesLS = JSON.parse(localStorage.getItem("psikotesTalenta"));
    psikotesLS?.jawabans?.forEach((jawaban) => {
      if (jawaban?.soalId === currentSoal?.id) {
        if (jawaban?.jawaban) {
          setAnswer(jawaban.jawaban);
          setAnswerOne(jawaban.jawaban[0]);
          setAnswerTwo(jawaban.jawaban[1]);
        }
      }
    });
  }, [currentSoal]);

  useEffect(() => {
    setJawabansLS(answer, replaceJawabans);
  }, [answer, replaceJawabans]);

  const submitHandler = (e) => {
    e.preventDefault();

    const psikotesLS = JSON.parse(localStorage.getItem("psikotesTalenta"));
    psikotesLS.jawabans = [...psikotesLS.jawabans].map((jawaban) => {
      if (jawaban.soalId === psikotesLS.currentSoal.id) {
        return {
          pesertaId: psikotesLS.peserta.id,
          tesId: psikotesLS.currentTes.tesId,
          soalId: psikotesLS.currentSoal.id,
          nomor: psikotesLS.currentSoal.nomor,
          jawaban: answerOne + answerTwo,
        };
      }

      return jawaban;
    });

    replaceJawabans(psikotesLS.jawabans);
    psikotesLS.currentSoal =
      psikotesLS.currentTes.tes.soals[currentSoal.nomor + 1 - 1];
    localStorage.setItem("psikotesTalenta", JSON.stringify(psikotesLS));
    changeCurrentSoal(psikotesLS.currentSoal);
  };

  return (
    <SoalContainer
      isShowSelesaiModal={isShowSelesaiModal}
      setIsShowSelesaiModal={setIsShowSelesaiModal}
    >
      <SoalHeader
        setIsShowPetunjukModal={setIsShowPetunjukModal}
        sisaWaktu={sisaWaktu}
        type="istr6"
      />
      <SoalContent>
        <form className={styles.form} onSubmit={submitHandler}>
          <div>
            {JSON.parse(currentSoal.soal)[0]}
            <select
              value={answerOne}
              onChange={(e) => setAnswerOne(e.target.value)}
            >
              <option value="0">&nbsp;</option>
              <option value="1"> + </option>
              <option value="2"> - </option>
              <option value="3"> * </option>
              <option value="4"> / </option>
            </select>
            {JSON.parse(currentSoal.soal)[1]}
            <select
              value={answerTwo}
              onChange={(e) => setAnswerTwo(e.target.value)}
            >
              <option value="0">&nbsp;</option>
              <option value="1"> + </option>
              <option value="2"> - </option>
              <option value="3"> * </option>
              <option value="4"> / </option>
            </select>
            {JSON.parse(currentSoal.soal)[2]}
          </div>

          <div>
            <button className={styles.buttonJawab} type="submit">
              Jawab
            </button>
          </div>
        </form>

        <Navigation type="istr6" />
      </SoalContent>
      <PetunjukModal
        isShow={isShowPetunjukModal}
        onHide={() => setIsShowPetunjukModal(false)}
      >
        <Petunjuk
          isModal
          tutupModalHandler={() => setIsShowPetunjukModal(false)}
        />
      </PetunjukModal>
    </SoalContainer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  replaceJawabans: (jawabans) => dispatch(replaceJawabans(jawabans)),
  changeCurrentSoal: (currentSoal) => dispatch(changeCurrentSoal(currentSoal)),
});

export default connect(mapStateToPropsSoal, mapDispatchToProps)(Soal);
