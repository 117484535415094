import styles from "./PetunjukMiddleContainer.module.css";

const PetunjukMiddleContainer = ({ isShow, children }) => {
  return (
    <div
      className={styles.petunjukMiddle}
      style={{ display: isShow ? "" : "none" }}
    >
      {children}
    </div>
  );
};

export default PetunjukMiddleContainer;
