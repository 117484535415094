import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import styles from "./Action.module.css";
import Dropdown from "./Dropdown";

const Action = ({ cell, row, getUsers }) => {
  const [isShowModal, setIsShowModal] = useState(false);

  return (
    <span className={styles.threeDots}>
      <FontAwesomeIcon
        icon={faEllipsisH}
        size="2x"
        onClick={() => setIsShowModal(true)}
      />
      <Dropdown
        isShow={isShowModal}
        onHide={() => setIsShowModal(false)}
        userId={row.original.id}
        getUsers={getUsers}
        userStatus={row.original.status}
      />
    </span>
  );
};

export default Action;
