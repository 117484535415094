import { connect } from 'react-redux';
import PetunjukParagraph from '../../Components/PetunjukMiddle/PetunjukParagraph';
import PetunjukContainer from '../../Containers/PetunjukContainer';
import { mapDispatchToPropsPetunjuk } from '../../Utils/reduxMap';
import styles from './Petunjuk.module.css';

const Petunjuk = ({ changeCurrentPetunjuk, isModal, tutupModalHandler }) => {
  const tutupHandler = () => {
    const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
    psikotesLS.currentPetunjuk = 0;
    localStorage.setItem('psikotesTalenta', JSON.stringify(psikotesLS));

    changeCurrentPetunjuk(0);
  };

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <div className={styles.petunjukLast}>
        <PetunjukParagraph>
          Anda akan dihadapkan pada berbagai macam pernyataan. Bacalah
          pernyataan-pernyataan tersebut dengan teliti. Akan ada beberapa kolom
          yaitu SANGAT SESUAI, SESUAI, NETRAL, TIDAK SESUAI, dan SANGAT TIDAK
          SESUAI . Pilih / Klik pada kolom SANGAT SESUAI jika anda sangat sesuai
          dengan pernyataan tersebut. Pilih / Klik pada kolom SANGAT TIDAK
          SESUAI jika anda sangat tidak sesuai dengan pernyataan tersebut dan
          begitu seterusnya.
        </PetunjukParagraph>
        <PetunjukParagraph>
          Anda harus menjawab semua pernyataan tersebut. Pastikan anda tidak
          melewatkan satu nomor pun.
        </PetunjukParagraph>
        <div className={styles.footer}>
          {isModal ? (
            <button onClick={tutupModalHandler}>Tutup</button>
          ) : (
            <button onClick={tutupHandler}>Tutup</button>
          )}
        </div>
      </div>
    </PetunjukContainer>
  );
};

export default connect(null, mapDispatchToPropsPetunjuk)(Petunjuk);
