import styles from "./Table.module.css";
import { matchSorter } from "match-sorter";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import Pagination from "./Pagination";
import TableHeader from "./TableHeader";
import { API } from "../../../../services/API";
import { ubahFormat } from "../../../../utils/tanggal";
import Action from "./Action";

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function Table({ columns, data, setData }) {
  const approveHandler = async (id) => {
    try {
      const res = await API.patch(`/credit/${id}`, {
        approve: true,
      });
      setData(
        res.data.credits?.map((item, index) => ({
          id: item.id,
          tanggal: ubahFormat(item.updatedAt),
          namaPerusahaan: item?.client?.nama,
          kuota: item.total,
          status: item.approve ? "Selesai" : "Belum",
          action: item.approve ? "Selesai" : "Belum",
          nomor: index + 1,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 6 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination,
    useRowSelect
  );

  // Render the UI for your table
  return (
    <>
      <TableHeader
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilterState={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <div className={styles.overflowX}>
      <table {...getTableProps()} className={styles.table}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className={styles.tableHeader}
            >
              {headerGroup.headers.map((column, index) => {
                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={styles.rowItem}>
                {row.cells.map((cell, index) => {
                  switch (index) {
                    case 4:
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={styles.cellItem}
                        >
                          {cell.value === "Belum" && (
                            <p className={styles.belumStatus}>Belum</p>
                          )}
                          {cell.value === "Selesai" && (
                            <p className={styles.selesaiStatus}>Selesai</p>
                          )}
                        </td>
                      );
                    case 5:
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={styles.cellItem}
                        >
                          <Action
                            cell={cell}
                            row={row}
                            approveHandler={approveHandler}
                          />
                          {/* <div className={styles.actionWrapper}>
                            <div className={styles.detailWrapper}>
                              <FontAwesomeIcon icon={faFileAlt} size="lg" />
                            </div>
                            {cell.value === "Belum" && (
                              <div
                                className={styles.approveWrapper}
                                onClick={() => approveHandler(row.original.id)}
                              >
                                <FontAwesomeIcon icon={faCheck} size="lg" />
                              </div>
                            )}
                          </div> */}
                        </td>
                      );
                    default:
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={styles.cellItem}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <Pagination
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
      </div>

    </>
  );
}

export default Table;
