import styles from "./ButtonKirim.module.css";

const ButtonKirim = () => {
  return (
    <div className={styles.buttonKirimWrapper}>
      <button className={styles.buttonKirim} type="submit">
        Kirim
      </button>
    </div>
  );
};

export default ButtonKirim;
