import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Pada subtes ini, terdapat pasangan nama pada setiap soal, tugas Anda
          adalah membandingkan pasangan nama yang ada pada setiap soal. <br />
          <br />
          Pilihlah huruf S jika pasangan nama tersebut sama dan huruf D jika
          pasangan nama tersebut berbeda.
        </p>
        <br />
        <p>Pilihlah kata yang tepat yang dapat menyempurnakan kalimat itu !</p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal={`Johan Affandi - Johan Affandi`}
        kunci="S"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Kedua nama tersebut <strong>sama</strong>. Oleh karena itu, pilihlah huruf <strong>S</strong>"
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal={`Reader’s Digest - Readers Digest`}
        kunci="D"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Kedua nama tersebut <strong>tidak sama</strong>. Oleh karena itu, pilihlah huruf <strong>D</strong>"
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        soal={`N.Y. Times - N.Y. Times`}
        kunci="S"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban Anda benar! Kedua nama tersebut <strong>sama</strong>. Oleh karena itu, pilihlah huruf <strong>S</strong>."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={4}
        soal={`Gunung Mas Ltd. - Gunung Emas Ltd.`}
        kunci="D"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban Anda benar! Kedua nama tersebut <strong>tidak sama</strong>. Oleh karena itu, pilihlah huruf <strong>D</strong>."
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={5}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
