import { useEffect } from "react";
import { connect } from "react-redux";
import { generateEmptyJawabans } from "../../Utils";
import {
  mapDispatchToPropsTes,
  mapStateToPropsTes,
} from "../../Utils/reduxMap";
import Petunjuk from "../Components/Petunjuk";
import Soal from "./Soal";

const AKSocialMediaManager = ({
  currentPetunjuk,
  currentTes,
  replaceJawabans,
}) => {
  useEffect(() => {
    generateEmptyJawabans(replaceJawabans, "Tuliskan analisa Anda di sini.");
  }, [replaceJawabans]);

  return (
    <>
      {currentPetunjuk === 100 && <Petunjuk menit={60} />}
      {currentPetunjuk !== 100 && currentTes.tesId === 100 && <Soal />}
    </>
  );
};

export default connect(
  mapStateToPropsTes,
  mapDispatchToPropsTes
)(AKSocialMediaManager);
