import React from 'react';

const HeaderSecond = () => {
  return (
    <React.Fragment>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        GRAPH1
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        GRAPH2
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        GRAPH3
      </th>
    </React.Fragment>
  );
};

export default HeaderSecond;
