import { useState } from "react";
import InvoiceModal from "../../../../InvoiceModal";
import styles from "./Cell.module.css";

const Cell = ({ cell }) => {
  const [isShowInvoiceModal, setIsShowInvoiceModal] = useState(false);

  return (
    <>
      <td
        {...cell.getCellProps()}
        className={styles.cellItem}
        onClick={() => setIsShowInvoiceModal(true)}
      >
        {cell.render("Cell")}
        {isShowInvoiceModal && (
          <InvoiceModal
            isShow={isShowInvoiceModal}
            onHide={() => setIsShowInvoiceModal(false)}
            creditId={cell.row.original.id}
          />
        )}
      </td>
    </>
  );
};

export default Cell;
