import { useEffect } from 'react';
import { connect } from 'react-redux';
import { searchProject } from '../../../redux/User/user-actions';
import { ubahFormat } from '../../../utils/tanggal';
import Table from './Table';
// import { API } from '../../../services/API';

const ProjectTable = ({ projects, searchProjectInput, searchProject }) => {
  let matchProject = [];
  // const [projectProgress, setProjectProgress] = useState([]);

  matchProject = [...projects];
  // if (searchProjectInput === '' && projects) {
  // }
  //  else if (projects) {
  //   matchProject = [...projects].filter((project) => {
  //     return project.nama
  //       .toLowerCase()
  //       .includes(searchProjectInput.toLowerCase());
  //   });
  // }

  // const findProgress = () =>{
  //     projects?.map(async(pId) =>{
  //           await API.get(`/peserta/progress-project/${pId}`).then(r =>{
  //               setProjectProgress([...projectProgress, r.data.progress]);
  //           }).catch(err => console.log(err));
  //     })
  // }

  const data = matchProject?.map((project, index) => {
    const tanggal = `${ubahFormat(project.tglMulai)} - ${ubahFormat(
      project.tglSelesai
    )}`;

    let status = 'Belum';
    if (project.progress > 0 && project.progress < 100) {
      status = 'Proses';
    }
    if (project.progress >= 100) {
      status = 'Selesai';
    }

    return {
      id: project.id,
      nomor: index + 1,
      namaProject: project.nama,
      tanggal,
      modul: project?.modul?.nama,
      status,
      progress: project.progress,
      pembuat: project.pembuat,
      action: 'ACTION',
    };
  });

  // Reset searchProjectInput at redux
  useEffect(() => {
    return () => {
      searchProject('');
    };
  }, [searchProject]);

  const columns = [
    {
      Header: 'No.',
      accessor: 'nomor',
    },
    {
      Header: 'Nama Project',
      accessor: 'namaProject',
    },
    {
      Header: 'Tanggal',
      accessor: 'tanggal',
    },
    {
      Header: 'Modul',
      accessor: 'modul',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Progress Peserta',
      accessor: 'progress',
    },
    {
      Header: 'Pembuat',
      accessor: 'pembuat',
    },
    {
      Header: 'Action',
      accessor: 'action',
    },
  ];

  return <Table columns={columns} data={data} />;
};

const mapStateToProps = (state) => {
  return {
    searchProjectInput: state?.user?.searchProjectInput,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchProject: (projectName) => dispatch(searchProject(projectName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTable);
