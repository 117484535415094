import styles from "./SectionEleven.module.css";

const SectionEleven = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Uraian Kepribadian</h1>
      <p>
        <strong>Brigitta Pradnya</strong> adalah seorang yang kritis, analitik,
        sistematis, hati-hati dan memiliki standar kualitas yang tinggi. Dalam
        menghadapi persoalan , ia akan menganalisisnya secara mendalam, hanya
        saja perasaan sensitifty nya dengan Brigitta Pradnya adalah seorang yang
        kritis, analitik, sistematis, hati-hati dan memiliki standar kualitas
        yang tinggi. Dalam menghadapi persoalan , ia akan menganalisisnya secara
        mendalam, hanya saja perasaan sensitifty nya dengan Brigitta Pradnya
        adalah seorang yang kritis, analitik, sistematis, hati-hati dan memiliki
        standar kualitas yang tinggi. Dalam menghadapi persoalan , ia akan
        menganalisisnya secara mendalam, hanya saja perasaan sensitifty nya
        dengan Brigitta Pradnya adalah seorang yang kritis, analitik,
        sistematis, hati-hati dan memiliki standar kualitas yang tinggi. Dalam
        menghadapi persoalan , ia akan menganalisisnya secara mendalam, hanya
        saja perasaan sensitifty nya dengan Brigitta Pradnya adalah seorang yang
        kritis, analitik, sistematis, hati-hati dan memiliki standar kualitas
        yang tinggi. Dalam menghadapi persoalan , ia akan menganalisisnya secara
        mendalam, hanya saja perasaan sensitifty nya dengan hati-hati.
      </p>
    </div>
  );
};

export default SectionEleven;
