export default function SubColumn1DataDiriPeserta({ format }) {
  return (
    <>
      <th rowSpan={format.rowSpanValue}>NO</th>
      <th rowSpan={format.rowSpanValue}>TANGGAL TES</th>
      <th rowSpan={format.rowSpanValue}>KODE AKTIVASI</th>
      <th rowSpan={format.rowSpanValue}>NAMA LENGKAP</th>
      <th rowSpan={format.rowSpanValue}>NAMA PANGGILAN</th>
      <th rowSpan={format.rowSpanValue}>NIK</th>
      <th rowSpan={format.rowSpanValue}>TELEPON</th>
      <th rowSpan={format.rowSpanValue}>EMAIL</th>
      <th rowSpan={format.rowSpanValue}>PENDIDIKAN</th>
      <th rowSpan={format.rowSpanValue}>JURUSAN</th>
      <th rowSpan={format.rowSpanValue}>TANGGAL LAHIR</th>
      <th rowSpan={format.rowSpanValue}>PERUSAHAAN</th>
      <th rowSpan={format.rowSpanValue}>POSISI</th>
    </>
  );
}
