import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Masing-masing soal berikut ini memperlihatkan deretan dari beberapa
          bentuk di sebelah atas yang disusun berdasarkan pada aturan tertentu.
          Pada sisi sebelah kanan diberikan beberapa kemungkinan pilihan.
        </p>
        <br />
        <p>
          Perhatikan contoh soal untuk memahami soal yang akan Anda kerjakan.
        </p>
        <br />
        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        next={nextHandler}
        previous={previousHandler}
        namaFile="petunjuk1-istr9.png"
        pesanBenar="Pada pola persoalan pada contoh ini bentuk kotak kecil putih berubah menjadi bentuk kotak besar hitam, dengan demikian maka lingkaran kecil putih juga harus berubah menjadi lingkaran besar hitam. Karena itu jawaban yang betul adalah <strong>B</strong>."
        kunci="B"
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        next={nextHandler}
        previous={previousHandler}
        namaFile="petunjuk2-istr9.png"
        pesanBenar="Pada contoh kedua, bentuk segitiga pada deretan atas terbalik posisinya seperti dilihat dalam cermin (segitiga itu berdiri pada ujungnya) dan diarsir hitam penuh. Karena itu bentuk empat persegi panjang juga harus dibalik posisinya dan diarsir hitam penuh. Jawaban yang benar adalah <strong>D</strong>."
        kunci="D"
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
