import Left from "./Left";
import Right from "./Right";
import styles from "./SectionSeven.module.css";

const SectionSeven = () => {
  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <Left />
        <Right />
      </div>
      <div className={styles.blueLines}></div>
    </div>
  );
};

export default SectionSeven;
