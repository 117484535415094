import { useEffect } from 'react';
import { connect } from 'react-redux';
import { generateEmptyJawabans } from '../../Utils';
import {
  mapDispatchToPropsTes,
  mapStateToPropsTes,
} from '../../Utils/reduxMap';
import Petunjuk from './Petunjuk';
import Soal from './Soal';

const IBManagerMultiFinance = ({
  currentPetunjuk,
  currentTes,
  replaceJawabans,
}) => {
  useEffect(() => {
    generateEmptyJawabans(
      replaceJawabans,
      `Urutan Pengerjaan : 
Kategori Kasus    : 
Respon            :`
    );
  }, [replaceJawabans]);

  return (
    <>
      {currentPetunjuk === 181 && <Petunjuk />}
      {currentPetunjuk !== 181 && currentTes.tesId === 181 && <Soal />}
    </>
  );
};

export default connect(
  mapStateToPropsTes,
  mapDispatchToPropsTes
)(IBManagerMultiFinance);
