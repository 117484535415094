import RoleEditContent from "../../components/RoleEditContent";
import SideBar from "../../components/SideBar";
import styles from "./RoleEditPage.module.css";

const RoleEditPage = () => {
  return (
    <div className={styles.container}>
      <SideBar />
      <RoleEditContent />
    </div>
  );
};

export default RoleEditPage;
