import * as actionTypes from "./peserta-types";

export const pesertaLogin = (
  peserta,
  currentTes,
  currentSoal,
  currentPetunjuk,
  jawabans, 
  photos
) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PESERTA_LOGIN,
      payload: {
        peserta,
        currentTes,
        currentSoal,
        currentPetunjuk,
        jawabans,
        photos
      },
    });
  };
};

export const changeCurrentTes = (currentTes) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_CURRENT_TES,
      payload: {
        currentTes,
      },
    });
  };
};

export const changeCurrentSoal = (currentSoal) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_CURRENT_SOAL,
      payload: {
        currentSoal,
      },
    });
  };
};

export const changeCurrentPetunjuk = (currentPetunjuk) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_CURRENT_PETUNJUK,
      payload: {
        currentPetunjuk,
      },
    });
  };
};

export const addPhotos = (photos) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_PHOTO,
      payload: {
        photos,
      },
    });
  };
};

export const replaceJawabans = (jawabans) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REPLACE_JAWABANS,
      payload: {
        jawabans,
      },
    });
  };
};
