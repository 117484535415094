import { useEffect, useState } from 'react';
import { API } from '../../../../../../services/API';
import { convertToRupiah } from '../../../../../../utils/moneyConvert';
import Body from './Body';
import styles from './DetailModal.module.css';

const DetailModal = ({ isShow, onHide, approveHandler, status, creditId }) => {
  const [credit, setCredit] = useState({});

  useEffect(() => {
    const getCredit = async () => {
      try {
        if (isShow) {
          const res = await API.get(`/credit/${creditId}`);
          setCredit(res.data.credit);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getCredit();
  }, [creditId, isShow]);

  // console.log(credit);

  return (
    <>
      {isShow && (
        <>
          <div className={styles.container}>
            <div className={styles.creditWrapper}>
              <div className={styles.sampul} />

              <div className={styles.main}>
                <div className={styles.header}>
                  <div className={styles.headerLeft}>
                    <h3>PT. Talenta Indonesia</h3>
                    <p>4140 Parker Rd. Allentown, New Mexico 31134</p>
                  </div>
                  <div className={styles.headerRight}>
                    {credit?.buktiTransfer ? (
                      <button>
                        <a
                          href={credit?.buktiTransfer}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Cek Bukti Pembayaran
                        </a>
                      </button>
                    ) : (
                      <p>Belum membayar</p>
                    )}
                  </div>
                </div>

                <Body credit={credit} />

                <div className={styles.footer}>
                  <strong>Total</strong>
                  <p>{convertToRupiah(credit?.modul?.modulH[0]?.harga)}</p>
                </div>

                <div className={styles.action}>
                  <button className={styles.cancel} onClick={onHide}>
                    Tutup
                  </button>
                  {credit?.buktiTransfer &&
                    (status === 'Selesai' ? (
                      <button disabled className={styles.selesai}>
                        Pembayaran Sudah Selesai
                      </button>
                    ) : (
                      <button
                        className={styles.accept}
                        onClick={() => {
                          approveHandler();
                          onHide();
                        }}
                      >
                        Accept Pembayaran
                      </button>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.background} onClick={onHide}></div>
        </>
      )}
    </>
  );
};

export default DetailModal;
