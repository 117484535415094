import React from 'react';

const HeaderSecond = () => {
  return (
    <React.Fragment>
      <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        JUMLAH PRESTASI
      </th>
      <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        KATEGORI
      </th>
      <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        RATA-RATA
      </th>
      <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        KATEGORI
      </th>
      <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        JUMLAH KESALAHAN
      </th>
      <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        KATEGORI
      </th>
      <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        KONSISTENSI
      </th>
      <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        KATEGORI
      </th>
      <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        KETAHANAN
      </th>
      <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        KATEGORI
      </th>
    </React.Fragment>
  );
};

export default HeaderSecond;
