import { useState } from "react";
import Benar from "../../../Components/PetunjukMiddle/Benar";
import Footer from "../../../Components/PetunjukMiddle/Footer";
import PetunjukMiddleContainer from "../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import PetunjukParagraph from "../../../Components/PetunjukMiddle/PetunjukParagraph";
import Salah from "../../../Components/PetunjukMiddle/Salah";
import SoalImage from "../../../Components/SoalImage";
import PilihanWrapper from "../../../Components/PilihanWrapper";
import PilihanText from "../../../Components/PilihanText";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  namaFile,
  kunci,
  next,
  previous,
}) => {
  const [answer, setAnswer] = useState("");

  const arrPilihan = ["A", "B", "C", "D", "E"];

  return (
    <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
      <PetunjukParagraph>
        Terdapat soal berupa kertas bujur sangkar dan di sebelah kanan adalah
        alternatif dari jawaban. Tentukan jawaban yang benar dengan memilih
        jawaban A, B, C, D, atau E
      </PetunjukParagraph>

      <SoalImage src={`/images/tes/abs24/${namaFile}`} alt="Contoh" />
      {selectedIndex === 1 && (
        <>
          <PetunjukParagraph>
            Rangkaian menunjukkan proses pelipatan kertas, pembuatan lubang dan
            membuka kembali lipatan kertas tersebut. Setelah kertas tersebut
            dibuka kembali, tentukan kertas mana yang benar beserta lubang yang
            terdapat pada kertas tersebut.
          </PetunjukParagraph>
          <PetunjukParagraph>
            Pada contoh diatas jawaban yang sesuai adalah huruf e, maka pilihlah
            jawaban tersebut. Perhatikan animasi dibawah ini
          </PetunjukParagraph>

          <SoalImage src={"/images/tes/abs24/petunjuk_abs.gif"} alt="Contoh" />
        </>
      )}
      <PilihanWrapper variant="text" col={2}>
        {arrPilihan?.map((item, index) => (
          <PilihanText
            label={item}
            onClick={() => setAnswer(item)}
            answer={answer}
            key={index}
          />
        ))}
      </PilihanWrapper>
      <Benar isShow={answer === kunci} htmlString="Jawaban anda benar!" />
      <Salah isShow={answer !== kunci && answer !== ""} />
      <Footer
        min={1}
        max={5}
        previous={previous}
        next={next}
        condition={answer === kunci}
      />
    </PetunjukMiddleContainer>
  );
};

export default PetunjukMiddle;
