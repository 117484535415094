import { useState } from 'react';
import { connect } from 'react-redux';
import { API } from '../../../services/API';
import InputGroup from '../Components/DataDiri/InputGroup';
import SelectGroup from '../Components/DataDiri/SelectGroup';
import DataDiriContainer from '../Components/DataDiri/DataDiriContainer';
import DataDiriForm from '../Components/DataDiri/DataDiriForm';
import { mapDispatchToPropsDataDiri } from '../Utils/reduxMap';
import ButtonKirim from '../Components/DataDiri/ButtonKirim';
import DataDiriTitle from '../Components/DataDiri/DataDiriTitle';
import SectionWrapper from '../Components/DataDiri/SectionWrapper';
import HalfSection from '../Components/DataDiri/HalfSection';
import SoalContainer from '../Containers/SoalContainer';

const DataDiriSingkat = ({
  changeCurrentPetunjuk,
  changeCurrentSoal,
  changeCurrentTes,
}) => {
  const [kodetes, setKodetes] = useState('');
  const [isShowSelesaiModal, setIsShowSelesaiModal] = useState(false);

  //peserta
  const [nama, setNama] = useState('');
  const [tanggalLahir, setTanggalLahir] = useState('');
  const [gender, setGender] = useState('');
  const [tempatLahir, setTempatLahir] = useState('');
  const [namaPanggilan, setNamaPanggilan] = useState('');
  const [pendidikan, setPendidikan] = useState('');
  const [jurusan, setJurusan] = useState('');
  const [perusahaanPengirim, setPerusahaanPengirim] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    submitToAPI();
  };

  const submitToAPI = async () => {
    const data = {
      nama,
      pendidikan,
      tglLahir: tanggalLahir,
      gender,
      namaPanggilan,
      tmptLahir: tempatLahir,
      jurusan,
      dataDiriSingkat: {
        top: {
          kodetes,
        },
        peserta: {
          nama,
          tanggalLahir,
          gender,
          tempatLahir,
          namaPanggilan,
          pendidikan,
          jurusan,
          perusahaanPengirim,
        },
      },
    };

    try {
      // console.log(data);
      // Upload to API
      const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
      await API.patch(`/peserta/${psikotesLS.peserta.id}`, data);

      setIsShowSelesaiModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DataDiriContainer>
      <SoalContainer
        isShowSelesaiModal={isShowSelesaiModal}
        setIsShowSelesaiModal={setIsShowSelesaiModal}
      >
        <DataDiriTitle title="DATA PESERTA TES" />

        <DataDiriForm onSubmit={handleSubmit}>
          <InputGroup
            label="Nomor Tes *(Isi terserah jika tidak ada)"
            id="kodetes"
            type="text"
            state={kodetes}
            setState={setKodetes}
            maxLength={50}
          />
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="Nama"
                id="nama"
                type="text"
                state={nama}
                setState={setNama}
                maxLength={60}
              />

              <InputGroup
                label="Nama Panggilan"
                id="namaPanggilan"
                type="text"
                state={namaPanggilan}
                setState={setNamaPanggilan}
                maxLength={20}
              />

              <SelectGroup
                label="Jenis Kelamin"
                state={gender}
                setState={setGender}
              >
                <option value=""></option>
                <option value="L">Laki-laki</option>
                <option value="P">Perempuan</option>
              </SelectGroup>
              <InputGroup
                label="Tempat Lahir"
                id="tempatLahir"
                type="text"
                state={tempatLahir}
                setState={setTempatLahir}
                maxLength={40}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Tanggal Lahir"
                id="tanggal-lahir"
                type="date"
                state={tanggalLahir}
                setState={setTanggalLahir}
              />
              <SelectGroup
                label="Jenjang Pendidikan"
                state={pendidikan}
                setState={setPendidikan}
              >
                <option value=""></option>
                <option value="SD">SD</option>
                <option value="SMP">SMP</option>
                <option value="SMA">SMA</option>
                <option value="SMK">SMK</option>
                <option value="D1">D1</option>
                <option value="D3">D3</option>
                <option value="D4">D4</option>
                <option value="S1">S1</option>
                <option value="S2">S2</option>
                <option value="S3">S3</option>
              </SelectGroup>
              <InputGroup
                label="Nama Jurusan Jenjang Pendidikan Anda"
                id="jurusan"
                type="text"
                state={jurusan}
                setState={setJurusan}
                maxLength={40}
              />
              <InputGroup
                label="Nama Perusahaan Pengirim Anda"
                id="jurusan"
                type="text"
                state={perusahaanPengirim}
                setState={setPerusahaanPengirim}
                maxLength={60}
              />
            </HalfSection>
          </SectionWrapper>

          <ButtonKirim />
        </DataDiriForm>
      </SoalContainer>
    </DataDiriContainer>
  );
};

export default connect(null, mapDispatchToPropsDataDiri)(DataDiriSingkat);
