import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import styles from "./Petunjuk.module.css";
import Footer from "../../../Components/PetunjukMiddle/Footer";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <div
        className={styles.petunjukZero}
        style={{ display: index === 0 ? "flex" : "none" }}
      >
        <p>
          Pada persoalan berikut, Anda akan menghadapi beberapa kasus yang
          terdiri dari memo/ dokumen kerja. Tugas anda adalah membuat respon
          atas memo/ dokumen tersebut dan membagi permasalahan-permasalahan
          tersebut ke dalam 3 kategori :
        </p>
        <ol>
          <b>
            <li>High Priority</li>
          </b>
          <b>
            <li>Moderate Priority</li>
          </b>
          <b>
            <li>Low Priority</li>
          </b>
        </ol>

        <p>
          Anda bebas untuk mengerjakan dokumen mana yang akan anda selesaikan
          terlebih dahulu. Yang terpenting berikan kategori pada setiap
          permasalahan dan tuliskan urutan permasalahan menurut masalah yang
          paling prioritas untuk diselesaikan.
        </p>

        <p>
          Tulislah respon kamu untuk setiap permasalahan tadi pada kolom
          jawaban, setelah yakin tekan <strong>Lanjut</strong> untuk mengisi
          jawabannya
        </p>

        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>

        <p>
          <font color="red">
            Jangan ditutup terlebih dulu petunjuk ini, baca dan pahami
            instruksi. Apabila petunjuk ini ditutup maka tes dimulai dan waktu
            berjalan.
          </font>
        </p>

        <div className={styles.buttonWrapper}>
          <button onClick={nextHandler}>Tutup</button>
        </div>
      </div>

      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 1 ? "flex" : "none" }}
      >
        <center>
          <h2>
            <u>INFORMASI SEPUTAR PERUSAHAAN</u>
          </h2>
        </center>
        <span>
          Situasi yang akan Anda hadapi berikut ini adalah buatan tetapi
          masalah-masalah yang muncul merupakan masalah-masalah yang benar
          terjadi, diambil dari situasi sehari-hari yang biasa ditemui. Anda
          adalah Bapak Hartanto Sujono, Kepala Seksi Pemasaran dari PT
          Talentatex, perusahaan tekstil terbesar ke-2 di Indonesia. Rekan Anda,
          Bapak Wahyu yang menjabat sebagai Kepala Seksi Purchasing, dimutasikan
          ke Surabaya, karena perusahaan membuka pabrik yang baru disana. Anda
          kemudian diminta untuk menggantikan posisi Kepala Seksi Purchasing
          tersebut. Anda baru diberitahu kabar ini pada hari Sabtu siang,
          tanggal 1 April. Karena satu dan lain hal, Anda baru dapat pergi ke
          kantor pada hari Senin, 3 April, pukul 7 pagi. Itu pun Anda hanya
          dapat berada di kantor untuk mengerjakan tugas-tugas baru selama 60
          menit. Anda harus meninggalkan kantor tepat pada pukul 8 pagi untuk ke
          bandara karena harus mengikuti pelatihan dari kantor di luar kota pada
          pukul 10 pagi hingga Rabu malam, 5 April. Anda baru akan kembali ke
          kantor pada hari Kamis pagi, 6 April. Anda akan sangat sibuk selama
          pelatihan sehingga tidak dapat membawa tugas-tugas baru Anda untuk
          diselesaikan. Sementara itu, Wakil Kepala Seksi Purchasing, Ibu Endang
          Yuliati, sedang cuti melahirkan dan baru akan kembali ke kantor pada
          hari Rabu, 5 April. Anda akan mendapatkan sebuah struktur organisasi
          perusahaan dan sebuah In-Basket. In-Basket berisi sejumlah materi yang
          ditinggalkan oleh sekretaris Anda untuk diperhatikan. Anda akan
          memiliki waktu selama 60 menit untuk menanggapi sebanyak mungkin
          masalah yang terdapat dalam In-Basket.
        </span>

        <Footer previous={previousHandler} next={nextHandler} condition />
      </div>

      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 2 ? "flex" : "none" }}
      >
        <p>
          &nbsp; &nbsp; Ingat! Hari ini adalah hari Senin, 3 April. Anda adalah
          Bapak Hartanto Sujono. Anda tidak dapat menghubungi siapapun untuk
          meminta bantuan karena kantor baru akan dibuka pada jam 9 pagi, dan
          semua telepon pun masih terkunci. Laci Anda juga terkunci dan yang
          memegang kunci adalah sekretaris Anda. Anda harus bekerja hanya dengan
          materi-materi yang Anda pegang. Anda memiliki waktu 60 menit. Anda
          akan pergi selama hari Senin sampai Rabu. Anda tidak dapat membawa
          materi-materi ini dalam perjalanan Anda.
        </p>
        <p>
          &nbsp; &nbsp; Agar kami dapat mengetahui cara Anda menangani setiap
          materi, tulislah semua hal yang akan Anda lakukan. Buatlah memo untuk
          diri Anda sendiri mengenai hal-hal yang akan Anda lakukan sepulang
          dari pelatihan Anda. Begitu juga dengan rancangan surat untuk
          dipersiapkan sekretaris Anda. Tulislah hal-hal yang akan Anda katakan
          lewat telepon dan tujuan dari tindakan Anda. Buatlah rancangan dari
          pertemuan yang akan Anda laksanakan.
        </p>
        <p>
          &nbsp; &nbsp; Guna tujuan identifikasi, materi In-Basket telah
          diberikan nomor dari satu sampai delapan, namun urutan pengerjaan
          materi terserah pada Anda.
        </p>
        <img src="./images/kalender_mt.png" className={styles.gambar} alt="" />
        <br />
        <br />
        <img
          src="./images/so_talentates.png"
          className={styles.gambar}
          alt=""
        />
        <Footer previous={previousHandler} next={nextHandler} condition />
      </div>

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
