export default function SubColumn1Tiki({ format }) {
  return (
    <>
      <th colSpan={format.perColRows} rowSpan={format.rowSpanValue - 1}>
        RS
      </th>
      <th colSpan={format.perColRows} rowSpan={format.rowSpanValue - 1}>
        WS
      </th>
      <th colSpan={format.perColRows * 0} rowSpan={format.rowSpanValue}>
        JUMLAH
      </th>
      <th colSpan={format.perColRows * 0} rowSpan={format.rowSpanValue}>
        IQ KELOMPOK TOTAL
      </th>
      <th colSpan={format.perColRows * 0} rowSpan={format.rowSpanValue}>
        Klasifikasi IQ
      </th>
      <th colSpan={format.perColRows * 0} rowSpan={format.rowSpanValue}>
        F1
      </th>
      <th colSpan={format.perColRows * 0} rowSpan={format.rowSpanValue}>
        F2
      </th>
      <th colSpan={format.perColRows * 0} rowSpan={format.rowSpanValue}>
        F3
      </th>
    </>
  );
}
