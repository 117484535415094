import { useEffect } from 'react';
import { connect } from 'react-redux';
import { generateEmptyJawabans } from '../../Utils';
import {
  mapDispatchToPropsTes,
  mapStateToPropsTes,
} from '../../Utils/reduxMap';
import Petunjuk from '../Components/Petunjuk';
import Soal from './Soal';

const AKReasuransiKepalaSeksi = ({
  currentPetunjuk,
  currentTes,
  replaceJawabans,
}) => {
  useEffect(() => {
    generateEmptyJawabans(replaceJawabans, 'Tuliskan analisa Anda di sini.');
  }, [replaceJawabans]);

  return (
    <>
      {currentPetunjuk === 141 && <Petunjuk menit={50} />}
      {currentPetunjuk !== 141 && currentTes.tesId === 141 && <Soal />}
    </>
  );
};

export default connect(
  mapStateToPropsTes,
  mapDispatchToPropsTes
)(AKReasuransiKepalaSeksi);
