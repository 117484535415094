import { connect } from 'react-redux';
import RekapExcelContent from '../../components/RekapExcelContent';
import SideBar from '../../components/SideBar';
import styles from '../Dashboard/Dashboard.module.css';

const RekapExcel = (props) => {
  return (
    <div className={styles.container}>
      <SideBar />

      <RekapExcelContent
        projectId={props.location.state?.id}
        table={props.location.state?.table}
        dataProject={props.location.state?.project}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: state.user.isAdmin,
    clientId: state?.user?.user?.client?.id,
  };
};

export default connect(mapStateToProps, null)(RekapExcel);
