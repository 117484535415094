import { useState } from "react";
import Benar from "../../../Components/PetunjukMiddle/Benar";
import Footer from "../../../Components/PetunjukMiddle/Footer";
import PetunjukMiddleContainer from "../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import PetunjukParagraph from "../../../Components/PetunjukMiddle/PetunjukParagraph";
import Salah from "../../../Components/PetunjukMiddle/Salah";
import PilihanText from "../../../Components/PilihanText";
import PilihanWrapper from "../../../Components/PilihanWrapper";
import SoalImage from "../../../Components/SoalImage";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  namaFile,
  firstDesc,
  kunci,
  next,
  previous,
}) => {
  const [answer, setAnswer] = useState("");
  const arrPilihan = [
    "1/10",
    "2/10",
    "3/10",
    "4/10",
    "5/10",
    "6/10",
    "7/10",
    "8/10",
    "9/10",
  ];

  return (
    <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
      <PetunjukParagraph>{firstDesc}</PetunjukParagraph>
      <SoalImage src={`/images/tes/ret/${namaFile}`} alt="Contoh" />

      <PilihanWrapper variant="text" col={5}>
        {arrPilihan?.map((item, index) => (
          <PilihanText
            label={item}
            onClick={() => setAnswer(item)}
            answer={answer}
            key={index}
          />
        ))}
      </PilihanWrapper>

      <Benar isShow={answer === kunci} htmlString={"Jawaban anda Benar"} />
      <Salah isShow={answer !== kunci && answer !== ""} />
      <Footer
        min={1}
        max={9}
        previous={previous}
        next={next}
        condition={answer === kunci}
      />
    </PetunjukMiddleContainer>
  );
};

export default PetunjukMiddle;
