import { useState } from "react";
import styles from "./PetunjukMiddle.module.css";
import PetunjukMiddleContainer from "../../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import Footer from "../../../../Components/PetunjukMiddle/Footer";
import Benar from "../../../../Components/PetunjukMiddle/Benar";
import Salah from "../../../../Components/PetunjukMiddle/Salah";
import PetunjukParagraph from "../../../../Components/PetunjukMiddle/PetunjukParagraph";
import PilihanWrapper from "../../../../Components/PilihanWrapper";
import PilihanText from "../../../../Components/PilihanText";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  kunci,
  next,
  previous,
  pesanBenar,
  kesimpulan,
}) => {
  const [answer, setAnswer] = useState("");

  const arrSoal = [
    "Ella adalah seorang janda",
    "Yeni bekerja pada perusahaan B",
    "Ella hanya mempunyai anak tunggal wanita",
    "Perusahaan A menghasilkan busi",
    "Perusahaan A tidak mempekerjakan karyawan wanita",
  ];
  const arrPilihan = ["B", "S", "?"];

  return (
    <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
      <ul className={styles.ul}>
        {arrSoal?.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <PetunjukParagraph textAlign="center">
        {`Kesimpulan : ${kesimpulan}`}
      </PetunjukParagraph>

      <PilihanWrapper variant="text" col={3}>
        {arrPilihan?.map((item, index) => (
          <PilihanText
            label={item}
            onClick={() => setAnswer(item)}
            answer={answer}
            key={index}
          />
        ))}
      </PilihanWrapper>
      <Benar isShow={answer === kunci} htmlString={pesanBenar} />
      <Salah isShow={answer !== kunci && answer !== ""} />
      <Footer
        min={1}
        max={3}
        previous={previous}
        next={next}
        condition={answer === kunci}
      />
    </PetunjukMiddleContainer>
  );
};

export default PetunjukMiddle;
