import { useState } from "react";
import PetunjukMiddle from "./PetunjukMiddle";
import PetunjukContainer from "../../Components/PetunjukContainer";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer
      isModal={isModal}
      tutupModalHandler={tutupModalHandler}
      previousHandler={previousHandler}
      nextHandler={nextHandler}
      index={index}
      lastIndex={4}
    >
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        namaFile="contoh-cfit3a9.png"
        pesanBenar='Contoh di atas, pola yang memiliki kemiripan adalah pola pada kotak "c" (sudah terdapat titik), dimana pola pada kotak "c" diberi tanda titik, maka akan berada dalam lingkaran dan didalam bidang segi empat, seperti pada pola contoh (atas).'
        kunci="c"
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        namaFile="contoh-cfit3a10.png"
        pesanBenar='Jawaban yang tepat adalah "d", karena: Pada pola "d", apabila diberi tanda titik akan mirip dengan pola contoh, yaitu titik itu akan berada di dalam segitiga tetapi diluar bidang segi empat.'
        kunci="d"
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        namaFile="contoh-cfit3a11.png"
        pesanBenar="Pada contoh ini jawaban yang tepat adalah <strong>b</strong> karena <strong>sama-sama bisa menempatkan titik di dalam segitiga dan diatas garis cekung."
        kunci="b"
        previous={previousHandler}
        next={nextHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
