import { convertToRupiah } from "../../../../../../../utils/moneyConvert";
import styles from "./Body.module.css";

const Body = ({ credit }) => {
  return (
    <table className={styles.body}>
      <thead>
        <tr>
          <th className={styles.thModul}>Modul</th>
          <th className={styles.thKredit}>Kredit</th>
          <th className={styles.thJumlah}>Jumlah</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td className={styles.tdModul}>{credit?.modul?.nama}</td>
          <td className={styles.tdKredit}>{credit?.total}</td>
          <td className={styles.tdJumlah}>
            {convertToRupiah(credit?.modul?.modulH[0]?.harga)}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Body;
