export default function SubColumn1SelfAssessmentString({ format }) {
  return (
    <>
      <th rowSpan={format.rowSpanValue}>
        Silahkan Gambarkan diri Anda secara singkat
      </th>
      <th rowSpan={format.rowSpanValue}>Prestasi terbesar anda</th>
      <th rowSpan={format.rowSpanValue}>
        Dalam hal apa anda sulit mengambil keputusan?
      </th>
      <th rowSpan={format.rowSpanValue}>
        Kesulitan dalam pekerjaan saat ini dan cara mengatasinya
      </th>
      <th rowSpan={format.rowSpanValue}>Kelebihan</th>
      <th rowSpan={format.rowSpanValue}>Hal yang perlu pengembangan</th>
    </>
  );
}
