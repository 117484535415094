import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  changeCurrentPetunjuk,
  changeCurrentSoal,
  changeCurrentTes,
  pesertaLogin,
} from '../../../redux/Peserta/peserta-actions';
import { API } from '../../../services/API';
import InputGroup from '../Components/DataDiri/InputGroup';
import SelectGroup from '../Components/DataDiri/SelectGroup';
// import { nextTesHandlerUtil } from '../Utils';
import TextAreaGroup from '../Components/DataDiri/TextAreaGroup';
import DataDiriTitle from '../Components/DataDiri/DataDiriTitle';
import DataDiriForm from '../Components/DataDiri/DataDiriForm';
import DataDiriContainer from '../Components/DataDiri/DataDiriContainer';
import ButtonKirim from '../Components/DataDiri/ButtonKirim';
import SectionWrapper from '../Components/DataDiri/SectionWrapper';
import HalfSection from '../Components/DataDiri/HalfSection';
// import Popup from '../Components/DataDiri/Popup';
import SoalContainer from '../Containers/SoalContainer';

const DataDiriMap = ({
  changeCurrentPetunjuk,
  changeCurrentSoal,
  changeCurrentTes,
}) => {
  // const [index, setIndex] = useState(0);
  // const index = 0;
  const [isShowSelesaiModal, setIsShowSelesaiModal] = useState(false);
  //Top
  const [nomorTes, setNomorTes] = useState('');
  const [kotaPelaksanaanTes, setKotaPelaksanaanTes] = useState('');
  const [posisiYangDituju, setPosisiYangDituju] = useState('');

  //peserta
  const [namaLengkap, setNamaLengkap] = useState('');
  const [jabatan, setJabatan] = useState('');
  const [telepon, setTelepon] = useState('');
  const [usia, setUsia] = useState('');
  const [alamat, setAlamat] = useState('');
  const [gender, setGender] = useState('');
  const [tanggalLahir, setTanggalLahir] = useState('');
  const [tempatLahir, setTempatLahir] = useState('');
  const [agama, setAgama] = useState('');
  const [statusPerkawinan, setStatusPerkawinan] = useState('');
  const [jumlahAnak, setJumlahAnak] = useState('');
  const [buktiIdentitas, setBuktiIdentitas] = useState('');

  //Orang Tua
  const [namaIbu, setNamaIbu] = useState('');
  const [pekerjaanIbu, setPekerjaanIbu] = useState('');
  const [namaAyah, setNamaAyah] = useState('');
  const [pekerjaanAyah, setPekerjaanAyah] = useState('');
  const [alamatOrangTua, setAlamatOrangTua] = useState('');
  const [anakKe, setAnakKe] = useState('');
  const [berapaBersaudara, setBerapaBersaudara] = useState('');

  //SD
  const [namaSekolahSD, setNamaSekolahSD] = useState('');
  const [tahunMulaiSD, setTahunMulaiSD] = useState('');
  const [tahunSelesaiSD, setTahunSelesaiSD] = useState('');

  //SMP
  const [namaSekolahSMP, setNamaSekolahSMP] = useState('');
  const [tahunMulaiSMP, setTahunMulaiSMP] = useState('');
  const [tahunSelesaiSMP, setTahunSelesaiSMP] = useState('');

  //SMA
  const [namaSekolahSMA, setNamaSekolahSMA] = useState('');
  const [tahunMulaiSMA, setTahunMulaiSMA] = useState('');
  const [tahunSelesaiSMA, setTahunSelesaiSMA] = useState('');

  //Diploma
  const [diploma, setDiploma] = useState('');
  const [namaUniversitasDiploma, setNamaUniversitasDiploma] = useState('');
  const [tahunMulaiDiploma, setTahunMulaiDiploma] = useState('');
  const [tahunSelesaiDiploma, setTahunSelesaiDiploma] = useState('');

  //Sarjana Satu
  const [namaUniversitasSarjanaSatu, setNamaUniversitasSarjanaSatu] =
    useState('');
  const [jurusanSarjanaSatu, setJurusanSarjanaSatu] = useState('');
  const [tahunMulaiSarjanaSatu, setTahunMulaiSarjanaSatu] = useState('');
  const [tahunSelesaiSarjanaSatu, setTahunSelesaiSarjanaSatu] = useState('');

  //Sarjana Dua
  const [namaUniversitasSarjanaDua, setNamaUniversitasSarjanaDua] =
    useState('');
  const [jurusanSarjanaDua, setJurusanSarjanaDua] = useState('');
  const [tahunMulaiSarjanaDua, setTahunMulaiSarjanaDua] = useState('');
  const [tahunSelesaiSarjanaDua, setTahunSelesaiSarjanaDua] = useState('');

  //Sarjana Tiga
  const [namaUniversitasSarjanaTiga, setNamaUniversitasSarjanaTiga] =
    useState('');
  const [jurusanSarjanaTiga, setJurusanSarjanaTiga] = useState('');
  const [tahunMulaiSarjanaTiga, setTahunMulaiSarjanaTiga] = useState('');
  const [tahunSelesaiSarjanaTiga, setTahunSelesaiSarjanaTiga] = useState('');

  //Pengalaman Organisasi
  const [namaOrgSatu, setNamaOrgSatu] = useState('');
  const [jabatanOrgSatu, setJabatanOrgSatu] = useState('');
  const [tahunOrgSatu, setTahunOrgSatu] = useState('');
  const [namaOrgDua, setNamaOrgDua] = useState('');
  const [jabatanOrgDua, setJabatanOrgDua] = useState('');
  const [tahunOrgDua, setTahunOrgDua] = useState('');
  const [namaOrgTiga, setNamaOrgTiga] = useState('');
  const [jabatanOrgTiga, setJabatanOrgTiga] = useState('');
  const [tahunOrgTiga, setTahunOrgTiga] = useState('');

  //Pengalaman Kerja
  const [namaKerjaSatu, setNamaKerjaSatu] = useState('');
  const [jabatanKerjaSatu, setJabatanKerjaSatu] = useState('');
  const [tahunKerjaSatu, setTahunKerjaSatu] = useState('');
  const [namaKerjaDua, setNamaKerjaDua] = useState('');
  const [jabatanKerjaDua, setJabatanKerjaDua] = useState('');
  const [tahunKerjaDua, setTahunKerjaDua] = useState('');
  const [namaKerjaTiga, setNamaKerjaTiga] = useState('');
  const [jabatanKerjaTiga, setJabatanKerjaTiga] = useState('');
  const [tahunKerjaTiga, setTahunKerjaTiga] = useState('');

  //Perspektif
  const [perspektifOne, setPerspektifOne] = useState('');
  const [perspektifTwo, setPerspektifTwo] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    submitToAPI();
  };

  const submitToAPI = async () => {
    const data = {
      nama: namaLengkap,
      tglLahir: tanggalLahir,
      telp: telepon,
      gender,
      tmptLahir: tempatLahir,
      alamat,
      dataMappingPegawai: {
        top: {
          nomorTes,
          kotaPelaksanaanTes,
          posisiYangDituju,
        },
        peserta: {
          namaLengkap,
          jabatan,
          telepon,
          usia,
          alamat,
          gender,
          tanggalLahir,
          tempatLahir,
          agama,
          statusPerkawinan,
          jumlahAnak,
          buktiIdentitas,
        },
        orangTua: {
          namaIbu,
          pekerjaanIbu,
          namaAyah,
          pekerjaanAyah,
          alamatOrangTua,
          anakKe,
          berapaBersaudara,
        },
        sd: {
          namaSekolahSD,
          tahunMulaiSD,
          tahunSelesaiSD,
        },
        smp: {
          namaSekolahSMP,
          tahunMulaiSMP,
          tahunSelesaiSMP,
        },
        sma: {
          namaSekolahSMA,
          tahunMulaiSMA,
          tahunSelesaiSMA,
        },
        diploma: {
          diploma,
          namaUniversitasDiploma,
          tahunMulaiDiploma,
          tahunSelesaiDiploma,
        },
        sarjanaSatu: {
          namaUniversitasSarjanaSatu,
          jurusanSarjanaSatu,
          tahunMulaiSarjanaSatu,
          tahunSelesaiSarjanaSatu,
        },
        sarjanaDua: {
          namaUniversitasSarjanaDua,
          jurusanSarjanaDua,
          tahunMulaiSarjanaDua,
          tahunSelesaiSarjanaDua,
        },
        sarjanaTiga: {
          namaUniversitasSarjanaTiga,
          jurusanSarjanaTiga,
          tahunMulaiSarjanaTiga,
          tahunSelesaiSarjanaTiga,
        },
        pengalamanOrganisasi: {
          namaOrgSatu,
          jabatanOrgSatu,
          tahunOrgSatu,
          namaOrgDua,
          jabatanOrgDua,
          tahunOrgDua,
          namaOrgTiga,
          jabatanOrgTiga,
          tahunOrgTiga,
        },
        pengalamanKerja: {
          namaKerjaSatu,
          jabatanKerjaSatu,
          tahunKerjaSatu,
          namaKerjaDua,
          jabatanKerjaDua,
          tahunKerjaDua,
          namaKerjaTiga,
          jabatanKerjaTiga,
          tahunKerjaTiga,
        },
        perspektif: {
          perspektifOne,
          perspektifTwo,
        },
      },
    };

    try {
      // console.log(data);
      // Upload to API
      if (navigator.onLine) {
        const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
        await API.patch(`/peserta/${psikotesLS.peserta.id}`, data);
      }

      // nextTesHandlerUtil(
      //   changeCurrentTes,
      //   changeCurrentSoal,
      //   changeCurrentPetunjuk
      // );
      setIsShowSelesaiModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  // if (index === 1) {
  //   return <Popup onClick={submitToAPI} />;
  // }

  useEffect(() => {
    let dataMappingPegawai = localStorage.getItem('dataMappingPegawai');
    // console.log({ dataMappingPegawai });

    if (dataMappingPegawai) {
      dataMappingPegawai = JSON.parse(dataMappingPegawai);

      setNomorTes(dataMappingPegawai?.nomorTes);
      setKotaPelaksanaanTes(dataMappingPegawai?.kotaPelaksanaanTes);
      setPosisiYangDituju(dataMappingPegawai?.posisiYangDituju);
      setNamaLengkap(dataMappingPegawai?.namaLengkap);
      setJabatan(dataMappingPegawai?.jabatan);
      setTelepon(dataMappingPegawai?.telepon);
      setUsia(dataMappingPegawai?.usia);
      setAlamat(dataMappingPegawai?.alamat);
      setGender(dataMappingPegawai?.gender);
      setTanggalLahir(dataMappingPegawai?.tanggalLahir);
      setTempatLahir(dataMappingPegawai?.tempatLahir);
      setAgama(dataMappingPegawai?.agama);
      setStatusPerkawinan(dataMappingPegawai?.statusPerkawinan);
      setJumlahAnak(dataMappingPegawai?.jumlahAnak);
      setBuktiIdentitas(dataMappingPegawai?.buktiIdentitas);
      setNamaIbu(dataMappingPegawai?.namaIbu);
      setPekerjaanIbu(dataMappingPegawai?.pekerjaanIbu);
      setNamaAyah(dataMappingPegawai?.namaAyah);
      setPekerjaanAyah(dataMappingPegawai?.pekerjaanAyah);
      setAlamatOrangTua(dataMappingPegawai?.alamatOrangTua);
      setAnakKe(dataMappingPegawai?.anakKe);
      setBerapaBersaudara(dataMappingPegawai?.berapaBersaudara);
      setNamaSekolahSD(dataMappingPegawai?.namaSekolahSD);
      setTahunMulaiSD(dataMappingPegawai?.tahunMulaiSD);
      setTahunSelesaiSD(dataMappingPegawai?.tahunSelesaiSD);
      setNamaSekolahSMP(dataMappingPegawai?.namaSekolahSMP);
      setTahunMulaiSMP(dataMappingPegawai?.tahunMulaiSMP);
      setTahunSelesaiSMP(dataMappingPegawai?.tahunSelesaiSMP);
      setNamaSekolahSMA(dataMappingPegawai?.namaSekolahSMA);
      setTahunMulaiSMA(dataMappingPegawai?.tahunMulaiSMA);
      setTahunSelesaiSMA(dataMappingPegawai?.tahunSelesaiSMA);
      setDiploma(dataMappingPegawai?.diploma);
      setNamaUniversitasDiploma(dataMappingPegawai?.namaUniversitasDiploma);
      setTahunMulaiDiploma(dataMappingPegawai?.tahunMulaiDiploma);
      setTahunSelesaiDiploma(dataMappingPegawai?.tahunSelesaiDiploma);
      setNamaUniversitasSarjanaSatu(
        dataMappingPegawai?.namaUniversitasSarjanaSatu
      );
      setJurusanSarjanaSatu(dataMappingPegawai?.jurusanSarjanaSatu);
      setTahunMulaiSarjanaSatu(dataMappingPegawai?.tahunMulaiSarjanaSatu);
      setTahunSelesaiSarjanaSatu(dataMappingPegawai?.tahunSelesaiSarjanaSatu);
      setNamaUniversitasSarjanaDua(
        dataMappingPegawai?.namaUniversitasSarjanaDua
      );
      setJurusanSarjanaDua(dataMappingPegawai?.jurusanSarjanaDua);
      setTahunMulaiSarjanaDua(dataMappingPegawai?.tahunMulaiSarjanaDua);
      setTahunSelesaiSarjanaDua(dataMappingPegawai?.tahunSelesaiSarjanaDua);
      setNamaUniversitasSarjanaTiga(
        dataMappingPegawai?.namaUniversitasSarjanaTiga
      );
      setJurusanSarjanaTiga(dataMappingPegawai?.jurusanSarjanaTiga);
      setTahunMulaiSarjanaTiga(dataMappingPegawai?.tahunMulaiSarjanaTiga);
      setTahunSelesaiSarjanaTiga(dataMappingPegawai?.tahunSelesaiSarjanaTiga);
      setNamaOrgSatu(dataMappingPegawai?.namaOrgSatu);
      setJabatanOrgSatu(dataMappingPegawai?.jabatanOrgSatu);
      setTahunOrgSatu(dataMappingPegawai?.tahunOrgSatu);
      setNamaOrgDua(dataMappingPegawai?.namaOrgDua);
      setJabatanOrgDua(dataMappingPegawai?.jabatanOrgDua);
      setTahunOrgDua(dataMappingPegawai?.tahunOrgDua);
      setNamaOrgTiga(dataMappingPegawai?.namaOrgTiga);
      setJabatanOrgTiga(dataMappingPegawai?.jabatanOrgTiga);
      setTahunOrgTiga(dataMappingPegawai?.tahunOrgTiga);
      setNamaKerjaSatu(dataMappingPegawai?.namaKerjaSatu);
      setJabatanKerjaSatu(dataMappingPegawai?.jabatanKerjaSatu);
      setTahunKerjaSatu(dataMappingPegawai?.tahunKerjaSatu);
      setNamaKerjaDua(dataMappingPegawai?.namaKerjaDua);
      setJabatanKerjaDua(dataMappingPegawai?.jabatanKerjaDua);
      setTahunKerjaDua(dataMappingPegawai?.tahunKerjaDua);
      setNamaKerjaTiga(dataMappingPegawai?.namaKerjaTiga);
      setJabatanKerjaTiga(dataMappingPegawai?.jabatanKerjaTiga);
      setTahunKerjaTiga(dataMappingPegawai?.tahunKerjaTiga);
      setPerspektifOne(dataMappingPegawai?.perspektifOne);
      setPerspektifTwo(dataMappingPegawai?.perspektifTwo);
    }
  }, []);

  return (
    <DataDiriContainer>
      <SoalContainer
        isShowSelesaiModal={isShowSelesaiModal}
        setIsShowSelesaiModal={setIsShowSelesaiModal}
      >
        <DataDiriTitle title="DATA PESERTA TES" />

        <InputGroup
          label={
            <>
              Nomor Tes <span className="text-danger">*</span>(isi terserah jika
              tidak ada)
            </>
          }
          id="nomorTes"
          type="text"
          state={nomorTes}
          setState={setNomorTes}
          maxLength={25}
        />
        <InputGroup
          label={
            <>
              Kota Pelaksanaan Tes <span className="text-danger">*</span>
            </>
          }
          id="kotaPelaksanaanTes"
          type="text"
          state={kotaPelaksanaanTes}
          setState={setKotaPelaksanaanTes}
          maxLength={40}
        />
        <InputGroup
          label={
            <>
              Posisi yang Dituju <span className="text-danger">*</span>
            </>
          }
          id="posisiYangDituju"
          type="text"
          state={posisiYangDituju}
          setState={setPosisiYangDituju}
          maxLength={40}
        />
        <h2>1. Identitas Diri</h2>
        <DataDiriForm onSubmit={handleSubmit}>
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label={
                  <>
                    Nama Lengkap <span className="text-danger">*</span>
                  </>
                }
                id="namaLengkap"
                type="text"
                state={namaLengkap}
                setState={setNamaLengkap}
                maxLength={60}
              />

              <SelectGroup
                label={
                  <>
                    Jenis Kelamin <span className="text-danger">*</span>
                  </>
                }
                state={gender}
                setState={setGender}
                id="gender"
              >
                <option value={gender} disabled={gender}>
                  {gender === 'L'
                    ? 'Laki-Laki'
                    : gender === 'P'
                    ? 'Perempuan'
                    : ''}
                </option>
                <option value="L">Laki-laki</option>
                <option value="P">Perempuan</option>
              </SelectGroup>

              <InputGroup
                label={
                  <>
                    Jabatan <span className="text-danger">*</span>
                  </>
                }
                id="jabatan"
                type="text"
                state={jabatan}
                setState={setJabatan}
                maxLength={40}
              />

              <InputGroup
                label={
                  <>
                    Alamat Rumah <span className="text-danger">*</span>
                  </>
                }
                id="alamat"
                type="text"
                state={alamat}
                setState={setAlamat}
                maxLength={100}
              />

              <InputGroup
                label={
                  <>
                    Nomor Telepon <span className="text-danger">*</span>
                  </>
                }
                type="text"
                id="telepon"
                state={telepon}
                setState={setTelepon}
                maxLength={16}
              />
              <InputGroup
                label={
                  <>
                    Tempat Lahir <span className="text-danger">*</span>
                  </>
                }
                id="tempatLahir"
                type="text"
                state={tempatLahir}
                setState={setTempatLahir}
                maxLength={40}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label={
                  <>
                    Usia <span className="text-danger">*</span>
                  </>
                }
                type="number"
                id="usia"
                state={usia}
                setState={setUsia}
              />
              <SelectGroup
                label={
                  <>
                    Agama <span className="text-danger">*</span>
                  </>
                }
                state={agama}
                setState={setAgama}
                id="agama"
              >
                <option value={agama} disabled={agama}>
                  {agama === 'Islam'
                    ? 'Islam'
                    : agama === 'Kristen Protestan'
                    ? 'Kristen Protestan'
                    : agama === 'Kristen Katolik'
                    ? 'Kristen Katolik'
                    : agama === 'Hindu'
                    ? 'Hindu'
                    : agama === 'Buddha'
                    ? 'Buddha'
                    : agama === 'Konghucu'
                    ? 'Konghucu'
                    : agama === 'Lainnya'
                    ? 'Lainnya'
                    : ''}
                </option>
                <option value="Islam">Islam</option>
                <option value="Kristen Protestan">Kristen Protestan</option>
                <option value="Kristen Katolik">Kristen Katolik</option>
                <option value="Hindu">Hindu</option>
                <option value="Buddha">Buddha</option>
                <option value="Konghucu">Konghucu</option>
                <option value="Lainnya">Lainnya</option>
              </SelectGroup>

              <SelectGroup
                label={
                  <>
                    Status Perkawinan <span className="text-danger">*</span>
                  </>
                }
                state={statusPerkawinan}
                setState={setStatusPerkawinan}
                id="statusPerkawinan"
              >
                <option value={statusPerkawinan} disabled={statusPerkawinan}>
                  {statusPerkawinan === 'Lajang'
                    ? 'Lajang'
                    : statusPerkawinan === 'Menikah'
                    ? 'Menikah'
                    : statusPerkawinan === 'Kristen Katolik'
                    ? 'Kristen Katolik'
                    : statusPerkawinan === 'Janda'
                    ? 'Janda'
                    : statusPerkawinan === 'Duda'
                    ? 'Duda'
                    : ''}
                </option>
                <option value="Lajang">Lajang</option>
                <option value="Menikah">Menikah</option>
                <option value="Janda">Janda</option>
                <option value="Duda">Duda</option>
              </SelectGroup>

              <InputGroup
                label={
                  <>
                    Jumlah Anak <span className="text-danger">*</span>
                  </>
                }
                id="jumlahAnak"
                type="number"
                state={jumlahAnak}
                setState={setJumlahAnak}
                maxLength={11}
              />

              <InputGroup
                label={
                  <>
                    Bukti Identitas(Nomor NIK/KTP){' '}
                    <span className="text-danger">*</span>
                  </>
                }
                id="buktiIdentitas"
                type="text"
                state={buktiIdentitas}
                setState={setBuktiIdentitas}
                maxLength={25}
              />
              <InputGroup
                label={
                  <>
                    Tanggal Lahir <span className="text-danger">*</span>
                  </>
                }
                id="tanggalLahir"
                type="date"
                state={tanggalLahir}
                setState={setTanggalLahir}
              />
            </HalfSection>
          </SectionWrapper>
          <h2>B. Catatan Tentang Keluarga</h2>
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label={
                  <>
                    Nama Ayah <span className="text-danger">*</span>
                  </>
                }
                id="namaAyah"
                type="text"
                state={namaAyah}
                setState={setNamaAyah}
                maxLength={60}
              />
              <InputGroup
                label={
                  <>
                    Pekerjaan Ayah <span className="text-danger">*</span>
                  </>
                }
                id="pekerjaanAyah"
                type="text"
                state={pekerjaanAyah}
                setState={setPekerjaanAyah}
                maxLength={40}
              />
              <InputGroup
                label={
                  <>
                    Anak Ke <span className="text-danger">*</span>
                  </>
                }
                id="anakKe"
                type="text"
                state={anakKe}
                setState={setAnakKe}
                maxLength={20}
              />
              <InputGroup
                label={
                  <>
                    Dari Berapa Bersaudara{' '}
                    <span className="text-danger">*</span>
                  </>
                }
                id="berapaBersaudara"
                type="text"
                state={berapaBersaudara}
                setState={setBerapaBersaudara}
                maxLength={20}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label={
                  <>
                    Nama Ibu <span className="text-danger">*</span>
                  </>
                }
                id="namaIbu"
                type="text"
                state={namaIbu}
                setState={setNamaIbu}
                maxLength={60}
              />
              <InputGroup
                label={
                  <>
                    Pekerjaan Ibu <span className="text-danger">*</span>
                  </>
                }
                id="pekerjaanIbu"
                type="text"
                state={pekerjaanIbu}
                setState={setPekerjaanIbu}
                maxLength={40}
              />
              <InputGroup
                label={
                  <>
                    Alamat Orang Tua <span className="text-danger">*</span>
                  </>
                }
                id="alamatOrangTua"
                type="text"
                state={alamatOrangTua}
                setState={setAlamatOrangTua}
                maxLength={100}
              />
            </HalfSection>
          </SectionWrapper>
          <h2>C. Pendidikan</h2>
          <br />
          <h3>SD</h3>
          <br />

          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="Nama Sekolah"
                id="namaSekolahSD"
                type="text"
                required={false}
                state={namaSekolahSD}
                setState={setNamaSekolahSD}
                maxLength={60}
              />

              <InputGroup
                label="Tahun Mulai"
                id="tahunMulaiSD"
                type="text"
                required={false}
                state={tahunMulaiSD}
                setState={setTahunMulaiSD}
                maxLength={11}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Tahun Selesai"
                type="text"
                id="tahunSelesaiSD"
                required={false}
                state={tahunSelesaiSD}
                setState={setTahunSelesaiSD}
                maxLength={11}
              />
            </HalfSection>
          </SectionWrapper>
          <br />
          <h3>SMP</h3>
          <br />
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="Nama Sekolah"
                id="namaSekolahSMP"
                type="text"
                required={false}
                state={namaSekolahSMP}
                setState={setNamaSekolahSMP}
                maxLength={60}
              />

              <InputGroup
                label="Tahun Mulai"
                id="tahunMulaiSMP"
                type="text"
                required={false}
                state={tahunMulaiSMP}
                setState={setTahunMulaiSMP}
                maxLength={11}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Tahun Selesai"
                type="text"
                id="tahunSelesaiSMP"
                required={false}
                state={tahunSelesaiSMP}
                setState={setTahunSelesaiSMP}
                maxLength={11}
              />
            </HalfSection>
          </SectionWrapper>
          <br />
          <h3>SMA</h3>
          <br />
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="Nama Sekolah"
                id="namaSekolahSMA"
                type="text"
                required={false}
                state={namaSekolahSMA}
                setState={setNamaSekolahSMA}
                maxLength={60}
              />

              <InputGroup
                label="Tahun Mulai"
                id="tahunMulaiSMA"
                type="text"
                required={false}
                state={tahunMulaiSMA}
                setState={setTahunMulaiSMA}
                maxLength={11}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Tahun Selesai"
                type="text"
                id="tahunSelesaiSMA"
                required={false}
                state={tahunSelesaiSMA}
                setState={setTahunSelesaiSMA}
                maxLength={11}
              />
            </HalfSection>
          </SectionWrapper>

          <br />
          <h3>D1/D2/D3</h3>
          <br />
          <SectionWrapper>
            <HalfSection>
              <SelectGroup
                label="D1/D2/D3"
                required={false}
                state={diploma}
                setState={setDiploma}
                id="diploma"
              >
                <option value={diploma} disabled={diploma}>
                  {diploma === 'D1'
                    ? 'D1'
                    : diploma === 'D2'
                    ? 'D2'
                    : diploma === 'D3'
                    ? 'D3'
                    : ''}
                </option>
                <option value="D1">D1</option>
                <option value="D2">D2</option>
                <option value="D3">D3</option>
              </SelectGroup>
              <InputGroup
                label="Nama Universitas"
                id="namaUniversitasDiploma"
                required={false}
                type="text"
                state={namaUniversitasDiploma}
                setState={setNamaUniversitasDiploma}
                maxLength={60}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Tahun Mulai"
                id="tahunMulaiDiploma"
                required={false}
                type="text"
                state={tahunMulaiDiploma}
                setState={setTahunMulaiDiploma}
                maxLength={11}
              />
              <InputGroup
                label="Tahun Selesai"
                required={false}
                type="text"
                id="tahunSelesaiDiploma"
                state={tahunSelesaiDiploma}
                setState={setTahunSelesaiDiploma}
                maxLength={11}
              />
            </HalfSection>
          </SectionWrapper>
          <br />
          <h3>S1</h3>
          <br />
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="Fakultas - Jurusan"
                id="jurusanSarjanaSatu"
                required={false}
                type="text"
                state={jurusanSarjanaSatu}
                setState={setJurusanSarjanaSatu}
                maxLength={40}
              />

              <InputGroup
                label="Nama Universitas"
                id="namaUniversitasSarjanaSatu"
                required={false}
                type="text"
                state={namaUniversitasSarjanaSatu}
                setState={setNamaUniversitasSarjanaSatu}
                maxLength={60}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Tahun Mulai"
                id="tahunMulaiSarjanaSatu"
                required={false}
                type="text"
                state={tahunMulaiSarjanaSatu}
                setState={setTahunMulaiSarjanaSatu}
                maxLength={11}
              />
              <InputGroup
                label="Tahun Selesai"
                required={false}
                type="text"
                id="tahunSelesaiSarjanaSatu"
                state={tahunSelesaiSarjanaSatu}
                setState={setTahunSelesaiSarjanaSatu}
                maxLength={11}
              />
            </HalfSection>
          </SectionWrapper>
          <br />
          <h3>S2</h3>
          <br />
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="Fakultas - Jurusan"
                id="jurusanSarjanaDua"
                required={false}
                type="text"
                state={jurusanSarjanaDua}
                setState={setJurusanSarjanaDua}
                maxLength={40}
              />

              <InputGroup
                label="Nama Universitas"
                id="namaUniversitasSarjanaDua"
                required={false}
                type="text"
                state={namaUniversitasSarjanaDua}
                setState={setNamaUniversitasSarjanaDua}
                maxLength={60}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Tahun Mulai"
                id="tahunMulaiSarjanaDua"
                required={false}
                type="text"
                state={tahunMulaiSarjanaDua}
                setState={setTahunMulaiSarjanaDua}
                maxLength={11}
              />
              <InputGroup
                label="Tahun Selesai"
                required={false}
                type="text"
                id="tahunSelesaiSarjanaDua"
                state={tahunSelesaiSarjanaDua}
                setState={setTahunSelesaiSarjanaDua}
                maxLength={11}
              />
            </HalfSection>
          </SectionWrapper>
          <br />
          <h3>S3</h3>
          <br />
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="Fakultas - Jurusan"
                id="jurusanSarjanaTiga"
                required={false}
                type="text"
                state={jurusanSarjanaTiga}
                setState={setJurusanSarjanaTiga}
                maxLength={40}
              />

              <InputGroup
                label="Nama Universitas"
                id="namaUniversitasSarjanaTiga"
                required={false}
                type="text"
                state={namaUniversitasSarjanaTiga}
                setState={setNamaUniversitasSarjanaTiga}
                maxLength={60}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Tahun Mulai"
                id="tahunMulaiSarjanaTiga"
                required={false}
                type="text"
                state={tahunMulaiSarjanaTiga}
                setState={setTahunMulaiSarjanaTiga}
                maxLength={11}
              />
              <InputGroup
                label="Tahun Selesai"
                required={false}
                type="text"
                id="tahunSelesaiSarjanaTiga"
                state={tahunSelesaiSarjanaTiga}
                setState={setTahunSelesaiSarjanaTiga}
                maxLength={11}
              />
            </HalfSection>
          </SectionWrapper>

          <h2>4. Pengalaman Berorganisasi</h2>
          <h3>1</h3>
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="Nama Organisasi"
                id="namaOrgSatu"
                type="text"
                state={namaOrgSatu}
                setState={setNamaOrgSatu}
                maxLength={60}
                required={false}
              />

              <InputGroup
                label="Jabatan"
                id="jabatanOrgSatu"
                type="text"
                state={jabatanOrgSatu}
                setState={setJabatanOrgSatu}
                maxLength={40}
                required={false}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Tahun"
                type="text"
                id="tahunOrgSatu"
                state={tahunOrgSatu}
                setState={setTahunOrgSatu}
                maxLength={11}
                required={false}
              />
            </HalfSection>
          </SectionWrapper>
          <h3>2</h3>
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="Nama Organisasi"
                id="namaOrgDua"
                type="text"
                state={namaOrgDua}
                setState={setNamaOrgDua}
                maxLength={60}
                required={false}
              />

              <InputGroup
                label="Jabatan"
                id="jabatanOrgDua"
                type="text"
                state={jabatanOrgDua}
                setState={setJabatanOrgDua}
                maxLength={40}
                required={false}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Tahun"
                type="text"
                id="tahunOrgDua"
                state={tahunOrgDua}
                setState={setTahunOrgDua}
                maxLength={11}
                required={false}
              />
            </HalfSection>
          </SectionWrapper>
          <h3>3</h3>
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="Nama Organisasi"
                id="namaOrgTiga"
                type="text"
                state={namaOrgTiga}
                setState={setNamaOrgTiga}
                maxLength={60}
                required={false}
              />

              <InputGroup
                label="Jabatan"
                id="jabatanOrgTiga"
                type="text"
                state={jabatanOrgTiga}
                setState={setJabatanOrgTiga}
                maxLength={40}
                required={false}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Tahun"
                type="text"
                id="tahunOrgTiga"
                state={tahunOrgTiga}
                setState={setTahunOrgTiga}
                maxLength={11}
                required={false}
              />
            </HalfSection>
          </SectionWrapper>
          <h2>5. Pengalaman Kerja</h2>
          <h3>1</h3>
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="Nama Perusahaan"
                id="namaKerjaSatu"
                type="text"
                state={namaKerjaSatu}
                setState={setNamaKerjaSatu}
                maxLength={60}
                required={false}
              />

              <InputGroup
                label="Jabatan"
                id="jabatanKerjaSatu"
                type="text"
                state={jabatanKerjaSatu}
                setState={setJabatanKerjaSatu}
                maxLength={40}
                required={false}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Tahun"
                type="text"
                id="tahunKerjaSatu"
                state={tahunKerjaSatu}
                setState={setTahunKerjaSatu}
                maxLength={11}
                required={false}
              />
            </HalfSection>
          </SectionWrapper>
          <h3>2</h3>
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="Nama Perusahaan"
                id="namaKerjaDua"
                type="text"
                state={namaKerjaDua}
                setState={setNamaKerjaDua}
                maxLength={60}
                required={false}
              />

              <InputGroup
                label="Jabatan"
                id="jabatanKerjaDua"
                type="text"
                state={jabatanKerjaDua}
                setState={setJabatanKerjaDua}
                maxLength={40}
                required={false}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Tahun"
                type="text"
                id="tahunKerjaDua"
                state={tahunKerjaDua}
                setState={setTahunKerjaDua}
                maxLength={11}
                required={false}
              />
            </HalfSection>
          </SectionWrapper>
          <h3>3</h3>
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="Nama Perusahaan"
                id="namaKerjaTiga"
                type="text"
                state={namaKerjaTiga}
                setState={setNamaKerjaTiga}
                maxLength={60}
                required={false}
              />

              <InputGroup
                label="Jabatan"
                id="jabatanKerjaTiga"
                type="text"
                state={jabatanKerjaTiga}
                setState={setJabatanKerjaTiga}
                maxLength={40}
                required={false}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Tahun"
                type="text"
                id="tahunKerjaTiga"
                state={tahunKerjaTiga}
                setState={setTahunKerjaTiga}
                maxLength={11}
                required={false}
              />
            </HalfSection>
          </SectionWrapper>

          <h2>6. Perspektif Diri</h2>
          <TextAreaGroup
            label={
              <>
                Faktor-faktor yang Saudara rasakan sebagai kelebihan (mental
                psikologis) bagi Saudara :{' '}
                <span className="text-danger">*</span>
              </>
            }
            id="perspektifOne"
            state={perspektifOne}
            setState={setPerspektifOne}
          />
          <TextAreaGroup
            label={
              <>
                Faktor-faktor yang Saudara rasakan sebagai kelemahan (mental
                psikologis) bagi Saudara :{' '}
                <span className="text-danger">*</span>
              </>
            }
            id="perspektifTwo"
            state={perspektifTwo}
            setState={setPerspektifTwo}
          />

          <ButtonKirim />
        </DataDiriForm>
      </SoalContainer>
    </DataDiriContainer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    pesertaLogin: (
      peserta,
      currentTes,
      currentSoal,
      currentPetunjuk,
      jawabans
    ) =>
      dispatch(
        pesertaLogin(
          peserta,
          currentTes,
          currentSoal,
          currentPetunjuk,
          jawabans
        )
      ),
    changeCurrentSoal: (currentSoal) =>
      dispatch(changeCurrentSoal(currentSoal)),
    changeCurrentTes: (currentTes) => dispatch(changeCurrentTes(currentTes)),
    changeCurrentPetunjuk: (currentPetunjuk) =>
      dispatch(changeCurrentPetunjuk(currentPetunjuk)),
  };
};

const mapStateToProps = (state) => ({
  currentPeserta: state.peserta?.peserta,
});

export default connect(mapStateToProps, mapDispatchToProps)(DataDiriMap);
