import {
  faCog,
  faStar,
  faStarHalfAlt,
} from "@fortawesome/free-solid-svg-icons";
import Card from "./Card";
import styles from "./SectionTwelve.module.css";

const SectionTwelve = () => {
  return (
    <section className={styles.container}>
      <Card
        icon={faCog}
        title="Karakteristik Umum"
        values={[
          "Kualitas",
          "Ketepatan",
          "Rinci",
          "Standar",
          "Tinggi",
          "Cermat",
        ]}
      />
      <Card
        icon={faStar}
        title="Perilaku Pekerjaan (Kekuatan)"
        values={[
          "Senang pada detail",
          "Perfeksionis",
          "Pemikir",
          "Berbakat",
          "Senang pada rincian",
        ]}
      />
      <Card
        icon={faStarHalfAlt}
        title="Perilaku Pekerjaan (Kelemahan)"
        values={[
          "Terlalu banyak berfikir",
          "Ragu untuk menciptakan",
          "Terlalu banyak berpikir",
          "Mudah lelah",
        ]}
      />
    </section>
  );
};

export default SectionTwelve;
