import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import TopSection from "../TopSection";
import FilterProject from "./FilterProject";
import styles from "./ManajemenPesertaContent.module.css";
import Table from "./Table";

const ManajemenPesertaContent = ({ peserta }) => {
  const history = useHistory();

  const [projectTags, setProjectTags] = useState([]);
  const [data, setData] = useState([]);

  const columns = [
    {
      Header: "No.",
      accessor: "nomor",
    },
    {
      Header: "Kode Aktivasi",
      accessor: "kodeAktivasi",
    },
    {
      Header: "Nama Peserta",
      accessor: "namaPeserta",
    },
    {
      Header: "Email Peserta",
      accessor: "emailPeserta",
    },
    {
      Header: "Status Email",
      accessor: "statusEmail",
    },
    {
      Header: "Tanggal Mulai",
      accessor: "tanggalMulai",
    },
    {
      Header: "Tanggal Selesai",
      accessor: "tanggalSelesai",
    },
    {
      Header: "Riwayat Project",
      accessor: "riwayatProject",
    },
  ];

  useEffect(() => {
    let pesertaMatchWithTags = [...peserta];

    if (projectTags.length > 0) {
      pesertaMatchWithTags = [...peserta].filter((item) =>
        projectTags.includes(item.project.nama)
      );
    }

    const newPeserta =
      pesertaMatchWithTags?.map((item, index) => ({
        id: item.id,
        nomor: index + 1,
        kodeAktivasi: item.kode,
        namaPeserta: item.nama || "-",
        emailPeserta: item.email || "-",
        statusEmail: item.email ? "Dibuka" : "Ditutup",
        tanggalMulai: new Date(item.project.tglMulai).toLocaleString(),
        tanggalSelesai: new Date(item.project.tglSelesai).toLocaleString(),
        riwayatProject: "Detail",
        project_id: item.projectId
      })) || [];

    setData(newPeserta);
  }, [projectTags, peserta]);

  return (
    <>
      <div className={styles.container}>
        <TopSection />

        <div className={styles.mainSection}>
          <h5 className={styles.pageTitle}>
            <span className={styles.pathLink} onClick={() => history.push("/dashboard")}>
              Beranda
            </span>{" "}
            <span className={styles.pathNow}>/ Manajemen Peserta</span>
          </h5>

          <div className={styles.sectionBody}>
            <FilterProject setProjectTags={setProjectTags} />

            <Table data={data} columns={columns} />
          </div>
        </div>
      </div>
      <div className={styles.background}></div>
    </>
  );
};

export default ManajemenPesertaContent;
