import { useState } from "react";
import PetunjukLast from "../../Components/PetunjukLast";
import PetunjukContainer from "../../Containers/PetunjukContainer";
import PetunjukZero from "../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Perhatikan contoh soal untuk memahami soal yang akan Anda kerjakan.
        </p>
        <br />
        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        firstDesc="Lihat kotak, apabila garis kanan (warna biru) dibagi menjadi 10 bagian, maka garis kiri (warna merah) adalah setengahnya atau 5/10 , maka pilihlah jawaban yang benar yaitu 5/10 tersebut."
        kunci="5/10"
        next={nextHandler}
        previous={previousHandler}
        namaFile="ret0001.png"
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        firstDesc="Lihat garis bantu, dimana garis merah adalah 5/10 atau setengahnya garis biru. <b>Ingat</b>, kelak ketika mengerjakan soal tidak ada garis bantu, ini hanyalah petunjuk agar Anda mudah memahami persoalan."
        kunci="5/10"
        next={nextHandler}
        previous={previousHandler}
        namaFile="ret0002.png"
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        firstDesc="Kerjakan contoh berikut ini."
        kunci="6/10"
        next={nextHandler}
        previous={previousHandler}
        namaFile="ret0003.png"
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={4}
        firstDesc="Kerjakan contoh berikut ini."
        kunci="2/10"
        next={nextHandler}
        previous={previousHandler}
        namaFile="ret0005.png"
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={5}
        firstDesc="Kerjakan contoh berikut ini."
        kunci="7/10"
        next={nextHandler}
        previous={previousHandler}
        namaFile="ret0007.png"
      />
      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={6}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
