import { useState } from 'react';
import PetunjukLast from '../../../Components/PetunjukLast';
import PetunjukContainer from '../../../Containers/PetunjukContainer';
import styles from './Petunjuk.module.css';
import Footer from '../../../Components/PetunjukMiddle/Footer';

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <div
        className={styles.petunjukZero}
        style={{ display: index === 0 ? 'flex' : 'none' }}
      >
        <p>
          Anda adalah Evan, seorang karyawan yang telah menduduki posisi sebagai
          Marketing Manager selama 5 bulan di PT Pelayaran Talenta Indonesia
          (PTI). Saat ini, PTI memiliki dan mengoperasikan lebih dari 100
          (seratus) unit berbagai macam armada yang terdiri atas Crewboat,
          Anchor Handling Tug Supply vessel (AHTS), Platform Support Vessel
          (PSV), Utility Vessel & Specialized Vessel. Dengan dukungan armada
          diatas PTI mampu memberikan layanan dibidang penyewaan kapal,
          pekerjaan lepas pantai, logistik, Engineering Procurement Construction
          dan Training & Development. Saat ini PTI telah berkantor pusat di kota
          XX, memiliki 5 (lima) kantor wilayah & pemeliharaan, Training Center
          dan Afiliasi Perusahaan Galangan yang mampu memberikan Layanan yang
          Cakap dan Handal berbasis Safe, Reliable dan Efficient, dalam
          menyediakan Sustainable Total Marine Solution.
        </p>

        <p>
          Hari ini, Senin 9 Januari 20XY adalah hari pertama Anda kembali masuk
          kerja setelah 3 hari mengambil cuti untuk liburan bersama keluarga.
          Pada meja kerja Anda tersusun rapi beberapa memo dan dokumen kerja
          yang menunggu untuk direspon:
        </p>
        <p>Terdapat tiga tugas yang Anda harus lakukan:</p>
        <ol>
          <li>
            Memberikan perioritas terhadap semua memo / dokumen kerja yang
            masuk, kedalam tiga kategori:
            <ul>
              <li>High Priority</li>
              <li>Moderate Priority</li>
              <li>Low Priority</li>
            </ul>
          </li>
          <li>
            Menuliskan secara spesifik Rencana Tindakan yang akan dilakukan
            untuk merespon setiap memo dan dokumen kerja.
          </li>
          <li>
            Menulis rencana kerja Program Pengembangan Staff. Detil mengenai
            tugas ini disajikan pada memo terakhir atau memo no.8
          </li>
        </ol>

        <p>
          Waktu keseluruhan yang Anda perlukan untuk melakukan semua tugas
          tersebut adalah 60 menit.
        </p>
        <div className={styles.buttonWrapper}>
          <button onClick={nextHandler}>Tutup</button>
        </div>
      </div>

      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 1 ? 'flex' : 'none' }}
      >
        <b>
          <p>Struktur Organisasi :</p>
        </b>
        <br />
        <center>
          <img
            src={`${process.env.REACT_APP_SERVER_URL}/in-basket/marketing-manager/struktur_organisasi_marketing_manager.png`}
            style={{ width: '80%' }}
          />
        </center>
        <br />
        <br />
        <Footer previous={previousHandler} next={nextHandler} condition />
      </div>

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={2}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
