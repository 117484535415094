import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihan = [
    ["a. besar", "b. luas", "c. kering", "d. lambat"],
    ["a. bosan", "b. setia", "c. kuno", "d. khianat"],
    ["a. halus", "b. betul", "c. salah", "d. sama"],
    ["a. buka", "b. jatuh", "c. mula", "d. akhir"],
    ["a. canggung", "b. kecil", "c. jenaka", "d. lucu"],
  ];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Pada subtes ini, Anda diminta untuk menemukan dua kata yang memiliki
          arti paling sama/ hampir sama atau paling berlawanan artinya.
        </p>
        <br />
        <p>
          Perhatikan contoh soal untuk memahami soal yang akan Anda kerjakan.
        </p>
        <br />
        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>
        <br />

        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        arrPilihan={arrPilihan[0]}
        kunci={3}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="<i>Besar</i> dan <i>luas</i> mempunyai arti yang <u>sama</u>.<br>Oleh karena itu, <b>opsi a</b> dan <b>b</b> dapat dipilih untuk contoh 1."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        arrPilihan={arrPilihan[1]}
        kunci={10}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="<i>Setia</i> dan <i>khianat</i> mempunyai arti yang <u>berlawanan</u>.<br>Oleh karena itu, <b>opsi b</b> dan <b>d</b> dapat dipilih untuk contoh 2."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        arrPilihan={arrPilihan[2]}
        kunci={6}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban anda benar!"
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={4}
        arrPilihan={arrPilihan[3]}
        kunci={12}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban anda benar!"
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={5}
        arrPilihan={arrPilihan[4]}
        kunci={12}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban anda benar!"
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={6}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
