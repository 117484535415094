export default function SubColumn1CFIT3BRS({ format }) {
  return (
    <>
      <th rowSpan={format.rowSpanValue}>1</th>
      <th rowSpan={format.rowSpanValue}>2</th>
      <th rowSpan={format.rowSpanValue}>3</th>
      <th rowSpan={format.rowSpanValue}>4</th>
    </>
  );
}
