import React from 'react';

const HeaderSecond = () => {
  return (
    <React.Fragment>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        IQ
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        KLASIFIKASI
      </th>
      <th colSpan="4" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        WS
      </th>
    </React.Fragment>
  );
};

export default HeaderSecond;
