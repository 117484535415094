import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ubahFormat } from "../../../utils/tanggal";
import TopSection from "../../TopSection";
import styles from "./RiwayatKuotaContentAdmin.module.css";
import Table from "./Table";

const RiwayatKuotaContentAdmin = ({ riwayatKuota }) => {
  const history = useHistory();

  const columns = [
    {
      Header: "No.",
      accessor: "nomor",
    },
    {
      Header: "Tanggal",
      accessor: "tanggal",
    },
    {
      Header: "Nama Perusahaan",
      accessor: "namaPerusahaan",
    },
    {
      Header: "Kuota",
      accessor: "kuota",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(
      riwayatKuota?.map((item, index) => ({
        id: item.id,
        tanggal: ubahFormat(item.tglTopUp),
        namaPerusahaan: item?.client?.nama,
        kuota: item.total,
        status: item.approve ? "Selesai" : "Belum",
        action: item.approve ? "Selesai" : "Belum",
        nomor: index + 1,
      }))
    );

    // const dataWithNumber = data.;
  }, [riwayatKuota]);

  // const data =

  return (
    <>
      <div className={styles.container}>
        <TopSection />

        <div className={styles.mainSection}>
          <h5 className={styles.pageTitle}>
            <span className={styles.pathLink} onClick={() => history.push("/")}>
              Beranda
            </span>{" "}
            <span className={styles.pathNow}>
              / Perusahaan / Riwayat Penambahan Kuota
            </span>
          </h5>

          <div className={styles.sectionBody}>
            <h1 className={styles.title}>Riwayat Penambahan Kuota</h1>
            <Table data={data} columns={columns} setData={setData} />
          </div>
        </div>
      </div>
      <div className={styles.background}></div>
    </>
  );
};

export default RiwayatKuotaContentAdmin;
