import React from 'react';

export default function TBodyRows({ datas }) {
  // console.log({ datas });
  datas.cfit3b = datas.cfit3b.reverse();
  return (
    <>
      {/* DATA DIRI EDU */}
      {datas.pesertas.length &&
        datas.pesertas?.map((peserta, indexPeserta) => {
          let selfAssesment = JSON.parse(peserta.selfAssessmentSingkat);
          // console.log(datas.kostick[indexPeserta]);
          let tglMulai = peserta?.project?.tglMulai;
          return (
            <tr key={indexPeserta}>
              <td>{indexPeserta + 1}</td>
              <td>{tglMulai}</td>
              <td>{peserta?.kode ? peserta.kode : '-'}</td>
              <td>{peserta?.nama ? peserta.nama : '-'}</td>
              <td>{peserta?.tmptLahir ? peserta.tmptLahir : '-'}</td>
              <td>{peserta?.posisi ? peserta.posisi : '-'}</td>
              <td>{peserta?.usia ? peserta.usia : '-'}</td>
              <td>{peserta?.gender ? peserta.gender : '-'}</td>
              <td>{peserta?.pendidikan ? peserta.pendidikan : '-'}</td>
              <td>{selfAssesment ? selfAssesment.assessmentOne : '-'}</td>
              <td>{selfAssesment ? selfAssesment.assessmentTwo : '-'}</td>
              <td>{selfAssesment ? selfAssesment.assessmentThree : '-'}</td>
              <td>{selfAssesment ? selfAssesment.assessmentFour : '-'}</td>
              <td>{selfAssesment ? selfAssesment.assessmentFive : '-'}</td>
              <td>{selfAssesment ? selfAssesment.assessmentSix : '-'}</td>

              {/* CFIT3B (WS) */}
              {datas.cfit3b?.length ? (
                <>
                  <td>
                    {datas.cfit3b[indexPeserta]?.[0]?.[0]?.rs
                      ? datas.cfit3b[indexPeserta]?.[0]?.[0].rs
                      : '-'}
                  </td>
                  <td>
                    {datas.cfit3b[indexPeserta]?.[0]?.[1]?.rs
                      ? datas.cfit3b[indexPeserta]?.[0]?.[1].rs
                      : '-'}
                  </td>
                  <td>
                    {datas.cfit3b[indexPeserta]?.[0]?.[2]?.rs
                      ? datas.cfit3b[indexPeserta]?.[0]?.[2].rs
                      : '-'}
                  </td>
                  <td>
                    {datas.cfit3b[indexPeserta]?.[0]?.[3]?.rs
                      ? datas.cfit3b[indexPeserta]?.[0]?.[3].rs
                      : '-'}
                  </td>
                  <td>
                    {datas.cfit3b[indexPeserta]?.[0]?.[indexPeserta]?.iq
                      ? datas.cfit3b[indexPeserta]?.[0]?.[indexPeserta].iq
                      : '-'}
                  </td>

                  {datas.cfit3b[indexPeserta]?.[0]?.[indexPeserta]?.iq < 70 ? (
                    <td>Mentally Defective</td>
                  ) : datas.cfit3b[indexPeserta]?.[0]?.[indexPeserta]?.iq <
                    81 ? (
                    <td>Borderline</td>
                  ) : datas.cfit3b[indexPeserta]?.[0]?.[indexPeserta]?.iq <
                    113 ? (
                    <td>Average</td>
                  ) : datas.cfit3b[indexPeserta]?.[0]?.[indexPeserta]?.iq <
                    121 ? (
                    <td>High Average</td>
                  ) : datas.cfit3b[indexPeserta]?.[0]?.[indexPeserta]?.iq >=
                    121 ? (
                    <td>Superior</td>
                  ) : (
                    <td>-</td>
                  )}
                </>
              ) : (
                <>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </>
              )}

              {/* KRAEPELIN */}
              {datas.kraepelin?.length ? (
                <>
                  <td>
                    {datas.kraepelin[indexPeserta]?.[0]?.benar
                      ? datas.kraepelin[indexPeserta]?.[0].benar
                      : '-'}
                  </td>
                  <td>
                    {datas.kraepelin[indexPeserta]?.[0]?.kategoriBenar
                      ? datas.kraepelin[indexPeserta]?.[0].kategoriBenar
                      : '-'}
                  </td>
                  <td>
                    {datas.kraepelin[indexPeserta]?.[0]?.salah
                      ? datas.kraepelin[indexPeserta]?.[0].salah
                      : '-'}
                  </td>
                  <td>
                    {datas.kraepelin[indexPeserta]?.[0]?.kategoriTeliti
                      ? datas.kraepelin[indexPeserta]?.[0].kategoriTeliti
                      : '-'}
                  </td>
                  <td>
                    {datas.kraepelin[indexPeserta]?.[0]?.averageD
                      ? datas.kraepelin[indexPeserta]?.[0].averageD
                      : '-'}
                  </td>
                  <td>
                    {datas.kraepelin[indexPeserta]?.[0]?.kategoriKonsistensi
                      ? datas.kraepelin[indexPeserta]?.[0].kategoriKonsistensi
                      : '-'}
                  </td>
                </>
              ) : (
                <>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </>
              )}

              {/* DISC */}
              <td>
                {datas?.disc[indexPeserta]?.[0].most
                  ? datas.disc[indexPeserta]?.[0].most
                  : '-'}
              </td>
              <td>
                {datas?.disc[indexPeserta]?.[0].least
                  ? datas.disc[indexPeserta]?.[0].least
                  : '-'}
              </td>
              <td>
                {datas?.disc[indexPeserta]?.[0].change
                  ? datas.disc[indexPeserta]?.[0].change
                  : '-'}
              </td>

              {/* KOSTICK */}
              {datas.kostick?.length ? (
                <>
                  <td>
                    {datas.kostick[indexPeserta]?.[0]?.nilai?.N
                      ? datas.kostick[indexPeserta]?.[0].nilai.N
                      : '-'}
                  </td>
                  <td>
                    {datas.kostick[indexPeserta]?.[0]?.nilai?.G
                      ? datas.kostick[indexPeserta]?.[0].nilai.G
                      : '-'}
                  </td>
                  <td>
                    {datas.kostick[indexPeserta]?.[0]?.nilai?.A
                      ? datas.kostick[indexPeserta]?.[0].nilai.A
                      : '-'}
                  </td>
                  <td>
                    {datas.kostick[indexPeserta]?.[0]?.nilai?.L
                      ? datas.kostick[indexPeserta]?.[0].nilai.L
                      : '-'}
                  </td>
                  <td>
                    {datas.kostick[indexPeserta]?.[0]?.nilai?.P
                      ? datas.kostick[indexPeserta]?.[0].nilai.P
                      : '-'}
                  </td>
                  <td>
                    {datas.kostick[indexPeserta]?.[0]?.nilai?.I
                      ? datas.kostick[indexPeserta]?.[0].nilai.I
                      : '-'}
                  </td>
                  <td>
                    {datas.kostick[indexPeserta]?.[0]?.nilai?.T
                      ? datas.kostick[indexPeserta]?.[0].nilai.T
                      : '-'}
                  </td>
                  <td>
                    {datas.kostick[indexPeserta]?.[0]?.nilai?.V
                      ? datas.kostick[indexPeserta]?.[0].nilai.V
                      : '-'}
                  </td>
                  <td>
                    {datas.kostick[indexPeserta]?.[0]?.nilai?.X
                      ? datas.kostick[indexPeserta]?.[0].nilai.X
                      : '-'}
                  </td>
                  <td>
                    {datas.kostick[indexPeserta]?.[0]?.nilai?.S
                      ? datas.kostick[indexPeserta]?.[0].nilai.S
                      : '-'}
                  </td>
                  <td>
                    {datas.kostick[indexPeserta]?.[0]?.nilai?.B
                      ? datas.kostick[indexPeserta]?.[0].nilai.B
                      : '-'}
                  </td>
                  <td>
                    {datas.kostick[indexPeserta]?.[0]?.nilai?.O
                      ? datas.kostick[indexPeserta]?.[0].nilai.O
                      : '-'}
                  </td>
                  <td>
                    {datas.kostick[indexPeserta]?.[0]?.nilai?.R
                      ? datas.kostick[indexPeserta]?.[0].nilai.R
                      : '-'}
                  </td>
                  <td>
                    {datas.kostick[indexPeserta]?.[0]?.nilai?.D
                      ? datas.kostick[indexPeserta]?.[0].nilai.D
                      : '-'}
                  </td>
                  <td>
                    {datas.kostick[indexPeserta]?.[0]?.nilai?.C
                      ? datas.kostick[indexPeserta]?.[0].nilai.C
                      : '-'}
                  </td>
                  <td>
                    {datas.kostick[indexPeserta]?.[0]?.nilai?.Z
                      ? datas.kostick[indexPeserta]?.[0].nilai.Z
                      : '-'}
                  </td>
                  <td>
                    {datas.kostick[indexPeserta]?.[0]?.nilai?.E
                      ? datas.kostick[indexPeserta]?.[0].nilai.E
                      : '-'}
                  </td>
                  <td>
                    {datas.kostick[indexPeserta]?.[0]?.nilai?.K
                      ? datas.kostick[indexPeserta]?.[0].nilai.K
                      : '-'}
                  </td>
                  <td>
                    {datas.kostick[indexPeserta]?.[0]?.nilai?.F
                      ? datas.kostick[indexPeserta]?.[0].nilai.F
                      : '-'}
                  </td>
                  <td>
                    {datas.kostick[indexPeserta]?.[0]?.nilai?.W
                      ? datas.kostick[indexPeserta]?.[0].nilai.W
                      : '-'}
                  </td>
                </>
              ) : (
                <>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </>
              )}
            </tr>
          );
        })}

      {/* DATA DIRI EDU <end> */}
    </>
  );
}
