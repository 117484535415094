import { API } from 'services/API';

export const createTest = async (data) => {
  try {
    const response = await API.post('/tes', data);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const createQuestion = async (data) => {
  try {
    const response = await API.post('/soal', data);
    return response.data;
  } catch (error) {
    return error.response;
  }
};
