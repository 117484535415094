import KonfigurasiContent from "../../../components/PerusahaanClient/KonfigurasiContent";
import SideBar from "../../../components/SideBar";
import styles from "./Konfigurasi.module.css";

const Konfigurasi = () => {
  return (
    <div
      className={styles.container}
    >
      <SideBar />
      <KonfigurasiContent />
    </div>
  );
};

export default Konfigurasi;
