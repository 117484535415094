// ** React
import React, { useEffect, useState } from 'react';

// ** Thirdparty
import { useParams, useLocation } from 'react-router-dom';
import { API } from 'services/API';
import { Spinner } from 'react-bootstrap';

// ** Table
import Table from './Table';

const getTableFormat = {
  standar: [
    'personal_info',
    'self_assessment',
    'tiki',
    'cfit3b',
    'kraepelin',
    'disc',
    'kostick',
    'holland',
  ],
  egatek: ['personal_info', 'tiki', 'cfit3b', 'kraepelin'],
  taf: ['personal_info', 'tiki', 'kraepelin'],
  lppi: ['personal_info', 'self_assessment', 'tiki', 'kraepelin', 'kostick', 'disc'],
  edu: ['personal_info', 'self_assessment', 'cfit3b', 'kraepelin', 'disc', 'kostick'],
  komatsu: ['personal_info', 'ist', 'kraepelin', 'disc'],
};

const getAllData = async (params) => {
  try {
    const response = await API.get(`/report-excel`, { params });
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const getProject = async (project_id) => {
  try {
    const response = await API.get(`/project/${project_id}`);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const ExcelReport = () => {
  // ** State
  const [data, setData] = useState(null);
  const [project, setProject] = useState(null);
  const [format, setFormat] = useState(getTableFormat['standar']);

  // ** Hooks
  const { project_id, tbl_type } = useParams();

  const custom_format = [
    'default',
    'personal_info',
    'self_assessment',
    'disc',
    'kostick',
    'cfit3b',
    'tiki',
    'kraepelin',
    'holland',
  ];

  const getCustomFormat = (arr = []) => {
    const searchParams = new URLSearchParams(window.location.search);
    const default_key = searchParams.get('default');

    if (default_key === 'false') {
      const filter_format = arr.filter((key) => {
        if (key !== 'default' && searchParams.get(key) === 'true') {
          return key;
        }
      });

      if (filter_format.length) {
        return setFormat(filter_format);
      }

      return setFormat(getTableFormat[tbl_type]);
    }

    setFormat(getTableFormat[tbl_type]);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const start_date = searchParams.get('startDate');
    const end_date = searchParams.get('endDate');

    getAllData({ project_id, start_date, end_date }).then((res) => {
      if (res.status) {
        setData(res.data);
      }
    });

    getProject(project_id).then((res) => {
      if (res.status === 'OK') {
        setProject(res.data);
      }
    });

    getCustomFormat(custom_format);
  }, []);

  return (
    <React.Fragment>
      {data === null ? (
        <div
          className="d-flex h-100 w-100 align-items-center justify-content-center"
          style={{ minHeight: '100vh' }}
        >
          <Spinner variant="primary" animation="border" />
        </div>
      ) : (
        <Table type={tbl_type} format={format} data={data} project={project} />
      )}
    </React.Fragment>
  );
};

export default ExcelReport;
