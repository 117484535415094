import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Card.module.css";

const Card = ({ icon, title, values }) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <div className={styles.titleIconWrapper}>
          <FontAwesomeIcon icon={icon} size="2x" />
        </div>
        <h3 className={styles.title}>{title}</h3>
      </div>

      <ul>
        {values?.map((item) => (
          <li>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default Card;
