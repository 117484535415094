import styles from "./PetunjukModal.module.css";

const PetunjukModal = ({ isShow, onHide, children }) => {
  return (
    <>
      {isShow && (
        <>
          <div className={styles.container}>{children}</div>
          <div className={styles.background} onClick={onHide} />
        </>
      )}
    </>
  );
};

export default PetunjukModal;
