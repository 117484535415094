import { useState } from "react";
import Footer from "../../../../../Components/PetunjukMiddle/Footer";
import Salah from "../../../../../Components/PetunjukMiddle/Salah";
import Benar from "../../../../../Components/PetunjukMiddle/Benar";
import PetunjukMiddleContainer from "../../../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import PetunjukParagraph from "../../../../../Components/PetunjukMiddle/PetunjukParagraph";
import PilihanWrapper from "../../../../../Components/PilihanWrapper";
import SoalImage from "../../../../../Components/SoalImage";
import ButtonJawab from "../../../../../Components/PetunjukMiddle/ButtonJawab";
import CheckboxText from "../../../../../Components/PetunjukMiddle/CheckboxText";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  namaFile,
  kunci,
  next,
  previous,
  pesanBenar,
}) => {
  const [answer, setAnswer] = useState(0);
  const [statusAnswer, setStatusAnswer] = useState(-1);

  const arrPilihan = [
    { label: "a", point: 1 },
    { label: "b", point: 2 },
    { label: "c", point: 4 },
    { label: "d", point: 8 },
    { label: "e", point: 16 },
  ];

  return (
    <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
      <PetunjukParagraph>
        {
          "Terdapat 5 kotak masing-masing dengan 5 pola. <strong>Terdapat dua (2) pola yang memiliki kecenderungan berbeda</strong> dibandingkan dengan lainnya (3 kotak yang lain). <strong>Pilih kedua jawaban tersebut</strong> dengan menekan alternatif jawaban a, b, c, d atau e."
        }
      </PetunjukParagraph>
      <SoalImage src={`/images/tes/cfit/cfitkpk/cfita2kpk/${namaFile}`} />

      <PilihanWrapper variant="text" col={2}>
        {arrPilihan.map((item, index) => (
          <CheckboxText
            key={index}
            label={item?.label}
            answer={answer}
            setAnswer={setAnswer}
            point={item?.point}
            setStatus={setStatusAnswer}
          />
        ))}
        <ButtonJawab
          answer={answer}
          setStatusAnswer={setStatusAnswer}
          kunci={kunci}
        />
      </PilihanWrapper>

      <Benar isShow={statusAnswer === 1} htmlString={pesanBenar} />
      <Salah isShow={statusAnswer === 0} />
      <Footer
        min={2}
        max={5}
        previous={previous}
        next={next}
        condition={statusAnswer === 1}
      />
    </PetunjukMiddleContainer>
  );
};

export default PetunjukMiddle;
