import React from 'react';
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ActionUnduh.module.css';
import Rodal from 'rodal';
import FileDownload from 'js-file-download';
import * as RB from 'react-bootstrap';
import { useState } from 'react';
import ErrorModal from '../../../../ErrorModal';
import { API } from '../../../../../services/API';
// import Dropdown from "./Dropdown";
// import { PDFDownloadLink } from "@react-pdf/renderer";
// import PdfDownload from "../../../../../pages/PdfDownload";

const UnduhPDF2 = ({ cell, row }) => {
  const [error, setError] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [formatLaporanPDF, setFormatLaporanPDF] = useState('STANDAR');
  const listFormatLaporan = ['STANDAR', 'KALBE LENGKAP', 'TAF'];

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const today = new Date().toLocaleDateString('pt-PT').split('/').join('-');

  let namaPeserta = row.original.namaPeserta.split(',,,')[0];
  const fileName = `${row.original.projectId}_${namaPeserta ? namaPeserta : 'xxx'}_${today}.pdf`;

  const download = async () => {
    setIsShowModal(false);
    setIsLoading(true);
    await API.get(`/laporan-pdf`, {
      responseType: 'blob',
      params: {
        pesertaId: row.original.id,
        isLaporanUmum: row.original.projectDetail.isLaporanUmum,
        forZip: 0,
        formatLaporanPDF: formatLaporanPDF,
      },
    })
      .then((res) => {
        setIsLoading(false);
        FileDownload(res.data, fileName);
      })
      .catch(async (err) => {
        let data = await err.response.data.text();
        data = JSON.parse(data);
        setIsLoading(false);
        let detailErrMessage = data.message;
        setError(true);
        setErrorMessage(
          <div>
            Gagal download semua laporan, silahkan cek kembali semua data peserta!
            {detailErrMessage && (
              <>
                <br />
                <span className="text-danger">Error Detail: {detailErrMessage}</span>
              </>
            )}
          </div>
        );
      });
  };

  const closeModal = () => {
    setIsShowModal(false);
    setError(false);
  };

  return (
    <div className={styles.wrapper}>
      <Rodal visible={isShowModal} animation="zoom" onClose={closeModal}>
        <RB.Container className="mt-3">
          <RB.Row>
            <RB.Col xs={12}>
              <h5>
                Pilih format laporan PDF: <span className="text-success">{formatLaporanPDF}</span>
              </h5>
              <RB.Dropdown>
                <RB.Dropdown.Toggle variant="success" id="dropdown-basic" className="w-100">
                  PILIH
                </RB.Dropdown.Toggle>

                <RB.Dropdown.Menu className="w-100">
                  {listFormatLaporan.map((l, i) => (
                    <React.Fragment key={i}>
                      <RB.Dropdown.Item onClick={() => setFormatLaporanPDF(l)}>
                        {l}
                      </RB.Dropdown.Item>
                    </React.Fragment>
                  ))}
                </RB.Dropdown.Menu>
              </RB.Dropdown>
            </RB.Col>
            <RB.Col className="mt-3">
              {/* <button className={styles.card}>Coming Soon</button> */}
              <button className={styles.card} onClick={download}>
                Ok
              </button>
            </RB.Col>
          </RB.Row>
        </RB.Container>
      </Rodal>
      <button className={styles.laporanButton} onClick={() => setIsShowModal(true)}>
        <span>Download</span>
        {isLoading ? (
          <RB.Spinner animation="border" variant="info" size="sm" />
        ) : (
          <span>
            <FontAwesomeIcon icon={faArrowCircleDown} />
          </span>
        )}
      </button>

      {error && <ErrorModal visible={error} message={errorMessage} onClose={closeModal} />}

      {/* <Dropdown
        isShow={isShowModal}
        onHide={() => setIsShowModal(false)}
        userId={row.original.id}
      /> */}
    </div>
  );
};

export default UnduhPDF2;
