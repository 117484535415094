import { useEffect } from "react";
import { connect } from "react-redux";
import { mapDispatchToPropsTes, mapStateToPropsTes } from "../Utils/reduxMap";
import Petunjuk from "./Petunjuk";
import Soal from "./Soal";

const TeamRoleBelbin = ({ currentPetunjuk, currentTes, replaceJawabans }) => {
  useEffect(() => {
    const psikotesLS = JSON.parse(localStorage.getItem("psikotesTalenta"));

    let isTesExist = false;

    psikotesLS.jawabans?.forEach((jawaban) => {
      if (jawaban.tesId === psikotesLS.currentTes.tesId) {
        isTesExist = true;
      }
    });

    if (!isTesExist) {
      psikotesLS.currentTes.tes.soals.forEach((soal) => {
        const defaultAnswer = soal?.pilihan?.map((item) => {
          return {
            pilihan: item,
            bobot: 0,
          };
        });

        psikotesLS.jawabans.push({
          pesertaId: psikotesLS.peserta.id,
          tesId: psikotesLS.currentTes.tesId,
          soalId: soal.id,
          nomor: soal.nomor,
          jawaban: defaultAnswer,
        });
      });
    }

    localStorage.setItem("psikotesTalenta", JSON.stringify(psikotesLS));
    replaceJawabans(psikotesLS.jawabans);
  }, [replaceJawabans]);

  return (
    <>
      {currentPetunjuk === 85 && <Petunjuk />}
      {currentPetunjuk !== 85 && currentTes.tesId === 85 && <Soal />}
    </>
  );
};

export default connect(
  mapStateToPropsTes,
  mapDispatchToPropsTes
)(TeamRoleBelbin);
