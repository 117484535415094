import LaporanPesertaContent from "../../components/LaporanPesertaContent";
import SideBar from "../../components/SideBar";
import styles from "./LaporanPeserta.module.css";

const LaporanPeserta = () => {
  return (
    <div className={styles.container}>
      <SideBar />
      <LaporanPesertaContent />
    </div>
  );
};

export default LaporanPeserta;
