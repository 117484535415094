import React from 'react';

import { isObjectEmpty, getEmptyData } from 'utility/Utils';

const Column = ({ data }) => {
  if (isObjectEmpty(data)) return getEmptyData(21);

  return (
    <React.Fragment>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.rsSe}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.wsSe}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.rsWa}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.wsWa}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.rsAn}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.wsAn}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.rsGe}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.wsGe}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.rsMe}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.wsMe}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.rsRa}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.wsRa}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.rsZr}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.wsZr}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.rsFa}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.wsFa}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.rsWu}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.wsWu}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.totalRs}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.iq}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.statusIq}</td>
    </React.Fragment>
  );
};

export default Column;
