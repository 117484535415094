import Left from "./Left";
import Right from "./Right";
import styles from "./SectionFour.module.css";

const SectionFour = () => {
  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <Left />
        <Right />
      </div>
      <div className={styles.blueLines}></div>
    </div>
  );
};

export default SectionFour;
