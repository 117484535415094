import * as actionTypes from './user-types';

const INITIAL_STATE = {
  user: false,
  isLogin: false,
  isAdmin: false,
  isClientSekolah: false,
  searchProjectInput: '',
  listCountProject: 25,
  orderListCountProject: [25, 50, 100],
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
      return {
        ...state,
        user: action.payload.user,
        isAdmin: action.payload.isAdmin,
        isClientSekolah: action.payload.isClientSekolah,
        isLogin: true,
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        user: false,
        isAdmin: false,
        isLogin: false,
        isClientSekolah: false,
      };
    case actionTypes.SEARCH_PROJECT:
      return {
        ...state,
        searchProjectInput: action.payload.projectName,
      };
    case actionTypes.CHANGE_ORDER_LIST_COUNT:
      return {
        ...state,
        listCountProject: action.payload.listCountProject,
      };
    default:
      return state;
  }
};

export default userReducer;
