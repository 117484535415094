import styles from "./ButtonJawab.module.css";

const ButtonJawab = ({ answer, setStatusAnswer, kunci }) => {
  const statusAnswerHandler = (state, setState, kunci) => {
    if (state === kunci) {
      setState(1);
    } else {
      setState(0);
    }
  };

  return (
    <button
      className={styles.buttonJawab}
      onClick={() => statusAnswerHandler(answer, setStatusAnswer, kunci)}
    >
      Jawab
    </button>
  );
};

export default ButtonJawab;
