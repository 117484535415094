import styles from "./Table.module.css";
import cn from "classnames";
import { matchSorter } from "match-sorter";
import { useMemo } from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import Pagination from "./Pagination";
import TableHeader from "./TableHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function Table({ columns, data }) {
  const history = useHistory();

  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: { pageSize: 6 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination,
    useRowSelect
  );

  // Render the UI for your table
  return (
    <>
      <TableHeader
        pageSize={pageSize}
        setPageSize={setPageSize}
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilterState={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />

      <div className={styles.overflowX}>
      <table {...getTableProps()} className={styles.table}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className={styles.tableHeader}
            >
              {headerGroup.headers.map((column, index) => {
                if (index === 1) {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={styles.headerName}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  );
                }
                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={styles.rowItem}>
                {row.cells.map((cell, index) => {
                  switch (index) {
                    case 1:
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={cn(styles.cellItem, styles.cellName)}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    case 4:
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={cn(styles.cellItem, styles.cellName)}
                        >
                          <p
                            className={styles.statusEmail}
                            style={
                              cell.value === "Ditutup"
                                ? {
                                    backgroundColor: "#FF2C5C",
                                    color: "#fff",
                                  }
                                : null
                            }
                          >
                            {cell.value}
                          </p>
                        </td>
                      );

                    case 7:
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={styles.cellItem}
                        >
                          <button
                            className={styles.detailButton}
                            onClick={() =>
                              history.push(
                                `/project/detail/${row.original.project_id}`
                              )
                            }
                          >
                            {cell.value}
                            <span>
                              <FontAwesomeIcon icon={faArrowRight} />
                            </span>
                          </button>
                        </td>
                      );
                    default:
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={styles.cellItem}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <Pagination
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
      />
      </div>
    </>
  );
}

export default Table;
