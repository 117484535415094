export default function SubColumn2TikiLPPI({ format }) {
  return (
    <>
      <th>1</th>
      <th>2</th>
      <th>3</th>
      <th>4</th>
    </>
  );
}
