import { combineReducers } from 'redux';
import pesertaReducer from './Peserta/peserta-reducer';
import userReducer from './User/user-reducer';
import projectSlice from 'components/ProjectDetailContent/store';

const rootReducer = combineReducers({
  user: userReducer,
  peserta: pesertaReducer,
  project: projectSlice,
});

export default rootReducer;
