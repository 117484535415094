export default function SubColumn1KraepelinEDU({ format }) {
  return (
    <>
      <th rowSpan={format.rowSpanValue}>PRESTASI</th>
      <th rowSpan={format.rowSpanValue}>KATEGORI PRESTASI</th>
      <th rowSpan={format.rowSpanValue}>KETELITIAN</th>
      <th rowSpan={format.rowSpanValue}>KATEGORI KETELITIAN</th>
      <th rowSpan={format.rowSpanValue}>KONSISTENSI</th>
      <th rowSpan={format.rowSpanValue}>KATEGORI KONSISTENSI</th>
    </>
  );
}
