import React from 'react';

const HeaderSecond = () => {
  return (
    <React.Fragment>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        Silakan Gambarkan Diri Anda Secara Singkat
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        Prestasi Terbesar Anda
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        Dalam Hal Apa Anda Sulit Mengambil Keputusan
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        Kesulitan Dalam Pekerjaan Saat Ini dan Cara Mengatasinya
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        Kelebihan
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        Hal yang Perlu Dikembangkan
      </th>
    </React.Fragment>
  );
};

export default HeaderSecond;
