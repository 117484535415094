import { faPenAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useReducer, useState } from 'react';
import { API } from '../../../../../services/API';
import ConfirmModal from '../../../../ConfirmModal';
import ErrorModal from '../../../../ErrorModal';
import GlobalFilter from './GlobalFilter';
import styles from './TableHeader.module.css';
import * as RB from 'react-bootstrap';
import Rodal from 'rodal';
import cn from 'classnames';

const TableHeader = ({
  pageSize,
  setPageSize,
  preGlobalFilteredRows,
  globalFilterState,
  setGlobalFilter,
  rowsSelected = [],
}) => {
  const [isShowDelete, setIsShowDelete] = useState(false);
  const [isShowEdit, setIsShowEdit] = useState(false);
  const [isShowEditConfirm, setIsShowEditConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const editStatePesertaFunction = (state, action) => {
    switch (action.type) {
      // case 'setNamaPeserta':
      //   return { ...state, nama: action.payload };
      // case 'setKodePeserta':
      //   return { ...state, kode: action.payload };
      case 'setJenisKelaminPeserta':
        return { ...state, gender: action.payload };
      case 'setTingkatPendidikanPeserta':
        return { ...state, pendidikan: action.payload };
      case 'setStatus':
        return { ...state, stat: action.payload };
      case 'setCamPeserta':
        return { ...state, cam: action.payload };
      default:
        return action.payload;
    }
  };

  const editStatePeserta = {
    // nama: null,
    // kode: null,
    gender: null,
    pendidikan: null,
    stat: null,
    cam: 'Mengikuti Project',
  };

  let [editedDataPeserta, dispatchEditPeserta] = useReducer(
    editStatePesertaFunction,
    editStatePeserta
  );
  // console.log({ rowsSelected });
  // console.log({ editedDataPeserta });

  const allPesertaId = rowsSelected.map((e) => e?.id);
  const daftarKodePeserta = rowsSelected.map(
    (e) => e?.namaPeserta?.split(',,,')[1]
  );

  const onMultipleDeletePeserta = async () => {
    try {
      setIsLoading(true);
      const response = await API.delete('/pesertas/multiple', {
        params: { allPesertaId },
      });

      if (response) {
        setIsShowDelete(false);
        setIsLoading(false);

        window.location.reload();
      }
    } catch (error) {
      const detailErrMessage = error?.response?.data?.message;
      setErrorMessage(
        <div>
          Gagal menghapus kode peserta ini ({allPesertaId.join(', ')}), silahkan
          cek kembali semua data peserta!
          {detailErrMessage && (
            <>
              <br />
              <span className="text-danger">
                Error Detail: {detailErrMessage}
              </span>
            </>
          )}
        </div>
      );
      setIsLoading(false);
      setIsShowDelete(false);
    }
  };

  const onMultipleEditPeserta = async () => {
    try {
      setIsLoading(true);
      let updatedData = {};
      for (const propertyName in editedDataPeserta) {
        const value = editedDataPeserta[propertyName];
        if (value !== null) {
          updatedData = { ...updatedData, [propertyName]: value };
        }
      }

      const response = await API.patch('/pesertas/multipleEdit', {
        updatedData,
        allPesertaId,
      });

      if (response) {
        setIsShowEditConfirm(false);
        setIsLoading(false);

        window.location.reload();
      }
    } catch (error) {
      const detailErrMessage = error?.response?.data?.message;
      setErrorMessage(
        <div>
          Gagal mengedit kode peserta ini ({allPesertaId.join(', ')}), silahkan
          cek kembali semua data peserta!
          {detailErrMessage && (
            <>
              <br />
              <span className="text-danger">
                Error Detail: {detailErrMessage}
              </span>
            </>
          )}
        </div>
      );
      setIsLoading(false);
      setIsShowEditConfirm(false);
    }
  };
  return (
    <div className={styles.wrapper}>
      <div>
        <span>Tampilkan: </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          className={styles.showOption}
        >
          {[4, 10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>

        {rowsSelected.length >= 2 && (
          <>
            <button
              className={cn(styles.actionButton, styles.actionButtonEdit)}
              title="Multiple Edit"
            >
              <FontAwesomeIcon
                icon={faPenAlt}
                color="white"
                size="lg"
                onClick={setIsShowEdit.bind(this, true)}
              />
            </button>
            <button
              className={cn(styles.actionButton, styles.actionButtonDelete)}
              title="Multiple Delete"
            >
              <FontAwesomeIcon
                icon={faTrash}
                color="white"
                size="lg"
                onClick={setIsShowDelete.bind(this, true)}
              />
            </button>
          </>
        )}

        {isShowDelete && (
          <ConfirmModal
            onClick={onMultipleDeletePeserta}
            content={`Apakah Anda yakin ingin menghapus kode peserta ini? (${daftarKodePeserta.join(
              ', '
            )})`}
            isVisible={isShowDelete}
            setVisible={setIsShowDelete}
            animation="fadeIn"
            customStyles={{ height: 'auto', overflowY: 'auto' }}
            onStateLoading={isLoading}
          />
        )}

        {isShowEdit && (
          <Rodal
            visible={isShowEdit}
            // setVisible={setIsShowDelete}
            animation="zoom"
            onClose={setIsShowEdit.bind(this, false)}
            height={550}
          >
            <RB.Container className="mt-3">
              <RB.Row className="text-center">
                <RB.Col xs={12}>
                  <h1 className={styles.title}>Multiple Edit Peserta</h1>
                </RB.Col>
                <RB.Col className="mt-3">
                  <RB.Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setIsShowEdit(false);
                      setIsShowEditConfirm(true);
                    }}
                  >
                    {/* KODE */}
                    {/* <RB.Form.Group className="mb-2 mt-4" controlId="kodePeserta">
                    <RB.Form.Label>Kode</RB.Form.Label>
                    <RB.Form.Control
                      type="text"
                      placeholder="trialkode1"
                      autoComplete="off"
                      onChange={(e) =>
                        dispatchEditPeserta({
                          type: 'setKodePeserta',
                          payload: e.target.value,
                        })
                      }
                      value={peserta.kode}
                    />
                  </RB.Form.Group> */}

                    {/* NAMA */}
                    {/* <RB.Form.Group className="mb-2" controlId="namaPeserta">
                    <RB.Form.Label>Nama</RB.Form.Label>
                    <RB.Form.Control
                      type="text"
                      placeholder="Brian"
                      autoComplete="off"
                      onChange={(e) =>
                        dispatchEditPeserta({
                          type: 'setNamaPeserta',
                          payload: e.target.value,
                        })
                      }
                      value={peserta.nama}
                    />
                  </RB.Form.Group> */}

                    {/* JENIS KELAMIN */}
                    <RB.Form.Group className="mb-2" controlId="jenisKelamin">
                      <RB.Form.Label>Jenis Kelamin</RB.Form.Label>
                      <RB.Form.Select
                        aria-label="gender"
                        onChange={(e) =>
                          dispatchEditPeserta({
                            type: 'setJenisKelaminPeserta',
                            payload: e.target.value,
                          })
                        }
                      >
                        <option value=""></option>
                        <option value="L">Laki - Laki</option>
                        <option value="P">Perempuan</option>
                      </RB.Form.Select>
                    </RB.Form.Group>

                    {/* STATUS */}
                    <RB.Form.Group className="mb-2" controlId="jenisKelamin">
                      <RB.Form.Label>Status</RB.Form.Label>
                      <RB.Form.Select
                        aria-label="status"
                        onChange={(e) =>
                          dispatchEditPeserta({
                            type: 'setStatus',
                            payload: e.target.value,
                          })
                        }
                      >
                        <option value=""></option>
                        <option value="1">Proses</option>
                        <option value="2">Selesai</option>
                      </RB.Form.Select>
                    </RB.Form.Group>

                    {/* PENDIDIKAN */}
                    <RB.Form.Group
                      className="mb-2"
                      controlId="tingkatPendidikan"
                    >
                      <RB.Form.Label>Tingkat Pendidikan</RB.Form.Label>
                      <RB.Form.Select
                        aria-label="pendidikan"
                        onChange={(e) =>
                          dispatchEditPeserta({
                            type: 'setTingkatPendidikanPeserta',
                            payload: e.target.value,
                          })
                        }
                      >
                        <option value=""></option>
                        <option value="SD">SD</option>
                        <option value="SMP">SMP</option>
                        <option value="SMA">SMA</option>
                        <option value="SMK">SMK</option>
                        <option value="D1">D1</option>
                        <option value="D3">D3</option>
                        <option value="D4">D4</option>
                        <option value="S1">S1</option>
                        <option value="S2">S2</option>
                        <option value="S3">S3</option>
                      </RB.Form.Select>
                    </RB.Form.Group>

                    {/* PROCTORING */}
                    <RB.Form.Group
                      className="mb-2"
                      controlId="proctoringPeserta"
                    >
                      <RB.Form.Label>Proctoring Peserta</RB.Form.Label>
                      <RB.Form.Select
                        aria-label="proctoring"
                        onChange={(e) =>
                          dispatchEditPeserta({
                            type: 'setCamPeserta',
                            payload: e.target.value,
                          })
                        }
                      >
                        <option value="Mengikuti project">
                          Mengikuti project
                        </option>
                        <option value="Ya">Ya</option>
                        <option value="Tidak">Tidak</option>
                      </RB.Form.Select>
                    </RB.Form.Group>

                    <RB.Button variant="primary" type="submit" className="mt-2">
                      Submit{' '}
                      {isLoading && (
                        <RB.Spinner
                          animation="border"
                          variant="info"
                          size="sm"
                        />
                      )}
                    </RB.Button>
                  </RB.Form>
                </RB.Col>
              </RB.Row>
            </RB.Container>
          </Rodal>
        )}

        {isShowEditConfirm && (
          <ConfirmModal
            onClick={onMultipleEditPeserta}
            content={
              <div>
                `Apakah Anda yakin ingin mengedit kode peserta ini test? ($
                {daftarKodePeserta.join(', ')})`
              </div>
            }
            isVisible={isShowEditConfirm}
            setVisible={setIsShowEditConfirm}
            customStyles={{ height: 'auto', overflowY: 'auto' }}
            animation="fadeIn"
            onStateLoading={isLoading}
          />
        )}

        {errorMessage && (
          <ErrorModal
            visible={!!errorMessage}
            message={errorMessage}
            onClose={setErrorMessage.bind(this, '')}
          />
        )}
      </div>

      <div>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilterState}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
    </div>
  );
};

export default TableHeader;
