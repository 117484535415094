import { connect } from "react-redux";
import { changeCurrentSoal } from "../../../../redux/Peserta/peserta-actions";
import styles from "./Navigation.module.css";

const Navigation = ({
  currentTes,
  currentSoal,
  changeCurrentSoal,
  type = "single",
}) => {
  const navColorHandler = (soal) => {
    let color;

    const psikotesLS = JSON.parse(localStorage.getItem("psikotesTalenta"));
    [...psikotesLS.jawabans].forEach((jawaban) => {
      if (jawaban.soalId === soal.id) {
        if (type === "single") {
          color = jawaban.jawaban ? "#3bc2aa" : "#ababab";
        } else if (type === "multiple") {
          color = jawaban.jawaban.length > 0 ? "#3bc2aa" : "#ababab";
        } else if (type === "istr6") {
          color =
            jawaban.jawaban !== "00" && jawaban.jawaban !== ""
              ? "#3bc2aa"
              : "#ababab";
        } else if (type === "cact") {
          color =
            jawaban.jawaban[0] !== "-" && jawaban.jawaban[1] !== "-"
              ? "#3bc2aa"
              : "#ababab";
        }
      }
    });

    return color;
  };

  const switchSoalHandler = async (soal) => {
    const psikotesLS = JSON.parse(localStorage.getItem("psikotesTalenta"));
    psikotesLS.currentSoal = soal;
    localStorage.setItem("psikotesTalenta", JSON.stringify(psikotesLS));

    changeCurrentSoal(soal);
  };

  return (
    <div className={styles.navigation}>
      {currentTes?.tes?.soals?.map((soal) => (
        <div
          className={styles.nomorWrapper}
          key={soal.id}
          style={{
            backgroundColor: navColorHandler(soal),
            border:
              soal.nomor === currentSoal.nomor ? "4px solid #0880c4" : "none",
          }}
          onClick={() => switchSoalHandler(soal)}
        >
          <p>{soal.nomor}</p>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentTes: state.peserta?.currentTes,
  currentSoal: state.peserta?.currentSoal,
});

const mapDispatchToProps = (dispatch) => ({
  changeCurrentSoal: (currentSoal) => dispatch(changeCurrentSoal(currentSoal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
