import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
// import SoalContainer from '../../../Containers/SoalContainer';
// import SoalContent from '../../../Containers/SoalContent';
// import SoalHeader from '../../../Components/SoalHeader';
// import SoalText from '../../../Components/SoalText';
// import PetunjukModal from '../../../Components/PetunjukModal';
// import Petunjuk from '../../Components/Petunjuk';
// import ButtonSoal from '../../../Components/ButtonSoal';

import SoalContainer from './SoalContainer';
import SoalHeader from './SoalHeader';
import SoalContent from '../../components/Peserta/Containers/SoalContent';
import InputWrapper from '../../components/Peserta/Components/InputWrapper';
import ButtonSoal from './ButtonSoal';
import SoalText from './SoalText';
// import InputWrapper from '../../../Components/InputWrapper';

export default function Testing() {
  return (
    <SoalContainer isShowSelesaiModal={null} setIsShowSelesaiModal={null}>
      <SoalHeader setIsShowPetunjukModal={null} sisaWaktu={null} />
      <SoalContent withNomor={false}>
        <div
          style={{
            width: '80%',
            marginTop: '50px',
          }}
        >
          {/* <h3 style={{ textAlign: 'center', marginBottom: 30 }}>PENDAHULUAN</h3> */}

          <SoalText
            textAlign="left"
            lineHeight="normal"
            width=""
            text={false}
            currentTes={{ tesId: 25 }}
            currentSoal={{
              soal: `
              <h5><b>PENDAHULUAN</b></h5>
              <p>
                PT Reasuransi Talenta Raya, atau disingkat Talenta RE, didirikan berdasarkan akta Nomor xx tanggal 1 Januari 1996. Akta tersebut kemudian disetujui oleh Menteri Kehakiman Republik Indonesia Nomor XX-XX.XXX.HT.XX.XX Th.99 tanggal 1 Februari 1996. Pada awalnya, Perseroan merupakan suatu unit setingkat Bagian yang dikemudian hari berkembang menjadi Divisi dari PT (Persero) Asuransi Kredit Talenta, yang diberi nama Divisi Reasuransi Kerugian. Divisi ini berfungsi sebagai unit usaha Professional Reinsurer. Dengan berlakunya Undang-Undang Nomor 2 Tahun 1992 tentang Usaha Perasuransian, tidak diperkenankan beroperasi sebagai asuransi maupun reasuransi sekaligus, sehingga dilakukan pemisahan menjadi satu entitas tersendiri dimana perusahaan (Talenta RE yang dibentuk pada tanggal 1 Januari 1996) memperoleh ijin operasi sebagai perusahaan reasuransi pada tahun 1997 berdasarkan dari Surat Keputusan Menteri Keuangan Republik Indonesia Nomor XX/XXX.XX/1997 tanggal 1 Januari 1997.
              </p>
              <p>
                Pada tanggal 30 Desember 2007, Talenta RE melebarkan sayap usahanya dengan memulai usaha Reasuransi Syariah. Langkah ini sejalan dengan pesatnya perkembangan perekonomian yang berazaskan Syariah di Indonesia, termasuk asuransi syariah. Semakin berkembangnya asuransi syariah di Indonesia, tentunya memerlukan adanya reasuransi yang beroperasi pula berdasarkan syariah Islam, sehingga dapat diadakan kerjasama yang saling menguntungkan antara kedua belah pihak.
              </p>
              <p>
                Sejak resmi berdiri, Perusahaan mencatat perkembangan yang cukup pesat dan berhasil membangun kepercayaan publik, sebagaimana yang terlihat dari berbagai penghargaan yang diterimanya. Pada tahun 2010, Perusahaan menerima penghargaan sebagai Perusahaan Reasuransi Terbaik, dan pada tahun 2012 mendapatkan penghargaan sebagai Unit Reasuransi Syariah Terbaik, kedua-duanya berdasarkan versi Majalah Investor.
              </p>
              <p>
                Perusahaan memasarkan berbagai produk reasuransi, antara lain Reasuransi Harta Benda, Kendaraan Bermotor, Rekayasa, Pengangkutan, Rangka Kapal, Rangka Pesawat, Kredit, Surety Bond, Kredit, Satelit, Energi, Tanggung Gugat, Kecelakaan Diri, Aneka dan Reasuransi Jiwa, baik untuk Reasuransi Konvensional maupun Reasuransi Syariah.
              </p>

              </br>
              <h5><b>Struktur Organisasi</b></h5>
              </br>
              <div style="display: flex; flex-direction: column; align-items: center">
                <img src='${process.env.REACT_APP_SERVER_URL}/analisa-kasus/ak_kepala_bagian_akuntansi.png' width="60%" />
              </div>
              </br></br></br>

              <h5><b>SITUASI</b></h5>
              <p>
                Anda adalah Kepala Bagian Akuntansi yang baru di PT Reasuransi Talenta Indonesia, Talenta RE. Tugas utama Anda yaitu mengontrol dan memonitor seluruh program kerja Divisi Keuangan & Akuntansi (Bagian Akuntansi) yaitu mengontrol  dan memonitor rencana kerja dan anggaran, mengontrol dan memonitor sistem dan prosedur unit kerja dan bertanggung  jawab atas kegiatan program kerja Bagian Akuntansi dengan melaksanakan tugas-tugas pokok seperti mengkoordinasikan  penyusunan Laporan Realisasi Anggaran bulanan dan Laporan Tingkat Solvabilitas serta Laporan Keuangan/ Operasional,  mengkoordinasikan penyusunan Laporan Unaudited dan Laporan Manajemen, serta senantiasa mengikuti perkembangan  baru dalam bidang akuntansi, keuangan, ekonomi, dan bidang terkait lainnya.              
              </p>

              <p>
                Anda baru saja bergabung dengan Talenta RE, selama 6 bulan. Selama itu pula Anda menemukan beberapa permasalahan. Anda baru mengetahui bahwa Kepala Bagian Akuntansi (yang lama) sebelum Anda, telah terlibat korupsi di Talenta RE yang melibatkan beberapa anggota di Divisi Keuangan juga termasuk staf di tim Bagian Akuntasi dalam waktu 3 tahun terakhir. Saat ini proses penyidikan secara internal terkait kasus tersebut masih berjalan. Keadaan ini membuat beberapa staff yang cukup baru di bagian Anda mulai mengajukan resign karena tidak ingin terlibat lebih jauh dalam kasus ini, selain itu staf lama yang memang dikenal dekat dengan Kepala Bagian sebelum Anda, juga diminta oleh Kepala Divisi untuk mengundurkan diri. Hal ini jelas memengaruhi kinerja Bagian Akuntansi salah satunya berdampak pada keterlambatan penyelesaian laporan triwulan yang harus diserahkan ke BOD. Pada laporan triwulan yang baru saja diberikan, masih banyak terdapat kesalahan dalam perhitungnanya yang membuat Anda mendapat teguran langsung. Hal ini terjadi karena kurangnya staf pada tim Anda dan terlambatnya data submission dari unit-unit kerja lain. Staf Anda juga melaporkan bahwa perhitungan dalam laporan tersebut sebenarnya sesudah sesuai dengan data di laporan keuangan yang diberikan masing-masing unit kerja. Staf Anda sangat mengeluhkan keadaan ini karena situasi kerja yang sangat tidak nyaman serta sangat kurangnya anggota tim
              </p>

              <p>
                Kemudian pada meeting bulanan dengan Kepala Divisi Keuangan, disampaikan beberapa hal. Pertama terkait dengan  kebijakan baru mengenai dengan kasus ini. Bahwa seluruh staf lama yang terlibat ataupun dikenal dekat dengan para terduga pelaku korupsi, akan diminta untuk mengundurkan diri. Termasuk untuk bagian Anda yaitu Kepala Seksi Anggaran yang diduga juga mendapatkan aliran dana korupsi tersebut.  Kemudian segala bentuk bonus yang diterima karyawan di bagian Anda dalam waktu 3 tahun terakhir harus di audit kembali. Kedua terkait dengan penyempurnaan sistem teknologi keuangan agar segala bentuk laporan keuangan dapat dikerjakan melalui sistem yang terintegrasi untuk mencegah kembali terjadi hal-hal yang tidak diinginkan. Ketiga adalah pengembangan dari setiap sumber daya manusia yang ada di Divisi Keuangan. Berdasarkan hasil meeting bulanan Divisi Keuangan ini, Anda diminta untuk dapat memberikan program kerja yang harus segara dilaksanakan. 
              </p>

              <p>
                Namun sejalan dengan permintaan Kepala Divisi Keuangan itu, Anda mendapatkan undangan secara personal dari Direktur Operasional Talenta RE. Dalam pertemuan tersebut, Anda diminta untuk dapat membantu salah satu kerabatnya yaitu Kepala Seksi Anggaran. Kepala Seksi Anggaran merupakan salah satu tim yang paling lama berada di bagian akuntansi. Ia memiliki dokumen keuangan penting yang memungkinkan untuk membantu proses penyidikan korupsi di Talenta RE, sehingga jika posisinya diganti oleh orang baru pada situasi saat ini akan cukup menyulitkan proses penyidikan kasus yang berjalan.
                </br></br>
              </p>

              <p>
                <i>
                  Berdasarkan situasi di atas, buatlah langkah-langkah kerja secara sistematis dan jelas untuk mengatasi masalah tersebut. Buatkan juga perencanaan kerja yang dapat anda lakukan untuk melakukan antisipasi permasalahan.
                </i>
                </br></br>
                 <b>Waktu Anda untuk menyelesaikan Analisa Kasus ini adalah 50 menit.</b>
              </p>
`,
            }}
          />
        </div>

        <br />
        <br />
        <InputWrapper>
          <textarea
            type="text"
            // value={null}
            // onChange={(e) => setAnswer(n)}
            placeholder="Ketikkan jawaban di sini"
          />
        </InputWrapper>

        <ButtonSoal setIsShowSelesaiModal={null} />
      </SoalContent>
    </SoalContainer>
  );
}
