import { useEffect } from "react";
import LoginContent from "../../components/LoginContent";

const LoginPage = ({ history }) => {
  useEffect(() => {
    if (localStorage.getItem("talentaToken")) {
      history.push("/dashboard");
    }
  }, [history]);

  return (
    <>
      <LoginContent />
    </>
  );
};

export default LoginPage;
