import { useHistory, useParams } from 'react-router';
import fileIcon from '../../../../assets/project/detail/file.svg';
import { ubahFormat } from '../../../../utils/tanggal';
import styles from './SummaryLeft.module.css';
import { useState } from 'react';
import { API } from '../../../../services/API';
import ProgressModal from './progressModal';
import { Link } from 'react-router-dom';
import * as RB from 'react-bootstrap';
import ErrorModal from '../../../ErrorModal';
import React from 'react';

import Select from 'react-select';
import Flatpickr from 'react-flatpickr';

// styles
import 'flatpickr/dist/themes/material_blue.css';
import { formatTanggalSekarang } from '../../../../utils/tanggal';

// ** Utils
const today = new Date();

const twoWeeksAgo = new Date().setDate(today.getDate() - 15);
const twoWeeksLater = new Date().setDate(today.getDate() + 15);

const SummaryLeft = ({ project }) => {
  const { id } = useParams();
  const history = useHistory();
  const [progress, setProgress] = useState(0);
  const [progressModal, setProgressModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);
  const [formExcelModal, setFormExcelModal] = useState(false);
  const [formatLaporanExcel, setFormatLaporanExcel] = useState({
    label: 'STANDAR',
    value: 'standar',
  });

  // ** State
  const [startDate, setStartDate] = useState(formatTanggalSekarang(twoWeeksAgo, true));
  const [endDate, setEndDate] = useState(formatTanggalSekarang(twoWeeksLater, true));

  let dataLength = 0;

  const siapkanZip = async () => {
    await API.get(`/peserta-selesai?projectId=${id}`)
      .then((r) => {
        dataLength = r.data.data.length;
        if (dataLength) {
          setProgressModal(true);
          r.data.data.forEach(async (p, i) => {
            let x = i + 1;
            await API.get(
              `/laporan-pdf?pesertaId=${p.id}&isLaporanUmum=${project.isLaporanUmum}&forZip=1&projectId=${id}`,
              {
                responseType: 'application/json',
              }
            )
              .then((r) => {
                setProgress(100 / (x / dataLength));
              })
              .catch((err) => {
                setError(true);
                setErrorMessage(
                  <div>
                    Gagal download semua laporan, silahkan cek kembali semua data peserta!
                    {err.response && (
                      <>
                        <br />
                        <span className="text-danger">
                          Error Detail: {err.response?.data?.message}
                        </span>
                      </>
                    )}
                  </div>
                );
              });
          });
        } else {
          setErrorMessage(
            <div>
              Gagal download semua laporan, silahkan cek kembali semua data peserta!
              <>
                <br />
                <span className="text-danger">
                  Error Detail: Tidak ada data peserta dalam projek ini
                </span>
              </>
            </div>
          );
          setError(true);
        }
      })
      .catch((err) => console.log(err));
  };
  const closeModal = () => {
    setProgressModal(false);
    setError(false);
    setFormExcelModal(false);
  };

  const reportOptions = [
    { value: 'standar', label: 'STANDAR' },
    { value: 'egatek', label: 'EGATEK' },
    { value: 'lppi', label: 'LPPI' },
    { value: 'taf', label: 'TAF' },
    { value: 'edu', label: 'EDU' },
    { value: 'komatsu', label: 'Komatsu IST' },
  ];

  return (
    <div className={styles.summaryLeft}>
      {!error && (
        <ProgressModal
          modalVisible={progressModal}
          percent={progress}
          projectId={id}
          setModalVisible={setProgressModal}
        />
      )}
      <RB.Modal show={formExcelModal} animation="zoom" onHide={closeModal} centered>
        <RB.Modal.Body className="h-100">
          <RB.Row className="h-100 justify-content-center align-items-center">
            <RB.Col xs={12}>
              <h5>
                Pilih format laporan excel:{' '}
                <span className="text-success">{formatLaporanExcel.label}</span>
              </h5>
            </RB.Col>
            <h5>Filter Tanggal</h5>
            <RB.Col sm="6" className="mb-3">
              <Flatpickr
                value={startDate}
                id="start-date-filter"
                className="form-control bg-transparent"
                onChange={(__, date) => {
                  setStartDate(date);
                }}
              />
            </RB.Col>
            <RB.Col sm="6" className="mb-3">
              <Flatpickr
                value={endDate}
                id="end-date-filter"
                className="form-control bg-transparent"
                onChange={(__, date) => {
                  setEndDate(date);
                }}
              />
            </RB.Col>
            <RB.Col sm="12">
              <Select
                options={reportOptions}
                onChange={(values) => {
                  setFormatLaporanExcel(values);
                }}
              />
            </RB.Col>
            <RB.Col className="mt-3">
              {/* <Link
                className={styles.card}
                to={{
                  pathname: '/rekap-excel',
                  state: { id: id, table: formatLaporanExcel, project },
                }}
              >
                Ok
              </Link> */}
              <Link
                className={styles.card}
                target="_blank"
                to={`/report-excel/${id}/${formatLaporanExcel.value}?startDate=${startDate}&endDate=${endDate}`}
              >
                Ok
              </Link>
            </RB.Col>
          </RB.Row>
        </RB.Modal.Body>
      </RB.Modal>
      <div className={styles.fileIconWrapper} onClick={() => history.push(`/project/edit/${id}`)}>
        <img src={fileIcon} alt="file" />
      </div>
      <div style={{ marginTop: '5%' }}>
        <div className={styles.data}>
          <p className={styles.dataTitle}>Name </p>
          <p className={styles.dataValue} style={{ fontWeight: 'bold', color: 'black' }}>
            {project?.nama}
          </p>
        </div>

        <div className={styles.data}>
          <p className={styles.dataTitle}>Deskripsi </p>
          <p className={styles.dataValue}> {project.deskripsi}</p>
        </div>

        <div className={styles.data}>
          <p className={styles.dataTitle}>Periode tanggal </p>
          <p className={styles.dataValue}>{`${ubahFormat(project.tglMulai)} - ${ubahFormat(
            project.tglSelesai
          )}`}</p>
        </div>

        <div className={styles.data}>
          <p className={styles.dataTitle}>Fitur Pengawasan </p>
          <p className={styles.dataValue}>{project?.cam ? 'Ya' : 'Tidak'}</p>
        </div>

        <div className={styles.data}>
          <p className={styles.dataTitle}>Acak Soal </p>
          <p className={styles.dataValue}>Ya</p>
        </div>

        <div className={styles.data}>
          <p className={styles.dataTitle}>Modul digunakan</p>
          <p className={styles.dataValue}>{project?.modul?.nama || ''}</p>
        </div>

        <div className={styles.data}>
          <p className={styles.dataTitle}>Laporan umum</p>
          <p className={styles.dataValue}>{project?.isLaporanUmum ? 'Ya' : 'Tidak'}</p>
        </div>
        <div className={styles.data}>
          <p className={styles.dataTitle}>Cutting Point</p>
          <p className={styles.dataValue}>{project?.isCuttingPoint ? 'Ya' : 'Tidak'}</p>
        </div>
      </div>
      <div className={styles.cards}>
        <button className={styles.card} onClick={siapkanZip}>
          Download All
        </button>
        <Link className={styles.card} target="_blank" to={`/report-excel/${id}/standar`}>
          Rekap Excel Standar
        </Link>
        <button className={styles.card} onClick={() => setFormExcelModal(true)}>
          Rekap Excel Pilihan
        </button>
      </div>
      {error && <ErrorModal visible={error} onClose={closeModal} message={errorMessage} />}
    </div>
  );
};

export default SummaryLeft;
