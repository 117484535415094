import { faArrowCircleUp, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { API } from '../../../services/API';
import TopSection from '../../TopSection';
import styles from './UnggahTestPesertaContent.module.css';
import ErrorModal from '../../ErrorModal';
import { Spinner } from 'react-bootstrap';
import Modal from '../../Modal';

const UnggahTestPesertaContent = () => {
  const history = useHistory();

  const [fileName, setFileName] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [succeeded, setIsSucceeded] = useState(false);

  const handleFileReader = (event) => {
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e) => {
      setUploadFile({
        data: reader.result.split(',').pop(),
        fileName: event.target.files[0].name,
      });
    };

    setFileName(event.target.files[0].name);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (fileName === false) {
      return setErrorMessage(
        <div>
          Gagal unggah data tes peserta!
          <br />
          <span className="text-danger">
            Error Detail: Silahkan masukkan filenya terlebih dahulu.
          </span>
        </div>
      );
    }
    try {
      setIsLoading(true);

      const response = await API.post('/uploaded_file', uploadFile);

      if (response) {
        setIsLoading(false);
        setIsSucceeded(true);
        setFileName('');
        setUploadFile(null);
      }
      // console.log({uploadFile});
      // console.log({fileName});
    } catch (error) {
      console.log({ error });
      setIsSucceeded(false);
      setIsLoading(false);
      setErrorMessage(
        <div>
          Gagal unggah data tes peserta!
          <br />
          <span className="text-danger">
            Error Detail:{' '}
            {error?.response?.data?.message ||
              'Silahkan upload file atau cek format filenya terlebih dahulu.'}
          </span>
        </div>
      );
    }
  };

  return (
    <>
      <div className={styles.container}>
        <TopSection />
        <div className={styles.mainSection}>
          <p className={styles.pageTitle}>
            <span className={styles.pathLink} onClick={() => history.push('/')}>
              Beranda
            </span>{' '}
            <span className={styles.pathNow}>
              / Perusahaan / Unggah Tes Peserta
            </span>
          </p>

          <form className={styles.unggahWrapper} onSubmit={submitHandler}>
            <p className={styles.unggahTitle}>Unggah Tes Peserta</p>

            <div className={styles.unggahField}>
              <p>
                <FontAwesomeIcon color="#A2A2A2" icon={faFile} size="3x" />
              </p>
              <p>Click to browse</p>
              <label htmlFor="upload" className={styles.labelFile}></label>
              <input
                type="file"
                id="upload"
                className={styles.fileInput}
                onChange={handleFileReader}
                onClick={() => {
                  setFileName(false);
                }}
                accept=".zip,.talenta"
              />
            </div>

            <p>{fileName}</p>

            <button
              className={styles.buttonUnggah}
              type="submit"
              disabled={isLoading}
            >
              Unggah{' '}
              {isLoading ? (
                <Spinner animation="border" variant="info" size="sm" />
              ) : (
                <span>
                  <FontAwesomeIcon icon={faArrowCircleUp} />
                </span>
              )}
            </button>
          </form>
        </div>
      </div>
      <div className={styles.background}></div>
      {errorMessage && (
        <ErrorModal
          visible={!!errorMessage}
          message={errorMessage}
          onClose={() => setErrorMessage('')}
        />
      )}
      {succeeded && (
        <Modal
          isVisible={succeeded}
          func={[() => {}, () => {}]}
          content="Unggah data tes peserta berhasil!"
        />
      )}
    </>
  );
};

export default UnggahTestPesertaContent;
