import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihanOne = ["26", "27", "28", "29", "30"];
  const arrPilihanTwo = ["62", "96", "84", "76", "72"];
  const arrPilihanThree = ["7", "8", "6", "4", "5"];
  const arrPilihanFour = ["50", "60", "75", "80", "65"];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>Tes terdiri dari persoalan-persoalan hitungan deret angka.</p>
        <br />
        <p>
          Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum mengerjakan.
        </p>
        <br />

        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="1 - 3 - 6 - 10 - 15 - 21 ..."
        arrPilihan={arrPilihanOne}
        kunci="28"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Prinsip atau aturan yang dipakai untuk menyusun deret angka dalam
            contoh adalah perbedaan dua angka berturut-turut selalu meningkat
            dan bertambah 1. Dengan demikian jawaban yang tepat adalah 28 yang
            dipilih."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="3 - 6 - 12 - 24 - 48 ..."
        arrPilihan={arrPilihanTwo}
        kunci="96"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Prinsip yang mendasari penyusunan deret itu adalah setiap angka
            adalah kelipatan dua dari angka yang mendahului. Jadi jawabannya
            adalah 96."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        soal="3 - 7 - 4 - 7 - 5 - ..."
        arrPilihan={arrPilihanThree}
        kunci="7"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Pada contoh, deret angka meningkat terus mulai dari 3, kemudian 4, 5
            dan seterusnya dengan diselingi angka yang tetap yaitu 7. Jadi pada
            saat tahap tersebut jawaban yang tepat adalah 7."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={4}
        soal="1 - 4 - 5 - 10 - 20 - 40 ..."
        arrPilihan={arrPilihanFour}
        kunci="80"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Pada contoh, angka-angka dalam deret itu merupakan jumlah dari semua
            angka yang mendahuluinya, dimulai dari deret ketiga. Jawaban yang
            tepat adalah 80 (didapat dari penjumlahan seluruh angka yaitu:
            1+4+5+10+20+40)."
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={5}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
