import { connect } from "react-redux";
import { useEffect, useState } from "react";
import SoalContainer from "../../../Containers/SoalContainer";
import SoalContent from "../../../Containers/SoalContent";
import SoalHeader from "../../../Components/SoalHeader";
import PetunjukModal from "../../../Components/PetunjukModal";
import Petunjuk from "../../Components/Petunjuk";
import ButtonSoal from "../../../Components/ButtonSoal";
import { setAnswerLS, setJawabansLS, setSisaWaktuLS } from "../../../Utils";
import {
  mapDispatchToPropsSoal,
  mapStateToPropsSoal,
} from "../../../Utils/reduxMap";
import InputWrapper from "../../../Components/InputWrapper";

const Soal = ({ currentSoal, currentTes, replaceJawabans }) => {
  const [answer, setAnswer] = useState("");
  const [sisaWaktu, setSisaWaktu] = useState(-1);
  const [isShowSelesaiModal, setIsShowSelesaiModal] = useState(false);
  const [isShowPetunjukModal, setIsShowPetunjukModal] = useState(false);

  useEffect(() => {
    setSisaWaktuLS(currentTes?.tes?.waktu, setSisaWaktu);
  }, [currentTes?.tes?.waktu]);

  useEffect(() => {
    setAnswer("");
    setAnswerLS(currentSoal, setAnswer);
  }, [currentSoal]);

  useEffect(() => {
    setJawabansLS(answer, replaceJawabans);
  }, [answer, replaceJawabans]);

  const thStyle = {
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "5px",
  };

  return (
    <SoalContainer
      isShowSelesaiModal={isShowSelesaiModal}
      setIsShowSelesaiModal={setIsShowSelesaiModal}
    >
      <SoalHeader
        setIsShowPetunjukModal={setIsShowPetunjukModal}
        sisaWaktu={sisaWaktu}
      />
      <SoalContent>
        <div style={{ width: "80%" }}>
          <h3 style={{ textAlign: "center" }}>PENDAHULUAN</h3>

          <p>
            Anda kini tengah bekerja pada suatu organisasi swasta yang bergerak
            pada <strong>industri farmasi,</strong> bernama{" "}
            <strong>Talenta Farma.</strong> Dulu, TALENTA FARMA dikenal sebagai
            salah satu perusahaan yang handal dan memiliki profitabilitas yang
            relatif besar. Namun beberapa tahun belakangan ini, tepatnya sejak
            tahun 2017, TALENTA FARMA mengalami penurunan laba bersih yang cukup
            dramatis (lihat tabel 1). Profil ringkas mengenai TALENTA FARMA
            dapat dilihat pada bagian profil.
          </p>

          <p>
            <b>Table 1. Laba Bersih Talenta Farma (Rp. dalam milyar)</b>

            <table>
              <tr>
                <th style={thStyle}>Tahun</th>
                <td style={thStyle}>2017</td>
                <td style={thStyle}>2018</td>
                <td style={thStyle}>2019</td>
                <td style={thStyle}>2020</td>
              </tr>
              <tr>
                <th style={thStyle}>Laba Bersih</th>
                <td style={thStyle}>234</td>
                <td style={thStyle}>200</td>
                <td style={thStyle}>172</td>
                <td style={thStyle}>154</td>
              </tr>
            </table>
          </p>

          <p>
            Tren penurunan laba ini dipicu oleh persaingan yang makin ketat dan
            kondisi internal perusahaan yang memburuk. Sejumlah karyawan yang
            menjadi tulang punggung perusahaan satu persatu mengundurkan diri
            dan memilih untuk bekerja di tempat lain, bahkan ada yang pindah ke
            perusahaan pesaing. Sementara itu, moral dan semangat karyawan
            mengalami penurunan yang cukup dramatis.
          </p>

          <p>
            Untuk mengatasi situasi kritis tersebut, Managing Director TALENTA
            FARMA, yakni saudara John Adiwiyoto bermaksud untuk melakukan suatu
            tindakan komprehensif guna mengembalikan posisi TALENTA FARMA
            sebagai organisasi yang berkembang secara sehat. Untuk ini ia telah
            menyewa konsultan independen guna melakukan semacam kajian
            menyeluruh mengenai kondisi internal di TALENTA FARMA. Berikut
            adalah ringkasan dari hasil kajian tersebut :
          </p>

          <ul>
            <h4>Aspek Budaya Organisasi</h4>

            <li>
              Dalam aspek ini, hasil analisa memperlihat adanya kecenderungan
              yang kuat tumbuhnya 'raja-raja kecil' pada masing-masing
              departemen. Masing-masing kepala departemen yang memiliki level
              manager cenderung lebih mementingkan kepentingan departemennya
              masing-masing, dan acap gagal melihat kepentingan perusahaan
              secara menyeluruh. Dalam kondisi seperti ini, budaya untuk saling
              berbagi informasi dan budaya kerjasama lintas departemen menjadi
              sulit tumbuh. Sebaliknya, komunikasi dan koordinasi antar
              departmen menjadi amat lemah; demikian juga konflik kepentingan
              menjadi sering muncul akibat masing-masing departemen melihat
              suatu persoalan dari perspektif kepentingannya masing-masing.
              Akibatnya, sejumlah inisiatif strategis seperti pengembangan
              produk baru; program peningkatan mutu, dan perluasan saluran
              distribusi menjadi tidak berjalan secara optimal karena konflik
              kepentingan ini.
            </li>

            <li>
              Aspek budaya lain yang juga menonjol adalah kultur birokratis yang
              lebih peduli pada aspek prosedural yang bertele-tele dan lamban;
              dan bukan berorientasi pada 'result' dan keberanian mengambil
              resiko. Berdasar analisa, kultur ini muncul akibat:
            </li>

            <ol>
              <li>struktur organisasi yang terlalu hirarkis</li>
              <li>
                tiadanya sistem atau infrastruktur yang mendorong para manajer
                untuk bertindak inovatif, kreatif serta berani mengambil resiko.
                Pada sisi lain, terdapat kecenderungan dari para manajer untuk
                menjadi 'safety player' dan kurang menunjukkan keberanian untuk
                melakukan terobosan yang signifikan.
              </li>
            </ol>

            <h4>Aspek Gaji dan Tunjangan</h4>

            <li>
              Analisa menunjukkan bahwa tingkat gaji yang diberikan oleh TALENTA
              FARMA kepada para karyawannya tergolong cukup kompetetif; dalam
              arti tidak kalah dengan tingkat gaji yang diberikan oleh
              perusahaan pesaingnya. Demikian juga dalam hal tunjangan, baik
              tunjangan transport, asuransi kesehatan, maupun tunjangan lainnya.
              Selain itu, karyawan TALENTA FARMA juga memperoleh fasilitas dana
              pensiun dari perusahaan yang tergolong cukup besar.
            </li>
            <li>
              Pada sisi lain, analisa menunjukkan bahwa sistem pemberian bonus
              yang dilakukan oleh TALENTA FARMA kurang mengacu pada prestasi.
              Dalam hampir semua kasus yang dikaji, besarnya bonus sama saja
              antara karyawan yang berprestasi dengan karyawan yang prestasinya
              biasa-biasa saja.
            </li>

            <h4>Aspek Keterampilan (skill) dan Pengembangan Karyawan</h4>
            <li>
              Analisa yang dilakukan menunjukkan bahwa pengembangan ketrampilan
              karyawan, baik melalui pelatihan reguler,{" "}
              <i>on-the-job-training</i>, maupun proses <i>coaching</i> tidak
              berjalan secara optimal. Program pelatihan yang ada hanya
              diijalankan berdasar rutinitas; dan bukan berdasar analisa
              kebutuhan yang nyata. Selain itu, pelatihan yang dilakukan juga
              sama sekali tidak diikuti dengan proses tindak lanjut yang
              terencana, sehingga materi pelatihan menjadi sulit diaplikasikan
              karena tiadanya dukungan perencanaan dan sistem yang memadai. Pada
              sisi lain, proses coaching untuk memberikan umpan balik kinerja
              pada karyawan juga tidak pernah dilakukan. Proses penilaian
              kinerja yang dilakukan tiap semester hanya dilakukan demi
              formalitas; dan bukan diarahkan sebagai mekanisme untuk
              meningkatkan kemampuan kinerja karyawan.
            </li>

            <h4>Aspek Personalia</h4>
            <li>
              Analisa menunjukkan bahwa penumpukan karyawan{" "}
              <i>(redudant employees)</i> terutama dalam bagian administrasi.
              Sebagai contoh, staf yang menangani tugas surat menyurat bisa
              berjumlah tiga orang; meski sesungguhnya bisa dikerjakan oleh satu
              personel. Banyaknya karyawan dalam bagian-bagian yang tidak
              bersifat inti ini menyebabkan sebagian di antara mereka tidak bisa
              mencapai produktivitas yang tinggi.{" "}
            </li>
            <li>
              Pada sisi lain, analisa juga menunjukkan tidak adanya kejelasan
              dalam perencanaan karir karyawan <i>(employee career planning)</i>
              . Sejumlah karyawan mengaku tidak tahu mengenai prospek karirnya,
              dan merasa stagnan dalam posisinya.
            </li>
            <li>
              Dalam enam bulan terakhir, juga terdapat gejala makin tingginya
              jumlah karyawan yang mengundurkan diri. Analisa juga menunjukkan
              bahwa karyawan yang keluar ini rata-rata memiliki prestasi kerja
              yang bagus (excellent).
            </li>

            <h4>Aspek Produk</h4>
            <li>
              Survei yang dilakukan kepada para customer memperlihatkan adanya
              kecenderungan dari para customer untuk beralih kepada produk
              pesaing. Hal ini terjadi karena customer melihat tidak adanya
              fitur-fitur inovatif pada produk yang dihasilkan seperti halnya
              yang secara agresif telah dilakukan oleh para pesaing.
              Produk-produk dari TALENTA FARMA dianggap telah 'ketinggalan
              zaman' dan kurang responsif terhadap perubahan selera pasar.
            </li>
          </ul>

          <p>
            Untuk merespon Laporan Analisa ini, Mr. John telah menunjuk anda
            sebagai Ketua Tim Penyempurnaan Organisasi. Dalam hal ini Anda
            ditugaskan untuk membuat Rencana Aksi yang detil untuk merespon dan
            sekaligus mengatasi setiap masalah yang ada dalam laporan tersebut.
            Rencana aksi yang anda gagas ini selanjutnya akan dijadikan{" "}
            <i>blue-print</i> bagi program penyempurnaan organisasi TALENTA
            FARMA.
          </p>

          <p>Waktu untuk menyelesaikan tugas ini adalah 60 menit.</p>

          <h4>PROFIL RINGKAS TALENTA FARMA</h4>

          <p>
            Talenta Farma adalah sebuah perusahaan yang bergerak di bidang
            industri farmasi. Talenta Farma memiliki total karyawan sebesar 8400
            orang. Produk-produk Talenta Farma dipasarkan ke seluruh Indonesia.
          </p>

          <p>
            Pada akhir tahun 2018, Talenta Farma memiliki 5 fasilitas produksi,
            4 Sentra Logistik Regional, 19 Area Distribusi, 2 Stock Point, serta
            30 agen yang tersebar di seluruh pulau besar di Indonesia.
          </p>

          <p>
            Secara keseluruhan, data demografi karyawan di Talenta Farma adalah
            sbb:
          </p>

          <table>
            <tr>
              <th colspan="2" style={thStyle}>
                Pendidikan
              </th>
            </tr>
            <tr>
              <td style={thStyle}>S2</td>
              <td style={thStyle}>1%</td>
            </tr>
            <tr>
              <td style={thStyle}>S1</td>
              <td style={thStyle}>18%</td>
            </tr>
            <tr>
              <td style={thStyle}>D3</td>
              <td style={thStyle}>24%</td>
            </tr>
            <tr>
              <td style={thStyle}>SMA/SMK</td>
              <td style={thStyle}>57%</td>
            </tr>
          </table>
          <br />
          <table style={{ display: "inline" }}>
            <tr>
              <th colspan="2" style={thStyle}>
                Usia Karyawan
              </th>
            </tr>
            <tr>
              <td style={thStyle}>20 - 30 Tahun</td>
              <td style={thStyle}>25%</td>
            </tr>
            <tr>
              <td style={thStyle}>31 - 40 Tahun</td>
              <td style={thStyle}>40%</td>
            </tr>
            <tr>
              <td style={thStyle}>41 Tahun</td>
              <td style={thStyle}>35%</td>
            </tr>
          </table>
        </div>

        <br />
        <br />
        <InputWrapper>
          <textarea
            type="text"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            placeholder="Ketikkan jawaban di sini"
          />
        </InputWrapper>

        <ButtonSoal setIsShowSelesaiModal={setIsShowSelesaiModal} />
      </SoalContent>
      <PetunjukModal
        isShow={isShowPetunjukModal}
        onHide={() => setIsShowPetunjukModal(false)}
      >
        <Petunjuk
          isModal
          tutupModalHandler={() => setIsShowPetunjukModal(false)}
          menit={60}
        />
      </PetunjukModal>
    </SoalContainer>
  );
};

export default connect(mapStateToPropsSoal, mapDispatchToPropsSoal)(Soal);
