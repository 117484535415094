import { useState } from 'react';
import PetunjukLast from '../../../Components/PetunjukLast';
import PetunjukContainer from '../../../Containers/PetunjukContainer';
import PetunjukZero from '../../../Containers/PetunjukZero';
import PetunjukMiddle from './PetunjukMiddle';

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihan = ['Benar', 'Salah'];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Tes ini terdiri dari soal-soal berhitung dengan menggunakan
          huruf-huruf. Dalam tes ini, soal-soalnya tidak terdiri dari
          angka-angka (bilangan-bilangan), melainkan huruf-huruf.
        </p>
        <br />
        <p>Diketahui:</p>
        <p>
          A = 1, B = 2, C = 3, D = 4, E = 5, F = 6, G = 7, H = 8, J = 9, K = 0
        </p>
        {/* <ul>				
            <li>A = 1</li>
            <li>B = 2</li>
            <li>C = 3</li>
            <li>D = 4</li>
            <li>E = 5</li>
            <li>F = 6</li>
            <li>G = 7</li>
            <li>H = 8</li>
            <li>J = 9</li>
            <li>K = 0</li>
          </ul> */}
        <br />
        <p>
          Huruf AB misalnya adalah angka 1 dan 2 berurutan, dan itu berarti sama
          dengan 12. Diketahui bahwa A = 1 dan F = 6; dengan demikian AF = 16.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="A	x	C	=	C"
        arrPilihan={arrPilihan}
        kunci="Benar"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Pada contoh 1, A x C = 1 x 3 dan hasilnya sama dengan 3. Angka 3 dapat diganti dengan huruf C. Dengan demikian perhitungan contoh 1 adalah benar. Oleh karena itu, Anda dapat memilih Benar."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="C	x	F	=	AE"
        arrPilihan={arrPilihan}
        kunci="Salah"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Pada contoh 2, C x F dapat diganti dengan 3 x 6 yang akan memberikan hasil sama dengan 18. Angka 18 dapat diganti dengan huruf AH. Pada contoh 2 dapat kita lihat bahwa C x F = AE, dengan demikian berarti hasil perhitungan tersebut salah, sebab jawaban yang benar adalah AH bukan AE. Oleh karena itu, Anda dapat memilih Salah."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        soal="G	x	H	=	EF"
        arrPilihan={arrPilihan}
        kunci="Benar"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Pada contoh 3, G x H = 7 x 8, hasilnya = 56 atau = EF. Dengan demikian contoh 3 adalah benar, Oleh karena itu Anda dapat memilih Benar."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={4}
        soal="K	x	B	=	K"
        arrPilihan={arrPilihan}
        kunci="Benar"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Pada contoh 4, K x B = 0 x 2, hasilnya = 0 atau = K, jadi contoh 4 adalah benar, oleh karena itu Anda dapat memilih Benar."
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={5}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
