import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihanOne = ["20", "21", "22", "23", "24"];
  const arrPilihanTwo = ["6", "7", "8", "9", "10"];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Soal-soal berikut terdiri dari deretan angka. Deretan angka tersebut
          terdiri dari pola tertentu. Temukan pola pada deret angka tersebut
          untuk dapat menjawab atau mengisi deret angka terakhir.
        </p>
        <br />
        <p>Pilihlah kata yang tepat yang dapat menyempurnakan kalimat itu !</p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="1 - 4 - 7 - 10 - 13 - 16 - 19 - ?"
        arrPilihan={arrPilihanOne}
        kunci="22"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban yang benar adalah <strong>22</strong> (Setiap angka ditambah 3)."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="4 - 6 - 5 - 7 - 6 - 8 - 7 - ?"
        arrPilihan={arrPilihanTwo}
        kunci="9"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban yang benar adalah <strong>9</strong> (Polanya adalah angka ditambah 2, angka berikutnya dikurang 1, ditambah 2, dikurang 1, dan seterusnya)."
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
