export const convertToWIB = (datetime) => {
  const value = new Date(
    new Date(datetime).setHours(new Date(datetime).getHours() + 7)
  );

  return value;
};

export const convertToUTC = (datetime) => {
  const value = new Date(
    new Date(datetime).setHours(new Date(datetime).getHours() - 7)
  );

  return value;
};
