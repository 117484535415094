import { useState } from 'react';
import PetunjukLast from '../../../Components/PetunjukLast';
import Footer from '../../../Components/PetunjukMiddle/Footer';
import PetunjukMiddleContainer from '../../../Components/PetunjukMiddle/PetunjukMiddleContainer';
import PetunjukContainer from '../../../Containers/PetunjukContainer';
import PetunjukZero from '../../../Containers/PetunjukZero';
import styles from './Petunjuk.module.css';

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Tes berikut terdiri dari rangkaian angka-angka atau huruf-huruf. Anda
          akan menjumpai bahwa tiap soal terdiri dari dua rangkaian angka atau
          huruf yang dipisahkan satu sama lain oleh garis. Untuk kedua bentuk
          soal, akan sering dijumpai bahwa rangkaian yang terdapat di depan
          garis pemisah sama dengan yang ada di belakangnya, tetapi terkadang
          tidak sama. Telitilah apakah rangkaian yang terdapat di depan dan di
          belakang garis pemisah itu sama ataukah tidak sama. Apabila sama
          pilihlah jawaban{' '}
          <strong>
            <em>SAMA</em>
          </strong>{' '}
          dan tidak sama pilihlah jawaban{' '}
          <strong>
            <em>TIDAK SAMA</em>
          </strong>
          .
        </p>
        <br />
        <p>
          Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum mengerjakan.
        </p>
        <br />
        <p style={{ color: 'red' }}>
          Jangan ditutup dulu petunjuk ini, perhatikan contoh, Apabila petunjuk
          ini ditutup maka tes dimulai dan waktu berjalan.
        </p>

        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddleContainer isShow={index === 1}>
        <br />
        <Soal
          soal="1. 216770	-	216770"
          pesan="Pada contoh 1, dapat dilihat bahwa kedua rangkaian angka itu"
          kunci="sama"
        />
        <Soal
          soal="2. 848217 - 845217"
          pesan="Pada contoh 2, dapat dilihat bahwa kedua rangkaian angka itu tidak sama. Oleh karena itu, pilihlah"
          kunci="tidak sama"
        />
        <Soal
          soal="3. ZRWRF - ZRWFR"
          pesan="Pada contoh 3, kedua rangkaian huruf itu berbeda. Oleh karena itu, pilihlah"
          kunci="tidak sama"
        />
        <Soal
          soal="4. BPADL - BPADL"
          pesan="Sedangkan pada contoh 4, kedua rangkaian huruf itu sama. Oleh karena itu, pilihlah"
          kunci="sama"
        />

        <Footer condition next={nextHandler} previous={previousHandler} />
      </PetunjukMiddleContainer>

      <PetunjukMiddleContainer isShow={index === 2}>
        <br />
        <Soal
          soal="5. 736251 - 736251"
          pesan="Seharusnya Anda memilih contoh soal 5 adalah"
          kunci="sama"
        />
        <Soal
          soal="6. 78491 - 74891"
          pesan="Seharusnya Anda memilih contoh soal 6 adalah"
          kunci="tidak sama"
        />
        <Soal
          soal="7. RBVHH - RVBHH"
          pesan="Seharusnya Anda memilih contoh soal 7 adalah"
          kunci="tidak sama"
        />
        <Soal
          soal="8. XTRFG - XTFRG"
          pesan="Seharusnya Anda memilih contoh soal 8 adalah"
          kunci="tidak sama"
        />

        <Footer condition next={nextHandler} previous={previousHandler} />
      </PetunjukMiddleContainer>

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

const Soal = ({ soal, pesan, kunci }) => (
  <div className={styles.soalWrapper}>
    <h3>{soal}</h3>
    <p>
      {pesan}{' '}
      <strong>
        <em>{kunci}.</em>
      </strong>
    </p>
  </div>
);

export default Petunjuk;
