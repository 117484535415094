import { useEffect } from 'react';
import { connect } from 'react-redux';
import { generateEmptyJawabans } from '../../Utils';
import {
  mapDispatchToPropsTes,
  mapStateToPropsTes,
} from '../../Utils/reduxMap';
import Petunjuk from '../Components/Petunjuk';
import Soal from './Soal';

const AKITSectionHeadManufacturing = ({
  currentPetunjuk,
  currentTes,
  replaceJawabans,
}) => {
  useEffect(() => {
    generateEmptyJawabans(replaceJawabans, 'Tuliskan analisa Anda di sini.');
  }, [replaceJawabans]);

  return (
    <>
      {currentPetunjuk === 132 && <Petunjuk menit={50} />}
      {currentPetunjuk !== 132 && currentTes.tesId === 132 && <Soal />}
    </>
  );
};

export default connect(
  mapStateToPropsTes,
  mapDispatchToPropsTes
)(AKITSectionHeadManufacturing);
