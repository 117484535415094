import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { userLogout } from "../../../redux/User/user-actions";
import styles from "./UserDropdown.module.css";

const UserDropdown = ({ onHide, isShow, userLogout }) => {
  const logoutHandler = () => {
    userLogout();
    window.location.replace(process.env.REACT_APP_URL);
  };

  return (
    <div className={styles.wrapper}>
      {isShow && (
        <>
          <div className={styles.dropdownWrapper}>
            <div className={styles.triangle}></div>

            <div className={styles.dropdownMenuWrapper}>
              <div className={styles.menuText} onClick={logoutHandler}>
                <span>
                  <FontAwesomeIcon icon={faSignOutAlt} color="#FF4040" />
                </span>
                <p>Logout</p>
              </div>
            </div>
          </div>

          <div
            className={styles.background}
            onClick={() => {
              onHide();
            }}
          ></div>
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    userLogout: () => dispatch(userLogout()),
  };
};

export default connect(null, mapDispatchToProps)(UserDropdown);
