import styles from "./Pagination.module.css";

const Pagination = ({
  pageIndex,
  pageOptions,
  pageCount,
  gotoPage,
  previousPage,
  nextPage,
  canNextPage,
  canPreviousPage,
}) => {
  return (
    <>
      <div className={styles.pagination}>
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"Sebelumnya"}
        </button>{" "}
        <span>
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            className={styles.pageInput}
          />
        </span>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {"Sesudahnya"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
      </div>
    </>
  );
};

export default Pagination;
