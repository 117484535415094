import { useEffect } from "react";
import { connect } from "react-redux";
import { generateEmptyJawabans } from "../../Utils";
import {
  mapDispatchToPropsTes,
  mapStateToPropsTes,
} from "../../Utils/reduxMap";
import Petunjuk from "./Petunjuk";
import Soal from "./Soal";

const IbDirekturDirektoratPendidikan = ({ currentPetunjuk, currentTes, replaceJawabans }) => {
  useEffect(() => {
    generateEmptyJawabans(
      replaceJawabans,
      `Urutan Pengerjaan : 
Kategori Kasus    : 
Respon            :`
    );
  }, [replaceJawabans]);

  return (
    <>
      {currentPetunjuk === 167 && <Petunjuk />}
      {currentPetunjuk !== 167 && currentTes.tesId === 167 && <Soal />}
    </>
  );
};

export default connect(mapStateToPropsTes, mapDispatchToPropsTes)(IbDirekturDirektoratPendidikan);
