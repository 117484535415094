import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import styles from "./KriteriaPsikograph.module.css";

const KriteriaPsikograph = () => {
  const [inputFields, setInputFields] = useState([
    {
      kriteria: "Tidak Disarankan",
      minimum: "",
      maksimum: "",
    },
  ]);

  const changeInputHandler = (index, e) => {
    const values = [...inputFields];
    values[index][e.target.name] = e.target.value;
    setInputFields(values);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(inputFields);
  };

  const deleteHandler = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const addHandler = () => {
    const values = [
      ...inputFields,
      {
        kriteria: "Tidak Disarankan",
        minimum: "",
        maksimum: "",
      },
    ];

    setInputFields(values);
  };

  return (
    <form className={styles.psikographBody} onSubmit={submitHandler}>
      {inputFields.map((inputField, index) => (
        <div className={styles.inputGroup} key={index}>
          <div className={styles.inputWrapper}>
            <label htmlFor="kriteria">Kriteria Psikograph</label>
            <select
              name="kriteria"
              onChange={(e) => changeInputHandler(index, e)}
              value={inputField.kriteria}
            >
              <option value="Tidak Disarankan">Tidak Disarankan</option>
              <option value="Dipertimbangkan">Dipertimbangkan</option>
              <option value="Disarankan">Disarankan</option>
            </select>
            {/* <input
              type="text"
              placeholder="Tidak disarankan"
              value={inputField.nama}
              name="kriteria"
              onChange={(e) => changeInputHandler(index, e)}
            /> */}
          </div>

          <div className={styles.inputWrapper}>
            <label htmlFor="minimum">Nilai Minimum</label>
            <input
              type="text"
              placeholder="70"
              name="minimum"
              value={inputField.minimum}
              onChange={(e) => changeInputHandler(index, e)}
            />
          </div>

          <div className={styles.inputWrapper}>
            <label htmlFor="maksimum">Nilai Maksimum</label>
            <input
              type="text"
              placeholder="80"
              name="maksimum"
              value={inputField.maksimum}
              onChange={(e) => changeInputHandler(index, e)}
            />
          </div>

          <div
            className={styles.trashWrapper}
            onClick={() => deleteHandler(index)}
          >
            <FontAwesomeIcon icon={faTrash} size="lg" color="#fff" />
          </div>
        </div>
      ))}

      <div className={styles.formFooter}>
        <button className={styles.buttonTambah} onClick={addHandler}>
          <span>
            <FontAwesomeIcon icon={faPlus} />
          </span>
          Tambah Kriteria Baru
        </button>
        <button className={styles.buttonSimpan} type="submit">
          Simpan
        </button>
      </div>
    </form>
  );
};

export default KriteriaPsikograph;
