import RoleSettingContent from "../../components/RoleSettingContent";
import SideBar from "../../components/SideBar";
import styles from "./RoleSettingPage.module.css";

const RoleSettingPage = () => {
  return (
    <div className={styles.container}>
      <SideBar />
      <RoleSettingContent />
    </div>
  );
};

export default RoleSettingPage;
