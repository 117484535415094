import { useState } from "react";
import PetunjukMiddle from "./PetunjukMiddle";
import PetunjukContainer from "../../Components/PetunjukContainer";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer
      isModal={isModal}
      tutupModalHandler={tutupModalHandler}
      previousHandler={previousHandler}
      nextHandler={nextHandler}
      index={index}
      lastIndex={4}
    >
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        namaFile="contoh-cfit3a1.png"
        pesanBenar='Dari contoh di atas, gambar tiap-tiap kotak menunjukkan tangkai yang bergerak searah jarum jam. Gambar yang TEPAT untuk mengisi kotak keempat adalah gambar "e".'
        kunci="e"
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        namaFile="contoh-cfit3a2.png"
        pesanBenar='Contoh di atas menunjukkan area hitam yang semakin lama semakin memenuhi area kotak. Dari urutan gambar tersebut, gambar yang tepat untuk mengisi kotak keempat adalah gambar "e", oleh karena itu tekan jawaban "e" tersebut.'
        kunci="e"
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        namaFile="contoh-cfit3a3.png"
        pesanBenar='Pada contoh ini daun bertambah searah jarum jam, oleh karena itu jawaban yang tepat adalah "e".'
        kunci="e"
        previous={previousHandler}
        next={nextHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
