import styles from './Table.module.css';
import { matchSorter } from 'match-sorter';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPenAlt } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import {
  mapDispatchToPropsListCountProject,
  mapStateToPropsListCountProject,
} from '../../../Peserta/Utils/reduxMap';
import { useEffect } from 'react';
import Pagination from './Pagination';
// import Pagination from "./Pagination";

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function Table({ columns, data, listCount, projectName }) {
  // Tabel ini Copy-an dari manajemen peserta
  const history = useHistory();

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: listCount },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    setPageSize(listCount);
    // console.log(projectName);
    setGlobalFilter(projectName);
  }, [listCount, setPageSize, setGlobalFilter, projectName]);

  // Render the UI for your table
  return (
    <>
      <div className={styles.overflow}>
        <table {...getTableProps()} className={styles.table}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className={styles.tableHeader}
              >
                {headerGroup.headers.map((column) => {
                  switch (column.id) {
                    case 'nomor':
                      return (
                        <th
                          className={styles.thNomor}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : ''}
                          </span>
                        </th>
                      );
                    case 'namaProject':
                      return (
                        <th
                          className={styles.thName}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : ''}
                          </span>
                        </th>
                      );
                    case 'modul':
                      return (
                        <th
                          className={styles.thName}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : ''}
                          </span>
                        </th>
                      );
                    default:
                      return (
                        <th
                          className={styles.default}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : ''}
                          </span>
                        </th>
                      );
                  }
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={styles.rowItem}
                  onClick={() =>
                    history.push(`/project/detail/${row.original.id}`)
                  }
                >
                  {row.cells.map((cell, index) => {
                    switch (index) {
                      case 1:
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={styles.tdName}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      case 2:
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={styles.tdTanggal}
                          >
                            <p>{cell.render('Cell')}</p>
                          </td>
                        );
                      case 3:
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={styles.tdmodul}
                          >
                            <p>{cell.render('Cell')}</p>
                          </td>
                        );
                      case 4:
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={styles.cellItem}
                          >
                            {cell.value === 'Belum' && (
                              <p className={styles.belum}>Belum</p>
                            )}
                            {cell.value === 'Proses' && (
                              <p className={styles.proses}>Proses</p>
                            )}
                            {cell.value === 'Selesai' && (
                              <p className={styles.selesai}>Selesai</p>
                            )}
                          </td>
                        );
                      case 5:
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={styles.cellItem}
                          >
                            <div className={styles.tdProgress}>
                              <div className={styles.progressBg}>
                                <div
                                  className={styles.progressFill}
                                  style={{
                                    width: cell.value + '%',
                                    background:
                                      cell.value < 100 ? '#0775B3' : '#5bcf5f',
                                  }}
                                >
                                  <p style={{ margin: '3%' }}>{cell.value}%</p>
                                </div>
                              </div>
                            </div>
                          </td>
                        );
                      case 7:
                        return (
                          <td {...cell.getCellProps()}>
                            <div className={styles.tdAction}>
                              <button
                                className={styles.pencilWrapper}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  history.push(
                                    `/project/edit/${row.original.id}`
                                  );
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faPenAlt}
                                  color="white"
                                  size="lg"
                                />
                              </button>

                              <p className={styles.paperPlaneWrapper}>
                                <FontAwesomeIcon
                                  icon={faPaperPlane}
                                  color="white"
                                  size="lg"
                                />
                              </p>
                            </div>
                          </td>
                        );
                      default:
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={styles.cellItem}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
      />
    </>
  );
}

export default connect(
  mapStateToPropsListCountProject,
  mapDispatchToPropsListCountProject
)(Table);
