import { useEffect, useState } from "react";
import { connect } from "react-redux";
import PetunjukLast from "../../../Components/PetunjukLast";
import Waktu from "../../../Components/Waktu";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import styles from "./Petunjuk.module.css";
import PetunjukMiddle from "./PetunjukMiddle";
import PetunjukZero from "../../../Containers/PetunjukZero";

const Petunjuk = ({ isModal, tutupModalHandler, currentTes }) => {
  const [index, setIndex] = useState(isModal ? 1 : 0);
  const [sisaWaktu, setSisaWaktu] = useState(-1);

  const [answerOne, setAnswerOne] = useState("");
  const [answerTwo, setAnswerTwo] = useState("");

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  useEffect(() => {
    if (!isModal) {
      let psikotesWaktu = JSON.parse(localStorage.getItem("psikotesWaktu"));
      if (!psikotesWaktu) {
        psikotesWaktu = {};
      }

      psikotesWaktu.sisaWaktu = 180; // Waktu yang diberikan untuk menghafal
      localStorage.setItem("psikotesWaktu", JSON.stringify(psikotesWaktu));

      console.log(psikotesWaktu.sisaWaktu);

      setSisaWaktu(psikotesWaktu.sisaWaktu);
    }
  }, [currentTes?.tes?.waktu, isModal]);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      {index === 0 && (
        <PetunjukZero selectedIndex={index}>
          <div className={styles.waktuWrapper}>
            {sisaWaktu >= 0 && (
              <Waktu duration={sisaWaktu} onTimesup={nextHandler} />
            )}
          </div>
          <br />
          <br />
          <p>
            Diberikan waktu 3 menit (yaitu 180 detik) untuk menghafal, hanya
            dihafalkan tidak untuk ditulis atau direkam dengan peralatan lain.
          </p>
          <br />
          <p>
            Segera <b>HAFALKAN</b> kalimat-kalimat berikut untuk mengisi soal di
            halaman selanjutnya:
          </p>
          <br />
          <br />
          <Hafalan kalimat="BUNGA : Soka - Larat - Flamboyan - Yasmin - Dahlia" />
          <Hafalan kalimat="PERKAKAS : Wajan - Jarum - Kikir - Cangkul - Palu" />
          <Hafalan kalimat="BURUNG : Itik - Elang - Walet - Tekukur - Nuri" />
          <Hafalan kalimat="KESENIAN : Quintet - Arca - Opera - Gamelan - Ukiran" />
          <Hafalan kalimat="BINATANG : Musang - Rusa - Beruang - Zebra - Harimau" />
        </PetunjukZero>
      )}

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="Kata yang mempunyai huruf permulaan -<strong>Q</strong>- adalah suatu"
        answer={answerOne}
        setAnswer={setAnswerOne}
        kunci="Kesenian"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban Anda benar! Q adalah quintet, yaitu termasuk jenis <b>Kesenian</b>."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="Kata yang mempunyai huruf permulaan -<strong>Z</strong>- adalah suatu"
        answer={answerTwo}
        setAnswer={setAnswerTwo}
        kunci="Binatang"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban Anda benar! <b>Binatang</b>, karena Z adalah Zebra."
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

const Hafalan = ({ kalimat }) => (
  <>
    <p>
      <strong>{kalimat}</strong>
    </p>
    <br />
  </>
);

const mapStateToProps = (state) => ({
  currentTes: state.peserta?.currentTes,
});

export default connect(mapStateToProps, null)(Petunjuk);
