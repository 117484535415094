import ProjectEditContent from "../../components/ProjectEditContent";
import SideBar from "../../components/SideBar";
import styles from "./ProjectEditPage.module.css";

const ProjectEditPage = () => {
  return (
    <div className={styles.container}>
      <SideBar />
      <ProjectEditContent />
    </div>
  );
};

export default ProjectEditPage;
