import { useEffect } from 'react';
import { connect } from 'react-redux';
import { generateEmptyJawabans } from '../Utils';
import { mapDispatchToPropsTes, mapStateToPropsTes } from '../Utils/reduxMap';
import Petunjuk from './Petunjuk';
import Soal from './Soal';

const BigFive = ({ currentPetunjuk, currentTes, replaceJawabans }) => {
  useEffect(() => {
    generateEmptyJawabans(replaceJawabans);
  }, [replaceJawabans]);
  return (
    <>
      {currentPetunjuk === 128 && <Petunjuk />}
      {currentPetunjuk !== 128 && currentTes.tesId === 128 && <Soal />}
    </>
  );
};

export default connect(mapStateToPropsTes, mapDispatchToPropsTes)(BigFive);
