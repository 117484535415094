import styles from './SoalText.module.css';

const SoalText = ({
  currentSoal,
  currentTes,
  textAlign = 'center',
  fontSize = 20,
  lineHeight = '56px',
  text,
  soalNo = 0,
  width = '80%',
}) => {
  let align =
    (currentTes.tesId >= 95 && currentTes.tesId <= 100) ||
    (currentTes.tesId >= 122 && currentTes.tesId <= 123)
      ? 'left'
      : textAlign;
  let fontS = currentTes.tesId >= 95 && currentTes.tesId <= 100 ? 15 : fontSize;
  let lh =
    (currentTes.tesId >= 95 && currentTes.tesId <= 100) ||
    (currentTes.tesId >= 122 && currentTes.tesId <= 123)
      ? 1.5
      : lineHeight;
  let soal = '';
  if (parseInt(currentTes.tesId) === 92) {
    soal = JSON.parse(localStorage.getItem('soalHollEgatek'));
  }

  return (
    <p
      className={styles.soal}
      dangerouslySetInnerHTML={{
        __html:
          text ?? parseInt(currentTes.tesId) === 92
            ? soal[soalNo] || text
            : currentSoal?.soal,
      }}
      style={{ textAlign: align, fontSize: fontS, lineHeight: lh, width }}
    />
  );
};

export default SoalText;
