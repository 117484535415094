import styles from "./Left.module.css";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Left = () => {
  return (
    <div className={styles.left}>
      <h1>Uraian</h1>
      <h1>Kepribadian</h1>
      <div className={styles.fileIconWrapper}>
        <FontAwesomeIcon icon={faFileAlt} size="lg" />
      </div>
      <div className={styles.paragraphWrapper}>
        <p>
          <strong>Sdr.Brigitta pradnya</strong>, memiliki kapasista intelegensi
          yang tergolong jauh di bawah rata-rata dengan demikian dapat di
          katakan bahwa ia memili daya berpikir yang terbatas dengan keinginan
          serta antusiame untukbelajar dari pengalaman.
        </p>
        <p>
          <strong>Sdr.Brigitta pradnya</strong>, memiliki kapasista intelegensi
          yang tergolong jauh di bawah rata-rata dengan demikian dapat di
          katakan bahwa ia memili daya berpikir yang terbatas dengan keinginan
          serta antusiame untukbelajar dari pengalaman.
        </p>
        <p>
          <strong>Sdr.Brigitta pradnya</strong>, memiliki kapasista intelegensi
          yang tergolong jauh di bawah rata-rata dengan demikian dapat di
          katakan bahwa ia memili daya berpikir yang terbatas dengan keinginan
          serta antusiame untukbelajar dari pengalaman.
        </p>
        <p>
          <strong>Sdr.Brigitta pradnya</strong>, memiliki kapasista intelegensi
          yang tergolong jauh di bawah rata-rata dengan demikian dapat di
          katakan bahwa ia memili daya berpikir yang terbatas dengan keinginan
          serta antusiame untukbelajar dari pengalaman.
        </p>
        <p>
          <strong>Sdr.Brigitta pradnya</strong>, memiliki kapasista intelegensi
          yang tergolong jauh di bawah rata-rata dengan demikian dapat di
          katakan bahwa ia memili daya berpikir yang terbatas dengan keinginan
          serta antusiame untukbelajar dari pengalaman.
        </p>
      </div>
    </div>
  );
};

export default Left;
