import { useEffect } from "react";
import { connect } from "react-redux";
import { generateEmptyJawabans } from "../../Utils";
import {
  mapDispatchToPropsTes,
  mapStateToPropsTes,
} from "../../Utils/reduxMap";
import Petunjuk from "./Petunjuk";
import Soal from "./Soal";

const IstGe = ({ currentPetunjuk, currentTes, replaceJawabans }) => {
  useEffect(() => generateEmptyJawabans(replaceJawabans), [replaceJawabans]);

  return (
    <>
      {currentPetunjuk === 11 && <Petunjuk />}
      {currentPetunjuk !== 11 && currentTes.tesId === 11 && <Soal />}
    </>
  );
};

export default connect(mapStateToPropsTes, mapDispatchToPropsTes)(IstGe);
