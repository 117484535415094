import Left from "./Left";
import Right from "./Right";
import styles from "./SectionThree.module.css";

const SectionThree = () => {
  return (
    <div className={styles.container}>
      <Left />
      <Right />
    </div>
  );
};

export default SectionThree;
