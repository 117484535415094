import { useEffect } from "react";
import { connect } from "react-redux";
import { generateEmptyJawabans } from "../../../Utils";
import {
  mapDispatchToPropsTes,
  mapStateToPropsTes,
} from "../../../Utils/reduxMap";
import Petunjuk from "../../Components/Petunjuk";
import Soal from "./Soal";

const TpaSixtyOne = ({ currentPetunjuk, currentTes, replaceJawabans }) => {
  useEffect(() => generateEmptyJawabans(replaceJawabans), [replaceJawabans]);

  return (
    <>
      {currentPetunjuk === 110 && <Petunjuk jumlahSoal={20} jumlahMenit={8} />}
      {currentPetunjuk !== 110 && currentTes.tesId === 110 && <Soal />}
    </>
  );
};

export default connect(mapStateToPropsTes, mapDispatchToPropsTes)(TpaSixtyOne);
