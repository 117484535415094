import { useHistory } from "react-router";
import styles from "./Prepare.module.css";

const Prepare = () => {
  const history = useHistory();
  const mulaiHandler = () => {
    history.push("/psikotes/mengerjakan");
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.popup}>
          <p>Silahkan Tekan Tombol Mulai Jika Anda Telah Siap Mengerjakan!</p>
          <button onClick={mulaiHandler}>Mulai</button>
        </div>
      </div>
    </div>
  );
};

export default Prepare;
