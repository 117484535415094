import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import SoalContainer from '../../../Containers/SoalContainer';
import SoalContent from '../../../Containers/SoalContent';
import SoalHeader from '../../../Components/SoalHeader';
import SoalText from '../../../Components/SoalText';
import PetunjukModal from '../../../Components/PetunjukModal';
import Petunjuk from '../../Components/Petunjuk';
import ButtonSoal from '../../../Components/ButtonSoal';
import { setAnswerLS, setJawabansLS, setSisaWaktuLS } from '../../../Utils';
import {
  mapDispatchToPropsSoal,
  mapStateToPropsSoal,
} from '../../../Utils/reduxMap';
import InputWrapper from '../../../Components/InputWrapper';

const Soal = ({ currentSoal, currentTes, replaceJawabans, menit }) => {
  const [answer, setAnswer] = useState('');
  const [sisaWaktu, setSisaWaktu] = useState(-1);
  const [isShowSelesaiModal, setIsShowSelesaiModal] = useState(false);
  const [isShowPetunjukModal, setIsShowPetunjukModal] = useState(false);

  useEffect(() => {
    setSisaWaktuLS(currentTes?.tes?.waktu, setSisaWaktu);
  }, [currentTes?.tes?.waktu]);

  useEffect(() => {
    setAnswer('');
    setAnswerLS(currentSoal, setAnswer);
  }, [currentSoal]);

  useEffect(() => {
    setJawabansLS(answer, replaceJawabans);
  }, [answer, replaceJawabans]);

  const imgStyle = {
    width: '80%',
    textAlign: 'center',
  };
  return (
    <SoalContainer
      isShowSelesaiModal={isShowSelesaiModal}
      setIsShowSelesaiModal={setIsShowSelesaiModal}
    >
      <SoalHeader
        setIsShowPetunjukModal={setIsShowPetunjukModal}
        sisaWaktu={sisaWaktu}
      />
      <SoalContent withNomor={false}>
        <div
          style={{
            width: '80%',
            marginTop: '50px',
          }}
        >
          <SoalText width="" textAlign="left" lineHeight="normal" />
        </div>

        <br />
        <br />
        <InputWrapper>
          <textarea
            type="text"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            placeholder="Ketikkan jawaban di sini"
          />
        </InputWrapper>

        <ButtonSoal setIsShowSelesaiModal={setIsShowSelesaiModal} />
      </SoalContent>
      <PetunjukModal
        isShow={isShowPetunjukModal}
        onHide={() => setIsShowPetunjukModal(false)}
      >
        <Petunjuk
          isModal
          tutupModalHandler={() => setIsShowPetunjukModal(false)}
          menit={menit}
        />
      </PetunjukModal>
    </SoalContainer>
  );
};

export default connect(mapStateToPropsSoal, mapDispatchToPropsSoal)(Soal);
