import { connect } from 'react-redux';
import {
  changeCurrentPetunjuk,
  changeCurrentSoal,
  changeCurrentTes,
  replaceJawabans,
} from '../../../../redux/Peserta/peserta-actions';
import { mapStateToPropsSoal } from '../../Utils/reduxMap';
import { nextTesHandlerUtil } from '../../Utils';
import styles from './PilihanOrange.module.css';
import { API } from '../../../../services/API';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const PilihanOrange = ({
  soalName,
  jwb,
  label,
  onClick,
  changeCurrentSoal,
  changeCurrentTes,
  changeCurrentPetunjuk,
  currentTes,
  soal,
  soalNo,
  setSoalNo,
  soalls,
}) => {
  let currentTestId = currentTes?.tesId
    ? parseInt(currentTes.tesId)
    : currentTes?.tesId;

  const history = useHistory();

  const [soalHabisEgatek, setSoalHabisEgatek] = useState(false);

  const switchSoalHandler = (soal) => {
    changeCurrentSoal(soal);

    const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
    psikotesLS.currentSoal = soal;
    localStorage.setItem('psikotesTalenta', JSON.stringify(psikotesLS));
  };

  const pilihanOnClickHandler = async (option) => {
    const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));

    switch (option) {
      case 'TIDAK SESUAI':
        option = '1';
        break;
      case 'CUKUP SESUAI':
        option = '2';
        break;
      case 'SANGAT SESUAI':
        option = '3';
        break;
      case 'A':
        option = 'A';
        break;
      case 'B':
        option = 'B';
        break;
      case 'Suka':
        option = 'Suka';
        break;
      case 'Tidak Suka':
        option = 'Tidak Suka';
        break;
      case 'Mampu':
        option = 'Mampu';
        break;
      case 'Tidak Mampu':
        option = 'Tidak Mampu';
        break;
      case 'Tertarik':
        option = 'Tertarik';
        break;
      case 'Tidak Tertarik':
        option = 'Tidak Tertarik';
        break;
      case 'SUKA':
        // console.log('SUKA', 'SUKA dipilih');
        option = 'Suka';
        break;
      case 'TIDAK SUKA':
        // console.log('TIDAK SUKA', 'TIDAK SUKA dipilih');
        option = 'Tidak Suka';
        break;
      case 'MAMPU':
        // console.log('MAMPU', 'MAMPU dipilih');
        option = 'Mampu';
        break;
      case 'TIDAK MAMPU':
        // console.log('MAMPU', 'TIDAK MAMPU dipilih');
        option = 'Tidak Mampu';
        break;
      case 'TERTARIK':
        // console.log('TERTARIK', 'TERTARIK dipilih');
        option = 'Tertarik';
        break;
      case 'TIDAK TERTARIK':
        // console.log('TERTARIK', 'TIDAK TERTARIK dipilih');
        option = 'Tidak Tertarik';
        break;
      case 'Ya':
        option = 'Ya';
        break;
      case 'Tidak':
        option = 'Tidak';
        break;
      case 'Tidak Tahu':
        option = 'Tidak Tahu';
        break;
      case 'Sangat Sesuai':
        option = 'Sangat Sesuai';
        break;
      case 'Sesuai':
        option = 'Sesuai';
        break;
      case 'Netral':
        option = 'Netral';
        break;
      case 'Tidak Sesuai':
        option = 'Tidak Sesuai';
        break;
      case 'Sangat Tidak Sesuai':
        option = 'Sangat Tidak Sesuai';
        break;
      default:
        console.warn('Peringatan option tidak terdeteksi!');
        option = null;
        break;
    }

    if (currentTestId == 92) {
      setSoalNo(soalNo + 1);
    }

    psikotesLS.jawabans = [...psikotesLS.jawabans].map((jawaban) => {
      if (jawaban.soalId === psikotesLS.currentSoal.id) {
        return {
          pesertaId: psikotesLS.peserta.id,
          tesId: psikotesLS.currentTes.tesId,
          soalId: psikotesLS.currentSoal.id,
          nomor: psikotesLS.currentSoal.nomor,
          jawaban: option,
        };
      }

      return jawaban;
    });

    localStorage.setItem('psikotesTalenta', JSON.stringify(psikotesLS));
    replaceJawabans(psikotesLS.jawabans);
    const batas = soalls
      ? soalls.length - 1
      : psikotesLS.currentTes.tes.soals.length;

    if (psikotesLS.currentSoal.nomor >= batas) {
      if (psikotesLS.currentSoal.nomor >= soalls?.length - 1) {
        setSoalHabisEgatek(true);
      }

      const response = await nextTesHandlerUtil(
        changeCurrentTes,
        changeCurrentSoal,
        changeCurrentPetunjuk
      );

      if (response === false) {
        history.push('/psikotes/finish');
      }

      if (currentTestId === 88) {
        const jawaban = JSON.parse(
          localStorage.getItem('psikotesTalenta')
        ).jawabans;
        // const jawabans = [];
        // jawaban.forEach((j) => {
        //   if (parseInt(j.tesId) === 88) {
        //     jawabans.push(j.jawaban);
        //   }
        // });

        let jawabans = jawaban
          .filter((j) => j.tesId === 88)
          .map((j) => j.jawaban);

        await API.get('/hollEgatek', {
          params: {
            pesertaId: jawaban[0].pesertaId,
            jawabans: JSON.stringify(jawabans),
          },
        })
          .then((r) => {
            localStorage.setItem('soalHollEgatek', JSON.stringify(r.data.data));
            localStorage.setItem(
              'twoCodesHolland',
              JSON.stringify(r.data?.twoCodes)
            );
          })
          .catch((e) => {
            localStorage.setItem('internetProblem', true);
            localStorage.setItem('soalHollEgatek', JSON.stringify([]));
          });
      }

      const psikotesStatusSelesai = JSON.parse(
        localStorage.getItem('statusTesSelesai')
      );

      let previousTesId =
        psikotesLS.modul.urutanTes[psikotesLS?.currentTes.urutan - 1]?.tesId;

      if (typeof psikotesStatusSelesai.statusTesSelesai === 'number') {
        psikotesStatusSelesai.statusTesSelesai = [
          psikotesStatusSelesai.statusTesSelesai,
        ];
      }
      if (
        psikotesStatusSelesai.statusTesSelesai.indexOf(previousTesId) === -1
      ) {
        if (psikotesLS.peserta.project.isCuttingPoint) {
          await API.post('/jawabanPerTes', {
            pesertaId: psikotesLS.peserta.id,
            jawabans: psikotesLS.jawabans,
            tesId: previousTesId,
          }).catch((err) => localStorage.setItem('internetProblem', true));
        }

        psikotesStatusSelesai.statusTesSelesai = [
          ...psikotesStatusSelesai.statusTesSelesai,
          previousTesId,
        ];

        localStorage.setItem(
          'statusTesSelesai',
          JSON.stringify(psikotesStatusSelesai)
        );

        if (navigator.onLine) {
          await API.patch('/edit-status-tes-selesai', {
            kodeTes: psikotesLS.aktivasi,
            statusTesSelesai: JSON.stringify(
              psikotesStatusSelesai.statusTesSelesai
            ),
          });
        }
      }
    } else {
      switchSoalHandler(
        psikotesLS.currentTes.tes.soals[psikotesLS.currentSoal.nomor + 1 - 1]
      );
    }
  };
  return (
    <>
      <div
        className={styles.pilihanItem}
        onClick={() => {
          onClick
            ? onClick()
            : jwb
            ? pilihanOnClickHandler(jwb)
            : pilihanOnClickHandler(label);
        }}
      >
        <p dangerouslySetInnerHTML={{ __html: label }} />
      </div>
      {soalHabisEgatek && (
        <>
          <div className={styles.container}>
            <div className={styles.wrapper}>
              <p>HARAP TUNGGU SEBENTAR!</p>
              <p className={styles.mengumpulkanButton}>
                Sedang mengumpulkan jawaban...
              </p>
            </div>
          </div>
          <div className={styles.background} style={{ color: 'white' }} />
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  replaceJawabans: (jawabans) => dispatch(replaceJawabans(jawabans)),
  changeCurrentSoal: (currentSoal) => dispatch(changeCurrentSoal(currentSoal)),
  changeCurrentTes: (currentTes) => dispatch(changeCurrentTes(currentTes)),
  changeCurrentPetunjuk: (currentPetunjuk) =>
    dispatch(changeCurrentPetunjuk(currentPetunjuk)),
});

export default connect(mapStateToPropsSoal, mapDispatchToProps)(PilihanOrange);
