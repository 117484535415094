import { useEffect, useState } from "react";
import { connect } from "react-redux";
import PetunjukLast from "../../../Components/PetunjukLast";
import Waktu from "../../../Components/Waktu";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import styles from "./Petunjuk.module.css";
import PetunjukMiddle from "./PetunjukMiddle";
import PetunjukZero from "../../../Containers/PetunjukZero";

const Petunjuk = ({ isModal, tutupModalHandler, currentTes }) => {
  const [index, setIndex] = useState(isModal ? 1 : 0);
  const [sisaWaktu, setSisaWaktu] = useState(-1);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  useEffect(() => {
    if (!isModal) {
      let psikotesWaktu = JSON.parse(localStorage.getItem("psikotesWaktu"));
      if (!psikotesWaktu) {
        psikotesWaktu = {};
      }

      psikotesWaktu.sisaWaktu = 60; // Waktu yang diberikan untuk menghafal
      localStorage.setItem("psikotesWaktu", JSON.stringify(psikotesWaktu));

      console.log(psikotesWaktu.sisaWaktu);

      setSisaWaktu(psikotesWaktu.sisaWaktu);
    }
  }, [currentTes?.tes?.waktu, isModal]);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      {index === 0 && (
        <PetunjukZero selectedIndex={index}>
          <div className={styles.waktuWrapper}>
            {sisaWaktu >= 0 && (
              <Waktu duration={sisaWaktu} onTimesup={nextHandler} />
            )}
          </div>
          <br />
          <br />
          <p>
            Diberikan waktu 1 menit (yaitu 60 detik) untuk menghafal, hanya
            dihafalkan tidak untuk ditulis atau direkam dengan peralatan lain.
          </p>
          <br />
          <p>
            Segera <b>HAFALKAN</b> kalimat-kalimat berikut untuk mengisi soal di
            halaman selanjutnya:
          </p>
          <br />
          <br />
          <Hafalan kalimat="JENIS OLAHRAGA  : Golf - Motorsport" />
          <Hafalan kalimat="BAHAN MAKANAN  : Kembang Kol - Tepung" />
          <Hafalan kalimat="KOTA  : Hiroshima - Seoul - Birmingham" />
          <Hafalan kalimat="PEKERJAAN   : Ahli Ilmu Alam - Pastor - Dokter Gigi" />
          <Hafalan kalimat="BENGKEL KERJA   : Ruang Konser - Lumbung - Jembatan" />
        </PetunjukZero>
      )}

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="Kata dengan huruf awal -<strong>S</strong>- adalah"
        kunci="C. Kota"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="S adalah Seoul, yaitu termasuk jenis Kota oleh karena itu jawaban yang tepat adalah <strong>C</strong>."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="Kata dengan huruf awal -<strong>J</strong>- adalah"
        kunci="E. Bengkel Kerja"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban yang tepat adalah <strong>E</strong> Bengkel Kerja karena J adalah Jembatan."
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

const Hafalan = ({ kalimat }) => (
  <>
    <p>
      <strong>{kalimat}</strong>
    </p>
    <br />
  </>
);

const mapStateToProps = (state) => ({
  currentTes: state.peserta?.currentTes,
});

export default connect(mapStateToProps, null)(Petunjuk);
