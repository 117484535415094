import { useState } from 'react';
import PetunjukLast from '../../../Components/PetunjukLast';
import PetunjukContainer from '../../../Containers/PetunjukContainer';
import styles from './Petunjuk.module.css';
import Footer from '../../../Components/PetunjukMiddle/Footer';

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <div
        className={styles.petunjukZero}
        style={{ display: index === 0 ? 'flex' : 'none' }}
      >
        <p>
          Anda adalah Debi, seorang karyawan yang telah menduduki posisi sebagai
          Business Analyst Supervisor selama 6 bulan di sebuah perusahaan swasta
          yang bergerak di bidang customer relationship management bernama
          Talenta World. Talenta World mengemban tugas penting dalam rantai
          bisnis otomotif Talenta Group dengan menyelenggarakan rangkaian
          program Customer Relationship Management (CRM). Talenta World
          memfasilitasi terciptanya nilai tambah bagi pelanggan dan hubungan
          yang berkelanjutan dalam setiap tahap kepemilikan kendaraan merk
          Talenta.
        </p>

        <p>
          Hari ini, Senin 10 Januari adalah hari pertama Anda kembali masuk
          kerja setelah 3 hari mengambil cuti untuk liburan bersama keluarga.
          Pada meja kerja Anda tersusun rapi beberapa memo dan dokumen kerja
          yang menunggu untuk direspon:
        </p>
        <p>Terdapat tiga tugas yang Anda harus lakukan:</p>
        <ol>
          <li>
            Memberikan perioritas terhadap semua memo / dokumen kerja yang
            masuk, kedalam tiga kategori:
            <ul>
              <li>High Priority</li>
              <li>Moderate Priority</li>
              <li>Low Priority</li>
            </ul>
          </li>
          <li>
            Menuliskan secara spesifik <b>Rencana Tindakan</b> yang akan
            dilakukan untuk merespon setiap memo dan dokumen kerja.
          </li>
          <li>
            Menulis rencana kerja Program Pengembangan Staff. Detil mengenai
            tugas ini disajikan pada memo terakhir atau memo no.9
          </li>
        </ol>

        <p>
          <b>
            Waktu keseluruhan yang Anda perlukan untuk melakukan semua tugas
            tersebut adalah 60 menit.
          </b>
        </p>

        <div className={styles.buttonWrapper}>
          <button onClick={nextHandler}>Tutup</button>
        </div>
      </div>

      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 1 ? 'flex' : 'none' }}
      >
        <center>
          <img
            src={`${process.env.REACT_APP_SERVER_URL}/in-basket/ro-manager/struktur_organisasi_ro.png`}
            style={{ width: '80%' }}
          />
        </center>
        <br />
        <br />
        <b>
          <p>Latar Belakang :</p>
        </b>
        <p>
          Talenta World berdiri di Jakarta, pada 22 Februari 2002 sebagai unit
          bisnis PT Talenta Indonesia Raya Tbk yang bertujuan memberikan nilai
          tambah bagi pelanggan PT Talenta Indonesia Raya Tbk. Nilai tambah
          tersebut diberikan dalam bentuk layanan-layanan yang menjadikan
          saat-saat memiliki dan mengendarai mobil menjadi semakin aman dan
          nyaman, sehingga pelanggan merasakan “Peace of Mind on the Road”.
        </p>
        <p>
          Sejalan dengan perkembangan bisnis perusahaan, Talenta World tidak
          hanya melayani kebutuhan para pelanggan otomotif PT Talenta Indonesia
          Raya Tbk namun juga menjadi mitra pelaksanaan program Customer
          Relationship Management (CRM) bagi perusahaan-perusahaan di bawah grup
          Talenta. Sejak tahun 2010 Talenta World mulai fokus pada bisnis
          contact center, sebagai salah satu ujung tombak pelaksanaan program
          CRM.
        </p>
        <p>
          Talenta World menawarkan solusi pengelolaan contact center yang
          menyeluruh dengan memadukan manajemen contact center dan aktivitas CRM
          sesuai kebutuhan perusahaan. Talenta World melakukan dan memberikan
          rofess hasil interaksi dengan pelanggan, yang masuk melalui contact
          center, sehingga perusahaan mitra dapat memanfaatkan hasil rofess
          tersebut sebagai masukan untuk membuat kebijakan. Purifikasi dan
          pengayaan (enrichment) data-data pelanggan juga Talenta World lakukan
          untuk meningkatkan akurasi data sehingga dapat memberikan pelayanan
          yang lebih personal ke pelanggan.
        </p>
        <p>
          Komitmen Talenta World adalah memberikan pelayanan terbaik kepada
          pelanggan dan mitra kerja. Untuk mendukung pelayananan yang terbaik
          kepada pelanggan dan mitra kerja, Talenta World memiliki lima kantor
          cabang (Regional Office) dan 30 titik layanan (assist point) yang
          tersebar di seluruh Indonesia, serta lebih dari 200 karyawan
          professional yang berpengalaman di bidangnya.
        </p>
        <Footer previous={previousHandler} next={nextHandler} condition />
      </div>

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={2}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
