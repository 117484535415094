import { useState } from 'react';
import PetunjukLast from '../../../Components/PetunjukLast';
import PetunjukContainer from '../../../Containers/PetunjukContainer';
import styles from './Petunjuk.module.css';
import Footer from '../../../Components/PetunjukMiddle/Footer';

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <div
        className={styles.petunjukZero}
        style={{ display: index === 0 ? 'flex' : 'none' }}
      >
        <p>
        Anda adalah Evan, seorang Kepala Unit Wilayah Kerja (UWK) yang baru di PT Surveyor Talenta Consulting Raya untuk wilayah 
        Republik Wakanda. PT Surveyor Talenta Consulting Raya (STCR) adalah sebuah perusahaan yang kegiatan bisnisnya bergerak di 
        bidang independen superintending Indonesia.  STCR adalah perusahaan penyedia beberapa jasa dalam banyak aspek pertambangan 
        dan kegiatan terkait yaitu menyediakan segala solusi dalam industri batubara dan mineral. SCTR memiliki beberapa kantor cabang yang tersebar di dalam negeri dan beberapa kantor unit wilayah kerja di luar negeri. Saat ini Anda telah bekerja di 
        perusahaan selama kurang lebih 5 bulan untuk kantor UWK Republik Wakanda. Tugas utama yang Anda miliki adalah mengendalikan, mengarahkan, memantau dan mengevaluasi seluruh kegiatan pengembangan, pemasaran dan operasional kantor wilayah Republik Wakanda guna memastikan target Kantor UWK tercapai dengan efektif dan efisien.
        </p>

        <p>
        Hari ini, Senin 10 Januari 20XY adalah hari pertama Anda kembali masuk kerja setelah 3 hari mengambil cuti untuk liburan 
        bersama keluarga. Pada hari ini juga Wakil Kepala UWK (Bapak Dika) sedang melakukan perjalan dinas untuk mengikuti 
        workshop selama 7 hari dan tidak dapat untuk dihubungi. Pada meja kerja Anda tersusun rapi beberapa memo dan dokumen kerja 
        untuk Anda dan Wakil Kepala UWK (Bapak Dika) yang menunggu untuk direspon:
        </p>
        <p>Terdapat tiga tugas yang Anda harus lakukan:</p>
        <ol>
          <li>
            Memberikan perioritas terhadap semua memo / dokumen kerja yang
            masuk, kedalam tiga kategori:
            <ul>
              <li>High Priority</li>
              <li>Moderate Priority</li>
              <li>Low Priority</li>
            </ul>
          </li>
          <li>
            Menuliskan secara spesifik Rencana Tindakan yang akan dilakukan
            untuk merespon setiap memo dan dokumen kerja.
          </li>
          <li>
            Menulis rencana kerja Program Pengembangan Staff. Detil mengenai
            tugas ini disajikan pada memo terakhir atau memo no.8
          </li>
        </ol>

        <p style={{ fontWeight: 'bold' }}>
          Waktu keseluruhan yang Anda perlukan untuk melakukan semua tugas
          tersebut adalah 60 menit.
        </p>

        <div className={styles.buttonWrapper}>
          <button onClick={nextHandler}>Tutup</button>
        </div>
      </div>

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={1}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
