import {
  faBook,
  faBuilding,
  faCubes,
  faHome,
  faListAlt,
  faUser,
  faUserEdit,
} from '@fortawesome/free-solid-svg-icons';

export const navigations = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    url: '/dashboard',
    icon: faHome,
    meta: {
      isAdmin: true,
      isClientSekolah: false,
    },
  },
  {
    id: 'modul',
    title: 'Modul',
    url: '/moduls',
    icon: faBook,
    children: [
      {
        id: 'DaftarModul',
        title: 'Daftar Modul',
        url: '/moduls',
      },
      {
        id: 'BuatModul',
        title: 'Buat Modul',
        url: '/moduls/add',
      },
      {
        id: 'UpdateModuls',
        title: 'Ubah Urutan Tes',
        url: '/moduls/update',
      },
    ],
  },
  {
    id: 'project',
    title: 'Projek',
    url: '/project',
    icon: faListAlt,
    meta: {
      isAdmin: true,
      isClientSekolah: false,
    },
  },
  {
    id: 'manajemen-peserta',
    title: 'Manajemen Peserta',
    url: '/manajemen-peserta',
    icon: faUser,
    meta: {
      isAdmin: true,
      isClientSekolah: false,
    },
  },
  {
    id: 'role-setting',
    title: 'Role Setting',
    url: '/role-setting',
    icon: faUserEdit,
    meta: {
      isAdmin: true,
      isClientSekolah: false,
    },
  },
  {
    id: 'perusahaan',
    title: 'Perusahaan',
    url: '/perusahaan',
    icon: faBuilding,
    children: [
      {
        id: 'konfigurasi',
        title: 'Konfigurasi',
        url: '/perusahaan/konfigurasi/profile',
      },
      {
        id: 'standar-nilai-aspek',
        title: 'Standar Nilai Aspek',
        url: '/perusahaan/standar-nilai-aspek/psikograph',
      },
      {
        id: 'unggah-tes-peserta',
        title: 'Unggah Tes Peserta',
        url: '/perusahaan/unggah-test-peserta',
      },
      {
        id: 'riwayat-kuota',
        title: 'Riwayat Kuota',
        url: '/perusahaan/riwayat-kuota',
      },
    ],
  },
];
