import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import Petunjuk from '../Petunjuk';
import ButtonSoal from 'components/Peserta/Components/ButtonSoal';
import Navigation from 'components/Peserta/Components/Navigation';
import PetunjukModal from 'components/Peserta/Components/PetunjukModal';
import SoalHeader from 'components/Peserta/Components/SoalHeader';
import SoalText from 'components/Peserta/Components/SoalText';
import PilihanWrapper from 'components/Peserta/Components/PilihanWrapper';
import PilihanText from 'components/Peserta/Components/PilihanText';
import { mapDispatchToPropsSoal, mapStateToPropsSoal } from 'components/Peserta/Utils/reduxMap';
import SoalContainer from 'components/Peserta/Containers/SoalContainer';
import SoalContent from 'pages/Testing/SoalContent';
import { setAnswerLS, setJawabansLS, setSisaWaktuLS } from 'components/Peserta/Utils';

// Harus dibuat komponen sendiri, jadi
// nanti ada komponen soals dan component soal
// jamak dan singular
// karena state answernya bertabrakan
// soal yang udah kepilih, pas nav kesitu lagi, ui nya ilang, ijo2nya itu lho

const Soal = ({ currentSoal, currentTes, replaceJawabans }) => {
  const [answer, setAnswer] = useState('');
  const [sisaWaktu, setSisaWaktu] = useState(-1);
  const [isShowSelesaiModal, setIsShowSelesaiModal] = useState(false);
  const [isShowPetunjukModal, setIsShowPetunjukModal] = useState(false);

  useEffect(() => {
    setSisaWaktuLS(currentTes?.tes?.waktu, setSisaWaktu);
  }, [currentTes?.tes?.waktu]);

  useEffect(() => {
    setAnswer('');
    setAnswerLS(currentSoal, setAnswer);
  }, [currentSoal]);

  useEffect(() => {
    setJawabansLS(answer, replaceJawabans);
  }, [answer, replaceJawabans]);

  const arrayPilihan = JSON.parse(currentSoal.pilihan);
  return (
    <SoalContainer
      isShowSelesaiModal={isShowSelesaiModal}
      setIsShowSelesaiModal={setIsShowSelesaiModal}
    >
      <SoalHeader setIsShowPetunjukModal={setIsShowPetunjukModal} />
      <SoalContent>
        <SoalText />

        <PilihanWrapper variant="text" col={2}>
          {arrayPilihan.map((option, index) => (
            <PilihanText
              key={index}
              label={option.teks}
              answer={answer}
              jwb={JSON.stringify(option)}
              setIsShowSelesaiModal={setIsShowSelesaiModal}
            />
          ))}
        </PilihanWrapper>
        <ButtonSoal setIsShowSelesaiModal={setIsShowSelesaiModal} />
        <Navigation />
      </SoalContent>

      <PetunjukModal isShow={isShowPetunjukModal} onHide={() => setIsShowPetunjukModal(false)}>
        <Petunjuk isModal tutupModalHandler={() => setIsShowPetunjukModal(false)} />
      </PetunjukModal>
    </SoalContainer>
  );
};

export default connect(mapStateToPropsSoal, mapDispatchToPropsSoal)(Soal);
