import styles from "./SectionNine.module.css";
import Left from "./Left";
import Right from "./Right";

const SectionNine = () => {
  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <Left />
        <Right />
      </div>
      <div className={styles.blueLines}></div>
    </div>
  );
};

export default SectionNine;
