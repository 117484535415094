import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import styles from "./Petunjuk.module.css";
import Footer from "../../../Components/PetunjukMiddle/Footer";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <div
        className={styles.petunjukZero}
        style={{ display: index === 0 ? "flex" : "none" }}
      >
        <p>
          Pada persoalan berikut, Anda akan menghadapi beberapa kasus yang
          terdiri dari memo/ dokumen kerja. Tugas anda adalah membuat respon
          atas memo/ dokumen tersebut dan membagi permasalahan-permasalahan
          tersebut ke dalam 3 kategori :
        </p>
        <ol>
          <b>
            <li>High Priority</li>
          </b>
          <b>
            <li>Moderate Priority</li>
          </b>
          <b>
            <li>Low Priority</li>
          </b>
        </ol>

        <p>
          Anda bebas untuk mengerjakan dokumen mana yang akan anda selesaikan
          terlebih dahulu. Yang terpenting berikan kategori pada setiap
          permasalahan dan tuliskan urutan permasalahan menurut masalah yang
          paling prioritas untuk diselesaikan.
        </p>

        <p>
          Tulislah respon kamu untuk setiap permasalahan tadi pada kolom
          jawaban, setelah yakin tekan <strong>Lanjut</strong> untuk mengisi
          jawabannya
        </p>

        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>

        <p>
          <font color="red">
            Jangan ditutup terlebih dulu petunjuk ini, baca dan pahami
            instruksi. Apabila petunjuk ini ditutup maka tes dimulai dan waktu
            berjalan.
          </font>
        </p>

        <div className={styles.buttonWrapper}>
          <button onClick={nextHandler}>Tutup</button>
        </div>
      </div>

      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 1 ? "flex" : "none" }}
      >
        <center>
          <p>
            <b>
              <u>INFORMASI SEPUTAR PERUSAHAAN</u>
            </b>
          </p>
        </center>
        <center>
          <p>PT. TALENTA MANUFACTURING INDONESIA</p>
        </center>
        <span>
          PT. Talenta Manufacturing Indonesia adalah perusahaan yang sudah
          berdiri sejak tahun 1967. PT. Talenta Manufacturing Indonesia bergerak
          di bidang manufaktur spion motor. Pembeli produk yang dihasilkan oleh
          PT. Talenta Manufacturing Indonesia adalah produsen kendaraan
          bermotor. Saat ini, PT. Talenta Manufacturing Indonesia memiliki 3
          klien besar dari perusahaan yang berbeda-beda, untuk 2 buah merk yang
          memimpin pasar Indonesia. Sesuai dengan banyaknya jumlah produksi
          kendaraan bermotor yang dilakukan oleh customer, maka tahun ini,
          produksi spion di PT. Talenta Manufacturing Indonesia sangat meningkat
          (2 – 3 kali lipat dibandingkan tahun lalu). Sebagai perusahaan
          manufaktur, kegiatan produksi merupakan kegiatan utama. Pada bagian
          produksi, terdapat beberapa lini, yaitu: pembuatan bingkai spion,
          pembuatan tangkai spion, pembuatan kaca spion, dan perakitan spion.
          Pada Lini Bingkai Spion, bertugas untuk memproduksi bingkai spion
          sesuai dengan bentuk yang diminta customer (sesuai jenis dan tipe
          motor). Lini Tangkai Spion, bertugas untuk memproduksi tangkai yang
          (dari besi), berdasarkan spesifikasi spion yang diminta customer. Lini
          Kaca Spion, bertugas untuk memproduksi kaca (melakukan pemotongan kaca
          sesuai bentuk spion yang diminta oleh customer). Setelah ketiga lini
          menghasilkan produk, maka dirakit oleh Lini Rakit Spion, untuk
          kemudian dikemas sehingga siap dikirimkan ke customer (sesuai jumlah
          dan tenggat waktu order). Seluruh aktivitas produksi, mengikuti
          instruksi dan rancangan yang sudah dibuat oleh bagian PPIC. Kepala
          Lini bertugas untuk memimpin jalannya produksi agar memenuhi target
          (kuantitas dan kualitas) sesuai dengan jadwal yang sudah ditetapkan
          oleh supervisor. Alur Proses di Bagian Produksi adalah sebagai
          berikut:
        </span>
        <br />
        <img
          src="/images/tes/ib/alur_ibp.png"
          alt=""
          className={styles.gambar}
        />
        <Footer previous={previousHandler} next={nextHandler} condition />
      </div>

      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 2 ? "flex" : "none" }}
      >
        <p>
          <b>
            <u>PERAN ANDA</u>
          </b>
        </p>
        <p>PT. TALENTA MANUFACTURING INDONESIA</p>
        <span>
          Anda merupakan Engineer yang bertugas untuk melakukan perbaikan dan
          pemeliharan pada alat-alat dan mesin produksi. Saat ini, tuntutan
          produksi sedang berada pada puncaknya (jumlah kuantitas output yang
          ditargetkan sangat tinggi dan ketat, sehingga jadwal shift sangat
          padat dan ketat, tidak memungkinkan untuk stop mesin dan memperlonggar
          shift). Hari ini, tanggal <b>9 April, pukul. 07.30</b>, sebelum mulai
          bekerja, seperti biasa anda melakukan “5 minutes talk” dan pada saat
          itu, anda memperoleh beberapa permasalahan berikut ini (pada 3 lembar
          kertas berikutnya). Berikan respon / tanggapan anda, tindakan apa saja
          yang akan anda lakukan dalam menghadapi setiap situasi tersebut di
          atas. Jelaskan secara rinci dan spesifik di bawah setiap laporan
          (situasi), berikan respon secara terpisah (masing-masing
          laporan/situasi).
        </span>
        <Footer previous={previousHandler} next={nextHandler} condition />
      </div>

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
