import { faCheck, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import styles from "./Action.module.css";
import DetailModal from "./DetailModal";

const Action = ({ cell, row, approveHandler }) => {
  const [isShowModal, setIsShowModal] = useState(false);
  return (
    <div className={styles.actionWrapper}>
      <div
        className={styles.detailWrapper}
        onClick={() => setIsShowModal(true)}
      >
        <FontAwesomeIcon icon={faFileAlt} size="lg" />
      </div>
      {cell.value === "Belum" && (
        <div
          className={styles.approveWrapper}
          onClick={() => approveHandler(row.original.id)}
        >
          <FontAwesomeIcon icon={faCheck} size="lg" />
        </div>
      )}
      <DetailModal
        approveHandler={() => approveHandler(row.original.id)}
        isShow={isShowModal}
        onHide={() => setIsShowModal(false)}
        status={row.original.status}
        creditId={row.original.id}
      />
    </div>
  );
};

export default Action;
