import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);
  const [answerOne, setAnswerOne] = useState("");
  const [answerTwo, setAnswerTwo] = useState("");

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>Persoalan yang akan Anda kerjakan adalah hitungan.</p>
        <br />
        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>
        <br />
        <p></p>
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="Sebatang pensil harganya 25 rupiah. Berapakah harga 3 batang ?"
        answer={answerOne}
        setAnswer={setAnswerOne}
        kunci={"75"}
        next={nextHandler}
        previous={previousHandler}
        isModal={isModal}
        pesanBenar="Jawaban Anda benar! Isian jawaban yang tepat adalah <strong>75</strong> yang dihasilkan dari perkalian 25 dan 3."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="Dengan sepeda Husin dapat mencapai 15 km dalam waktu 1 jam. Berapa km-kah yang dapat ia capai dalam waktu 4 jam ?"
        answer={answerTwo}
        setAnswer={setAnswerTwo}
        kunci={"60"}
        next={nextHandler}
        previous={previousHandler}
        isModal={isModal}
        pesanBenar="Jawaban Anda benar! Nilai <b>60</b> adalah hasil perkalian 15 dan 4"
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
