import React from 'react';

import { Button, Col, Form, Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

const defaultValues = {
  nama: '',
  waktu: '',
};

const ModalAdd = (props) => {
  const { control, handleSubmit } = useForm({ defaultValues });
  const history = useHistory();

  const onSubmit = (data) => {
    console.log(data);

    history.push('/analisa-kasus/create/200');
  };

  return (
    <Modal {...props}>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)} className="row">
          <Col sm="12" className="mb-3">
            <Form.Label>
              Nama Analisa Kasus <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="nama"
              render={({ fields }) => {
                return <Form.Control type="text" placeholder="Analisa Kasus" {...fields} />;
              }}
            />
          </Col>
          <Col sm="12" className="mb-3">
            <Form.Label>
              Waktu (Dalam Detik) <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="waktu"
              render={({ fields }) => {
                return <Form.Control type="number" placeholder="3600" {...fields} />;
              }}
            />
          </Col>
          <Col sm="12">
            <Button type="submit" className="w-100">
              Lanjut Buat Soal
            </Button>
          </Col>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAdd;
