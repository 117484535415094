import { useState } from "react";
import { connect } from "react-redux";
import ButtonKirim from "../Components/DataDiri/ButtonKirim";
import DataDiriContainer from "../Components/DataDiri/DataDiriContainer";
import DataDiriForm from "../Components/DataDiri/DataDiriForm";
import DataDiriTitle from "../Components/DataDiri/DataDiriTitle";
import HalfSection from "../Components/DataDiri/HalfSection";
import SectionWrapper from "../Components/DataDiri/SectionWrapper";
import { mapDispatchToPropsDataDiri } from "../Utils/reduxMap";
import { API } from "../../../services/API";
import InputGroup from "../Components/DataDiri/InputGroup";
import SelectGroup from "../Components/DataDiri/SelectGroup";
import TextAreaGroup from "../Components/DataDiri/TextAreaGroup";
import { nextTesHandlerUtil } from "../Utils";

// Belum dihandle dibackend
// harus koordinasi dulu mau ditaro di table mana
// kalo mau ditaro di table peserta
// maka perlu perlu nambah banyak field

const DataRekruitmenPegawai = ({
  changeCurrentPetunjuk,
  changeCurrentSoal,
  changeCurrentTes,
}) => {
  const [nama, setNama] = useState("");
  const [tanggalLahir, setTanggalLahir] = useState("");
  const [tempatLahir, setTempatLahir] = useState("");
  const [usia, setUsia] = useState(0);
  const [gender, setGender] = useState("");
  const [posisi, setPosisi] = useState("");
  const [alamat, setAlamat] = useState("");
  const [pengalaman, setPengalaman] = useState("");

  const [kebiasaanOne, setKebiasaanOne] = useState("");
  const [kebiasaanTwo, setKebiasaanTwo] = useState("");
  const [kebiasaanThree, setKebiasaanThree] = useState("");
  const [kebiasaanFour, setKebiasaanFour] = useState("");
  const [kebiasaanFive, setKebiasaanFive] = useState("");
  const [kebiasaanSix, setKebiasaanSix] = useState("");
  const [kebiasaanSeven, setKebiasaanSeven] = useState("");
  const [kebiasaanEight, setKebiasaanEight] = useState("");
  const [kebiasaanNine, setKebiasaanNine] = useState("");
  const [kebiasaanNineA, setKebiasaanNineA] = useState("");
  const [kebiasaanNineB, setKebiasaanNineB] = useState("");
  const [kebiasaanTen, setKebiasaanTen] = useState("");
  const [kebiasaanEleven, setKebiasaanEleven] = useState("");
  const [kebiasaanTwelve, setKebiasaanTwelve] = useState("");
  const [kebiasaanThirteen, setKebiasaanThirteen] = useState("");
  const [kebiasaanFourteen, setKebiasaanFourteen] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      nama,
      tglLahir: tanggalLahir,
      gender,
      posisi,
      tmptLahir: tempatLahir,
      alamat,
      dataRekruitmenPegawai: {
        nama,
        tanggalLahir,
        tempatLahir,
        usia,
        gender,
        posisi,
        alamat,
        pengalaman,
        kebiasaanOne,
        kebiasaanTwo,
        kebiasaanThree,
        kebiasaanFour,
        kebiasaanFive,
        kebiasaanSix,
        kebiasaanSeven,
        kebiasaanEight,
        kebiasaanNine,
        kebiasaanTen,
        kebiasaanEleven,
        kebiasaanTwelve,
        kebiasaanThirteen,
        kebiasaanFourteen,
      },
    };

    try {
      // console.log(data);
      // Upload to API
      const psikotesLS = JSON.parse(localStorage.getItem("psikotesTalenta"));
      await API.patch(`/peserta/${psikotesLS.peserta.id}`, data);

      nextTesHandlerUtil(
        changeCurrentTes,
        changeCurrentSoal,
        changeCurrentPetunjuk
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DataDiriContainer>
      <DataDiriTitle title="BIODATA & SELF ASESMEN" />

      <h2>1. Identitas Diri</h2>
      <DataDiriForm onSubmit={handleSubmit}>
        <SectionWrapper>
          <HalfSection>
            <InputGroup
              label="Nama"
              id="nama"
              type="text"
              state={nama}
              setState={setNama}
            />
            <InputGroup
              label="Tempat Lahir"
              id="tempatLahir"
              type="text"
              state={tempatLahir}
              setState={setTempatLahir}
            />
            <InputGroup
              label="Tanggal Lahir"
              id="tanggalLahir"
              type="date"
              state={tanggalLahir}
              setState={setTanggalLahir}
            />
            <InputGroup
              label="Usia"
              id="usia"
              type="number"
              state={usia}
              setState={setUsia}
            />
          </HalfSection>
          <HalfSection>
            <SelectGroup
              label="Jenis Kelamin"
              state={gender}
              setState={setGender}
            >
              <option value=""></option>
              <option value="L">Laki-laki</option>
              <option value="P">Perempuan</option>
            </SelectGroup>
            <InputGroup
              label="Posisi yang Dituju"
              type="text"
              id="posisi"
              state={posisi}
              setState={setPosisi}
            />
            <InputGroup
              label="Alamat Rumah"
              type="text"
              id="alamat"
              state={alamat}
              setState={setAlamat}
            />
          </HalfSection>
        </SectionWrapper>
        <SectionWrapper variant="col">
          <TextAreaGroup
            label="Pengalaman Kerja Sebelumnya"
            id="pengalaman"
            state={pengalaman}
            setState={setPengalaman}
          />
        </SectionWrapper>

        <h2>2. Perspektif Diri & Kebiasaan</h2>
        <SectionWrapper variant="col">
          <TextAreaGroup
            label="1. Apa yang menjadi motivasi saudara untuk melamar pekerjaan ini? Jelaskan *"
            id="kebiasaan-one"
            state={kebiasaanOne}
            setState={setKebiasaanOne}
          />
          <TextAreaGroup
            label="2. Apa yang menjadi modal utama bagi saudara untuk bisa menjalankan tugas pada posisi yang dituju ini? *"
            id="kebiasaan-two"
            state={kebiasaanTwo}
            setState={setKebiasaanTwo}
          />
          <TextAreaGroup
            label="3. Jabarkan tugas/peran saudara dalam pengalaman kerja sebelumnya? *"
            id="kebiasaan-three"
            state={kebiasaanThree}
            setState={setKebiasaanThree}
          />
          <TextAreaGroup
            label="4. Sejauh ini dalam pelaksanaan tugas atau pekerjaan, pola kerja secara mandiri atau berkelompok kah yang saudara jalankan, mengapa? *"
            id="kebiasaan-four"
            state={kebiasaanFour}
            setState={setKebiasaanFour}
          />
          <TextAreaGroup
            label="5. Adakah kendala yang saudara alami selama ini dalam lingkungan sosial saudara terutama ketika berhubungan dengan masyarakat disekitar tempat tinggal saudara? *"
            id="kebiasaan-five"
            state={kebiasaanFive}
            setState={setKebiasaanFive}
          />
          <TextAreaGroup
            label="6. Dalam tugas saudara selama ini, bagaimana cara saudara mengetahui apa yang menjadi kebutuhan ataupun harapan dari saudara, Khususnya yang berkaitan dengan pelayanan di profesi yang dituju? *"
            id="kebiasaan-six"
            state={kebiasaanSix}
            setState={setKebiasaanSix}
          />
          <TextAreaGroup
            label="7. Sejauh ini bentuk ide/gagasan apa yang sudah pernah saudara lakukan, terutama berkaitan dengan bidang keilmuan saudara yang akan mampu mendukung tugas di profesi yang dituju? *"
            id="kebiasaan-seven"
            state={kebiasaanSeven}
            setState={setKebiasaanSeven}
          />
          <TextAreaGroup
            label="8. Dalam menjalankan tugas selama ini, bagaimana perilaku yang saudara tampilkan untuk memastikan bahwa setiap tugas yang menjadi tanggung jawab saudara menghasilkan kualitas yang bagus atau sesuai harapan? Berikan Contoh! *"
            id="kebiasaan-eight"
            state={kebiasaanEight}
            setState={setKebiasaanEight}
          />
          <TextAreaGroup
            label="9. Apakah Visi dan  target yang ingin anda capai ketika melamar pada posisi pekerjaan yang dituju? *"
            id="kebiasaan-nine"
            state={kebiasaanNine}
            setState={setKebiasaanNine}
          />
          <TextAreaGroup
            label="9A. Sebutkan minimal 3 (tiga) kelebihan karakter diri pribadi Saudara? *"
            id="kebiasaan-nine-a"
            state={kebiasaanNineA}
            setState={setKebiasaanNineA}
          />
          <TextAreaGroup
            label="9B. Sebutkan minimal 3 (tiga) kekurangan karakter diri pribadi Saudara? *"
            id="kebiasaan-nine-b"
            state={kebiasaanNineB}
            setState={setKebiasaanNineB}
          />
          <TextAreaGroup
            label="10. Jelaskan prestasi/pengalaman sukses yang pernah Saudara capai dalam pekerjaan!  *"
            id="kebiasaan-ten"
            state={kebiasaanTen}
            setState={setKebiasaanTen}
          />
          <TextAreaGroup
            label="11. Bagaimana selama ini saudara melakukan antisipasi atau menghadapi suatu hambatan yang muncul dalam mencapai suatu target? *"
            id="kebiasaan-eleven"
            state={kebiasaanEleven}
            setState={setKebiasaanEleven}
          />
          <TextAreaGroup
            label="12. Tuliskan ketakutan-ketakutan yang anda miliki berkaitan dengan kondisi alam yang ada selama ini? (*contoh : ketinggian, perjalanan laut dll)"
            id="kebiasaan-twelve"
            state={kebiasaanTwelve}
            setState={setKebiasaanTwelve}
          />
          <TextAreaGroup
            label="13. Situasi lingkungan  seperti apa yang bisa mempengaruhi situasi emosi saudara, jelaskan? *"
            id="kebiasaan-thirteen"
            state={kebiasaanThirteen}
            setState={setKebiasaanThirteen}
          />
          <TextAreaGroup
            label="14. Sebutkan target kerja yang ingin Saudara capai dalam 1 tahun ke depan !  *"
            id="kebiasaan-fourteen"
            state={kebiasaanFourteen}
            setState={setKebiasaanFourteen}
          />
        </SectionWrapper>

        <p>*Isian wajib diisi</p>
        <ButtonKirim />
      </DataDiriForm>
    </DataDiriContainer>
  );
};

export default connect(null, mapDispatchToPropsDataDiri)(DataRekruitmenPegawai);
