import Data from "./Data";
import styles from "./Left.module.css";

const Left = () => {
  return (
    <div className={styles.left}>
      <Data title="Speed & Accuracy" value={89} min={0} max={150} />
      <Data title="Reasoning" value={97} min={0} max={150} />
      <Data title="Focus and Attention" value={110} min={0} max={150} />
      <Data title="Numerical Ability" value={102} min={0} max={150} />
      <Data title="Technical Problem Solving" value={130} min={0} max={150} />
    </div>
  );
};

export default Left;
