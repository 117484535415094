import { useState } from 'react';
import PetunjukLast from '../../../Components/PetunjukLast';
import PetunjukContainer from '../../../Containers/PetunjukContainer';
import styles from './Petunjuk.module.css';
import Footer from '../../../Components/PetunjukMiddle/Footer';

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <div
        className={styles.petunjukZero}
        style={{ display: index === 0 ? 'flex' : 'none' }}
      >
        <p>
          Anda Bapak Brian kepala sekolah baru di SMA Talenta Indonesia. Sejak 3
          hari yang lalu Anda mendapat penugasan terakit metode dan program
          pembelajaran untuk kurikulum terbaru secara offline di luar daerah.
        </p>

        <p>
          Hari ini adalah hari Minggu 10 Juli 2022 pada pukul 09.00, saat ini
          Anda berada di kantor Anda untuk menyelesaikan pekerjaan yang sempat
          tertunda karena penugasan Anda di luar daerah 3 hari yang lalu.
          Kemudian pada siang hari ini pukul 13.00, Anda harus berangkat ke luar
          kota untuk melakukan workshop selama 1 minggu penuh dan baru akan
          kembali di tanggal 18 Juli 2022. Pada e-mail dan dihadapan meja kerja
          Anda terdapat beberapa dokumen/surat/nota yang harus Anda tuntaskan
          sebelum Anda berangkat. Tugas Anda ialah mengambil tindakan atas
          setiap surat/dokumen/memo tersebut. Anda tidak dapat berkomunikasi
          dengan bawahan ataupun rekan kerja Anda saat ini dikarekan hari ini
          adalah hari libur, sehingga Anda sendiri yang harus memberikan
          tanggapan atupun instruksi kepada pihak-pihak terkait. Anda diharapkan
          dapat menuliskan tindakan yang akan diambil secara spesifik pada
          setiap surat atau memo yang tersedia.
        </p>

        <div className={styles.buttonWrapper}>
          <button onClick={nextHandler}>Tutup</button>
        </div>
      </div>

      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 1 ? 'flex' : 'none' }}
      >
        <p>Terdapat dua tugas yang Anda harus lakukan:</p>
        <ol>
          <li>
            Memberikan prioritas terhadap semua memo / dokumen kerja yang masuk,
            ke dalam tiga kategori:
            <ul>
              <li>High Priority</li>
              <li>Moderate Priority</li>
              <li>Low Priority</li>
            </ul>
          </li>
          <li>
            Menuliskan secara spesifik Rencana Tindakan yang akan dilakukan
            untuk merespon setiap memo dan dokumen kerja.
          </li>
        </ol>

        <p>
          Waktu keseluruhan yang Anda perlukan untuk melakukan semua tugas
          tersebut adalah 60 menit.
        </p>
        <Footer previous={previousHandler} next={nextHandler} condition />
      </div>

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={2}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
