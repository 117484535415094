export default function SubColumn1Kostick({ format }) {
  return (
    <>
      <th rowSpan={format.rowSpanValue}>N</th>
      <th rowSpan={format.rowSpanValue}>G</th>
      <th rowSpan={format.rowSpanValue}>A</th>
      <th rowSpan={format.rowSpanValue}>L</th>
      <th rowSpan={format.rowSpanValue}>P</th>
      <th rowSpan={format.rowSpanValue}>I</th>
      <th rowSpan={format.rowSpanValue}>T</th>
      <th rowSpan={format.rowSpanValue}>V</th>
      <th rowSpan={format.rowSpanValue}>X</th>
      <th rowSpan={format.rowSpanValue}>S</th>
      <th rowSpan={format.rowSpanValue}>B</th>
      <th rowSpan={format.rowSpanValue}>O</th>
      <th rowSpan={format.rowSpanValue}>R</th>
      <th rowSpan={format.rowSpanValue}>D</th>
      <th rowSpan={format.rowSpanValue}>C</th>
      <th rowSpan={format.rowSpanValue}>Z</th>
      <th rowSpan={format.rowSpanValue}>E</th>
      <th rowSpan={format.rowSpanValue}>K</th>
      <th rowSpan={format.rowSpanValue}>F</th>
      <th rowSpan={format.rowSpanValue}>W</th>
    </>
  );
}
