import { useEffect } from "react";
import { connect } from "react-redux";
import { generateEmptyJawabans } from "../../Utils";
import {
  mapDispatchToPropsTes,
  mapStateToPropsTes,
} from "../../Utils/reduxMap";
import Petunjuk from "./Petunjuk";
import Soal from "./Soal";

const IstrThree = ({ currentPetunjuk, currentTes, replaceJawabans }) => {
  useEffect(() => {
    generateEmptyJawabans(replaceJawabans, []);
  }, [replaceJawabans]);
  return (
    <>
      {currentPetunjuk === 61 && <Petunjuk />}
      {currentPetunjuk !== 61 && currentTes.tesId === 61 && <Soal />}
    </>
  );
};

export default connect(mapStateToPropsTes, mapDispatchToPropsTes)(IstrThree);
