import { useEffect } from "react";
import { connect } from "react-redux";
import { generateEmptyJawabans } from "../Utils";
import { mapDispatchToPropsTes, mapStateToPropsTes } from "../Utils/reduxMap";
import Petunjuk from "./Petunjuk";
import Soal from "./Soal";

const NumerikForty = ({ currentPetunjuk, currentTes, replaceJawabans }) => {
  useEffect(() => {
    generateEmptyJawabans(replaceJawabans);
  }, [replaceJawabans]);
  return (
    <>
      {currentPetunjuk === 42 && <Petunjuk />}
      {currentPetunjuk !== 42 && currentTes.tesId === 42 && <Soal />}
    </>
  );
};

export default connect(mapStateToPropsTes, mapDispatchToPropsTes)(NumerikForty);
