import { useHistory } from "react-router";
import { ubahFormat } from "../../../utils/tanggal";
import TopSection from "../../TopSection";
import styles from "./RiwayatKuotaContent.module.css";
import Table from "./Table";

const RiwayatKuotaContent = ({ riwayatKuota }) => {
  const history = useHistory();

  const columns = [
    {
      Header: "No.",
      accessor: "nomor",
    },
    {
      Header: "Tanggal",
      accessor: "tanggal",
    },
    {
      Header: "Kuota",
      accessor: "kuota",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Pilihan",
      accessor: "pilihan",
    },
  ];

  const newData =
    riwayatKuota?.map((item) => ({
      id: item.id,
      tanggal: ubahFormat(item.createdAt),
      kuota: item.total,
      status: item.approve
        ? "Approved"
        : item.buktiTransfer
        ? "Menunggu Admin"
        : "Silahkan upload bukti pembayaran",
      pilihan: "Download Invoice",
    })) || [];

  const dataWithNumber = newData.map((item, index) => ({
    ...item,
    nomor: index + 1,
  }));

  return (
    <>
      <div className={styles.container}>
        <TopSection />

        <div className={styles.mainSection}>
          <h5 className={styles.pageTitle}>
            <span className={styles.pathLink} onClick={() => history.push("/")}>
              Beranda
            </span>{" "}
            <span className={styles.pathNow}>
              / Perusahaan / Riwayat Penambahan Kuota
            </span>
          </h5>

          <div className={styles.sectionBody}>
            <h1 className={styles.title}>Riwayat Penambahan Kuota</h1>
            <Table data={dataWithNumber} columns={columns} />
          </div>
        </div>
      </div>
      <div className={styles.background}></div>
    </>
  );
};

export default RiwayatKuotaContent;
