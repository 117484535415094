import {
  faEnvelope,
  faFile,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import TopSection from "../../TopSection";
import EmailPerusahaan from "./EmailPerusahaan";
import styles from "./KonfigurasiContent.module.css";
import KriteriaPsikograph from "./KriteriaPsikograph";
import NavItem from "./NavItem";
import Profile from "./Profile";

const KonfigurasiContent = ({ client }) => {
  const history = useHistory();
  const [path, setPath] = useState(history.location.pathname);

  useEffect(() => {
    setPath(history.location.pathname);
  }, [history]);

  return (
    <>
      <div className={styles.container}>
        <TopSection />

        <div className={styles.mainSection}>
          <h5 className={styles.pageTitle}>
            <span className={styles.pathLink} onClick={() => history.push("/")}>
              Beranda
            </span>{" "}
            <span className={styles.pathNow}>/ Perusahaan / Konfigurasi</span>
          </h5>

          <div className={styles.sectionBody}>
            <div className={styles.navbar}>
              <NavItem
                icon={faUserCircle}
                title="Profile"
                href="/perusahaan/konfigurasi/profile"
                setPath={setPath}
              />

              <div className={styles.divider}></div>

              <NavItem
                icon={faFile}
                title="Kriteria Psikograph"
                href="/perusahaan/konfigurasi/kriteria-psikograph"
                setPath={setPath}
              />

              <div className={styles.divider}></div>

              <NavItem
                icon={faEnvelope}
                title="Email Perusahaan"
                href="/perusahaan/konfigurasi/email-perusahaan"
                setPath={setPath}
              />
            </div>

            {path === "/perusahaan/konfigurasi/profile" && (
              <Profile client={client} />
            )}
            {path === "/perusahaan/konfigurasi/kriteria-psikograph" && (
              <KriteriaPsikograph />
            )}
            {path === "/perusahaan/konfigurasi/email-perusahaan" && (
              <EmailPerusahaan />
            )}
          </div>
        </div>
      </div>
      <div className={styles.background}></div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    client: state?.user?.user?.client,
  };
};

export default connect(mapStateToProps, null)(KonfigurasiContent);
