import styles from "./Data.module.css";

const Data = ({ value, min, max, title }) => {
  return (
    <section className={styles.data}>
      <div className={styles.header}>
        <h1 className={styles.title}>{title}</h1>
        <h2 className={styles.value}>{value}</h2>
      </div>

      <div className={styles.body}>
        <div className={styles.barBackground}>
          <div
            className={styles.barFill}
            style={{ width: `${(value * 100) / max}%` }}
          >
            <div className={styles.barValue}>
              <p>{value}</p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <p>Min : {min}</p>
        <p>Max : {max}</p>
      </div>
    </section>
  );
};

export default Data;
