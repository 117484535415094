import StandarNilaiAspekContent from "../../../components/PerusahaanAdmin/StandarNilaiAspekContent";
import SideBar from "../../../components/SideBar";
import styles from "./StandarNilaiAspek.module.css";

const StandarNilaiAspek = () => {
  return (
    <div className={styles.container}>
      <SideBar />
      <StandarNilaiAspekContent />
    </div>
  );
};

export default StandarNilaiAspek;
