import { connect } from 'react-redux';
import PetunjukContainer from '../../../Containers/PetunjukContainer';
import PetunjukZero from '../../../Containers/PetunjukZero';
import { tutupPetunjukHandler } from '../../../Utils';
import { mapDispatchToPropsPetunjuk } from '../../../Utils/reduxMap';

const Petunjuk = ({
  isModal,
  tutupModalHandler,
  changeCurrentPetunjuk,
  menit,
}) => {
  const index = 0;

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Pada tugas ini, Anda akan dihadapkan pada suatu permasalahan. Silahkan
          dibaca dengan baik PENDAHULUAN serta permasalahan yang dijabarkan.
          Kemudian, silahkan menuliskan analisa kasus dengan detail dan
          sistematis. Hasil analisa kasus yang anda jabarkan akan menjadi
          penilaian.
        </p>
        <br />
        <p>
          Waktu Anda dalam membaca permasalahan dan mengerjakan analisa kasus
          adalah {menit} menit. Setelah Anda menutup petunjuk ini, waktu akan
          mulai berjalan.
        </p>
        <p>Selamat mengerjakan.</p>

        {isModal ? (
          <button onClick={tutupModalHandler}>Tutup</button>
        ) : (
          <button onClick={() => tutupPetunjukHandler(changeCurrentPetunjuk)}>
            Tutup
          </button>
        )}
      </PetunjukZero>
    </PetunjukContainer>
  );
};

export default connect(null, mapDispatchToPropsPetunjuk)(Petunjuk);
