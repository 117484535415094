import TopSection from '../TopSection';
import React, { useState, useEffect } from 'react';
import styles from './ModulContent.module.css';
import { API } from '../../services/API';
import Modal from '../Modal';
import ErrorModal from '../ErrorModal';

const BuatModulContent = () => {
  const [tes, setTes] = useState([]);
  const [cheked, setCheked] = useState([]);
  const [nModul, setnModul] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const content = 'Modul Berhasil Dibuat!';
  useEffect(() => {
    const getAllTes = async () => {
      try {
        const res = await API.get('/tes');
        setTes(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    getAllTes();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (nModul === '') {
        return setErrorMessage(
          'Silahkan masukkan nama modulnya terlebih dahulu!'
        );
      }
      // console.log(cheked);
      await API.post('/modul', {
        nama: nModul,
        tes: cheked,
      });
      setCheked([]);
      setnModul('');
      setModalVisible(true);
    } catch (error) {
      setErrorMessage(
        error?.response?.data?.message || 'Something went wrong!'
      );
    }
  };

  const handleInput = (e) => {
    setnModul(e.target.value);
  };

  const removeItemCheked = (x) => {
    const index = cheked.indexOf(x);
    cheked.splice(index, 1);
  };

  //  const closeModal = () =>{
  //         setInterval(() => {
  //             setModalVisible(false)
  //         }, 3000);
  //     }
  const handleCheckBox = (e) => {
    cheked.includes(e) ? removeItemCheked(e) : setCheked([...cheked, e]);
    // console.log({ cheked });
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.mainSection}>
          <div className={styles.modul}>
            <TopSection page="BuatModul" />
            {modalVisible && (
              <Modal
                isVisible={modalVisible}
                content={content}
                func={[() => {}, () => {}]}
              />
            )}
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className={styles.wrapTopEl}>
                <input
                  type="text"
                  className={styles.input}
                  onChange={(e) => handleInput(e)}
                  placeholder="Nama Modul"
                  value={nModul}
                />
                <button className={styles.submitButton} type="submit">
                  Create
                </button>
              </div>
              <div className={styles.wrapBotEl}>
                {tes &&
                  tes.map((t, i) => (
                    <div key={i}>
                      <input
                        type="checkbox"
                        name={'c' + i}
                        id="c"
                        value={t.id}
                        onChange={() => handleCheckBox(t.id)}
                      />
                      <label htmlFor={'c' + i}>{t.nama}</label>
                      <br />
                    </div>
                  ))}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className={styles.background}></div>
      {errorMessage && (
        <ErrorModal
          visible={errorMessage}
          message={
            <p>
              Ada masalah pada saat menambahkan modul. <br />
              <b>Error detail:</b>{' '}
              <span className="text-danger">{errorMessage}</span>
            </p>
          }
          onClose={setErrorMessage.bind(this, '')}
        />
      )}
    </>
  );
};

export default BuatModulContent;
