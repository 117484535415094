import {
  changeCurrentPetunjuk,
  changeCurrentSoal,
  changeCurrentTes,
  pesertaLogin,
  replaceJawabans,
  addPhotos,
} from '../../../redux/Peserta/peserta-actions';
import { changeListCountProject } from '../../../redux/User/user-actions';

export const mapStateToPropsTes = (state) => ({
  currentTes: state.peserta?.currentTes,
  currentPetunjuk: state.peserta?.currentPetunjuk,
  photos: state.peserta?.photos,
});

export const mapStateToPropsListCountProject = (state) => ({
  listCount: state.user.listCountProject,
  projectName: state?.user?.searchProjectInput,
  orderListCount: state.user.orderListCountProject,
});

export const mapDispatchToPropsListCountProject = (dispatch) => ({
  changeListCountProject: (listCount) =>
    dispatch(changeListCountProject(listCount)),
});

export const mapDispatchToPropsTes = (dispatch) => ({
  replaceJawabans: (jawabans) => dispatch(replaceJawabans(jawabans)),
});

export const mapStateToPropsSoal = (state) => ({
  currentTes: state.peserta?.currentTes,
  currentSoal: state.peserta?.currentSoal,
});

export const mapDispatchToPropsSoal = (dispatch) => ({
  replaceJawabans: (jawabans) => dispatch(replaceJawabans(jawabans)),
});

export const mapDispatchToPropsLayout = (dispatch) => ({
  addPhotos: (photos) => dispatch(addPhotos(photos)),
});

export const mapStateToPropsLayout = (state) => ({
  photos: state.photos,
  currentSoal: state.peserta?.currentSoal,
});

export const mapDispatchToPropsPetunjuk = (dispatch) => ({
  changeCurrentPetunjuk: (currentPetunjuk) =>
    dispatch(changeCurrentPetunjuk(currentPetunjuk)),
});

export const mapDispatchToPropsPilihan = (dispatch) => ({
  replaceJawabans: (jawabans) => dispatch(replaceJawabans(jawabans)),
  changeCurrentSoal: (currentSoal) => dispatch(changeCurrentSoal(currentSoal)),
});

export const mapStateToPropsDataDiri = (state) => ({
  currentPeserta: state.peserta?.peserta,
});

export const mapDispatchToPropsDataDiri = (dispatch) => {
  return {
    pesertaLogin: (
      peserta,
      currentTes,
      currentSoal,
      currentPetunjuk,
      jawabans
    ) =>
      dispatch(
        pesertaLogin(
          peserta,
          currentTes,
          currentSoal,
          currentPetunjuk,
          jawabans
        )
      ),
    changeCurrentSoal: (currentSoal) =>
      dispatch(changeCurrentSoal(currentSoal)),
    changeCurrentTes: (currentTes) => dispatch(changeCurrentTes(currentTes)),
    changeCurrentPetunjuk: (currentPetunjuk) =>
      dispatch(changeCurrentPetunjuk(currentPetunjuk)),
  };
};
