import { useState } from "react";
import check from "../../../../../assets/psikotes/check.png";
import checkFilled from "../../../../../assets/psikotes/check_filled.png";
import styles from "./CheckboxText.module.css";

const CheckboxText = ({ label, setAnswer, answer, point, setStatus }) => {
  const [isSelected, setIsSelected] = useState(false);

  return (
    <>
      <div
        className={styles.pilihanItem}
        style={{
          backgroundColor: isSelected ? "#3BC2AA" : "#e5e8ee",
        }}
        onClick={() => {
          isSelected ? setAnswer(answer - point) : setAnswer(answer + point);
          setIsSelected(!isSelected);
          setStatus(-1);
        }}
      >
        <img
          src={isSelected ? checkFilled : check}
          alt="check"
          className={styles.check}
        />
        <p dangerouslySetInnerHTML={{ __html: label }} />
      </div>
    </>
  );
};

export default CheckboxText;
