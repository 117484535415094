import styles from "./Left.module.css";

const Left = () => {
  return (
    <div className={styles.left}>
      <h1 className={styles.title}>Kekuatan</h1>
      <ol>
        <li>Kemampuan fokus</li>
        <li>Akurasi tinggi</li>
      </ol>
    </div>
  );
};

export default Left;
