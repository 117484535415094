import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihan = [
    ["A. 8", "B. 10", "C. 16", "D. 24", "E. Selain di atas"],
    [
      "A. Rp 520,-",
      "B. Rp 690,-",
      "C. Rp 750,-",
      "D. Rp 760,-",
      "E. Selain di atas",
    ],
  ];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Pada subtes ini, Anda diminta untuk mengerjakan soal-soal hitungan.
          Pada setiap soal diberikan beberapa opsi jawaban, dan tugas Anda
          adalah memilih jawaban yang paling tepat dari setiap soal tersebut.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="Satu bagian pekerjaan dapat diselesaikan dalam waktu setengah jam. Berapa bagian pekerjaan yang dapat diselesaikan dalam 8 jam?"
        arrPilihan={arrPilihan[0]}
        kunci={arrPilihan[0][2]}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban Anda benar!"
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="Johan menggunakan 1/6 dari gaji mingguannya untuk makan. Dalam seminggu ia menerima Rp 4.740,-. Berapa rupiah yang ia gunakan untuk makan?"
        arrPilihan={arrPilihan[1]}
        kunci={arrPilihan[1][4]}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban Anda benar! <br>Jawaban yang benar ialah Rp 790,-, jumlah ini tidak terdapat pada pilihan jawaban. Dalam hal ini E merupakan jawaban yang benar"
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
