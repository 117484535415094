// LS is stand for 'localStorage'

import { kumpulkanJawabanToAPI } from './kumpulkanJawaban';
import { API } from '../../../services/API';

// Dipanggil di PetunjukHeader dan PetunjukLast
export const tutupPetunjukHandler = (changeCurrentPetunjuk) => {
  const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
  psikotesLS.currentPetunjuk = 0;
  localStorage.setItem('psikotesTalenta', JSON.stringify(psikotesLS));

  changeCurrentPetunjuk(0);
};
//==============================================

// Dipanggil di komponen Tes
export const generateEmptyJawabans = (
  replaceJawabansDispatch,
  emptyAnswer = ''
) => {
  const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));

  let isTesExist = false;

  psikotesLS.jawabans?.forEach((jawaban) => {
    if (jawaban.tesId === psikotesLS.currentTes.tesId) {
      isTesExist = true;
    }
  });

  if (!isTesExist) {
    psikotesLS.currentTes.tes.soals.map((soal) =>
      psikotesLS.jawabans.push({
        pesertaId: psikotesLS.peserta.id,
        tesId: psikotesLS.currentTes.tesId,
        soalId: soal.id,
        nomor: soal.nomor,
        jawaban: emptyAnswer,
      })
    );
  }

  localStorage.setItem('psikotesTalenta', JSON.stringify(psikotesLS));
  replaceJawabansDispatch(psikotesLS.jawabans);
};
//===========================================================================//

// Dipanggil di komponen Soal
export const setSisaWaktuLS = (waktu, setSisaWaktuState) => {
  let psikotesWaktu = JSON.parse(localStorage.getItem('psikotesWaktu'));
  if (!psikotesWaktu) {
    psikotesWaktu = {};
  }

  if (!psikotesWaktu?.sisaWaktu) {
    // psikotesWaktu.sisaWaktu = currentTes?.tes?.waktu;
    psikotesWaktu.sisaWaktu = waktu;
    localStorage.setItem('psikotesWaktu', JSON.stringify(psikotesWaktu));
  }

  // console.log(psikotesWaktu.sisaWaktu);

  setSisaWaktuState(psikotesWaktu.sisaWaktu);
  // console.log('setSisaWaktuLS');
};

export const setAnswerLS = (currentSoal, setAnswerState) => {
  const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
  psikotesLS?.jawabans?.forEach((jawaban) => {
    if (jawaban?.soalId === currentSoal?.id) {
      if (jawaban?.jawaban) {
        setAnswerState(jawaban.jawaban);
      }
    }
  });
  // console.log('setAnswerLS');
};

export const setJawabansLS = (answer, replaceJawabansDispatch) => {
  const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
  psikotesLS.jawabans = [...psikotesLS.jawabans].map((jawaban) => {
    if (jawaban.soalId === psikotesLS.currentSoal.id) {
      return {
        pesertaId: psikotesLS.peserta.id,
        tesId: psikotesLS.currentTes.tesId,
        soalId: psikotesLS.currentSoal.id,
        nomor: psikotesLS.currentSoal.nomor,
        jawaban: answer,
      };
    }

    return jawaban;
  });

  localStorage.setItem('psikotesTalenta', JSON.stringify(psikotesLS));
  replaceJawabansDispatch(psikotesLS.jawabans);
  // console.log('setJawabansLS');
};

export const nextTesHandlerUtil = async (
  changeCurrentTes,
  changeCurrentSoal,
  changeCurrentPetunjuk
) => {
  const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
  if (psikotesLS?.currentTes?.urutan >= psikotesLS?.modul?.urutanTes?.length) {
    if (navigator.onLine) {
      await kumpulkanJawabanToAPI();
      const fd = new FormData();
      const photos = JSON.parse(localStorage.getItem('photos'));
      photos?.forEach((p) => {
        if (p === null) {
          return;
        }

        let arr = p?.split(','),
          mime = arr?.[0]?.match(/:(.*?);/)[1],
          bstr = atob(arr?.[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        var fileName = '';
        var possible =
          'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (var i = 0; i < 10; i++) {
          fileName += possible.charAt(
            Math.floor(Math.random() * possible.length)
          );
        }
        fileName += '.jpg';
        let photo = new File([u8arr], fileName, { type: mime });

        fd.append('photos', photo);
      });

      API({
        method: 'post',
        url: `/proctoring?pesertaId=${psikotesLS.peserta.id}`,
        data: fd,
        headers: { 'Content-Type': 'multipart/form-data' },
      }).catch((err) => localStorage.setItem('internetProblem', true));
      window.location.replace(`${process.env.REACT_APP_URL}/psikotes/finish`);
      return;
    } else {
      localStorage.setItem('internetProblem', true);
      return false;
    }
  }
  // console.log('yes masuk');
  psikotesLS.currentTes =
    psikotesLS.modul.urutanTes[psikotesLS?.currentTes.urutan];
  psikotesLS.currentSoal = psikotesLS?.currentTes.tes.soals[0];
  psikotesLS.currentPetunjuk = psikotesLS?.currentTes.tes.id;

  localStorage.setItem('psikotesTalenta', JSON.stringify(psikotesLS));
  changeCurrentTes(psikotesLS.currentTes);
  changeCurrentSoal(psikotesLS.currentSoal);
  changeCurrentPetunjuk(psikotesLS.currentPetunjuk);
};
//===========================================================================//
//Dipanggil di komponen pilihan
export const pilihanOnClickHandler = (
  option,
  replaceJawabans,
  changeCurrentSoal,
  setIsShowSelesaiModal
) => {
  const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
  psikotesLS.jawabans = [...psikotesLS.jawabans].map((jawaban) => {
    if (jawaban.soalId === psikotesLS.currentSoal.id) {
      return {
        pesertaId: psikotesLS.peserta.id,
        tesId: psikotesLS.currentTes.tesId,
        soalId: psikotesLS.currentSoal.id,
        nomor: psikotesLS.currentSoal.nomor,
        jawaban: option,
      };
    }

    return jawaban;
  });

  localStorage.setItem('psikotesTalenta', JSON.stringify(psikotesLS));
  replaceJawabans(psikotesLS.jawabans);

  if (psikotesLS.currentSoal.nomor >= psikotesLS.currentTes.tes.soals.length) {
    setIsShowSelesaiModal(true);
  } else {
    switchSoalHandler(
      psikotesLS.currentTes.tes.soals[psikotesLS.currentSoal.nomor + 1 - 1],
      changeCurrentSoal
    );
  }
};

export const switchSoalHandler = (soal, changeCurrentSoal) => {
  changeCurrentSoal(soal);

  const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
  psikotesLS.currentSoal = soal;
  localStorage.setItem('psikotesTalenta', JSON.stringify(psikotesLS));
};
