import { useEffect } from 'react';
import { connect } from 'react-redux';
import { generateEmptyJawabans } from '../../Utils';
import {
  mapDispatchToPropsTes,
  mapStateToPropsTes,
} from '../../Utils/reduxMap';
import Petunjuk from './Petunjuk';
import Soal from './Soal';

const TikiMTen = ({ currentPetunjuk, currentTes, replaceJawabans }) => {
  useEffect(() => generateEmptyJawabans(replaceJawabans), [replaceJawabans]);

  return (
    <>
      {currentPetunjuk === 130 && <Petunjuk />}
      {currentPetunjuk !== 130 && currentTes.tesId === 130 && <Soal />}
    </>
  );
};

export default connect(mapStateToPropsTes, mapDispatchToPropsTes)(TikiMTen);
