import Rodal from 'rodal';
// import {useState} from 'react'
import { Progress } from 'react-sweet-progress';
import 'react-sweet-progress/lib/style.css';
import styles from './progressModal.module.css';
import { API } from '../../../../../services/API';
// import FileDownload from "js-file-download";

const ProgressModal = ({
  modalVisible,
  percent,
  projectId,
  setModalVisible,
}) => {
  const downloadZip = async () => {
    setModalVisible(false);
    await API.get(`/download-zip?projectId=${projectId}`, {
      responseType: 'blob',
    })
      .then((r) => setModalVisible(false))
      .catch((err) => console.log(err));
  };
  const href =
    process.env.REACT_APP_SERVER_URL +
    '/api/v1/download-zip?projectId=' +
    projectId;
  return (
    <>
      <Rodal
        visible={modalVisible}
        animation="rotate"
        showCloseButton={false}
        onClose={() => {}}
      >
        <div className={styles.wrapModal}>
          <Progress
            type="circle"
            width={130}
            percent={percent}
            strokeWidth={10}
            status={percent < 100 ? 'active' : 'success'}
            className={styles.progress}
            style={{ marginRight: '300' }}
          />
          <br></br>
          {percent < 100 && (
            <h5 style={{ marginTop: 20 }}>Mempersiapkan File...</h5>
          )}

          {percent >= 100 && (
            <button className={styles.button} onClick={() => downloadZip()}>
              <a
                href={href}
                target="_blank"
                rel="noreferrer noopener"
                style={{ textDecoration: 'none', color: 'white' }}
              >
                Download Zip
              </a>
            </button>
          )}
        </div>
      </Rodal>
    </>
  );
};

export default ProgressModal;
