import styles from "./StandarNilaiAspekContent.module.css";
import TopSection from "../../TopSection";
import NavItem from "./NavItem";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Nilai from "./Nilai";
import Psikograph from "./Psikograph";

const StandarNilaiAspekContent = () => {
  const history = useHistory();
  const [path, setPath] = useState(history.location.pathname);

  useEffect(() => {
    setPath(history.location.pathname);
  }, [history]);

  return (
    <div className={styles.container}>
      <TopSection />

      <div className={styles.mainSection}>
        <p className={styles.path}>
          <span className={styles.pathLink} onClick={() => history.push("/")}>
            Beranda
          </span>{" "}
          <span className={styles.pathNow}>
            / Perusahaan / Standar Nilai Aspek
          </span>
        </p>
        <div className={styles.sectionBody}>
          <div className={styles.navbar}>
            <NavItem
              title="Aspek Psikograph"
              href="/perusahaan/standar-nilai-aspek/psikograph"
              setPath={setPath}
            />
            <div className={styles.divider}></div>

            <NavItem
              title="Standar Nilai Aspek"
              href="/perusahaan/standar-nilai-aspek/nilai"
              setPath={setPath}
            />
          </div>

          {path === "/perusahaan/standar-nilai-aspek/psikograph" && (
            <Psikograph />
          )}
          {path === "/perusahaan/standar-nilai-aspek/nilai" && <Nilai />}
        </div>
      </div>
    </div>
  );
};

export default StandarNilaiAspekContent;
