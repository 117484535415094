import SideBar from "../../../components/SideBar";

const ManajemenPeran = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <SideBar />
      <div>This is ManajemenPeran page</div>
    </div>
  );
};

export default ManajemenPeran;
