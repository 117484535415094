import styles from "./Pilihan.module.css";

const Pilihan = ({ label, answer, setAnswer, index }) => {
  const incrementHandler = () => {
    const newAnswer = answer.map((item, idx) => {
      if (index === idx) {
        return { ...item, bobot: item.bobot + 1 };
      }

      return item;
    });

    setAnswer(newAnswer);
  };

  const decrementHandler = () => {
    const newAnswer = answer.map((item, idx) => {
      if (index === idx) {
        if (item.bobot <= 0) {
          return item;
        }
        return { ...item, bobot: item.bobot - 1 };
      }

      return item;
    });

    setAnswer(newAnswer);
  };

  console.log(answer);

  return (
    <div className={styles.pilihan}>
      <div className={styles.answerWrapper}>
        <div className={styles.arrowUp} onClick={incrementHandler} />
        <div className={styles.arrowDown} onClick={decrementHandler} />
      </div>
      <p className={styles.number}>{answer[index]?.bobot}</p>
      <p>{label}</p>
    </div>
  );
};

export default Pilihan;
