// ** React Import
import React, { useEffect, useRef, useReducer, useState } from 'react';

// ** Thirdparty
import { useDownloadExcel } from 'react-export-table-to-excel';

// ** Custom Components
import TopSection from '../TopSection';
import ErrorModal from '../ErrorModal';
import * as RB from 'react-bootstrap';

// ** Table Type
import TableEDU from './Tables/TableEDU';
import TableSTANDAR from './Tables/TableSTANDAR';
import TableLPPI from './Tables/TableLPPI';
import TableTAF from './Tables/TableTAF';
import TablePTEGATEK from './Tables/TablePTEGATEK';

// ** Utilities
import {
  KOSTICK,
  CFIT3B,
  DATA_DIRI_PESERTA,
  DISC,
  KRAEPELIN,
  SELF_ASSESSMENT_STRING,
  TIKI,
  HOLLAND,
  DATA_DIRI_EDU,
  CFIT3B_RS,
  IQ_CFIT,
  KLASIFIKASI,
  KRAEPELIN_EDU,
  DATA_DIRI,
  SELF_ASSESSMENT_NUMBER,
  TIKI_LPPI,
} from './Tables/ActionTypes';
import { formatTanggalSekarang as fTS } from '../../utils/tanggal';

// ** Base API
import { API } from '../../services/API';

// ** Style
import styles from './RekapExcelContent.module.css';

const RekapExcelContent = ({ projectId, table, dataProject }) => {
  const namaProject = dataProject.nama;
  const date = new Date();
  const formatTanggalSekarang = `${date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate()}-${
    date.getMonth() <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  }-${date.getFullYear()}`;

  const tableRef = useRef(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const datasState = {
    pesertas: [],
    cfit3b: [],
    kraepelin: [],
    kostick: [],
    tiki: [],
    holland: [],
    disc: [],
  };

  const datasReducerFunction = (state, action) => {
    switch (action.type) {
      case 'setPesertas':
        return { ...state, pesertas: action.payload };
      case 'setCFIT3B':
        return {
          ...state,
          cfit3b: [...state.cfit3b, action.payload],
        };
      case 'setKraepelin':
        return {
          ...state,
          kraepelin: [...state.kraepelin, action.payload],
        };
      case 'setKostick':
        return {
          ...state,
          kostick: [...state.kostick, action.payload],
        };
      case 'setTiki':
        return {
          ...state,
          tiki: [...state.tiki, action.payload],
        };
      case 'setHolland':
        return {
          ...state,
          holland: [...state.holland, action.payload],
        };
      case 'setDISC':
        return {
          ...state,
          disc: [...state.disc, action.payload],
        };
      default:
        return null;
    }
  };

  const [datasReducer, dispatchReducer] = useReducer(datasReducerFunction, datasState);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `${namaProject}_${formatTanggalSekarang}`,
    sheet: 'Users',
  });

  const formatLaporan = [
    {
      name: 'STANDAR',
      isSubColumn2: true,
      datas: [
        {
          name: DATA_DIRI_PESERTA,
          perColRows: 0,
          rowsLength: 13,
          rowSpanValue: 3,
        },
        {
          name: SELF_ASSESSMENT_STRING,
          perColRows: 0,
          rowsLength: 6,
          rowSpanValue: 3,
        },
        {
          name: KRAEPELIN,
          perColRows: 2,
          rowsLength: 10,
          rowSpanValue: 2,
        },
        {
          name: TIKI,
          perColRows: 4,
          rowsLength: 14,
          rowSpanValue: 3,
        },
        {
          name: CFIT3B,
          perColRows: 0,
          rowsLength: 3,
          rowSpanValue: 3,
        },
        { name: DISC, perColRows: 0, rowsLength: 3, rowSpanValue: 3 },
        {
          name: KOSTICK,
          rowsLength: 20,
          rowSpanValue: 3,
        },
        {
          name: HOLLAND,
          rowsLength: 3,
          perColRows: 3,
          rowSpanValue: 2,
        },
      ],
    },
    {
      name: 'PT EDU',
      isSubColumn2: false,
      datas: [
        {
          name: DATA_DIRI_EDU,
          perColRows: 0,
          rowsLength: 9,
          rowSpanValue: 2,
        },
        {
          name: SELF_ASSESSMENT_STRING,
          perColRows: 0,
          rowsLength: 6,
          rowSpanValue: 0,
        },
        {
          name: CFIT3B_RS,
          perColRows: 0,
          rowsLength: 4,
          rowSpanValue: 0,
        },
        { name: IQ_CFIT, perColRows: 0, rowsLength: 1, rowSpanValue: 2 },
        { name: KLASIFIKASI, perColRows: 0, rowsLength: 1, rowSpanValue: 2 },
        {
          name: KRAEPELIN_EDU,
          perColRows: 0,
          rowsLength: 6,
          rowSpanValue: 0,
        },
        { name: DISC, perColRows: 0, rowsLength: 3, rowSpanValue: 0 },
        {
          name: KOSTICK,
          rowsLength: 20,
          rowSpanValue: 0,
        },
      ],
    },
    {
      name: 'LPPI',
      isSubColumn2: true,
      datas: [
        {
          name: DATA_DIRI,
          perColRows: 0,
          rowsLength: 9,
          rowSpanValue: 3,
        },
        {
          name: SELF_ASSESSMENT_NUMBER,
          perColRows: 0,
          rowsLength: 6,
          rowSpanValue: 3,
        },
        {
          name: TIKI_LPPI,
          perColRows: 4,
          rowsLength: 6,
          rowSpanValue: 3,
        },
        {
          name: KRAEPELIN,
          perColRows: 2,
          rowsLength: 10,
          rowSpanValue: 2,
        },
        {
          name: KOSTICK,
          rowsLength: 20,
          rowSpanValue: 3,
        },
        { name: DISC, perColRows: 0, rowsLength: 3, rowSpanValue: 3 },
      ],
    },
    {
      name: 'TAF',
      isSubColumn2: true,
      datas: [
        {
          name: DATA_DIRI_PESERTA,
          perColRows: 0,
          rowsLength: 13,
          rowSpanValue: 3,
        },
        {
          name: KRAEPELIN,
          perColRows: 2,
          rowsLength: 10,
          rowSpanValue: 2,
        },
        {
          name: TIKI,
          perColRows: 4,
          rowsLength: 14,
          rowSpanValue: 3,
        },
      ],
    },
    {
      name: 'PT EGATEK (Cutting Point)',
      isSubColumn2: true,
      datas: [
        {
          name: DATA_DIRI_PESERTA,
          perColRows: 0,
          rowsLength: 13,
          rowSpanValue: 3,
        },
        {
          name: TIKI,
          perColRows: 4,
          rowsLength: 14,
          rowSpanValue: 3,
        },
        {
          name: KRAEPELIN,
          perColRows: 2,
          rowsLength: 10,
          rowSpanValue: 2,
        },
        {
          name: CFIT3B,
          perColRows: 0,
          rowsLength: 3,
          rowSpanValue: 3,
        },
      ],
    },
  ];

  // Membuat ini agar pada saat mengambil data dari api itu tidak semua, hanya yang bernilai true saja.
  let getLaporanDatas;
  if (table === 'STANDAR') {
    getLaporanDatas = {
      cfit3b: true,
      kraepelin: true,
      kostick: true,
      tiki: true,
      holland: true,
      disc: true,
    };
  } else if (table === 'PT EDU') {
    getLaporanDatas = {
      cfit3b: true,
      kraepelin: true,
      kostick: true,
      disc: true,
    };
  } else if (table === 'LPPI') {
    getLaporanDatas = {
      kraepelin: true,
      kostick: true,
      tiki: true,
      disc: true,
    };
  } else if (table === 'TAF') {
    getLaporanDatas = { kraepelin: true, tiki: true };
  } else if (table === 'PT EGATEK (Cutting Point)') {
    getLaporanDatas = {
      tiki: true,
      kraepelin: true,
      cfit3b: true,
    };
  }

  let isAll = '';
  if (dataProject?.isCuttingPoint) {
    isAll = true;
  }

  useEffect(() => {
    function getDatas() {
      setLoading(true);
      try {
        API.get(`/peserta-selesai?projectId=${projectId}&all=${isAll}`).then((res) => {
          let pesertas = res.data.data;

          pesertas = pesertas.map((peserta) => {
            peserta.project.tglMulai = fTS(peserta?.project?.tglMulai);
            peserta.tglLahir = fTS(peserta?.tglLahir);

            if (peserta.dataDiri) {
              peserta.perusahaan = JSON.parse(peserta.dataDiri).peserta.perusahaan;
            } else if (peserta.dataDiriSingkat && peserta.dataDiriSingkat?.length !== 2) {
              peserta.perusahaan = JSON.parse(peserta.dataDiriSingkat).peserta.perusahaanPengirim;
            } else {
              peserta.perusahaan = '-';
            }

            if ('cfit3b' in getLaporanDatas) {
              API.get(`/hasil-cfit3b?pesertaId=${peserta.id}`)
                .then((res) => {
                  let newCfit = res.data.data;
                  dispatchReducer({
                    type: 'setCFIT3B',
                    payload: [...datasReducer.cfit3b, newCfit],
                  });
                })
                .catch((error) => setError(true));
            }

            if ('kraepelin' in getLaporanDatas) {
              API.get(`/hasil-kraepelin?pesertaId=${peserta.id}`).then((res) => {
                let newKraepelin = res.data.data;
                dispatchReducer({
                  type: 'setKraepelin',
                  payload: [...datasReducer.kraepelin, newKraepelin],
                });
              });
            }

            if ('kostick' in getLaporanDatas) {
              API.get(`/hasil-kostick?pesertaId=${peserta.id}`)
                .then((res) => {
                  let newKostick = res.data.data;
                  dispatchReducer({
                    type: 'setKostick',
                    payload: [...datasReducer.kostick, newKostick],
                  });
                })
                .catch((error) => setError(true));
            }

            if ('tiki' in getLaporanDatas) {
              API.get(`/hasil-tiki?pesertaId=${peserta.id}`)
                .then((res) => {
                  let newKostick = res.data.data;
                  dispatchReducer({
                    type: 'setTiki',
                    payload: [...datasReducer.tiki, newKostick],
                  });
                })
                .catch((error) => setError(true));
            }

            if ('holland' in getLaporanDatas) {
              API.get(`/hasil-holland?pesertaId=${peserta.id}`)
                .then((res) => {
                  let newHolland = res.data.data;
                  dispatchReducer({
                    type: 'setHolland',
                    payload: [...datasReducer.holland, newHolland],
                  });
                })
                .catch((error) => setError(true));
            }

            if ('disc' in getLaporanDatas) {
              API.get(`/hasil-disc?pesertaId=${peserta.id}`)
                .then((res) => {
                  let newDISC = res.data.data;
                  dispatchReducer({
                    type: 'setDISC',
                    payload: [...datasReducer.disc, newDISC],
                  });
                })
                .catch((error) => setError(true));
            }

            return peserta;
          });
          setLoading(false);
          dispatchReducer({ type: 'setPesertas', payload: pesertas });
        });
      } catch (err) {
        setError(true);
        setLoading(false);
      }
    }

    getDatas();
  }, [table]);

  return (
    <>
      <div className={styles.container}>
        <TopSection />
        <button onClick={onDownload} className={styles.buttonTalenta}>
          Export excel
        </button>
        {loading && <RB.Spinner animation="border" variant="info" className="mx-3 mt-2" />}
        <div className={styles.mainSection}>
          {formatLaporan.map((format, index) => {
            if (format.name === table && table === 'STANDAR') {
              return (
                <>
                  <TableSTANDAR
                    projectId={projectId}
                    tableRef={tableRef}
                    downloadRekap={onDownload}
                    formatLaporanExcel={format}
                    datas={datasReducer}
                    index={index}
                  />
                </>
              );
            }
            if (format.name === table && table === 'PT EDU') {
              return (
                <>
                  <TableEDU
                    projectId={projectId}
                    tableRef={tableRef}
                    downloadRekap={onDownload}
                    formatLaporanExcel={format}
                    datas={datasReducer}
                    index={index}
                  />
                </>
              );
            }
            if (format.name === table && table === 'LPPI') {
              return (
                <>
                  <TableLPPI
                    projectId={projectId}
                    tableRef={tableRef}
                    downloadRekap={onDownload}
                    formatLaporanExcel={format}
                    datas={datasReducer}
                    index={index}
                  />
                </>
              );
            }
            if (format.name === table && table === 'TAF') {
              return (
                <>
                  <TableTAF
                    projectId={projectId}
                    tableRef={tableRef}
                    downloadRekap={onDownload}
                    formatLaporanExcel={format}
                    datas={datasReducer}
                    index={index}
                  />
                </>
              );
            }
            if (format.name === table && table === 'PT EGATEK (Cutting Point)') {
              return (
                <>
                  <TablePTEGATEK
                    projectId={projectId}
                    tableRef={tableRef}
                    downloadRekap={onDownload}
                    formatLaporanExcel={format}
                    datas={datasReducer}
                    index={index}
                  />
                </>
              );
            }
          })}
        </div>
      </div>
      <div className={styles.background}></div>
      {error && (
        <ErrorModal
          visible={error}
          message="Peringatan, Modul dalam project ini beberapa ada yang tidak mengandung data tes yang di perlukan dalam format laporan ini! Ini akan mengakibatkan nilai kosong (-)."
          onClose={() => setError(false)}
        />
      )}
    </>
  );
};

export default RekapExcelContent;
