import { useState } from "react";
import { connect } from "react-redux";
import { mapDispatchToPropsPetunjuk } from "../../Utils/reduxMap";
import styles from "./Petunjuk.module.css";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ changeCurrentPetunjuk }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);
  const tutupHandler = () => {
    const psikotesLS = JSON.parse(localStorage.getItem("psikotesTalenta"));
    psikotesLS.currentPetunjuk = 0;
    localStorage.setItem("psikotesTalenta", JSON.stringify(psikotesLS));

    changeCurrentPetunjuk(0);
  };

  return (
    <div className={styles.container}>
      <header>
        <h1>{index === 0 ? "Petunjuk" : "Contoh Soal"}</h1>
      </header>
      <div className={styles.content}>
        <div
          className={styles.petunjukZero}
          style={{ display: index === 0 ? "flex" : "none" }}
        >
          <p>
            Pada Tes ini Anda diminta untuk melakukan penjumlahan sederhana dari
            dua angka yang terdapat pada soal.
          </p>
          <br />
          <p>
            Bekerjalah dengan <strong>CEPAT dan TELITI.</strong>
          </p>
          <br />
          <br />
          <br />

          <button onClick={nextHandler}>Lanjut</button>
        </div>

        <PetunjukMiddle
          next={nextHandler}
          previous={previousHandler}
          selectedIndex={index}
          kunci="9"
          pesanBenar="Jawaban Anda benar! 2 + 7 = <b>9</b>"
          showWhenIndex={1}
          soal="2 + 7 = ?"
        />

        <PetunjukMiddle
          next={nextHandler}
          previous={previousHandler}
          selectedIndex={index}
          kunci="6"
          pesanBenar="Jawaban Anda benar! 0 + 6 = <b>6</b>"
          showWhenIndex={2}
          soal="0 + 6 = ?"
        />
        <PetunjukMiddle
          next={nextHandler}
          previous={previousHandler}
          selectedIndex={index}
          kunci="2"
          pesanBenar="Jawaban Anda benar! 6 + 6 = 1<b>2</b>. Cukup menjawab angka 2
              saja, karena angka 2 adalah digit terakhir dari angka 12."
          showWhenIndex={3}
          soal="6 + 6 = ?"
        />
        <PetunjukMiddle
          next={nextHandler}
          previous={previousHandler}
          selectedIndex={index}
          kunci="7"
          pesanBenar="Jawaban Anda benar! 8 + 9 = 1<b>7</b>. Cukup menjawab angka 7
              saja, karena angka 7 adalah digit terakhir dari angka 17."
          showWhenIndex={4}
          soal="8 + 9 = ?"
        />

        <div
          className={styles.petunjukLast}
          style={{ display: index === 5 ? "flex" : "none" }}
        >
          <p>
            Menekan tombol MULAI SOAL berarti Anda akan langsung mengerjakan
            soal. Waktu dalam mengerjakan soal terbatas, harap gunakan waktu
            dengan efektif. Silahkan dimulai
          </p>
          <br />

          <div className={styles.footer}>
            <button onClick={previousHandler}>Kembali</button>
            <button onClick={tutupHandler}>Mulai Soal</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToPropsPetunjuk)(Petunjuk);
