import { useRef } from 'react';

import brandLogo from '../../assets/img/brand-logo.svg';
import whiteBrandLogo from '../../assets/img/white-brand-logo.svg';

import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

// ** Dian Erdiana
import { navigations } from './navigations';
import NavMenuLink from './NavMenuLink';
import NavMenuGroup from './NavMenuGroup';

import styles from './SideBar.module.css';
import 'assets/scss/sidebar.scss';

const SideBar = ({ isAdmin, isClientSekolah }) => {
  const navRef = useRef();
  const footRef = useRef();
  const responsiveClass = `${styles.responsiveNavbar}`;
  const iconChange = `${styles.iconChange}`;
  const fullHeight = `${styles.fullHeight}`;
  const heightRef = useRef();
  const iconRef = useRef();
  const showNavbar = () => {
    navRef.current.classList.toggle(responsiveClass);
    footRef.current.classList.toggle(responsiveClass);
    iconRef.current.classList.toggle(iconChange);
    heightRef.current.classList.toggle(fullHeight);
  };

  return (
    <div className={styles.container} ref={heightRef}>
      <div className={styles.logo}>
        <img src={brandLogo} alt="talenta" className={styles.brandLogo} />
        <button className={styles.navBtn} onClick={showNavbar} ref={iconRef}>
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>

      <div className={styles.overflowY}>
        <div className={styles.menu + ' pb-5 ' + styles.bgWhite} ref={navRef}>
          {navigations.map((nav, idx) => {
            if (nav.hasOwnProperty('children')) {
              return (
                <NavMenuGroup
                  key={idx}
                  title={nav.title}
                  url={nav.url}
                  icon={nav.icon}
                  menuGroups={nav.children}
                />
              );
            }
            return <NavMenuLink key={idx} title={nav.title} url={nav.url} icon={nav.icon} />;
          })}
          <div>
            <button className={styles.navBtn + ' ' + styles.navBtnClose} onClick={showNavbar}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>

          <div className={styles.sidebarFooter} ref={footRef}>
            <p>Psikotes Online by</p>
            <img src={whiteBrandLogo} alt="dashboard" className={styles.whiteBrandLogo} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: state.user.isAdmin,
    isClientSekolah: state.user.isClientSekolah,
  };
};

export default connect(mapStateToProps, null)(SideBar);
