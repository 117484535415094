import React from 'react';
import { Button, Modal } from 'react-bootstrap';

/**
 *
 * @param {Boolean} visible
 * @param {String} message
 * @param {Function} onClose
 * @returns Error modal react element.
 */

export default function ErrorModal({ visible, message, onClose }) {
  return (
    <Modal show={visible} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Error!</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onClose}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
