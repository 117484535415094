import { useState } from "react";
import PetunjukLast from "../../../../Components/PetunjukLast";
import PetunjukContainer from "../../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>Perhatikan contoh untuk memahami soal yang akan Anda kerjakan.</p>
        <br />
        <p>
          Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        namaFile="contoh-cfit2a4.png"
        pesanBenar='Dari contoh di atas, hampir setiap gambar berbentuk kotak memanjang horizontal, terkecuali pilihan D dimana tampak tegak lurus secara vertikal. Maka pilihan "d" adalah jawaban yang paling tepat.'
        kunci="d"
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        namaFile="contoh-cfit2a5.png"
        kunci="a"
        pesanBenar='Pada contoh ini jawaban yang paling tepat adalah "a", karena hampir semua lingkaran berwarna putih terkecuali pilihan "a" yang berbeda dengan warna hitam.'
        previous={previousHandler}
        next={nextHandler}
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
