import styles from "./PetunjukMiddle.module.css";
import Benar from "../../../Components/PetunjukMiddle/Benar";
import PetunjukMiddleContainer from "../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import PetunjukParagraph from "../../../Components/PetunjukMiddle/PetunjukParagraph";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  soal,
  kunci,
  next,
  previous,
  pesanBenar,
  isModal,
}) => (
  <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
    <p className={styles.title}>Contoh</p>
    <PetunjukParagraph>{soal}</PetunjukParagraph>
    <div className={styles.inputWrapper}>
      <input type="text" value={kunci} readOnly />
    </div>

    <Benar isShow htmlString={pesanBenar} />
    <div className={styles.footer}>
      {!isModal && (
        <p>
          Jangan ditutup terlebih dulu petunjuk ini, Baca dan pahami instruksi.
          Apabila petunjuk ini ditutup maka tes dimulai dan waktu berjalan.
        </p>
      )}
      <button onClick={previous}>Kembali</button>
      <button onClick={next}>Lanjutkan</button>
    </div>
  </PetunjukMiddleContainer>
);

export default PetunjukMiddle;
