import { useEffect } from "react";
import { connect } from "react-redux";
import { generateEmptyJawabans } from "../Utils";
import { mapDispatchToPropsTes, mapStateToPropsTes } from "../Utils/reduxMap";
import Petunjuk from "./Petunjuk";
import Soal from "./Soal";

const Cpm = ({ currentPetunjuk, currentTes, replaceJawabans }) => {
  useEffect(
    () => generateEmptyJawabans(replaceJawabans),
    [replaceJawabans, currentTes]
  );
  return (
    <>
      {currentPetunjuk === 106 && <Petunjuk />}
      {currentPetunjuk !== 106 && currentTes.tesId === 106 && (
        <Soal pathImage="/images/tes/cpm/cpm1" />
      )}
      {currentPetunjuk === 107 && <Petunjuk />}
      {currentPetunjuk !== 107 && currentTes.tesId === 107 && (
        <Soal pathImage="/images/tes/cpm/cpm2" />
      )}
      {currentPetunjuk === 108 && <Petunjuk />}
      {currentPetunjuk !== 108 && currentTes.tesId === 108 && (
        <Soal pathImage="/images/tes/cpm/cpm3" />
      )}
    </>
  );
};

export default connect(mapStateToPropsTes, mapDispatchToPropsTes)(Cpm);
