import { useEffect } from "react";
import { connect } from "react-redux";
import { generateEmptyJawabans } from "../../Utils";
import {
  mapDispatchToPropsTes,
  mapStateToPropsTes,
} from "../../Utils/reduxMap";
import Petunjuk from "../Components/Petunjuk";
import Soal from "./Soal";

const AKManajerFarmasi = ({ currentPetunjuk, currentTes, replaceJawabans }) => {
  useEffect(() => {
    generateEmptyJawabans(replaceJawabans, "Tuliskan analisa Anda di sini.");
  }, [replaceJawabans]);

  return (
    <>
      {currentPetunjuk === 99 && <Petunjuk menit={60} />}
      {currentPetunjuk !== 99 && currentTes.tesId === 99 && <Soal />}
    </>
  );
};

export default connect(
  mapStateToPropsTes,
  mapDispatchToPropsTes
)(AKManajerFarmasi);
