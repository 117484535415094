import SideBar from "../../components/SideBar";
import SkorPesertaContent from "../../components/SkorPesertaContent";
import styles from "./SkorPeserta.module.css";

const SkorPeserta = () => {
  return (
    <div className={styles.container}>
      <SideBar />
      <SkorPesertaContent />
    </div>
  );
};

export default SkorPeserta;
