import React from 'react';

const HeaderThird = () => {
  return (
    <React.Fragment>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>RS</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>WS</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>RS</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>WS</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>RS</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>WS</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>RS</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>WS</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>RS</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>WS</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>RS</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>WS</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>RS</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>WS</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>RS</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>WS</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>RS</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>WS</th>
    </React.Fragment>
  );
};

export default HeaderThird;
