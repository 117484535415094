import styles from "./Pilihan.module.css";

const Pilihan = ({ index, label, setAnswer, answer }) => {
  const jawabHandler = (answerType, indexJawaban) => {
    let newAnswer = ["", ""];
    if (answerType === "PALING_SESUAI") {
      newAnswer = [answer[0], "y"+indexJawaban];
      if (answer[0] === "x"+indexJawaban) {
        newAnswer = ["", "y"+indexJawaban];
      }
    }

    if (answerType === "PALING_TIDAK_SESUAI") {
      newAnswer = ["x"+indexJawaban, answer[1]];
      if (answer[1] === "y"+indexJawaban) {
        newAnswer = ["x"+indexJawaban, ""];
      }
    }
    setAnswer(newAnswer);
  };

  return (
    <div className={styles.pilihanItemWrapper}>
      <div className={styles.pilihanItem}>
        <p dangerouslySetInnerHTML={{ __html: label }} />
      </div>
      <div
        className={styles.pilihanType}
        onClick={() => jawabHandler("PALING_SESUAI", index)}
        style={{
          backgroundColor: answer[1] === "y"+index ? "#3BC2AA" : "#e5e8ee",
        }}
      >
        Paling Sesuai
      </div>
      <div
        className={styles.pilihanType}
        onClick={() => jawabHandler("PALING_TIDAK_SESUAI", index)}
        style={{
          backgroundColor: answer[0] === "x"+index ? "#3BC2AA" : "#e5e8ee",
        }}
      >
        Paling Tidak Sesuai
      </div>
    </div>
  );
};

export default Pilihan;

// import check from "../../../../assets/psikotes/check.png";
// import checkFilled from "../../../../assets/psikotes/check_filled.png";
// import styles from "./Pilihan.module.css";

// const Pilihan = ({ label, onClick, answer }) => {
//   return (
//     <div
//       className={styles.pilihanItem}
//       style={{
//         backgroundColor: answer === label ? "#3BC2AA" : "#e5e8ee",
//       }}
//       onClick={onClick}
//     >
//       {answer === label ? (
//         <img src={checkFilled} alt="check" className={styles.check} />
//       ) : (
//         <img src={check} alt="check" className={styles.check} />
//       )}
//       <p dangerouslySetInnerHTML={{ __html: label }} />
//     </div>
//   );
// };

// export default Pilihan;
