import { useState } from "react";
import { connect } from "react-redux";
import InputGroup from "../Components/DataDiri/InputGroup";
import SelectGroup from "../Components/DataDiri/SelectGroup";
import { nextTesHandlerUtil } from "../Utils";
import TextAreaGroup from "../Components/DataDiri/TextAreaGroup";
import SectionWrapper from "../Components/DataDiri/SectionWrapper";
import DataDiriForm from "../Components/DataDiri/DataDiriForm";
import DataDiriContainer from "../Components/DataDiri/DataDiriContainer";
import ButtonKirim from "../Components/DataDiri/ButtonKirim";
import DataDiriTitle from "../Components/DataDiri/DataDiriTitle";
import HalfSection from "../Components/DataDiri/HalfSection";
import {
  mapDispatchToPropsDataDiri,
  mapStateToPropsDataDiri,
} from "../Utils/reduxMap";
import { API } from "../../../services/API";

const Flk = ({
  changeCurrentPetunjuk,
  changeCurrentSoal,
  changeCurrentTes,
}) => {
  //peserta
  const [namaLengkap, setNamaLengkap] = useState("");
  const [namaSekolah, setNamaSekolah] = useState("");
  const [tanggalLahir, setTanggalLahir] = useState("");
  const [kelas, setKelas] = useState("");
  const [tempatLahir, setTempatLahir] = useState("");
  const [alamatLengkap, setAlamatLengkap] = useState("");
  const [gender, setGender] = useState("");
  const [jurusan, setJurusan] = useState("");

  //Orang Tua
  const [namaIbu, setNamaIbu] = useState("");
  const [pekerjaanIbu, setPekerjaanIbu] = useState("");
  const [teleponIbu, setTeleponIbu] = useState("");
  const [alamatOrangTua, setAlamatOrangTua] = useState("");
  const [namaAyah, setNamaAyah] = useState("");
  const [pekerjaanAyah, setPekerjaanAyah] = useState("");
  const [teleponAyah, setTeleponAyah] = useState("");

  //Mata pelajaran
  const [ipa, setIpa] = useState("");
  const [ips, setIps] = useState("");
  const [bahasa, setBahasa] = useState("");
  const [matematika, setMatematika] = useState("");
  const [sejarah, setSejarah] = useState("");
  const [seniBudaya, setSeniBudaya] = useState("");
  const [pendidikanAgama, setPendidikanAgama] = useState("");
  const [penjasorkers, setPenjasorkers] = useState("");

  //pekerjaan
  const [ahliPertanian, setAhliPertanian] = useState("");
  const [insinyur, setInsinyur] = useState("");
  const [akuntan, setAkuntan] = useState("");
  const [ilmuwan, setIlmuwan] = useState("");
  const [marketing, setMarketing] = useState("");
  const [seniman, setSeniman] = useState("");
  const [penulis, setPenulis] = useState("");
  const [musisi, setMusisi] = useState("");
  const [guru, setGuru] = useState("");
  const [karyawanBank, setKaryawanBank] = useState("");
  const [tukang, setTukang] = useState("");

  //Hobby
  const [hobby, setHobby] = useState("");

  //Bacaan
  const [majalah, setMajalah] = useState("");
  const [novel, setNovel] = useState("");
  const [komik, setKomik] = useState("");
  const [bukuPelajaran, setBukuPelajaran] = useState("");

  //Musik
  const [musik, setMusik] = useState("");

  //minat
  const [minatOne, setMinatOne] = useState("");
  const [minatTwo, setMinatTwo] = useState("");
  const [minatThree, setMinatThree] = useState("");
  const [minatFour, setMinatFour] = useState("");
  const [minatFive, setMinatFive] = useState("");
  const [minatSix, setMinatSix] = useState("");
  const [minatSeven, setMinatSeven] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    submitToAPI();
  };

  const submitToAPI = async () => {
    const data = {
      nama: namaLengkap,
      tglLahir: tanggalLahir,
      gender,
      tmptLahir: tempatLahir,
      jurusan,
      alamat: alamatLengkap,
      dataFlk: {
        peserta: {
          namaLengkap,
          namaSekolah,
          tanggalLahir,
          kelas,
          tempatLahir,
          alamatLengkap,
          gender,
          jurusan,
        },
        orangTua: {
          namaIbu,
          pekerjaanIbu,
          teleponIbu,
          alamatOrangTua,
          namaAyah,
          pekerjaanAyah,
          teleponAyah,
        },
        matpel: {
          ipa,
          ips,
          bahasa,
          matematika,
          sejarah,
          seniBudaya,
          pendidikanAgama,
          penjasorkers,
        },
        pekerjaan: {
          ahliPertanian,
          insinyur,
          akuntan,
          ilmuwan,
          marketing,
          seniman,
          penulis,
          musisi,
          guru,
          karyawanBank,
          tukang,
        },
        hobby: {
          hobby,
        },
        bacaan: {
          majalah,
          novel,
          komik,
          bukuPelajaran,
        },
        musik: {
          musik,
        },
        minat: {
          minatOne,
          minatTwo,
          minatThree,
          minatFour,
          minatFive,
          minatSix,
          minatSeven,
        },
      },
    };

    try {
      // console.log(data);
      // Upload to API
      const psikotesLS = JSON.parse(localStorage.getItem("psikotesTalenta"));
      await API.patch(`/peserta/${psikotesLS.peserta.id}`, data);

      nextTesHandlerUtil(
        changeCurrentTes,
        changeCurrentSoal,
        changeCurrentPetunjuk
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DataDiriContainer>
      <DataDiriTitle title="DATA PESERTA TES" />
      <h2>Form A1: Identitas Diri Siswa</h2>
      <DataDiriForm onSubmit={handleSubmit}>
        <SectionWrapper>
          <HalfSection>
            <InputGroup
              label="Nama Lengkap"
              id="namaLengkap"
              type="text"
              state={namaLengkap}
              setState={setNamaLengkap}
            />

            <SelectGroup
              label="Jenis Kelamin"
              state={gender}
              setState={setGender}
            >
              <option value=""></option>
              <option value="L">Laki-laki</option>
              <option value="P">Perempuan</option>
            </SelectGroup>

            <InputGroup
              label="Kelas"
              id="kelas"
              type="text"
              state={kelas}
              setState={setKelas}
            />
            <InputGroup
              label="Jurusan"
              id="jurusan"
              type="text"
              state={jurusan}
              setState={setJurusan}
            />
          </HalfSection>
          <HalfSection>
            <InputGroup
              label="Nama Sekolah"
              id="namaSekolah"
              type="text"
              state={namaSekolah}
              setState={setNamaSekolah}
            />
            <InputGroup
              label="Tempat Kelahiran"
              id="tempatLahir"
              type="text"
              state={tempatLahir}
              setState={setTempatLahir}
            />

            <InputGroup
              label="Tanggal Lahir"
              id="tanggal-lahir"
              type="date"
              state={tanggalLahir}
              setState={setTanggalLahir}
            />

            <InputGroup
              label="Alamat Lengkap"
              type="text"
              id="alamatLengkap"
              state={alamatLengkap}
              setState={setAlamatLengkap}
            />
          </HalfSection>
        </SectionWrapper>
        <h2>Form A2: Identitas Orang Tua</h2>
        <SectionWrapper>
          <HalfSection>
            <InputGroup
              label="Nama Ayah"
              id="namaAyah"
              type="text"
              state={namaAyah}
              setState={setNamaAyah}
            />

            <InputGroup
              label="Pekerjaan Ayah"
              id="pekerjaanAyah"
              type="text"
              state={pekerjaanAyah}
              setState={setPekerjaanAyah}
            />

            <InputGroup
              label="Telepon Ayah"
              type="text"
              id="teleponAyah"
              state={teleponAyah}
              setState={setTeleponAyah}
            />
            <InputGroup
              label="Alamat Orang Tua"
              type="text"
              id="alamatOrangTua"
              state={alamatOrangTua}
              setState={setAlamatOrangTua}
            />
          </HalfSection>
          <HalfSection>
            <InputGroup
              label="Nama Ibu"
              id="namaIbu"
              type="text"
              state={namaIbu}
              setState={setNamaIbu}
            />

            <InputGroup
              label="Pekerjaan Ibu"
              id="pekerjaanIbu"
              type="text"
              state={pekerjaanIbu}
              setState={setPekerjaanIbu}
            />

            <InputGroup
              label="Telepon Ibu"
              type="text"
              id="teleponIbu"
              state={teleponIbu}
              setState={setTeleponIbu}
            />
          </HalfSection>
        </SectionWrapper>
        <h2>Form B1: Self Assessment</h2>
        <h3>Bidang Studi yang Diminati</h3>
        <p>Urutkan dari jenis bidang studi yang anda minati</p>
        <br />
        <SectionWrapper>
          <HalfSection>
            <InputGroup
              label="IPA"
              id="ipa"
              type="text"
              state={ipa}
              setState={setIpa}
            />

            <InputGroup
              label="IPS"
              id="ips"
              type="text"
              state={ips}
              setState={setIps}
            />

            <InputGroup
              label="Bahasa"
              type="text"
              id="bahasa"
              state={bahasa}
              setState={setBahasa}
            />
            <InputGroup
              label="Matematika"
              id="matematika"
              type="text"
              state={matematika}
              setState={setMatematika}
            />
          </HalfSection>
          <HalfSection>
            <InputGroup
              label="Sejarah"
              id="sejarah"
              type="text"
              state={sejarah}
              setState={setSejarah}
            />

            <InputGroup
              label="Seni Budaya"
              id="seniBudaya"
              type="text"
              state={seniBudaya}
              setState={setSeniBudaya}
            />

            <InputGroup
              label="Pendidkan Agama"
              type="text"
              id="pendidikanAgama"
              state={pendidikanAgama}
              setState={setPendidikanAgama}
            />
            <InputGroup
              label="Penjasorkers"
              id="penjasorkers"
              type="text"
              state={penjasorkers}
              setState={setPenjasorkers}
            />
          </HalfSection>
        </SectionWrapper>
        <br />
        <h3>Bidang Pekerjaan yang Diminati</h3>
        <p>Urutkan dari jenis pekerjaan yang anda minati</p>
        <br />
        <SectionWrapper>
          <HalfSection>
            <InputGroup
              label="Ahli Pertanian"
              id="ahliPertanian"
              type="text"
              state={ahliPertanian}
              setState={setAhliPertanian}
            />

            <InputGroup
              label="Insinyur"
              id="insinyur"
              type="text"
              state={insinyur}
              setState={setInsinyur}
            />

            <InputGroup
              label="Akuntan/Auditor"
              type="text"
              id="akuntan"
              state={akuntan}
              setState={setAkuntan}
            />
            <InputGroup
              label="Ilmuwan/peneliti"
              id="ilmuwan"
              type="text"
              state={ilmuwan}
              setState={setIlmuwan}
            />
            <InputGroup
              label="Marketing"
              id="marketing"
              type="text"
              state={marketing}
              setState={setMarketing}
            />
            <InputGroup
              label="Seniman"
              id="seniman"
              type="text"
              state={seniman}
              setState={setSeniman}
            />
          </HalfSection>
          <HalfSection>
            <InputGroup
              label="Penulis/Wartawan"
              type="text"
              id="penulis"
              state={penulis}
              setState={setPenulis}
            />
            <InputGroup
              label="musisi/Pianis"
              id="musisi"
              type="text"
              state={musisi}
              setState={setMusisi}
            />
            <InputGroup
              label="Guru/Dosen/Pendidik"
              id="guru"
              type="text"
              state={guru}
              setState={setGuru}
            />
            <InputGroup
              label="Karyawan Bank/Sekertaris"
              id="karyawanBank"
              type="text"
              state={karyawanBank}
              setState={setKaryawanBank}
            />
            <InputGroup
              label="Tukang/Ahli Bangunan"
              id="tukang"
              type="text"
              state={tukang}
              setState={setTukang}
            />
          </HalfSection>
        </SectionWrapper>
        <br />
        <h3>Kegiatan atau Hobby yang digemari</h3>
        <br />
        <SectionWrapper>
          <HalfSection>
            <InputGroup
              label="Kegiatan atau Hobby yang digemari"
              id="hobby"
              type="text"
              state={hobby}
              setState={setHobby}
            />
          </HalfSection>
          <HalfSection />
        </SectionWrapper>
        <br />
        <h2>Form B2: Self Assessment</h2>
        <h3>Buku yang Diminati</h3>
        <p>Urutkan dari jenis Buku yang anda minati</p>
        <br />
        <SectionWrapper>
          <HalfSection>
            <InputGroup
              label="Majalah"
              id="majalah"
              type="text"
              state={majalah}
              setState={setMajalah}
            />
            <InputGroup
              label="Novel"
              id="novel"
              type="text"
              state={novel}
              setState={setNovel}
            />
          </HalfSection>
          <HalfSection>
            <InputGroup
              label="Komik"
              id="komik"
              type="text"
              state={komik}
              setState={setKomik}
            />
            <InputGroup
              label="Buku Pelajaran/Makalah/Jurnal"
              id="bukuPelajaran"
              type="text"
              state={bukuPelajaran}
              setState={setBukuPelajaran}
            />
          </HalfSection>
        </SectionWrapper>

        <br />
        <h3>Musik yang Anda minati</h3>
        <br />
        <SectionWrapper>
          <HalfSection>
            <InputGroup
              label="Musik yang Anda minati"
              id="musik"
              type="text"
              state={musik}
              setState={setMusik}
            />
          </HalfSection>
          <HalfSection />
        </SectionWrapper>

        <h2>Form B3: Minat dan Orientasi Kerja</h2>
        <SectionWrapper variant="col">
          <TextAreaGroup
            label="1. Apa yang menjadi keinginan dan cita-cita yang ingin diraih ?"
            id="minatOne"
            state={minatOne}
            setState={setMinatOne}
          />
          <TextAreaGroup
            label="2. Sebutkan minimal 3 (tiga) kelebihan karakter/sifat diri pribadi anda !"
            id="minatTwo"
            state={minatTwo}
            setState={setMinatTwo}
          />
          <TextAreaGroup
            label="3. Sebutkan minimal 3 (tiga) kelemahan karakter/sifat diri pribadi anda !"
            id="minatThree"
            state={minatThree}
            setState={setMinatThree}
          />
          <TextAreaGroup
            label="4. Sebutkan prestasi yang sudah anda raih selama ini !"
            id="minatFour"
            state={minatFour}
            setState={setMinatFour}
          />
          <TextAreaGroup
            label="5. Sebutkan kegagalan yang pernah anda alami selama ini"
            id="minatFive"
            state={minatFive}
            setState={setMinatFive}
          />
          <TextAreaGroup
            label="6. Apa sajakah aktivitas yang menjadi prioritas bagi anda yang dilakukan secara rutin?"
            id="minatSix"
            state={minatSix}
            setState={setMinatSix}
          />
          <TextAreaGroup
            label="7. Apa sajakah aktivitas (hobby/kegemaran) yang sering anda lakukan di saat waktu luang anda?"
            id="minatSeven"
            state={minatSeven}
            setState={setMinatSeven}
          />
        </SectionWrapper>

        <ButtonKirim />
      </DataDiriForm>
    </DataDiriContainer>
  );
};

export default connect(
  mapStateToPropsDataDiri,
  mapDispatchToPropsDataDiri
)(Flk);
