import SideBar from "../../../components/SideBar";

const DetailLogAdmin = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <SideBar />
      <div>This is DetailLogAdmin page</div>
    </div>
  );
};

export default DetailLogAdmin;
