import React from 'react';

const HeaderFirst = () => {
  return (
    <th colSpan="14" style={{ verticalAlign: 'middle' }}>
      TIKI
    </th>
  );
};

export default HeaderFirst;
