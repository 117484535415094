import { useState } from "react";
import Benar from "../../../../Components/PetunjukMiddle/Benar";
import Footer from "../../../../Components/PetunjukMiddle/Footer";
import PetunjukMiddleContainer from "../../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import Salah from "../../../../Components/PetunjukMiddle/Salah";
import PetunjukParagraph from "../../../../Components/PetunjukMiddle/PetunjukParagraph";
import PilihanWrapper from "../../../../Components/PilihanWrapper";
import PilihanText from "../../../../Components/PilihanText";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  soal,
  kunci,
  next,
  previous,
  pesanBenar,
}) => {
  const [answer, setAnswer] = useState("");
  const arrPilihan = [
    "A. Jenis Olahraga",
    "B. Bahan Makanan",
    "C. Kota",
    "D. Pekerjaan",
    "E. Bengkel Kerja",
  ];

  return (
    <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
      <PetunjukParagraph textAlign="center">{soal}</PetunjukParagraph>
      <PilihanWrapper variant="text" col={2}>
        {arrPilihan?.map((item, index) => (
          <PilihanText
            label={item}
            onClick={() => setAnswer(item)}
            answer={answer}
            key={index}
          />
        ))}
      </PilihanWrapper>
      <Benar isShow={answer === kunci} htmlString={pesanBenar} />
      <Salah isShow={answer !== kunci && answer !== ""} />

      <Footer
        min={1}
        max={5}
        previous={previous}
        next={next}
        condition={answer === kunci}
      />
    </PetunjukMiddleContainer>
  );
};

export default PetunjukMiddle;
