import React from 'react';
import DataDiriColumns from '../columns/DataDiriColumns';
import SubColumn1DataDiri from '../columns/DataDiriColumns/SubColumn1';
import KraepelinEDUColumns from '../columns/KraepelinEDUColumns';
import SubColumn1Kraepelin from '../columns/KraepelinColumns/SubColumn1';
import SubColumn1SelfAssessmentNumber from '../columns/SelfAssessmentNumberColumns/SubColumn1';
import SubColumn1SelfAssessmentString from '../columns/SelfAssessmentStringColumns/SubColumn1';
import SelfAssessmentNumberColumns from '../columns/SelfAssessmentNumberColumns';
import SelfAssessmentStringColumns from '../columns/SelfAssessmentStringColumns';
import CFIT3BRSColumns from '../columns/CFIT3B(RS)Columns';
import IQCFITColumns from '../columns/IQCFITColumns';
import SubColumn1CFIT3BRS from '../columns/CFIT3B(RS)Columns/SubColumn1';
import KlasifikasiColumns from '../columns/KlasifikasiColumns';
import DISCColumns from '../columns/DISCColumns';
import SubColumn1DISC from '../columns/DISCColumns/SubColumn1';
import KostickColumns from '../columns/KostickColumns';
import SubColumn1Kostick from '../columns/KostickColumns/SubColumn1';
import DataDiriEduColumns from '../columns/DataDiriEduColumns';
import DataDiriPesertaColumns from '../columns/DataDiriPesertaColumns';
import SubColumn1DataDiriPeserta from '../columns/DataDiriPesertaColumns/SubColumn1';
import SubColumn2Kraepelin from '../columns/KraepelinColumns/SubColumn2';
import KraepelinColumns from '../columns/KraepelinColumns';
import SubColumn1KraepelinEDU from '../columns/KraepelinEDUColumns/SubColumn1';
import TikiColumns from '../columns/TikiColumns';
import SubColumn1Tiki from '../columns/TikiColumns/SubColumn1';
import SubColumn2Tiki from '../columns/TikiColumns/SubColumn2';
import CFIT3BColumns from '../columns/CFIT3B';
import SubColumn1CFIT3B from '../columns/CFIT3B/SubColumn1';
import SubColumn1Holland from '../columns/HollandColumns/SubColumn1';
import HollandColumns from '../columns/HollandColumns';
import SubColumn2Holland from '../columns/HollandColumns/SubColumn2';
import TikiLPPIColumns from '../columns/TikiLPPIColumns';
import SubColumn1TikiLPPI from '../columns/TikiLPPIColumns/SubColumn1';
import SubColumn2TikiLPPI from '../columns/TikiLPPIColumns/SubColumn2';
import {
  CFIT3B,
  CFIT3B_RS,
  DATA_DIRI,
  DATA_DIRI_EDU,
  DATA_DIRI_PESERTA,
  DISC,
  HOLLAND,
  IQ_CFIT,
  KLASIFIKASI,
  KOSTICK,
  KRAEPELIN,
  KRAEPELIN_EDU,
  SELF_ASSESSMENT_NUMBER,
  SELF_ASSESSMENT_STRING,
  TIKI,
  TIKI_LPPI,
} from '../ActionTypes';

export default function TheadColumns({ formatLaporanExcel }) {
  return (
    <>
      <tr>
        {/* COLUMNS */}
        {formatLaporanExcel.datas.map((format) => {
          if (format.name === DATA_DIRI) {
            return (
              <>
                <DataDiriColumns format={format} />
              </>
            );
          }
          if (format.name === DATA_DIRI_PESERTA) {
            return (
              <>
                <DataDiriPesertaColumns format={format} />
              </>
            );
          }
          if (format.name === DATA_DIRI_EDU) {
            return (
              <>
                <DataDiriEduColumns format={format} />
              </>
            );
          }
          if (format.name === SELF_ASSESSMENT_NUMBER) {
            return (
              <>
                <SelfAssessmentNumberColumns format={format} />
              </>
            );
          }
          if (format.name === SELF_ASSESSMENT_STRING) {
            return (
              <>
                <SelfAssessmentStringColumns format={format} />
              </>
            );
          }
          if (format.name === CFIT3B) {
            return (
              <>
                <CFIT3BColumns format={format} />
              </>
            );
          }
          if (format.name === CFIT3B_RS) {
            return (
              <>
                <CFIT3BRSColumns format={format} />
              </>
            );
          }
          if (format.name === IQ_CFIT) {
            return (
              <>
                <IQCFITColumns format={format} />
              </>
            );
          }
          if (format.name === KLASIFIKASI) {
            return (
              <>
                <KlasifikasiColumns format={format} />
              </>
            );
          }
          if (format.name === KRAEPELIN) {
            return (
              <>
                <KraepelinColumns format={format} />
              </>
            );
          }
          if (format.name === KRAEPELIN_EDU) {
            return (
              <>
                <KraepelinEDUColumns format={format} />
              </>
            );
          }
          if (format.name === DISC) {
            return (
              <>
                <DISCColumns format={format} />
              </>
            );
          }
          if (format.name === KOSTICK) {
            return (
              <>
                <KostickColumns format={format} />
              </>
            );
          }
          if (format.name === TIKI) {
            return (
              <>
                <TikiColumns format={format} />
              </>
            );
          }
          if (format.name === TIKI_LPPI) {
            return (
              <>
                <TikiLPPIColumns format={format} />
              </>
            );
          }
          if (format.name === HOLLAND) {
            return (
              <>
                <HollandColumns format={format} />
              </>
            );
          }
        })}
      </tr>

      {/* SUBCOLUMNS 1 */}
      <tr>
        {formatLaporanExcel.datas.map((format) => {
          if (format.name === DATA_DIRI) {
            return <SubColumn1DataDiri format={format} />;
          }
          if (format.name === DATA_DIRI_PESERTA) {
            return <SubColumn1DataDiriPeserta format={format} />;
          }
          if (format.name === SELF_ASSESSMENT_NUMBER) {
            return <SubColumn1SelfAssessmentNumber format={format} />;
          }
          if (format.name === SELF_ASSESSMENT_STRING) {
            return <SubColumn1SelfAssessmentString format={format} />;
          }
          if (format.name === CFIT3B) {
            return <SubColumn1CFIT3B format={format} />;
          }
          if (format.name === CFIT3B_RS) {
            return <SubColumn1CFIT3BRS format={format} />;
          }
          if (format.name === KRAEPELIN) {
            return <SubColumn1Kraepelin format={format} />;
          }
          if (format.name === KRAEPELIN_EDU) {
            return <SubColumn1KraepelinEDU format={format} />;
          }
          if (format.name === DISC) {
            return <SubColumn1DISC format={format} />;
          }
          if (format.name === KOSTICK) {
            return <SubColumn1Kostick format={format} />;
          }
          if (format.name === TIKI) {
            return <SubColumn1Tiki format={format} />;
          }
          if (format.name === TIKI_LPPI) {
            return <SubColumn1TikiLPPI format={format} />;
          }
          if (format.name === HOLLAND) {
            return <SubColumn1Holland format={format} />;
          }
        })}
      </tr>

      {/* SUBCOLUMNS 2 */}
      {formatLaporanExcel.isSubColumn2 && (
        <>
          <tr></tr>
          <tr>
            {formatLaporanExcel.datas.map((format) => {
              if (format.name === KRAEPELIN) {
                return <SubColumn2Kraepelin format={format} />;
              }
              if (format.name === TIKI) {
                return <SubColumn2Tiki format={format} />;
              }
              if (format.name === TIKI_LPPI) {
                return <SubColumn2TikiLPPI format={format} />;
              }
              if (format.name === HOLLAND) {
                return <SubColumn2Holland format={format} />;
              }
            })}
          </tr>
        </>
      )}
    </>
  );
}
