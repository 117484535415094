import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import styles from "./Petunjuk.module.css";
import Footer from "../../../Components/PetunjukMiddle/Footer";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <div
        className={styles.petunjukZero}
        style={{ display: index === 0 ? "flex" : "none" }}
      >
        <p>
          Pada persoalan berikut, Anda akan menghadapi beberapa kasus yang
          terdiri dari memo/ dokumen kerja. Tugas Anda adalah membuat respon
          atas memo/ dokumen tersebut dan membagi permasalahan-permasalahan
          tersebut ke dalam 3 kategori :
        </p>
        <ol>
          <b>
            <li>High Priority</li>
          </b>
          <b>
            <li>Moderate Priority</li>
          </b>
          <b>
            <li>Low Priority</li>
          </b>
        </ol>

        <p>
          Anda bebas untuk mengerjakan dokumen mana yang akan Anda selesaikan
          terlebih dahulu. Yang terpenting berikan kategori pada setiap
          permasalahan dan tuliskan urutan permasalahan menurut masalah yang
          paling prioritas untuk diselesaikan.
        </p>

        <p>
          Tulislah respon Anda untuk setiap permasalahan tadi pada kolom
          jawaban, setelah yakin tekan <strong>Lanjut</strong> untuk mengisi
          jawabannya
        </p>

        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>

        <p>
          <font color="red">
            Jangan ditutup terlebih dulu petunjuk ini, baca dan pahami
            instruksi. Apabila petunjuk ini ditutup maka tes dimulai dan waktu
            berjalan.
          </font>
        </p>

        <div className={styles.buttonWrapper}>
          <button onClick={nextHandler}>Tutup</button>
        </div>
      </div>

      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 1 ? "flex" : "none" }}
      >
        <p>
        Anda adalah Evan Amulia, seorang karyawan yang telah menduduki posisi sebagai manager pengembangan bisnis selama 6 bulan di sebuah organisasi swasta yang bergerak di bidang distribusi, bernama Talenta Indonesia Jaya. PT Talenta Indonesia Jaya adalah perusahaan distribusi makanan dan minuman dingin terbesar di Indonesia, dan importir terkemuka produk makanan beku, dingin, kering, serta susu dan minuman. PT. Talenta Indonesia Jaya merupakan perusahaan yang bergerak di bidang distribusi produk dari PT. Crystal Cold Storage.
        </p>
        <Footer previous={previousHandler} next={nextHandler} condition />
      </div>
      
      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 2 ? "flex" : "none" }}
      >
        <p>
        Hari ini, Senin 5 Januari adalah hari pertama kembali masuk kerja setelah 3 hari mengambil cuti untuk liburan bersama keluarga. Pada meja kerja Anda tersusun rapi beberapa memo dan dokumen kerja yang menunggu untuk direspon
        </p>
        <p>Waktu keseluruhan yang Anda perlukan untuk melakukan semua tugas tersebut adalah 60 menit.</p>
        <Footer previous={previousHandler} next={nextHandler} condition />
      </div>

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
