import styles from './InputGroup.module.css';

const InputGroup = ({ label, id, type, state, setState, maxLength = 50 }) => {
  return (
    <div className={styles.inputGroup}>
      <label htmlFor={id}>{label}</label>
      <br />
      <input
        type={type}
        id={id}
        required
        value={state}
        onChange={(e) => setState(e.target.value)}
        maxLength={maxLength}
      />
    </div>
  );
};

export default InputGroup;
