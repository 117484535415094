import React from 'react';

import TheadColumns from '../TheadColumns';
import TBodyRows from './TBodyRows';
import styles from './Table.module.css';

const TableEDU = ({ projectId, tableRef, formatLaporanExcel, datas }) => {
  return (
    <div>
      <table ref={tableRef} className={styles.tableRekap} border="2">
        <thead>
          <tr>
            <th colSpan={50}>
              <h4>REKAPITULASI PT ENERGI DATA UTAMA</h4>
            </th>
          </tr>
          <tr></tr>
          <TheadColumns formatLaporanExcel={formatLaporanExcel} />
        </thead>
        <tbody>
          <TBodyRows datas={datas} />
        </tbody>
      </table>
    </div>
  );
};

export default TableEDU;
