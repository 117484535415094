import Alert from 'react-bootstrap/Alert';

import styles from './FormLogin.module.css';
import usernameIcon from '../../../../assets/login/username-icon.svg';
import passwordIcon from '../../../../assets/login/password-icon.svg';
import { useForm } from 'react-hook-form';
import { API } from '../../../../services/API';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { userLogin } from '../../../../redux/User/user-actions';

const FormLogin = ({ userLogin }) => {
  const [error, setError] = useState(false);

  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const loginHandler = async (data) => {
    try {
      const dataBody = {
        email: data.email,
        pass: data.password.toString(),
      };

      const res = await API.post('/login', dataBody);
      setError(false);

      localStorage.setItem('talentaToken', res.data.token);

      // Redux handler
      userLogin(res.data.token);

      reset();
      history.push('/dashboard');
    } catch (error) {
      if (error?.response?.status === 401) {
        setError(error.response.data.message);
      } else {
        setError('Email / Password Salah');
      }
      setTimeout(() => {
        setError('');
      }, 4000);
    }
  };

  return (
    <form onSubmit={handleSubmit(loginHandler)} className={styles.formLogin}>
      {error ? (
        <Alert key="danger" variant="danger" className={styles.error}>
          {error}
        </Alert>
      ) : (
        <div></div>
      )}
      <div>
        <div className={styles.inputWrapper}>
          <img src={usernameIcon} alt="username" width={16} />
          <input
            type="email"
            placeholder="Email"
            {...register('email', {
              required: {
                value: true,
                message: 'Email is required!',
              },
            })}
            name="email"
            autoComplete="off"
          />
        </div>
        {errors.email && <p className={styles.error}>{errors.email.message}</p>}
      </div>

      <div>
        <div className={styles.inputWrapper}>
          <img src={passwordIcon} alt="password" width={16} />
          <input
            type="password"
            placeholder="Password"
            {...register('password', {
              required: {
                value: true,
                message: 'Password is required!',
              },
            })}
            autoComplete="off"
          />
        </div>
        {errors.password && (
          <p className={styles.error}>{errors.password.message}</p>
        )}
      </div>

      <button type="submit">Login</button>
    </form>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    userLogin: (token) => dispatch(userLogin(token)),
  };
};

export default connect(null, mapDispatchToProps)(FormLogin);
