import UnggahTestPesertaContent from "../../../components/PerusahaanClient/UnggahTestPesertaContent";
import SideBar from "../../../components/SideBar";
import styles from "./UnggahTestPeserta.module.css";

const UnggahTestPeserta = () => {
  return (
    <div
      className={styles.container}
    >
      <SideBar />
      <UnggahTestPesertaContent />
    </div>
  );
};

export default UnggahTestPeserta;
