import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        namaFile="petunjuk_tikit10_1.png"
        namaPilihan="petunjuk_tikit10_1pilihan.png"
        pesan="Jawaban yang benar adalah E"
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        namaFile="petunjuk_tikit10_2.png"
        namaPilihan="petunjuk_tikit10_2pilihan.png"
        pesan="Jawaban yang benar adalah B"
        previous={previousHandler}
        next={nextHandler}
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
