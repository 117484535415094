import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>Tes berikut ini terdiri dari gambar-gambar benda.</p>
        <br />
        <p>
          Gambar di sebelah atas dari tiap soal memperlihatkan selembar karton
          dalam bentuk tertentu. Garis yang terputus-putus menunjukkan tempat
          dimana karton tersebut dapat dilipat.
        </p>
        <br />
        <p>
          Gambar di sebelah bawah memperlihatkan 4 buah benda a, b, c dan d yang
          berbeda satu sama lain. Salah satu dari benda-benda tersebut terbentuk
          dari karton yang terdapat di sebelah kiri, bila karton tersebut
          dilipat. Carilah benda yang dimaksud itu.
        </p>
        <br />
        <p>
          Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        namaFile="petunjuk_tikit71.png"
        kunci="d"
        pesanBenar="Diantara keempat benda tersebut, hanya benda d saja yang terbentuk
            dari karton yang terdapat di sebelah bawah jika karton tersebut
            dilipat. Jadi contoh di atas jawaban yang benar adalah d"
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        kunci="c"
        namaFile="petunjuk_tikit72.png"
        pesanBenar="Pada contoh atas, yang tepat adalah jawaban c."
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        kunci="c"
        namaFile="petunjuk_tikit73.png"
        pesanBenar="Pada contoh atas, yang tepat adalah jawaban c."
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={4}
        kunci="b"
        namaFile="petunjuk_tikit74.png"
        pesanBenar="Pada contoh atas, yang tepat adalah jawaban b."
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={5}
        kunci="a"
        namaFile="petunjuk_tikit75.png"
        pesanBenar="Pada contoh atas, yang tepat adalah jawaban a."
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={6}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
