import React from 'react';

import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NavMenuLink = ({ title, url, icon, level = 1 }) => {
  const location = useLocation();
  const isActive = location.pathname === url;

  return (
    <Link
      to={url}
      className={`text-start text-decoration-none ps-5 ps-md-4 py-3 my-1 custom-nav-link ${
        isActive ? 'active' : 'inactive'
      } ${level > 1 ? 'ps-md-5' : ''}`}
    >
      {icon && (
        <span className="icon-wrapper">
          <FontAwesomeIcon size="lg" icon={icon} />
        </span>
      )}
      <span className="ms-2">{title}</span>
    </Link>
  );
};

export default NavMenuLink;
