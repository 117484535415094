import { useState } from 'react';
import PetunjukLast from '../../../Components/PetunjukLast';
import PetunjukContainer from '../../../Containers/PetunjukContainer';
import PetunjukZero from '../../../Containers/PetunjukZero';
import PetunjukMiddle from './PetunjukMiddle';

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihanOne = ['8 kursi', '10 kursi', '16 kursi', '24 kursi', 't.a.'];
  const arrPilihanTwo = ['Rp5,20', 'Rp6,90', 'Rp 7,-', 'Rp7,60', 't.a.'];
  const arrPilihanThree = [
    'Rp 400,-',
    'Rp 475,-',
    'Rp 490,-',
    'Rp 525,-',
    't.a.',
  ];
  const arrPilihanFour = [
    '2 meter',
    '2,5 meter',
    '3 meter',
    '3,5 meter',
    't.a.',
  ];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>Tes ini terdiri atas soal-soal berhitung.</p>
        <br />
        <p>
          Setiap soal disertai dengan lima kemungkinan jawaban a, b, c, d, dan
          e. Salah satu diantaranya merupakan jawaban dari soal tersebut.
        </p>
        <p>
          Maksud dari kemungkinan kelima atau e yaitu t.a., adalah bahwa jawaban
          dari soal yang bersangkutan tidak terdapat diantara angka-angka
          jawaban yang tersedia.
        </p>
        <br />

        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="Seseorang memerlukan waktu 1/2 jam untuk mencat sebuah kursi. Berapa kursikah dapat dicatnya dalam waktu 8 jam?"
        arrPilihan={arrPilihanOne}
        kunci="16 kursi"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban anda benar, adalah <strong>16 kursi</strong>. Silahkan klik tombol lanjutkan"
      />

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="Ahmad membelanjakan 1/6 dari gaji per jam untuk makan. Gajinya adalah Rp 47,40. Berapakah yang ia gunakan untuk membeli makanan?"
        arrPilihan={arrPilihanTwo}
        kunci="t.a."
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="jawaban yang benar adalah <strong>Rp 7,90</strong>. Bila Anda perhatikan kemungkinan jawaban-jawaban yang tersedia, maka tidak terdapat Rp 7,90. Dengan demikian jawaban yang benar adalah <strong>t.a.</strong>, yang berarti tidak ada (<strong>t.a.</strong>) satupun jawaban yang dimaksudkan. Silahkan klik tombol lanjutkan"
      />

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        soal="Harun mendapat Rp 75 setiap jam. Berapa upah yang diterimanya dalam 7 jam?"
        arrPilihan={arrPilihanThree}
        kunci="Rp 525,-"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban anda benar, adalah <strong>Rp 525,-</strong>. Silahkan klik tombol lanjutkan"
      />

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={4}
        soal="Dari sebilah papan yang panjangnya 4,5 meter digergaji sebagian sepanjang 1,5 meter. Berapa panjang papan itu setelah digergaji?"
        arrPilihan={arrPilihanFour}
        kunci="3 meter"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban anda benar, adalah <strong>3 meter</strong>. Silahkan klik tombol lanjutkan"
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={5}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
