export default function SubColumn2Kraepelin({ format }) {
  return (
    <>
      <th>JUMLAH PRESTASI</th>
      <th>KATEGORI</th>
      <th>RATA-RATA</th>
      <th>KATEGORI</th>
      <th>JUMLAH KESALAHAN</th>
      <th>KATEGORI</th>
      <th>KONSISTENSI</th>
      <th>KATEGORI</th>
      <th>KETAHANAN</th>
      <th>KATEGORI</th>
    </>
  );
}
