import { useHistory } from "react-router";
import TopSection from "../TopSection";
import styles from "./DaftarKredensialContent.module.css";
import Table from "./Table";

const DaftarKredensialContent = ({ credentials }) => {
  const columns = [
    {
      Header: "No.",
      accessor: "nomor",
    },
    {
      Header: "Kode",
      accessor: "kode",
    },
    {
      Header: "Nama Peserta",
      accessor: "nama",
    },
    {
      Header: "Nama Perusahaan",
      accessor: "namaClient",
    },
    {
      Header: "Nama Project",
      accessor: "namaProject",
    },
    {
      Header: "Modul",
      accessor: "modul",
    },
    {
      Header: "Entry",
      accessor: "entryDate",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];
  const history = useHistory();
  return (
    <div className={styles.container}>
      <TopSection />

      <div className={styles.mainSection}>
        <p className={styles.path}>
          <span className={styles.pathLink} onClick={() => history.push("/")}>
            Beranda
          </span>{" "}
          <span
            className={styles.pathLink}
            onClick={() => history.push("/project")}
          >
            / Project
          </span>{" "}
          <span className={styles.pathNow}>/ Daftar Kredensial</span>
        </p>

        <div className={styles.body}>
          <Table columns={columns} data={credentials} />
        </div>
      </div>
    </div>
  );
};

export default DaftarKredensialContent;
