import GlobalFilter from "./GlobalFilter";
import styles from "./TableHeader.module.css";

const TableHeader = ({
  pageSize,
  setPageSize,
  preGlobalFilteredRows,
  globalFilterState,
  setGlobalFilter,
}) => {
  return (
    <div
      className={styles.wrapper}
    >
      <div>
        <span>Tampilkan: </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          className={styles.showOption}
        >
          {[6, 10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
      </div>

      <div>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilterState}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
    </div>
  );
};

export default TableHeader;
