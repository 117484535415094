import { useEffect } from "react";
import { connect } from "react-redux";
import { replaceJawabans } from "../../../../redux/Peserta/peserta-actions";
import { generateEmptyJawabans } from "../../Utils";
import Petunjuk from "./Petunjuk";
import Soal from "./Soal";

const IstWu = ({ currentPetunjuk, currentTes, replaceJawabans }) => {
  useEffect(() => generateEmptyJawabans(replaceJawabans), [replaceJawabans]);

  return (
    <>
      {currentPetunjuk === 16 && <Petunjuk />}
      {currentPetunjuk !== 16 && currentTes.tesId === 16 && <Soal />}
    </>
  );
};

const mapStateToProps = (state) => ({
  currentTes: state.peserta?.currentTes,
  currentPetunjuk: state.peserta?.currentPetunjuk,
});

const mapDispatchToProps = (dispatch) => ({
  replaceJawabans: (jawabans) => dispatch(replaceJawabans(jawabans)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IstWu);
