import { connect } from "react-redux";
import PetunjukParagraph from "../../Components/PetunjukMiddle/PetunjukParagraph";
import PetunjukContainer from "../../Containers/PetunjukContainer";
import { mapDispatchToPropsPetunjuk } from "../../Utils/reduxMap";
import styles from "./Petunjuk.module.css";

const Petunjuk = ({ changeCurrentPetunjuk, isModal, tutupModalHandler }) => {
  const tutupHandler = () => {
    const psikotesLS = JSON.parse(localStorage.getItem("psikotesTalenta"));
    psikotesLS.currentPetunjuk = 0;
    localStorage.setItem("psikotesTalenta", JSON.stringify(psikotesLS));

    changeCurrentPetunjuk(0);
  };

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <div className={styles.petunjukLast}>
        <PetunjukParagraph>
          Terdapat pernyataan dengan beberapa pilihan jawaban. Pilih jawaban
          yang Anda anggap sesuai dengan keadaan diri Anda.
        </PetunjukParagraph>
        <br />
        <PetunjukParagraph>
          Anda menjawab dengan menekan jawaban yang tersedia. Tidak perlu
          terlalu lama mempertimbangkan jawaban tersebut. Persoalan ini tidak
          membutuhkan waktu namun bekerjalah dengan cepat dan teliti.
        </PetunjukParagraph>

        <div className={styles.footer}>
          {isModal ? (
            <button onClick={tutupModalHandler}>Tutup</button>
          ) : (
            <button onClick={tutupHandler}>Tutup</button>
          )}
        </div>
      </div>
    </PetunjukContainer>
  );
};

export default connect(null, mapDispatchToPropsPetunjuk)(Petunjuk);
