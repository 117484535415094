import React from 'react';

// * Utils
import { birthDate, slashDate } from 'utils/tanggal';

const Column = ({ data }) => {
  // ** Validate if value of 'test_date' is not null
  const getSlashDate = (value) => {
    if (!value) return '-';
    return slashDate(value);
  };

  // ** Validate if value of 'birth' is not null
  const getBirthDate = (value) => {
    if (!value) return '-';
    return birthDate(value);
  };

  // ** Exclude Props
  const excludes = [
    'id',
    'short_name',
    'nik',
    'phone',
    'email',
    'major',
    'company',
    'birth',
    'birth_place',
  ];

  return (
    <React.Fragment>
      {Object.entries(data).map(([key, value]) => {
        if (excludes.includes(key)) return;
        return (
          <td key={key} style={{ verticalAlign: 'middle', textAlign: value ? 'left' : 'center' }}>
            {key === 'test_date'
              ? getSlashDate(value)
              : key === 'birth'
              ? getBirthDate(value)
              : value || '-'}
          </td>
        );
      })}
    </React.Fragment>
  );
};

export default Column;
