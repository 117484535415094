import { useState } from "react";
import PetunjukMiddle from "./PetunjukMiddle";
import PetunjukContainer from "../../Components/PetunjukContainer";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer
      isModal={isModal}
      tutupModalHandler={tutupModalHandler}
      previousHandler={previousHandler}
      nextHandler={nextHandler}
      index={index}
      lastIndex={3}
    >
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        namaFile="contoh-cfit3a4.png"
        pesanBenar='Dari contoh diatas, jawaban yang TEPAT adalah "b" dan "d", yaitu pada kotak b dan d berbeda dibandingkan 3 kotak lainnya yang memiliki 3 sudut sementara b dan e memiliki 4 sudut.'
        kunci={10}
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        namaFile="contoh-cfit3a5.png"
        pesanBenar="Jawaban yang paling TEPAT adalah c dan e, mengapa demikian? Karena ketiga kotak lainnya (a,b dan d) berupa lingkaran berwarna putih, sementara c dan e berupa lingkaran berwarna bukan putih."
        kunci={20}
        previous={previousHandler}
        next={nextHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
