import React from 'react';

import { isObjectEmpty, getEmptyData } from 'utility/Utils';

const Column = ({ data }) => {
  if (isObjectEmpty(data)) return getEmptyData(10);

  return (
    <React.Fragment>
      {Object.entries(data).map(([key, value]) => {
        return (
          <td key={key} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
            {key === 'averageD'
              ? value.toFixed(2)
              : key === 'resilience'
              ? value.toFixed(2)
              : value || '-'}
          </td>
        );
      })}
    </React.Fragment>
  );
};

export default Column;
