import { connect } from "react-redux";
import { useEffect, useState } from "react";
import PetunjukModal from "../../Components/PetunjukModal";
import Petunjuk from "../Petunjuk";
import { setJawabansLS } from "../../Utils";
import {
  mapDispatchToPropsSoal,
  mapStateToPropsSoal,
} from "../../Utils/reduxMap";
import SoalContent from "../../Containers/SoalContent";
import { setAnswerLS } from "../../Utils";
import SoalText from "../../Components/SoalText";
import PilihanOrange from "../../Components/PilihanOrange";
import SoalContainer from "../../Containers/SoalContainer";
import SoalHeaderAlt from "../../Components/SoalHeaderAlt";
import PilihanWrapper from "../../Components/PilihanWrapper";

const Soal = ({ currentSoal, replaceJawabans }) => {
  const [answer, setAnswer] = useState("");
  const [isShowPetunjukModal, setIsShowPetunjukModal] = useState(false);

  useEffect(() => {
    setAnswer("");
    setAnswerLS(currentSoal, setAnswer);
  }, [currentSoal]);

  useEffect(() => {
    setJawabansLS(answer, replaceJawabans);
  }, [answer, replaceJawabans]);

  return (
    <SoalContainer>
      <SoalHeaderAlt setIsShowPetunjukModal={setIsShowPetunjukModal} />
      <SoalContent>
        <SoalText />
        <PilihanWrapper variant="text">
          {currentSoal?.pilihan?.map((option) => (
            <PilihanOrange key={option} label={option} />
          ))}
        </PilihanWrapper>
      </SoalContent>
      <PetunjukModal
        isShow={isShowPetunjukModal}
        onHide={() => setIsShowPetunjukModal(false)}
      >
        <Petunjuk
          isModal
          tutupModalHandler={() => setIsShowPetunjukModal(false)}
        />
      </PetunjukModal>
    </SoalContainer>
  );
};

export default connect(mapStateToPropsSoal, mapDispatchToPropsSoal)(Soal);
