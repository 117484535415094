import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { API } from "../../services/API";
import TopSection from "../TopSection";
import styles from "./RoleSettingContent.module.css";
import Table from "./Table";

const RoleSettingContent = () => {
  const history = useHistory();

  const [users, setUsers] = useState([]);

  const getUsers = async () => {
    try {
      const res = await API.get("/user");
      setUsers(
        res.data.data.map((item, index) => ({
          id: item.id,
          nama: item.admin ? item.nama : item?.client?.nama,
          email: item.userName,
          role: item.admin ? "Admin Talenta" : "Admin Perusahaan",
          status: item.isActive ? "Aktif" : "Nonaktif",
          nomor: index + 1,
          aksi: "aksi",
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const columns = [
    {
      Header: "No.",
      accessor: "nomor",
    },
    {
      Header: "Nama",
      accessor: "nama",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Role",
      accessor: "role",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Aksi",
      accessor: "aksi",
    },
  ];

  return (
    <div className={styles.container}>
      <TopSection />

      <div className={styles.mainSection}>
        <h5 className={styles.path} onClick={() => history.push("/dashboard")}>
          Beranda /<span> Role Setting</span>
        </h5>

        <div className={styles.tambahWrapper}>
          <button
            className={styles.tambahButton}
            onClick={() => history.push("/role-setting/tambah")}
          >
            Tambah Admin
          </button>
        </div>

        <div className={styles.body}>
          <h1 className={styles.title}>Role Setting</h1>
          <Table data={users} columns={columns} getUsers={getUsers} />
        </div>
      </div>
    </div>
  );
};

export default RoleSettingContent;
