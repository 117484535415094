import { Button } from 'react-bootstrap';

const columns = [
  {
    name: 'No.',
    sortable: true,
    maxWidth: '100px',
    selector: (__, index) => index + 1,
  },
  {
    name: 'Nama Tes',
    sortable: true,
    selector: (row) => row.nama,
  },
  {
    name: 'Waktu',
    selector: (row) => row.waktu,
  },
  {
    name: 'Opsi',
    sortable: true,
    selector: (row) => {
      return (
        <div>
          <Button size="sm" variant="primary" className="me-0 me-md-2">
            Preview
          </Button>
          <Button size="sm" variant="success" className="me-0 me-md-2">
            Edit
          </Button>
          <Button size="sm" variant="danger">
            Hapus
          </Button>
        </div>
      );
    },
  },
];

export { columns };
