import styles from "./Jumbotron.module.css";
import circle from "../../assets/login/circle.svg";
import whiteBrandLogo from "../../assets/login/white-brand-logo.svg";
import laptopTalenta from "../../assets/login/laptop-talenta.svg";

const Jumbotron = () => {
  return (
    <div className={styles.jumbotron}>
      <div className={styles.background}>
        <img src={circle} alt="circle" className={styles.circle} />
      </div>
      <div className={styles.jumbotronBody}>
        <img src={whiteBrandLogo} alt="talenta-indonesia" width={262} />

        <div className={styles.laptopWrapper}>
          <img
            src={laptopTalenta}
            alt="laptop-talenta-indonesia"
            className={styles.laptopTalenta}
          />
        </div>

        <h1 className={styles.jumbotronTitle}>
          Online Assessment by Talenta Indonesia
        </h1>

        <p>
          Konsultan ahli (psikolog) yang kompeten di bidangnya dan komprehensif
          dalam pelayanan.
        </p>
      </div>
    </div>
  );
};

export default Jumbotron;
