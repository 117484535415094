import styles from "./SectionFourteen.module.css";

const SectionFourteen = () => {
  return (
    <section className={styles.container}>
      <div className={styles.table}>
        <div className={styles.head}>
          <h1>Arah Kerja</h1>
        </div>

        <div className={styles.row}>
          <h2>N</h2>
          <p>
            komitmen tinggi ,lebih suka menangani pekerjaan satu demi satu, akan
            tetapi mengubah prioritas jika terpaksa
          </p>
        </div>

        <div className={styles.row}>
          <h2>H</h2>
          <p>
            komitmen tinggi ,lebih suka menangani pekerjaan satu demi satu, akan
            tetapi mengubah prioritas jika terpaksa
          </p>
        </div>

        <div className={styles.row}>
          <h2>A</h2>
          <p>
            komitmen tinggi ,lebih suka menangani pekerjaan satu demi satu, akan
            tetapi mengubah prioritas jika terpaksa
          </p>
        </div>
      </div>

      <div className={styles.table}>
        <div className={styles.head}>
          <h1>Arah Kerja</h1>
        </div>

        <div className={styles.row}>
          <h2>R</h2>
          <p>
            komitmen tinggi ,lebih suka menangani pekerjaan satu demi satu, akan
            tetapi mengubah prioritas jika terpaksa
          </p>
        </div>

        <div className={styles.row}>
          <h2>D</h2>
          <p>
            komitmen tinggi ,lebih suka menangani pekerjaan satu demi satu, akan
            tetapi mengubah prioritas jika terpaksa
          </p>
        </div>

        <div className={styles.row}>
          <h2>N</h2>
          <p>
            komitmen tinggi ,lebih suka menangani pekerjaan satu demi satu, akan
            tetapi mengubah prioritas jika terpaksa
          </p>
        </div>
      </div>
    </section>
  );
};

export default SectionFourteen;
