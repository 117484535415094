import { useState } from 'react';
import PetunjukLast from '../../../Components/PetunjukLast';
import PetunjukMiddle from './PetunjukMiddle';
import PetunjukContainer from '../../../Containers/PetunjukContainer';
import PetunjukZero from '../../../Containers/PetunjukZero';

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const pilihanOne = ['menikah', 'menebang', 'kawin', ' meneduh'];
  const pilihanTwo = ['selesai', 'tua', 'usia', ' muda'];
  const pilihanThree = ['lembut', 'betul', 'salah', 'sama'];
  const pilihanFour = ['membuka', 'menyongsong', 'menoleh', 'menutup'];
  const pilihanFive = ['jenaka', 'kecil', 'canggung', 'lucu'];

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>Tes berikut ini terdiri dari kata-kata.</p>
        <br />
        <p>Setiap soal terdiri dari 4 kata</p>
        <p>
          Carilah dua buah kata yang memiliki kesamaan arti yang paling dekat.
        </p>

        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        arrPilihan={pilihanOne}
        kunci={5}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Pada contoh diatas dapat dilihat bahwa kata <em>Menikah dan Kawin</em> memiliki kesamaan arti, jadi jawaban contoh diatas adalah <em>Menikah dan Kawin</em>."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        arrPilihan={pilihanTwo}
        kunci={10}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Pada contoh diatas kata <em>Tua dan Muda</em> memiliki arti yang berlawaban satu sama lain, oleh karena itu, huruf <em>Tua dan Muda</em> dapat dipilih."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        arrPilihan={pilihanThree}
        kunci={6}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban yang tepat adalah <em>Betul dan Salah</em> karena memiliki kesamaan arti satu sama lain."
      />

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={4}
        arrPilihan={pilihanFour}
        kunci={9}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban yang tepat adalah <em>Membuka dan Menutup</em> karena memiliki kesamaan arti satu sama lain."
      />

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={5}
        arrPilihan={pilihanFive}
        kunci={9}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban yang tepat adalah <em>Jenaka dan Lucu</em> karena memiliki kesamaan arti satu sama lain."
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={6}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
