import { useState } from "react";
import Benar from "../../../Components/PetunjukMiddle/Benar";
import PetunjukMiddleContainer from "../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import PetunjukParagraph from "../../../Components/PetunjukMiddle/PetunjukParagraph";
import Salah from "../../../Components/PetunjukMiddle/Salah";
import Pilihan from "../../Pilihan";
import styles from "./PetunjukMiddle.module.css";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  soal,
  kunci,
  next,
  previous,
  pesanBenar,
}) => {
  const [answer, setAnswer] = useState("");

  const arrPilihan = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];

  return (
    <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
      <PetunjukParagraph fontSize="36px" textAlign="center">
        {soal}
      </PetunjukParagraph>
      <div className={styles.pilihanWrapper}>
        {arrPilihan?.map((item, index) => (
          <Pilihan
            label={item}
            onClick={() => setAnswer(item)}
            answer={answer}
            key={index}
          />
        ))}
      </div>
      <Benar isShow={answer === kunci} htmlString={pesanBenar} />
      <Salah isShow={answer !== kunci && answer !== ""} />
      <div className={styles.footer}>
        <p>
          Silakan tekan tombol dengan jawaban yang benar untuk penjumlahan di
          atas, jika hasil penjumlahannya 2 digit angka, maka jawablah dengan
          digit angka terakhir saja (Catatan: ini hanya contoh soal, jika
          jawaban Anda salah maka tidak akan mempengaruhi skor Anda)
        </p>
        <button onClick={previous}>Kembali</button>
        {answer === kunci ? (
          <button onClick={next}>Lanjutkan</button>
        ) : (
          <button className={styles.pasifButton}>Lanjutkan</button>
        )}
      </div>
    </PetunjukMiddleContainer>
  );
};

export default PetunjukMiddle;
