import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);
  const [answerOne, setAnswerOne] = useState("");
  const [answerTwo, setAnswerTwo] = useState("");

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>Persoalan yang akan Anda kerjakan adalah berupa deret angka.</p>
        <br />
        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="2 - 6 - 8 - 10 - 12 - 14 - ?"
        answer={answerOne}
        setAnswer={setAnswerOne}
        kunci={"16"}
        next={nextHandler}
        previous={previousHandler}
        isModal={isModal}
        pesanBenar="Pada deret ini angka berikutnya selalu didapat jika angka di depannya ditambah dengan 2. Oleh karena itu Anda mengisi dengan <strong>16</strong>."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="9 - 7 - 10 - 8 - 11 - 9 - 12 - ?"
        answer={answerTwo}
        setAnswer={setAnswerTwo}
        kunci={"10"}
        next={nextHandler}
        previous={previousHandler}
        isModal={isModal}
        pesanBenar="Pada deret ini selalu berganti-ganti harus dikurangi dengan 2 dan setelah itu ditambah dengan 3. Jawaban yang tepat adalah 10."
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
