import { connect } from 'react-redux';
import styles from './SoalHeaderAlt.module.css';
import ButtonPetunjuk from '../ButtonPetunjuk';
import { useEffect, useState } from 'react';

const SoalHeaderAlt = ({
  currentTes,
  currentSoal,
  setIsShowPetunjukModal,
  type = 'single',
}) => {
  const [progress, setProgress] = useState('');

  useEffect(() => {
    const generateProgress = () => {
      const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));

      let countAnswered = 0;
      let countTotalAnswer = 0;

      psikotesLS.jawabans.forEach((jawaban) => {
        if (jawaban.tesId === currentTes.tesId) {
          if (type === 'single') {
            if (jawaban.jawaban) {
              countAnswered++;
            }
            countTotalAnswer++;
          } else {
            if (jawaban.jawaban.length > 0) {
              countAnswered++;
            }
            countTotalAnswer++;
          }
        }
      });

      const strProgress = ((countAnswered / countTotalAnswer) * 100).toString();
      return strProgress.substring(
        0,
        strProgress.indexOf('.') < 0
          ? strProgress.length
          : strProgress.indexOf('.')
      );
    };

    setProgress(generateProgress());
  }, [currentTes.tesId, currentSoal, type]);

  let namaTes = currentTes?.tes?.nama?.toLowerCase();
  let displayNamaTes = currentTes?.tes?.nama;

  if (/tiki-t/g.test(namaTes)) {
    displayNamaTes = 'TES INTELEGENSI 1';
  } else if (/cfit/g.test(namaTes)) {
    displayNamaTes = 'TES INTELEGENSI 2';
  } else if (/ist/y.test(namaTes)) {
    displayNamaTes = 'TES INTELEGENSI 3';
  } else if (/tiki-m/g.test(namaTes)) {
    displayNamaTes = 'TES INTELEGENSI SEKOLAH 1';
  } else if (/tiki-d/g.test(namaTes)) {
    displayNamaTes = 'TES INTELEGENSI SEKOLAH 2';
  } else if (/eas/y.test(namaTes)) {
    displayNamaTes = 'TES APTITUDE 1';
  } else if (/c2-komponen/g.test(namaTes)) {
    displayNamaTes = 'TES APTITUDE 1';
  } else if (/disc/g.test(namaTes)) {
    displayNamaTes = 'PERSONALITY ASSESSMENT 1';
  } else if (/kostick/g.test(namaTes)) {
    displayNamaTes = 'PERSONALITY ASSESSMENT 2';
  } else if (/mbti/g.test(namaTes)) {
    displayNamaTes = 'PERSONALITY ASSESSMENT 3';
  } else if (/msdt/g.test(namaTes)) {
    displayNamaTes = 'MANAGEMENT STYLE TEST';
  } else if (/kraepelin/g.test(namaTes)) {
    displayNamaTes = 'PERFORMANCE POTENTIAL TEST';
  } else if (/epps/g.test(namaTes)) {
    displayNamaTes = 'PERSONAL PREFERENCE TEST';
  } else if (/vak/g.test(namaTes)) {
    displayNamaTes = 'TES GAYA BELAJAR';
  } else if (/riasec/g.test(namaTes)) {
    displayNamaTes = 'CARREER TEST';
  } else if (/egatek/g.test(namaTes)) {
    displayNamaTes = 'CARREER TEST 2';
  }

  return (
    <header className={styles.header}>
      <div className={styles.leftHeader}>
        <p className={styles.title}>Progress Test</p>
        <p className={styles.subtitle}>{displayNamaTes}</p>
      </div>

      <div className={styles.progressWrapper}>
        <p>{progress}%</p>
        <div className={styles.progressBackground}>
          <div
            className={styles.progressFill}
            style={{
              width: `${progress}%`,
            }}
          ></div>
        </div>
      </div>

      <div className={styles.waktu}>
        <ButtonPetunjuk onClick={() => setIsShowPetunjukModal(true)} />
      </div>
    </header>
  );
};

const mapStateToProps = (state) => ({
  currentTes: state.peserta?.currentTes,
  currentSoal: state.peserta?.currentSoal,
});

export default connect(mapStateToProps, null)(SoalHeaderAlt);
