import { useState } from 'react';
import PetunjukLast from '../../../Components/PetunjukLast';
import PetunjukContainer from '../../../Containers/PetunjukContainer';
import PetunjukParagraph from '../../../Components/PetunjukMiddle/PetunjukParagraph';
import styles from './Petunjuk.module.css';
import Footer from '../../../Components/PetunjukMiddle/Footer';
import PetunjukZero from '../../../Containers/PetunjukZero';
import PetunjukMiddleContainer from '../../../Components/PetunjukMiddle/PetunjukMiddleContainer';

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Perhatikan contoh soal untuk memahami soal yang akan Anda kerjakan.
        </p>
        <br />
        <p>
          Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum mengerjakan.
        </p>
        <br />
        <p className={styles.warning}>
          Jangan ditutup dulu petunjuk ini, perhatikan contoh, Apabila petunjuk
          ini ditutup maka tes dimulai dan waktu berjalan.
        </p>

        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddleContainer isShow={index === 1}>
        <PetunjukParagraph>
          Pilihlah tombol SAMA apabila dua bagian dari simbol, huruf atau angka
          tersebut sama dan pilihlah tombol BEDA apabila dua bagian dari simbol,
          huruf atau angka tersebut berbeda.
        </PetunjukParagraph>
        <Soal soal="6123 - 6223" kunci="BEDA" />
        <Soal soal="72,10 - 72.10" kunci="BEDA" />
        <Soal soal="33338 - 333338" kunci="BEDA" />

        <Footer condition next={nextHandler} previous={previousHandler} />
      </PetunjukMiddleContainer>

      <PetunjukMiddleContainer isShow={index === 2}>
        <PetunjukParagraph>
          Pilihlah tombol SAMA apabila dua bagian dari simbol, huruf atau angka
          tersebut sama dan pilihlah tombol BEDA apabila dua bagian dari dari
          simbol, huruf atau angka tersebut berbeda.
        </PetunjukParagraph>

        <Soal soal="$898 - $898" kunci="SAMA" />
        <Soal soal="33333 - 33333" kunci="SAMA" />
        <Soal soal="!667 - !667" kunci="SAMA" />

        <Footer condition next={nextHandler} previous={previousHandler} />
      </PetunjukMiddleContainer>

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

const Soal = ({ soal, kunci }) => (
  <div className={styles.soalWrapper}>
    <h3>{soal}</h3>
    <p>
      Pada contoh ini jawabannya{' '}
      <strong>
        <em>{kunci}.</em>
      </strong>
    </p>
  </div>
);

export default Petunjuk;
