import styles from "./Right.module.css";

const Right = () => {
  return (
    <div className={styles.right}>
      <section className={styles.data}>
        <h1 className={styles.title}>Kekuatan</h1>
        <ol>
          <li>Perfeksionis</li>
          <li>Konservatif</li>
          <li>Diplomatif</li>
          <li>Nebentukan standar</li>
          <li>Memiliki perencanaan jangka panjang</li>
        </ol>
      </section>

      <section className={styles.data}>
        <h1 className={styles.title}>Kelemahan</h1>
        <ol>
          <li>Perfeksionis</li>
          <li>Konservatif</li>
          <li>Diplomatif</li>
          <li>Nebentukan standar</li>
          <li>Memiliki perencanaan jangka panjang</li>
        </ol>
      </section>

      <section className={styles.data}>
        <h1 className={styles.title}>Karakteristik Pekerjaan</h1>
        <ol>
          <li>Perfeksionis</li>
          <li>Konservatif</li>
          <li>Diplomatif</li>
          <li>Nebentukan standar</li>
          <li>Memiliki perencanaan jangka panjang</li>
        </ol>
      </section>
    </div>
  );
};

export default Right;
