import { useEffect } from "react";
import { connect } from "react-redux";
import Petunjuk from "./Petunjuk";
import Soal from "./Soal";
import { generateEmptyJawabans } from "../Utils";
import { mapStateToPropsTes, mapDispatchToPropsTes } from "../Utils/reduxMap";

const Vak = ({ currentPetunjuk, currentTes, replaceJawabans }) => {
  useEffect(() => generateEmptyJawabans(replaceJawabans), [replaceJawabans]);

  return (
    <div>
      {currentPetunjuk === 117 && <Petunjuk />}
      {currentPetunjuk !== 117 && currentTes.tesId === 117 && <Soal />}
    </div>
  );
};

export default connect(mapStateToPropsTes, mapDispatchToPropsTes)(Vak);
