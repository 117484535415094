import { useState } from "react";
import Benar from "../../../../Components/PetunjukMiddle/Benar";
import Footer from "../../../../Components/PetunjukMiddle/Footer";
import PetunjukMiddleContainer from "../../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import Salah from "../../../../Components/PetunjukMiddle/Salah";
import PetunjukParagraph from "../../../../Components/PetunjukMiddle/PetunjukParagraph";
import PilihanWrapper from "../../../../Components/PilihanWrapper";
import PilihanGambar from "../../../../Components/PilihanGambar";
import SoalImage from "../../../../Components/SoalImage";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  namaFile,
  kunci,
  arrPilihan,
  previous,
  next,
}) => {
  const [answer, setAnswer] = useState("");
  const pathImage = "/images/tes/ist/wu/petunjuk";

  return (
    <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
      <SoalImage src={`${pathImage}/${namaFile}`} alt="Contoh" />

      <PetunjukParagraph textAlign="center">
        Kubus di atas, sebenarnya adalah kubus yang sama dengan kubus yang mana
        kah dari gambar di bawah nya?
      </PetunjukParagraph>

      <PilihanWrapper col={5}>
        {arrPilihan?.map((item, index) => (
          <PilihanGambar
            label={item}
            onClick={() => setAnswer(item)}
            answer={answer}
            key={index}
            alt="Pilihan IST-WU"
            path={pathImage}
          />
        ))}
      </PilihanWrapper>

      <Benar isShow={answer === kunci} htmlString="Jawaban Anda benar!" />
      <Salah isShow={answer !== kunci && answer !== ""} />
      <Footer
        min={1}
        max={5}
        previous={previous}
        next={next}
        condition={answer === kunci}
      />
    </PetunjukMiddleContainer>
  );
};

export default PetunjukMiddle;
