import { useHistory } from "react-router";
import TopSection from "../TopSection";
import styles from "./SkorPesertaContent.module.css";

const SkorPesertaContent = () => {
  const history = useHistory();
  return (
    <div className={styles.container}>
      <TopSection />

      <div className={styles.mainSection}>
        <p className={styles.path}>
          <span className={styles.pathLink} onClick={() => history.push("/")}>
            Beranda
          </span>{" "}
          <span
            className={styles.pathLink}
            onClick={() => history.push("/project")}
          >
            / Project
          </span>{" "}
          <span className={styles.pathNow}>/ Skor Peserta</span>
        </p>

        <div className={styles.body}>Skor Peserta Body</div>
      </div>
    </div>
  );
};

export default SkorPesertaContent;
