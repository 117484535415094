import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Perhatikan contoh soal untuk memahami soal yang akan Anda kerjakan.
        </p>
        <br />
        <p>
          Dalam tes ini Anda akan menghadapi tumpukan balok-balok. Tugas Anda
          yaitu menghitung berapa jumlah balok yang menempel pada balok yang
          telah ditentukan. <br /> Definisi menempel yaitu menempel sisinya,
          bukan hanya bersinggungan sikunya.
        </p>
        <br />
        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="Berapa jumlah balok yang menempel sisi BALOK A?"
        kunci="2"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Ada dua balok yang menempel balok A, lihat gambar."
        namaFile="petunjuk_eas52.png"
      />

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="Berapa jumlah balok yang menempel sisi BALOK B?"
        kunci="3"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Ada tiga balok yang menempel balok B yaitu balok C,D dan balok E. Lihat gambar."
        namaFile="petunjuk_eas53.png"
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
