
import { connect } from "react-redux";
import BuatModulContent from "../../components/BuatModulContent";
import SideBar from "../../components/SideBar";
import styles from "../Dashboard/Dashboard.module.css";

const BuatModul = ({ isAdmin, clientId }) => {

  return (
    <div className={styles.container}>
      <SideBar />
      
      <BuatModulContent  />

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: state.user.isAdmin,
    clientId: state?.user?.user?.client?.id,
  };
};

export default connect(mapStateToProps, null)(BuatModul);
