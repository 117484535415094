import React from 'react';

const HeaderFirst = () => {
  return (
    <React.Fragment>
      <th rowSpan="3" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        NO
      </th>
      <th rowSpan="3" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        TANGGAL TES
      </th>
      <th rowSpan="3" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        KODE AKTIVASI
      </th>
      <th rowSpan="3" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        NAMA
      </th>
      <th rowSpan="3" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        USIA
      </th>
      <th rowSpan="3" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        JENIS KELAMIN
      </th>
      <th rowSpan="3" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        PENDIDIKAN
      </th>
      <th rowSpan="3" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        POSISI
      </th>
      <th rowSpan="3" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        AREA
      </th>
    </React.Fragment>
  );
};

export default HeaderFirst;
