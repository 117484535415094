import { connect } from "react-redux";
import PetunjukParagraph from "../../Components/PetunjukMiddle/PetunjukParagraph";
import PetunjukContainer from "../../Containers/PetunjukContainer";
import { mapDispatchToPropsPetunjuk } from "../../Utils/reduxMap";
import styles from "./Petunjuk.module.css";

const Petunjuk = ({ changeCurrentPetunjuk, isModal, tutupModalHandler }) => {
  const tutupHandler = () => {
    const psikotesLS = JSON.parse(localStorage.getItem("psikotesTalenta"));
    psikotesLS.currentPetunjuk = 0;
    localStorage.setItem("psikotesTalenta", JSON.stringify(psikotesLS));

    changeCurrentPetunjuk(0);
  };

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <div className={styles.petunjukLast}>
        <PetunjukParagraph>
          {`Terdapat masing-masing 2 (dua) pernyataan. <br/><br/>Pilihlah <strong> satu pernyataan yang paling mendekati gambaran diri Anda </strong>. Jika kedua pernyataan tersebut tidak mencerminkan keadaan diri, Anda diminta untuk tetap memilih salah satu pernyataan yang paling mendekati, diantara kedua pernyataan tersebut. <br/><br/>Sebaliknya jika kedua pernyataan tersebut sama-sama mencerminkan diri Anda, tetap Anda diminta memilih salah satu diantara dua pernyataan tersebut.`}
        </PetunjukParagraph>

        <div className={styles.footer}>
          {isModal ? (
            <button onClick={tutupModalHandler}>Tutup</button>
          ) : (
            <button onClick={tutupHandler}>Tutup</button>
          )}
        </div>
      </div>
    </PetunjukContainer>
  );
};

export default connect(null, mapDispatchToPropsPetunjuk)(Petunjuk);
