import styles from "./SectionOne.module.css";
import SummaryLeft from "./SummaryLeft";
import SummaryRight from "./SummaryRight";

const SectionOne = ({ project }) => {
  // console.log("ini di section one sumarry right");
  // console.log(project)
  return (
    <div className={styles.sectionOne}>
      <SummaryLeft project={project} />
      <SummaryRight peserta={project?.peserta} project={project} />
    </div>
  );
};

export default SectionOne;
