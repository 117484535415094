import styles from "./Footer.module.css";

const Footer = ({ min, max, condition, previous, next }) => (
  <div className={styles.footer}>
    {min && (
      <p>
        Silakan pilih jawaban Anda dengan mengklik {min} pilihan di antara {max}{" "}
        pilihan jawaban di atas (Catatan : Ini hanya contoh soal, jika jawaban
        Anda salah maka tidak akan mempengaruhi skor Anda)
      </p>
    )}

    <button onClick={previous}>Kembali</button>
    {condition ? (
      <button onClick={next}>Lanjutkan</button>
    ) : (
      <button className={styles.pasifButton}>Lanjutkan</button>
    )}
  </div>
);

export default Footer;
