import React, { useState } from 'react';
import styles from '../ModulContent.module.css';
import Table from 'react-bootstrap/Table';
import { API } from '../../../services/API';
import 'rodal/lib/rodal.css';
import Modal from '../../Modal';

export default function TableUrutanTes({ urutans }) {
  const [modalVisible, setModalVisible] = useState(false);
  const content =
    'Maaf, Ubah urutan tes gagal!, Silahkan priksa jaringan anda dan coba lagi';

  const closeModal = () => {
    setInterval(() => {
      setModalVisible(false);
    }, 3000);
  };
  const changeUrutan = async (e, id) => {
    try {
      await API.patch('/urutantes/edit', {
        id: id,
        urutan: e.target.value,
      });
    } catch (error) {
      setModalVisible(true);
      closeModal();
    }
  };
  return (
    <div className={styles.wrapBotEl}>
      {modalVisible && (
        <Modal
          isVisible={modalVisible}
          content={content}
          func={[() => {}, () => {}]}
        />
      )}

      <Table className={styles.tableUrutanTes}>
        <thead>
          <tr className={styles.thTableUrutanTes}>
            <th>No.</th>
            <th>Nama Tes</th>
            <th>Urutan</th>
          </tr>
        </thead>
        <tbody>
          {urutans.map((u, i) => (
            <tr className={styles.trTableUrutanTes} key={i}>
              <td>{i + 1}</td>
              <td>{u.tes.nama}</td>
              <td>
                <input
                  type="number"
                  placeholder={u.urutan}
                  onChange={(e) => changeUrutan(e, u.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
