import { faBars, faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router';
import styles from './ProjectHeader.module.css';
import ShowListDropdown from './ShowListDropdown';

const ProjectHeader = () => {
  const history = useHistory();

  return (
    <div className={styles.projectHeader}>
      <h3>Project</h3>

      <div className={styles.headerMenu}>
        <ShowListDropdown />

        <button
          className={styles.whiteButton}
          onClick={() => history.push('/skor-peserta')}
        >
          <span>
            <FontAwesomeIcon icon={faUser} />{' '}
          </span>
          <span className={styles.textButton}>Skor Peserta</span>
        </button>
        <button
          className={styles.whiteButton}
          onClick={() => history.push('/daftar-kredensial')}
        >
          <span>
            <FontAwesomeIcon icon={faBars} />{' '}
          </span>
          <span className={styles.textButton}>Daftar Kredensial</span>
        </button>

        <button
          className={styles.yellowButton}
          onClick={() => history.push('/project/tambah')}
        >
          <span>
            <FontAwesomeIcon icon={faPlus} />{' '}
          </span>
          <span className={styles.textButton}>Tambah Project</span>
        </button>
      </div>
    </div>
  );
};

export default ProjectHeader;
