import { useState } from "react";
import PetunjukLast from "../../Components/PetunjukLast";
import PetunjukParagraph from "../../Components/PetunjukMiddle/PetunjukParagraph";
import PetunjukContainer from "../../Containers/PetunjukContainer";
import PetunjukZero from "../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const naskah =
    "Suatu kode etik tentang praktek rekrutmen dan seleksi Sarjana Baru telah disetujui bersama oleh organisasi-organisasi yang mewakili Pengusaha, Mahasiswa dan Perusahaan Jasa Rekrutmen. Isi kode etik tersebut antara lain Perusahaan Jasa setuju bahwa setiap mahasiswa mempunyai hak untuk melamar ke semua perusahaan: Perusahaan-perusahaan setuju untuk memberikan brosur yang memberikan gambaran up-to date tentang perusahaan mereka sehingga para mahasiswa memperoleh gambaran yang objektif tentang organisasi dan jabatan yang mereka lamar, dan para mahasiswa setuju untuk segera membatalkan semua tawaran lain (tawaran yang menggiurkan sekalipun) setelah ia menerima tawaran dari suatu perusahaan.";

  const pernyataan = [
    '"Organisasi yang mewakili mahasiswa setuju bahwa seorang mahasiswa tidak boleh menyetujui lebih dari satu tawaran pekerjaan."',
    '"Perusahaan jasa rekrutmen berhak menolak untuk mengajukan daftar nama mahasiswa kepada para pengusaha."',
    '"Brosur-brosur yang dikeluarkan oleh perusahaan di waktu yang lalu, cenderung subjektif dan out of date."',
  ];
  const pesanBenar = [
    "<b>...dan para mahasiswa setuju untuk segera membatalkan semua tawaran lain (tawaran yang menggiurkan sekalipun) setelah ia menerima tawaran dari suatu perusahaan...</b><br/><br/> dari potongan bacaan diatas, ternyata informasi tersebut benar dan terdapat dalam bacaan. Sehingga: Bila informasi tersebut benar sesuai dengan bacaan, maka jawaban yang benar adalah: <b>A</b>",
    "<b>...Isi kode etik tersebut antara lain: Perusahaan Jasa setuju bahwa setiap mahasiswa mempunyai hak untuk melamar ke semua perusahaan: Perusahaan-perusahaan setuju untuk memberikan brosur yang memberikan gambaran up-to date tentang perusahaan mereka...</b><br/><br/>dari potongan bacaan diatas, ternyata informasi tersebut salah dan terdapat dalam bacaan<br/>Sehingga: Bila informasi tersebut salah dan terdapat dalam bacaan, maka jawaban yang benar adalah: <b>B</b>",

    "<b>...Perusahaan-perusahaan setuju untuk memberikan brosur yang memberikan gambaran up-to date tentang perusahaan mereka sehingga...</b><br/><br/>dari potongan bacaan diatas, kita tidak bisa menyimpulkan diwaktu yang lalu(perusahaan memberikan brosur yang out-of date tanpa ada informasi tambahan.<br/>Sehingga: Bila Anda tidak bisa menyimpulkan informasi tersebut benar atau salah tanpa informasi atau keterangan tambahan maka jawaban yang benar adalah: <b>C</b>",
  ];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <PetunjukParagraph>Tes ini adalah sekumpulan bacaan.</PetunjukParagraph>
        <br />
        <PetunjukParagraph>
          Anda diminta menilai setiap pernyataan berdasarkan bacaan diatasnya
          dan kemudian memilih :
        </PetunjukParagraph>

        <ul>
          <li>
            Pilih jawaban A apabila: Pernyataan tersebut benar dan informasi
            terkandung dalam bacaan.
          </li>

          <li>
            Pilih jawaban B apabila: Pernyataan tersebut salah dan informasi
            terkandung dalam bacaan.
          </li>

          <li>
            Pilih jawaban C apabila: Anda tidak bisa menilai pernyataan tersebut
            benar atau salah tanpa ada informasi lain.
          </li>
        </ul>
        <br />
        <PetunjukParagraph>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </PetunjukParagraph>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        naskah={naskah}
        pernyataan={pernyataan[0]}
        pesanBenar={pesanBenar[0]}
        kunci="A"
        next={nextHandler}
        previous={previousHandler}
      />

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        naskah={naskah}
        pernyataan={pernyataan[1]}
        pesanBenar={pesanBenar[1]}
        kunci="B"
        next={nextHandler}
        previous={previousHandler}
      />

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        naskah={naskah}
        pernyataan={pernyataan[2]}
        pesanBenar={pesanBenar[2]}
        kunci="C"
        next={nextHandler}
        previous={previousHandler}
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={4}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
