// PRIMARY TYPES / COLUMNS

export const DATA_DIRI_PESERTA = 'DATA DIRI PESERTA';
export const SELF_ASSESSMENT_STRING = 'SELF ASSESSMENT STRING';
export const SELF_ASSESSMENT_NUMBER = 'SELF ASSESSMENT NUMBER';
export const KRAEPELIN = 'KRAEPELIN';
export const TIKI = 'TIKI';
export const CFIT3B = 'CFIT3B';
export const DISC = 'DISC';
export const KOSTICK = 'KOSTICK';
export const HOLLAND = 'HOLLAND';
export const CFIT3B_RS = 'CFIT3B (RS)';
export const IQ_CFIT = 'IQ CFIT';
export const KLASIFIKASI = 'KLASIFIKASI';
export const DATA_DIRI = 'DATA DIRI';

// CUSTOM TYPES / COLUMNS
export const DATA_DIRI_EDU = 'DATA DIRI EDU';
export const KRAEPELIN_EDU = 'KRAEPELIN EDU';
export const TIKI_LPPI = 'TIKI LPPI';
