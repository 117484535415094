import { Spinner } from 'react-bootstrap';
import TopSection from '../TopSection';
import styles from './ProjectContent.module.css';
import ProjectHeader from './ProjectHeader';
import ProjectTable from './ProjectTable';

const ProjectContent = ({ projects, isLoading }) => {
  return (
    <>
      <div className={styles.container}>
        <TopSection page="project" />

        <div className={styles.mainSection}>
          <div className={styles.project}>
            <ProjectHeader />
            {isLoading ? (
              <Spinner animation="border" variant="info" className="mt-5" />
            ) : (
              <ProjectTable projects={projects} />
            )}
          </div>
        </div>
      </div>
      <div className={styles.background}></div>
    </>
  );
};

export default ProjectContent;
