import { faCoins, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import CreditModal from "../../../../CreditModal";
import GlobalFilter from "./GlobalFilter";
import styles from "./TableHeader.module.css";

const TableHeader = ({
  preGlobalFilteredRows,
  globalFilterState,
  setGlobalFilter,
}) => {
  const [isShowModal, setIsShowModal] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.actionWrapper}>
        <button className={styles.buttonExcel}>
          <span>
            <FontAwesomeIcon icon={faFileExcel} />
          </span>
          Riwayat Penambahan Kuota. xls
        </button>

        <button
          className={styles.buttonTopup}
          onClick={() => setIsShowModal(true)}
        >
          <span>
            <FontAwesomeIcon icon={faCoins} />
          </span>
          Request Top Up
        </button>

        <CreditModal
          isShow={isShowModal}
          onHide={() => setIsShowModal(false)}
        />
      </div>

      <div>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilterState}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
    </div>
  );
};

export default TableHeader;
