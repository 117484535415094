import Data from "./Data";
import styles from "./Left.module.css";

const Left = () => {
  return (
    <div className={styles.left}>
      <Data title="Berhitung Angka" value={4} min={0} max={10} />
      <Data title="Reasoning" value={10} min={0} max={19} />
      <Data title="Gabungan Bagian" value={18} min={0} max={20} />
    </div>
  );
};

export default Left;
