import Footer from "../../../../Components/PetunjukMiddle/Footer";
import PetunjukMiddleContainer from "../../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import PetunjukParagraph from "../../../../Components/PetunjukMiddle/PetunjukParagraph";
import SoalImage from "../../../../Components/SoalImage";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  namaFile,
  next,
  previous,
  soal,
}) => (
  <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
    <br />
    <SoalImage src={`/images/tes/istr/istr8/${namaFile}`} alt="Contoh" />
    <PetunjukParagraph textAlign="center">{soal}</PetunjukParagraph>
    <Footer previous={previous} next={next} condition={true} />
  </PetunjukMiddleContainer>
);

export default PetunjukMiddle;
