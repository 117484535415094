import { useState } from "react";
import Benar from "../../../../Components/PetunjukMiddle/Benar";
import PetunjukMiddleContainer from "../../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import styles from "./PetunjukMiddle.module.css";
import PetunjukParagraph from "../../../../Components/PetunjukMiddle/PetunjukParagraph";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  soal,
  kunci,
  next,
  previous,
  pesanBenar,
  isModal,
}) => {
  const [answer, setAnswer] = useState("");
  const [statusJawaban, setStatusJawaban] = useState(false);
  const [salah, setSalah] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();

    setStatusJawaban(false);
    setSalah("Jawaban anda salah, silahkan coba jawab lagi");
    kunci.forEach((item) => {
      if (item === answer.toLowerCase()) {
        setStatusJawaban(true);
        setSalah("");
      }
    });
    setTimeout(() => {
      setSalah("");
    }, 3000);
  };

  return (
    <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
      <p className={styles.title}>Contoh</p>
      <PetunjukParagraph>{soal}</PetunjukParagraph>
      {!isModal ? (
        <form className={styles.inputWrapper} onSubmit={submitHandler}>
          <input
            type="text"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            placeholder="Ketikkan jawaban di sini"
            required
          />
          <button type="submit">Jawab</button>
        </form>
      ) : (
        <form className={styles.inputWrapper}>
          <input type="text" value={kunci[0]} readOnly />
        </form>
      )}

      <Benar isShow={statusJawaban || isModal} htmlString={pesanBenar} />

      <p className={styles.salah}>{salah}</p>

      <div className={styles.footer}>
        {!isModal && (
          <p className={styles.salah}>
            Jangan ditutup terlebih dulu petunjuk ini, Baca dan pahami
            instruksi. Apabila petunjuk ini ditutup maka tes dimulai dan waktu
            berjalan.
          </p>
        )}
        <button onClick={previous}>Kembali</button>
        {statusJawaban || isModal ? (
          <button onClick={next}>Lanjutkan</button>
        ) : (
          <button className={styles.pasifButton}>Lanjutkan</button>
        )}
      </div>
    </PetunjukMiddleContainer>
  );
};

export default PetunjukMiddle;
