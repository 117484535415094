import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";
import PetunjukContainer from "../../../Containers/PetunjukContainer";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihan = [
    ["Pisau", "Mentega", "Koran", "Roti", "Cerutu", "Gelang Tangan"],
    ["Rumput", "Gandum hitam", "Kue", "Tepung", "Gandum", "Pohon"],
  ];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Anda akan menghadapi enam kata. Anda harus mencari dua kata yang
          mempunyai persamaan golongan.
        </p>
        <br />
        <p>
          Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum mengerjakan.
        </p>
        <br />
        <p style={{ color: "red" }}>
          Jangan ditutup dulu petunjuk ini, perhatikan contoh, Apabila petunjuk
          ini ditutup maka tes dimulai dan waktu berjalan.
        </p>

        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        arrPilihan={arrPilihan[0]}
        kunci={10}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Mentega dan Roti adalah jawaban yang benar, karena Mentega  dan Roti mempunyai persamaan golongan, yaitu <strong>Bahan Makanan</strong>.<br/>Oleh karena itu Anda menjawab dengan memilih <strong>Roti</strong> dan <strong>Mentega</strong> agar jawaban benar."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        arrPilihan={arrPilihan[1]}
        kunci={18}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Pada contoh ini jawabannya adalah <em>Gandum hitam</em> dan <em>Gandum</em>, karena keduanya mempunyai persamaan golongan, yaitu <strong>Jenis Gandum</strong>."
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
