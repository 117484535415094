import { useState } from "react";
import PetunjukLast from "../../../../Components/PetunjukLast";
import PetunjukContainer from "../../../../Containers/PetunjukContainer";
import PetunjukZero from "../../Components/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihan = [
    [
      "contoh-cfit3b-2-1a.png",
      "contoh-cfit3b-2-1b.png",
      "contoh-cfit3b-2-1c.png",
      "contoh-cfit3b-2-1d.png",
      "contoh-cfit3b-2-1e.png",
    ],
    [
      "contoh-cfit3b-2-2a.png",
      "contoh-cfit3b-2-2b.png",
      "contoh-cfit3b-2-2c.png",
      "contoh-cfit3b-2-2d.png",
      "contoh-cfit3b-2-2e.png",
    ],
  ];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero next={nextHandler} selectedIndex={index} />

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        kunci={10}
        arrPilihan={arrPilihan[0]}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar='Dari contoh diatas, jawaban yang TEPAT adalah "b" dan "d", karena pada kotak b dan d hanya memiliki 1 lingkaran, berbeda dibandingkan 3 kotak lainnya.'
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        kunci={20}
        arrPilihan={arrPilihan[1]}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban yang paling TEPAT adalah c dan e, karena ketiga kotak lainnya (a,b dan d) berupa lingkaran berwarna putih, sementara c dan e berupa lingkaran yang diarsir."
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
