// import { useHistory } from "react-router";
import FormLogin from './FormLogin';
import styles from './LoginBody.module.css';

const LoginBody = () => {
  const tahunSekarang = new Date().getUTCFullYear();
  // const history = useHistory();

  return (
    <div className={styles.loginBody}>
      <div className={styles.loginBodyContent}>
        <h1>Hi, Selamat Datang!</h1>
        <h5>Login untuk masuk ke akun Anda</h5>

        <FormLogin />

        {/* <button className={styles.forgotPassword}>Lupa password?</button> */}
        {/* <p className={styles.register}>
          {`Belum punya akun? silahkan register `}
          <span onClick={() => history.push("/register")}>disini</span>
        </p> */}

        <p className={styles.copyright}>
          @2021 - {tahunSekarang} Talenta Indonesia All Right Reserved
        </p>
      </div>
    </div>
  );
};

export default LoginBody;
