import ProjectTambahContent from "../../components/ProjectTambahContent";
import SideBar from "../../components/SideBar";
import styles from "./ProjectTambahPage.module.css";

const ProjectTambahPage = () => {
  return (
    <div className={styles.container}>
      <SideBar />
      <ProjectTambahContent />
    </div>
  );
};

export default ProjectTambahPage;
