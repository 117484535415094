export default function SubColumn1SelfAssessmentNumber({ format }) {
  return (
    <>
      <th rowSpan={format.rowSpanValue}>1</th>
      <th rowSpan={format.rowSpanValue}>2</th>
      <th rowSpan={format.rowSpanValue}>3</th>
      <th rowSpan={format.rowSpanValue}>4</th>
      <th rowSpan={format.rowSpanValue}>5</th>
      <th rowSpan={format.rowSpanValue}>6</th>
    </>
  );
}
