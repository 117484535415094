import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihanOne = [
    "rumput",
    "jerami",
    "makanan",
    "hijau",
    "padang rumput",
  ];
  const arrPilihanTwo = ["hujan", "hari", "berupa", "angin", "kering"];
  const arrPilihanThree = ["rel", "landasan", "garasi", "stasiun", "peron"];
  const arrPilihanFour = ["gembira", "pikiran", "marah", "cerdas", "lamban"];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>Tes terdiri persoalan kata-kata.</p>
        <br />
        <p>
          Setiap soal terdiri dari 3 kata dan satu tanda tanya. Di bawahnya
          terdapat 5 kata pilihan. Antara kata pertama dan kata kedua terdapat
          suatu hubungan tertentu. Begitu pula antara kata ketiga dan satu kata
          diantara pilihan kelima kata yang ada di bawahnya terdapat hubungan
          yang sama. Carilah kata tersebut.
        </p>{" "}
        <br />
        <p>
          Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="Hutan : Pohon = Lapangan rumput : ?"
        arrPilihan={arrPilihanOne}
        kunci="rumput"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Hubungan antara hutan dan pohon adalah sama dengan hubungan antara
              lapangan rumput dan rumput. Oleh karena itu, jawaban yang benar
              pada contoh itu adalah rumput (a)."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="Gelap : terang = basah : ?"
        arrPilihan={arrPilihanTwo}
        kunci="kering"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Gelap dan terang merupakan kata yang berlawanan, karenanya lawan
              kata basah adalah kering dimana kering adalah jawaban yang benar."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        soal="Pesawat terbang : pelabuhan udara = kereta api : ?"
        arrPilihan={arrPilihanThree}
        kunci="stasiun"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Gelap dan terang merupakan kata yang berlawanan, karenanya lawan
              kata basah adalah kering dimana kering adalah jawaban yang benar."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={4}
        soal="Benci : perasaan = bodoh : ?"
        arrPilihan={arrPilihanFour}
        kunci="pikiran"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban yang tepat untuk contoh atas adalah <strong>pikiran.</strong>"
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={5}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
