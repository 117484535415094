import { useEffect, useState } from "react";
import { connect } from "react-redux";
import RiwayatKuotaContentAdmin from "../../../components/PerusahaanAdmin/RiwayatKuotaContent";
import RiwayatKuotaContent from "../../../components/PerusahaanClient/RiwayatKuotaContent";
import SideBar from "../../../components/SideBar";
import { API } from "../../../services/API";
import styles from "./RiwayatKuota.module.css";

const RiwayatKuota = ({ isAdmin, user }) => {
  const [riwayatKuota, setRiwayatKuota] = useState([]);

  useEffect(() => {
    const getRiwayat = async () => {
      try {
        const res = await API.get(`/credits/client/${user?.client?.id}`);

        setRiwayatKuota(res.data.credits);
      } catch (error) {
        console.log(error);
      }
    };
    const getRiwayatAdmin = async () => {
      try {
        const res = await API.get("/credits");

        setRiwayatKuota(res.data.credits);
      } catch (error) {
        console.log(error);
      }
    };

    if (!isAdmin) {
      getRiwayat();
    } else {
      getRiwayatAdmin();
    }
  }, [user, isAdmin]);

  return (
    <div className={styles.container}>
      <SideBar />

      {isAdmin ? (
        <RiwayatKuotaContentAdmin riwayatKuota={riwayatKuota} />
      ) : (
        <RiwayatKuotaContent riwayatKuota={riwayatKuota} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: state.user.isAdmin,
    user: state.user?.user,
  };
};

export default connect(mapStateToProps, null)(RiwayatKuota);
