import styles from "./SectionTen.module.css";

const SectionTen = () => {
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <h3 className={styles.title}>Most</h3>
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>Least</h3>
      </div>
      <div className={styles.card}>
        <h3 className={styles.title}>Change</h3>
      </div>
    </div>
  );
};

export default SectionTen;
