import { useEffect } from "react";
import { connect } from "react-redux";
import { generateEmptyJawabans } from "../../Utils";
import {
  mapDispatchToPropsTes,
  mapStateToPropsTes,
} from "../../Utils/reduxMap";
import Petunjuk from "./Petunjuk";
import Soal from "./Soal";

const IstrEight = ({ currentPetunjuk, currentTes, replaceJawabans }) => {
  useEffect(() => generateEmptyJawabans(replaceJawabans), [replaceJawabans]);
  return (
    <>
      {currentPetunjuk === 66 && <Petunjuk />}
      {currentPetunjuk !== 66 && currentTes.tesId === 66 && <Soal />}
    </>
  );
};

export default connect(mapStateToPropsTes, mapDispatchToPropsTes)(IstrEight);
