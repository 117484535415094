import styles from './PesertaLogin.module.css';
import * as RB from 'react-bootstrap';
import logo from '../../../assets/psikotes/login/logo.png';
import formLogo from '../../../assets/psikotes/login/form_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLock,
  faTools,
  faArrowCircleDown,
  faArrowCircleUp,
} from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { API } from '../../../services/API';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { pesertaLogin } from '../../../redux/Peserta/peserta-actions';
import { formatTanggalSekarang } from '../../../utils/tanggal';
import FileDownload from 'js-file-download';
import Modal from '../../../components/Modal';

const PesertaLogin = ({ pesertaLogin }) => {
  const history = useHistory();
  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowModalRemove, setIsShowModalRemove] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);
  const [error, setError] = useState(false);
  const [isDownloadSelesai, setIsDownloadSelesai] = useState(false);
  const [dataLS, setDataLS] = useState(null);

  const psikotesLS = JSON.parse(dataLS);
  const psikotesStatusSelesai = JSON.parse(localStorage.getItem('statusTesSelesai'));
  const psikotesSelfAssessment =
    localStorage.getItem('selfAssessmentSingkat') &&
    JSON.parse(localStorage.getItem('selfAssessmentSingkat'));
  const psikotesPhotos =
    localStorage.getItem('photos') && JSON.parse(localStorage.getItem('photos'));
  const dataDiriMap =
    localStorage.getItem('dataMappingPegawai') &&
    JSON.parse(localStorage.getItem('dataMappingPegawai'));

  const kodeAktivasi = psikotesLS?.aktivasi;
  const now = new Date();

  const fileName = `${kodeAktivasi}_${formatTanggalSekarang(now)}.talenta`;

  const dataJawaban = JSON.stringify({
    psikotesLS,
    psikotesStatusSelesai,
    psikotesSelfAssessment,
    psikotesPhotos,
    dataDiriMap,
  });

  function isObjectEmpty(obj = {}) {
    return Object.keys(obj).length === 0;
  }

  const uploadJawabanHandler = () => {
    const aElement = document.createElement('a');
    aElement.target = '_blank';
    aElement.rel = 'noreferrer';
    aElement.href = 'https://forms.gle/xED4V8sbMYqfywDb9';

    return aElement.click();
  };

  const downloadJawabanHandler = async () => {
    if (psikotesLS) {
      if (navigator.onLine === false) {
        setErrorMessage(
          <>
            Gagal download jawaban!{' '}
            <span className="text-danger">
              Silahkan nyalakan internet Anda terlebih dahulu, lalu download kembali jawaban Anda.
            </span>
          </>
        );
        return setError(true);
      }
      setIsLoading(true);
      await API.post(
        `/downloadJawaban`,
        {
          dataJawaban,
          fileName,
        },
        {
          responseType: 'blob',
        }
      )
        .then((res) => {
          setIsLoading(false);
          setIsDownloadSelesai(true);
          FileDownload(res.data, fileName);
        })
        .catch((err) => {
          console.log({ err });
          setIsDownloadSelesai(false);
          setIsLoading(false);
          setError(true);
          setErrorMessage(
            <>
              Gagal download jawaban! <span className="text-danger">Silahkan coba kembali.</span>
            </>
          );
        });
    } else {
      setError(true);
      setErrorMessage('Download laporan gagal, Anda belum pernah melakukan tes apapun!');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (code === '') {
        return setErrorMessage('Silahkan masukkan kode aktivasi Anda terlebih dahulu!');
      }
      const res = await API.post('/peserta/login', { kodeTes: code });
      // console.log(res.data);
      const resModul = await API.get(`/modul/${res.data.peserta.project.modulId}`);

      const getJawabanYangSudahAda = await API.get(`/jawabans/${res.data.peserta.id}`);
      let statusTesSelesaiPeserta = res.data.peserta.statusTesSelesai;
      let isCuttingPoint = res.data.peserta.project.isCuttingPoint;

      // console.log(resModul.data);

      // localStorage.clear();
      const checkPsikotesTalentaStorage = (code) =>
        new Promise((resolve, reject) => {
          const talentaStorage = localStorage.getItem('psikotesTalenta');
          const psikotesTalentaStorage = talentaStorage ? JSON.parse(talentaStorage) : {};

          if (isObjectEmpty(psikotesTalentaStorage)) {
            resolve({ status: 'clear' });
          } else {
            if (psikotesTalentaStorage.aktivasi === code) {
              resolve({ status: 'exist' });
            } else if (psikotesTalentaStorage.aktivasi !== code) {
              localStorage.clear('psikotesTalenta');
              resolve({ status: 'clear' });
            }
          }
        });

      const storageStatus = await checkPsikotesTalentaStorage(code);
      let psikotesLS = {};
      if (storageStatus.status === 'clear') {
        psikotesLS = {
          aktivasi: code,
          peserta: res.data.peserta,
          modul: resModul.data.data,
          currentTes: resModul.data.data.urutanTes[0],
          currentSoal:
            resModul.data.data.urutanTes[0].tes.soals[0] ||
            resModul.data.data.urutanTes[0].tes.nama,
          currentPetunjuk: resModul.data.data.urutanTes[0].tes.id,
        };

        // If buat ketika peserta logout terus localstorage ke hapus bisa balik lagi
        if (statusTesSelesaiPeserta && isCuttingPoint) {
          statusTesSelesaiPeserta = JSON.parse(statusTesSelesaiPeserta);

          psikotesLS = {
            ...psikotesLS,
            currentPetunjuk: 0,
            currentSoal:
              resModul.data.data.urutanTes[statusTesSelesaiPeserta?.length].tes.soals[0] ||
              resModul.data.data.urutanTes[statusTesSelesaiPeserta?.length].tes.nama,
            currentTes: resModul.data.data.urutanTes[statusTesSelesaiPeserta?.length],
          };
        }

        if (getJawabanYangSudahAda.data?.jawabans.length) {
          psikotesLS = {
            ...psikotesLS,
            jawabans: getJawabanYangSudahAda.data?.jawabans,
          };
        } else {
          psikotesLS = {
            ...psikotesLS,
            jawabans: [],
          };
        }
      } else {
        psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
      }

      // if (res.data.peserta.foto?.length) {
      //   localStorage.setItem('photos', res.data.peserta.foto);
      // }

      pesertaLogin(
        psikotesLS.peserta,
        psikotesLS.currentTes,
        psikotesLS.currentSoal,
        psikotesLS.currentPetunjuk,
        psikotesLS.jawabans
      );
      localStorage.setItem('psikotesTalenta', JSON.stringify(psikotesLS));
      psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));

      let psikotesStatusSelesai = {
        statusTesSelesai: (statusTesSelesaiPeserta && JSON.parse(statusTesSelesaiPeserta)) || [],
      };
      localStorage.setItem('statusTesSelesai', JSON.stringify(psikotesStatusSelesai));

      const response = await API.patch('/edit-status', {
        kode: code,
        stat: 1,
      });

      if (response) {
        // localStorage.removeItem('psikotesWaktu');
        localStorage.removeItem('photos');
        localStorage.removeItem('selfAssessmentSingkat');
        localStorage.removeItem('dataMappingPegawai');
        localStorage.removeItem('twoCodesHolland');
        localStorage.removeItem('soalHollEgatek');
        localStorage.removeItem('soalNo');
        localStorage.removeItem('internetProblem');
      }

      // console.log("LS", psikotesLS);
      history.push('/psikotes/prepare');
    } catch (error) {
      // console.log(error);
      console.clear();
      setErrorMessage(error?.response?.data?.message || 'Error');
      setTimeout(() => {
        setErrorMessage('');
      }, 7000);
    }
  };

  const removeHandler = () => {
    localStorage.clear();
    setIsShowModalRemove(false);
    setIsRemoved(true);
  };

  useEffect(() => {
    setDataLS(localStorage.getItem('psikotesTalenta'));
  });

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <section className={styles.left}>
          <h1>Hi,</h1>
          <h1>Selamat Datang!</h1>
          <p>Silahkan Masukkan Kode Tes Untuk Memulai Psikotes</p>
          <p className={styles.copyright}>Psikotes Online by</p>
          <img src={logo} alt="talenta" width={214} />
          <RB.Dropdown autoClose={isLoading === true ? false : true} className={styles.dropdown}>
            <RB.Dropdown.Toggle
              id="dropdown-basic"
              style={{ backgroundColor: '#0884c9', border: '0' }}
            >
              <FontAwesomeIcon icon={faTools} />
            </RB.Dropdown.Toggle>

            <RB.Dropdown.Menu>
              <RB.Dropdown.Item onClick={uploadJawabanHandler} disabled={isLoading}>
                <span>Upload Jawaban </span>
                <span>
                  <FontAwesomeIcon icon={faArrowCircleUp} />
                </span>
              </RB.Dropdown.Item>
              <RB.Dropdown.Item onClick={downloadJawabanHandler} disabled={isLoading}>
                <span>Download Jawaban </span>
                {isLoading ? (
                  <RB.Spinner animation="border" variant="info" size="sm" />
                ) : (
                  <span>
                    <FontAwesomeIcon icon={faArrowCircleDown} />
                  </span>
                )}
              </RB.Dropdown.Item>
              <RB.Dropdown.Item onClick={setIsShowModalRemove.bind(this, true)}>
                <span>Clear Site Data</span>
              </RB.Dropdown.Item>
            </RB.Dropdown.Menu>
          </RB.Dropdown>
        </section>
        <section className={styles.right}>
          <form onSubmit={handleSubmit}>
            <img src={formLogo} alt="talenta" width={240} />

            <section className={styles.inputGroup}>
              <span className={styles.lockIcon}>
                <FontAwesomeIcon icon={faLock} color="#0b3d9d" opacity={0.5} />
              </span>
              <input
                type="text"
                placeholder="Ketikkan Kode Tes"
                value={code}
                maxLength={50}
                onChange={(e) => setCode(e.target.value)}
              />
            </section>
            <p className={styles.error}>{errorMessage}</p>
            <button type="submit">Login</button>
          </form>
        </section>
      </div>
      {isShowModalRemove && (
        <>
          <Modal
            isVisible={isShowModalRemove}
            func={[() => {}, () => {}]}
            showClose={false}
            onCloseMask={false}
            content={
              <>
                <p>Apakah Anda yakin ingin menghapus semua data web ini?</p>
                <p onClick={setIsShowModalRemove.bind(this, false)} style={{ cursor: 'pointer' }}>
                  Tidak
                </p>
                <p onClick={removeHandler} style={{ cursor: 'pointer' }}>
                  Iya
                </p>
              </>
            }
          />
        </>
      )}
      {isRemoved && (
        <Modal
          isVisible={isRemoved}
          func={[() => {}, () => {}]}
          showClose={false}
          onCloseMask={false}
          content={
            <>
              <p>Berhasil menghapus data site website ini!</p>
              <p
                onClick={() => {
                  setIsRemoved(false);
                  window.location.reload();
                }}
                style={{ cursor: 'pointer' }}
              >
                OK
              </p>
            </>
          }
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    pesertaLogin: (peserta, currentTes, currentSoal, currentPetunjuk, jawabans) =>
      dispatch(pesertaLogin(peserta, currentTes, currentSoal, currentPetunjuk, jawabans)),
  };
};

export default connect(null, mapDispatchToProps)(PesertaLogin);
