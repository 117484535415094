import NomorSoalTitle from '../../Components/NomorSoalTitle';
import styles from './SoalContent.module.css';

const SoalContent = ({ children, withNomor = true }) => (
  <div className={styles.content}>
    {withNomor && <NomorSoalTitle />}
    {children}
  </div>
);

export default SoalContent;
