// React
import { useState } from 'react';

// Custom Components
import TopSection from 'components/TopSection';
import SideBar from 'components/SideBar';

// Third Party Component
import { useHistory } from 'react-router-dom';
import { Alert, Button, Card, Col, Form } from 'react-bootstrap';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

// Custom Component
import TableImages from './TableImages';
import BreadcrumbSection from './BreadcrumbSection';

// Store
import { createQuestion } from '../store';

// styles
import 'assets/scss/editor.scss';

const CreateAnalisaKasus = () => {
  // State
  const [errorMessage, setErrorMessage] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const convertToHtml = () => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  // Hooks
  const history = useHistory();

  // Handle Submit Form
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const question = await createQuestion({
        tesId: test.data.id,
        nomor: 1,
        soal: convertToHtml(),
        pilihan: null,
        kuncil: null,
      });

      console.log({ test, question });
    } catch (error) {
      setErrorMessage(error);
    }
  };

  return (
    <div className="d-flex flex-md-row flex-column bg-pattern">
      <SideBar />
      <div className="container py-5">
        <TopSection />
        <BreadcrumbSection />
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <TableImages />

        <Card>
          <Card.Body className="p-4">
            <Card.Title>Buat Soal Analisa Kasus Baru</Card.Title>
            <hr />
            <Form onSubmit={onSubmit} className="row">
              <Col sm="12" className="mb-3">
                <Editor editorState={editorState} onEditorStateChange={setEditorState} />
              </Col>

              <Col sm="12" className="text-end">
                <Button variant="outline-danger" className="me-3">
                  Batalkan
                </Button>
                <Button type="submit">Simpan</Button>
              </Col>
            </Form>

            <div
              dangerouslySetInnerHTML={{
                __html: convertToHtml(),
              }}
            ></div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default CreateAnalisaKasus;
