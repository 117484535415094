import styles from "./Table.module.css";
import { matchSorter } from "match-sorter";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import Pagination from "./Pagination";
import TableHeader from "./TableHeader";

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function Table({ columns, data, credentials }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination,
    useRowSelect
  );

  // Render the UI for your table
  return (
    <>
      <TableHeader
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilterState={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />

      <table {...getTableProps()} className={styles.table}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className={styles.tableHeader}
            >
              {headerGroup.headers.map((column, index) => {
                return (
                  <th
                    className={styles.thItem}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={styles.rowItem}>
                {row.cells.map((cell, index) => {
                  switch (index) {
                    case 0:
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={styles.cellItem}
                          style={{ textAlign: "center" }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    default:
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={styles.cellItem}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <Pagination
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
      />
    </>
  );
}

export default Table;
