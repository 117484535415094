export default function SubColumn1TikiLPPI({ format }) {
  return (
    <>
      <th colSpan={format.perColRows * 0} rowSpan={format.rowSpanValue}>
        IQ
      </th>
      <th colSpan={format.perColRows * 0} rowSpan={format.rowSpanValue}>
        KLASIFIKASI
      </th>
      <th colSpan={format.perColRows} rowSpan={format.rowSpanValue - 1}>
        WS
      </th>
    </>
  );
}
