import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import styles from "./Petunjuk.module.css";
import Footer from "../../../Components/PetunjukMiddle/Footer";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <div
        className={styles.petunjukZero}
        style={{ display: index === 0 ? "flex" : "none" }}
      >
        <p>
          Pada persoalan berikut, Anda akan menghadapi beberapa kasus yang
          terdiri dari memo/ dokumen kerja. Tugas anda adalah membuat respon
          atas memo/ dokumen tersebut dan membagi permasalahan-permasalahan
          tersebut ke dalam 3 kategori :
        </p>
        <ol>
          <b>
            <li>High Priority</li>
          </b>
          <b>
            <li>Moderate Priority</li>
          </b>
          <b>
            <li>Low Priority</li>
          </b>
        </ol>

        <p>
          Anda bebas untuk mengerjakan dokumen mana yang akan anda selesaikan
          terlebih dahulu. Yang terpenting berikan kategori pada setiap
          permasalahan dan tuliskan urutan permasalahan menurut masalah yang
          paling prioritas untuk diselesaikan.
        </p>

        <p>
          Tulislah respon kamu untuk setiap permasalahan tadi pada kolom
          jawaban, setelah yakin tekan <strong>Lanjut</strong> untuk mengisi
          jawabannya
        </p>

        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>

        <p>
          <font color="red">
            Jangan ditutup terlebih dulu petunjuk ini, baca dan pahami
            instruksi. Apabila petunjuk ini ditutup maka tes dimulai dan waktu
            berjalan.
          </font>
        </p>

        <div className={styles.buttonWrapper}>
          <button onClick={nextHandler}>Tutup</button>
        </div>
      </div>

      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 1 ? "flex" : "none" }}
      >
        <p>
          <b>
            &nbsp;&nbsp;&nbsp;Anda adalah Herman Pradana, seorang manajer pada
            suatu perusahaan consumer goods
          </b>
          , bernama Sukses Sejahtera. Bidang yang Anda tangani adalah mengenai
          Human Resources (lihat struktur organisasi). Anda telah menduduki
          posisi ini selama enam bulan, setelah sebelumnya selama 7 tahun
          berkarir di bidang training and development. Pada tanggal 1 September
          Anda ditugaskan untuk melakukan studi banding di Singapore mengenai
          peran manajemen pengembangan sumber daya manusia dalam transformasi
          organisasi selama dua minggu. Ketika Anda pulang ke tanah air dan
          kembali masuk ke kantor, ternyata sudah banyak tugas yang harus segera
          Anda selesaikan. Hal ini, misalnya, terlihat dari sejumlah e-mail yang
          muncul di Inbox Digital Notes Anda. Terlampir adalah e-mail-e-mail
          yang harus Anda tanggapi dan struktur organisasi perusahaan.
        </p>
        <Footer previous={previousHandler} next={nextHandler} condition />
      </div>

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={2}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
