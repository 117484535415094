import { useState } from "react";
import PetunjukLast from "../../../../Components/PetunjukLast";
import PetunjukContainer from "../../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>Perhatikan contoh untuk memahami soal yang akan Anda kerjakan.</p>
        <br />
        <p>
          Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        namaFile="contoh-cfit2a1.png"
        pesanBenar='Dari contoh di atas, gambar tiap-tiap kotak menunjukkan kotak hitam yang semakin melebar. Gambar yang TEPAT untuk mengisi kotak keempat adalah gambar "a".'
        previous={previousHandler}
        next={nextHandler}
        kunci="a"
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        namaFile="contoh-cfit2a2.png"
        pesanBenar='Pada contoh ini jawaban yang paling tepat adalah "c".'
        previous={previousHandler}
        next={nextHandler}
        kunci="c"
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        namaFile="contoh-cfit2a3.png"
        pesanBenar='Pada contoh ini area hitam berputar semakin ke bawah searah jarum jam. Jawaban yang paling tepat adalah "c".'
        previous={previousHandler}
        next={nextHandler}
        kunci="c"
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={4}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
