import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { searchProject } from '../../../redux/User/user-actions';
import styles from './SearchBar.module.css';

const SearchBar = ({ searchProjectInput, searchProject }) => {
  const [searchInput, setSearchInput] = useState('');

  const searchHandler = (e) => {
    e.preventDefault();

    searchProject(searchInput);
  };

  useEffect(() => {
    return () => {
      searchProject('');
    };
  }, [searchProject]);

  return (
    <form className={styles.searchWrapper} onSubmit={searchHandler}>
      <input
        type="text"
        placeholder="Cari Nama, Modul, Tanggal, dsb.. Dari Project"
        className={styles.searchInput}
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />
      <button className={styles.searchButton}>
        <span>
          <FontAwesomeIcon icon={faSearch} />{' '}
        </span>
        <span className={styles.textButton}>Cari</span>
      </button>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    searchProjectInput: state?.user?.searchProjectInput,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchProject: (projectName) => dispatch(searchProject(projectName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
