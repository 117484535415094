import * as RB from 'react-bootstrap';
import ConfirmModal from '../../../../ConfirmModal';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faList,
  faCheckCircle,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import Rodal from 'rodal';
import { useReducer } from 'react';
import styles from './Action.module.css';
import { useEffect } from 'react';
import { StatusComponent } from '../index';

export default function Actions({
  dataPeserta,
  onClickDeletePeserta,
  onClickEditPeserta,
}) {
  const [isShowDelete, setIsShowDelete] = useState(false);
  const [isShowEdit, setIsShowEdit] = useState(false);
  const [isShowStatusTes, setIsShowStatusTes] = useState(false);
  const [widthRodalStatusTes, setWidthStatusTes] = useState('50%');
  const [widthRodalEdit, setWidthRodalEdit] = useState('35%');
  const [heightRodalEdit, setHeightRodalEdit] = useState('100%');
  // console.log('dataPeserta:',dataPeserta)

  let daftarTes = dataPeserta.projectDetail.modul?.urutanTes;
  daftarTes =
    daftarTes &&
    daftarTes?.sort((a, b) => parseInt(a.urutan) - parseInt(b.urutan));
  let statusSelesaiTes =
    dataPeserta.statusSelesai !== null && JSON.parse(dataPeserta.statusSelesai);
  const [nama, kode] = dataPeserta.namaPeserta?.split(',,,');
  const editStatePeserta = {
    nama: nama || '-',
    kode: kode || '-',
    gender: dataPeserta.jenis_kelamin || '-',
    pendidikan: dataPeserta.tingkatPendidikan || '-',
    stat: dataPeserta.status || '-',
    cam: dataPeserta.cam || '-',
  };

  const editStatePesertaFunction = (state, action) => {
    switch (action.type) {
      case 'setNamaPeserta':
        return { ...state, nama: action.payload };
      case 'setKodePeserta':
        return { ...state, kode: action.payload };
      case 'setJenisKelaminPeserta':
        return { ...state, gender: action.payload };
      case 'setTingkatPendidikanPeserta':
        return { ...state, pendidikan: action.payload };
      case 'setStatus':
        return { ...state, stat: action.payload };
      case 'setCamPeserta':
        return { ...state, cam: action.payload };
      default:
        return action.payload;
    }
  };
  let [peserta, dispatchPeserta] = useReducer(
    editStatePesertaFunction,
    editStatePeserta
  );

  const onEditedPeserta = (e) => {
    e.preventDefault();

    let kodeBaruPesertaSekarang = peserta.kode;
    let kodeDataPesertaLama = kode;
    let dataPesertaYangAkanDiUpdate = peserta;

    // Intinya ini kalo misalkan si kode gak edit kita kirim ke backendnya kosong atau tidak kirimkan property kode sama sekali
    // karena di backend akan di cek apabila ada kode yang sama bakalan di reject
    if (kodeBaruPesertaSekarang === kodeDataPesertaLama) {
      delete peserta.kode; // hapus kode baru peserta sekarang
    }

    onClickEditPeserta(
      e,
      dataPesertaYangAkanDiUpdate,
      dataPeserta.id,
      setIsShowEdit
    );
  };

  useEffect(() => {
    dispatchPeserta({
      payload: {
        nama,
        kode,
        gender: dataPeserta.jenis_kelamin,
        pendidikan: dataPeserta.tingkatPendidikan,
        stat: dataPeserta.status,
        cam: dataPeserta.cam,
      },
    });

    let mobileScreen = window.matchMedia('(max-width: 576px)');

    if (mobileScreen.matches) {
      setWidthRodalEdit('80%');
      setHeightRodalEdit('85%');
      setWidthStatusTes('95%');
    }
  }, [dataPeserta, kode, nama]);

  const customStylesRodalStatusTesSelesai = {
    height: 'auto',
    bottom: 'auto',
    top: '5%',
    width: widthRodalStatusTes,
  };
  return (
    <>
      <RB.Dropdown>
        <RB.Dropdown.Toggle
          id="dropdown-basic"
          style={{ backgroundColor: '#0964a7' }}
        >
          <FontAwesomeIcon icon={faList} />
        </RB.Dropdown.Toggle>

        <RB.Dropdown.Menu>
          <RB.Dropdown.Item onClick={setIsShowStatusTes.bind(this, true)}>
            Status Tes Selesai
          </RB.Dropdown.Item>
          <RB.Dropdown.Item onClick={setIsShowEdit.bind(this, true)}>
            Edit
          </RB.Dropdown.Item>
          <RB.Dropdown.Item onClick={setIsShowDelete.bind(this, true)}>
            Hapus
          </RB.Dropdown.Item>
        </RB.Dropdown.Menu>
      </RB.Dropdown>

      {isShowEdit && (
        <Rodal
          visible={isShowEdit}
          animation="zoom"
          onClose={setIsShowEdit.bind(this, false)}
          // width={500}
          // height={650}
          customStyles={{ width: widthRodalEdit, height: heightRodalEdit }}
        >
          <RB.Container className="mt-3">
            <RB.Row>
              <RB.Col xs={12} className="text-center">
                <h1 className={styles.title}>Edit Peserta</h1>
              </RB.Col>
              <RB.Col>
                <RB.Form onSubmit={onEditedPeserta}>
                  <RB.Form.Group className="mb-2 mt-4" controlId="kodePeserta">
                    <RB.Form.Label>Kode</RB.Form.Label>
                    <RB.Form.Control
                      type="text"
                      placeholder="trialkode1"
                      autoComplete="off"
                      onChange={(e) =>
                        dispatchPeserta({
                          type: 'setKodePeserta',
                          payload: e.target.value,
                        })
                      }
                      value={peserta.kode}
                    />
                  </RB.Form.Group>

                  <RB.Form.Group className="mb-2" controlId="namaPeserta">
                    <RB.Form.Label>Nama</RB.Form.Label>
                    <RB.Form.Control
                      type="text"
                      placeholder="Brian"
                      autoComplete="off"
                      onChange={(e) =>
                        dispatchPeserta({
                          type: 'setNamaPeserta',
                          payload: e.target.value,
                        })
                      }
                      value={peserta.nama}
                    />
                  </RB.Form.Group>

                  <RB.Form.Group className="mb-2" controlId="jenisKelamin">
                    <RB.Form.Label>Jenis Kelamin</RB.Form.Label>
                    <RB.Form.Select
                      aria-label="gender"
                      onChange={(e) =>
                        dispatchPeserta({
                          type: 'setJenisKelaminPeserta',
                          payload: e.target.value,
                        })
                      }
                    >
                      <option defaultValue={peserta.gender} hidden>
                        {peserta.gender === 'L'
                          ? 'Laki - Laki'
                          : peserta.gender === 'P'
                          ? 'Perempuan'
                          : peserta.gender}
                      </option>
                      <option value="L">Laki - Laki</option>
                      <option value="P">Perempuan</option>
                    </RB.Form.Select>
                  </RB.Form.Group>

                  <RB.Form.Group className="mb-2" controlId="jenisKelamin">
                    <RB.Form.Label>Status</RB.Form.Label>
                    <RB.Form.Select
                      aria-label="status"
                      onChange={(e) =>
                        dispatchPeserta({
                          type: 'setStatus',
                          payload: e.target.value,
                        })
                      }
                    >
                      <option defaultValue={peserta.stat} hidden>
                        {!peserta.stat
                          ? 'Belum'
                          : peserta.stat == '1'
                          ? 'Proses'
                          : peserta.stat == '2'
                          ? 'Selesai'
                          : peserta.stat}
                      </option>
                      <option value="1">Proses</option>
                      <option value="2">Selesai</option>
                    </RB.Form.Select>
                  </RB.Form.Group>

                  <RB.Form.Group className="mb-2" controlId="tingkatPendidikan">
                    <RB.Form.Label>Tingkat Pendidikan</RB.Form.Label>
                    <RB.Form.Select
                      aria-label="pendidikan"
                      onChange={(e) =>
                        dispatchPeserta({
                          type: 'setTingkatPendidikanPeserta',
                          payload: e.target.value,
                        })
                      }
                    >
                      <option defaultValue={peserta.pendidikan} hidden>
                        {peserta.pendidikan}
                      </option>
                      <option value="SD">SD</option>
                      <option value="SMP">SMP</option>
                      <option value="SMA">SMA</option>
                      <option value="SMK">SMK</option>
                      <option value="D1">D1</option>
                      <option value="D3">D3</option>
                      <option value="D4">D4</option>
                      <option value="S1">S1</option>
                      <option value="S2">S2</option>
                      <option value="S3">S3</option>
                    </RB.Form.Select>
                  </RB.Form.Group>

                  <RB.Form.Group className="mb-2" controlId="proctoringPeserta">
                    <RB.Form.Label>Proctoring Peserta</RB.Form.Label>
                    <RB.Form.Select
                      aria-label="proctoring"
                      onChange={(e) =>
                        dispatchPeserta({
                          type: 'setCamPeserta',
                          payload: e.target.value,
                        })
                      }
                    >
                      <option defaultValue={peserta.cam} hidden>
                        {peserta.cam}
                      </option>
                      <option value="Mengikuti project">
                        Mengikuti project
                      </option>
                      <option value="Ya">Ya</option>
                      <option value="Tidak">Tidak</option>
                    </RB.Form.Select>
                  </RB.Form.Group>

                  <RB.Button variant="primary" type="submit" className="mt-2">
                    Submit
                  </RB.Button>
                </RB.Form>
              </RB.Col>
            </RB.Row>
          </RB.Container>
        </Rodal>
      )}

      {/* Modal untuk hapus peserta */}
      {isShowDelete && (
        <ConfirmModal
          onClick={onClickDeletePeserta.bind(this, dataPeserta.id)}
          content={'Apakah anda yakin ingin menghapus data peserta ini?'}
          isVisible={isShowDelete}
          setVisible={setIsShowDelete}
        />
      )}

      {/* Modal untuk menampilkan status tes selesai */}

      {isShowStatusTes && (
        <Rodal
          visible={isShowStatusTes}
          animation="zoom"
          onClose={setIsShowStatusTes.bind(this, false)}
          customStyles={customStylesRodalStatusTesSelesai}
        >
          <RB.Container className="mt-3">
            <h2>Status Tes Peserta</h2>
            <RB.Table striped bordered hover size="sm" className="mt-5">
              <thead>
                <tr>
                  <th>Urutan Tes</th>
                  <th>Nama Tes</th>
                  <th>Status Tes</th>
                </tr>
              </thead>
              <tbody>
                {daftarTes &&
                  daftarTes.map((x, i) => (
                    <tr>
                      <td>{x.urutan}</td>
                      <td>{x.tes.nama}</td>
                      {/* <td>{dataPeserta.projectDetail.modul.urutanTes[i].tesId}</td> */}
                      {statusSelesaiTes &&
                      statusSelesaiTes?.some((e) => e === x.tesId) ? (
                        <td>
                          <StatusComponent
                            title="Selesai"
                            color="#5BCF5F"
                            icon={faCheckCircle}
                          />
                        </td>
                      ) : (
                        <td>
                          <StatusComponent
                            title="Belum"
                            color="#FF6F5B"
                            icon={faClock}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </RB.Table>
          </RB.Container>
        </Rodal>
      )}
    </>
  );
}
