import { useState } from "react";
import PetunjukLast from "../../../../Components/PetunjukLast";
import PetunjukContainer from "../../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>Perhatikan contoh untuk memahami soal yang akan Anda kerjakan.</p>
        <br />
        <p>
          Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        namaFile="contoh-cfit3a9.png"
        kunci="c"
        pesanBenar='Contoh di atas, pola yang memiliki kemiripan adalah pola pada kotak "c" (sudah terdapat titik), dimana pola pada kotak "c" diberi tanda titik, maka akan berada dalam lingkaran dan didalam bidang segi empat, seperti pada pola contoh (atas).'
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        namaFile="contoh-cfit3a10.png"
        kunci="d"
        pesanBenar='Jawaban yang tepat adalah "d", karena: Pada pola "d", apabila diberi tanda titik akan mirip dengan pola contoh, yaitu titik itu akan berada di dalam segitiga tetapi diluar bidang segi empat.'
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        namaFile="contoh-cfit3a11.png"
        kunci="b"
        pesanBenar="Pada contoh ini jawaban yang tepat adalah <strong>b</strong> karena <strong>sama-sama bisa menempatkan titik di dalam segitiga dan diatas garis cekung.</strong>."
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={4}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
