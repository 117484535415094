import { useState } from 'react';
import PetunjukLast from '../../../Components/PetunjukLast';
import PetunjukContainer from '../../../Containers/PetunjukContainer';
import styles from './Petunjuk.module.css';
import Footer from '../../../Components/PetunjukMiddle/Footer';

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <div
        className={styles.petunjukZero}
        style={{ display: index === 0 ? 'flex' : 'none' }}
      >
        <p>
          Pada persoalan berikut, Anda akan menghadapi beberapa kasus yang
          terdiri dari memo/ dokumen kerja. Tugas anda adalah membuat respon
          atas memo/ dokumen tersebut dan membagi permasalahan-permasalahan
          tersebut ke dalam 3 kategori :
        </p>
        <ol>
          <b>
            <li>High Priority</li>
          </b>
          <b>
            <li>Moderate Priority</li>
          </b>
          <b>
            <li>Low Priority</li>
          </b>
        </ol>

        <p>
          Anda bebas untuk mengerjakan dokumen mana yang akan anda selesaikan
          terlebih dahulu. Yang terpenting berikan kategori pada setiap
          permasalahan dan tuliskan urutan permasalahan menurut masalah yang
          paling prioritas untuk diselesaikan.
        </p>

        <p>
          Tulislah respon kamu untuk setiap permasalahan tadi pada kolom
          jawaban, setelah yakin tekan <strong>Lanjut</strong> untuk mengisi
          jawabannya
        </p>

        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>

        <p>
          <font color="red">
            Jangan ditutup terlebih dulu petunjuk ini, baca dan pahami
            instruksi. Apabila petunjuk ini ditutup maka tes dimulai dan waktu
            berjalan.
          </font>
        </p>

        <div className={styles.buttonWrapper}>
          <button onClick={nextHandler}>Lanjut</button>
        </div>
      </div>

      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 1 ? 'flex' : 'none' }}
      >
        <h1>Info Soal</h1>
        <p>
          Anda adalah Budi Kusuma, seorang karyawan yang telah menduduki posisi
          sebagai Supervisor selama 6 bulan di sebuah organisasi swasta yang
          bergerak di bidang penyediaan jasa audit, bernama Darendra & Rekan.
        </p>
        <br />
        <p>
          Hari ini, Senin tanggal 14 Januari adalah hari pertama Anda kembali
          masuk kerja setelah selama 2 minggu mengambil cuti untuk liburan
          bersama keluarga. Di meja Anda yang terusun rapi tampak telah menumpuk
          sejumlah memo/ dokumen kerja (struktur organisasi, Notulen Rapat, dll)
          yang menunggu untuk segera direspon.
        </p>
        <p>Terdapat tiga tugas yang Anda harus lakukan :</p>
        <br />
        <ol>
          <li>
            Memberikan prioritas terhadap semua memo/dokumen kerja yang masuk,
            kedalam tiga kategori :
            <ul>
              <li>High Priority</li>
              <li>Moderate Priority</li>
              <li>Low Priority</li>
            </ul>
          </li>
          <li>
            Menuliskan secara spesifik Rencana Tindakan yang akan lakukan untuk
            merespon setiap memo/dokumen kerja.
          </li>
          <li>
            Menulis rencana kerja Program Pengembangan Staff . Detil mengenai
            tugas ini disajikan pada memo terakhir, atau memo no. 12.
          </li>
        </ol>
        <p>
          Waktu keseluruhan yang Anda perlukan untuk melakukan semua tugas
          tersebut adalah 60 menit.
        </p>
        <Footer previous={previousHandler} next={nextHandler} condition />
      </div>
      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 2 ? 'flex' : 'none' }}
      >
        <h1>Info Soal</h1>
        <img
          src="/images/tes/ib/strukturOrganisasi.png"
          alt=""
          className={styles.gambar}
        />
        &nbsp;
        <img
          src="/images/tes/ib/tanggal.png"
          alt=""
          className={styles.gambar}
        />
        <br />
        <br />
        <b>
          <p>NOTULEN Rapat Bulanan Bidang BUMN:</p>
        </b>
        <ul>
          <li>Supervsior 1 sebagai Karyawan Penegak Disiplin bulan ini</li>
          <li>
            Karyawan yang absen tanpa alasan lebih dari 3 hari agar diberi
            peringatan tertulis
          </li>
          <li>Laporan Bulanan Bidang jangan sampai terlambat lagi</li>
          <li>
            Supervisor 1 mendapat giliran untuk mempersiapkan Laporan Bulanan
            Bidang BUMN
          </li>
        </ul>
        <br />
        <b>
          <p>Laporan Absensi PT. Darendra & Rekan </p>
        </b>
        <h5>Dipersiapkan oleh departemen personalia</h5>
        <b>
          <p>Karyawan yang datang terlambat :</p>
        </b>
        <ul>
          <li> Wahyu Hidayat 1 hari </li>
          <li> Sulistyo 2 hari</li>
          <li> Lukito 3 hari</li>
          <li> Probo Wicaksono 5 hari</li>
        </ul>
        <br />
        <b>
          <p>Karyawan yang tidak hadir :</p>
        </b>
        <ul>
          <li> James Luluhima 3 hari (sakit)</li>
          <li> Rendi Hastono 4 hari (tanpa keterangan)</li>
          <li> Hadiwibowo 1 hari (sakit)</li>
        </ul>
        <br />
        <b>
          <p>Posisi Pekerjaan di Supervisor 1 </p>
        </b>
        <img src="/images/tes/ib/table.png" className={styles.gambar} alt="" />
        <p>PL : Pemeriksaan Lapangan</p>
        <Footer previous={previousHandler} next={nextHandler} condition />
      </div>

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
