import { useState } from 'react';
import { API } from '../../../../services/API';
import styles from './SelesaiModal.module.css';
import { useHistory } from 'react-router-dom';

const SelesaiModal = ({ isShow, onHide, selesaiHandler }) => {
  const [isSelesai, setIsSelesai] = useState(false);
  const psikotes_talenta = JSON.parse(localStorage.getItem('psikotesTalenta'));
  const test_id = psikotes_talenta.currentTes.tesId;
  const history = useHistory();

  const selesaiModalHandler = async () => {
    const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
    const psikotesStatusSelesai = JSON.parse(localStorage.getItem('statusTesSelesai'));

    let previousTesId = psikotesLS.modul.urutanTes[psikotesLS?.currentTes.urutan - 1]?.tesId;

    // if (psikotesStatusSelesai.statusTesSelesai.indexOf(previousTesId) === -1) {
    //   psikotesStatusSelesai.statusTesSelesai = [
    //     ...psikotesStatusSelesai.statusTesSelesai,
    //     previousTesId,
    //   ];

    //   localStorage.setItem(
    //     'statusTesSelesai',
    //     JSON.stringify(psikotesStatusSelesai)
    //   );
    // }

    if (typeof psikotesStatusSelesai.statusTesSelesai === 'number') {
      psikotesStatusSelesai.statusTesSelesai = [psikotesStatusSelesai.statusTesSelesai];
    }

    if (psikotesLS?.currentTes?.urutan >= psikotesLS?.modul?.urutanTes?.length) {
      if (!navigator.onLine) {
        if (psikotesStatusSelesai.statusTesSelesai.indexOf(previousTesId) === -1) {
          psikotesStatusSelesai.statusTesSelesai = [
            ...psikotesStatusSelesai.statusTesSelesai,
            previousTesId,
          ];

          localStorage.setItem('statusTesSelesai', JSON.stringify(psikotesStatusSelesai));
        }
        localStorage.setItem('internetProblem', true);
        return history.push('/psikotes/finish');
      }
      setIsSelesai(true);
    }
    selesaiHandler();

    if (psikotesStatusSelesai.statusTesSelesai.indexOf(previousTesId) === -1) {
      if (psikotesLS.peserta.project.isCuttingPoint) {
        await API.post('/jawabanPerTes', {
          pesertaId: psikotesLS.peserta.id,
          jawabans: psikotesLS.jawabans,
          tesId: previousTesId,
        }).catch((err) => localStorage.setItem('internetProblem', true));
      }

      psikotesStatusSelesai.statusTesSelesai = [
        ...psikotesStatusSelesai.statusTesSelesai,
        previousTesId,
      ];

      localStorage.setItem('statusTesSelesai', JSON.stringify(psikotesStatusSelesai));

      if (navigator.onLine) {
        await API.patch('/edit-status-tes-selesai', {
          kodeTes: psikotesLS.aktivasi,
          statusTesSelesai: JSON.stringify(psikotesStatusSelesai.statusTesSelesai),
        });
      }
    }
  };
  return (
    <>
      {isShow && (
        <>
          <div className={styles.container}>
            <div className={styles.wrapper}>
              <p>
                {test_id == 1 ||
                test_id == 2 ||
                test_id == 3 ||
                test_id == 5 ||
                test_id == 6 ||
                test_id == 7 ||
                test_id == 118 ||
                test_id == 119 ||
                test_id == 120 ||
                test_id == 121
                  ? 'Anda yakin sudah mengisi formulir dengan benar dan ingin mulai mengerjakan Tes?'
                  : 'Anda yakin ingin menyelesaikan tes ini dan berganti ke tes selanjutnya?'}
              </p>

              {isSelesai === false ? (
                <div className={styles.buttonWrapper}>
                  <button onClick={onHide} className={styles.cancelButton}>
                    Cancel
                  </button>
                  <button className={styles.selesaiButton} onClick={selesaiModalHandler}>
                    {test_id == 1 ||
                    test_id == 2 ||
                    test_id == 3 ||
                    test_id == 5 ||
                    test_id == 6 ||
                    test_id == 7 ||
                    test_id == 118 ||
                    test_id == 119 ||
                    test_id == 120 ||
                    test_id == 121
                      ? 'Mulai'
                      : 'Selesai'}
                  </button>
                </div>
              ) : (
                <p className={styles.mengumpulkanButton}>
                  Tes sudah selesai, sedang mengumpulkan jawaban...
                </p>
              )}
            </div>
          </div>
          <div className={styles.background} style={{ color: 'white' }} onClick={onHide} />
        </>
      )}
    </>
  );
};

export default SelesaiModal;
