import React from 'react';

const HeaderSecond = () => {
  return (
    <React.Fragment>
      <th colSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        PRESTASI KERJA
      </th>
      <th colSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        TEMPO KERJA
      </th>
      <th colSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        KETELITIAN
      </th>
      <th colSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        KONSISTENSI
      </th>
      <th colSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        KETAHANAN
      </th>
    </React.Fragment>
  );
};

export default HeaderSecond;
