import TopSection from '../TopSection';
import React, { useState, useEffect } from 'react';
import styles from './ModulContent.module.css';
import { API } from '../../services/API';
import Select from 'react-select';
import TableUrutanTes from './TabelUrutanTes';

const UbahUrutanTes = () => {
  const [options, setOptions] = useState([]);
  const [modul, setModul] = useState(0);
  // const [selectedModul, setSelectedModul] = useState([]);
  const [urutanTes, setUrutanTes] = useState([]);
  useEffect(() => {
    async function getAllModul() {
      try {
        const res = await API.get('/moduls');
        let moduls = res.data.modulAndTes[0];

        moduls = moduls?.reverse();

        moduls.forEach((m) => {
          setOptions((options) => [
            ...options,
            {
              value: m.id,
              label: m.nama,
            },
          ]);
        });
      } catch (error) {
        console.log(error);
      }
    }
    getAllModul();
  }, []);

  const handleChange = (x) => {
    setModul(x.value);
    // setSelectedModul(x.label);
  };

  useEffect(() => {
    async function getModul() {
      const tes = await API.get(`/urutantes-modulid/${modul}`);
      setUrutanTes(tes.data.data);
    }
    getModul();
  }, [modul]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.mainSection}>
          <div className={styles.modul}>
            <TopSection page="UbahUrutanTes" />
            <div className={styles.wrapTopEl}>
              <Select options={options} onChange={(e) => handleChange(e)} />
            </div>
            {urutanTes && (
              <TableUrutanTes urutans={urutanTes} modulId={modul} />
            )}
          </div>
        </div>
      </div>
      <div className={styles.background}></div>
    </>
  );
};

export default UbahUrutanTes;
