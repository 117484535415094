import React from 'react';

import { isObjectEmpty, getEmptyData } from 'utility/Utils';

const Column = ({ data }) => {
  if (isObjectEmpty(data)) return getEmptyData(3);

  return (
    <React.Fragment>
      {data.splitTreeCodes.length
        ? data.splitTreeCodes.map((t, i) => {
            return (
              <td key={i} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                {t}
              </td>
            );
          })
        : getEmptyData(3)}
    </React.Fragment>
  );
};

export default Column;
