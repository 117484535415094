import { useState } from "react";
import PetunjukLast from "../../../../Components/PetunjukLast";
import PetunjukContainer from "../../../../Containers/PetunjukContainer";
import PetunjukZero from "../../Components/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihan = [
    [
      "contoh-cfit3b-1-1a.png",
      "contoh-cfit3b-1-1b.png",
      "contoh-cfit3b-1-1c.png",
      "contoh-cfit3b-1-1d.png",
      "contoh-cfit3b-1-1e.png",
      "contoh-cfit3b-1-1f.png",
    ],
    [
      "contoh-cfit3b-1-2a.png",
      "contoh-cfit3b-1-2b.png",
      "contoh-cfit3b-1-2c.png",
      "contoh-cfit3b-1-2d.png",
      "contoh-cfit3b-1-2e.png",
      "contoh-cfit3b-1-2f.png",
    ],
    [
      "contoh-cfit3b-1-3a.png",
      "contoh-cfit3b-1-3b.png",
      "contoh-cfit3b-1-3c.png",
      "contoh-cfit3b-1-3d.png",
      "contoh-cfit3b-1-3e.png",
      "contoh-cfit3b-1-3f.png",
    ],
  ];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero next={nextHandler} selectedIndex={index} />

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        namaFile="contoh-cfit3b-1-1.png"
        arrPilihan={arrPilihan[0]}
        pesanBenar="Dari contoh di atas, gambar tiap-tiap kotak menunjukkan lingkaran yang semakin kecil ukurannya. Jawaban anda benar!"
        kunci="contoh-cfit3b-1-1c.png"
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        namaFile="contoh-cfit3b-1-2.png"
        arrPilihan={arrPilihan[1]}
        pesanBenar="Pada contoh ini jumlah garis bertambah dengan ukuran yang sama, jawaban anda benar!"
        kunci="contoh-cfit3b-1-2e.png"
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        namaFile="contoh-cfit3b-1-3.png"
        arrPilihan={arrPilihan[2]}
        pesanBenar="Pada contoh ini objek berotasi searah jarum jam, oleh karena itu,  jawaban anda benar!"
        kunci="contoh-cfit3b-1-3e.png"
        previous={previousHandler}
        next={nextHandler}
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={4}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
