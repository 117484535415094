import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { API } from "../../services/API";
import Select from "./Select";
import TopSection from "../TopSection";
import Input from "./Input";
import styles from "./RoleEditContent.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImage } from "@fortawesome/free-solid-svg-icons";

const RoleEditContent = () => {
  const history = useHistory();
  const { userId } = useParams();

  //logo
  const [file, setFile] = useState(false);
  const [fileName, setFileName] = useState(false);

  // State
  const [nama, setNama] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("select");

  //client
  const [clientId, setClientId] = useState(0);
  const [namaPerusahaan, setNamaPerusahaan] = useState("");
  const [alamatPerusahaan, setAlamatPerusahaan] = useState("");
  const [kontakPerusahaan, setKontakPerusahaan] = useState("");
  const [logoURL, setLogoURL] = useState("");

  const submitHandler = async (e) => {
    try {
      e.preventDefault();

      if (role === "select") {
        return;
      }

      if (role === "admin") {
        const data = {
          email,
          nama,
        };

        await API.patch(`/user/${userId}`, data);
      }
      if (role === "client") {
        const data = {
          email,
        };

        await API.patch(`/user/${userId}`, data);

        var bodyForm = new FormData();

        bodyForm.append("nama", namaPerusahaan);
        bodyForm.append("kontak", kontakPerusahaan);
        bodyForm.append("alamat", alamatPerusahaan);
        bodyForm.append("email", email);
        bodyForm.append("logo", file);

        await API.patch(`/client/${clientId}`, bodyForm);
      }

      history.push("/role-setting");
    } catch (error) {
      console.log(error);
    }
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    history.push("/role-setting");
  };

  useEffect(() => {
    const getData = async () => {
      try {
        let res;

        res = await API.get(`/user/${userId}`);
        const data = res.data.data;

        if (data.admin) {
          setRole("admin");
          setNama(data?.nama);
          setEmail(data?.userName);
        } else {
          setRole("client");
          setNamaPerusahaan(data?.client?.nama);
          setEmail(data?.userName);
          setAlamatPerusahaan(data?.client?.alamat);
          setKontakPerusahaan(data?.client?.kontak);
          setClientId(data?.client?.id);
          setLogoURL(data?.client?.logo);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, [userId]);

  return (
    <div className={styles.container}>
      <TopSection />

      <div className={styles.mainSection}>
        <p className={styles.path}>
          Beranda / Role Setting<span> / Edit akun</span>
        </p>

        <form className={styles.body}>
          <Select
            disabled
            state={role}
            setState={setRole}
            name="role"
            label="Role"
          >
            {role === "client" && <option value="client">Client</option>}
            {role === "admin" && <option value="admin">Admin</option>}
          </Select>

          {role === "admin" && (
            <Input
              state={nama}
              setState={setNama}
              label="Nama"
              name="nama"
              type="text"
            />
          )}
          {role !== "select" && (
            <Input
              state={email}
              name="email"
              setState={setEmail}
              label="Email"
              type="email"
            />
          )}

          {role === "client" && (
            <Input
              state={namaPerusahaan}
              setState={setNamaPerusahaan}
              label="Nama Perusahaan"
              name="namaPerusahaan"
              type="text"
            />
          )}
          {role === "client" && (
            <Input
              state={alamatPerusahaan}
              setState={setAlamatPerusahaan}
              label="Alamat Perusahaan"
              name="alamatPerusahaan"
              type="text"
            />
          )}
          {role === "client" && (
            <Input
              state={kontakPerusahaan}
              setState={setKontakPerusahaan}
              label="Kontak Perusahaan"
              name="kontakPerusahaan"
              type="text"
            />
          )}

          {role === "client" && logoURL && (
            <div className={styles.imageWrapper}>
              <label htmlFor="logo">Logo Perusahaan Saat Ini</label>
              <img src={logoURL} alt="logo perusahaan saat ini" />
            </div>
          )}

          {role === "client" && (
            <div className={styles.fileInputWrapper}>
              <label htmlFor="logo">Logo Perusahaan</label>
              <input
                type="file"
                name="logo"
                id="logo"
                onChange={(e) => {
                  setFileName(e?.target?.files[0]?.name);
                  setFile(e.target.files[0]);
                }}
                onClick={() => {
                  setFile(false);
                  setFileName(false);
                }}
              />
              <label htmlFor="logo" className={styles.labelLogo}>
                <FontAwesomeIcon icon={faFileImage} size="2x" color="#CFCFCF" />
                <p>Click to browse</p>
              </label>
              <p>{fileName}</p>
            </div>
          )}

          <div className={styles.actionWrapper}>
            <button className={styles.cancel} onClick={cancelHandler}>
              Batalkan
            </button>
            <button className={styles.simpan} onClick={submitHandler}>
              Simpan
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RoleEditContent;
