import styles from './TextAreaGroup.module.css';

const TextAreaGroup = ({ label, id, state, setState, required = true }) => {
  let psikotesTalenta = localStorage.getItem('psikotesTalenta');
  let dataMappingPegawai = localStorage.getItem('dataMappingPegawai');
  psikotesTalenta = psikotesTalenta && JSON.parse(psikotesTalenta);

  const dataMappingPegawaiTesId = 121;
  let saveData;

  if (dataMappingPegawai) {
    dataMappingPegawai = JSON.parse(dataMappingPegawai);
    saveData = dataMappingPegawai;
  }

  const changeHandler = (e) => {
    if (psikotesTalenta?.currentTes?.tesId === dataMappingPegawaiTesId) {
      saveData = { ...saveData, [id]: e.target.value };

      localStorage.setItem('dataMappingPegawai', JSON.stringify(saveData));
    }

    return setState(e.target.value);
  };
  return (
    <div className={styles.textAreaGroup}>
      <label htmlFor={id}>{label}</label>
      <br />
      <textarea
        rows="4"
        cols="50"
        maxLength="500"
        placeholder="Jawab Disini"
        type="text"
        id={id}
        autoComplete="off"
        value={state}
        onChange={changeHandler}
        required={required}
      />
    </div>
  );
};

export default TextAreaGroup;
