const hariArray = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at", 'Sabtu'];

export const ubahFormat = (date) => {
  if (!date) {
    return false;
  }

  const copyDate = new Date(date);
  if (isNaN(copyDate.getTime())) {
    return false;
  }

  const readyTanggal =
    copyDate.getDate() + '/' + (copyDate.getMonth() + 1) + '/' + copyDate.getFullYear();

  return readyTanggal;
};

export const tanggalLengkap = (date) => {
  if (!date) {
    return false;
  }

  const copyDate = new Date(date);
  if (isNaN(copyDate.getTime())) {
    return false;
  }

  const readyTanggal =
    copyDate.getDate() + '/' + (copyDate.getMonth() + 1) + '/' + copyDate.getFullYear();

  let jam = copyDate.getHours().toString();
  if (jam.length === 1) {
    jam = '0' + jam;
  }

  let menit = copyDate.getMinutes().toString();
  if (menit.length === 1) {
    menit = '0' + menit;
  }

  const hari = hariArray[copyDate.getDay()];

  return `${hari}, ${readyTanggal}, ${jam}:${menit} WIB`;
};

export const formatTanggalSekarang = (date, revert) => {
  date = new Date(date);

  if (revert) {
    return `${date.getFullYear()}-${
      date.getMonth() <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }-${date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate()}`;
  }

  return `${date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate()}-${
    date.getMonth() <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  }-${date.getFullYear()}`;
};

export const slashDate = (date) => {
  // Buat objek Date dari tanggal awal
  const tanggal = new Date(date);

  // Dapatkan komponen tanggal yang diperlukan
  const tanggalFormatted = tanggal.getDate();
  const bulanFormatted = tanggal.getMonth() + 1; // Ingat, bulan dalam objek Date dimulai dari 0
  const tahunFormatted = tanggal.getFullYear();

  // Buat string dengan format yang diinginkan
  const tanggalAkhir =
    ('0' + tanggalFormatted).slice(-2) +
    '/' +
    ('0' + bulanFormatted).slice(-2) +
    '/' +
    tahunFormatted;

  return tanggalAkhir;
};

export const birthDate = (date) => {
  // Memisahkan tanggal, bulan, dan tahun dengan metode split
  const tanggalArray = date.split('-');
  const tahun = tanggalArray[0];
  const bulan = tanggalArray[1];
  const tanggal = tanggalArray[2];

  // Menggabungkan kembali dengan format yang diinginkan
  const tanggalAkhir = tanggal + '/' + bulan + '/' + tahun;

  return tanggalAkhir; // Output: 02/02/2000
};
