import { useEffect, useState } from "react";
import { connect } from "react-redux";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukParagraph from "../../../Components/PetunjukMiddle/PetunjukParagraph";
import Waktu from "../../../Components/Waktu";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import styles from "./Petunjuk.module.css";
import PetunjukZero from "../../../Containers/PetunjukZero";
import SoalImage from "../../../Components/SoalImage";

const Petunjuk = ({ isModal, tutupModalHandler, currentTes }) => {
  const [index, setIndex] = useState(isModal ? 1 : 0);
  const [sisaWaktu, setSisaWaktu] = useState(-1);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  useEffect(() => {
    if (!isModal) {
      let psikotesWaktu = JSON.parse(localStorage.getItem("psikotesWaktu"));
      if (!psikotesWaktu) {
        psikotesWaktu = {};
      }

      psikotesWaktu.sisaWaktu = 60; // Waktu yang diberikan untuk menghafal
      localStorage.setItem("psikotesWaktu", JSON.stringify(psikotesWaktu));

      console.log(psikotesWaktu.sisaWaktu);

      setSisaWaktu(psikotesWaktu.sisaWaktu);
    }
  }, [currentTes?.tes?.waktu, isModal]);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      {index === 0 && (
        <PetunjukZero selectedIndex={index}>
          <div className={styles.waktuWrapper}>
            {sisaWaktu >= 0 && (
              <Waktu duration={sisaWaktu} onTimesup={nextHandler} />
            )}
          </div>
          <br />
          <br />
          <PetunjukParagraph>
            Anda diberikan waktu 1 menit untuk menghafal setiap pasang gambar,
            hanya dihafalkan tidak untuk ditulis atau direkam dengan peralatan
            lain.
          </PetunjukParagraph>

          <SoalImage
            src="/images/tes/istr/istr11/mem-istr11.png"
            alt="Mengingat IST2000-11"
          />

          <br />
          <br />
        </PetunjukZero>
      )}

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={1}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

const mapStateToProps = (state) => ({
  currentTes: state.peserta?.currentTes,
});

export default connect(mapStateToProps, null)(Petunjuk);
