import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import styles from './styles.module.css';
import { Button, Spinner } from 'react-bootstrap';

export default function ConfirmModal({
  isVisible,
  setVisible,
  onClick,
  content,
  customStyles = {},
  animation = 'rotate',
  onStateLoading = false,
}) {
  const onCloseModal = () => {
    setVisible(false);
  };

  return (
    <div className={styles.rodal_}>
      <Rodal
        visible={isVisible}
        onClose={onCloseModal}
        animation={animation}
        showCloseButton={true}
        customStyles={customStyles}
      >
        <div className={styles.contentModal}>{content}</div>
        <div className="d-flex justify-content-center gap-4 mt-5">
          <Button
            variant="danger"
            style={{ width: '60%' }}
            onClick={onCloseModal}
          >
            Tidak
          </Button>
          <Button
            style={{ width: '60%', backgroundColor: '#0772ae' }}
            onClick={onClick}
          >
            Ya
            {onStateLoading && (
              <>
                {' '}
                <Spinner animation="border" variant="info" size="sm" />
              </>
            )}
          </Button>
        </div>
      </Rodal>
    </div>
  );
}
