import Benar from "../../../../Components/PetunjukMiddle/Benar";
import PetunjukMiddleContainer from "../../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import PetunjukParagraph from "../../../../Components/PetunjukMiddle/PetunjukParagraph";
import Footer from "../../../../Components/PetunjukMiddle/Footer";
import { useState } from "react";
import Salah from "../../../../Components/PetunjukMiddle/Salah";
import styles from "./PetunjukMiddle.module.css";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  next,
  previous,
  pesanBenar,
  kunci,
  soal,
}) => {
  const [answerOne, setAnswerOne] = useState("0");
  const [answerTwo, setAnswerTwo] = useState("0");

  const [statusAnswer, setStatusAnswer] = useState(-1);

  const submitHandler = (e) => {
    e.preventDefault();

    if (answerOne + answerTwo === kunci) {
      setStatusAnswer(1);
    } else {
      setStatusAnswer(0);
    }
  };

  return (
    <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
      <PetunjukParagraph textAlign="center">
        Anda harus mencari perhitungan yang tepat.
      </PetunjukParagraph>

      <form className={styles.form} onSubmit={submitHandler}>
        <div>
          {soal[0]}
          <select
            value={answerOne}
            onChange={(e) => setAnswerOne(e.target.value)}
          >
            <option value="0">&nbsp;</option>
            <option value="1"> + </option>
            <option value="2"> - </option>
            <option value="3"> * </option>
            <option value="4"> / </option>
          </select>
          {soal[1]}
          <select
            value={answerTwo}
            onChange={(e) => setAnswerTwo(e.target.value)}
          >
            <option value="0">&nbsp;</option>
            <option value="1"> + </option>
            <option value="2"> - </option>
            <option value="3"> * </option>
            <option value="4"> / </option>
          </select>
          {soal[2]}
        </div>

        <div>
          <button className={styles.buttonJawab} type="submit">
            Jawab
          </button>
        </div>
      </form>

      <Benar isShow={statusAnswer === 1} htmlString={pesanBenar} />
      <Salah isShow={statusAnswer === 0} />
      <Footer
        min={2}
        max={6}
        previous={previous}
        next={next}
        condition={statusAnswer === 1}
      />
    </PetunjukMiddleContainer>
  );
};

export default PetunjukMiddle;
