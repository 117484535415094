import React from 'react';

const HeaderFirst = () => {
  return (
    <th colSpan="21" style={{ verticalAlign: 'middle' }}>
      IST
    </th>
  );
};

export default HeaderFirst;
