import styles from "./Select.module.css";

const Select = ({ state, setState, name, label, children }) => {
  return (
    <div className={styles.inputWrapper}>
      <label htmlFor={name}>{label}</label>
      <select
        name={name}
        id={name}
        onChange={(e) => setState(e.target.value)}
        value={state}
      >
        {children}
      </select>
    </div>
  );
};

export default Select;
