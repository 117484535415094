import { useState } from "react";
import PetunjukMiddleContainer from "../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import Salah from "../../../Components/PetunjukMiddle/Salah";
import Benar from "../../../Components/PetunjukMiddle/Benar";
import Footer from "../../../Components/PetunjukMiddle/Footer";
import PetunjukParagraph from "../../../Components/PetunjukMiddle/PetunjukParagraph";
import PilihanWrapper from "../../../Components/PilihanWrapper";
import PilihanText from "../../../Components/PilihanText";
import SoalImage from "../../../Components/SoalImage";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  namaFile,
  kunci,
  next,
  previous,
  pesanBenar,
}) => {
  const [answer, setAnswer] = useState("");
  const arrPilihan = ["1", "2", "3", "4", "5", "6", "7", "8"];

  return (
    <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
      <br />
      <SoalImage src={`/images/tes/apm/${namaFile}`} alt="Contoh" />
      <PetunjukParagraph>
        Terdapat bentuk yang memiliki pola teratur. Terdapat potongan dalam
        figur tersebut dan Anda diminta menentukan potongan figur yang paling
        sesuai dari 8 (delapan) pilihan figur bentuk tersebut.
      </PetunjukParagraph>

      <PilihanWrapper variant="text" col={4}>
        {arrPilihan?.map((item, index) => (
          <PilihanText
            label={item}
            onClick={() => setAnswer(item)}
            answer={answer}
            key={index}
          />
        ))}
      </PilihanWrapper>
      <Benar isShow={answer === kunci} htmlString={pesanBenar} />
      <Salah isShow={answer !== kunci && answer !== ""} />

      <Footer
        min={1}
        max={8}
        previous={previous}
        next={next}
        condition={answer === kunci}
      />
    </PetunjukMiddleContainer>
  );
};

export default PetunjukMiddle;
