import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihan = [
    ["A. 2", "B. 3", "C. 5", "D. 9", "E. Selain di atas"],
    ["A. 2", "B. 3", "C. 4", "D. 5", "E. Selain di atas"],
    ["A. 6", "B. 7", "C. 8", "D. 9", "E. Selain di atas"],
    ["A. 2", "B. 4", "C. 9", "D. 12", "E. Selain di atas"],
  ];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Perhatikan contoh soal untuk memahami soal yang akan Anda kerjakan.
        </p>
        <br />
        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        kunci="C. 5"
        next={nextHandler}
        previous={previousHandler}
        namaFile="GATB_berhitung_contoh1.PNG"
        arrPilihan={arrPilihan[0]}
        pesanBenar="Jawaban yang benar adalah 5. Karena itu Anda dapat memilih opsi C."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        kunci="E. Selain di atas"
        next={nextHandler}
        previous={previousHandler}
        namaFile="GATB_berhitung_contoh2.PNG"
        arrPilihan={arrPilihan[1]}
        pesanBenar="Jawaban yang tepat, yaitu 6, namun pada pilihan jawaban tidak terdapat angka 6.
            Dalam hal ini opsi E merupakan jawaban yang tepat, oleh karena itu Anda dapat memilih opsi E.
            Sekarang coba kerjakan contoh 3 dan 4 sebagai latihan."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        kunci="C. 8"
        next={nextHandler}
        previous={previousHandler}
        namaFile="GATB_berhitung_contoh3.PNG"
        arrPilihan={arrPilihan[2]}
        pesanBenar="Jawaban Anda benar!"
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={4}
        kunci="A. 2"
        next={nextHandler}
        previous={previousHandler}
        namaFile="GATB_berhitung_contoh4.PNG"
        arrPilihan={arrPilihan[3]}
        pesanBenar="Jawaban Anda benar!"
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={5}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
