import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihanOne = ["25", "26", "24", "23"];
  const arrPilihanTwo = ["2/4", "3/8", "1/4", "4/8"];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>Tes terdiri dari persoalan-persoalan hitungan.</p>
        <br />
        <p>
          Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum mengerjakan.
        </p>
        <br />

        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="18 + 7 = ..."
        arrPilihan={arrPilihanOne}
        kunci="25"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban anda benar, dimana <strong>18 ditambah 7</strong> adalah <strong> 25</strong>. silahkan klik tombol lanjutkan"
      />

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="... - 1/8 = 1/8"
        arrPilihan={arrPilihanTwo}
        kunci="1/4"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban anda benar, dimana <strong>1/4 dikurangi 1/8 </strong> adalah <strong> 1/8</strong>. silahkan klik tombol lanjutkan"
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
