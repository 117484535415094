import { useState } from 'react';
import PetunjukLast from '../../../Components/PetunjukLast';
import PetunjukContainer from '../../../Containers/PetunjukContainer';
import styles from './Petunjuk.module.css';
import Footer from '../../../Components/PetunjukMiddle/Footer';

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <div
        className={styles.petunjukZero}
        style={{ display: index === 0 ? 'flex' : 'none' }}
      >
        <p>
          Anda adalah Evan, seorang karyawan yang telah menduduki posisi sebagai
          Kepala Departemen Riset Produk selama 6 bulan di PT Talenta Multi
          Finance. PT Talenta Multi Finance merupakan perusahaan yang bergerak
          di bidang pembiayaan dalam industri otomotif di Indonesia, khususnya
          dalam industri kendaraan roda dua.
        </p>

        <p>
          Hari ini, Senin 5 Januari adalah hari pertama kembali masuk kerja
          setelah 3 hari mengambil cuti untuk liburan bersama keluarga. Pada
          meja kerja Anda tersusun rapi beberapa memo dan dokumen kerja yang
          menunggu untuk direspon.
        </p>
        <p>Terdapat tiga tugas yang Anda harus lakukan:</p>
        <ol>
          <li>
            Memberikan perioritas terhadap semua memo / dokumen kerja yang
            masuk, kedalam tiga kategori:
            <ul>
              <li>High Priority</li>
              <li>Moderate Priority</li>
              <li>Low Priority</li>
            </ul>
          </li>
          <li>
            Menuliskan secara spesifik Rencana Tindakan yang akan dilakukan
            untuk merespon setipa memo dan dokumen kerja.
          </li>
          <li>
            Menulis rencana kerja Program Pengembangan Staff. Detil mengenai
            tugas ini disajikan pada memo terakhir atau memo no.7
          </li>
        </ol>

        <p>
          Waktu keseluruhan yang Anda perlukan untuk melakukan semua tugas
          tersebut adalah 60 menit.
        </p>

        <div className={styles.buttonWrapper}>
          <button onClick={nextHandler}>Tutup</button>
        </div>
      </div>

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={1}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
