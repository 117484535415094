import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PetunjukModal from '../../Components/PetunjukModal';
import PilihanOrange from '../../Components/PilihanOrange';
import PilihanWrapper from '../../Components/PilihanWrapper';
import SoalHeaderAlt from '../../Components/SoalHeaderAlt';
import SoalText from '../../Components/SoalText';
import SoalContainer from '../../Containers/SoalContainer';
import SoalContent from '../../Containers/SoalContent';
import { setAnswerLS, setJawabansLS } from '../../Utils';
import {
  mapDispatchToPropsTes,
  mapStateToPropsSoal,
} from '../../Utils/reduxMap';
import Petunjuk from '../Petunjuk';

const Soal = ({ currentSoal, replaceJawabans }) => {
  const [answer, setAnswer] = useState('');
  const [isShowPetunjukModal, setIsShowPetunjukModal] = useState(false);

  useEffect(() => {
    setAnswer('');
    setAnswerLS(currentSoal, setAnswer);
  }, [currentSoal]);

  useEffect(() => {
    setJawabansLS(answer, replaceJawabans);
  }, [answer, replaceJawabans]);

  return (
    <SoalContainer>
      <SoalHeaderAlt setIsShowPetunjukModal={setIsShowPetunjukModal} />
      <SoalContent>
        <SoalText />
        <PilihanWrapper variant="text">
          {currentSoal?.pilihan?.map((option) => (
            <PilihanOrange key={option} label={option} />
          ))}
        </PilihanWrapper>
      </SoalContent>
      <PetunjukModal
        isShow={isShowPetunjukModal}
        onHide={() => setIsShowPetunjukModal(false)}
      >
        <Petunjuk
          isModal
          tutupModalHandler={() => setIsShowPetunjukModal(false)}
        />
      </PetunjukModal>
    </SoalContainer>
  );
};

export default connect(mapStateToPropsSoal, mapDispatchToPropsTes)(Soal);
