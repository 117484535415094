import styles from './Input.module.css';

const Input = ({
  state,
  setState,
  label,
  name,
  type,
  length = 30,
  required = true,
}) => {
  return (
    <div className={styles.inputWrapper}>
      <label htmlFor={name}>
        {label} {required && <span className="text-danger">*</span>}
      </label>
      <input
        type={type}
        value={state}
        onChange={(e) => setState(e.target.value)}
        id={name}
        name={name}
        maxLength={length}
        required={required}
      />
    </div>
  );
};

export default Input;
