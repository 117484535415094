import { faFileImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { API } from '../../../../services/API';
import styles from './Profile.module.css';

const Profile = ({ client }) => {
  console.log({ faFileImage });
  // console.log(client);
  const [file, setFile] = useState(false);
  const [fileName, setFileName] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [alamat, setAlamat] = useState('');
  const [kontak, setKontak] = useState('');
  const [preview, setPreview] = useState(null);

  const submitHandler = async (e) => {
    try {
      e.preventDefault();

      var bodyForm = new FormData();

      if (file.length >= 0 || file) {
        bodyForm.append('logo', file);
      }

      bodyForm.append('nama', name);
      bodyForm.append('kontak', kontak);
      bodyForm.append('alamat', alamat);
      bodyForm.append('email', email);

      const res = await API({
        method: 'PATCH',
        url: `/client/${client?.id}`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: bodyForm,
      });

      if (res.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePreviewImage = (e) => {
    if (e.target.type === 'file') {
      setFileName(e?.target?.files[0]?.name);
      setFile(e.target.files[0]);
      const imageURL = URL.createObjectURL(e.target.files[0]);
      setPreview(imageURL);
    }
  };

  useEffect(() => {
    if (client) {
      setName(client?.nama);
      setEmail(client?.email);
      setAlamat(client?.alamat);
      setKontak(client?.kontak);
    }

    return () => {
      setName('');
      setEmail('');
      setAlamat('');
      setKontak('');
    };
  }, [setAlamat, client]);

  return (
    <form className={styles.profileBody} onSubmit={submitHandler}>
      <div className={styles.leftContent}>
        <div className={styles.inputWrapper}>
          <label htmlFor="nama">Nama Perusahaan</label>
          <input
            type="text"
            placeholder="Nama Perusahaan"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className={styles.inputWrapper}>
          <label htmlFor="email">Alamat Email Perusahaan</label>
          <input
            type="text"
            placeholder="Alamat Email Perusahaan"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className={styles.inputWrapper}>
          <label htmlFor="nomor">Nomor Kontak Perusahaan</label>
          <input
            type="text"
            placeholder="Nomor Kontak Perusahaan"
            value={kontak}
            onChange={(e) => setKontak(e.target.value)}
          />
        </div>
        <div className={styles.inputWrapper}>
          <label htmlFor="alamat">Alamat Perusahaan</label>
          <input
            type="text"
            placeholder="Alamat Perusahaan"
            value={alamat}
            onChange={(e) => setAlamat(e.target.value)}
          />
        </div>
        <div className={styles.inputWrapper}>
          <label htmlFor="mandatory">Opsi Mandatory</label>
          <select name="mandatory" id="mandatory">
            <option value="none">none</option>
          </select>
        </div>
      </div>
      <div className={styles.rightContent}>
        {client?.logo && (
          <div className={styles.imageWrapper}>
            <label htmlFor="logo">Logo Perusahaan saat ini</label>
            <img src={client?.logo} alt="logo perusahaan" />
          </div>
        )}

        <div className={styles.fileInputWrapper}>
          <label htmlFor="logo">Ubah Logo Perusahaan</label>
          <input
            type="file"
            name="logo"
            id="logo"
            onChange={handleChangePreviewImage}
            onClick={() => {
              setFile(false);
              setFileName(false);
            }}
          />
          <label htmlFor="logo" className={styles.labelLogo}>
            {preview ? (
              <img src={preview} alt="file yang di pilih" height={'100%'} />
            ) : (
              <>
                <FontAwesomeIcon icon={faFileImage} size="2x" color="#CFCFCF" />
                <p>Click to browse</p>
              </>
            )}
          </label>
          <p>{fileName}</p>

          <button type="submit">Simpan</button>
        </div>
      </div>
    </form>
  );
};

export default Profile;
