import React from 'react';

// ** Components
import * as Cfit3b from './components/cfit3b';
import * as Disc from './components/disc';
import * as Holland from './components/holland';
import * as Kostick from './components/kostick';
import * as Kraepelin from './components/kraepelin';
import * as PersonalInfo from './components/personal-info';
import * as SelfAssessment from './components/self-assessment';
import * as Tiki from './components/tiki';
import * as IST from './components/ist';

const getColumn = {
  standar: {
    cfit3b: Cfit3b.DefaultColumn,
    disc: Disc.DefaultColumn,
    holland: Holland.DefaultColumn,
    kostick: Kostick.DefaultColumn,
    kraepelin: Kraepelin.DefaultColumn,
    personal_info: PersonalInfo.DefaultColumn,
    self_assessment: SelfAssessment.DefaultColumn,
    tiki: Tiki.DefaultColumn,
  },
  taf: {
    cfit3b: Cfit3b.DefaultColumn,
    disc: Disc.DefaultColumn,
    holland: Holland.DefaultColumn,
    kostick: Kostick.DefaultColumn,
    kraepelin: Kraepelin.DefaultColumn,
    personal_info: PersonalInfo.DefaultColumn,
    self_assessment: SelfAssessment.DefaultColumn,
    tiki: Tiki.TafColumn,
  },
  egatek: {
    cfit3b: Cfit3b.DefaultColumn,
    disc: Disc.DefaultColumn,
    holland: Holland.DefaultColumn,
    kostick: Kostick.DefaultColumn,
    kraepelin: Kraepelin.DefaultColumn,
    personal_info: PersonalInfo.DefaultColumn,
    self_assessment: SelfAssessment.DefaultColumn,
    tiki: Tiki.DefaultColumn,
  },
  edu: {
    cfit3b: Cfit3b.EduColumn,
    disc: Disc.DefaultColumn,
    holland: Holland.DefaultColumn,
    kostick: Kostick.DefaultColumn,
    kraepelin: Kraepelin.EduColumn,
    personal_info: PersonalInfo.EduColumn,
    self_assessment: SelfAssessment.DefaultColumn,
    tiki: Tiki.DefaultColumn,
  },
  lppi: {
    cfit3b: Cfit3b.DefaultColumn,
    disc: Disc.DefaultColumn,
    holland: Holland.DefaultColumn,
    kostick: Kostick.DefaultColumn,
    kraepelin: Kraepelin.DefaultColumn,
    personal_info: PersonalInfo.LppiColumn,
    self_assessment: SelfAssessment.LppiColumn,
    tiki: Tiki.LppiColumn,
  },
  komatsu: {
    personal_info: PersonalInfo.DefaultColumn,
    ist: IST.KomatsuColumn,
    kraepelin: Kraepelin.DefaultColumn,
    disc: Disc.DefaultColumn,
  },
};

const TableColumn = ({ type = 'standar', format = [], data = [] }) => {
  return (
    <React.Fragment>
      {data.map((talent, index) => {
        return (
          <tr key={index + 1} className="report-excel-data">
            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{index + 1}</td>
            {format.map((f) => {
              const Column = getColumn[type][f];

              if (!Column) return <React.Fragment key={f} />;

              return <Column key={f} data={talent[f]} />;
            })}
          </tr>
        );
      })}
    </React.Fragment>
  );
};

export default TableColumn;
