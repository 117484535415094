import React from 'react';

// ** Components
import * as Cfit3b from './components/cfit3b';
import * as Holland from './components/holland';
import * as Kraepelin from './components/kraepelin';
import * as Tiki from './components/tiki';
import * as IST from './components/ist';

const getHeader = {
  standar: {
    holland: Holland.DefaultHeaderThird,
    kraepelin: Kraepelin.DefaultHeaderThird,
    tiki: Tiki.DefaultHeaderThird,
  },
  taf: {
    holland: Holland.DefaultHeaderThird,
    tiki: Tiki.TafHeaderThird,
  },
  egatek: {
    holland: Holland.DefaultHeaderThird,
    tiki: Tiki.DefaultHeaderThird,
  },
  edu: {
    cfit3b: Cfit3b.EduHeaderThird,
    holland: Holland.DefaultHeaderThird,
    kraepelin: Kraepelin.EduHeaderThird,
    tiki: Tiki.DefaultHeaderThird,
  },
  lppi: {
    holland: Holland.DefaultHeaderThird,
    kraepelin: Kraepelin.DefaultHeaderThird,
    tiki: Tiki.LppiHeaderThird,
  },
  komatsu: {
    ist: IST.KomatsuHeaderThird,
    kraepelin: Kraepelin.DefaultHeaderThird,
  },
};

const HeaderThird = ({ type = 'standar', format = [] }) => {
  return (
    <tr className="th-first">
      {format.map((f) => {
        const Header = getHeader[type][f];

        if (!Header) return <React.Fragment key={f} />;

        return <Header key={f} />;
      })}
    </tr>
  );
};

export default HeaderThird;
