import { useState } from "react";
import EditorComponent from "./EditorComponent";
import styles from "./EmailPerusahaan.module.css";

const EmailPerusahaan = () => {
  const [activeMenu, setActiveMenu] = useState("header");

  return (
    <form className={styles.mainBody}>
      <div className={styles.inputSection}>
        <div className={styles.leftSection}>
          <div className={styles.inputWrapper}>
            <label htmlFor="pengirim">Nama Pengirim</label>
            <input type="text" placeholder="Recruitment PPT G" />
          </div>

          <div className={styles.inputWrapper}>
            <label htmlFor="judul">Judul Email</label>
            <input
              type="text"
              placeholder="Link Psikotes Online dari PT. Garuda Metalindo"
            />
          </div>

          <div className={styles.inputWrapper}>
            <label htmlFor="pengirim">Email Pengirim</label>
            <input type="text" placeholder="contact@online-assesment.id" />
          </div>
        </div>

        <div className={styles.rightSection}>
          <div className={styles.inputWrapper}>
            <label htmlFor="ccEmail">CC Email</label>
            <input type="text" />
          </div>

          <div className={styles.inputWrapper}>
            <label htmlFor="bccEmail">BCC Email</label>
            <input type="text" />
          </div>
        </div>
      </div>
      <div className={styles.emailMenu}>
        <div
          className={styles.menuItem}
          style={
            activeMenu === "header"
              ? { color: "#0772AE", borderBottom: "3px solid #0772AE" }
              : null
          }
          onClick={() => setActiveMenu("header")}
        >
          <p>Header Email</p>
        </div>
        <div
          className={styles.menuItem}
          style={
            activeMenu === "isi"
              ? { color: "#0772AE", borderBottom: "3px solid #0772AE" }
              : null
          }
          onClick={() => setActiveMenu("isi")}
        >
          <p>Isi Email</p>
        </div>
        <div
          className={styles.menuItem}
          style={
            activeMenu === "footer"
              ? { color: "#0772AE", borderBottom: "3px solid #0772AE" }
              : null
          }
          onClick={() => setActiveMenu("footer")}
        >
          <p>Footer Email</p>
        </div>
      </div>
      <div className={styles.editor}>
        <EditorComponent isShow={activeMenu === "header" ? null : "none"} />
        <EditorComponent isShow={activeMenu === "isi" ? null : "none"} />
        <EditorComponent isShow={activeMenu === "footer" ? null : "none"} />
      </div>
      <div className={styles.formFooter}>
        <button type="submit">Simpan</button>
      </div>
    </form>
  );
};

export default EmailPerusahaan;
