import styles from "./LoginContent.module.css";
import Jumbotron from "../Jumbotron";
import LoginBody from "./LoginBody";


const LoginContent = () => {
  return (
    <div className={styles.container}>
      <Jumbotron />
      <LoginBody />
    </div>
  );
};

export default LoginContent;
