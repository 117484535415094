import { useEffect } from 'react';
import { useState } from 'react';
import { API } from '../../../services/API';
import ErrorModal from '../../ErrorModal';
import styles from './SectionThree.module.css';
import Table from './Table';

const SectionThree = ({ peserta, projectId, projectDetail }) => {
  const [dataPeserta, setDataPeserta] = useState([]);
  const [error, setError] = useState(false);

  const columns = [
    {
      Header: 'Nama Peserta',
      accessor: 'namaPeserta',
    },
    {
      Header: 'Status Penyelesaian',
      accessor: 'status',
    },
    {
      Header: 'Tingkat Kecocokan',
      accessor: 'tingkatKecocokan',
    },
    {
      Header: 'Unduh PDF Standar',
      accessor: 'pdf1',
    },
    {
      Header: 'Unduh PDF Pilihan',
      accessor: 'pdf2',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
    },
    {
      Header: 'Id Peserta',
      accessor: 'id',
    },
  ];

  const onDeletePeserta = async (pesertaId) => {
    try {
      const response = await API.delete(`/peserta/${pesertaId}`);

      if (response.status === 200) {
        const refetchProjectData = await API.get(`/project/${projectId}`);

        setDataPeserta(refetchProjectData.data?.data?.peserta);
      }
    } catch (error) {
      setError(error?.response?.data?.message || 'Internal server error!');
    }
  };

  const onEditPeserta = async (e, editedData, pesertaId, editModal) => {
    try {
      e.preventDefault();
      const response = await API.patch(`peserta/${pesertaId}`, editedData);

      if (response.status === 200) {
        const refetchProjectData = await API.get(`/project/${projectId}`);

        setDataPeserta(refetchProjectData.data?.data?.peserta);
        editModal(false);
      }
    } catch (error) {
      setError(error?.response?.data?.message || 'Internal server error!');
    }
  };

  useEffect(() => setDataPeserta(peserta), [peserta]);

  let newData =
    dataPeserta?.map((item) => {
      return {
        id: item.id,
        namaPeserta: `${item?.nama || '-'},,,${item.kode || '-'}`,
        status: item.stat,
        tingkatKecocokan:
          item.stat === 'Belum' || item.stat === 'Proses' ? '-' : '74.23%',
        unduh: item.id,
        lihat: 'Laporan',
        usia: item.usia,
        jenis_kelamin: item.gender,
        tingkatPendidikan: item.pendidikan,
        cam: item.cam,
        statusSelesai: item.statusTesSelesai,
        projectId,
        projectDetail,
      };
    }) || [];

  // const data = [
  //   {
  //     namaPeserta: "Sabili Maulana,,,sabilimaulana@gmail.com",
  //     status: "Sudah",
  //     tingkatKecocokan: "74.36%",
  //     unduh: "Laporan",
  //     lihat: "Laporan",
  //   },
  // ];

  return (
    <>
      <div className={styles.sectionThree}>
        <div className={styles.overflowX}>
          {dataPeserta?.length ? (
            <Table
              columns={columns}
              data={newData}
              onDeletePeserta={onDeletePeserta}
              onEditPeserta={onEditPeserta}
            />
          ) : (
            <h4 className={'mt-5 text-center mb-1 ' + styles.title}>
              Belum ada peserta yang di tambahkan ke dalam project ini.
            </h4>
          )}
        </div>
      </div>
      {error && (
        <ErrorModal
          visible={error}
          message={
            <p>
              Ada masalah pada saat mengedit/menghapus data peserta. <br />
              <b>Error detail:</b> <span className="text-danger">{error}</span>
            </p>
          }
          onClose={setError.bind(this, '')}
        />
      )}
    </>
  );
};

export default SectionThree;
