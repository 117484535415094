import { useEffect } from 'react';
import { connect } from 'react-redux';
import { generateEmptyJawabans } from '../../Utils';
import {
  mapDispatchToPropsTes,
  mapStateToPropsTes,
} from '../../Utils/reduxMap';
import Petunjuk from '../Components/Petunjuk';
import Soal from './Soal';

const AKECommerceSPV = ({ currentPetunjuk, currentTes, replaceJawabans }) => {
  useEffect(() => {
    generateEmptyJawabans(replaceJawabans, 'Tuliskan analisa Anda di sini.');
  }, [replaceJawabans]);

  const menit = 50;
  return (
    <>
      {currentPetunjuk === 156 && <Petunjuk menit={menit} />}
      {currentPetunjuk !== 156 && currentTes.tesId === 156 && <Soal />}
    </>
  );
};

export default connect(
  mapStateToPropsTes,
  mapDispatchToPropsTes
)(AKECommerceSPV);
