import { useEffect } from "react";
import { connect } from "react-redux";
import { generateEmptyJawabans } from "../Utils";
import { mapDispatchToPropsTes, mapStateToPropsTes } from "../Utils/reduxMap";
import Petunjuk from "./Petunjuk";
import Soal from "./Soal";

const AbstraksiTwentyFour = ({
  currentPetunjuk,
  currentTes,
  replaceJawabans,
}) => {
  useEffect(() => generateEmptyJawabans(replaceJawabans), [replaceJawabans]);
  return (
    <>
      {currentPetunjuk === 44 && <Petunjuk />}
      {currentPetunjuk !== 44 && currentTes.tesId === 44 && <Soal />}
    </>
  );
};

export default connect(
  mapStateToPropsTes,
  mapDispatchToPropsTes
)(AbstraksiTwentyFour);
