import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useAsyncDebounce } from "react-table";

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  // const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span style={{ position: "relative" }}>
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="Cari"
        style={{
          fontSize: "1.1rem",
          border: "0.953099px solid #D1D1D1",
          boxSizing: "border-box",
          borderRadius: "8.57789px",
          width: "437.98px",
          height: "62.91px",
          paddingLeft: "20px",
        }}
      />
      <div style={{ position: "absolute", right: "20px", top: "-8px" }}>
        <FontAwesomeIcon icon={faSearch} color="#0963A7" size="2x" />
      </div>
    </span>
  );
};

export default GlobalFilter;
