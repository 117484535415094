import { connect } from "react-redux";
import { useEffect, useState } from "react";
import SoalContainer from "../../../Containers/SoalContainer";
import SoalContent from "../../../Containers/SoalContent";
import SoalHeader from "../../../Components/SoalHeader";
import PetunjukModal from "../../../Components/PetunjukModal";
import Petunjuk from "../Petunjuk";
import ButtonSoal from "../../../Components/ButtonSoal";
import Navigation from "../../../Components/Navigation";
import { setAnswerLS, setJawabansLS, setSisaWaktuLS } from "../../../Utils";
import {
  mapDispatchToPropsSoal,
  mapStateToPropsSoal,
} from "../../../Utils/reduxMap";
import SoalText from "../../../Components/SoalText";
import InputWrapper from "../../../Components/InputWrapper";

const Soal = ({ currentSoal, currentTes, replaceJawabans }) => {
  const [answer, setAnswer] = useState("");
  const [sisaWaktu, setSisaWaktu] = useState(-1);
  const [isShowSelesaiModal, setIsShowSelesaiModal] = useState(false);
  const [isShowPetunjukModal, setIsShowPetunjukModal] = useState(false);

  useEffect(() => {
    setSisaWaktuLS(currentTes?.tes?.waktu, setSisaWaktu);
  }, [currentTes?.tes?.waktu]);

  useEffect(() => {
    setAnswer("");
    setAnswerLS(currentSoal, setAnswer);
  }, [currentSoal]);

  useEffect(() => {
    setJawabansLS(answer, replaceJawabans);
  }, [answer, replaceJawabans]);

  return (
    <SoalContainer
      isShowSelesaiModal={isShowSelesaiModal}
      setIsShowSelesaiModal={setIsShowSelesaiModal}
    >
      <SoalHeader
        setIsShowPetunjukModal={setIsShowPetunjukModal}
        sisaWaktu={sisaWaktu}
      />
      <SoalContent>
        <SoalText textAlign="left" fontSize="24px" lineHeight="normal" />

        <InputWrapper>
          <textarea
            type="text"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            placeholder="Ketikkan jawaban di sini"
          />
        </InputWrapper>

        <ButtonSoal setIsShowSelesaiModal={setIsShowSelesaiModal} />
        <Navigation />
      </SoalContent>
      <PetunjukModal
        isShow={isShowPetunjukModal}
        onHide={() => setIsShowPetunjukModal(false)}
      >
        <Petunjuk
          isModal
          tutupModalHandler={() => setIsShowPetunjukModal(false)}
        />
      </PetunjukModal>
    </SoalContainer>
  );
};

export default connect(mapStateToPropsSoal, mapDispatchToPropsSoal)(Soal);
