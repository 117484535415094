import { API } from '../../../services/API';

export const kumpulkanJawabanToAPI = async () => {
  const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));

  const data = {
    jawabans: psikotesLS?.jawabans,
    pesertaId: psikotesLS?.peserta?.id,
    modulId: psikotesLS?.modul?.id,
    isCuttingPoint: psikotesLS?.peserta?.project?.isCuttingPoint,
  };

  try {
    console.log('mengumpulkan jawaban ke api...');

    // console.log(JSON.stringify(data));
    await API.post('/jawabans', data).catch((err) =>
      localStorage.setItem('internetProblem', true)
    );

    console.log('jawaban berhasil dikumpulkan');

    // Dibawah ini sebelum di hapus localStoragenya
    // kalo mau di backup dulu datanya sebelum dihapus, juga bisa
    // ... tambahkan codenya di sini

    // Membersihkan localStorage
    // localStorage.clear();
  } catch (error) {
    console.log('mengumpulkan jawaban ke api gagal');
    console.log(error);
  }
};
