import { ubahFormat } from "../../../utils/tanggal";
import Table from "./Table";
import styles from "./SectionOne.module.css";

const SectionOne = ({ peserta }) => {
  
  const columns = [
    {
      Header: "No.",
      accessor: "nomor",
    },
    {
      Header: "Nama Project",
      accessor: "namaProject",
    },
    {
      Header: "Tanggal Tes",
      accessor: "tanggalTes",
    },
    {
      Header: "Status Penyelesaian",
      accessor: "statusPenyelesaian",
    },
    {
      Header: "Detail Log Peserta",
      accessor: "detailLogPeserta",
    },
  ];

  const data = [
    {
      nomor: 1,
      projectId: peserta?.projectId,
      namaProject: peserta?.project?.nama,
      tanggalTes: ubahFormat(peserta?.tgl_mulai_mengerjakan),
      statusPenyelesaian: peserta?.stat,
      detailLogPeserta: "Detail",
    },
  ];

  return (
    <div className={styles.container}>
      <Table columns={columns} data={data} />
    </div>
  );
};

export default SectionOne;
