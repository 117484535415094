import React from 'react';

const Column = ({ data }) => {
  return (
    <React.Fragment>
      {Object.entries(data).map(([key, value]) => {
        return (
          <td key={key} style={{ verticalAlign: 'middle', textAlign: value ? 'left' : 'center' }}>
            {value || '-'}
          </td>
        );
      })}
    </React.Fragment>
  );
};

export default Column;
