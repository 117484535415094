import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>Ditentukan dua kata.</p>
        <br />
        <p>Carilah satu perkataan yang meliputi pengertian kedua kata tadi.</p>
        <br />
        <p>
          Tulislah perkataan itu tadi pada kolom jawaban, setelah yakin tekan{" "}
          <strong>enter</strong> untuk mengisi jawabannya
        </p>
        <br />
        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>
        <br />
        <p></p>
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="Ayam - Itik"
        kunci={["burung", "unggas"]}
        next={nextHandler}
        previous={previousHandler}
        isModal={isModal}
        pesanBenar="Jawaban Anda benar! Jawaban isian yang tepat adalah <strong>Burung/Unggas</strong>. Kata tersebut dapat meliputi dua kata dari pertanyaan yang ada."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="Gaun : Celana"
        kunci={["pakaian", "baju"]}
        next={nextHandler}
        previous={previousHandler}
        isModal={isModal}
        pesanBenar="Jawaban Anda benar! Isi jawaban adalah <strong>Pakaian/Baju</strong> karena jawaban tersebut dapat meliputi dua pengertian kata dari pertanyaan."
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
