import { useState } from "react";
import PetunjukLast from "../../Components/PetunjukLast";
import PetunjukMiddleContainer from "../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import PetunjukParagraph from "../../Components/PetunjukMiddle/PetunjukParagraph";
import PetunjukContainer from "../../Containers/PetunjukContainer";
import PetunjukZero from "../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";
import styles from "./Petunjuk.module.css";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihan = [
    [
      "Saya terlalu tekun menjalankan tugas yang diberikan atasan kepada saya.",
      "Saya agak pemalas.",
      "Sebagai orang yang ingin berprestasi, saya sering belajar tentang hal-hal baru",
      "saya termasuk seorang pekerja keras.",
      "Bila harus memilih, saya akan lembur kerja daripada meneruskannya keesokan hari",
    ],
  ];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Terdapat beberapa pernyataan, di mana Anda diminta memberikan bobot
          penilaian terhadap pernyataan yang sesuai dengan keadaan diri Anda.
        </p>
        <br />
        <p>
          Anda memiliki nilai bobot 10 pada tiap lembar pernyataan untuk
          diberikan kepada tiap-tiap pernyataan tersebut.
        </p>
        <br />
        <p>
          Semakin tinggi nilai bobot pada tiap pernyataan yang diberikan berarti
          semakin sesuai dengan keadaan diri Anda.
        </p>
        <br />
        <p>Perhatikan contoh pada halaman selanjutnya</p>
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        arrPilihan={arrPilihan[0]}
        next={nextHandler}
        previous={previousHandler}
      />

      <PetunjukMiddleContainer isShow={index === 2}>
        <br />
        <br />
        <br />
        <PetunjukParagraph>
          Anda sudah paham dengan cara mengisinya. Yang perlu diperhatikan,
          sekali lagi adalah:
        </PetunjukParagraph>
        <PetunjukParagraph>
          Apabila dari berbagai pernyataan tersebut semua tidak sesuai dengan
          keadaan diri Anda, maka Anda tetap diminta untuk memilih dan
          memberikan bobot nilai terhadap pernyataan yang paling mendekati di
          antara pernyataan-pernyataan yang ada.
        </PetunjukParagraph>
        <PetunjukParagraph>
          Kerjakan lembar demi lembar dengan teliti
        </PetunjukParagraph>
        <br />
        <br />
        <br />
        <div className={styles.footer}>
          <button onClick={previousHandler}>Kembali</button>
          <button onClick={nextHandler}>Lanjutkan</button>
        </div>
      </PetunjukMiddleContainer>

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
