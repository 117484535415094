import React, { useState } from 'react';

// ** RB Component
import { Button, Card, Col, Row } from 'react-bootstrap';

// ** Thirdparty
import DataTable from 'react-data-table-component';

// ** Columns
import { columns } from './columns';

// ** Custom Components
import ModalAdd from './ModalAdd';

const store = [
  {
    id: 1,
    nama: 'Analisa Kasus Percobaan',
    waktu: 3600,
  },
];

const TableSection = () => {
  const [showModalAdd, setShowModalAdd] = useState(false);
  const toggleModalAdd = () => setShowModalAdd(!showModalAdd);

  return (
    <Card className="shadow">
      <Card.Body>
        <Card.Title>List Analisa Kasus</Card.Title>
        <DataTable
          subHeader
          pagination
          data={store}
          columns={columns}
          subHeaderComponent={
            <div className="w-100 me-1 ms-50 my-3">
              <Row>
                <Col sm="6"></Col>
                <Col sm="6">
                  <div className="d-flex justify-content-end">
                    <Button variant="primary" className="shadow" onClick={toggleModalAdd}>
                      Tambah Analisa Kasus
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          }
        />
      </Card.Body>

      <ModalAdd show={showModalAdd} onHide={toggleModalAdd} centered />
    </Card>
  );
};

export default TableSection;
