import styles from "./Benar.module.css";

const Benar = ({ isShow, htmlString, textAlign = "center" }) => {
  return (
    <>
      {isShow && (
        <p
          className={styles.benar}
          dangerouslySetInnerHTML={{ __html: htmlString }}
          style={{ textAlign }}
        />
      )}
    </>
  );
};

export default Benar;
