import styles from "./PetunjukZero.module.css";

const PetunjukZero = ({ selectedIndex, children }) => {
  return (
    <div
      className={styles.petunjukZero}
      style={{ display: selectedIndex === 0 ? "flex" : "none" }}
    >
      {children}
    </div>
  );
};

export default PetunjukZero;
