// ** React
import { lazy, useEffect, Suspense } from 'react';

// ** Router & Redux
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { userLogin } from './redux/User/user-actions';
import { connect } from 'react-redux';

// ** Components
import PrivateRoute from './components/PrivateRoute';

// ** views
import LoginPage from './pages/Login';

// import Dashboard from './pages/Dashboard';
import Project from './pages/Project';
import Manajemen from './pages/Manajemen';
import Konfigurasi from './pages/Perusahaan/Konfigurasi';
import StandarNilaiAspek from './pages/Perusahaan/StandarNilaiAspek';
import UnggahTestPeserta from './pages/Perusahaan/UnggahTestPeserta';
import ManajemenPengguna from './pages/Perusahaan/ManajemenPengguna';
import ManajemenPeran from './pages/Perusahaan/ManajemenPeran';
import RiwayatKuota from './pages/Perusahaan/RiwayatKuota';
import DetailLogAdmin from './pages/Perusahaan/DetailLogAdmin';
import ProjectDetailPage from './pages/ProjectDetailPage';
import ProjectEditPage from './pages/ProjectEditPage';
import ProjectTambahPage from './pages/ProjectTambahPage';
import RoleSettingPage from './pages/RoleSettingPage';
import RoleTambahPage from './pages/RoleTambahPage';
import RoleEditPage from './pages/RoleEditPage';
import RiwayatProjectPeserta from './pages/RiwayatProjectPeserta';
import DaftarKredensial from './pages/DaftarKredensial';
import SkorPeserta from './pages/SkorPeserta';
import LaporanPeserta from './pages/LaporanPeserta';
import PesertaLogin from './pages/Peserta/PesertaLogin';
import Mengerjakan from './pages/Peserta/Mengerjakan';
import PesertaPrivateRoute from './pages/Peserta/PesertaPrivateRoute';
import Prepare from './pages/Peserta/Prepare';
import Selesai from './pages/Peserta/Selesai';
import AssignPeserta from './pages/AssignPeserta';
import UbahUrutanTesPage from './pages/UbahUrutanTesPage';
import DaftarModul from './pages/DaftarModul';
import BuatModul from './pages/BuatModul';
// import PdfDownload from './pages/PdfDownload';
import RekapExcel from './pages/RekapExcel';
import NotFoundPages from './pages/NotFoundPages';
import { Spinner } from 'react-bootstrap';
import Testing from './pages/Testing';
import ReportExcel from './pages/ReportExcel/index.jsx';
import CreateAnalisaKasus from './pages/AnalisaKasus/Create';
import DaftarAnalisaKasus from './pages/AnalisaKasus/List';

// ** PrivateRoute Dian
import MyPrivateRoute from './components/routes/PrivateRoute';

function App({ userLogin, isAdmin }) {
  // const Mengerjakan = lazy(() => import('./pages/Peserta/Mengerjakan'));
  const Dashboard = lazy(() => import('./pages/Dashboard'));

  useEffect(() => {
    const token = localStorage.getItem('talentaToken');
    if (token) {
      userLogin(token);
    }

    localStorage.setItem(
      'JANGAN DI HACK!',
      'Hey Anda yang sedang melihat data localStorage ini, jangan mencoba untuk memodifikasi data ini atau merubahnya kecuali dari Tim PT. Talenta Indonesia Raya. Apabila di rubah Anda akan ada jaminan untuk tidak lulus!'
    );
  }, []);

  return (
    <Router>
      <Suspense
        fallback={
          <div>
            Loading... <Spinner animation="border" variant="info" />
          </div>
        }
      >
        <Switch>
          <div className="App">
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/" component={PesertaLogin} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/project" component={Project} />
            <PrivateRoute exact path="/manajemen-peserta" component={Manajemen} />
            <PrivateRoute
              exact
              path="/manajemen-peserta/riwayat-project/:id"
              component={RiwayatProjectPeserta}
            />
            {isAdmin && <PrivateRoute exact path="/role-setting" component={RoleSettingPage} />}
            {isAdmin && (
              <PrivateRoute exact path="/role-setting/tambah" component={RoleTambahPage} />
            )}
            {isAdmin && (
              <PrivateRoute exact path="/role-setting/edit/:userId" component={RoleEditPage} />
            )}

            <PrivateRoute exact path="/perusahaan/konfigurasi/profile" component={Konfigurasi} />
            {/* <PrivateRoute
            exact
            path="/laporan/:pesertaId"
            component={PdfDownload}
          /> */}
            <PrivateRoute
              exact
              path="/perusahaan/konfigurasi/kriteria-psikograph"
              component={Konfigurasi}
            />
            <PrivateRoute
              exact
              path="/perusahaan/konfigurasi/email-perusahaan"
              component={Konfigurasi}
            />

            <PrivateRoute
              exact
              path="/perusahaan/standar-nilai-aspek/psikograph"
              component={StandarNilaiAspek}
            />
            <PrivateRoute
              exact
              path="/perusahaan/standar-nilai-aspek/nilai"
              component={StandarNilaiAspek}
            />

            <PrivateRoute
              exact
              path="/perusahaan/unggah-test-peserta"
              component={UnggahTestPeserta}
            />
            <PrivateRoute
              exact
              path="/perusahaan/manajemen-pengguna"
              component={ManajemenPengguna}
            />
            <PrivateRoute exact path="/perusahaan/manajemen-peran" component={ManajemenPeran} />
            <PrivateRoute exact path="/perusahaan/riwayat-kuota" component={RiwayatKuota} />
            <PrivateRoute exact path="/perusahaan/detail-log-admin" component={DetailLogAdmin} />

            <PrivateRoute exact path="/project/detail/:id" component={ProjectDetailPage} />
            <PrivateRoute exact path="/project/edit/:id" component={ProjectEditPage} />
            <PrivateRoute exact path="/project/tambah" component={ProjectTambahPage} />
            <PrivateRoute exact path="/project/add-peserta/:idProject" component={AssignPeserta} />
            {isAdmin && (
              <>
                <PrivateRoute exact path="/moduls" component={DaftarModul} />
                <PrivateRoute exact path="/moduls/add" component={BuatModul} />
                <PrivateRoute exact path="/moduls/update" component={UbahUrutanTesPage} />
              </>
            )}

            <PrivateRoute exact path="/rekap-excel" component={RekapExcel} />
            {/* <Route exact path="/register" component={RegisterPage} /> */}

            <PrivateRoute exact path="/daftar-kredensial" component={DaftarKredensial} />
            <PrivateRoute
              exact
              path="/project/detail/:projectId/daftar-kredensial"
              component={DaftarKredensial}
            />
            <PrivateRoute exact path="/skor-peserta" component={SkorPeserta} />
            <PrivateRoute exact path="/laporan-peserta" component={LaporanPeserta} />

            {/* Dian Erdiana 07/06/2023 08:52 */}
            <PrivateRoute
              exact
              path="/analisa-kasus/create/:tes_id"
              component={CreateAnalisaKasus}
            />
            <PrivateRoute exact path="/analisa-kasus" component={DaftarAnalisaKasus} />

            {/* Peserta Routes */}
            <Route exact path="/psikotes" component={PesertaLogin} />
            <PesertaPrivateRoute exact path="/psikotes/mengerjakan" component={Mengerjakan} />

            <Route exact path="/psikotes/prepare" component={Prepare} />
            <Route exact path="/psikotes/finish" component={Selesai} />
            <Route path="/110423009" component={Testing} />
            <Route exact path="/report-excel/:project_id/:tbl_type" component={ReportExcel} />
          </div>
          <Route component={NotFoundPages} />
        </Switch>
      </Suspense>
    </Router>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    userLogin: (token) => dispatch(userLogin(token)),
  };
};

const mapStateToProps = (state) => {
  return {
    isAdmin: state.user.isAdmin,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
