import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { API } from "../../services/API";
import TopSection from "../TopSection";
import styles from "./RiwayatProjectPesertaContent.module.css";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";

const RiwayatProjectPesertaContent = () => {
  const history = useHistory();
  const { id } = useParams();

  const [peserta, setPeserta] = useState({});

  useEffect(() => {
    const getPeserta = async () => {
      try {
        const res = await API.get(`/peserta/${id}`);
        setPeserta(res.data.peserta);
      } catch (error) {
        console.log(error);
      }
    };

    getPeserta();
  }, [id]);

  return (
    <>
      <div className={styles.container}>
        <TopSection />

        <div className={styles.mainSection}>
          <h5 className={styles.pageTitle}>
            <span className={styles.pathLink} onClick={() => history.push("/dashboard")}>
              Beranda
            </span>{" "}
            <span
              className={styles.pathLink}
              onClick={() => history.push("/manajemen-peserta")}
            >
              / Manajemen Peserta
            </span>{" "}
            <span className={styles.pathNow}>/ Riwayat Project</span>
          </h5>

          <div className={styles.sectionBody}>
            <SectionOne peserta={peserta} />

            <SectionTwo peserta={peserta} />
          </div>
        </div>
      </div>
      <div className={styles.background}></div>
    </>
  );
};

export default RiwayatProjectPesertaContent;
