import styles from "./SelectGroup.module.css";

const SelectGroup = ({ children, label, state, setState }) => {
  return (
    <div className={styles.selectGroup}>
      <label>{label}</label>
      <br />
      <select value={state} onChange={(e) => setState(e.target.value)}>
        {children}
      </select>
    </div>
  );
};

export default SelectGroup;
