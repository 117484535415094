import PetunjukLast from "../../../../Components/PetunjukLast";
import PetunjukContainer from "../../../../Containers/PetunjukContainer";
import PetunjukZero from "../PetunjukZero";

const PetunjukContainerCfitThreeA = ({
  children,
  isModal,
  previousHandler,
  nextHandler,
  tutupModalHandler,
  index,
  lastIndex,
}) => {
  return (
    <PetunjukContainer>
      <PetunjukZero selectedIndex={index} next={nextHandler} />
      {children}
      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={lastIndex}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default PetunjukContainerCfitThreeA;
