import React from 'react';

const HeaderThird = () => {
  return (
    <React.Fragment>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>1</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>2</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>3</th>
    </React.Fragment>
  );
};

export default HeaderThird;
