import { connect } from "react-redux";
import { useEffect, useState } from "react";
import SoalHeader from "../../Components/SoalHeader";
import PetunjukModal from "../../Components/PetunjukModal";
import Petunjuk from "../Petunjuk";
import { setAnswerLS, setJawabansLS, setSisaWaktuLS } from "../../Utils";
import ButtonSoal from "../../Components/ButtonSoal";
import Navigation from "../../Components/Navigation";
import SoalContainer from "../../Containers/SoalContainer";
import SoalContent from "../../Containers/SoalContent";
import {
  mapDispatchToPropsSoal,
  mapStateToPropsSoal,
} from "../../Utils/reduxMap";
import PilihanWrapper from "../../Components/PilihanWrapper";
import PilihanText from "../../Components/PilihanText";
import SoalText from "../../Components/SoalText";

const Soal = ({ currentSoal, currentTes, replaceJawabans }) => {
  const [answer, setAnswer] = useState("");
  const [sisaWaktu, setSisaWaktu] = useState(-1);
  const [isShowSelesaiModal, setIsShowSelesaiModal] = useState(false);
  const [isShowPetunjukModal, setIsShowPetunjukModal] = useState(false);

  useEffect(() => {
    setSisaWaktuLS(currentTes?.tes?.waktu, setSisaWaktu);
  }, [currentTes?.tes?.waktu]);

  useEffect(() => {
    setAnswer("");
    setAnswerLS(currentSoal, setAnswer);
  }, [currentSoal]);

  useEffect(() => {
    setJawabansLS(answer, replaceJawabans);
  }, [answer, replaceJawabans]);

  return (
    <SoalContainer
      isShowSelesaiModal={isShowSelesaiModal}
      setIsShowSelesaiModal={setIsShowSelesaiModal}
    >
      <SoalHeader
        setIsShowPetunjukModal={setIsShowPetunjukModal}
        sisaWaktu={sisaWaktu}
      />
      <SoalContent>
        <SoalText
          text={JSON.parse(currentSoal?.soal)?.naskah}
          fontSize="24px"
          lineHeight="normal"
          textAlign="justify"
        />
        <SoalText text={JSON.parse(currentSoal?.soal)?.soal} />

        <PilihanWrapper variant="text" col={3}>
          {currentSoal?.pilihan?.map((option, index) => (
            <PilihanText
              key={index}
              label={option}
              answer={answer}
              setIsShowSelesaiModal={setIsShowSelesaiModal}
            />
          ))}
        </PilihanWrapper>
        <ButtonSoal setIsShowSelesaiModal={setIsShowSelesaiModal} />
        <Navigation />
      </SoalContent>
      <PetunjukModal
        isShow={isShowPetunjukModal}
        onHide={() => setIsShowPetunjukModal(false)}
      >
        <Petunjuk
          isModal
          tutupModalHandler={() => setIsShowPetunjukModal(false)}
        />
      </PetunjukModal>
    </SoalContainer>
  );
};

export default connect(mapStateToPropsSoal, mapDispatchToPropsSoal)(Soal);
