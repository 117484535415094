import { useState } from "react";
import PetunjukContainer from "../../../../Containers/PetunjukContainer";
import PetunjukLast from "../../../../Components/PetunjukLast";
import PetunjukMiddle from "./PetunjukMiddle";
import PetunjukZero from "../../Components/PetunjukZero";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihan = [
    [
      "contoh-cfit3b-4-1a.png",
      "contoh-cfit3b-4-1b.png",
      "contoh-cfit3b-4-1c.png",
      "contoh-cfit3b-4-1d.png",
      "contoh-cfit3b-4-1e.png",
    ],
    [
      "contoh-cfit3b-4-2a.png",
      "contoh-cfit3b-4-2b.png",
      "contoh-cfit3b-4-2c.png",
      "contoh-cfit3b-4-2d.png",
      "contoh-cfit3b-4-2e.png",
    ],
    [
      "contoh-cfit3b-4-3a.png",
      "contoh-cfit3b-4-3b.png",
      "contoh-cfit3b-4-3c.png",
      "contoh-cfit3b-4-3d.png",
      "contoh-cfit3b-4-3e.png",
    ],
  ];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero next={nextHandler} selectedIndex={index} />

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        namaFile="contoh-cfit3b-4-1.png"
        arrPilihan={arrPilihan[0]}
        pesanBenar='Contoh di atas, pola yang memiliki kemiripan adalah pola pada kotak "c" (sudah terdapat titik), dimana pola pada kotak "c" diberi tanda titik, maka akan berada dalam lingkaran dan didalam bidang segi empat, seperti pada pola contoh (atas).'
        kunci="contoh-cfit3b-4-1c.png"
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        namaFile="contoh-cfit3b-4-2.png"
        arrPilihan={arrPilihan[1]}
        pesanBenar='Jawaban yang tepat adalah "d", karena: Pada pola "d", apabila diberi tanda titik akan mirip dengan pola contoh, yaitu titik itu akan berada di dalam segitiga tetapi diluar bidang segi empat.'
        kunci="contoh-cfit3b-4-2d.png"
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        namaFile="contoh-cfit3b-4-3.png"
        arrPilihan={arrPilihan[2]}
        pesanBenar="Pada contoh ini jawaban yang tepat adalah <strong>b</strong> karena <strong>sama-sama bisa menempatkan titik di dalam segitiga dan diatas garis cekung."
        kunci="contoh-cfit3b-4-3b.png"
        previous={previousHandler}
        next={nextHandler}
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={4}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
