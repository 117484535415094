import { useState } from "react";
import Benar from "../../../../Components/PetunjukMiddle/Benar";
import Footer from "../../../../Components/PetunjukMiddle/Footer";
import Salah from "../../../../Components/PetunjukMiddle/Salah";
import PetunjukParagraph from "../../../../Components/PetunjukMiddle/PetunjukParagraph";
import PetunjukMiddleContainer from "../../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import SoalImage from "../../../../Components/SoalImage";
import PilihanWrapper from "../../../../Components/PilihanWrapper";
import PilihanText from "../../../../Components/PilihanText";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  namaFile,
  kunci,
  next,
  previous,
  pesanBenar,
  soal,
}) => {
  const [answer, setAnswer] = useState("");
  const arrPilihan = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

  return (
    <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
      <PetunjukParagraph textAlign="center">{soal}</PetunjukParagraph>
      <SoalImage src={`/images/tes/eas/eas5/petunjuk_eas51.png`} alt="Contoh" />

      <PilihanWrapper variant="text" col={5}>
        {arrPilihan?.map((item, index) => (
          <PilihanText
            label={item}
            onClick={() => setAnswer(item)}
            answer={answer}
            key={index}
          />
        ))}
      </PilihanWrapper>
      {answer === kunci && (
        <>
          <Benar isShow htmlString={pesanBenar} />
          <br />
          <SoalImage src={`/images/tes/eas/eas5/${namaFile}`} alt="Benar" />
        </>
      )}
      <Salah isShow={answer !== kunci && answer !== ""} />
      <Footer
        min={1}
        max={10}
        previous={previous}
        next={next}
        condition={answer === kunci}
      />
    </PetunjukMiddleContainer>
  );
};

export default PetunjukMiddle;
