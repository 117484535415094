import { connect } from "react-redux";
import PetunjukParagraph from "../../../Components/PetunjukMiddle/PetunjukParagraph";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import { mapDispatchToPropsPetunjuk } from "../../../Utils/reduxMap";
import styles from "./Petunjuk.module.css";

const Petunjuk = ({ changeCurrentPetunjuk, isModal, tutupModalHandler }) => {
  const tutupHandler = () => {
    const psikotesLS = JSON.parse(localStorage.getItem("psikotesTalenta"));
    psikotesLS.currentPetunjuk = 0;
    localStorage.setItem("psikotesTalenta", JSON.stringify(psikotesLS));

    changeCurrentPetunjuk(0);
  };

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <div className={styles.petunjukLast}>
        <PetunjukParagraph>
          Anda akan dihadapkan pada berbagai macam aktivitas. Bacalah
          aktivitas-aktivitas tersebut dengan teliti. Pilih / Klik pada kolom
          SUKA jika anda menyukai aktivitas tersebut. Pilih / Klik pada kolom
          TIDAK SUKA jika anda tidak menyukai aktivitas tersebut.
        </PetunjukParagraph>
        <PetunjukParagraph>
          Pada bagian kedua soal akan ada pilihan MAMPU jika anda mampu
          melakukan aktivitas tersebut. Pilih / Klik pada kolom TIDAK MAMPU jika
          anda tidak mampu melakukan aktivitas tersebut.
        </PetunjukParagraph>
        <PetunjukParagraph>
          Dan pada bagian ketiga akan ada pilihan TERTARIK jika anda tertarik
          melakukan aktivitas tersebut. Pilih / Klik pada kolom TIDAK TERTARIK
          jika anda tidak tertarik melakukan aktivitas tersebut.
        </PetunjukParagraph>
        <PetunjukParagraph>
          Anda harus menjawab semua aktivitas tersebut. Pastikan anda tidak
          melewatkan satu nomor pun.
        </PetunjukParagraph>
        <div className={styles.footer}>
          {isModal ? (
            <button onClick={tutupModalHandler}>Tutup</button>
          ) : (
            <button onClick={tutupHandler}>Tutup</button>
          )}
        </div>
      </div>
    </PetunjukContainer>
  );
};

export default connect(null, mapDispatchToPropsPetunjuk)(Petunjuk);
