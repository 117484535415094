import React from 'react';

// ** Components
import * as Cfit3b from './components/cfit3b';
import * as Disc from './components/disc';
import * as Holland from './components/holland';
import * as Kostick from './components/kostick';
import * as Kraepelin from './components/kraepelin';
import * as PersonalInfo from './components/personal-info';
import * as SelfAssessment from './components/self-assessment';
import * as Tiki from './components/tiki';
import * as IST from './components/ist';

const getHeader = {
  standar: {
    cfit3b: Cfit3b.DefaultHeaderSecond,
    disc: Disc.DefaultHeaderSecond,
    holland: Holland.DefaultHeaderSecond,
    kostick: Kostick.DefaultHeaderSecond,
    kraepelin: Kraepelin.DefaultHeaderSecond,
    personal_info: PersonalInfo.DefaultHeaderSecond,
    self_assessment: SelfAssessment.DefaultHeaderSecond,
    tiki: Tiki.DefaultHeaderSecond,
  },
  taf: {
    cfit3b: Cfit3b.DefaultHeaderSecond,
    disc: Disc.DefaultHeaderSecond,
    holland: Holland.DefaultHeaderSecond,
    kostick: Kostick.DefaultHeaderSecond,
    kraepelin: Kraepelin.TafHeaderSecond,
    personal_info: PersonalInfo.DefaultHeaderSecond,
    self_assessment: SelfAssessment.DefaultHeaderSecond,
    tiki: Tiki.TafHeaderSecond,
  },
  egatek: {
    cfit3b: Cfit3b.DefaultHeaderSecond,
    disc: Disc.DefaultHeaderSecond,
    holland: Holland.DefaultHeaderSecond,
    kostick: Kostick.DefaultHeaderSecond,
    kraepelin: Kraepelin.EgatekHeaderSecond,
    personal_info: PersonalInfo.DefaultHeaderSecond,
    self_assessment: SelfAssessment.DefaultHeaderSecond,
    tiki: Tiki.DefaultHeaderSecond,
  },
  edu: {
    cfit3b: Cfit3b.EduHeaderSecond,
    disc: Disc.DefaultHeaderSecond,
    holland: Holland.DefaultHeaderSecond,
    kostick: Kostick.DefaultHeaderSecond,
    kraepelin: Kraepelin.EduHeaderSecond,
    personal_info: PersonalInfo.EduHeaderSecond,
    self_assessment: SelfAssessment.DefaultHeaderSecond,
    tiki: Tiki.DefaultHeaderSecond,
  },
  lppi: {
    cfit3b: Cfit3b.DefaultHeaderSecond,
    disc: Disc.DefaultHeaderSecond,
    holland: Holland.DefaultHeaderSecond,
    kostick: Kostick.DefaultHeaderSecond,
    kraepelin: Kraepelin.DefaultHeaderSecond,
    personal_info: PersonalInfo.LppiHeaderSecond,
    self_assessment: SelfAssessment.LppiHeaderSecond,
    tiki: Tiki.LppiHeaderSecond,
  },
  komatsu: {
    personal_info: PersonalInfo.DefaultHeaderSecond,
    ist: IST.KomatsuHeaderSecond,
    kraepelin: Kraepelin.DefaultHeaderSecond,
    disc: Disc.DefaultHeaderSecond,
  },
};

const HeaderSecond = ({ type = 'standar', format = [] }) => {
  return (
    <tr className="th-first">
      {format.map((f) => {
        const Header = getHeader[type][f];

        if (!Header) return <React.Fragment key={f} />;

        return <Header key={f} />;
      })}
    </tr>
  );
};

export default HeaderSecond;
