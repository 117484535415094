import styles from "./PetunjukParagraph.module.css";

const PetunjukParagraph = ({
  children,
  textAlign = "justify",
  fontSize = "24px",
}) => (
  <p
    className={styles.PetunjukParagraph}
    dangerouslySetInnerHTML={{ __html: children }}
    style={{ textAlign, fontSize }}
  />
);

export default PetunjukParagraph;
