import { useEffect, useState } from 'react';
import styles from './CheckboxGambar.module.css';
import check from '../../../../assets/psikotes/check.png';
import checkFilled from '../../../../assets/psikotes/check_filled.png';

const CheckboxGambar = ({
  fileName,
  setAnswer,
  answer,
  point,
  imagePath,
  alt,
}) => {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (typeof answer === 'string') {
      answer = JSON.parse(answer);
    }

    answer?.forEach((item) => {
      if (item.fileName === fileName) {
        setIsSelected(true);
      }
    });
  }, [answer, fileName]);

  const removeAnswerHandler = () =>
    [...answer].filter((item) => item.fileName !== fileName);

  return (
    <>
      <div
        className={styles.pilihanItem}
        style={{
          backgroundColor: isSelected ? '#3BC2AA' : '#e5e8ee',
        }}
        onClick={() => {
          isSelected
            ? setAnswer(removeAnswerHandler())
            : setAnswer([...answer, { fileName, point }]);
          setIsSelected(!isSelected);
        }}
      >
        <img
          src={`${imagePath}/${fileName}`}
          alt={alt}
          className={styles.pilihanGambar}
          draggable={false}
        />
        <img
          src={isSelected ? checkFilled : check}
          alt="check"
          className={styles.check}
          draggable={false}
        />
      </div>
    </>
  );
};

export default CheckboxGambar;
