import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'services/API';

export const getAllData = createAsyncThunk('getAllData/project', async (project_id) => {
  try {
    const response = await API.get(`/project/${project_id}`);
    return response.data.data;
  } catch (error) {
    return error.response;
  }
});

const projectSlice = createSlice({
  name: 'project',
  initialState: {
    allData: [],
    data: {},
    selectedData: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllData.fulfilled, (state, action) => {
      state.allData = action.payload;
    });
  },
});

export default projectSlice.reducer;
