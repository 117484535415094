import { API as axios } from '../../../services/API';

const getClients = async () => {
  try {
    const response = await axios.get('/client');
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const addCodes = async (data) => {
  try {
    const response = await axios.post('/peserta/add-aktivasi', data);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getClients, addCodes };
