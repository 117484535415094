import { useState } from "react";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukMiddle from "./PetunjukMiddle";
import PetunjukZero from "../../../Containers/PetunjukZero";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Terdapat pernyataan-pernyataan. Silahkan pilih jawaban B (Benar), S
          (Salah), atau ? (tidak dapat diketahui) dari kesimpulan yang ada atas
          pernyataan-pernyataan berikut.
        </p>
        <br />
        <p>
          Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        kesimpulan="Ella tidak bekerja di perusahaan A"
        kunci="B"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban yang tepat adalah <b>B (Benar)</b>"
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        kesimpulan="Anak laki-laki Ella sakit"
        kunci="?"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban yang tepat adalah <b>? (tidak dapat diketahui)</b>"
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
