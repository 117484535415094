import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import styles from './ActionLihat.module.css';
import { API } from '../../../../../services/API';
import FileDownload from 'js-file-download';
import ErrorModal from '../../../../ErrorModal';
import { Spinner } from 'react-bootstrap';

const UnduhPDF1 = ({ cell, row }) => {
  // const [isShowModal, setIsShowModal] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const today = new Date().toLocaleDateString('pt-PT').split('/').join('-');

  let namaPeserta = row.original.namaPeserta.split(',,,')[0];
  const fileName = `${row.original.projectId}_${namaPeserta ? namaPeserta : 'xxx'}_${today}.pdf`;
  const download = async () => {
    setIsLoading(true);
    await API.get(
      `/laporan-pdf?pesertaId=${row.original.id}&isLaporanUmum=${row.original.projectDetail.isLaporanUmum}&forZip=0`,
      {
        responseType: 'blob',
      }
    )
      .then((res) => {
        setIsLoading(false);
        FileDownload(res.data, fileName);
      })
      .catch(async (err) => {
        let data = await err.response.data.text();
        data = JSON.parse(data);
        setIsLoading(false);
        let detailErrMessage = data.message;
        setError(true);
        setErrorMessage(
          <div>
            Gagal download semua laporan, silahkan cek kembali semua data
            peserta!
            {detailErrMessage && (
              <>
                <br />
                <span className="text-danger">
                  Error Detail: {detailErrMessage}
                </span>
              </>
            )}
          </div>
        );
      });
  };

  const closeModal = () => setError(false);

  return (
    <div className={styles.wrapper}>
      <button
        className={
          isLoading
            ? `${styles.laporanButton} ` + styles.disabled
            : styles.laporanButton
        }
        // onClick={() => setIsShowModal(true)}
        onClick={download}
        disabled={isLoading}
      >
        <span>Download</span>
        {isLoading ? (
          <Spinner animation="border" variant="info" size="sm" />
        ) : (
          <span>
            <FontAwesomeIcon icon={faArrowCircleDown} />
          </span>
        )}
      </button>

      {error && (
        <ErrorModal
          visible={error}
          message={errorMessage}
          onClose={closeModal}
        />
      )}
      {/* <Dropdown
        isShow={isShowModal}
        onHide={() => setIsShowModal(false)}
        userId={row.original.id}
      /> */}
    </div>
  );
};

export default UnduhPDF1;
