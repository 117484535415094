import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihanOne = ["01a.jpg", "01b.jpg", "01c.jpg", "01d.jpg", "01e.jpg"];
  const arrPilihanTwo = ["02a.jpg", "02b.jpg", "02c.jpg", "02d.jpg", "02e.jpg"];
  const arrPilihanThree = [
    "03a.jpg",
    "03b.jpg",
    "03c.jpg",
    "03d.jpg",
    "03e.jpg",
  ];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Ditentukan 5 (lima) buah kubus, Pada tiap-tiap kubus terdapat enam
          tanda yang berlainan pada setiap sisinya. Tiga dari tanda itu dapat
          dilihat.
        </p>
        <br />
        <p>
          Kubus-kubus yang ditentukan itu ialah kubus-kubus yang berbeda,
          artinya kubus-kubus itu dapat mempunyai tanda-tanda yang sama, akan
          tetapi susunannya berlainan. Setiap soal memperlihatkan salah satu
          kubus yang ditentukan di dalam kedudukan yang berbeda.
        </p>
        <br />
        <p>
          Carilah kubus yang dimaksudkan itu dan pilihlah jawaban dengan klik/
          memilih gambar kubus yang benar pada tiap soal.
        </p>
        <br />
        <p>
          Kubus itu dapat diputar, dapat digulingkan atau dapat diputar dan
          digulingkan dalam pikiran (dibayangkan)
        </p>
        <br />
        <p>
          Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        namaFile="01.jpg"
        arrPilihan={arrPilihanOne}
        previous={previousHandler}
        next={nextHandler}
        kunci="01b.jpg"
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        namaFile="02.jpg"
        arrPilihan={arrPilihanTwo}
        previous={previousHandler}
        next={nextHandler}
        kunci="02a.jpg"
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        namaFile="03.jpg"
        arrPilihan={arrPilihanThree}
        previous={previousHandler}
        next={nextHandler}
        kunci="03d.jpg"
      />
      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={4}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
