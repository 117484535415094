import { useEffect, useState } from "react";
import PetunjukMiddleContainer from "../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import PetunjukParagraph from "../../../Components/PetunjukMiddle/PetunjukParagraph";
import PilihanWrapper from "../../../Components/PilihanWrapper";
import Pilihan from "../../Pilihan";
import styles from "./PetunjukMiddle.module.css";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  arrPilihan,
  next,
  previous,
}) => {
  const [answer, setAnswer] = useState([]);
  const [sumBobot, setSumBobot] = useState(0);
  const [isShowSalah, setIsShowSalah] = useState(false);

  useEffect(() => {
    if (answer.length === 0) {
      const newAnswer = arrPilihan.map((item) => {
        return {
          pilihan: item,
          bobot: 0,
        };
      });

      setAnswer(newAnswer);
    }
  }, [arrPilihan, answer]);

  useEffect(() => {
    let sum = 0;
    answer?.forEach((item) => {
      sum += item.bobot;
    });

    setSumBobot(sum);
    setIsShowSalah(false);
  }, [answer]);

  const lanjutHandler = () => {
    if (sumBobot !== 10) {
      setIsShowSalah(true);
      return;
    }

    next();
  };

  return (
    <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
      <PetunjukParagraph>
        Pilihan pernyataan yang sesuai dengan memberikan penilaian bobot, dimana
        total keseluruhan bobot harus sama dengan 10
      </PetunjukParagraph>
      <PilihanWrapper variant="text" col={1}>
        {arrPilihan?.map((item, index) => (
          <Pilihan
            label={item}
            answer={answer}
            setAnswer={setAnswer}
            index={index}
            key={index}
          />
        ))}
        <br />
      </PilihanWrapper>

      {isShowSalah && (
        <p className={styles.salah}>Total bobot nilai harus bernilai 10</p>
      )}

      <div className={styles.footer}>
        <button onClick={previous}>Kembali</button>
        <button onClick={lanjutHandler}>Lanjutkan</button>
      </div>
    </PetunjukMiddleContainer>
  );
};

export default PetunjukMiddle;
