import styles from "./Right.module.css";

const Right = () => {
  return (
    <div className={styles.right}>
      <h1 className={styles.title}>Kelemahan</h1>
      <ol>
        <li>Melaksanakan tugas-tugas adminisitratif</li>
        <li>Keterlampilan Verbal</li>
        <li>Keterlampiran numerical</li>
        <li>Kemapuan logis</li>
      </ol>
    </div>
  );
};

export default Right;
