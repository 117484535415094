import styles from './Select.module.css';

const Select = ({
  state,
  setState,
  name,
  label,
  children,
  required = true,
  moduls = null,
  setErrorModul = null,
}) => {
  return (
    <div className={styles.inputWrapper}>
      <label htmlFor={name}>
        {label} {required && <span className="text-danger">*</span>}
      </label>
      <select
        name={name}
        id={name}
        onChange={(e) => {
          if (moduls) {
            const index = moduls[0].indexOf(parseInt(e.target.value));
            const getModul = moduls[1][index];
            const belumDiUrutkan = getModul.urutanTes.every(
              (urut) => urut.urutan === 0
            );
            if (belumDiUrutkan) {
              setErrorModul(
                <span className="text-danger" style={{ fontSize: '.8em' }}>
                  Modul ({getModul.nama}) belum di urutkan tesnya. Silahkan
                  untuk mengurutkannya terlebih dahulu!
                </span>
              );
            } else {
              setErrorModul(null);
            }
          }
          setState(e.target.value);
        }}
        value={state}
        required={required}
      >
        {children}
      </select>
    </div>
  );
};

export default Select;
