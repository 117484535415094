import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukMiddle from "./PetunjukMiddle";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <img
          src="/images/tes/tiki-t-8/contoh1_tikit81.png"
          alt="Petunjuk TIKI-T 8"
        />
        <br />
        <p>
          Gambar atas dari tiap soal merupakan gambar yang ditentukan. Di
          sebelah bawah terdapat 6 gambar a, b, c, d, e dan f yang sama
          bentuknya, tetapi dalam posisi atau kedudukan yang berbeda-beda.
          <br /> Empat diantaranya adalah tepat sama dengan gambar yang
          ditentukan, yaitu gambar atas, sedangkan 2 lainnya merupakan{" "}
          <strong>
            <em>bayangan cermin</em>{" "}
          </strong>
          dari padanya. Carilah kedua gambar bayangan cermin yang dimaksud dan
          berilah jawaban yang sesuai.
        </p>
        <br />

        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        namaFile="contoh1_tikit82.png"
        kunci={18}
        pesanBenar="Gambar b dan e merupakan bayangan cermin dari gambar yang ditentukan. Oleh karena itu, jawablah b dan e."
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        namaFile="contoh1_tikit83.png"
        kunci={10}
        pesanBenar="Gambar b dan d merupakan bayangan cermin dari gambar yang ditentukan. Oleh karena itu, jawablah b dan d."
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        namaFile="contoh1_tikit84.png"
        kunci={17}
        pesanBenar="Gambar a dan e merupakan bayangan cermin dari gambar yang ditentukan. Oleh karena itu, jawablah a dan e."
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={4}
        kunci={24}
        namaFile="contoh1_tikit85.png"
        pesanBenar="Gambar d dan e merupakan bayangan cermin dari gambar yang ditentukan. Oleh karena itu, jawablah d dan e."
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={5}
        kunci={34}
        namaFile="contoh1_tikit86.png"
        pesanBenar="Gambar b dan f merupakan bayangan cermin dari gambar yang ditentukan. Oleh karena itu, jawablah b dan f."
        previous={previousHandler}
        next={nextHandler}
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={6}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
