import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihan = [
    [
      "GATB_bangun ruang_contoh1_jwb1.jpg",
      "GATB_bangun ruang_contoh1_jwb2.jpg",
      "GATB_bangun ruang_contoh1_jwb3.jpg",
      "GATB_bangun ruang_contoh1_jwb4.jpg",
    ],
    [
      "GATB_bangun ruang_contoh2_jwb1.jpg",
      "GATB_bangun ruang_contoh2_jwb2.jpg",
      "GATB_bangun ruang_contoh2_jwb3.jpg",
      "GATB_bangun ruang_contoh2_jwb4.jpg",
    ],
    [
      "GATB_bangun ruang_contoh3_jwb1.jpg",
      "GATB_bangun ruang_contoh3_jwb2.jpg",
      "GATB_bangun ruang_contoh3_jwb3.jpg",
      "GATB_bangun ruang_contoh3_jwb4.jpg",
    ],
    [
      "GATB_bangun ruang_contoh4_jwb1.jpg",
      "GATB_bangun ruang_contoh4_jwb2.jpg",
      "GATB_bangun ruang_contoh4_jwb3.jpg",
      "GATB_bangun ruang_contoh4_jwb4.jpg",
    ],
    [
      "GATB_bangun ruang_contoh5_jwb1.jpg",
      "GATB_bangun ruang_contoh5_jwb2.jpg",
      "GATB_bangun ruang_contoh5_jwb3.jpg",
      "GATB_bangun ruang_contoh5_jwb4.jpg",
    ],
  ];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Pada subtes ini, Anda akan mengerjakan soal-soal yang mengharuskan
          Anda untuk mencari benda mana yang dapat dibuat dari potongan seng
          datar pada soal.
        </p>
        <br />
        <p>
          Pada setiap soal, hanya satu benda yang dapat dibuat dari potongan
          seng datar tersebut, yaitu dengan melihat, menggulung atau keduanya.
        </p>
        <br />
        <p>
          Perhatikan contoh soal untuk memahami soal yang akan Anda kerjakan.
        </p>
        <br />
        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        next={nextHandler}
        previous={previousHandler}
        namaFile="GATB_bangun ruang_contoh1.jpg"
        pesanBenar="Gambar pada soal merupakan sebuah potongan yang datar. Garis yang terputus-putus menunjukkan batas-batas dimana seng tersebut dilipat. Kemudian terdapat gambar dari empat benda (A, B, C, D).<br> Perhatikan bahwa hanya benda <strong>D</strong> dapat dibuat dengan melipat potongan yang disebelah kiri, sesuai dengan batas-batas yang ada (garis yang terputus-putus). Karena itu untuk contoh 1 <strong>huruf D</strong> harus dipilih."
        arrPilihan={arrPilihan[0]}
        kunci={arrPilihan[0][3]}
      />

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        next={nextHandler}
        previous={previousHandler}
        namaFile="GATB_bangun ruang_contoh2.jpg"
        pesanBenar="Perhatikan gambar-gambar A, B, C, dan D. Hanya benda <strong>C</strong> dapat dibuat dari gambar 2, yaitu dengan menggulung dan melipat potongan seng tersebut. Oleh karena itu untuk contoh 2, huruf <strong>C</strong> harus dipilih."
        arrPilihan={arrPilihan[1]}
        kunci={arrPilihan[1][2]}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        next={nextHandler}
        previous={previousHandler}
        namaFile="GATB_bangun ruang_contoh3.jpg"
        pesanBenar="Jawaban anda benar!"
        arrPilihan={arrPilihan[2]}
        kunci={arrPilihan[2][2]}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={4}
        next={nextHandler}
        previous={previousHandler}
        namaFile="GATB_bangun ruang_contoh4.jpg"
        pesanBenar="Jawaban anda benar!"
        arrPilihan={arrPilihan[3]}
        kunci={arrPilihan[3][1]}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={5}
        next={nextHandler}
        previous={previousHandler}
        namaFile="GATB_bangun ruang_contoh5.jpg"
        pesanBenar="Jawaban anda benar!"
        arrPilihan={arrPilihan[4]}
        kunci={arrPilihan[4][0]}
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={6}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
