import { useState } from 'react';
import PetunjukLast from '../../../Components/PetunjukLast';
import PetunjukContainer from '../../../Containers/PetunjukContainer';
import PetunjukZero from '../../../Containers/PetunjukZero';
import PetunjukMiddle from './PetunjukMiddle';

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>Perhatikan kode-kode di bawah ini :</p>

        <Hint symbol="=" pesan="berarti SAMA DENGAN" />
        <Hint symbol=">" pesan="berarti LEBIH BESAR DARI" />
        <Hint symbol="<" pesan="berarti LEBIH KECIL DARI" />
        <Hint symbol="#" pesan="berarti TIDAK SAMA DENGAN (LEBIH BESAR / LEBIH KECIL)" />
        <Hint symbol="≥" pesan="berarti LEBIH BESAR SAMA DENGAN" />
        <Hint symbol="≤" pesan="berarti LEBIH KECIL SAMA DENGAN" />
        <Hint symbol="?" pesan="berarti TIDAK DIKETAHUI" />
        <br />
        <p>
          Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan contoh soal sebelum
          mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="JIKA  X = Y > Z  MAKA  X > Z"
        kunci="B"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban yang tepat adalah <b>B (Benar)</b>"
      />

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="JIKA  X > Y > Z  MAKA  X < Z"
        kunci="S"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban yang tepat adalah <b>S (Salah)</b>"
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

const Hint = ({ symbol, pesan }) => (
  <p>
    <strong>{symbol}</strong> {pesan}
  </p>
);

export default Petunjuk;
