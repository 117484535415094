import { connect } from "react-redux";
import {
  changeCurrentPetunjuk,
  changeCurrentSoal,
  changeCurrentTes,
} from "../../../../redux/Peserta/peserta-actions";
import SelesaiModal from "../../Components/SelesaiModal";
import { nextTesHandlerUtil } from "../../Utils";
import styles from "./SoalContainer.module.css";

const SoalContainer = ({
  children,
  isShowSelesaiModal,
  setIsShowSelesaiModal,
  changeCurrentPetunjuk,
  changeCurrentSoal,
  changeCurrentTes,
}) => (
  <div className={styles.container}>
    {children}
    {setIsShowSelesaiModal && (
      <SelesaiModal
        isShow={isShowSelesaiModal}
        onHide={() => setIsShowSelesaiModal(false)}
        selesaiHandler={() =>
          nextTesHandlerUtil(
            changeCurrentTes,
            changeCurrentSoal,
            changeCurrentPetunjuk
          )
        }
      />
    )}
  </div>
);

const mapDispatchToProps = (dispatch) => ({
  changeCurrentSoal: (currentSoal) => dispatch(changeCurrentSoal(currentSoal)),
  changeCurrentTes: (currentTes) => dispatch(changeCurrentTes(currentTes)),
  changeCurrentPetunjuk: (currentPetunjuk) =>
    dispatch(changeCurrentPetunjuk(currentPetunjuk)),
});

export default connect(null, mapDispatchToProps)(SoalContainer);
