import { useEffect } from "react";
import { connect } from "react-redux";
import { generateEmptyJawabans } from "../Utils";
import { mapDispatchToPropsTes, mapStateToPropsTes } from "../Utils/reduxMap";
import Petunjuk from "./Petunjuk";
import Soal from "./Soal";
const TiuFive = ({ currentPetunjuk, currentTes, replaceJawabans }) => {
  useEffect(() => generateEmptyJawabans(replaceJawabans), [replaceJawabans]);

  return (
    <div>
      {currentPetunjuk === 17 && <Petunjuk />}
      {currentPetunjuk !== 17 && currentTes.tesId === 17 && <Soal />}
    </div>
  );
};

export default connect(mapStateToPropsTes, mapDispatchToPropsTes)(TiuFive);
