import SideBar from "../../../components/SideBar";

const ManajemenPengguna = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <SideBar />
      <div>This is ManajemenPengguna page</div>
    </div>
  );
};

export default ManajemenPengguna;
