import React from 'react';

import { isObjectEmpty, getEmptyData } from 'utility/Utils';

const Column = ({ data }) => {
  if (isObjectEmpty(data)) return getEmptyData(3);

  return (
    <React.Fragment>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.rs}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.iq}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.classification}</td>
    </React.Fragment>
  );
};
export default Column;
