import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukMiddle from "./PetunjukMiddle";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Perhatikan pada tab contoh untuk memahami soal yang akan Anda
          kerjakan.
        </p>
        <br />
        <p>Tes berikut ini terdiri dari gambar bentuk-bentuk.</p>
        <br />
        <p style={{ color: "red" }}>
          Jangan ditutup dulu petunjuk ini, perhatikan contoh, Apabila petunjuk
          ini ditutup maka tes dimulai dan waktu berjalan.
        </p>
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        namaFile="petunjuk_tiki4_0001.png"
        kunci={33}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Dapat dilihat, bahwa 4 gambar pertama yang diatas semuanya adalah segi empat atau bujur sangkar. Dari 6 gambar berikutnya, hanya gambar a dan f saja yang menunjukkan kesamaan itu, yaitu berupa segi empat atau bujur sangkar. Oleh karena itu a dan f yang dipilih."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        namaFile="petunjuk_tiki4_0002.png"
        kunci={20}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Keempat gambar pertama di bagian atas semuanya dihitamkan dengan cara sama. Diantara 6 gambar berikutnya, hanya gambar c dan e yang dihitamkan dengan cara sama. Oleh karena itu pilihlah c dan e."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        namaFile="petunjuk_tiki4_0003.png"
        kunci={20}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Dapat dilihat bahwa ke-4 gambar pertama adalah garis-garis melengkung, maka jawaban yang benar adalah c dan e."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={4}
        namaFile="petunjuk_tiki4_0004.png"
        kunci={24}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawabannya adalah d dan e, sebab garis-garis dalam ke-4 gambar pertama hanya menyinggung lingkaran bukannya memotong."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={5}
        namaFile="petunjuk_tiki4_0005.png"
        kunci={48}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawabannya adalah e dan f. Jika diperhatikan akan terlihat bahwa bentuk-bentuk dalam ke-4 gambar pertama tidak ada yang bersudut tegak lurus."
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={6}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
