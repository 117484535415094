import { useState } from 'react';
import PetunjukLast from '../../Components/PetunjukLast';
import PetunjukContainer from '../../Containers/PetunjukContainer';
import styles from './Petunjuk.module.css';
import Footer from '../../Components/PetunjukMiddle/Footer';

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <div
        className={styles.petunjukZero}
        style={{ display: index === 0 ? 'flex' : 'none' }}
      >
        <p>Terdapat tiga tugas yang harus Anda lakukan:</p>
        <ol>
          <li>
            Memberikan prioritas terhadap semua memo / dokumen kerja yang masuk,
            ke dalam tiga kategori:
            <ul>
              <li>High Priority</li>
              <li>Moderate Priority</li>
              <li>Low Priority</li>
            </ul>
          </li>
          <li>
            Menuliskan secara spesifik Rencana Tindakan yang akan dilakukan untuk merespon setiap memo dan dokumen kerja.
          </li>
          <li>
            Menulis rencana kerja Program Pengembangan Staff. Detil mengenai tugas ini disajikan pada memo terakhir atau memo no.10
          </li>
        </ol>

        <p>
          <b>
            Waktu keseluruhan yang Anda perlukan untuk melakukan semua tugas
            tersebut adalah 60 menit.
          </b>
        </p>

        <div className={styles.buttonWrapper}>
          <button onClick={nextHandler}>Tutup</button>
        </div>
      </div>

      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 1 ? 'flex' : 'none' }}
      >
        <p>
          Anda adalah Jack, seorang karyawan yang telah menduduki posisi sebagai Kepala Bagian – Akuntansi selama 6 bulan di perusahaan reasuransi bernama Talenta RE. PT Reasuransi Talenta Indonesia adalah perusahaan reasuransi yang memasarkan berbagai produk reasuransi, antara lain Reasuransi Harta Benda, Kendaraan Bermotor, Rekayasa, Pengangkutan, Rangka Kapal, Rangka Pesawat, Kredit, Surety Bond, Kredit, Satelit, Energi, Tanggung Gugat, Kecelakaan Diri, Aneka dan Reasuransi Jiwa, baik untuk Reasuransi Konvensional maupun Reasuransi Syariah.
        </p>

        <p><i>Struktur Organisasi</i></p>
        <div className="mb-3 text-center">
          <img src={process.env.REACT_APP_SERVER_URL + '/analisa-kasus/ak_kepala_bagian_akuntansi.png'} alt="akuntansi" width='60%' />
        </div>
        <p>
          Hari ini, Kamis 13 Januari XXXX adalah hari pertama Anda kembali masuk kerja setelah 3 hari mengambil cuti untuk liburan bersama keluarga. Pada meja kerja Anda tersusun rapi beberapa memo dan dokumen kerja yang menunggu untuk direspon:
        </p>
        <Footer previous={previousHandler} next={nextHandler} condition />
      </div>

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={2}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
