import { useState } from "react";
import Footer from "../../../Components/PetunjukMiddle/Footer";
import PetunjukMiddleContainer from "../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import Salah from "../../../Components/PetunjukMiddle/Salah";
import PilihanText from "../../../Components/PilihanText";
import PilihanWrapper from "../../../Components/PilihanWrapper";
import styles from "./PetunjukMiddle.module.css";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  kunci,
  next,
  previous,
  pesanBenar,
  pernyataan,
  naskah,
}) => {
  const [answer, setAnswer] = useState("");

  const arrPilihan = ["A", "B", "C"];

  return (
    <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
      <p
        className={styles.naskah}
        dangerouslySetInnerHTML={{
          __html:
            "<small><font color='#3BC2AA'>Bacaan:</font></small><br/>" + naskah,
        }}
      />
      <br />
      <p className={styles.soal}>
        <small>
          <font color="#3BC2AA">Pernyataan:</font>
        </small>
        <br />
        {pernyataan}
      </p>

      <p>
        <small>
          <font color="#3BC2AA">Berikan penilaian, apakah A, B atau C</font>
        </small>
        <br />
        <br />
      </p>

      <PilihanWrapper variant="text" col={3}>
        {arrPilihan?.map((item, index) => (
          <PilihanText
            label={item}
            onClick={() => setAnswer(item)}
            answer={answer}
            key={index}
          />
        ))}
      </PilihanWrapper>

      {answer === kunci && (
        <p
          className={styles.benar}
          dangerouslySetInnerHTML={{
            __html:
              "<small><font color='#3BC2AA'>Keterangan:</font></small><br/>" +
              pesanBenar,
          }}
        />
      )}
      <Salah isShow={answer !== kunci && answer !== ""} />
      <Footer
        min={1}
        max={3}
        previous={previous}
        next={next}
        condition={answer === kunci}
      />
    </PetunjukMiddleContainer>
  );
};

export default PetunjukMiddle;
