import React, { useRef } from 'react';

import { getCurrentDateTime } from 'utility/Utils';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { Button } from 'react-bootstrap';

// ** Components
import HeaderFirst from './HeaderFirst';
import HeaderSecond from './HeaderSecond';
import HeaderThird from './HeaderThird';
import Columns from './Columns';

// ** Style
import './style.scss';

const Table = ({ type = 'standar', format = [], data = [], project = {} }) => {
  const tableRef = useRef();
  const today = getCurrentDateTime();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `${project.nama} - ${today} - ${type.toUpperCase()}`,
    sheet: 'Users',
  });

  return (
    <React.Fragment>
      <div className="p-3">
        <Button onClick={onDownload}>Download Excel</Button>
      </div>
      <table className="table-report-excel m-2" ref={tableRef} border="1">
        <thead>
          {type === 'edu' ? (
            <React.Fragment>
              <tr>
                <th colSpan="50" style={{ fontSize: '28px' }}>
                  REKAPITULASI PT ENERGI DATA UTAMA
                </th>
              </tr>
              <tr></tr>
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}
          {type === 'lppi' ? (
            <React.Fragment>
              <tr>
                <th colSpan="54" style={{ fontSize: '28px' }}>
                  REKAPITULASI LPPI
                </th>
              </tr>
              <tr></tr>
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}
          <HeaderFirst type={type} format={format} />
          <HeaderSecond type={type} format={format} />
          <HeaderThird type={type} format={format} />
        </thead>
        <tbody>
          <Columns data={data} type={type} format={format} />
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default Table;
