import styles from "./Salah.module.css";

const Salah = ({ isShow }) => {
  return (
    <>
      {isShow && (
        <p className={styles.salah}>
          Maaf, jawaban Anda salah. Baca soal dengan teliti sebelum menjawab.
        </p>
      )}
    </>
  );
};

export default Salah;
