import React from 'react';

import { isObjectEmpty, getEmptyData } from 'utility/Utils';

const Column = ({ data }) => {
  if (isObjectEmpty(data)) return getEmptyData(14);

  // ** Function
  const getTikiFour = (arr = [], key) => {
    return arr.map((tik, tik_idx) => {
      if (tik_idx < 4) {
        return (
          <td key={tik_idx} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
            {tik[key]}
          </td>
        );
      }
    });
  };

  return (
    <React.Fragment>
      {data.all_tiki.length < 3 ? getEmptyData(4) : getTikiFour(data.all_tiki, 'rs')}
      {data.all_tiki.length < 3 ? getEmptyData(4) : getTikiFour(data.all_tiki, 'ws')}
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.total}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.iq_group_total}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        {data.iq_classification_total}
      </td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.iq_beta}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.iq_classification_beta}</td>
      {data.f_values.map((tik, tik_idx) => {
        return (
          <td key={tik_idx} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
            {tik.value || '-'}
          </td>
        );
      })}
    </React.Fragment>
  );
};

export default Column;
