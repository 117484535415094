import { useState } from "react";
import Benar from "../../../../Components/PetunjukMiddle/Benar";
import Footer from "../../../../Components/PetunjukMiddle/Footer";
import PetunjukMiddleContainer from "../../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import Salah from "../../../../Components/PetunjukMiddle/Salah";
import PetunjukParagraph from "../../../../Components/PetunjukMiddle/PetunjukParagraph";
import PilihanWrapper from "../../../../Components/PilihanWrapper";
import PilihanText from "../../../../Components/PilihanText";
import SoalImage from "../../../../Components/SoalImage";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  namaFile,
  next,
  previous,
  pesanBenar,
  kunci,
}) => {
  const [answer, setAnswer] = useState("");
  const arrPilihan = ["A", "B", "C", "D", "E"];

  return (
    <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
      <PetunjukParagraph>
        Anda diminta untuk menentukan salah satu dari kelima bentuk yang ada
        untuk menggantikan posisi Tanda Tanya (?)
      </PetunjukParagraph>
      <SoalImage src={`/images/tes/istr/istr9/${namaFile}`} alt="Contoh" />
      <PilihanWrapper variant="text" col={2}>
        {arrPilihan?.map((item, index) => (
          <PilihanText
            label={item}
            onClick={() => setAnswer(item)}
            answer={answer}
            key={index}
          />
        ))}
      </PilihanWrapper>

      <Benar
        isShow={answer === kunci}
        htmlString={pesanBenar}
        textAlign="justify"
      />
      <Salah isShow={answer !== kunci && answer !== ""} />
      <Footer
        min={1}
        max={5}
        previous={previous}
        next={next}
        condition={answer === kunci}
      />
    </PetunjukMiddleContainer>
  );
};

export default PetunjukMiddle;
