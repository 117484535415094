import React from 'react';

const HeaderThird = () => {
  return (
    <React.Fragment>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>JUMLAH PRESTASI</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>KATEGORI</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>RATA-RATA</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>KATEGORI</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>JUMLAH KESALAHAN</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>KATEGORI</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>KONSISTENSI</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>KATEGORI</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>KETAHANAN</th>
      <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>KATEGORI</th>
    </React.Fragment>
  );
};

export default HeaderThird;
