import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import SoalHeaderAlt from '../../../Components/SoalHeaderAlt';
import PilihanWrapper from '../../../Components/PilihanWrapper';
import PetunjukModal from '../../../Components/PetunjukModal';
import Petunjuk from '../Petunjuk';
import { setJawabansLS } from '../../../Utils';
import {
  mapDispatchToPropsTes,
  mapStateToPropsSoal,
} from '../../../Utils/reduxMap';
import SoalContent from '../../../Containers/SoalContent';
import { setAnswerLS } from '../../../Utils';
import PilihanOrange from '../../../Components/PilihanOrange';
import SoalContainer from '../../../Containers/SoalContainer';
import SoalText from '../../../Components/SoalText';

const Soal = ({ currentSoal, replaceJawabans, currentTes }) => {
  const [answer, setAnswer] = useState('');
  const [isShowPetunjukModal, setIsShowPetunjukModal] = useState(false);
  const [soalNo, setSoalNo] = useState(0);
  const [soal, setSoal] = useState([]);

  useEffect(() => {
    if (localStorage.getItem('soalNo') !== null) {
      setSoalNo(parseInt(localStorage.getItem('soalNo')));
    }
    // console.log(soalNo);

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    localStorage.setItem('soalNo', soalNo);
  }, [soalNo]);

  useEffect(() => {
    setAnswer('');
    setAnswerLS(currentSoal, setAnswer);

    if (parseInt(currentTes.tesId) === 92) {
      setSoal(JSON.parse(localStorage.getItem('soalHollEgatek')));
    }

    //eslint-disable-next-line
  }, [currentSoal]);

  useEffect(() => {
    setJawabansLS(answer, replaceJawabans);
  }, [answer, replaceJawabans]);

  return (
    <SoalContainer>
      <SoalHeaderAlt setIsShowPetunjukModal={setIsShowPetunjukModal} />
      <SoalContent>
        <SoalText soalNo={soalNo} />
        <PilihanWrapper variant="text">
          {currentSoal?.pilihan?.map((option) => (
            <PilihanOrange
              key={option}
              label={option}
              setSoalNo={setSoalNo}
              soalNo={soalNo}
              soal={soal[soalNo]}
              soalls={soal}
            />
          ))}
        </PilihanWrapper>
      </SoalContent>
      <PetunjukModal
        isShow={isShowPetunjukModal}
        onHide={() => setIsShowPetunjukModal(false)}
      >
        <Petunjuk
          isModal
          tutupModalHandler={() => setIsShowPetunjukModal(false)}
        />
      </PetunjukModal>
    </SoalContainer>
  );
};

export default connect(mapStateToPropsSoal, mapDispatchToPropsTes)(Soal);
