import styles from './Selesai.module.css';
import logo from '../../../assets/psikotes/login/form_logo.png';
import { useHistory } from 'react-router';
import { API } from '../../../services/API';
import { useEffect, useState } from 'react';
import FileDownload from 'js-file-download';
import { formatTanggalSekarang } from '../../../utils/tanggal';
import ErrorModal from '../../../components/ErrorModal';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../../components/Modal';

const Selesai = () => {
  const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
  const psikotesStatusSelesai = JSON.parse(
    localStorage.getItem('statusTesSelesai')
  );
  const psikotesSelfAssessment =
    localStorage.getItem('selfAssessmentSingkat') &&
    JSON.parse(localStorage.getItem('selfAssessmentSingkat'));
  const psikotesPhotos =
    localStorage.getItem('photos') &&
    JSON.parse(localStorage.getItem('photos'));
  const dataDiriMap =
    localStorage.getItem('dataMappingPegawai') &&
    JSON.parse(localStorage.getItem('dataMappingPegawai'));

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isDownloadSelesai, setIsDownloadSelesai] = useState(false);
  const [isInternetProblem, setIsInternetProblem] = useState(false);
  const internetProblem =
    localStorage.getItem('internetProblem') &&
    JSON.parse(localStorage.getItem('internetProblem'));

  const kodeAktivasi = psikotesLS?.aktivasi;
  const now = new Date();

  const fileName = `${kodeAktivasi}_${formatTanggalSekarang(now)}.talenta`;
  const kode = psikotesLS?.aktivasi;

  const dataJawaban = JSON.stringify({
    psikotesLS,
    psikotesStatusSelesai,
    psikotesSelfAssessment,
    psikotesPhotos,
    dataDiriMap,
  });

  const backToLogin = async () => {
    if (navigator.onLine === false || internetProblem) {
      setErrorMessage(
        <div>
          Gagal kembali ke halaman awal!{' '}
          <span className="text-danger">
            Kami mendeteksi bahwa Anda terkendala jaringan atau selama
            mengerjakan tes sepertinya ada kendala masalah soal tidak ada
            ataupun lainnya.{' '}
            <strong>Kami sarankan Anda untuk download jawaban Anda</strong>{' '}
            terlebih dahulu lalu tekan kembali tombol{' '}
            <strong>"KEMBALI KE HALAMAN AWAL".</strong>
          </span>
        </div>
      );
      return setError(true);
    }

    await API.patch('/edit-status', {
      kode: kode,
      stat: 2,
    });

    localStorage.removeItem('psikotesWaktu');
    history.push('/psikotes');
  };

  const closeModal = () => setError(false);

  const downloadJawaban = async () => {
    if (navigator.onLine === false) {
      setErrorMessage(
        <div>
          Gagal download jawaban!{' '}
          <span className="text-danger">
            Silahkan nyalakan internet anda terlebih dahulu, lalu download
            kembali jawaban Anda.
            <br />
            Ketika Anda sudah menyalakan internet Anda.{' '}
            <strong>"HARAP UNTUK TIDAK MEREFRESH HALAMAN INI!"</strong>
          </span>
        </div>
      );
      return setError(true);
    }
    setIsLoading(true);
    await API.post(
      `/downloadJawaban`,
      {
        dataJawaban,
        fileName,
      },
      {
        responseType: 'blob',
      }
    )
      .then((res) => {
        // console.log({ res });
        setIsLoading(false);
        setIsDownloadSelesai(true);
        FileDownload(res.data, fileName);
        localStorage.setItem('internetProblem', false);
      })
      .catch((err) => {
        setIsDownloadSelesai(false);
        setIsLoading(false);
        setError(true);
        setErrorMessage(
          <div>
            Gagal download jawaban!{' '}
            <span className="text-danger">Silahkan coba kembali.</span>
          </div>
        );
      });
  };
  window.onbeforeunload = (e) => {
    if (navigator.onLine === false || isInternetProblem) {
      e.preventDefault();
      e.returnValue =
        'Harap untuk tidak refresh atau tutup halaman ini! Silahkan download jawaban Anda terlebih dahulu.';
      setErrorMessage(
        <div>
          <span className="text-danger">
            <strong>{e.returnValue}</strong>
          </span>
        </div>
      );
      return setError(true);
    }
  };

  // window.onclose = (e) => {
  //   e.preventDefault();
  //   return false;
  // };

  useEffect(() => {
    setIsInternetProblem(internetProblem);
  }, [internetProblem]);
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <img src={logo} alt="talenta" className={styles.logo} />
      </header>
      <div className={styles.content}>
        <h1>
          Terima kasih Anda Telah Menyelesaikan Seluruh Tes dengan Baik Klik
          Tombol Dibawah Ini Untuk Tahap Akhir
        </h1>
        {(navigator.onLine === false || error || internetProblem) && (
          <button
            className={styles.buttonDownload}
            onClick={downloadJawaban}
            disabled={isLoading}
          >
            Download Jawaban{' '}
            {isLoading ? (
              <Spinner animation="border" variant="info" size="sm" />
            ) : (
              <span>
                <FontAwesomeIcon icon={faArrowCircleDown} />
              </span>
            )}
          </button>
        )}
        <button onClick={() => backToLogin()}>Kembali ke Halaman Awal</button>
        {isDownloadSelesai && (
          <>
            <Modal
              isVisible={isDownloadSelesai}
              func={[() => {}, () => {}]}
              showClose={false}
              onCloseMask={false}
              content={
                <>
                  <p>
                    Download file jawaban berhasil!. Silahkan kumpulkan jawaban
                    Anda di{' '}
                  </p>
                  <a
                    href="https://forms.gle/xED4V8sbMYqfywDb9"
                    target="_blank"
                    rel="noreferrer"
                    className="w-100 h3 text-uppercase"
                    onClick={() => setIsDownloadSelesai(false)}
                  >
                    sini.
                  </a>
                </>
              }
            />
          </>
        )}
      </div>
      {error && (
        <ErrorModal
          visible={error}
          message={errorMessage}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default Selesai;
