import { useState } from "react";
import { connect } from "react-redux";
import InputGroup from "../Components/DataDiri/InputGroup";
import SelectGroup from "../Components/DataDiri/SelectGroup";
import { nextTesHandlerUtil } from "../Utils";
import TextAreaGroup from "../Components/DataDiri/TextAreaGroup";
import {
  mapDispatchToPropsDataDiri,
  mapStateToPropsDataDiri,
} from "../Utils/reduxMap";
import DataDiriContainer from "../Components/DataDiri/DataDiriContainer";
import DataDiriTitle from "../Components/DataDiri/DataDiriTitle";
import DataDiriForm from "../Components/DataDiri/DataDiriForm";
import SectionWrapper from "../Components/DataDiri/SectionWrapper";
import ButtonKirim from "../Components/DataDiri/ButtonKirim";
import HalfSection from "../Components/DataDiri/HalfSection";
import { API } from "../../../services/API";

const BiodataCalonMahasiswa = ({
  changeCurrentPetunjuk,
  changeCurrentSoal,
  changeCurrentTes,
}) => {
  //peserta
  const [namaLengkap, setNamaLengkap] = useState("");
  const [namaSekolah, setNamaSekolah] = useState("");
  const [tanggalLahir, setTanggalLahir] = useState("");
  const [kelas, setKelas] = useState("");
  const [tempatLahir, setTempatLahir] = useState("");
  const [alamatLengkap, setAlamatLengkap] = useState("");
  const [gender, setGender] = useState("");
  const [jurusan, setJurusan] = useState("");

  //Orang Tua
  const [namaIbu, setNamaIbu] = useState("");
  const [pekerjaanIbu, setPekerjaanIbu] = useState("");
  const [teleponIbu, setTeleponIbu] = useState("");
  const [alamatOrangTua, setAlamatOrangTua] = useState("");
  const [namaAyah, setNamaAyah] = useState("");
  const [pekerjaanAyah, setPekerjaanAyah] = useState("");
  const [teleponAyah, setTeleponAyah] = useState("");

  //minat
  const [minatOne, setMinatOne] = useState("");
  const [minatTwo, setMinatTwo] = useState("");
  const [minatThree, setMinatThree] = useState("");
  const [minatFour, setMinatFour] = useState("");
  const [minatFive, setMinatFive] = useState("");
  const [minatSix, setMinatSix] = useState("");
  const [minatSeven, setMinatSeven] = useState("");
  const [minatEight, setMinatEight] = useState("");
  const [minatNine, setMinatNine] = useState("");
  const [minatTen, setMinatTen] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    submitToAPI();
  };

  const submitToAPI = async () => {
    const data = {
      tglLahir: tanggalLahir,
      gender,
      tmptLahir: tempatLahir,
      jurusan,
      dataCalonMahasiswa: {
        peserta: {
          namaLengkap,
          namaSekolah,
          tanggalLahir,
          kelas,
          tempatLahir,
          alamatLengkap,
          gender,
          jurusan,
        },
        orangTua: {
          namaIbu,
          pekerjaanIbu,
          teleponIbu,
          alamatOrangTua,
          namaAyah,
          pekerjaanAyah,
          teleponAyah,
        },
        minat: {
          minatOne,
          minatTwo,
          minatThree,
          minatFour,
          minatFive,
          minatSix,
          minatSeven,
          minatEight,
          minatNine,
          minatTen,
        },
      },
    };

    try {
      // console.log(data);
      // Upload to API
      const psikotesLS = JSON.parse(localStorage.getItem("psikotesTalenta"));
      await API.patch(`/peserta/${psikotesLS.peserta.id}`, data);

      nextTesHandlerUtil(
        changeCurrentTes,
        changeCurrentSoal,
        changeCurrentPetunjuk
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DataDiriContainer>
      <DataDiriTitle title="DATA PESERTA TES" />

      <h2>Form A1: Identitas Diri Siswa</h2>
      <DataDiriForm onSubmit={handleSubmit}>
        <SectionWrapper>
          <HalfSection>
            <InputGroup
              label="Nama Lengkap"
              id="namaLengkap"
              type="text"
              state={namaLengkap}
              setState={setNamaLengkap}
            />

            <SelectGroup
              label="Jenis Kelamin"
              state={gender}
              setState={setGender}
            >
              <option value=""></option>
              <option value="L">Laki-laki</option>
              <option value="P">Perempuan</option>
            </SelectGroup>

            <InputGroup
              label="Kelas"
              id="kelas"
              type="text"
              state={kelas}
              setState={setKelas}
            />
            <InputGroup
              label="Jurusan"
              id="jurusan"
              type="text"
              state={jurusan}
              setState={setJurusan}
            />
          </HalfSection>
          <HalfSection>
            <InputGroup
              label="Nama Sekolah"
              id="namaSekolah"
              type="text"
              state={namaSekolah}
              setState={setNamaSekolah}
            />
            <InputGroup
              label="Tempat Kelahiran"
              id="tempatLahir"
              type="text"
              state={tempatLahir}
              setState={setTempatLahir}
            />

            <InputGroup
              label="Tanggal Lahir"
              id="tanggal-lahir"
              type="date"
              state={tanggalLahir}
              setState={setTanggalLahir}
            />

            <InputGroup
              label="Alamat Lengkap"
              type="text"
              id="alamatLengkap"
              state={alamatLengkap}
              setState={setAlamatLengkap}
            />
          </HalfSection>
        </SectionWrapper>
        <h2>Form A2: Identitas Orang Tua</h2>
        <SectionWrapper>
          <HalfSection>
            <InputGroup
              label="Nama Ayah"
              id="namaAyah"
              type="text"
              state={namaAyah}
              setState={setNamaAyah}
            />

            <InputGroup
              label="Pekerjaan Ayah"
              id="pekerjaanAyah"
              type="text"
              state={pekerjaanAyah}
              setState={setPekerjaanAyah}
            />

            <InputGroup
              label="Telepon Ayah"
              type="text"
              id="teleponAyah"
              state={teleponAyah}
              setState={setTeleponAyah}
            />
            <InputGroup
              label="Alamat Orang Tua"
              type="text"
              id="alamatOrangTua"
              state={alamatOrangTua}
              setState={setAlamatOrangTua}
            />
          </HalfSection>
          <HalfSection>
            <InputGroup
              label="Nama Ibu"
              id="namaIbu"
              type="text"
              state={namaIbu}
              setState={setNamaIbu}
            />

            <InputGroup
              label="Pekerjaan Ibu"
              id="pekerjaanIbu"
              type="text"
              state={pekerjaanIbu}
              setState={setPekerjaanIbu}
            />

            <InputGroup
              label="Telepon Ibu"
              type="text"
              id="teleponIbu"
              state={teleponIbu}
              setState={setTeleponIbu}
            />
          </HalfSection>
        </SectionWrapper>

        <h2>Form B3: Minat dan Orientasi Kerja</h2>
        <SectionWrapper variant="col">
          <TextAreaGroup
            label="1. Apa yang menjadi keinginan dan cita-cita yang ingin diraih ?"
            id="minatOne"
            state={minatOne}
            setState={setMinatOne}
          />
          <TextAreaGroup
            label="2. Sebutkan minimal 3 (tiga) kelebihan karakter/sifat diri pribadi anda !"
            id="minatTwo"
            state={minatTwo}
            setState={setMinatTwo}
          />
          <TextAreaGroup
            label="3. Sebutkan minimal 3 (tiga) kelemahan karakter/sifat diri pribadi anda !"
            id="minatThree"
            state={minatThree}
            setState={setMinatThree}
          />
          <TextAreaGroup
            label="4. Sebutkan prestasi yang sudah anda raih selama ini !"
            id="minatFour"
            state={minatFour}
            setState={setMinatFour}
          />
          <TextAreaGroup
            label="5. Sebutkan kegagalan yang pernah anda alami selama ini"
            id="minatFive"
            state={minatFive}
            setState={setMinatFive}
          />
          <TextAreaGroup
            label="6. Apa sajakah aktivitas yang menjadi prioritas bagi anda yang dilakukan secara rutin?"
            id="minatSix"
            state={minatSix}
            setState={setMinatSix}
          />
          <TextAreaGroup
            label="7. Apa sajakah aktivitas (hobby/kegemaran) yang sering anda lakukan di saat waktu luang anda?"
            id="minatSeven"
            state={minatSeven}
            setState={setMinatSeven}
          />
          <TextAreaGroup
            label="8. Ceritakan pengalaman organisasi yang pernah/ sedang Anda ikuti!"
            id="minatEight"
            state={minatEight}
            setState={setMinatEight}
          />
          <TextAreaGroup
            label="9. Sebutkan mata pelajaran yang paling Anda sukai, dan ceritakan alasannya!"
            id="minatNine"
            state={minatNine}
            setState={setMinatNine}
          />
          <TextAreaGroup
            label="10. Sebutkan mata pelajaran yang paling tidak Anda sukai, dan ceritakan alasannya!"
            id="minatTen"
            state={minatTen}
            setState={setMinatTen}
          />

          <ButtonKirim />
        </SectionWrapper>
      </DataDiriForm>
    </DataDiriContainer>
  );
};

export default connect(
  mapStateToPropsDataDiri,
  mapDispatchToPropsDataDiri
)(BiodataCalonMahasiswa);
