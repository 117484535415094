import { useState } from 'react';
import Benar from '../../../../Components/PetunjukMiddle/Benar';
import ButtonJawab from '../../../../Components/PetunjukMiddle/ButtonJawab';
import CheckboxText from '../../../../Components/PetunjukMiddle/CheckboxText';
import Footer from '../../../../Components/PetunjukMiddle/Footer';
import PetunjukMiddleContainer from '../../../../Components/PetunjukMiddle/PetunjukMiddleContainer';
import PetunjukParagraph from '../../../../Components/PetunjukMiddle/PetunjukParagraph';
import Salah from '../../../../Components/PetunjukMiddle/Salah';
import PilihanWrapper from '../../../../Components/PilihanWrapper';

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  arrPilihan,
  kunci,
  next,
  previous,
  pesanBenar,
}) => {
  const [answer, setAnswer] = useState(0);
  const [statusAnswer, setStatusAnswer] = useState(-1);

  const arrPoint = [1, 2, 4, 8];

  return (
    <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
      <PetunjukParagraph>
        {
          'Carilah dua kata yang <em>memiliki kesamaan arti yang paling dekat atau yang mempunyai arti berlawanan.</em>'
        }
      </PetunjukParagraph>

      <PilihanWrapper variant="text" col={2}>
        {arrPilihan.map((item, index) => (
          <CheckboxText
            key={index}
            label={item}
            answer={answer}
            setAnswer={setAnswer}
            point={arrPoint[index]}
            setStatus={setStatusAnswer}
          />
        ))}
        <p></p>
        <ButtonJawab
          answer={answer}
          setStatusAnswer={setStatusAnswer}
          kunci={kunci}
        />
      </PilihanWrapper>

      <Benar isShow={statusAnswer === 1} htmlString={pesanBenar} />
      <Salah isShow={statusAnswer === 0} />
      <Footer
        min={2}
        max={4}
        previous={previous}
        next={next}
        condition={statusAnswer === 1}
      />
    </PetunjukMiddleContainer>
  );
};

export default PetunjukMiddle;
