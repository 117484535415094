import { useEffect, useState } from "react";
import styles from "./CreditModal.module.css";
import topupIcon from "../../assets/dashboard/topup-icon.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { API } from "../../services/API";
import { convertToRupiah } from "../../utils/moneyConvert";
import { connect } from "react-redux";
import InvoiceModal from "../InvoiceModal";

const CreditModal = ({ isShow, onHide, clientId }) => {
  const [moduls, setModuls] = useState([]);
  const [selectedModul, setSelectedModul] = useState("select");
  const [selectedModulPrice, setSelectedModulPrice] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);

  const [isShowInvoice, setIsShowInvoice] = useState(false);
  const [creditId, setCreditId] = useState(0);

  useEffect(() => {
    const getModuls = async () => {
      try {
        const res = await API.get("/moduls");

        setModuls(res.data.modulAndTes[0]);
      } catch (error) {
        console.log(error);
      }
    };

    getModuls();
  }, []);

  const countTotalPrice = (e) => {
    setTotalCredit(e)
    selectedModul === "select" && setSelectedModulPrice(0);
    moduls?.map(
      (modul) =>
        +modul.id === +selectedModul && setSelectedModulPrice(modul?.modulH[0]?.harga * e)
    );
  };

  const requestHandler = async () => {
    try {
      const data = {
        clientId,
        modulId: selectedModul,
        tglTopUp: new Date(),
        total: totalCredit,
        terpakai: 0,
        approve: false,
        buktiTransfer: null,
        totalHarga: selectedModulPrice,
      };
      const res = await API.post("/credit", data);

      setCreditId(res.data.credit.id);

      onHide();
      setCreditId(0);
      setSelectedModul("select");
      setTotalCredit(0);
      setSelectedModulPrice(0);
      setIsShowInvoice(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isShow && (
        <>
          <div className={styles.container}>
            <div className={styles.creditWrapper}>
              <div className={styles.header}>
                <h1>Top Up Kredit</h1>
                <img src={topupIcon} alt="topup" />
              </div>

              <div className={styles.main}>
                <div className={styles.inputGroup}>
                  <div className={styles.inputWrapper}>
                    <label htmlFor="modul">Pilih Modul</label>
                    <select
                      name="modul"
                      id="modul"
                      onChange={(e) => {
                        setSelectedModul(e.target.value);
                      }}
                      value={selectedModul}
                    >
                      <option value="select">select</option>
                      {moduls?.map((modul, index) => (
                        <option key={index} value={modul.id}>
                          {modul.nama +
                            " - " +
                            convertToRupiah(modul?.modulH[0]?.harga)}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className={styles.inputWrapper}>
                    <label htmlFor="modul">Jumlah Kredit</label>
                    <input
                      type="number"
                      name="kredit"
                      id="kredit"
                      onChange={(e) => countTotalPrice(e.target.value)}
                      value={totalCredit}
                    />
                  </div>
                </div>

                {/* <div className={styles.inputGroup}>
                  <div className={styles.inputWrapper}>
                    <label htmlFor="modul">Pilih Modul</label>
                    <select name="modul" id="modul">
                      <option value="select">select</option>
                    </select>
                  </div>

                  <div className={styles.inputWrapper}>
                    <label htmlFor="modul">Jumlah Kredit</label>
                    <input type="number" name="kredit" id="kredit" />
                  </div>
                </div>

                <button className={styles.buttonTambah}>
                  <span>
                    <FontAwesomeIcon icon={faPlusCircle} />
                  </span>
                  Tambah
                </button> */}

                <h3 style={{ textAlign: "left" }}>
                  Total : {convertToRupiah(selectedModulPrice)}
                </h3>

                <div className={styles.actionWrapper}>
                  <button className={styles.cancel} onClick={() => onHide()}>
                    Batalkan
                  </button>
                  <button className={styles.request} onClick={requestHandler}>
                    Request Top Up
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.background}
            onClick={() => {
              onHide();
            }}
          ></div>
        </>
      )}
      <InvoiceModal
        isShow={isShowInvoice}
        onHide={() => setIsShowInvoice(false)}
        creditId={creditId}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    clientId: state?.user?.user?.client?.id,
  };
};

export default connect(mapStateToProps, null)(CreditModal);
