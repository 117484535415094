import { connect } from "react-redux";
import { mapStateToPropsSoal } from "../../Utils/reduxMap";
import styles from "./SoalImage.module.css";

const SoalImage = ({ pathImage, currentSoal, alt, src }) => (
  <div className={styles.gambarSoalWrapper}>
    <img
      src={src ?? `${pathImage}/${currentSoal?.soal}`}
      alt={alt}
      className={styles.gambarSoal}
      draggable={false}
    />
  </div>
);

export default connect(mapStateToPropsSoal, null)(SoalImage);
