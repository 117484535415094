import { useState } from "react";
import PetunjukMiddle from "./PetunjukMiddle";
import PetunjukContainer from "../../Components/PetunjukContainer";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer
      isModal={isModal}
      tutupModalHandler={tutupModalHandler}
      previousHandler={previousHandler}
      nextHandler={nextHandler}
      index={index}
      lastIndex={4}
    >
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        namaFile="contoh-cfit3a6.png"
        pesanBenar='Contoh diatas, pola yang TEPAT yang harus diisi dalam kotak tersebut adalah pola pada kotak "b", mengapa? Karena pada kotak b apabila disusun akan sama persis dengan kotak-kotak yang ada diatasnya. Jadi jawaban untuk contoh di atas adalah "b".'
        kunci="b"
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        namaFile="contoh-cfit3a7.png"
        pesanBenar='Jawaban yang tepat untuk contoh di atas adalah "c", dimana jika gambar kotak c ditempatkan pada kotak kosong tersebut, maka gambar tersebut serupa dengan kotak diatasnya yaitu menghadap ke kanan tetapi berwarna abu-abu seperti halnya kotak yang berada di sebelah kirinya.'
        kunci="c"
        previous={previousHandler}
        next={nextHandler}
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        namaFile="contoh-cfit3a8.png"
        pesanBenar="Pada contoh ini jawaban yang tepat adalah <strong>f</strong>."
        kunci="f"
        previous={previousHandler}
        next={nextHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
