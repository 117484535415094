import RiwayatProjectPesertaContent from "../../components/RiwayatProjectPesertaContent";
import SideBar from "../../components/SideBar";
import styles from "./RiwayatProjectPeserta.module.css";

const RiwayatProjectPeserta = () => {
  return (
    <div className={styles.container}>
      <SideBar />
      <RiwayatProjectPesertaContent />
    </div>
  );
};

export default RiwayatProjectPeserta;
