import { connect } from "react-redux";
import PetunjukContainer from "../../Containers/PetunjukContainer";
import { mapDispatchToPropsPetunjuk } from "../../Utils/reduxMap";

import styles from "./Petunjuk.module.css";

const Petunjuk = ({ changeCurrentPetunjuk, isModal, tutupModalHandler }) => {
  const tutupHandler = () => {
    const psikotesLS = JSON.parse(localStorage.getItem("psikotesTalenta"));
    psikotesLS.currentPetunjuk = 0;
    localStorage.setItem("psikotesTalenta", JSON.stringify(psikotesLS));

    changeCurrentPetunjuk(0);
  };

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <div className={styles.petunjukLast}>
        <p>
          Terdapat empat daftar pernyataan Anda diminta{" "}
          <span className={styles.warning}>
            memilih masing-masing satu pernyataan yang PALING SESUAI dengan diri
            Anda dan satu pernyataan yang PALING TIDAK SESUAI dengan diri Anda.
          </span>
        </p>
        <br />
        <p>
          Pilihan satu yang paling sesuai dan satu yang paling tidak sesuai
          dengan diri Anda adalah keharusan, artinya meski pernyataan-pernyataan
          tersebut dianggap sesuai semua dengan diri Anda maka pilih satu yang
          paling utama.
        </p>
        <br />
        <p>
          Sebaliknya jika pernyataan-pernyataan tersebut semua tidak sesuai
          dengan diri Anda maka pilih yang paling tidak sesuai.
        </p>
        <br />
        <p>
          Anda menjawab dengan memilih pilihan yang tersedia. Tidak perlu
          terlalu lama mempertimbangkan jawaban tersebut. Persoalan ini tidak
          membutuhkan waktu namun bekerjalah dengan cepat dan teliti
        </p>
        <div className={styles.footer}>
          {isModal ? (
            <button onClick={tutupModalHandler}>Tutup</button>
          ) : (
            <button onClick={tutupHandler}>Tutup</button>
          )}
        </div>
      </div>
    </PetunjukContainer>
  );
};

export default connect(null, mapDispatchToPropsPetunjuk)(Petunjuk);
