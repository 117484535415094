import PetunjukZero from "../../../../Containers/PetunjukZero";

const PetunjukZeroCfitThreeA = ({ selectedIndex, next }) => {
  return (
    <PetunjukZero selectedIndex={selectedIndex}>
      <p>
        Perhatikan pada tab contoh untuk memahami soal yang akan Anda kerjakan.
      </p>
      <br />
      <p>
        Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan
        contoh soal sebelum mengerjakan.
      </p>
      <br />
      <button onClick={next}>Lanjut</button>
    </PetunjukZero>
  );
};

export default PetunjukZeroCfitThreeA;
