import styles from "./Card.module.css";

const Card = ({ title, number, iconBgColor, children }) => {
  return (
    <div className={styles.card}>
      <p className={styles.cardTitle}>{title}</p>
      <div className={styles.cardBody}>
        <div
          className={styles.cardLogo}
          style={{ backgroundColor: iconBgColor || "salmon" }}
        >
          {children}
        </div>
        <p className={styles.cardNumber}>{number}</p>
      </div>
    </div>
  );
};

export default Card;
