import styles from './PetunjukHeader.module.css';

const PetunjukHeader = ({ isModal, onClose }) => (
  <header className={styles.header} style={isModal && { marginBottom: '20px' }}>
    <h1>Petunjuk</h1>
    <div className={styles.closeModalButton}>
      {isModal && <p onClick={onClose}>+</p>}
    </div>
  </header>
);

export default PetunjukHeader;
