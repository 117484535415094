import { useState } from "react";
import Footer from "../../../../../Components/PetunjukMiddle/Footer";
import PilihanWrapper from "../../../../../Components/PilihanWrapper";
import Benar from "../../../../../Components/PetunjukMiddle/Benar";
import Salah from "../../../../../Components/PetunjukMiddle/Salah";
import PetunjukMiddleContainer from "../../../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import PetunjukParagraph from "../../../../../Components/PetunjukMiddle/PetunjukParagraph";
import ButtonJawab from "../../../../../Components/PetunjukMiddle/ButtonJawab";
import CheckboxGambar from "../../../../../Components/PetunjukMiddle/CheckboxGambar";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  kunci,
  next,
  previous,
  pesanBenar,
  arrPilihan,
}) => {
  const [answer, setAnswer] = useState(0);
  const [statusAnswer, setStatusAnswer] = useState(-1);

  const arrPoint = [1, 2, 4, 8, 16];
  const arrAbjad = ["A", "B", "C", "D", "E"];

  return (
    <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
      <PetunjukParagraph>
        {
          "Terdapat 5 kotak masing-masing dengan 5 pola. <strong> Terdapat dua (2) pola yang memiliki kecenderungan berbeda</strong> dibandingkan dengan lainnya (3 kotak yang lain). <strong>Pilih kedua jawaban tersebut</strong> dengan menekan alternatif jawaban a, b, c, d atau e."
        }
      </PetunjukParagraph>

      <PilihanWrapper col={5}>
        {arrPilihan.map((item, index) => (
          <CheckboxGambar
            key={index}
            label={item}
            answer={answer}
            setAnswer={setAnswer}
            point={arrPoint[index]}
            abjad={arrAbjad[index]}
            setStatus={setStatusAnswer}
            imagePath="/images/tes/cfit/cfit3b/cfit3b2/petunjuk"
            alt="Pilihan CFIT-3B-2"
          />
        ))}
      </PilihanWrapper>
      <br />
      <div style={{ width: "400px", display: "flex" }}>
        <ButtonJawab
          answer={answer}
          setStatusAnswer={setStatusAnswer}
          kunci={kunci}
        />
      </div>

      <Benar
        isShow={statusAnswer === 1}
        htmlString={pesanBenar}
        textAlign="justify"
      />
      <Salah isShow={statusAnswer === 0} />
      <Footer
        min={2}
        max={5}
        previous={previous}
        next={next}
        condition={statusAnswer === 1}
      />
    </PetunjukMiddleContainer>
  );
};

export default PetunjukMiddle;
