import { useState } from 'react';
import PetunjukLast from '../../../Components/PetunjukLast';
import PetunjukContainer from '../../../Containers/PetunjukContainer';
import styles from './Petunjuk.module.css';
import Footer from '../../../Components/PetunjukMiddle/Footer';

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <div
        className={styles.petunjukZero}
        style={{ display: index === 0 ? 'flex' : 'none' }}
      >
        <p>
          Anda adalah Evan Amulia, seorang karyawan yang telah menduduki posisi
          sebagai manager operasional selama 6 bulan di sebuah organisasi swasta
          yang bergerak di bidang distribusi, bernama Talenta Indonesia Jaya. PT
          Talenta Indonesia Jaya adalah perusahaan distribusi makanan dan
          minuman dingin terbesar di Indonesia, dan importir terkemuka produk
          makanan beku, dingin, kering, serta susu dan minuman. PT. Talenta
          Indonesia Jaya merupakan perusahaan yang bergerak di bidang distribusi
          produk dari PT. Crystal Cold Storage.
        </p>

        <div className={styles.buttonWrapper}>
          <button onClick={nextHandler}>Tutup</button>
        </div>
      </div>

      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 1 ? 'flex' : 'none' }}
      >
        <p>
          Hari ini, Senin 5 Januari adalah hari pertama Anda kembali masuk kerja
          setelah 3 hari mengambil cuti untuk liburan bersama keluarga. Pada
          meja kerja Anda tersusun rapi beberapa memo dan dokumen kerja yang
          menunggu untuk direspon:
        </p>
        <p>Terdapat tiga tugas yang Anda harus lakukan:</p>
        <ol>
          <li>
            Memberikan prioritas terhadap semua memo / dokumen kerja yang masuk,
            kedalam tiga kategori:
            <ul>
              <li>High Priority</li>
              <li>Moderate Priority</li>
              <li>Low Priority</li>
            </ul>
          </li>
          <li>
            Menuliskan secara spesifik Rencana Tindakan yang akan dilakukan
            untuk merespon setiap memo dan dokumen kerja.
          </li>
          <li>
            Menulis rencana kerja Program Pengembangan Staff. Detil mengenai
            tugas ini disajikan pada memo terakhir atau memo no.9
          </li>
        </ol>

        <p>
          <b>
            Waktu keseluruhan yang Anda perlukan untuk melakukan semua tugas
            tersebut adalah 60 menit.
          </b>
        </p>
        <Footer previous={previousHandler} next={nextHandler} condition />
      </div>

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={2}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
