import { useEffect } from "react";
import { connect } from "react-redux";
import Petunjuk from "./Petunjuk";
import Soal from "./Soal";
import { generateEmptyJawabans } from "../Utils/";
import { mapStateToPropsTes, mapDispatchToPropsTes } from "../Utils/reduxMap";

const CTwo = ({ currentPetunjuk, currentTes, replaceJawabans }) => {
  useEffect(() => generateEmptyJawabans(replaceJawabans), [replaceJawabans]);

  return (
    <>
      {currentPetunjuk === 23 && <Petunjuk />}
      {currentPetunjuk !== 23 && currentTes.tesId === 23 && <Soal />}
    </>
  );
};

export default connect(mapStateToPropsTes, mapDispatchToPropsTes)(CTwo);
