import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";
import styles from "./NavItem.module.css";

const NavItem = ({ href, icon, title, setPath }) => {
  const history = useHistory();
  const path = history.location.pathname;
  return (
    <div
      className={styles.navItem}
      onClick={() => {
        history.push(href);
        setPath(href);
      }}
      style={
        path === href
          ? {
              backgroundColor: "#fff",
              color: "#0772AE",
            }
          : null
      }
    >
      <span>
        <FontAwesomeIcon
          icon={icon}
          color={path === href ? "#0772AE" : "#fff"}
        />
      </span>
      <p>{title}</p>
    </div>
  );
};

export default NavItem;
