import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukMiddle from "./PetunjukMiddle";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const pilihanOne = ["Menikah", "Menebang", "Kawin", "Meneduh"];
  const pilihanTwo = ["Selesai", "Tua", "Usia", "Muda"];
  const pilihanThree = ["Jenaka", "Kecil", "Canggung", "Lucu"];

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum mengerjakan.
        </p>
        <br />
        <p style={{ color: "red" }}>
          Jangan ditutup dulu petunjuk ini, perhatikan contoh, Apabila petunjuk
          ini ditutup maka tes dimulai dan waktu berjalan.
        </p>

        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        arrPilihan={pilihanOne}
        kunci={5}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Pada contoh diatas tampak bahwa kata <em>Menikah dan Kawin</em> memiliki kesamaan arti, oleh karena Anda memilih Menikah dan Kawin agar jawabannya benar"
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        arrPilihan={pilihanTwo}
        kunci={10}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban yang tepat adalah <em>Tua dan Muda</em> karena memiliki arti yang berlawanan satu sama lain."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        arrPilihan={pilihanThree}
        kunci={9}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban yang tepat adalah <em>Jenaka dan Lucu</em> karena memiliki kesamaan arti satu sama lain."
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={4}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
