import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihanOne = [
    "A. Rumput kering",
    "B. Makanan ternak",
    "C. Rumput",
    "D. Hijau",
    "E. Padang rumput",
  ];
  const arrPilihanTwo = [
    "A. hujan",
    "B. hari",
    "C. kering",
    "D. angin",
    "E. lembab",
  ];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Ditentukan tiga kata, hubungan kata ketiga dengan pilihan yang ada
          harus memiliki kesamaan hubungan dengan dua kata sebelumnya.
        </p>
        <br />
        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="Hutan : Pepohonan = Padang rumput : ?"
        arrPilihan={arrPilihanOne}
        kunci="C. Rumput"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Hubungan hutan dan pohon adalah hutan terdiri dari pohon-pohon, maka padang rumput terdiri dari rumput, jawaban yang tepat adalah <strong>C</strong>."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="Gelap : terang = basah : ?"
        arrPilihan={arrPilihanTwo}
        kunci="C. kering"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Lawan kata gelap adalah terang maka lawan kata dari basah adalah kering (jawaban C). Maka jawaban <strong>C</strong> yang benar."
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
