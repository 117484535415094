import React from 'react';
import { isObjectEmpty } from 'utility/Utils';

const Column = ({ data }) => {
  console.log({ data });

  if (isObjectEmpty(data)) {
    return (
      <React.Fragment>
        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>-</td>
        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>-</td>
        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>-</td>
        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>-</td>
        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>-</td>
        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>-</td>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {Object.entries(data).map(([key, value]) => {
        return (
          <td key={key} style={{ verticalAlign: 'middle', textAlign: value ? 'left' : 'center' }}>
            {value || '-'}
          </td>
        );
      })}
    </React.Fragment>
  );
};

export default Column;
