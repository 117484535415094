import { useEffect } from "react";
import { connect } from "react-redux";
import {
  mapDispatchToPropsTes,
  mapStateToPropsTes,
} from "../../Utils/reduxMap";
import { generateEmptyJawabans } from "../../Utils";
import Petunjuk from "./Petunjuk";
import Soal from "./Soal";

const HollandEgatek = ({ currentPetunjuk, currentTes, replaceJawabans }) => {
  useEffect(() => {
    generateEmptyJawabans(replaceJawabans);
  }, [replaceJawabans]);
  return (
    <>
      {currentPetunjuk === 92 && <Petunjuk />}
      {currentPetunjuk !== 92 && currentTes.tesId === 92 && <Soal />}
    </>
  );
};

export default connect(
  mapStateToPropsTes,
  mapDispatchToPropsTes
)(HollandEgatek);
