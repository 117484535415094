import { useState } from 'react';
import { connect } from 'react-redux';
import InputGroup from '../Components/DataDiri/InputGroup';
import SelectGroup from '../Components/DataDiri/SelectGroup';
import { mapDispatchToPropsDataDiri } from '../Utils/reduxMap';
import ButtonKirim from '../Components/DataDiri/ButtonKirim';
import DataDiriForm from '../Components/DataDiri/DataDiriForm';
import DataDiriContainer from '../Components/DataDiri/DataDiriContainer';
import SectionWrapper from '../Components/DataDiri/SectionWrapper';
import HalfSection from '../Components/DataDiri/HalfSection';
import { API } from '../../../services/API';
import DataDiriTitle from '../Components/DataDiri/DataDiriTitle';
import SoalContainer from '../Containers/SoalContainer';

const DataDiriSekolahTwo = ({
  changeCurrentPetunjuk,
  changeCurrentSoal,
  changeCurrentTes,
}) => {
  //peserta
  const [nama, setNama] = useState('');
  const [tanggalLahir, setTanggalLahir] = useState('');
  const [nomorPendaftaran, setNomorPendaftaran] = useState('');
  const [gender, setGender] = useState('');
  const [asalSekolah, setAsalSekolah] = useState('');
  const [jurusanOne, setJurusanOne] = useState('');
  const [jurusanTwo, setJurusanTwo] = useState('');
  const [tempatLahir, setTempatLahir] = useState('');
  const [noHp, setNoHp] = useState('');
  const [isShowSelesaiModal, setIsShowSelesaiModal] = useState(false);

  //Orang Tua
  const [namaIbu, setNamaIbu] = useState('');
  const [namaAyah, setNamaAyah] = useState('');
  const [nomorWaliOne, setNomorWaliOne] = useState('');
  const [nomorWaliTwo, setNomorWaliTwo] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    submitToAPI();
  };

  const submitToAPI = async () => {
    const data = {
      nama,
      tglLahir: tanggalLahir,
      gender,
      tmptLahir: tempatLahir,
      telepon: noHp,
      dataSekolah_2: {
        peserta: {
          nama,
          tanggalLahir,
          nomorPendaftaran,
          gender,
          asalSekolah,
          jurusanOne,
          jurusanTwo,
          tempatLahir,
          noHp,
        },
        orangTua: { namaIbu, namaAyah, nomorWaliOne, nomorWaliTwo },
      },
    };

    try {
      // console.log(data);
      // Upload to API
      const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
      await API.patch(`/peserta/${psikotesLS.peserta.id}`, data);

      // nextTesHandlerUtil(
      //   changeCurrentTes,
      //   changeCurrentSoal,
      //   changeCurrentPetunjuk
      // );
      setIsShowSelesaiModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DataDiriContainer>
      <SoalContainer
        isShowSelesaiModal={isShowSelesaiModal}
        setIsShowSelesaiModal={setIsShowSelesaiModal}
      >
        <DataDiriTitle title="DATA PESERTA TES" />

        <h2>A. Identitas Diri</h2>
        <DataDiriForm onSubmit={handleSubmit}>
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="Asal Sekolah"
                id="asalSekolah"
                type="text"
                state={asalSekolah}
                setState={setAsalSekolah}
              />
              <InputGroup
                label="Nomor Pendaftaran"
                id="nomorPendaftaran"
                type="text"
                state={nomorPendaftaran}
                setState={setNomorPendaftaran}
              />
              <InputGroup
                label="Nama"
                id="nama"
                type="text"
                state={nama}
                setState={setNama}
              />

              <SelectGroup
                label="Jenis Kelamin"
                state={gender}
                setState={setGender}
              >
                <option value=""></option>
                <option value="L">Laki-laki</option>
                <option value="P">Perempuan</option>
              </SelectGroup>
              <InputGroup
                label="No. HP"
                id="noHp"
                type="text"
                state={noHp}
                setState={setNoHp}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Tempat Lahir"
                id="tempatLahir"
                type="text"
                state={tempatLahir}
                setState={setTempatLahir}
              />
              <InputGroup
                label="Tanggal Lahir"
                id="tanggal-lahir"
                type="date"
                state={tanggalLahir}
                setState={setTanggalLahir}
              />
              <InputGroup
                label="Jurusan 1 yang Anda Inginkan"
                id="jurusanOne"
                type="text"
                state={jurusanOne}
                setState={setJurusanOne}
              />
              <InputGroup
                label="Jurusan 2 yang Anda Inginkan"
                id="jurusanTwo"
                type="text"
                state={jurusanTwo}
                setState={setJurusanTwo}
              />
            </HalfSection>
          </SectionWrapper>
          <h2>B. Identitas Orang Tua</h2>
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="Nama Ayah"
                id="namaAyah"
                type="text"
                state={namaAyah}
                setState={setNamaAyah}
              />
              <InputGroup
                label="Nomor Telepon Wali"
                id="nomorWaliOne"
                type="text"
                state={nomorWaliOne}
                setState={setNomorWaliOne}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Nama Ibu"
                id="namaIbu"
                type="text"
                state={namaIbu}
                setState={setNamaIbu}
              />
              <InputGroup
                label="Nomor Telepon Wali"
                id="nomorWaliTwo"
                type="text"
                state={nomorWaliTwo}
                setState={setNomorWaliTwo}
              />
            </HalfSection>
          </SectionWrapper>

          <ButtonKirim />
        </DataDiriForm>
      </SoalContainer>
    </DataDiriContainer>
  );
};

export default connect(null, mapDispatchToPropsDataDiri)(DataDiriSekolahTwo);
