import RoleTambahContent from "../../components/RoleTambahContent";
import SideBar from "../../components/SideBar";
import styles from "./RoleTambahPage.module.css";

const RoleTambahPage = () => {
  return (
    <div className={styles.container}>
      <SideBar />
      <RoleTambahContent />
    </div>
  );
};

export default RoleTambahPage;
