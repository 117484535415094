import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import styles from "./Petunjuk.module.css";
import Footer from "../../../Components/PetunjukMiddle/Footer";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <div
        className={styles.petunjukZero}
        style={{ display: index === 0 ? "flex" : "none" }}
      >
        <p>
          Pada persoalan berikut, Anda akan menghadapi beberapa kasus yang
          terdiri dari memo/ dokumen kerja. Tugas Anda adalah membuat respon
          atas memo/ dokumen tersebut dan membagi permasalahan-permasalahan
          tersebut ke dalam 3 kategori :
        </p>
        <ol>
          <b>
            <li>High Priority</li>
          </b>
          <b>
            <li>Moderate Priority</li>
          </b>
          <b>
            <li>Low Priority</li>
          </b>
        </ol>

        <p>
          Anda bebas untuk mengerjakan dokumen mana yang akan Anda selesaikan
          terlebih dahulu. Yang terpenting berikan kategori pada setiap
          permasalahan dan tuliskan urutan permasalahan menurut masalah yang
          paling prioritas untuk diselesaikan.
        </p>

        <p>
          Tulislah respon Anda untuk setiap permasalahan tadi pada kolom
          jawaban, setelah yakin tekan <strong>Lanjut</strong> untuk mengisi
          jawabannya
        </p>

        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>

        <p>
          <font color="red">
            Jangan ditutup terlebih dulu petunjuk ini, baca dan pahami
            instruksi. Apabila petunjuk ini ditutup maka tes dimulai dan waktu
            berjalan.
          </font>
        </p>

        <div className={styles.buttonWrapper}>
          <button onClick={nextHandler}>Tutup</button>
        </div>
      </div>

      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 1 ? "flex" : "none" }}
      >
        <p>
        Anda Prima Kurniawan pelaksana tugas yang mengisi jabatan Direktur Pelaksana Pendidikan dan Pelatihan.
        Sejak 3 hari yang lalu Anda mendapat penugasan terkait program kerja sama diklat yang dilakukan
        secara offline di luar daerah. Penugasan Anda terkait dengan pengembangan diklat secara digital yang
        berkerja sama dengan beberapa pihak eksternal.
        </p>
        <Footer previous={previousHandler} next={nextHandler} condition />
      </div>
      
      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 2 ? "flex" : "none" }}
      >
        <p>
        Hari ini adalah hari Minggu 10 Juli 2022 pada pukul 09.00,
        saat ini Anda di kantor untuk menyelesaikan pekerjaan yang sempat tertunda
        karena penugasan Anda di luar daerah 3 hari yang lalu. Kemudian pada siang hari ini pukul 13.00,
        Anda harus berangkat ke luar kota untuk melakukan symposium selama 1 minggu penuh
        dan baru akan kembali di tanggal 18 Juli 2022. Pada e-mail dan dihadapan meja kerja Anda terdapat
        beberapa dokumen/surat/nota yang harus Anda tuntaskan sebelum Anda berangkat. Tugas Anda ialah mengambil
        tindakan atas setiap surat/dokumen/memo. Anda tidak dapat berkomunikasi dengan bawahan ataupun rekan kerja
        Anda saat ini dikarekan hari ini adalah hari libur. Anda juga tidak diperkenankan untuk menunjuk pejabat sementara,
        sehingga Anda sendiri yang harus memberikan tanggapan atupun instruksi kepada pihak-pihak terkait.
        Anda diharapkan dapat menuliskan tindakan yang akan diambil secara spesifik pada setiap surat atau memo yang tersedia. 
        </p>
        <p>Waktu keseluruhan yang Anda perlukan untuk melakukan semua tugas tersebut adalah 60 menit.</p>
        <Footer previous={previousHandler} next={nextHandler} condition />
      </div>

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
