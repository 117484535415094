import * as actionTypes from './peserta-types';

const INITIAL_STATE = {
  currentTes: false,
  currentSoal: false,
  currentPetunjuk: false,
  peserta: false,
  jawabans: false,
  photos: false,
};

const pesertaReducer = (state = INITIAL_STATE, action) => {
  // console.log("pay", action.payload);
  switch (action.type) {
    case actionTypes.PESERTA_LOGIN:
      return {
        ...state,
        peserta: action.payload.peserta,
        currentTes: action.payload.currentTes,
        currentSoal: action.payload.currentSoal,
        currentPetunjuk: action.payload.currentPetunjuk,
        jawabans: action.payload.jawabans,
        photos: action.payload.photos,
      };
    case actionTypes.CHANGE_CURRENT_TES:
      return {
        ...state,
        currentTes: action.payload.currentTes,
      };
    case actionTypes.CHANGE_CURRENT_SOAL:
      return {
        ...state,
        currentSoal: action.payload.currentSoal,
      };
    case actionTypes.CHANGE_CURRENT_PETUNJUK:
      return {
        ...state,
        currentPetunjuk: action.payload.currentPetunjuk,
      };
    case actionTypes.REPLACE_JAWABANS:
      return {
        ...state,
        jawabans: action.payload.jawabans,
      };
    case actionTypes.ADD_PHOTO:
      return {
        ...state,
        photos: action.payload.photos,
      };

    default:
      // console.log("yo", action.type);
      return state;
  }
};

export default pesertaReducer;
