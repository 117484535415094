import styles from "./SectionWrapper.module.css";

const SectionWrapper = ({ children, variant = "row" }) => (
  <div
    className={
      variant === "row" ? styles.sectionWrapper : styles.sectionColWrapper
    }
  >
    {children}
  </div>
);

export default SectionWrapper;
