import { useState } from 'react';
import { connect } from 'react-redux';
// import { API } from "../../../services/API";
import TextAreaGroup from '../Components/DataDiri/TextAreaGroup';
import DataDiriContainer from '../Components/DataDiri/DataDiriContainer';
import DataDiriForm from '../Components/DataDiri/DataDiriForm';
import SectionWrapper from '../Components/DataDiri/SectionWrapper';
import HalfSection from '../Components/DataDiri/HalfSection';
import ButtonKirim from '../Components/DataDiri/ButtonKirim';

import InputGroup from '../Components/DataDiri/InputGroup';
import SelectGroup from '../Components/DataDiri/SelectGroup';
import {
  mapDispatchToPropsDataDiri,
  mapStateToPropsDataDiri,
} from '../Utils/reduxMap';
import DataDiriTitle from '../Components/DataDiri/DataDiriTitle';
import { API } from '../../../services/API';
import SoalContainer from '../Containers/SoalContainer';

const DataDiriSekolah = ({
  currentPeserta,
  changeCurrentPetunjuk,
  changeCurrentSoal,
  changeCurrentTes,
}) => {
  //peserta
  const [nama, setNama] = useState('');
  const [pendidikan, setPendidikan] = useState('');
  const [tanggalLahir, setTanggalLahir] = useState('');
  const [kelas, setKelas] = useState('');
  const [sekolah, setSekolah] = useState('');
  const [usia, setUsia] = useState('');
  const [alamat, setAlamat] = useState('');
  const [gender, setGender] = useState('');
  const [jurusan, setJurusan] = useState('');

  //Orang Tua
  const [namaIbu, setNamaIbu] = useState('');
  const [alamatIbu, setAlamatIbu] = useState('');
  const [teleponIbu, setTeleponIbu] = useState('');
  const [pekerjaanIbu, setPekerjaanIbu] = useState('');
  const [namaAyah, setNamaAyah] = useState('');
  const [alamatAyah, setAlamatAyah] = useState('');
  const [teleponAyah, setTeleponAyah] = useState('');
  const [pekerjaanAyah, setPekerjaanAyah] = useState('');

  //Mata pelajaran
  const [ipa, setIpa] = useState('');
  const [ips, setIps] = useState('');
  const [bahasa, setBahasa] = useState('');
  const [matematika, setMatematika] = useState('');
  const [sejarah, setSejarah] = useState('');
  const [seniBudaya, setSeniBudaya] = useState('');
  const [pendidikanAgama, setPendidikanAgama] = useState('');
  const [penjasorkers, setPenjasorkers] = useState('');

  //pekerjaan
  const [ahliPertanian, setAhliPertanian] = useState('');
  const [insinyur, setInsinyur] = useState('');
  const [akuntan, setAkuntan] = useState('');
  const [ilmuwan, setIlmuwan] = useState('');
  const [marketing, setMarketing] = useState('');
  const [seniman, setSeniman] = useState('');
  const [penulis, setPenulis] = useState('');
  const [musical, setMusical] = useState('');
  const [guru, setGuru] = useState('');
  const [karyawanBank, setKaryawanBank] = useState('');
  const [tukang, setTukang] = useState('');
  const [dokter, setDokter] = useState('');

  //Hobby dan musik
  const [hobby, setHobby] = useState('');
  const [musik, setMusik] = useState('');

  //Bacaan
  const [novel, setNovel] = useState('');
  const [komik, setKomik] = useState('');
  const [majalah, setMajalah] = useState('');
  const [suratKabar, setSuratKabar] = useState('');
  const [makalah, setMakalah] = useState('');

  //Perspektif
  const [perspektifOne, setPerspektifOne] = useState('');
  const [perspektifTwo, setPerspektifTwo] = useState('');
  const [perspektifThree, setPerspektifThree] = useState('');
  const [perspektifFour, setPerspektifFour] = useState('');
  const [perspektifFive, setPerspektifFive] = useState('');
  const [perspektifSix, setPerspektifSix] = useState('');
  const [perspektifSeven, setPerspektifSeven] = useState('');

  const [isShowSelesaiModal, setIsShowSelesaiModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    submitToAPI();
  };

  const submitToAPI = async () => {
    const data = {
      nama,
      pendidikan,
      tglLahir: tanggalLahir,
      gender,
      jurusan,
      dataDiriSekolah: {
        peserta: {
          nama,
          pendidikan,
          tanggalLahir,
          kelas,
          sekolah,
          usia,
          alamat,
          gender,
          jurusan,
        },
        orangTua: {
          namaIbu,
          alamatIbu,
          teleponIbu,
          pekerjaanIbu,
          namaAyah,
          alamatAyah,
          teleponAyah,
          pekerjaanAyah,
        },
        matpel: {
          ipa,
          ips,
          bahasa,
          matematika,
          sejarah,
          seniBudaya,
          pendidikanAgama,
          penjasorkers,
        },
        pekerjaan: {
          ahliPertanian,
          insinyur,
          akuntan,
          ilmuwan,
          marketing,
          seniman,
          penulis,
          musical,
          guru,
          karyawanBank,
          tukang,
          dokter,
        },
        hobbyDanMusik: {
          hobby,
          musik,
        },
        bacaan: {
          novel,
          komik,
          majalah,
          suratKabar,
          makalah,
        },
        perspektif: {
          perspektifOne,
          perspektifTwo,
          perspektifThree,
          perspektifFour,
          perspektifFive,
          perspektifSix,
          perspektifSeven,
        },
      },
    };

    try {
      // console.log(data);
      // Upload to API
      const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
      await API.patch(`/peserta/${psikotesLS.peserta.id}`, data);

      setIsShowSelesaiModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const inputStyle = {
    outline: 'none',
    padding: '0 20px',
    marginTop: '18px',
    marginBottom: '32px',
    width: '100%',
    height: '56.51px',
    background: '#ffffff',
    border: '0.842709px solid #ffffff',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(8, 131, 201, 0.22)',
    borderRadius: '7.39049px',
  };

  return (
    <DataDiriContainer>
      <SoalContainer
        isShowSelesaiModal={isShowSelesaiModal}
        setIsShowSelesaiModal={setIsShowSelesaiModal}
      >
        <DataDiriTitle title="DATA PESERTA TES" />

        <input
          type="text"
          id="kodetes"
          value={currentPeserta?.kode}
          style={inputStyle}
          readOnly
        />
        <h2>A. Identitas Diri</h2>
        <DataDiriForm onSubmit={handleSubmit}>
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="Nama"
                id="nama"
                type="text"
                state={nama}
                setState={setNama}
              />

              <SelectGroup
                label="Jenis Kelamin"
                state={gender}
                setState={setGender}
              >
                <option value=""></option>
                <option value="L">Laki-laki</option>
                <option value="P">Perempuan</option>
              </SelectGroup>

              <SelectGroup
                label="Pendidikan"
                state={pendidikan}
                setState={setPendidikan}
              >
                <option value=""></option>
                <option value="SD">SD</option>
                <option value="SMP">SMP</option>
                <option value="SMA">SMA</option>
                <option value="SMK">SMK</option>
                <option value="Perguruan Tinggi">Perguruan Tinggi</option>
              </SelectGroup>

              <InputGroup
                label="Kelas / Semester"
                id="kelas"
                type="text"
                state={kelas}
                setState={setKelas}
              />

              <SelectGroup
                label="Jurusan"
                state={jurusan}
                setState={setJurusan}
              >
                <option value=""></option>
                <option value="IPA">IPA</option>
                <option value="IPS">IPS</option>
                <option value="Bahasa">Bahasa</option>
                <option value="Lain-lain">Lain-lain</option>
              </SelectGroup>
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Sekolah"
                id="sekolah"
                type="text"
                state={sekolah}
                setState={setSekolah}
              />

              <InputGroup
                label="Tanggal Lahir"
                id="tanggal-lahir"
                type="date"
                state={tanggalLahir}
                setState={setTanggalLahir}
              />

              <InputGroup
                label="Usia"
                type="number"
                id="usia"
                state={usia}
                setState={setUsia}
              />
              <InputGroup
                label="Alamat"
                id="alamat"
                type="text"
                state={alamat}
                setState={setAlamat}
              />
              {/* <button type="submit">Kirim</button> */}
            </HalfSection>
          </SectionWrapper>
          <h2>B. Identitas Orang Tua</h2>
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="Nama Ayah"
                id="namaAyah"
                type="text"
                state={namaAyah}
                setState={setNamaAyah}
              />

              <InputGroup
                label="Alamat Ayah"
                id="alamatAyah"
                type="text"
                state={alamatAyah}
                setState={setAlamatAyah}
              />

              <InputGroup
                label="Telepon Ayah"
                type="text"
                id="teleponAyah"
                state={teleponAyah}
                setState={setTeleponAyah}
              />
              <InputGroup
                label="Pekerjaan Ayah"
                id="pekerjaanAyah"
                type="text"
                state={pekerjaanAyah}
                setState={setPekerjaanAyah}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Nama Ibu"
                id="namaIbu"
                type="text"
                state={namaIbu}
                setState={setNamaIbu}
              />

              <InputGroup
                label="Alamat Ibu"
                id="alamatIbu"
                type="text"
                state={alamatIbu}
                setState={setAlamatIbu}
              />

              <InputGroup
                label="Telepon Ibu"
                type="text"
                id="teleponIbu"
                state={teleponIbu}
                setState={setTeleponIbu}
              />
              <InputGroup
                label="Pekerjaan Ibu"
                id="pekerjaanIbu"
                type="text"
                state={pekerjaanIbu}
                setState={setPekerjaanIbu}
              />
            </HalfSection>
          </SectionWrapper>
          <h2>C. Self Assessment</h2>
          <br />
          <h3>Minat Diri</h3>
          <p>
            Bidang Studi Yang Diminati : Berikan nomor secara berurutan mulai
            dari jenis bidang studi yang paling anda minati sampai dengan jenis
            bidang studi yang kurang anda minati (contoh: no.1 untuk jenis
            bidang studi yang paling Anda minati sampai dengan no.8 untuk jenis
            bidang studi yang paling kurang Anda minati).
          </p>
          <br />

          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="IPA"
                id="ipa"
                type="number"
                state={ipa}
                setState={setIpa}
              />

              <InputGroup
                label="IPS"
                id="ips"
                type="number"
                state={ips}
                setState={setIps}
              />

              <InputGroup
                label="Bahasa"
                type="number"
                id="bahasa"
                state={bahasa}
                setState={setBahasa}
              />
              <InputGroup
                label="Matematika"
                id="matematika"
                type="number"
                state={matematika}
                setState={setMatematika}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Sejarah"
                id="sejarah"
                type="number"
                state={sejarah}
                setState={setSejarah}
              />

              <InputGroup
                label="Seni Budaya"
                id="seniBudaya"
                type="number"
                state={seniBudaya}
                setState={setSeniBudaya}
              />

              <InputGroup
                label="Pendidkan Agama"
                type="number"
                id="pendidikanAgama"
                state={pendidikanAgama}
                setState={setPendidikanAgama}
              />
              <InputGroup
                label="Penjasorkers"
                id="penjasorkers"
                type="number"
                state={penjasorkers}
                setState={setPenjasorkers}
              />
            </HalfSection>
          </SectionWrapper>
          <br />
          <p>
            Pekerjaan yang Diminati: Berikan nomor secara berurutan mulai dari
            jenis bidang pekerjaan yang paling anda minati sampai dengan jenis
            bidang pekerjaan yang kurang anda minati (contoh: no.1 untuk jenis
            bidang pekerjaan yang paling Anda minati sampai dengan no.12 untuk
            jenis bidang pekerjaan yang paling kurang Anda minati).
          </p>
          <br />
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="Ahli Pertanian"
                id="ahliPertanian"
                type="number"
                state={ahliPertanian}
                setState={setAhliPertanian}
              />

              <InputGroup
                label="Insinyur"
                id="insinyur"
                type="number"
                state={insinyur}
                setState={setInsinyur}
              />

              <InputGroup
                label="Akuntan/Auditor"
                type="number"
                id="akuntan"
                state={akuntan}
                setState={setAkuntan}
              />
              <InputGroup
                label="Ilmuwan/peneliti"
                id="ilmuwan"
                type="number"
                state={ilmuwan}
                setState={setIlmuwan}
              />
              <InputGroup
                label="Marketing"
                id="marketing"
                type="number"
                state={marketing}
                setState={setMarketing}
              />
              <InputGroup
                label="Seniman"
                id="seniman"
                type="number"
                state={seniman}
                setState={setSeniman}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Penulis/Wartawan"
                type="number"
                id="penulis"
                state={penulis}
                setState={setPenulis}
              />
              <InputGroup
                label="Musical/Pianis"
                id="musical"
                type="number"
                state={musical}
                setState={setMusical}
              />
              <InputGroup
                label="Guru/Dosen/Pendidik"
                id="guru"
                type="number"
                state={guru}
                setState={setGuru}
              />
              <InputGroup
                label="Karyawan Bank/Sekertaris"
                id="karyawanBank"
                type="number"
                state={karyawanBank}
                setState={setKaryawanBank}
              />
              <InputGroup
                label="Tukang/Ahli Bangunan"
                id="tukang"
                type="number"
                state={tukang}
                setState={setTukang}
              />
              <InputGroup
                label="Dokter"
                id="dokter"
                type="number"
                state={dokter}
                setState={setDokter}
              />
            </HalfSection>
          </SectionWrapper>

          <h3>Hobby dan Musik</h3>
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="Kegiatan/Hobby yang Digemari"
                id="hobby"
                type="text"
                state={hobby}
                setState={setHobby}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Musik yang Dinikmati"
                id="musik"
                type="text"
                state={musik}
                setState={setMusik}
              />
            </HalfSection>
          </SectionWrapper>

          <br />
          <p>
            Buku yang Diminati: Berikan nomor secara berurutan mulai dari jenis
            buku yang paling anda minati sampai dengan jenis buku yang kurang
            anda minati (contoh: no.1 untuk jenis buku yang paling Anda minati
            sampai dengan no.5 untuk jenis buku yang paling kurang Anda minati).
          </p>
          <br />
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="Majalah"
                id="majalah"
                type="number"
                state={majalah}
                setState={setMajalah}
              />

              <InputGroup
                label="Surat Kabar"
                id="suratKabar"
                type="number"
                state={suratKabar}
                setState={setSuratKabar}
              />

              <InputGroup
                label="Novel"
                type="number"
                id="novel"
                state={novel}
                setState={setNovel}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Komik"
                id="komik"
                type="number"
                state={komik}
                setState={setKomik}
              />
              <InputGroup
                label="Buku Pelajaran/Makalah/Jurnal"
                id="makalah"
                type="number"
                state={makalah}
                setState={setMakalah}
              />
            </HalfSection>
          </SectionWrapper>

          <h3>Perspektif Diri</h3>
          <SectionWrapper variant="col">
            <TextAreaGroup
              label="1.Sebutkan keinginan dan cita-cita yang ingin di raih ?"
              id="perspektifOne"
              state={perspektifOne}
              setState={setPerspektifOne}
            />
            <TextAreaGroup
              label="2. Sebutkan minimal 3 (tiga) karakter/sifat positif diri  anda !"
              id="perspektifTwo"
              state={perspektifTwo}
              setState={setPerspektifTwo}
            />
            <TextAreaGroup
              label="3. Sebutkan minimal 3 (tiga) karakter/sifat negatif diri  anda !"
              id="perspektifThree"
              state={perspektifThree}
              setState={setPerspektifThree}
            />
            <TextAreaGroup
              label="4. Sebutkan prestasi yang pernah diraih selama ini !"
              id="perspektifFour"
              state={perspektifFour}
              setState={setPerspektifFour}
            />
            <TextAreaGroup
              label="5. Sebutkan kegagalan yang  pernah anda alami selama ini"
              id="perspektifFive"
              state={perspektifFive}
              setState={setPerspektifFive}
            />
            <TextAreaGroup
              label="6. Sebutkan aktivitas rutin  yang menjadi prioritas bagi anda ?"
              id="perspektifSix"
              state={perspektifSix}
              setState={setPerspektifSix}
            />
            <TextAreaGroup
              label="7. Apa sajakah aktivitas (hobby/kegemaran) yang sering Anda lakukan disaat waktu luang Anda ?"
              id="perspektifSeven"
              state={perspektifSeven}
              setState={setPerspektifSeven}
            />

            <ButtonKirim />
          </SectionWrapper>
        </DataDiriForm>
      </SoalContainer>
    </DataDiriContainer>
  );
};

export default connect(
  mapStateToPropsDataDiri,
  mapDispatchToPropsDataDiri
)(DataDiriSekolah);
