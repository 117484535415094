import { useState } from "react";
import Benar from "../../../../Components/PetunjukMiddle/Benar";
import Footer from "../../../../Components/PetunjukMiddle/Footer";
import PetunjukMiddleContainer from "../../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import Salah from "../../../../Components/PetunjukMiddle/Salah";
import PilihanText from "../../../../Components/PilihanText";
import PilihanWrapper from "../../../../Components/PilihanWrapper";
import SoalImage from "../../../../Components/SoalImage";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  namaFile,
  kunci,
  next,
  previous,
  arrPilihan,
  pesanBenar,
}) => {
  const [answer, setAnswer] = useState("");

  return (
    <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
      <br />
      <SoalImage src={`/images/tes/gatb/gatb2/${namaFile}`} alt="Contoh Soal" />

      <PilihanWrapper col={2} variant="text">
        {arrPilihan?.map((item, index) => (
          <PilihanText
            label={item}
            onClick={() => setAnswer(item)}
            answer={answer}
            key={index}
          />
        ))}
      </PilihanWrapper>
      <Benar isShow={answer === kunci} htmlString={pesanBenar} />
      <Salah isShow={answer !== kunci && answer !== ""} />
      <Footer
        min={1}
        max={5}
        previous={previous}
        next={next}
        condition={answer === kunci}
      />
    </PetunjukMiddleContainer>
  );
};

export default PetunjukMiddle;
