import Footer from "../../../../../Components/PetunjukMiddle/Footer";
import Benar from "../../../../../Components/PetunjukMiddle/Benar";
import PetunjukMiddleContainer from "../../../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import PetunjukParagraph from "../../../../../Components/PetunjukMiddle/PetunjukParagraph";
import SoalImage from "../../../../../Components/SoalImage";
import PilihanWrapper from "../../../../../Components/PilihanWrapper";
import PilihanText from "../../../../../Components/PilihanText";

import { useState } from "react";
import Salah from "../../../../../Components/PetunjukMiddle/Salah";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  pesanBenar,
  namaFile,
  previous,
  next,
  kunci,
}) => {
  const [answer, setAnswer] = useState("");
  const arrPilihan = ["a", "b", "c", "d", "e"];

  return (
    <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
      <PetunjukParagraph>
        Terdapat suatu pola dengan titik didalamnya. Tugas anda adalah
        menentukan pola yang cenderung sama / identik sehingga jika pola
        tersebut diberikan titik akan memiliki kemiripan dengan pola contoh.
        Alternatif pola tersebut terdapat pada kotak a, b, c, d atau e.
      </PetunjukParagraph>
      <SoalImage
        src={`/images/tes/cfit/cfit3a/cfit3a4/petunjuk/${namaFile}`}
        alt="Contoh"
      />
      <PilihanWrapper variant="text" col={2}>
        {arrPilihan?.map((item, index) => (
          <PilihanText
            label={item}
            onClick={() => setAnswer(item)}
            answer={answer}
            key={index}
          />
        ))}
      </PilihanWrapper>

      <Benar isShow={answer === kunci} htmlString={pesanBenar} />
      <Salah isShow={answer !== kunci && answer !== ""} />
      <Footer
        min={1}
        max={5}
        previous={previous}
        next={next}
        condition={answer === kunci}
      />
    </PetunjukMiddleContainer>
  );
};

export default PetunjukMiddle;
