import styles from "./InvoiceModal.module.css";
import picturePlus from "../../assets/invoice/picture-plus.svg";
import talentaLogo from "../../assets/img/brand-logo.svg";
import { useEffect, useState } from "react";
import { API } from "../../services/API";
import { convertToRupiah } from "../../utils/moneyConvert";

const InvoiceModal = ({ isShow, onHide, creditId }) => {
  const [credit, setCredit] = useState({});
  const [file, setFile] = useState(false);
  const [fileName, setFileName] = useState(false);

  useEffect(() => {
    const getCredit = async () => {
      try {
        if (creditId) {
          const res = await API.get(`/credit/${creditId}`);
          setCredit(res.data.credit);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getCredit();
  }, [creditId]);

  const submitHandler = async () => {
    try {
      console.log(file);
      var bodyForm = new FormData();
      bodyForm.append("buktiTransfer", file);

      const res = await API({
        method: "PATCH",
        url: `/credit/${credit?.id}`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: bodyForm,
      });

      onHide();
      setCredit({});
      setFile(false);
      setFileName(false);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isShow && (
        <>
          <div className={styles.container}>
            <div className={styles.invoiceWrapper}>
              <div className={styles.main}>
                <div className={styles.leftMain}>
                  <h1>Transfer pembayaran ke rekening</h1>
                  <p className={styles.talentaName}>PT. Talenta Indonesia</p>
                  <p className={styles.rekening}>32XXXXXXXX</p>
                  <p className={styles.bankName}>Bank Central Asia</p>
                  {credit?.buktiTransfer ? (
                    credit?.approve ? (
                      <>
                        <p className={styles.responAdmin}>
                          Pembayaran sudah selesai
                        </p>
                        <button className={styles.buktiTransferButton}>
                          <a
                            href={credit?.buktiTransfer}
                            rel="noreferrer"
                            target="_blank"
                          >
                            Lihat Bukti Transfer
                          </a>
                        </button>
                      </>
                    ) : (
                      <>
                        <p className={styles.responAdmin}>
                          Menunggu respon admin
                        </p>
                        <button className={styles.buktiTransferButton}>
                          <a
                            href={credit?.buktiTransfer}
                            rel="noreferrer"
                            target="_blank"
                          >
                            Lihat Bukti Transfer
                          </a>
                        </button>
                      </>
                    )
                  ) : (
                    <>
                      <p className={styles.uploadTitle}>
                        Upload Bukti Pembayaran
                      </p>

                      <input
                        type="file"
                        id="buktiTransfer"
                        onChange={(e) => {
                          setFileName(e?.target?.files[0]?.name);
                          setFile(e.target.files[0]);
                        }}
                        onClick={() => {
                          setFile(false);
                          setFileName(false);
                        }}
                      />
                      <label htmlFor="buktiTransfer">
                        <img
                          src={picturePlus}
                          alt="upload bukti transfer"
                          width="16"
                          height="16"
                        />
                        {fileName ? fileName : "Click to browse"}
                      </label>
                      <button onClick={submitHandler}>Upload</button>
                    </>
                  )}
                </div>

                <div className={styles.rightMain}>
                  <h2>Total Pembayaran</h2>
                  <p className={styles.price}>
                    {convertToRupiah(credit?.totalHarga)}
                  </p>

                  <div className={styles.invoicePaper}>
                    <img src={talentaLogo} alt="talenta" height="46" />

                    <div className={styles.paperHeader}>
                      <div className={styles.leftPaperHeader}>
                        <p className={styles.talenta}>PT. Talenta Indonesia</p>
                        <p className={styles.alamat}>
                          4140 Parker Rd. Allentown, New Mexico 31134
                        </p>
                      </div>
                      <p
                        className={styles.invoiceId}
                      >{`Invoice #${credit.id}`}</p>
                    </div>

                    <table className={styles.paperBody}>
                      <thead>
                        <tr>
                          <th style={{ width: "50%" }}>Modul</th>
                          <th style={{ textAlign: "center", width: "25%" }}>
                            Kredit
                          </th>
                          <th>Harga</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{credit?.modul?.nama}</td>
                          <td style={{ textAlign: "center" }}>
                            {credit?.total}
                          </td>
                          <td>
                            {convertToRupiah(credit?.modul?.modulH[0]?.harga)}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div className={styles.paperFooter}>
                      <p className={styles.title}>Total</p>
                      <p>{convertToRupiah(credit?.totalHarga)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.background}
            onClick={(e) => {
              e.stopPropagation();
              onHide();
            }}
          ></div>
        </>
      )}
    </>
  );
};

export default InvoiceModal;
