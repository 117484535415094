import styles from "./Input.module.css";

const Input = ({ state, setState, label, name, type }) => {
  return (
    <div className={styles.inputWrapper}>
      <label htmlFor={name}>{label}</label>
      <input
        type={type}
        value={state}
        onChange={(e) => setState(e.target.value)}
        id={name}
        name={name}
      />
    </div>
  );
};

export default Input;
