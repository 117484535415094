import styles from "./Right.module.css";

const Right = () => {
  return (
    <div className={styles.right}>
      <p className={styles.resultTitle}>Result GTQ</p>
      <h1 className={styles.result}>87</h1>
      <p className={styles.resultStatus}>Dibawah rata-rata</p>
    </div>
  );
};

export default Right;
