import { useEffect } from 'react';
import { connect } from 'react-redux';
import { generateEmptyJawabans } from '../../Utils';
import {
  mapDispatchToPropsTes,
  mapStateToPropsTes,
} from '../../Utils/reduxMap';
import Petunjuk from '../Components/Petunjuk';
import Soal from './Soal';

const AKPresentasi = ({ currentPetunjuk, currentTes, replaceJawabans }) => {
  useEffect(() => {
    generateEmptyJawabans(replaceJawabans, 'Tuliskan analisa Anda di sini.');
  }, [replaceJawabans]);

  return (
    <>
      {currentPetunjuk === 134 && <Petunjuk menit={60} />}
      {currentPetunjuk !== 134 && currentTes.tesId === 134 && <Soal />}
    </>
  );
};

export default connect(mapStateToPropsTes, mapDispatchToPropsTes)(AKPresentasi);
