import { faEdit, faPowerOff, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";
import { API } from "../../../../../services/API";
import styles from "./Dropdown.module.css";

const Dropdown = ({ isShow, onHide, getUsers, userId, userStatus }) => {
  const history = useHistory();

  const nonaktifHandler = async () => {
    try {
      await API.patch(`/user/${userId}`, {
        isActive: false,
      });

      getUsers();
    } catch (error) {
      console.log(error);
    }
  };

  const aktifHandler = async () => {
    try {
      await API.patch(`/user/${userId}`, {
        isActive: true,
      });

      getUsers();
    } catch (error) {
      console.log(error);
    }
  };

  const hapusHandler = async () => {
    try {
      await API.delete(`/user/${userId}`);

      getUsers();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.wrapper}>
      {isShow && (
        <>
          <div className={styles.dropdownWrapper}>
            <div className={styles.dropdownMenuWrapper}>
              {userStatus === "Aktif" && (
                <div className={styles.menuText} onClick={nonaktifHandler}>
                  <FontAwesomeIcon icon={faPowerOff} />
                  <p>Nonaktif</p>
                </div>
              )}

              {userStatus === "Nonaktif" && (
                <div className={styles.menuText} onClick={aktifHandler}>
                  <FontAwesomeIcon icon={faPowerOff} />
                  <p>Aktifkan</p>
                </div>
              )}
            </div>
            <div className={styles.dropdownMenuWrapper}>
              <div
                className={styles.menuText}
                onClick={() => history.push(`/role-setting/edit/${userId}`)}
              >
                <FontAwesomeIcon icon={faEdit} />
                <p>Edit</p>
              </div>
            </div>
            <div className={styles.dropdownMenuWrapper}>
              <div className={styles.menuText} onClick={hapusHandler}>
                <FontAwesomeIcon icon={faTrash} />
                <p>Hapus</p>
              </div>
            </div>
          </div>

          <div
            className={styles.background}
            onClick={() => {
              onHide();
            }}
          ></div>
        </>
      )}
    </div>
  );
};

export default Dropdown;
