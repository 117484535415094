import styles from './Table.module.css';
import cn from 'classnames';
import { matchSorter } from 'match-sorter';
import { forwardRef, useEffect, useMemo, useRef } from 'react';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import Pagination from './Pagination';
import TableHeader from './TableHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClock } from '@fortawesome/free-solid-svg-icons';
import UnduhPDF1 from './UnduhPDF1';
import UnduhPDF2 from './UnduhPDF2';
import Actions from './Actions';

export const StatusComponent = ({ color, title, icon }) => (
  <p
    style={{
      fontWeight: 'bold',
      color: color,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '76px',
      margin: '0 auto',
    }}
  >
    <span>
      <FontAwesomeIcon icon={icon} />
    </span>
    {title}
  </p>
);

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  // console.log({ rest });
  if (rest?.status !== 2) {
    return (
      <>
        {/* <input
          type="checkbox"
          ref={resolvedRef}
          {...rest}
          className={styles.checkboxRow}
        /> */}
        <input
          class="form-check-input"
          type="checkbox"
          aria-label="..."
          ref={resolvedRef}
          {...rest}
        />
      </>
    );
  } else {
    return <div ref={resolvedRef} {...rest}></div>;
  }
});

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function Table({ columns, data, onDeletePeserta, onEditPeserta }) {
  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  let {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: { pageSize: 4, hiddenColumns: ['id'] },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => {
            return (
              <div>
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps()}
                  status={row.values?.status}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    }
  );

  // useEffect(() => {
  // const selected = {
  //   selectedRowIds,
  //   selectedFlatRowsOriginal: selectedFlatRows.map((d) => d.original),
  // };
  // console.log(selected);
  // }, [selectedRowIds, selectedFlatRows]);
  // console.log(page);
  // Render the UI for your table

  selectedFlatRows = selectedFlatRows
    .map((row) => row.original)
    .filter((e) => e?.status !== 2);
  return (
    <>
      <TableHeader
        pageSize={pageSize}
        setPageSize={setPageSize}
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilterState={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
        rowsSelected={selectedFlatRows}
      />

      <table {...getTableProps()} className={styles.table}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className={styles.tableHeader}
            >
              {headerGroup.headers.map((column, index) => {
                if (index === 1) {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={styles.headerName}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </th>
                  );
                }

                if (index === 6) {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{ width: '140px' }}
                    >
                      {column.Header}
                    </th>
                  );
                }
                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={styles.rowItem}>
                {row.cells.map((cell, index) => {
                  // console.log(cell);
                  switch (index) {
                    case 1:
                      const nameAndEmail = cell.value.split(',,,');
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={cn(styles.cellItem, styles.cellName)}
                        >
                          <div>
                            <strong>{nameAndEmail[0]}</strong>
                            <p>{nameAndEmail[1]}</p>
                          </div>
                        </td>
                      );
                    case 2:
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={styles.cellItem}
                        >
                          {cell.row.values.status === null && (
                            <StatusComponent
                              title="Belum"
                              color="#FF6F5B"
                              icon={faClock}
                            />
                          )}
                          {cell.row.values.status === 1 && (
                            <StatusComponent
                              title="Proses"
                              color="#FF9F40"
                              icon={faClock}
                            />
                          )}
                          {cell.row.values.status === 2 && (
                            <StatusComponent
                              title="Selesai"
                              color="#5BCF5F"
                              icon={faCheckCircle}
                            />
                          )}
                        </td>
                      );
                    case 3:
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={styles.cellItem}
                        >
                          <button className={styles.buttonCell}>
                            {cell.render('Cell')}
                          </button>
                        </td>
                      );
                    case 4:
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={styles.cellItem}
                        >
                          <UnduhPDF1 cell={cell} row={row} />
                        </td>
                      );
                    case 5:
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={styles.cellItem}
                        >
                          <UnduhPDF2 cell={cell} row={row} />
                        </td>
                      );
                    case 6:
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={styles.cellItem}
                        >
                          <Actions
                            dataPeserta={cell.row.original}
                            onClickEditPeserta={onEditPeserta}
                            onClickDeletePeserta={onDeletePeserta}
                          />
                        </td>
                      );
                    default:
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={styles.cellItem}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <Pagination
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
      />
    </>
  );
}

export default Table;
