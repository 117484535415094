import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./SectionTwo.module.css";

const SectionTwo = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <h1>Hasil</h1>
          <h1>Pemeriksaan Psikologis</h1>
        </div>

        <div className={styles.fileIconWrapper}>
          <FontAwesomeIcon icon={faFileAlt} size="2x" />
        </div>
      </div>

      <table className={styles.table}>Disini tablenya</table>
    </div>
  );
};

export default SectionTwo;
