import { connect } from "react-redux";
import { useEffect, useState } from "react";
import {
  changeCurrentSoal,
  replaceJawabans,
} from "../../../../../redux/Peserta/peserta-actions";
import SoalHeader from "../../../Components/SoalHeader";
import PetunjukModal from "../../../Components/PetunjukModal";
import Petunjuk from "../Petunjuk";
import ButtonSoal from "../../../Components/ButtonSoal";
import Navigation from "../../../Components/Navigation";
import { setAnswerLS, setJawabansLS, setSisaWaktuLS } from "../../../Utils";
import SoalContainer from "../../../Containers/SoalContainer";
import SoalContent from "../../../Containers/SoalContent";
import { mapStateToPropsSoal } from "../../../Utils/reduxMap";
import SoalText from "../../../Components/SoalText";
import InputWrapper from "../../../Components/InputWrapper";

const Soal = ({
  currentSoal,
  currentTes,
  replaceJawabans,
  changeCurrentSoal,
}) => {
  const [answer, setAnswer] = useState("");
  const [sisaWaktu, setSisaWaktu] = useState(-1);
  const [isShowSelesaiModal, setIsShowSelesaiModal] = useState(false);
  const [isShowPetunjukModal, setIsShowPetunjukModal] = useState(false);

  useEffect(() => {
    setSisaWaktuLS(currentTes?.tes?.waktu, setSisaWaktu);
  }, [currentTes?.tes?.waktu]);

  useEffect(() => {
    setAnswer("");
    setAnswerLS(currentSoal, setAnswer);
  }, [currentSoal]);

  useEffect(() => {
    setJawabansLS(answer, replaceJawabans);
  }, [answer, replaceJawabans]);

  const nextSoalHandler = () => {
    const psikotesLS = JSON.parse(localStorage.getItem("psikotesTalenta"));
    psikotesLS.currentSoal =
      psikotesLS.currentTes.tes.soals[currentSoal.nomor + 1 - 1];
    localStorage.setItem("psikotesTalenta", JSON.stringify(psikotesLS));

    changeCurrentSoal(psikotesLS.currentSoal);
  };

  const enterHandler = (e) =>
    e.key === "Enter" &&
    (currentTes?.tes?.soals?.length === currentSoal.nomor
      ? setIsShowSelesaiModal(true)
      : nextSoalHandler());

  return (
    <SoalContainer
      isShowSelesaiModal={isShowSelesaiModal}
      setIsShowSelesaiModal={setIsShowSelesaiModal}
    >
      <SoalHeader
        setIsShowPetunjukModal={setIsShowPetunjukModal}
        sisaWaktu={sisaWaktu}
      />
      <SoalContent>
        <SoalText />

        <InputWrapper>
          <input
            type="number"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            placeholder="Ketikkan jawaban di sini"
            onKeyDown={enterHandler}
          />
        </InputWrapper>

        <ButtonSoal setIsShowSelesaiModal={setIsShowSelesaiModal} />
        <Navigation />
      </SoalContent>

      <PetunjukModal
        isShow={isShowPetunjukModal}
        onHide={() => setIsShowPetunjukModal(false)}
      >
        <Petunjuk
          isModal
          tutupModalHandler={() => setIsShowPetunjukModal(false)}
        />
      </PetunjukModal>
    </SoalContainer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  replaceJawabans: (jawabans) => dispatch(replaceJawabans(jawabans)),
  changeCurrentSoal: (currentSoal) => dispatch(changeCurrentSoal(currentSoal)),
});

export default connect(mapStateToPropsSoal, mapDispatchToProps)(Soal);
