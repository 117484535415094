import React from 'react';

const HeaderSecond = () => {
  return (
    <React.Fragment>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        NO
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        TANGGAL TES
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        KODE AKTIVASI
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        NAMA
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        USIA
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        JENIS KELAMIN
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        PENDIDIKAN
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        TEMPAT LAHIR
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        TANGGAL LAHIR
      </th>
    </React.Fragment>
  );
};

export default HeaderSecond;
