import { API, setAuthToken } from '../../services/API';
import * as actionTypes from './user-types';

export const userLogin = (token) => {
  return (dispatch) => {
    setAuthToken(token);
    API.get('/user/profile')
      .then((res) => {
        // console.log("res.data.data", res.data.data.admin);
        dispatch({
          type: actionTypes.LOGIN,
          payload: {
            user: res.data.data,
            isAdmin: res.data.data.admin === 1 ? true : false,
            isClientSekolah: res.data.data.admin === 2 ? true : false,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        setAuthToken();
        localStorage.removeItem('talentaToken');
        window.location.reload();
      });
  };
};

export const userLogout = () => {
  return (dispatch) => {
    // Membersihkan token
    setAuthToken();
    localStorage.removeItem('talentaToken');
    dispatch({
      type: actionTypes.LOGOUT,
    });
  };
};

export const searchProject = (projectName) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SEARCH_PROJECT,
      payload: {
        projectName,
      },
    });
  };
};

export const changeListCountProject = (listCountProject = 25) => {
  return {
    type: actionTypes.CHANGE_ORDER_LIST_COUNT,
    payload: { listCountProject },
  };
};
