import { useState } from 'react';
import { connect } from 'react-redux';
import { API } from '../../../services/API';
import DataDiriContainer from '../Components/DataDiri/DataDiriContainer';
import DataDiriTitle from '../Components/DataDiri/DataDiriTitle';
import DataDiriForm from '../Components/DataDiri/DataDiriForm';
import SectionWrapper from '../Components/DataDiri/SectionWrapper';
import HalfSection from '../Components/DataDiri/HalfSection';
import ButtonKirim from '../Components/DataDiri/ButtonKirim';
import InputGroup from '../Components/DataDiri/InputGroup';
import SelectGroup from '../Components/DataDiri/SelectGroup';
import { mapDispatchToPropsDataDiri } from '../Utils/reduxMap';
import SoalContainer from '../Containers/SoalContainer';
const DataDiriSekolahSingkat = ({
  changeCurrentPetunjuk,
  changeCurrentSoal,
  changeCurrentTes,
}) => {
  const [kodetes, setKodetes] = useState('');

  //peserta
  const [nama, setNama] = useState('');
  const [tanggalLahir, setTanggalLahir] = useState('');
  const [usia, setUsia] = useState('');
  const [gender, setGender] = useState('');
  const [asalSekolah, setAsalSekolah] = useState('');
  const [asalKelas, setAsalKelas] = useState('');
  const [tempatLahir, setTempatLahir] = useState('');
  const [noHp, setNoHp] = useState('');

  //Orang Tua
  const [namaIbu, setNamaIbu] = useState('');
  const [namaAyah, setNamaAyah] = useState('');
  const [nomorDarurat, setNomorDarurat] = useState('');

  const [isShowSelesaiModal, setIsShowSelesaiModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    submitToAPI();
  };

  const submitToAPI = async () => {
    const data = {
      nama,
      tglLahir: tanggalLahir,
      telp: noHp,
      gender,
      tmptLahir: tempatLahir,
      dataSekolahSingkat: {
        top: {
          kodetes,
        },
        peserta: {
          nama,
          tanggalLahir,
          usia,
          gender,
          asalSekolah,
          asalKelas,
          tempatLahir,
          noHp,
        },
        orangTua: {
          namaIbu,
          namaAyah,
          nomorDarurat,
        },
      },
    };

    try {
      // console.log(data);
      // Upload to API
      const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
      await API.patch(`/peserta/${psikotesLS.peserta.id}`, data);

      // nextTesHandlerUtil(
      //   changeCurrentTes,
      //   changeCurrentSoal,
      //   changeCurrentPetunjuk
      // );

      setIsShowSelesaiModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DataDiriContainer>
      <SoalContainer
        isShowSelesaiModal={isShowSelesaiModal}
        setIsShowSelesaiModal={setIsShowSelesaiModal}
      >
        <DataDiriTitle title="DATA PESERTA TES" />

        <InputGroup
          label="Nomor Tes *(Isi terserah jika tidak ada)"
          id="kodetes"
          type="text"
          state={kodetes}
          setState={setKodetes}
        />
        <h2>A. Identitas Diri</h2>
        <DataDiriForm onSubmit={handleSubmit}>
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="Nama"
                id="nama"
                type="text"
                state={nama}
                setState={setNama}
              />

              <SelectGroup
                label="Jenis Kelamin"
                state={gender}
                setState={setGender}
              >
                <option value=""></option>
                <option value="L">Laki-laki</option>
                <option value="P">Perempuan</option>
              </SelectGroup>

              <InputGroup
                label="Asal Sekolah"
                id="asalSekolah"
                type="text"
                state={asalSekolah}
                setState={setAsalSekolah}
              />

              <InputGroup
                label="Asal Kelas"
                id="asalKelas"
                type="text"
                state={asalKelas}
                setState={setAsalKelas}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Tempat Lahir"
                id="tempatLahir"
                type="text"
                state={tempatLahir}
                setState={setTempatLahir}
              />
              <InputGroup
                label="Tanggal Lahir"
                id="tanggal-lahir"
                type="date"
                state={tanggalLahir}
                setState={setTanggalLahir}
              />

              <InputGroup
                label="Usia"
                type="number"
                id="usia"
                state={usia}
                setState={setUsia}
              />
              <InputGroup
                label="No. HP"
                id="noHp"
                type="text"
                state={noHp}
                setState={setNoHp}
              />
            </HalfSection>
          </SectionWrapper>
          <h2>B. Identitas Orang Tua</h2>
          <SectionWrapper>
            <HalfSection>
              <InputGroup
                label="Nama Ayah"
                id="namaAyah"
                type="text"
                state={namaAyah}
                setState={setNamaAyah}
              />
              <InputGroup
                label="Nomor Telepon Darurat"
                id="nomorDarurat"
                type="text"
                state={nomorDarurat}
                setState={setNomorDarurat}
              />
            </HalfSection>
            <HalfSection>
              <InputGroup
                label="Nama Ibu"
                id="namaIbu"
                type="text"
                state={namaIbu}
                setState={setNamaIbu}
              />
            </HalfSection>
          </SectionWrapper>

          <ButtonKirim />
        </DataDiriForm>
      </SoalContainer>
    </DataDiriContainer>
  );
};

export default connect(
  null,
  mapDispatchToPropsDataDiri
)(DataDiriSekolahSingkat);
