import { connect } from "react-redux";
import styles from "./NomorSoalTitle.module.css";

const NomorSoalTitle = ({ currentSoal }) => (
  <p className={styles.nomorTitle}>Soal Nomor {currentSoal?.nomor}</p>
);

const mapStateToProps = (state) => ({
  currentSoal: state.peserta?.currentSoal,
});

export default connect(mapStateToProps, null)(NomorSoalTitle);
