import { useEffect } from 'react';
import { connect } from 'react-redux';
import { generateEmptyJawabans } from '../../Utils';
import {
  mapDispatchToPropsTes,
  mapStateToPropsTes,
} from '../../Utils/reduxMap';
import Petunjuk from './Petunjuk';
import Soal from './Soal';

const TikiMThree = ({ currentPetunjuk, currentTes, replaceJawabans }) => {
  useEffect(() => {
    generateEmptyJawabans(replaceJawabans, []);
  }, [replaceJawabans]);
  return (
    <>
      {currentPetunjuk === 125 && <Petunjuk />}
      {currentPetunjuk !== 125 && currentTes.tesId === 125 && <Soal />}
    </>
  );
};

export default connect(mapStateToPropsTes, mapDispatchToPropsTes)(TikiMThree);
