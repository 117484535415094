import styles from "./Right.module.css";

const Right = () => {
  return (
    <div className={styles.right}>
      <h1 className={styles.title}>Keterangan</h1>

      <div className={styles.data}>
        <p className={styles.dataTitle}>Rendah : 0 - 80.9</p>
        <div className={styles.dataBar} style={{ background: "#ff6e6a" }} />
      </div>

      <div className={styles.data}>
        <p className={styles.dataTitle}>Menengah : 90 - 109.9</p>
        <div className={styles.dataBar} style={{ background: "#FCA621" }} />
      </div>

      <div className={styles.data}>
        <p className={styles.dataTitle}>Tinggi : 110 - Batas Maksimal</p>
        <div className={styles.dataBar} style={{ background: "#5F7DD7" }} />
      </div>
    </div>
  );
};

export default Right;
