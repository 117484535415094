import { useHistory, useParams } from "react-router";
import styles from "./SectionTwo.module.css";

const SectionTwo = () => {
  const { id: projectId } = useParams();
  const history = useHistory();

  return (
    <div className={styles.sectionTwo}>
      <div className={styles.filter}>
        <p>
          <strong>Filter : </strong>Tingkat Kecocokan
        </p>

        <div className={styles.filterBody}>
          <div className={styles.filterBar}>filter bar</div>
          <button className={styles.kalkulasiButton}>Kalkulasi Hasil</button>
        </div>
      </div>

      <div className={styles.buttons}>
        <button>Skor Peserta</button>
        <button
          onClick={() =>
            history.push(`/project/detail/${projectId}/daftar-kredensial`)
          }
        >
          Daftar Kredensial
        </button>
      </div>
    </div>
  );
};

export default SectionTwo;
