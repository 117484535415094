import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihanOne = ["Kucing", "Bajing", "Keledai", "Lembu", "Anjing"];
  const arrPilihanTwo = ["Duka", "Putus Asa", "Sengsara", "Cinta", "Benci"];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>Soal terdiri atas kalimat-kalimat.</p>
        <br />
        <p>
          Pada setiap kalimat satu kata hilang dan disediakan 5 (lima) kata
          pilihan sebagai penggantinya.
        </p>
        <br />
        <p>Pilihlah kata yang tepat yang dapat menyempurnakan kalimat itu !</p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="Kuda memiliki kesamaan terbanyak dengan seekor..."
        arrPilihan={arrPilihanOne}
        kunci="Keledai"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban Anda benar! <strong>Keledai</strong>, dimana kuda memiliki persamaan paling banyak dengan keledai."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal='Lawannya <strong>"Harapan"</strong> ialah'
        arrPilihan={arrPilihanTwo}
        kunci="Putus Asa"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban Anda benar! Lawan dari Harapan adalah <strong>Putus Asa</strong>."
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
