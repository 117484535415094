import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import pesertaEnter from '../../../../../assets/project/detail/enter-peserta.svg';
import styles from './Peserta.module.css';
import React, { useEffect, useState } from 'react';
import { API } from '../../../../../services/API';
// import Modal from '../../../../Modal';
import { connect } from 'react-redux';

const Peserta = ({ peserta, project, isAdmin }) => {
  const [credit, setCredit] = useState({});
  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const content = 'Maaf anda belum bisa menambahkan peserta pada project ini, silahkan lakukan pembayaran. jika sudah melakukan pembayaran silahkan chat admin.';
  // console.log("ini  dari peserta");
  // console.log(project);

  // console.log(project.nama)
  useEffect(() => {
    const getCredit = async () => {
      try {
        const res = await API.get(
          `/credits/total?clientId=${project.clientId}&modulId=${project.modulId}`
        );
        const kredit = res.data.data;
        // console.log(kredit);
        setCredit(kredit);
      } catch (error) {
        console.log(error);
      }
    };
    getCredit();
  }, [project]);

  // const assignPeserta = peserta?.filter(
  //   (item) => item.status === 'Sudah' || item.status === 'Proses'
  // );
  const { id } = useParams();

  // const showModal = () => {
  //   setIsModalVisible(true);
  //   setInterval(()=>{setIsModalVisible(false)}, 5000);
  // }

  // console.log(peserta)

  return (
    <div className={styles.peserta}>
      {/* <Modal isVisible={isModalVisible} content={content} /> */}
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>Credit</h3>
        <p className={styles.cardContent}>{credit.total ? credit.total : 0}</p>
      </div>

      <div className={styles.card}>
        <h3 className={styles.cardTitle}>Jumlah Peserta</h3>
        <p className={styles.cardContent}>{peserta?.length}</p>
      </div>

      <div className={styles.card}>
        <div className={styles.enter}>
          {(credit.total < credit.terpakai || isAdmin) && (
            <Link to={`/project/add-peserta/${id}`}>
              <img src={pesertaEnter} alt="enter" width="100%" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: state.user.isAdmin,
    clientId: state?.user?.user?.client?.id,
  };
};
export default connect(mapStateToProps, null)(Peserta);
