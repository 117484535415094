// React
import React from 'react';

// Custom Components
import TopSection from 'components/TopSection';
import SideBar from 'components/SideBar';
import BreadcrumbSection from './BreadcrumbSection';
import TableSection from './TableSection';

const CreateAnalisaKasus = () => {
  return (
    <div className="d-flex flex-md-row flex-column bg-pattern">
      <SideBar />
      <div className="container py-5">
        <TopSection />
        <BreadcrumbSection />
        <TableSection />
      </div>
    </div>
  );
};

export default CreateAnalisaKasus;
