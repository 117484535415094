import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";
import PetunjukContainer from "../../../Containers/PetunjukContainer";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Pada soal berikut ini, Anda diminta untuk menggabungkan hitungan pada
          sisi kiri dari tanda sama dengan (=) dimana disebelah kanan tanda sama
          dengan (=) adalah hasil dari perhitungannya.
        </p>
        <br />
        <p>
          Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum mengerjakan.
        </p>
        <br />
        <p style={{ color: "red" }}>
          Jangan ditutup dulu petunjuk ini, perhatikan contoh, Apabila petunjuk
          ini ditutup maka tes dimulai dan waktu berjalan.
        </p>

        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal={["6", "2", "3 = 5"]}
        kunci={"12"}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Cara menghitung persoalan diatas adalah 6 <strong>ditambah</strong> 2 <strong>dikurangi</strong> 3 agar sama dengan 5, cara menjawab dengan memilih seperti dibawah ini."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal={["7", "2", "4 = 10"]}
        kunci={"32"}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Cara menghitung persoalan diatas adalah 7 <strong>dikali</strong> 2 <strong>dikurangi</strong> 4 agar sama dengan 10. Pilih dua hitungan dari drop down pilihan yang tersedia."
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
