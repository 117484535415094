import Peserta from "./Peserta";
import Progress from "./Progress";
import styles from "./SummaryRight.module.css";

const SummaryRight = ({ peserta, project }) => {
  // console.log("")
  return (
    <div className={styles.summaryRight}>
      <Progress peserta={peserta} />
      <Peserta peserta={peserta} project={project} />
    </div>
  );
};

export default SummaryRight;
