import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ProjectContent from '../../components/ProjectContent';
import SideBar from '../../components/SideBar';
import { API } from '../../services/API';
import styles from '../Dashboard/Dashboard.module.css';

const Project = ({ isAdmin, clientId }) => {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getProjectForAdmin = async () => {
      try {
        setIsLoading(true);
        const res = await API.get('/projects');
        if (res) {
          setProjects(res.data.data);
          setIsLoading(false);
        }
        // console.log(res.data.data);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    const getProjectForClient = async () => {
      try {
        setIsLoading(true);
        const res = await API.get(`/all-project/${clientId}`);

        if (res) {
          setIsLoading(false);
          setProjects(res.data.data);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    if (isAdmin) {
      getProjectForAdmin();
    }

    if (clientId) {
      getProjectForClient();
    }
  }, [isAdmin, clientId]);

  return (
    <div className={styles.container}>
      <SideBar />
      <ProjectContent projects={projects} isLoading={isLoading} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: state.user.isAdmin,
    clientId: state?.user?.user?.client?.id,
  };
};

export default connect(mapStateToProps, null)(Project);
