import { faFileImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useHistory } from "react-router";
import { API } from "../../services/API";
import TopSection from "../TopSection";
import Input from "./Input";
import styles from "./RoleTambahContent.module.css";
import Select from "./Select";

const RoleTambahContent = () => {
  const history = useHistory();

  //logo
  const [file, setFile] = useState(false);
  const [fileName, setFileName] = useState(false);

  // State
  const [nama, setNama] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("select");

  //client
  const [namaPerusahaan, setNamaPerusahaan] = useState("");
  const [alamatPerusahaan, setAlamatPerusahaan] = useState("");
  const [kontakPerusahaan, setKontakPerusahaan] = useState("");

  const submitHandler = async (e) => {
    try {
      e.preventDefault();

      if (role === "select") {
        return;
      }

      if (role === "admin") {
        const data = {
          email,
          pass: password,
          admin: true,
        };

        await API.post("/register", data);
      }

      if (role === "client") {
        const data = {
          email,
          pass: password,
          admin: false,
        };

        const res = await API.post("/register", data);

        // Create Client
        var bodyForm = new FormData();

        bodyForm.append("tipe", "Instansi");
        bodyForm.append("nama", namaPerusahaan);
        bodyForm.append("kontak", kontakPerusahaan);
        bodyForm.append("alamat", alamatPerusahaan);
        bodyForm.append("email", email);
        bodyForm.append("userId", res.data.client);
        bodyForm.append("kopId", 3);
        bodyForm.append("logo", file);

        await API({
          method: "POST",
          url: "/client",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: bodyForm,
        });
      }

      history.push("/role-setting");
    } catch (error) {
      console.log(error);
    }
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    history.push("/role-setting");
  };

  return (
    <div className={styles.container}>
      <TopSection />

      <div className={styles.mainSection}>
        <p className={styles.path}>
          Beranda / Role Setting<span> / Tambah akun</span>
        </p>

        <form className={styles.body}>
          <Select state={role} setState={setRole} name="role" label="Role">
            <option value="select">select</option>
            <option value="admin">Admin</option>
            <option value="client">Client</option>
          </Select>

          {role === "admin" && (
            <Input
              state={nama}
              setState={setNama}
              label="Nama"
              name="nama"
              type="text"
            />
          )}
          {role !== "select" && (
            <Input
              state={email}
              name="email"
              setState={setEmail}
              label="Email"
              type="email"
            />
          )}

          {role !== "select" && (
            <Input
              state={password}
              name="password"
              setState={setPassword}
              label="Password"
              type="password"
            />
          )}

          {role === "client" && (
            <Input
              state={namaPerusahaan}
              setState={setNamaPerusahaan}
              label="Nama Perusahaan"
              name="namaPerusahaan"
              type="text"
            />
          )}
          {role === "client" && (
            <Input
              state={alamatPerusahaan}
              setState={setAlamatPerusahaan}
              label="Alamat Perusahaan"
              name="alamatPerusahaan"
              type="text"
            />
          )}
          {role === "client" && (
            <Input
              state={kontakPerusahaan}
              setState={setKontakPerusahaan}
              label="Kontak Perusahaan"
              name="kontakPerusahaan"
              type="text"
            />
          )}
          {role === "client" && (
            <div className={styles.fileInputWrapper}>
              <label htmlFor="logo">Logo Perusahaan</label>
              <input
                type="file"
                name="logo"
                id="logo"
                onChange={(e) => {
                  setFileName(e?.target?.files[0]?.name);
                  setFile(e.target.files[0]);
                }}
                onClick={() => {
                  setFile(false);
                  setFileName(false);
                }}
              />
              <label htmlFor="logo" className={styles.labelLogo}>
                <FontAwesomeIcon icon={faFileImage} size="2x" color="#CFCFCF" />
                <p>Click to browse</p>
              </label>
              <p>{fileName}</p>
            </div>
          )}

          <div className={styles.actionWrapper}>
            <button className={styles.cancel} onClick={cancelHandler}>
              Batalkan
            </button>
            <button className={styles.simpan} onClick={submitHandler}>
              Simpan
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RoleTambahContent;
