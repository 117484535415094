import { useState } from "react";
import PetunjukLast from "../../Components/PetunjukLast";
import Footer from "../../Components/PetunjukMiddle/Footer";
import PetunjukContainer from "../../Containers/PetunjukContainer";
import PetunjukZero from "../../Containers/PetunjukZero";
import SoalImage from "../../Components/SoalImage";
import PetunjukMiddleContainer from "../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import PetunjukParagraph from "../../Components/PetunjukMiddle/PetunjukParagraph";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>Perhatikan contoh untuk memahami soal yang akan Anda kerjakan.</p>
        <br />
        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>
        <p>
          Tiap persoalan hanya terdapat satu jawaban yang dianggap benar. Waktu
          yang tersedia untuk mengerjakan tes ini 20 menit. Tes ini terdiri dari
          20 soal.
        </p>
        <br />

        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddleContainer isShow={index === 1}>
        <PetunjukParagraph>
          Terdiri dari sederetan pola gambar dalam kotak A, B, dan C. Dua kotak
          paling awal yaitu kotak A dan B menunjukkan pola tertentu, sedangkan
          pada kotak C menunjukkan pola yang identik dengan dua kotak sebelumnya
          (A dan B). Pilihlah pola yang mirip atau identik dengan kedua pola
          pada kotak A dan B dari pola pada kotak C tersebut.
        </PetunjukParagraph>
        <PetunjukParagraph>
          Perhatikan contoh 1. Pada kotak A dan B terdapat lingkaran, Pada kotak
          A lingkaran tersebut besar, dan pada kotak B lingkaran mengecil. Pada
          kotak C, pola bujur sangkar ukurannya sama. Dicari pola kotak
          selanjutnya setelah C yang menunjukkan pola yang sama antara kotak A
          dan B.
        </PetunjukParagraph>
        <PetunjukParagraph>
          Jawaban yang benar adalah no:2, karena kotak bujur sangkar mengecil
          dan mirip dengan pola pada kotak B.
        </PetunjukParagraph>

        <SoalImage src="/images/tes/tiu5/contoh_soal_tiu5.png" alt="Contoh" />

        <Footer previous={previousHandler} next={nextHandler} condition />
      </PetunjukMiddleContainer>

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={2}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
