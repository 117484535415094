import { connect } from 'react-redux';
import PetunjukContainer from '../../Containers/PetunjukContainer';
import { mapDispatchToPropsPetunjuk } from '../../Utils/reduxMap';

import styles from './Petunjuk.module.css';

const Petunjuk = ({ changeCurrentPetunjuk, isModal, tutupModalHandler }) => {
  const tutupHandler = () => {
    const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
    psikotesLS.currentPetunjuk = 0;
    localStorage.setItem('psikotesTalenta', JSON.stringify(psikotesLS));

    changeCurrentPetunjuk(0);
  };

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <div className={styles.petunjukLast}>
        <p>
          Tes ini terdiri dari beberapa pernyataan/pertanyaan yang harus Anda jawab. Terdapat 4
          pilihan jawaban, silahkan pilih jawaban yang paling sesuai dengan yang Anda rasakan/hadapi
        </p>
        <div className={styles.footer}>
          {isModal ? (
            <button onClick={tutupModalHandler}>Tutup</button>
          ) : (
            <button onClick={tutupHandler}>Tutup</button>
          )}
        </div>
      </div>
    </PetunjukContainer>
  );
};

export default connect(null, mapDispatchToPropsPetunjuk)(Petunjuk);
