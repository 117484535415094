import { useState } from 'react';
import PetunjukLast from '../../../Components/PetunjukLast';
import PetunjukContainer from '../../../Containers/PetunjukContainer';
import styles from './Petunjuk.module.css';
import Footer from '../../../Components/PetunjukMiddle/Footer';

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <div
        className={styles.petunjukZero}
        style={{ display: index === 0 ? 'flex' : 'none' }}
      >
        <p>
        Anda adalah Dika, seorang Manager IT baru pada sebuah perusahaan tambang batu bara
        bernama PT Talenta Mining. Perusahaan ini merupakan pemegang Kontrak Perjanjian
        Karya Pengusahaan Pertambangan Batubara (PKP2B) seluas Â± 20.00 Ha, yang sebagian besar
        Wilayah Perjanjiannya terletak di Kecamatan Tenggarong Seberang dan Kecamatan Manaogung, 
        Kabupaten Kutai Kartanegara. Kegiatan penambangan yang dilakukan oleh PT Talenta Mining
        yang dilakukan di wilayah Kabupaten Kutai Kartanegara, khususnya di wilayah 
        Kecamatan Manaogung sejak tahun 2015.
        </p>

        <p>
        Hari ini, Senin 10 Januari adalah hari pertama Anda kembali masuk kerja 
        setelah 3 hari menghadiri pertemuan yang dijadwalkan oleh Kantor Pusat 
        Talenta Mining. Pada meja kerja Anda tersusun rapi beberapa memo dan 
        dokumen kerja yang menunggu untuk direspon:
        </p>
        <p>Terdapat tiga tugas yang Anda harus lakukan:</p>
        <ol>
          <li>
            Memberikan perioritas terhadap semua memo / dokumen kerja yang
            masuk, kedalam tiga kategori:
            <ul>
              <li>High Priority</li>
              <li>Moderate Priority</li>
              <li>Low Priority</li>
            </ul>
          </li>
          <li>
            Menuliskan secara spesifik Rencana Tindakan yang akan dilakukan
            untuk merespon setipa memo dan dokumen kerja.
          </li>
          <li>
            Menulis rencana kerja Program Pengembangan Staff. Detil mengenai
            tugas ini disajikan pada memo terakhir atau memo no.8
          </li>
        </ol>

        <p>
          Waktu keseluruhan yang Anda perlukan untuk melakukan semua tugas
          tersebut adalah 60 menit.
        </p>

        <div className={styles.buttonWrapper}>
          <button onClick={nextHandler}>Tutup</button>
        </div>
      </div>

      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 1 ? "flex" : "none" }}
      >
        <p><b>Info Tambahan</b></p>
        <p><b>PT Talenta Mining</b></p>
        <p>
        Komunikasi bawah tanah dari Talenta Mining menggunakan <i>Mine Site Technology Underground Tracking & Communications System</i> atau dapat disingkat MST. MST digunakan dengan sistem wireless digital yang mana dapat dikombinasikan dengan VoIPtelepon genggam maupun jaringan yang lain yang berada di bawah tanah. MST dapat mengalirkan data dengan baik sehingga percakapan antar pekerja bawah tanah dengan pekerja di permukaan dapat berjalan dengan lancar, sehingga dapat mendukung grup kerjamaupun rapat kerja dengan maksimal. MST juga dapat digunakan sebagai tracking para pekerja di bawah tanah, hal ini dapat memudahkan pengawas Kesehatan dan KeselamatanKerja (K3) dapat mengawasi para pekerja dengan baik sehingga dapat meminimalisir terjadinya kecelakaan kerja. Dalam mendukung grup kerja ketika tidak berada di area tambang bawah tanah, dapatmenggunakan aplikasi telepon maupun video interface seperti biasa. Aplikasi ini dapat digunakan oleh semua orang dan semua jenis pekerjaan yang membutuhkan grup kerja tetapidengan batasan jarak yang jauh. Aplikasi yang memungkinkan dilakukannya grup kerja misalnya Microsoft Teams, Cisco Webex Meetings, Skype dan masih banyak lagi. Pemilihan aplikasi dapat melalui pertimbangan-pertimbangan seperti keperluan perusahaan, pembayaran, sewa ataupun jaringan yang memungkinkan digunakan pada daerah tersebut.
        </p>
        <Footer previous={previousHandler} next={nextHandler} condition />
      </div>

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={2}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
