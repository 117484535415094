import {
  faCheck,
  faClock,
  faUserClock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from './Card';
import styles from './Progress.module.css';

const Progress = ({ peserta }) => {
  const belumPesertaCount = peserta?.filter(
    (item) => item.stat === null
  )?.length;
  const prosesPesertaCount = peserta?.filter(
    (item) => String(item.stat) === '1'
  )?.length;
  const sudahPesertaCount = peserta?.filter(
    (item) => String(item.stat) === '2'
  )?.length;

  return (
    <div className={styles.progress}>
      <h3 className={styles.title}>Progress Pengerjaan</h3>

      <div className={styles.data}>
        <Card title="Belum" number={belumPesertaCount} iconBgColor="#FF6F5B">
          <FontAwesomeIcon icon={faClock} color="#fff" />
        </Card>

        <Card title="Proses" number={prosesPesertaCount} iconBgColor="#FF9F40">
          <FontAwesomeIcon icon={faUserClock} color="#fff" />
        </Card>

        <Card title="Selesai" number={sudahPesertaCount} iconBgColor="#5BCF5F">
          <FontAwesomeIcon icon={faCheck} color="#fff" />
        </Card>
      </div>
    </div>
  );
};

export default Progress;
