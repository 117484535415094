import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ManajemenPesertaContent from '../../components/ManajemenPesertaContent';
// import ManajemenPesertaClientContent from "../../components/ManajemenPesertaClientContent";
import styles from './ManajemenPeserta.module.css';
import SideBar from '../../components/SideBar';
import { API } from '../../services/API';

const Manajemen = ({ isAdmin, clientId }) => {
  const [peserta, setPeserta] = useState([]);

  useEffect(() => {
    const getPesertaForClient = async () => {
      try {
        const res = await API.get(`/peserta/client/${clientId}`);

        setPeserta(res.data.allPeserta);
      } catch (error) {
        console.log(error);
      }
    };

    const getPesertaForAdmin = async () => {
      try {
        const res = await API.get('/peserta');
        setPeserta(res.data.allPeserta?.reverse());
      } catch (error) {
        console.log(error);
      }
    };

    if (isAdmin) {
      getPesertaForAdmin();
    }

    if (clientId) {
      getPesertaForClient();
    }
  }, [clientId, isAdmin]);

  return (
    <div className={styles.container}>
      <SideBar />

      <ManajemenPesertaContent peserta={peserta} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: state.user.isAdmin,
    clientId: state?.user?.user?.client?.id,
  };
};

export default connect(mapStateToProps, null)(Manajemen);
