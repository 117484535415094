import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DataDiri from '../../../components/Peserta/DataDiri';
import DataRekruitmenPegawai from '../../../components/Peserta/DataRekruitmenPegawai';
import DiscProfile from '../../../components/Peserta/DiscProfile';
import IstSe from '../../../components/Peserta/Ist/Se';
import IstWa from '../../../components/Peserta/Ist/Wa';
import IstAn from '../../../components/Peserta/Ist/An';
import IstGe from '../../../components/Peserta/Ist/Ge';
import IstMe from '../../../components/Peserta/Ist/Me';
import IstRa from '../../../components/Peserta/Ist/Ra';
import IstZr from '../../../components/Peserta/Ist/Zr';
import IstFa from '../../../components/Peserta/Ist/Fa';
import IstWu from '../../../components/Peserta/Ist/Wu';
import Kostick from '../../../components/Peserta/Kostick';
import Kraepelin from '../../../components/Peserta/Kraepelin';
import Layout from '../../../components/Peserta/Layout/proctoring-revisi-1';
import TikiEight from '../../../components/Peserta/Tiki/TikiEight';
import TikiEleven from '../../../components/Peserta/Tiki/TikiEleven';
import TikiFive from '../../../components/Peserta/Tiki/TikiFive';
import TikiFour from '../../../components/Peserta/Tiki/TikiFour';
import TikiNine from '../../../components/Peserta/Tiki/TikiNine';
import TikiOne from '../../../components/Peserta/Tiki/TikiOne';
import TikiSeven from '../../../components/Peserta/Tiki/TikiSeven';
import TikiSix from '../../../components/Peserta/Tiki/TikiSix';
import TikiTen from '../../../components/Peserta/Tiki/TikiTen';
import TikiThree from '../../../components/Peserta/Tiki/TikiThree';
import TikiTwo from '../../../components/Peserta/Tiki/TikiTwo';
import TiuFive from '../../../components/Peserta/TiuFive';
import TpaOneHundredFour from '../../../components/Peserta/Tpa/TpaOneHundred/TpaOneHundredFour';
import TpaOneHundredOne from '../../../components/Peserta/Tpa/TpaOneHundred/TpaOneHundredOne';
import TpaOneHundredThree from '../../../components/Peserta/Tpa/TpaOneHundred/TpaOneHundredThree';
import TpaOneHundredTwo from '../../../components/Peserta/Tpa/TpaOneHundred/TpaOneHundredTwo';
import TpaSixtyOne from '../../../components/Peserta/Tpa/TpaSixty/TpaSixtyOne';
import TpaSixtyThree from '../../../components/Peserta/Tpa/TpaSixty/TpaSixtyThree';
import TpaSixtyTwo from '../../../components/Peserta/Tpa/TpaSixty/TpaSixtyTwo';
import Vak from '../../../components/Peserta/Vak';
import { pesertaLogin } from '../../../redux/Peserta/peserta-actions';
import Wpt from '../../../components/Peserta/Wpt';
import Logika from '../../../components/Peserta/Logika';
import Apm from '../../../components/Peserta/Apm';
import AOne from '../../../components/Peserta/AOne';
import CTwo from '../../../components/Peserta/CTwo';
import EasFour from '../../../components/Peserta/Eas/EasFour';
import EasFive from '../../../components/Peserta/Eas/EasFive';
import EasSix from '../../../components/Peserta/Eas/EasSix';
import EasSeven from '../../../components/Peserta/Eas/EasSeven';
import EasTen from '../../../components/Peserta/Eas/EasTen';
import CfitThreeAOne from '../../../components/Peserta/Cfit/CfitThreeA/CfitThreeAOne';
import CfitThreeATwo from '../../../components/Peserta/Cfit/CfitThreeA/CfitThreeATwo';
import CfitThreeAThree from '../../../components/Peserta/Cfit/CfitThreeA/CfitThreeAThree';
import CfitThreeAFour from '../../../components/Peserta/Cfit/CfitThreeA/CfitThreeAFour';
import Ret from '../../../components/Peserta/Ret';
import Msdt from '../../../components/Peserta/Msdt';
import NumerikForty from '../../../components/Peserta/NumerikForty';
import VerbalSixty from '../../../components/Peserta/VerbalSixty';
import AbstraksiTwentyFour from '../../../components/Peserta/AbstraksiTwentyFour';
import CfitThreeBOne from '../../../components/Peserta/Cfit/CfitThreeB/CfitThreeBOne';
import CfitThreeBTwo from '../../../components/Peserta/Cfit/CfitThreeB/CfitThreeBTwo';
import CfitThreeBThree from '../../../components/Peserta/Cfit/CfitThreeB/CfitThreeBThree';
import CfitThreeBFour from '../../../components/Peserta/Cfit/CfitThreeB/CfitThreeBFour';
import WptLike from '../../../components/Peserta/WptLike';
import IstrOne from '../../../components/Peserta/Istr/IstrOne';
import IstrTwo from '../../../components/Peserta/Istr/IstrTwo';
import IstrThree from '../../../components/Peserta/Istr/IstrThree';
import IstrFour from '../../../components/Peserta/Istr/IstrFour';
import IstrFive from '../../../components/Peserta/Istr/IstrFive';
import IstrSeven from '../../../components/Peserta/Istr/IstrSeven';
import IstrEight from '../../../components/Peserta/Istr/IstrEight';
import IstrNine from '../../../components/Peserta/Istr/IstrNine';
import IstrTen from '../../../components/Peserta/Istr/IstrTen';
import IstrEleven from '../../../components/Peserta/Istr/IstrEleven';
import Cpm from '../../../components/Peserta/Cpm';
import GatbOne from '../../../components/Peserta/Gatb/GatbOne';
import GatbTwo from '../../../components/Peserta/Gatb/GatbTwo';
import GatbThree from '../../../components/Peserta/Gatb/GatbThree';
import GatbFour from '../../../components/Peserta/Gatb/GatbFour';
import GatbSix from '../../../components/Peserta/Gatb/GatbSix';
import Mmpi from '../../../components/Peserta/Mmpi/';
import Mbti from '../../../components/Peserta/Mbti';
import Kuder from '../../../components/Peserta/Kuder';
import Op from '../../../components/Peserta/Op';
import SixteenPf from '../../../components/Peserta/SixteenPf';
import CfitTwoAOne from '../../../components/Peserta/Cfit/CfitTwoA/CfitTwoAOne';
import CfitTwoATwo from '../../../components/Peserta/Cfit/CfitTwoA/CfitTwoATwo';
import CfitTwoAThree from '../../../components/Peserta/Cfit/CfitTwoA/CfitTwoAThree';
import CfitTwoAFour from '../../../components/Peserta/Cfit/CfitTwoA/CfitTwoAFour';
import Epps from '../../../components/Peserta/Epps';
import CfitAOneKpk from '../../../components/Peserta/Cfit/CfitKpk/CfitAOneKpk';
import CfitATwoKpk from '../../../components/Peserta/Cfit/CfitKpk/CfitATwoKpk';
import CfitAThreeKpk from '../../../components/Peserta/Cfit/CfitKpk/CfitAThreeKpk';
import CfitAFourKpk from '../../../components/Peserta/Cfit/CfitKpk/CfitAFourKpk';
import NeoPi from '../../../components/Peserta/NeoPi';
import IstrSix from '../../../components/Peserta/Istr/IstrSix';
import Cact from '../../../components/Peserta/Cact';
import HollandRiasec from '../../../components/Peserta/Holland/Riasec';
import HollandEgatek from '../../../components/Peserta/Holland/Egatek';
import IbManagerAudit from '../../../components/Peserta/InBasket/ManagerAudit';
import IbManagerHr from '../../../components/Peserta/InBasket/ManagerHr';
import IbProductionEngineer from '../../../components/Peserta/InBasket/ProductionEngineer';
import IbSpvProductionEngineer from '../../../components/Peserta/InBasket/SpvProductionEngineer';
import IbManagerTekstil from '../../../components/Peserta/InBasket/ManagerTekstil';

import IBRoManager from '../../../components/Peserta/InBasket/ROManager';
import IBSupervisor from '../../../components/Peserta/InBasket/Supervisor';
import IBKepalaSekolah from '../../../components/Peserta/InBasket/KepalaSekolah';
import IBKepalaDepartemenRisetProduk from '../../../components/Peserta/InBasket/KepalaDepartemenRisetProduk';
import IBMarketingManager from '../../../components/Peserta/InBasket/MarketingManager';
import IBPTASURANSISOSIALKARYAWAN from '../../../components/Peserta/InBasket/PTASURANSISOSIALKARYAWAN';
import IBManagerOperasional from '../../../components/Peserta/InBasket/ManagerOperasional';
import IBManagerMultiFinance from '../../../components/Peserta/InBasket/ManagerMultiFinance';
import AKReasuransiKepalaDivisiAktuaria from '../../../components/Peserta/AnalisaKasus/ReasuransiKepalaDivisiAktuaria';
import AKSalesAndMarketingManager from '../../../components/Peserta/AnalisaKasus/SalesAndMarketingManager';
import AKBranchManagerUsedCarMultifinance from '../../../components/Peserta/AnalisaKasus/BranchManagerUsedCarMultifinance';

import IbDirekturDirektoratPendidikan from '../../../components/Peserta/InBasket/DirekturDirektoratPendidikan';
import IbReasuransiKepalaBagian from '../../../components/Peserta/InBasket/ReasuransiKepalaBagian';
import IbMineEngineering from '../../../components/Peserta/InBasket/MineEngineering';
import IbLegalDepartementHead from '../../../components/Peserta/InBasket/LegalDepartementHead';
import IbKepalaSeksiAnggaran from '../../../components/Peserta/InBasket/KepalaSeksiAnggaran';
import IbReasuransiKepalaBagianTIK from '../../../components/Peserta/InBasket/ReasuransiKepalaBagianTIK';
import IbManagerPengembanganBisnis from '../../../components/Peserta/InBasket/ManagerPengembanganBisnis';
import IbManagerPPIC from '../../../components/Peserta/InBasket/ManagerPPIC';
import IBITDepartemen from '../../../components/Peserta/InBasket/ITDepartemen';
import IBKepalaUnitWilayahKerja from '../../../components/Peserta/InBasket/KepalaUnitWilayahKerja';
import IBKepalaBagianAkuntansi from '../../../components/Peserta/InBasket/KepalaBagianAkuntansi';

import AKManajerLogistik from '../../../components/Peserta/AnalisaKasus/ManajerLogistik';
import AKStaffAccounting from '../../../components/Peserta/AnalisaKasus/StaffAccounting';
import AKInventoryControl from '../../../components/Peserta/AnalisaKasus/InventoryControl';
import AKManajerManufaktur from '../../../components/Peserta/AnalisaKasus/ManajerManufaktur';
import AKIjinPertambangan from '../../../components/Peserta/AnalisaKasus/IjinPertambangan';
import AKManajerFarmasi from '../../../components/Peserta/AnalisaKasus/ManajerFarmasi';
import AKSocialMediaManager from '../../../components/Peserta/AnalisaKasus/SocialMediaManager';
import AKAdministrasiOperasi from '../../../components/Peserta/AnalisaKasus/AdministrasiOperasi';
import AKKepalaSeksiKeuangan from '../../../components/Peserta/AnalisaKasus/KepalaSeksiKeuangan';
import AKITSectionHeadManufacturing from '../../../components/Peserta/AnalisaKasus/ITSectionHeadManufacturing';
import AKMultiFinance from '../../../components/Peserta/AnalisaKasus/MultiFinance';
import AKPresentasi from '../../../components/Peserta/AnalisaKasus/Presentasi';
import AKVPRisikoBank from '../../../components/Peserta/AnalisaKasus/VPRisikoBank';
import AKSiteManagerProperty from '../../../components/Peserta/AnalisaKasus/SiteManagerProperty';
import AKManagerPengembanganBisnis from '../../../components/Peserta/AnalisaKasus/ManagerPengembanganBisnis';
import AKDirektoratPelaksaanPendidikan from '../../../components/Peserta/AnalisaKasus/DirektoratPelaksaanPendidikan';
import AKReasuransiKepalaBagian from '../../../components/Peserta/AnalisaKasus/ReasuransiKepalaBagian';
import AKManagerHRAsuransi from '../../../components/Peserta/AnalisaKasus/ManagerHRAsuransi';
import AKPimpinanKCBank from '../../../components/Peserta/AnalisaKasus/PimpinanKCBank';
import AKKepalaSekolah from '../../../components/Peserta/AnalisaKasus/KepalaSekolah';
import AKKepalaDepartemenRisetProduk from '../../../components/Peserta/AnalisaKasus/KepalaDepartemenRisetProduk';
import AKOfficer1VerifikasiLS_LHV from '../../../components/Peserta/AnalisaKasus/Officer1VerifikasiLS_LHV';
import AKEngineeringTambang from '../../../components/Peserta/AnalisaKasus/EngineeringTambang';
import AKKasusMarketingManager from '../../../components/Peserta/AnalisaKasus/MarketingManager';
import AKReasuransiKepalaBagianTIK from '../../../components/Peserta/AnalisaKasus/ReasuransiKepalaBagianTIK';
import AKRetailOperationManager from '../../../components/Peserta/AnalisaKasus/RetailOperationManager';
import AKECommerceSPV from '../../../components/Peserta/AnalisaKasus/ECommerceSPV';
import AKGeneralManager from '../../../components/Peserta/AnalisaKasus/GeneralManager';
import AKProductMarketingManager from '../../../components/Peserta/AnalisaKasus/ProductMarketingManager';
import AKTaxAccountant from '../../../components/Peserta/AnalisaKasus/TaxAccountant';
import AKManagerOperasional from '../../../components/Peserta/AnalisaKasus/ManagerOperasional';
import AKKepalaSeksiHumas from '../../../components/Peserta/AnalisaKasus/KepalaSeksiHumas';
import AKPPIC from '../../../components/Peserta/AnalisaKasus/PPIC';

import AKHSEOfficerMigas from '../../../components/Peserta/AnalisaKasus/HSEOfficerMigas';
import AKManagerOprasional from '../../../components/Peserta/AnalisaKasus/ManagerOprasional';
import AKReasuransiKepalaSeksi from '../../../components/Peserta/AnalisaKasus/ReasuransiKepalaSeksi';
import AKTIBank from '../../../components/Peserta/AnalisaKasus/TIBank';
import AKManagerKeuangan from '../../../components/Peserta/AnalisaKasus/ManagerKeuangan';
import AKReasuransiKepalaDivisi from '../../../components/Peserta/AnalisaKasus/ReasuransiKepalaDivisi';
import AKWarehouseSPV from '../../../components/Peserta/AnalisaKasus/WarehouseSPV';
import AKLegalManager from '../../../components/Peserta/AnalisaKasus/LegalManager';
import AKRetailManager from '../../../components/Peserta/AnalisaKasus/RetailManager';
import AKReasuransiKepalaSeksiTIK from '../../../components/Peserta/AnalisaKasus/ReasuransiKepalaSeksiTIK';
import AKSurveyor from '../../../components/Peserta/AnalisaKasus/Surveyor';
import AKKepalaUnitWilayahKerja from '../../../components/Peserta/AnalisaKasus/KepalaUnitWilayahKerja';
import AKKepalaBagianAkuntansi from '../../../components/Peserta/AnalisaKasus/KepalaBagianAkuntansi';

import DataDiriSekolah from '../../../components/Peserta/DataDiriSekolah';
import DataDiriMap from '../../../components/Peserta/DataDiriMap';
import DataDiriSekolahSingkat from '../../../components/Peserta/DataDiriSekolahSingkat';
import DataDiriSingkat from '../../../components/Peserta/DataDiriSingkat';
import DataDiriSekolahTwo from '../../../components/Peserta/DataDiriSekolahTwo';
import SelfAssessmentSingkat from '../../../components/Peserta/SelfAssessmentSingkat';
import BiodataCalonMahasiswa from '../../../components/Peserta/BiodataCalonMahasiswa';
import DataDiriSd from '../../../components/Peserta/DataDiriSd';
import Flk from '../../../components/Peserta/Flk';
import TeamRoleBelbin from '../../../components/Peserta/TeamRoleBelbin';
import { API } from '../../../services/API';
import TikiMOne from '../../../components/Peserta/TikiM/TikiMOne';
import TikiMThree from '../../../components/Peserta/TikiM/TikiMThree';
import TikiMFive from '../../../components/Peserta/TikiM/TikiMFive';
import TikiMSix from '../../../components/Peserta/TikiM/TikiMSix';
import BigFive from '../../../components/Peserta/BigFive';
import TikiMEight from '../../../components/Peserta/TikiM/TikiMEight';
import TikiMTen from '../../../components/Peserta/TikiM/TikiMTen';
import TikiMTwelve from '../../../components/Peserta/TikiM/TikiMTwelve';
import TIBank from '../../../components/Peserta/AnalisaKasus/TIBank';

import EngagementTestTalenta from 'components/Peserta/EngagementTestTalenta';

const Mengerjakan = ({ currentTes, pesertaLogin }) => {
  const [peserta, setPeserta] = useState({});
  const [proctoring, setProctoring] = useState(false);
  const [camPeserta, setCamPeserta] = useState(false);
  const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));

  async function checkCameraPeserta() {
    const res = await API.post('/peserta/login', {
      kodeTes: psikotesLS.aktivasi,
    });
    setCamPeserta(res.data.peserta.cam);
  }

  useEffect(() => {
    // console.log({ camPesertaMenger: camPeserta });
    if (camPeserta === 'Ya') {
      setProctoring(true);
      return;
    } else if (camPeserta === 'Mengikuti project') {
      // CHECK ON / OFF CAMERA
      async function checkCamera() {
        await API.get(`/camera/${psikotesLS.peserta.id}`)
          .then((res) => {
            setProctoring(res.data.data);
          })
          .catch((err) => {
            console.log(err);
            setProctoring(false);
          });
      }
      checkCamera();
    }

    checkCameraPeserta();
  }, [camPeserta, proctoring]);

  useEffect(() => {
    const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
    setPeserta(psikotesLS.peserta.id);
    const login = (psikotesLS) => {
      pesertaLogin(
        psikotesLS.peserta,
        psikotesLS.currentTes,
        psikotesLS.currentSoal,
        psikotesLS.currentPetunjuk,
        psikotesLS.jawabans
      );
    };

    login(psikotesLS);
  }, [pesertaLogin]);

  // console.clear();
  return (
    <Layout
      pesertaId={peserta}
      proctoring={proctoring}
      camPeserta={camPeserta}
      currentTesId={currentTes?.tes?.id}
    >
      {currentTes?.tes?.id === 1 && <DataDiriSekolahSingkat />}
      {currentTes?.tes?.id === 2 && <DataDiriSingkat />}
      {currentTes?.tes?.id === 3 && <DataDiriSekolahTwo />}
      {currentTes?.tes?.id === 4 && <SelfAssessmentSingkat />}
      {currentTes?.tes?.id === 5 && <BiodataCalonMahasiswa />}
      {currentTes?.tes?.id === 6 && <DataDiriSd />}
      {currentTes?.tes?.id === 7 && <Flk />}
      {currentTes?.tes?.id === 8 && <IstSe />}
      {currentTes?.tes?.id === 9 && <IstWa />}
      {currentTes?.tes?.id === 10 && <IstAn />}
      {currentTes?.tes?.id === 11 && <IstGe />}
      {currentTes?.tes?.id === 12 && <IstMe />}
      {currentTes?.tes?.id === 13 && <IstRa />}
      {currentTes?.tes?.id === 14 && <IstZr />}
      {currentTes?.tes?.id === 15 && <IstFa />}
      {currentTes?.tes?.id === 16 && <IstWu />}
      {currentTes?.tes?.id === 17 && <TiuFive />}
      {currentTes?.tes?.id === 18 && <Wpt />}
      {currentTes?.tes?.id === 19 && <Logika />}
      {currentTes?.tes?.id === 20 && <Apm />}
      {currentTes?.tes?.id === 21 && <AOne />}
      {currentTes?.tes?.id === 23 && <CTwo />}
      {currentTes?.tes?.id === 24 && <EasFour />}
      {currentTes?.tes?.id === 25 && <EasFive />}
      {currentTes?.tes?.id === 26 && <Cact />}
      {currentTes?.tes?.id === 27 && <TikiOne />}
      {currentTes?.tes?.id === 28 && <TikiTwo />}
      {currentTes?.tes?.id === 29 && <TikiThree />}
      {currentTes?.tes?.id === 30 && <TikiFour />}
      {currentTes?.tes?.id === 31 && <TikiFive />}
      {currentTes?.tes?.id === 32 && <TikiSix />}
      {currentTes?.tes?.id === 33 && <TikiSeven />}
      {currentTes?.tes?.id === 34 && <TikiEight />}
      {currentTes?.tes?.id === 35 && <TikiNine />}
      {currentTes?.tes?.id === 36 && <TikiTen />}
      {currentTes?.tes?.id === 37 && <TikiEleven />}
      {currentTes?.tes?.id === 38 && <CfitThreeAOne />}
      {currentTes?.tes?.id === 39 && <CfitThreeATwo />}
      {currentTes?.tes?.id === 40 && <CfitThreeAThree />}
      {currentTes?.tes?.id === 41 && <CfitThreeAFour />}
      {currentTes?.tes?.id === 42 && <NumerikForty />}
      {currentTes?.tes?.id === 43 && <VerbalSixty />}
      {currentTes?.tes?.id === 44 && <AbstraksiTwentyFour />}
      {currentTes?.tes?.id === 45 && <CfitThreeBOne />}
      {currentTes?.tes?.id === 46 && <CfitThreeBTwo />}
      {currentTes?.tes?.id === 47 && <CfitThreeBThree />}
      {currentTes?.tes?.id === 48 && <CfitThreeBFour />}
      {currentTes?.tes?.id === 49 && <WptLike />}
      {currentTes?.tes?.id === 50 && <CfitAOneKpk />}
      {currentTes?.tes?.id === 51 && <CfitATwoKpk />}
      {currentTes?.tes?.id === 52 && <CfitAThreeKpk />}
      {currentTes?.tes?.id === 53 && <CfitAFourKpk />}
      {currentTes?.tes?.id === 54 && <Ret />}
      {currentTes?.tes?.id === 55 && <CfitTwoAOne />}
      {currentTes?.tes?.id === 56 && <CfitTwoATwo />}
      {currentTes?.tes?.id === 57 && <CfitTwoAThree />}
      {currentTes?.tes?.id === 58 && <CfitTwoAFour />}
      {currentTes?.tes?.id === 59 && <IstrOne />}
      {currentTes?.tes?.id === 60 && <IstrTwo />}
      {currentTes?.tes?.id === 61 && <IstrThree />}
      {currentTes?.tes?.id === 62 && <IstrFour />}
      {currentTes?.tes?.id === 63 && <IstrFive />}
      {currentTes?.tes?.id === 64 && <IstrSix />}
      {currentTes?.tes?.id === 65 && <IstrSeven />}
      {currentTes?.tes?.id === 66 && <IstrEight />}
      {currentTes?.tes?.id === 67 && <IstrNine />}
      {currentTes?.tes?.id === 68 && <IstrTen />}
      {currentTes?.tes?.id === 69 && <IstrEleven />}
      {currentTes?.tes?.id === 70 && <IbManagerAudit />}
      {currentTes?.tes?.id === 71 && <IbManagerHr />}
      {currentTes?.tes?.id === 72 && <IbProductionEngineer />}
      {currentTes?.tes?.id === 73 && <IbSpvProductionEngineer />}
      {currentTes?.tes?.id === 74 && <IbManagerTekstil />}
      {currentTes?.tes?.id === 75 && <Msdt />}
      {currentTes?.tes?.id === 76 && <DiscProfile />}
      {currentTes?.tes?.id === 77 && <Op />}
      {currentTes?.tes?.id === 78 && <SixteenPf />}
      {currentTes?.tes?.id === 79 && <Epps />}
      {(currentTes?.tes?.id === 80 || currentTes?.tes?.id === 81 || currentTes?.tes?.id === 82) && (
        <Mmpi />
      )}
      {currentTes?.tes?.id === 83 && <NeoPi />}
      {currentTes?.tes?.id === 84 && <Mbti />}
      {currentTes?.tes?.id === 85 && <TeamRoleBelbin />}
      {currentTes?.tes?.id === 86 && <Kuder />}
      {currentTes?.tes?.id === 87 && <Kostick />}
      {currentTes?.tes?.id === 88 && <HollandRiasec />}
      {currentTes?.tes?.id === 89 && <EasSix />}
      {currentTes?.tes?.id === 90 && <EasSeven />}
      {currentTes?.tes?.id === 91 && <EasTen />}
      {currentTes?.tes?.id === 92 && <HollandEgatek />}
      {currentTes?.tes?.id === 93 && <Kraepelin />}
      {currentTes?.tes?.id === 101 && <GatbOne />}
      {currentTes?.tes?.id === 102 && <GatbTwo />}
      {currentTes?.tes?.id === 103 && <GatbThree />}
      {currentTes?.tes?.id === 104 && <GatbFour />}
      {currentTes?.tes?.id === 105 && <GatbSix />}
      {(currentTes?.tes?.id === 106 ||
        currentTes?.tes?.id === 107 ||
        currentTes?.tes?.id === 108) && <Cpm />}
      {currentTes?.tes?.id === 110 && <TpaSixtyOne />}
      {currentTes?.tes?.id === 111 && <TpaSixtyTwo />}
      {currentTes?.tes?.id === 112 && <TpaSixtyThree />}
      {currentTes?.tes?.id === 113 && <TpaOneHundredOne />}
      {currentTes?.tes?.id === 114 && <TpaOneHundredTwo />}
      {currentTes?.tes?.id === 115 && <TpaOneHundredThree />}
      {currentTes?.tes?.id === 116 && <TpaOneHundredFour />}
      {currentTes?.tes?.id === 117 && <Vak />}
      {currentTes?.tes?.id === 118 && <DataDiri />}
      {currentTes?.tes?.id === 119 && <DataDiriSekolah />}
      {currentTes?.tes?.id === 120 && <DataRekruitmenPegawai />}
      {currentTes?.tes?.id === 121 && <DataDiriMap />}
      {currentTes?.tes?.id === 122 && <AKAdministrasiOperasi />}
      {currentTes?.tes?.id === 123 && <AKKepalaSeksiKeuangan />}
      {currentTes?.tes?.id === 94 && <AKManajerLogistik />}
      {currentTes?.tes?.id === 95 && <AKStaffAccounting />}
      {currentTes?.tes?.id === 96 && <AKInventoryControl />}
      {currentTes?.tes?.id === 97 && <AKManajerManufaktur />}
      {currentTes?.tes?.id === 98 && <AKIjinPertambangan />}
      {currentTes?.tes?.id === 99 && <AKManajerFarmasi />}
      {currentTes?.tes?.id === 100 && <AKSocialMediaManager />}
      {currentTes?.tes?.id === 124 && <TikiMOne />}
      {currentTes?.tes?.id === 125 && <TikiMThree />}
      {currentTes?.tes?.id === 126 && <TikiMFive />}
      {currentTes?.tes?.id === 127 && <TikiMSix />}
      {currentTes?.tes?.id === 128 && <BigFive />}
      {currentTes?.tes?.id === 129 && <TikiMEight />}
      {currentTes?.tes?.id === 130 && <TikiMTen />}
      {currentTes?.tes?.id === 131 && <TikiMTwelve />}
      {currentTes?.tes?.id === 132 && <AKITSectionHeadManufacturing />}
      {currentTes?.tes?.id === 133 && <AKMultiFinance />}
      {currentTes?.tes?.id === 134 && <AKPresentasi />}
      {currentTes?.tes?.id === 135 && <AKVPRisikoBank />}
      {currentTes?.tes?.id === 136 && <AKSiteManagerProperty />}
      {currentTes?.tes?.id === 137 && <AKHSEOfficerMigas />}
      {currentTes?.tes?.id === 138 && <AKManagerOprasional />}
      {currentTes?.tes?.id === 139 && <AKManagerPengembanganBisnis />}
      {currentTes?.tes?.id === 140 && <AKDirektoratPelaksaanPendidikan />}
      {currentTes?.tes?.id === 141 && <AKReasuransiKepalaSeksi />}
      {currentTes?.tes?.id === 142 && <AKReasuransiKepalaBagian />}
      {currentTes?.tes?.id === 143 && <AKTIBank />}
      {currentTes?.tes?.id === 144 && <AKManagerHRAsuransi />}
      {currentTes?.tes?.id === 145 && <AKPimpinanKCBank />}
      {currentTes?.tes?.id === 146 && <AKKepalaSekolah />}
      {currentTes?.tes?.id === 147 && <AKKepalaDepartemenRisetProduk />}
      {currentTes?.tes?.id === 148 && <AKOfficer1VerifikasiLS_LHV />}
      {currentTes?.tes?.id === 149 && <AKEngineeringTambang />}
      {currentTes?.tes?.id === 150 && <AKManagerKeuangan />}
      {currentTes?.tes?.id === 151 && <AKKasusMarketingManager />}
      {currentTes?.tes?.id === 152 && <AKReasuransiKepalaDivisi />}
      {currentTes?.tes?.id === 153 && <AKReasuransiKepalaBagianTIK />}
      {currentTes?.tes?.id === 154 && <AKWarehouseSPV />}
      {currentTes?.tes?.id === 155 && <AKRetailOperationManager />}
      {currentTes?.tes?.id === 156 && <AKECommerceSPV />}
      {currentTes?.tes?.id === 157 && <AKLegalManager />}
      {currentTes?.tes?.id === 158 && <AKGeneralManager />}
      {currentTes?.tes?.id === 159 && <AKProductMarketingManager />}
      {currentTes?.tes?.id === 160 && <AKRetailManager />}
      {currentTes?.tes?.id === 161 && <AKTaxAccountant />}
      {currentTes?.tes?.id === 162 && <AKManagerOperasional />}
      {currentTes?.tes?.id === 163 && <AKReasuransiKepalaSeksiTIK />}
      {currentTes?.tes?.id === 164 && <AKKepalaSeksiHumas />}
      {currentTes?.tes?.id === 165 && <AKPPIC />}
      {currentTes?.tes?.id === 166 && <AKSurveyor />}
      {currentTes?.tes?.id === 167 && <IbDirekturDirektoratPendidikan />}
      {currentTes?.tes?.id === 168 && <IbReasuransiKepalaBagian />}
      {currentTes?.tes?.id === 169 && <IBRoManager />}
      {currentTes?.tes?.id === 170 && <IBSupervisor />}
      {currentTes?.tes?.id === 171 && <IBKepalaSekolah />}
      {currentTes?.tes?.id === 172 && <IBKepalaDepartemenRisetProduk />}
      {currentTes?.tes?.id === 173 && <IbMineEngineering />}
      {currentTes?.tes?.id === 174 && <IbLegalDepartementHead />}
      {currentTes?.tes?.id === 175 && <IBMarketingManager />}
      {currentTes?.tes?.id === 176 && <IBPTASURANSISOSIALKARYAWAN />}
      {currentTes?.tes?.id === 177 && <IbKepalaSeksiAnggaran />}
      {currentTes?.tes?.id === 178 && <IbReasuransiKepalaBagianTIK />}
      {currentTes?.tes?.id === 179 && <IbManagerPengembanganBisnis />}
      {currentTes?.tes?.id === 180 && <IBManagerOperasional />}
      {currentTes?.tes?.id === 181 && <IBManagerMultiFinance />}
      {currentTes?.tes?.id === 182 && <IbManagerPPIC />}
      {currentTes?.tes?.id === 183 && <AKReasuransiKepalaDivisiAktuaria />}
      {currentTes?.tes?.id === 184 && <AKSalesAndMarketingManager />}
      {currentTes?.tes?.id === 185 && <AKBranchManagerUsedCarMultifinance />}
      {currentTes?.tes?.id === 186 && <IBITDepartemen />}
      {currentTes?.tes?.id === 187 && <AKKepalaUnitWilayahKerja />}
      {currentTes?.tes?.id === 188 && <IBKepalaUnitWilayahKerja />}
      {currentTes?.tes?.id === 189 && <AKKepalaBagianAkuntansi />}
      {currentTes?.tes?.id === 190 && <IBKepalaBagianAkuntansi />}
      {currentTes?.tes?.id === 191 && <EngagementTestTalenta />}
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    currentTes: state.peserta?.currentTes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    pesertaLogin: (peserta, currentTes, currentSoal, currentPetunjuk, jawabans) =>
      dispatch(pesertaLogin(peserta, currentTes, currentSoal, currentPetunjuk, jawabans)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mengerjakan);
