import TopSection from '../TopSection';
import React, { useState, useEffect } from 'react';
import styles from './ModulContent.module.css';
import { API } from '../../services/API';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ConfirmModal from '../ConfirmModal';

const ModulsContent = () => {
  const [moduls, setModuls] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [remove, setRemove] = useState(false);
  const [modulId, setModulId] = useState(0);
  const [content, setContent] = useState('Apakah anda yakin ingin menghapus modul ini?');

  const onRemovedModul = () => {
    API.delete('/modul/' + modulId)
      .then(() => {
        console.log('Berhasil menghapus modul!');
        setShowModal(false);
        setRemove(true);
      })
      .catch((err) => {
        setContent(err.response.data.message || 'Gagal menghapus modul!');
      });
  };

  useEffect(() => {
    const getAllModul = async () => {
      try {
        const res = await API.get('/moduls');
        const modul = res.data.modulAndTes;
        setModuls(modul);
        setRemove(false);
      } catch (error) {
        console.log(error);
      }
    };

    getAllModul();
  }, [remove]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.mainSection}>
          <div className={styles.modul}>
            <ConfirmModal
              isVisible={showModal}
              setVisible={setShowModal}
              content={content}
              onClick={onRemovedModul}
            />
            <TopSection page="DaftarModul" />
            {moduls[0] && (
              <div className={styles.overflowY}>
                <Table className={styles.Table}>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Nama Modul</th>
                      <th>Daftar Tes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {moduls[0].map((e, index) => (
                      <tr key={index}>
                        <td className={styles.td}>{index + 1}</td>
                        <td className={styles.td}>{e.nama}</td>
                        <td>
                          <ul>
                            {moduls[1].map(
                              (x, i) =>
                                parseInt(x.modulId) === e.id && (
                                  <li key={i}>{x.tes.nama + ' - ' + x.urutan}</li>
                                )
                            )}
                          </ul>
                        </td>
                        <td className={styles.td}>
                          <button
                            type="button"
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                            }}
                            className={styles.removeButton}
                            onClick={() => {
                              setModulId(e.id);
                              setShowModal(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} color="red" size="lg" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.background}></div>
    </>
  );
};

export default ModulsContent;
