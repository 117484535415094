import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukMiddle from "./PetunjukMiddle";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum mengerjakan.
        </p>
        <br />
        <p style={{ color: "red" }}>
          Jangan ditutup dulu petunjuk ini, perhatikan contoh, Apabila petunjuk
          ini ditutup maka tes dimulai dan waktu berjalan.
        </p>

        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        namaFile="contoh1_tikit2.png"
        kunci={10}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Pada contoh tersebut jawaban yang benar adalah b dan d. Oleh karena itu Anda menjawab b dan d agar jawaban benar."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        namaFile="contoh2_tikit2.png"
        kunci={36}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban yang paling TEPAT adalah c dan f, oleh karena itu Anda seharusnya memilih jawaban c dan f tersebut."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        namaFile="contoh3_tikit2.png"
        kunci={34}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban yang paling TEPAT adalah b dan f, oleh karena itu Anda seharusnya memilih jawaban b dan f tersebut."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={4}
        namaFile="contoh4_tikit2.png"
        kunci={9}
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban yang paling TEPAT adalah a dan d, oleh karena itu Anda seharusnya memilih jawaban a dan d tersebut."
      />
      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={5}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
