import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihanOne = ["a", "j", "l", "u"];
  const arrPilihanTwo = ["l", "k", "s", "m"];
  const arrPilihanThree = ["l", "k", "s", "m"];
  const arrPilihanFour = ["o", "k", "s", "a"];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>Tes terdiri persoalan kata-kata.</p>
        <br />
        <p>
          Tes berikut ini terdiri atas kata-kata yang belum sempurna. Dalam
          setiap kata ada satu huruf yang kurang. Untuk membentuk kata yang
          mempunyai arti, harus ditambahkan satu huruf.
        </p>
        <br />
        <p>
          Setiap kata dalam tes ini adalah kata-kata dalam bahasa Indonesia yang
          sederhana bukan merupakan singkatan atau akronim.
        </p>
        <br />
        <p>
          Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="m a k n"
        arrPilihan={arrPilihanOne}
        kunci="a"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Huruf yang kurang dari contoh diatas adalah huruf <strong>a</strong> untuk membentuk kata <em>makan.</em> Oleh karena itu pilih huruf a tersebut."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="e d i h"
        arrPilihan={arrPilihanTwo}
        kunci="s"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Kata yang tepat adalah sedih, maka huruf s yang dipilih."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        soal="s a a h"
        arrPilihan={arrPilihanThree}
        kunci="l"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Kata yang tepat adalah salah, maka huruf l yang dipilih."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={4}
        soal="s e k l a h"
        arrPilihan={arrPilihanFour}
        kunci="o"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Kata yang tepat adalah sekolah, maka huruf o yang dipilih."
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={5}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
