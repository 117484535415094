import styles from "./Right.module.css";

const Right = () => {
  return (
    <div className={styles.right}>
      <div className={styles.top}>
        <div className={styles.topLeft}>
          <p className={styles.presentaseTitle}>
            Presentase Kecocokan Pengerjaan
          </p>
          <p className={styles.presentase}>73.23%</p>
        </div>
        <div className={styles.topRight}>
          <p className={styles.rekomendasiTitle}>Hasil Rekomendasi</p>
          <p className={styles.rekomendasiStatus}>Dipertimbangkan</p>
        </div>
      </div>
      <div className={styles.bottom}>
        <select name="jabatan" id="jabatan">
          <option value="mekanik">Mekanik</option>
        </select>
        <button>Proses</button>
      </div>
    </div>
  );
};

export default Right;
