import React from 'react';

const HeaderSecond = () => {
  return (
    <React.Fragment>
      <th colSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        SE
      </th>
      <th colSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        WA
      </th>
      <th colSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        AN
      </th>
      <th colSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        GE
      </th>
      <th colSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        ME
      </th>
      <th colSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        RA
      </th>
      <th colSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        ZR
      </th>
      <th colSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        FA
      </th>
      <th colSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        WU
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        Total RS
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        IQ
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        Klasifikasi IQ
      </th>
    </React.Fragment>
  );
};

export default HeaderSecond;
