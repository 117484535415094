import GlobalFilter from "./GlobalFilter";
import styles from "./TableHeader.module.css";

const TableHeader = ({
  preGlobalFilteredRows,
  globalFilterState,
  setGlobalFilter,
  pageSize,
  setPageSize,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.actionWrapper}>
        <div>
          <span>Tampilkan: </span>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            style={{ height: "42px", width: "160px", padding: "0 15px" }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilterState}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
    </div>
  );
};

export default TableHeader;
