import React from 'react';

import { isObjectEmpty, getEmptyData } from 'utility/Utils';

const Column = ({ data }) => {
  if (isObjectEmpty(data)) return getEmptyData(6);

  const excludes = ['average', 'average_category', 'resilience', 'resilience_category'];
  return (
    <React.Fragment>
      {Object.entries(data).map(([key, value]) => {
        if (excludes.includes(key)) return;
        return (
          <td key={key} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
            {key === 'averageD'
              ? value.toFixed(2)
              : key === 'resilience'
              ? value.toFixed(2)
              : value || '-'}
          </td>
        );
      })}
    </React.Fragment>
  );
};

export default Column;
