import styles from "./PilihanWrapper.module.css";

const PilihanWrapper = ({ children, col = 1, variant = "image" }) => (
  <div
    className={variant === "image" ? styles.image : styles.text}
    style={{ gridTemplateColumns: `repeat(${col}, minmax(0, 1fr))` }}
  >
    {children}
  </div>
);

export default PilihanWrapper;
