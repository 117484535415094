import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { API } from '../../services/API';
import TopSection from '../TopSection';
import styles from './ProjectDetailContent.module.css';
import SectionOne from './SectionOne';
import SectionThree from './SectionThree';
import SectionTwo from './SectionTwo';
import { connect } from 'react-redux';
import * as RB from 'react-bootstrap';

const ProjectDetailContent = ({ isClientSekolah }) => {
  const history = useHistory();

  const { id } = useParams();
  const [project, setProject] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getProjectDetail = async () => {
      try {
        setIsLoading(true);
        const res = await API.get(`/project/${id}`);
        // console.log('ini dari project details content');
        // console.log(res.data.data);

        if (res.status === 200) {
          setProject(res.data.data);
          setIsLoading(false);
        }
      } catch (error) {
        //console.log(error);
        // console.log("This should redirect to home '/'");
        setProject(false);
        setIsLoading(false);
      }
    };

    getProjectDetail();
  }, [id]);

  return (
    <div className={styles.container}>
      <TopSection />

      <div className={styles.mainSection}>
        <p className={styles.path}>
          <span
            className={styles.pathLink}
            onClick={() => history.push('/dashboard')}
          >
            Beranda
          </span>{' '}
          <span
            className={styles.pathLink}
            onClick={() => history.push('/project')}
          >
            / Project
          </span>{' '}
          <span className={styles.pathNow}>/ Detail Project</span>
        </p>

        <SectionOne project={project} />
        {!isClientSekolah && <SectionTwo />}

        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center mt-5">
            <RB.Spinner animation="border" variant="info" />
          </div>
        ) : (
          <SectionThree
            peserta={project?.peserta}
            projectId={project?.id}
            projectDetail={project}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isClientSekolah: state.user.isClientSekolah,
  };
};

export default connect(mapStateToProps, null)(ProjectDetailContent);
