export default function DataDiriEduColumns({ format }) {
  return (
    <>
      <th rowSpan={format.rowSpanValue}>NO</th>
      <th rowSpan={format.rowSpanValue}>TANGGAL TES</th>
      <th rowSpan={format.rowSpanValue}>KODE AKTIVASI</th>
      <th rowSpan={format.rowSpanValue}>NAMA</th>
      <th rowSpan={format.rowSpanValue}>AREA</th>
      <th rowSpan={format.rowSpanValue}>POSISI</th>
      <th rowSpan={format.rowSpanValue}>USIA</th>
      <th rowSpan={format.rowSpanValue}>JENIS KELAMIN</th>
      <th rowSpan={format.rowSpanValue}>PENDIDIKAN</th>
    </>
  );
}
