import * as RB from 'react-bootstrap';
import styles from '../ProjectHeader.module.css';
import stylesDropdown from './ShowListDropdown.module.css';
import cn from 'classnames';
import {
  mapDispatchToPropsListCountProject,
  mapStateToPropsListCountProject,
} from '../../../Peserta/Utils/reduxMap';
import { connect } from 'react-redux';

const ShowListDropdown = ({
  listCount,
  orderListCount,
  changeListCountProject,
}) => {
  return (
    <RB.Dropdown className={styles.whiteButton}>
      <RB.Dropdown.Toggle variant="transparent" style={{ boxShadow: 'none' }}>
        <span className={cn(styles.textButton, stylesDropdown.textBlue)}>
          Tampilkan {listCount}
        </span>
      </RB.Dropdown.Toggle>

      <RB.Dropdown.Menu align={{ lg: 'start' }}>
        {/* olC = orderListCount */}
        {orderListCount.map((olC) => (
          <RB.Dropdown.Item
            href={`#${olC}`}
            className={styles.textButton}
            key={olC}
            onClick={(e) => changeListCountProject(olC)}
          >
            {olC}
          </RB.Dropdown.Item>
        ))}
      </RB.Dropdown.Menu>
    </RB.Dropdown>
  );
};

export default connect(
  mapStateToPropsListCountProject,
  mapDispatchToPropsListCountProject
)(ShowListDropdown);
