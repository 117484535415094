import { connect } from "react-redux";
import { pilihanOnClickHandler } from "../../Utils";
import { mapDispatchToPropsPilihan } from "../../Utils/reduxMap";
import styles from "./Pilihan.module.css";

const Pilihan = ({
  label,
  onClick,
  answer,
  replaceJawabans,
  changeCurrentSoal,
  setIsShowSelesaiModal,
}) => {
  return (
    <div
      className={styles.pilihanItem}
      style={{
        backgroundColor: answer === label ? "#3BC2AA" : "#e5e8ee",
      }}
      onClick={() => {
        onClick
          ? onClick()
          : pilihanOnClickHandler(
              label,
              replaceJawabans,
              changeCurrentSoal,
              setIsShowSelesaiModal
            );
      }}
    >
      <p dangerouslySetInnerHTML={{ __html: label }} />
    </div>
  );
};

export default connect(null, mapDispatchToPropsPilihan)(Pilihan);
