import React from 'react';

import { isObjectEmpty, getEmptyData } from 'utility/Utils';

const Column = ({ data }) => {
  if (isObjectEmpty(data)) return getEmptyData(20);

  const { nilai } = data;

  return (
    <React.Fragment>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{nilai.N}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{nilai.G}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{nilai.A}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{nilai.L}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{nilai.P}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{nilai.I}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{nilai.T}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{nilai.V}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{nilai.X}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{nilai.S}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{nilai.B}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{nilai.O}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{nilai.R}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{nilai.D}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{nilai.C}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{nilai.Z}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{nilai.E}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{nilai.K}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{nilai.F}</td>
      <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{nilai.W}</td>
    </React.Fragment>
  );
};

export default Column;
