import styles from "./Left.module.css";

const Left = () => {
  return (
    <div className={styles.left}>
      <h1 className={styles.title}>Learning Agility</h1>
      <h2 className={styles.subtitle}>Index Report</h2>

      <div className={styles.data}>
        <h1 className={styles.name}>Ronald Richards</h1>
        <p className={styles.tanggal}>Tanggal Pengerjaan 04-08-2021 14:39:10</p>
        <button>Cetak PDF</button>
      </div>
    </div>
  );
};

export default Left;
