import Footer from "../../../../Components/PetunjukMiddle/Footer";
import Benar from "../../../../Components/PetunjukMiddle/Benar";
import PetunjukMiddleContainer from "../../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import PetunjukParagraph from "../../../../Components/PetunjukMiddle/PetunjukParagraph";
import SoalImage from "../../../../Components/SoalImage";
import Salah from "../../../../Components/PetunjukMiddle/Salah";
import PilihanWrapper from "../../../../Components/PilihanWrapper";
import PilihanText from "../../../../Components/PilihanText";
import { useState } from "react";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  pesanBenar,
  namaFile,
  previous,
  next,
  kunci,
}) => {
  const [answer, setAnswer] = useState("");
  const arrPilihan = ["a", "b", "c", "d"];

  return (
    <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
      <PetunjukParagraph>
        Gambar di sebelah bawah memperlihatkan 4 buah benda a, b, c dan d yang
        berbeda satu sama lain. Salah satu dari benda-benda tersebut terbentuk
        dari karton yang terdapat di sebelah Atas, bila karton tersebut dilipat.
        Carilah benda yang dimaksud itu.
      </PetunjukParagraph>

      <SoalImage src={`/images/tes/tiki-t-7/${namaFile}`} alt="Contoh" />
      <PilihanWrapper variant="text" col={2}>
        {arrPilihan?.map((item, index) => (
          <PilihanText
            label={item}
            onClick={() => setAnswer(item)}
            answer={answer}
            key={index}
          />
        ))}
      </PilihanWrapper>

      <Benar isShow={answer === kunci} htmlString={pesanBenar} />
      <Salah isShow={answer !== kunci && answer !== ""} />
      <Footer
        min={1}
        max={4}
        previous={previous}
        next={next}
        condition={answer === kunci}
      />
    </PetunjukMiddleContainer>
  );
};

export default PetunjukMiddle;
