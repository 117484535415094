import { connect } from "react-redux";
import styles from "./Soal.module.css";
import Pilihan from "../Pilihan";
import { useEffect, useState } from "react";
import {
  changeCurrentSoal,
  replaceJawabans,
} from "../../../../redux/Peserta/peserta-actions";
import ButtonPetunjuk from "../../Components/ButtonPetunjuk";
import PetunjukModal from "../../Components/PetunjukModal";
import Petunjuk from "../Petunjuk";
import SoalContainer from "../../Containers/SoalContainer";
import SoalContent from "../../Containers/SoalContent";
import { mapStateToPropsSoal } from "../../Utils/reduxMap";

// answer[0] adalah 'least' / 'Paling Tidak Sesuai'
// answer[1] adalah 'most' / 'Paling Sesuai'
// Belum sama persis kayak yang sistem lama
// cobain lagi deh disc profile di sistem lama
// kalo belum dijawab ga boleh lanjut
// button navigasi dibawah di ilangin aja

const Soal = ({
  currentSoal,
  currentTes,
  replaceJawabans,
  changeCurrentSoal,
}) => {
  const [answer, setAnswer] = useState([]);
  const [warning, setWarning] = useState("");
  const [isShowSelesaiModal, setIsShowSelesaiModal] = useState(false);
  const [isShowPetunjukModal, setIsShowPetunjukModal] = useState(false);

  useEffect(() => {
    const psikotesLS = JSON.parse(localStorage.getItem("psikotesTalenta"));
    psikotesLS?.jawabans?.forEach((jawaban) => {
      if (jawaban.soalId === currentSoal.id) {
        if (jawaban.jawaban) {
          console.log("cs", jawaban.jawaban);
          setAnswer(jawaban.jawaban);
        }
      }
    });
  }, [currentSoal]);

  useEffect(() => {
    console.log(answer, "jawaaaaaaaaaaab");
    const psikotesLS = JSON.parse(localStorage.getItem("psikotesTalenta"));
    console.log(psikotesLS);
    psikotesLS.jawabans = [...psikotesLS.jawabans].map((jawaban) => {
      if (jawaban.soalId === psikotesLS.currentSoal.id) {
        return {
          pesertaId: psikotesLS.peserta.id,
          tesId: psikotesLS.currentTes.tesId,
          soalId: psikotesLS.currentSoal.id,
          nomor: psikotesLS.currentSoal.nomor,
          jawaban: answer,
        };
      }

      return jawaban;
    });

    localStorage.setItem("psikotesTalenta", JSON.stringify(psikotesLS));
    replaceJawabans(psikotesLS.jawabans);
  }, [answer, replaceJawabans]);

  const nextSoalHandler = () => {
    let sumBobot = 0;
    answer?.forEach((item) => {
      sumBobot += item.bobot;
    });
    if (sumBobot !== 10) {
      setWarning("Total bobot nilai harus bernilai 10");
      setTimeout(() => {
        setWarning("");
      }, 10000);

      return;
    }
    if (currentTes.tes.soals.length === currentSoal.nomor) {
      setIsShowSelesaiModal(true);
    }

    const psikotesLS = JSON.parse(localStorage.getItem("psikotesTalenta"));
    psikotesLS.currentSoal =
      psikotesLS.currentTes.tes.soals[currentSoal.nomor + 1 - 1];
    localStorage.setItem("psikotesTalenta", JSON.stringify(psikotesLS));

    changeCurrentSoal(psikotesLS.currentSoal);
  };

  const generateProgress = () => {
    const psikotesLS = JSON.parse(localStorage.getItem("psikotesTalenta"));

    let countAnswered = 0;
    let countTotalAnswer = 0;

    psikotesLS.jawabans.forEach((jawaban) => {
      if (jawaban.tesId === currentTes.tesId) {
        let sumBobot = 0;
        jawaban?.jawaban?.forEach((item) => {
          sumBobot += item.bobot;
        });
        if (sumBobot === 10) {
          countAnswered++;
        }
        countTotalAnswer++;
      }
    });

    const strProgress = ((countAnswered / countTotalAnswer) * 100).toString();
    return strProgress.substring(
      0,
      strProgress.indexOf(".") < 0
        ? strProgress.length
        : strProgress.indexOf(".")
    );
  };

  return (
    <SoalContainer
      isShowSelesaiModal={isShowSelesaiModal}
      setIsShowSelesaiModal={setIsShowSelesaiModal}
    >
      <header className={styles.header}>
        <div className={styles.leftHeader}>
          <p className={styles.title}>Progress Test</p>
          <p className={styles.subtitle}>{currentTes?.tes?.nama}</p>
        </div>

        <div className={styles.progressWrapper}>
          <p>{generateProgress()}%</p>
          <div className={styles.progressBackground}>
            <div
              className={styles.progressFill}
              style={{ width: `${generateProgress()}%` }}
            ></div>
          </div>
        </div>

        <div className={styles.buttonPetunjukWrapper}>
          <ButtonPetunjuk onClick={() => setIsShowPetunjukModal(true)} />
        </div>
      </header>
      <SoalContent>
        <p
          className={styles.soal}
          dangerouslySetInnerHTML={{ __html: currentSoal?.soal }}
        />
        <div className={styles.pilihanWrapper}>
          {currentSoal?.pilihan?.map((option, index) => (
            <Pilihan
              label={option}
              answer={answer}
              setAnswer={setAnswer}
              index={index}
              key={index}
            />
          ))}
        </div>
        <div className={styles.buttonWrapper}>
          <p>{warning}</p>
          {currentTes.tes.soals.length === currentSoal.nomor ? (
            <button
              onClick={nextSoalHandler}
              style={{ backgroundColor: "#3bc2aa" }}
            >
              Selesai
            </button>
          ) : (
            <button onClick={nextSoalHandler}>Jawab</button>
          )}
        </div>
      </SoalContent>

      <PetunjukModal
        isShow={isShowPetunjukModal}
        onHide={() => setIsShowPetunjukModal(false)}
      >
        <Petunjuk
          isModal
          tutupModalHandler={() => setIsShowPetunjukModal(false)}
        />
      </PetunjukModal>
    </SoalContainer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    replaceJawabans: (jawabans) => dispatch(replaceJawabans(jawabans)),
    changeCurrentSoal: (currentSoal) =>
      dispatch(changeCurrentSoal(currentSoal)),
  };
};

export default connect(mapStateToPropsSoal, mapDispatchToProps)(Soal);
