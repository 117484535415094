// React
import { useState, useEffect } from 'react';

// Routing, Redux= & Store
import { Link, useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { getClients, addCodes } from './store';

// Custom Components
import TopSection from '../../components/TopSection';
import SideBar from '../../components/SideBar';

// Third Party Component
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Breadcrumb, Button, Card, Col, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import classnames from 'classnames';

// Validation
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// styles
import 'flatpickr/dist/themes/material_blue.css';

const Required = ({ text }) => {
  return <span className="text-danger">{text}</span>;
};

const defaultValues = {
  is_random: false,
  client: '',
  random_codes: '',
  custom_codes: '',
};

const AssignPeserta = ({ isAdmin, clientId }) => {
  // State
  const [data, setData] = useState(null);
  const [clientOptions, setClientOptions] = useState([]);
  const [expiredDate, setExpiredDate] = useState(
    new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000)
  );
  const [isRandom, setIsRandom] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { idProject } = useParams();
  const history = useHistory();

  // Validation
  const FormSchema = Yup.object().shape({
    is_random: Yup.boolean(),
    client: Yup.object().required('Client is required'),
    random_codes: Yup.string().when('is_random', {
      is: true,
      then: () => Yup.string().required('Random codes are required'),
      otherwise: () => Yup.string(),
    }),
    custom_codes: Yup.string().when('is_random', {
      is: false,
      then: () => Yup.string().required('Custom codes are required'),
      otherwise: () => Yup.string(),
    }),
  });

  // Hooks
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(FormSchema),
  });

  // Handle Submit Form
  const onSubmit = (data) => {
    setData(data);

    const params = {
      clientId: isAdmin ? data.client.value : clientId,
      jumlahKode: isRandom ? data.random_codes : 0,
      arrKode: !isRandom ? data.custom_codes : '',
      projectId: idProject,
      tglKadaluarsa: expiredDate,
    };

    addCodes(params).then((res) => {
      if (res.status === 'Ok') {
        history.push(`/project/detail/${idProject}`);
      } else {
        setErrorMessage(res.message);
      }
    });
  };

  useEffect(() => {
    if (isAdmin) {
      getClients().then((res) => {
        if (res.status === 'OK') {
          const options = res.data.map((client) => {
            return {
              value: client.id,
              label: client.nama,
            };
          });

          setClientOptions(options);
        } else {
          setErrorMessage(res.message);
        }
      });
    }
  }, [isAdmin]);

  return (
    <div
      className="d-flex flex-md-row flex-column"
      style={{ background: 'url(../../assets/dashboard/pattern.svg), #cee1ea' }}
    >
      <SideBar />
      <div className="container py-5">
        <TopSection />

        <Breadcrumb className="mt-3">
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/', className: 'text-decoration-none' }}>
            Beranda
          </Breadcrumb.Item>
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: '/project', className: 'text-decoration-none' }}
          >
            Project
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Assign Peserta</Breadcrumb.Item>
        </Breadcrumb>

        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

        <Card>
          <Card.Body className="p-4">
            <Form onSubmit={handleSubmit(onSubmit)} className="row">
              <Col sm="12" className="mb-3">
                <Form.Label>
                  Pilih Client <Required text="*" />
                </Form.Label>
                <Controller
                  name="client"
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={clientOptions}
                      classNamePrefix="select"
                      className={classnames('react-select', {
                        'is-invalid': data !== null && data.client === '',
                      })}
                      {...field}
                    />
                  )}
                />
              </Col>

              <Col sm="12" className="mb-3">
                <Form.Label>Buat Kode Random?</Form.Label>
                <Controller
                  name="is_random"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Form.Check
                      type="checkbox"
                      id="is_random"
                      label="Random"
                      onChange={() => {
                        onChange(!isRandom);
                        setIsRandom(!isRandom);
                      }}
                    />
                  )}
                />
              </Col>

              <Col sm="6" className="mb-3">
                <Form.Label>
                  Jumlah Kode <Required text="*" />
                </Form.Label>
                <Controller
                  name="random_codes"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      type="number"
                      placeholder="0"
                      disabled={!isRandom}
                      isInvalid={errors.random_codes && true}
                      {...field}
                    />
                  )}
                />
              </Col>

              <Col sm="6" className="mb-3">
                <Form.Label>
                  Tanggal Kadaluarsa <Required text="*" />
                </Form.Label>
                <Flatpickr
                  value={expiredDate}
                  id="date-time-picker"
                  className="form-control bg-transparent"
                  onChange={(date) => {
                    setExpiredDate(date[0]);
                  }}
                />
              </Col>

              <Col sm="12" className="mb-3">
                <Form.Label>
                  Kustom Kode <Required text="*" />
                </Form.Label>
                <div>
                  <Form.Text>
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <span className="ms-2">Maksimal panjang karakter kode adalah 50!</span>
                  </Form.Text>
                </div>
                <Controller
                  name="custom_codes"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      as="textarea"
                      className="mt-1"
                      rows={5}
                      disabled={isRandom}
                      isInvalid={errors.custom_codes && true}
                      {...field}
                    ></Form.Control>
                  )}
                />
              </Col>

              <Col sm="12" className="text-end">
                <Button variant="outline-danger" className="me-3">
                  Batalkan
                </Button>
                <Button type="submit">Simpan</Button>
              </Col>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: state?.user?.isAdmin,
    clientId: state?.user?.user?.client?.id,
  };
};

export default connect(mapStateToProps, null)(AssignPeserta);
