import { useState } from "react";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukMiddle from "./PetunjukMiddle";
import PetunjukZero from "../../../Containers/PetunjukZero";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihanOne = ["Batu Bata", "Rumah", "Semen", "Putih", "Dinding"];
  const arrPilihanTwo = ["Hujan", "Hari", "Lembab", "Angin", "Kering"];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>Ditentukan tiga kata.</p>
        <br />
        <p>
          Antara kata pertama dengan kata kedua terdapat suatu hubungan yang
          tertentu.
        </p>
        <br />
        <p>
          Antara kata ketiga dan salah satu diantara lima kata pilihan harus
          pula terdapat hubungan yang sama, carilah kata itu!
        </p>
        <br />
        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="Hutan : pohon = Tembok : ?"
        arrPilihan={arrPilihanOne}
        kunci="Batu Bata"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban Anda benar! Hubungan hutan dan pohon adalah hutan terdiri dari pohon-pohon, maka tembok terdiri dari <b>Batu Bata</b>."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="Gelap : terang = basah : ?"
        arrPilihan={arrPilihanTwo}
        kunci="Kering"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban Anda benar! Lawan kata gelap adalah terang maka lawan kata dari basah adalah <b>Kering</b>."
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
