import { Link } from 'react-router-dom';
import confusePeople from '../../assets/img/freepik/confuse-people.jpg';
import styles from './NotFound.module.css';

export default function NotFoundPages() {
  return (
    <div className={styles.container}>
      <img
        className={styles.notfoundImages}
        src={confusePeople}
        alt="confuse people"
      />
      <h1>Sepertinya kamu tersesat. </h1>
      <Link to="/" className={styles.talentaButton}>
        Yuk balik lagi
      </Link>
      <a
        rel="noreferrer"
        target="_blank"
        className="freepik"
        href="https://www.freepik.com/free-vector/flat-people-group-asking-questions_13560778.htm#query=human&position=11&from_view=keyword"
      >
        Image by Freepik
      </a>
    </div>
  );
}
