import React, { useState } from 'react';
import { Button, Card, Form, Modal, Table } from 'react-bootstrap';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ImgPreview from 'assets/img/book.png';

const TableImages = () => {
  const [modalAdd, setModalAdd] = useState(false);
  const [imgPreview, setImgPreview] = useState(null);

  const images = [
    {
      id: 1,
      name: 'Table 1',
      link: '/upload/blalala.jpg',
    },
    {
      id: 2,
      name: 'Table 2',
      link: '/upload/blalala.jpg',
    },
  ];

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImgPreview(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Card className="mb-5">
      <div className="my-3 px-3">
        <Button onClick={() => setModalAdd(!modalAdd)}>Tambah Gambar</Button>
      </div>
      <Card.Body>
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th style={{ width: '80px' }}>No</th>
              <th>Nama</th>
              <th>Preview</th>
              <th style={{ width: '250px' }}>Opsi</th>
            </tr>
          </thead>
          <tbody>
            {images.map((img, idx) => {
              return (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{img.name}</td>
                  <td>
                    <img src={ImgPreview} width={38} alt="preview" className="img-thumbnail" />
                  </td>
                  <td>
                    <Button
                      size="sm"
                      onClick={() => navigator.clipboard.writeText(img.link)}
                      className="me-2 mb-2 mb-sm-0"
                    >
                      Copy
                    </Button>
                    <Button size="sm" variant="success" className="me-2 mb-2 mb-sm-0">
                      Preview
                    </Button>
                    <Button size="sm" variant="danger">
                      Hapus
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>

      <Modal centered show={modalAdd} onHide={() => setModalAdd(!modalAdd)}>
        <Modal.Header closeButton>Header</Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Nama</Form.Label>
              <Form.Control type="text" className="mb-3" />
            </Form.Group>
            <Form.Group>
              <Form.Label>Gambar</Form.Label>
              <Form.Control type="file" className="mb-3" onChange={handleUploadImage} />
            </Form.Group>

            <div>{imgPreview && <img src={imgPreview} alt="preview" className="img-fluid" />}</div>
            <Button type="submit" className="w-100 my-3">
              Upload
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Card>
  );
};

export default TableImages;
