import { connect } from "react-redux";
import { useEffect, useState } from "react";
import PetunjukModal from "../../Components/PetunjukModal";
import Petunjuk from "../Petunjuk";
import SoalHeader from "../../Components/SoalHeader";
import Navigation from "../../Components/Navigation";
import { setAnswerLS, setJawabansLS, setSisaWaktuLS } from "../../Utils";
import SoalContainer from "../../Containers/SoalContainer";
import SoalContent from "../../Containers/SoalContent";
import {
  mapDispatchToPropsSoal,
  mapStateToPropsSoal,
} from "../../Utils/reduxMap";
import styles from "./Soal.module.css";
import Pilihan from "../Pilihan";
import SoalImage from "../../Components/SoalImage";
import ButtonSoal from "../../Components/ButtonSoal";

const Soal = ({ currentSoal, currentTes, replaceJawabans }) => {
  const [answer, setAnswer] = useState("--");
  const [sisaWaktu, setSisaWaktu] = useState(-1);
  const [isShowSelesaiModal, setIsShowSelesaiModal] = useState(false);
  const [isShowPetunjukModal, setIsShowPetunjukModal] = useState(false);

  useEffect(() => {
    setSisaWaktuLS(currentTes?.tes?.waktu, setSisaWaktu);
  }, [currentTes?.tes?.waktu]);

  useEffect(() => {
    setAnswer("--");
    setAnswerLS(currentSoal, setAnswer);
  }, [currentSoal]);

  useEffect(() => {
    setJawabansLS(answer, replaceJawabans);
  }, [answer, replaceJawabans]);

  return (
    <SoalContainer
      isShowSelesaiModal={isShowSelesaiModal}
      setIsShowSelesaiModal={setIsShowSelesaiModal}
    >
      <SoalHeader
        setIsShowPetunjukModal={setIsShowPetunjukModal}
        sisaWaktu={sisaWaktu}
        type="cact"
      />
      <SoalContent>
        <SoalImage src="/images/tes/cact/cact-contoh.png" />

        <section className={styles.grid}>
          <p>A</p>
          <p></p>
          <p>B</p>
          <p></p>
          <p>C</p>

          <img
            src={`/images/tes/cact/${JSON.parse(currentSoal.soal)[0]}`}
            alt={JSON.parse(currentSoal.soal)[0]}
          />
          <font color="#FB0606">
            <b>{JSON.parse(currentSoal.soal)[1]}</b>
          </font>
          <img
            src={`/images/tes/cact/${JSON.parse(currentSoal.soal)[2]}`}
            alt={JSON.parse(currentSoal.soal)[2]}
          />
          <font color="#FB0606">
            <b>{JSON.parse(currentSoal.soal)[3]}</b>
          </font>
          <img
            src={`/images/tes/cact/${JSON.parse(currentSoal.soal)[4]}`}
            alt={JSON.parse(currentSoal.soal)[4]}
          />

          <p></p>
          <section style={{ display: "flex", gap: "7px" }}>
            <Pilihan
              label="<"
              onClick={() => setAnswer((c) => "<" + c[1])}
              answer={answer[0]}
            />
            <Pilihan
              label="="
              onClick={() => setAnswer((c) => "=" + c[1])}
              answer={answer[0]}
            />
            <Pilihan
              label=">"
              onClick={() => setAnswer((c) => ">" + c[1])}
              answer={answer[0]}
            />
          </section>
          <p></p>
          <section style={{ display: "flex", gap: "7px" }}>
            <Pilihan
              label="<"
              onClick={() => setAnswer((c) => c[0] + "<")}
              answer={answer[1]}
            />
            <Pilihan
              label="="
              onClick={() => setAnswer((c) => c[0] + "=")}
              answer={answer[1]}
            />
            <Pilihan
              label=">"
              onClick={() => setAnswer((c) => c[0] + ">")}
              answer={answer[1]}
            />
          </section>
          <p></p>
        </section>
        <ButtonSoal />
        <Navigation type="cact" />
      </SoalContent>
      <PetunjukModal
        isShow={isShowPetunjukModal}
        onHide={() => setIsShowPetunjukModal(false)}
      >
        <Petunjuk
          isModal
          tutupModalHandler={() => setIsShowPetunjukModal(false)}
        />
      </PetunjukModal>
    </SoalContainer>
  );
};

export default connect(mapStateToPropsSoal, mapDispatchToPropsSoal)(Soal);
