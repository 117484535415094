import PetunjukHeader from '../../Components/PetunjukHeader';
import styles from './PetunjukContainer.module.css';

const PetunjukContainer = ({ isModal, children, tutupModalHandler }) => {
  return (
    <div
      className={styles.container}
      style={{ marginBottom: isModal ? '' : '100px' }}
    >
      <PetunjukHeader isModal={isModal} onClose={tutupModalHandler} />
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default PetunjukContainer;
