import PetunjukZero from "../../../../Containers/PetunjukZero";

const PetunjukZeroCfitThreeB = ({ next, selectedIndex }) => (
  <PetunjukZero selectedIndex={selectedIndex}>
    <p>Perhatikan contoh untuk memahami soal yang akan Anda kerjakan.</p>
    <br />
    <p>
      Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan
      contoh soal sebelum mengerjakan.
    </p>
    <br />
    <button onClick={next}>Lanjut</button>
  </PetunjukZero>
);

export default PetunjukZeroCfitThreeB;
