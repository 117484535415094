import { useEffect, useState } from 'react';
import check from '../../../../assets/psikotes/check.png';
import checkFilled from '../../../../assets/psikotes/check_filled.png';
import styles from './CheckboxText.module.css';

const CheckboxText = ({ label, setAnswer, answer, point }) => {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (typeof answer === 'string') {
      answer = JSON.parse(answer);
    }

    answer?.forEach((item) => {
      if (item.label === label) {
        setIsSelected(true);
      }
    });
  }, [answer, label]);

  const removeAnswerHandler = () =>
    [...answer].filter((item) => item.label !== label);

  return (
    <>
      <div
        className={styles.pilihanItem}
        style={{
          backgroundColor: isSelected ? '#3BC2AA' : '#e5e8ee',
        }}
        onClick={() => {
          isSelected
            ? setAnswer(removeAnswerHandler())
            : setAnswer([...answer, { label, point }]);
          setIsSelected(!isSelected);
        }}
      >
        <img
          src={isSelected ? checkFilled : check}
          alt="check"
          className={styles.check}
          draggable={false}
        />
        <p>{label}</p>
      </div>
    </>
  );
};

export default CheckboxText;
