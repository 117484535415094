import { connect } from "react-redux";
import { tutupPetunjukHandler } from "../../Utils";
import { mapDispatchToPropsPetunjuk } from "../../Utils/reduxMap";
import styles from "./PetunjukLast.module.css";

const PetunjukLast = ({
  isModal,
  previous,
  selectedIndex,
  showWhenIndex,
  tutupModalHandler,
  changeCurrentPetunjuk,
}) => {
  return (
    <div
      className={styles.petunjukLast}
      style={{ display: selectedIndex === showWhenIndex ? "flex" : "none" }}
    >
      <p>
        Ketika menjawab persoalan terakhir, maka tombol selesai akan tampak.
      </p>
      <p>Tekan tombol tersebut untuk menyelesaikan ini.</p>
      <br />
      <p>Apabila sudah siap, tutup petunjuk ini dan waktu mulai berjalan.</p>
      <br />

      <div className={styles.footer}>
        {previous && <button onClick={previous}>Kembali</button>}
        {isModal ? (
          <button onClick={tutupModalHandler}>Tutup</button>
        ) : (
          <button onClick={() => tutupPetunjukHandler(changeCurrentPetunjuk)}>
            Tutup
          </button>
        )}
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToPropsPetunjuk)(PetunjukLast);
