import Benar from "../../../../Components/PetunjukMiddle/Benar";
import Footer from "../../../../Components/PetunjukMiddle/Footer";
import PetunjukMiddleContainer from "../../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import PetunjukParagraph from "../../../../Components/PetunjukMiddle/PetunjukParagraph";
import SoalImage from "../../../../Components/SoalImage";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  pesan,
  namaFile,
  namaPilihan,
  previous,
  next,
}) => (
  <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
    <br />
    <SoalImage src={`/images/tes/tiki-t-10/${namaFile}`} alt="petunjuk" />
    <PetunjukParagraph>
      Gambar diatas adalah gambar kompleks, sementara 5 gambar dibawah adalah
      gambar sederhana, Pilihlah mana gambar bawah yang merupakan bagian dari
      gambar kompleks diatas
    </PetunjukParagraph>
    <SoalImage src={`/images/tes/tiki-t-10/${namaPilihan}`} alt="petunjuk" />

    <Benar isShow htmlString={pesan} />

    <Footer previous={previous} next={next} condition />
  </PetunjukMiddleContainer>
);

export default PetunjukMiddle;
