import { useEffect } from "react";
import { connect } from "react-redux";
import { generateEmptyJawabans } from "../../../Utils";
import {
  mapDispatchToPropsTes,
  mapStateToPropsTes,
} from "../../../Utils/reduxMap";
import Petunjuk from "./Petunjuk";
import Soal from "./Soal";

const CfitThreeATwo = ({ currentPetunjuk, currentTes, replaceJawabans }) => {
  useEffect(() => {
    generateEmptyJawabans(replaceJawabans, []);
  }, [replaceJawabans]);
  return (
    <>
      {currentPetunjuk === 39 && <Petunjuk />}
      {currentPetunjuk !== 39 && currentTes.tesId === 39 && <Soal />}
    </>
  );
};

export default connect(
  mapStateToPropsTes,
  mapDispatchToPropsTes
)(CfitThreeATwo);
