import { ubahFormat } from "../../../utils/tanggal";
import Table from "./Table";
import styles from "./SectionTwo.module.css";

const SectionTwo = ({ peserta }) => {
  const columns = [
    {
      Header: "No.",
      accessor: "nomor",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Status Email",
      accessor: "statusEmail",
    },
    {
      Header: "Tanggal/Waktu",
      accessor: "waktu",
    },
  ];

  const data = [
    {
      nomor: 1,
      email: peserta?.email,
      statusEmail: "Dibuka",
      waktu: ubahFormat(peserta?.testDate),
    },
  ];

  return (
    <div className={styles.container}>
      <Table columns={columns} data={data} />
    </div>
  );
};

export default SectionTwo;
