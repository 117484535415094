// Documentation
// https://github.com/yairEO/tagify

import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "./FilterProject.module.css";

import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css"; // Tagify CSS
import { API } from "../../../services/API";

// Tagify settings object
const baseTagifySettings = {
  maxTags: 4,
  //backspace: "edit",
  placeholder: "Filter peserta by project name",
  dropdown: {
    enabled: 0, // always show suggestions dropdown
  },
};

// this is an example React component which implemenets Tagify within
// itself. This example is a bit elaborate, to demonstrate what's possible.
const FilterProject = ({ setProjectTags }) => {
  const tagifyRef1 = useRef();
  const [tagifyProps, setTagifyProps] = useState({});

  // on component mount
  useEffect(() => {
    const getProjects = async () => {
      try {
        const res = await API.get("/projects");
        const projectNames = res.data.data.map((project) => project.nama);

        setTagifyProps((lastProps) => ({
          ...lastProps,
          whitelist: projectNames,
          showFilteredDropdown: "a",
          loading: false,
        }));
      } catch (error) {
        console.log(error);
      }
    };

    setTagifyProps({ loading: true });

    getProjects();

    // simulate state change where some tags were deleted
    setTimeout(
      () =>
        setTagifyProps((lastProps) => ({
          ...lastProps,
          defaultValue: [],
          showFilteredDropdown: false,
        })),
      5000
    );
  }, []);

  // merged tagify settings (static & dynamic)
  const settings = {
    ...baseTagifySettings,
  };

  const onChange = useCallback(
    (e) => {
      console.log("CHANGED:", e.detail.value);

      let projectTags = [];
      if (e.detail.value) {
        projectTags = JSON.parse(e.detail.value)?.map((tag) => tag.value);
      }
      setProjectTags(projectTags);
    },
    [setProjectTags]
  );

  // access Tagify internal methods example:
  const clearAll = () => {
    tagifyRef1.current && tagifyRef1.current.removeAllTags();
  };

  return (
    <div className={styles.wrapper}>
      <h2>Filter Project</h2>
      <Tags
        className={styles.tagify}
        tagifyRef={tagifyRef1}
        settings={settings}
        autoFocus={true}
        {...tagifyProps}
        onChange={onChange}
        onEditInput={() => console.log("onEditInput")}
        onEditBeforeUpdate={() => console.log`onEditBeforeUpdate`}
        onEditUpdated={() => console.log("onEditUpdated")}
        onEditStart={() => console.log("onEditStart")}
        onEditKeydown={() => console.log("onEditKeydown")}
        onDropdownShow={() => console.log("onDropdownShow")}
        onDropdownHide={() => console.log("onDropdownHide")}
        onDropdownSelect={() => console.log("onDropdownSelect")}
        onDropdownScroll={() => console.log("onDropdownScroll")}
        onDropdownNoMatch={() => console.log("onDropdownNoMatch")}
        onDropdownUpdated={() => console.log("onDropdownUpdated")}
      />
      <button className={styles.clearAll} onClick={clearAll}>
        Clear Tag
      </button>
    </div>
  );
};

export default FilterProject;
