import { connect } from 'react-redux';
import check from '../../../../assets/psikotes/check.png';
import checkFilled from '../../../../assets/psikotes/check_filled.png';
import { pilihanOnClickHandler } from '../../Utils';
import { mapDispatchToPropsPilihan } from '../../Utils/reduxMap';
import styles from './PilihanText.module.css';

const PilihanText = ({
  label,
  onClick,
  answer,
  replaceJawabans,
  changeCurrentSoal,
  setIsShowSelesaiModal,
  jwb,
  gap = '43px',
  height = '65.75px',
}) => {
  return (
    <div
      className={styles.pilihanItem}
      style={{
        backgroundColor: answer === label ? '#3BC2AA' : '#e5e8ee',
        gap,
        padding: '1%',
      }}
      onClick={() => {
        onClick
          ? onClick()
          : pilihanOnClickHandler(
              jwb ? jwb : label,
              replaceJawabans,
              changeCurrentSoal,
              setIsShowSelesaiModal
            );
      }}
    >
      <img
        src={answer === (jwb ? jwb : label) ? checkFilled : check}
        alt="check"
        className={styles.check}
      />

      <p dangerouslySetInnerHTML={{ __html: label }} />
    </div>
  );
};

export default connect(null, mapDispatchToPropsPilihan)(PilihanText);
