import styles from "./Left.module.css";

const Left = () => {
  return (
    <div className={styles.left}>
      <h1>Ronald Richards</h1>
      <p className={styles.subtitle}>georgia.young@example.com</p>

      <table className={styles.table}>
        <tbody>
          <tr className={styles.tableRow}>
            <td>Tanggal Pengerjaan</td>
            <td>24/9/2021, 09.00 WIB</td>
          </tr>
          <tr className={styles.tableRow}>
            <td>Tanggal Lahir</td>
            <td>8/40/03</td>
          </tr>
          <tr className={styles.tableRow}>
            <td>Jenis Kelamin</td>
            <td>Laki-laki</td>
          </tr>
          <tr className={styles.tableRow}>
            <td>Institusi</td>
            <td>Pemerintah</td>
          </tr>
          <tr className={styles.tableRow}>
            <td>Pendidikan Terakhir</td>
            <td>S1</td>
          </tr>
          <tr className={styles.tableRow}>
            <td>Provinsi</td>
            <td>Bantern</td>
          </tr>
        </tbody>
      </table>

      <button>Cetak Laporan</button>
    </div>
  );
};

export default Left;
