import ProjectDetailContent from '../../components/ProjectDetailContent';
import SideBar from '../../components/SideBar';
import styles from './ProjectDetailPage.module.css';

const ProjectDetailPage = () => {
  return (
    <div className={styles.container}>
      <SideBar />
      <ProjectDetailContent />
    </div>
  );
};

export default ProjectDetailPage;
