import { useState } from "react";
import Footer from "../../../../../Components/PetunjukMiddle/Footer";
import PilihanWrapper from "../../../../../Components/PilihanWrapper";
import Benar from "../../../../../Components/PetunjukMiddle/Benar";
import Salah from "../../../../../Components/PetunjukMiddle/Salah";
import PetunjukMiddleContainer from "../../../../../Components/PetunjukMiddle/PetunjukMiddleContainer";
import PetunjukParagraph from "../../../../../Components/PetunjukMiddle/PetunjukParagraph";
import SoalImage from "../../../../../Components/SoalImage";
import PilihanGambar from "../../../../../Components/PilihanGambar";

const PetunjukMiddle = ({
  selectedIndex,
  showWhenIndex,
  pesanBenar,
  namaFile,
  arrPilihan,
  kunci,
  previous,
  next,
}) => {
  const [answer, setAnswer] = useState("");
  const imagePath = "/images/tes/cfit/cfit3b/cfit3b1/petunjuk";

  return (
    <PetunjukMiddleContainer isShow={selectedIndex === showWhenIndex}>
      <PetunjukParagraph>
        Terdapat 3 kotak pertama dan satu kotak kosong, dimana alternatif
        jawaban terdapat pada kotak a,b,c,d, e atau f. Tugas anda adalah
        menentukan deret pola yang terdapat pada 3 kotak pertama, dan tentukan
        pola selanjutnya setelah 3 kotak pertama tersebut.
      </PetunjukParagraph>
      <SoalImage src={`${imagePath}/${namaFile}`} alt="Contoh" />

      <PilihanWrapper col={6}>
        {arrPilihan?.map((item, index) => (
          <PilihanGambar
            label={item}
            onClick={() => setAnswer(item)}
            answer={answer}
            key={index}
            path={imagePath}
          />
        ))}
      </PilihanWrapper>

      <Benar
        isShow={answer === kunci}
        htmlString={pesanBenar}
        textAlign="justify"
      />
      <Salah isShow={answer !== kunci && answer !== ""} />
      <Footer
        min={1}
        max={6}
        previous={previous}
        next={next}
        condition={answer === kunci}
      />
    </PetunjukMiddleContainer>
  );
};

export default PetunjukMiddle;
