import styles from './SelectGroup.module.css';

const SelectGroup = ({
  children,
  label,
  state,
  setState,
  required = true,
  id = '',
}) => {
  let psikotesTalenta = localStorage.getItem('psikotesTalenta');
  let dataMappingPegawai = localStorage.getItem('dataMappingPegawai');
  psikotesTalenta = psikotesTalenta && JSON.parse(psikotesTalenta);

  const dataMappingPegawaiTesId = 121;
  let saveData;

  if (dataMappingPegawai) {
    dataMappingPegawai = JSON.parse(dataMappingPegawai);
    saveData = dataMappingPegawai;
  }

  const changeHandler = (e) => {
    if (psikotesTalenta?.currentTes?.tesId === dataMappingPegawaiTesId) {
      saveData = { ...saveData, [id]: e.target.value };

      localStorage.setItem('dataMappingPegawai', JSON.stringify(saveData));
    }

    return setState(e.target.value);
  };
  return (
    <div className={styles.selectGroup}>
      <label>{label}</label>
      <br />
      <select value={state} onChange={changeHandler} required={required}>
        {children}
      </select>
    </div>
  );
};

export default SelectGroup;
