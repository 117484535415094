import { useState } from "react";
import PetunjukLast from "../../Components/PetunjukLast";
import PetunjukContainer from "../../Containers/PetunjukContainer";
import PetunjukZero from "../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihanOne = [
    "A. Orang ini adalah orang idiot",
    "B. Orang ini belum tentu idiot",
    "C. Pernyataan a dan b adalah salah",
    "D. Jawaban semuanya salah",
  ];
  const arrPilihanTwo = [
    "A. Burung unta dapat terbang",
    "B. Burung unta memang tidak dapat terbang asa",
    "C. Burung unta belum tentu tidak dapat terbang",
    "D. Jawaban semuanya salah",
  ];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Bacalah pernyataan-pernyataan dalam tugas di bawah ini dengan baik,
          kemudian jawablah dengan cara memilih satu jawaban dari alternatif
          jawaban yang tersedia. Pilih jawaban yang Anda anggap benar untuk
          setiap soal.
        </p>
        <br />
        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="Orang-orang idiot tidak dapat menulis. Orang ini tidak dapat menulis. Jadi:"
        arrPilihan={arrPilihanOne}
        kunci="B. Orang ini belum tentu idiot"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban yang benar <strong>B</strong> oleh karena itu pilih jawaban tersebut agar benar."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="Kebanyakan burung dapat terbang. Burung unta adalah juga seekor burung. Jadi:"
        arrPilihan={arrPilihanTwo}
        kunci="C. Burung unta belum tentu tidak dapat terbang"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban yang benar <strong>C</strong> oleh karena itu pilih jawaban tersebut agar benar."
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
