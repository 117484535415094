import { menuClasses } from 'react-pro-sidebar';

const sidebarTheme = {
  sidebar: {
    backgroundColor: '#ffffff',
    color: '#607489',
  },
  menu: {
    menuContent: '#fbfcfd',
    icon: '#000',
    hover: {
      backgroundColor: '#f0f8f9',
      color: '#0098e5',
      icon: '#0098e5',
    },
    disabled: {
      color: '#9fb6cf',
    },
    active: {
      color: '#0098e5',
      backgroundColor: '#f0f8f9',
      icon: '#0098e5',
    },
  },
};

export const menuItemStyles = {
  root: {
    fontSize: '13px',
    fontWeight: 400,
  },
  icon: {
    color: sidebarTheme.menu.icon,
    [`&.${menuClasses.disabled}`]: {
      color: sidebarTheme.menu.disabled.color,
    },
    [`&.${menuClasses.active}`]: {
      color: sidebarTheme.menu.active.icon,
    },
  },
  SubMenuExpandIcon: {
    color: '#b6b7b9',
  },
  subMenuContent: ({ level }) => ({
    backgroundColor: level === 0 ? sidebarTheme.menu.menuContent : 'transparent',
  }),
  button: {
    [`&.${menuClasses.disabled}`]: {
      color: sidebarTheme.menu.disabled.color,
    },
    [`&.${menuClasses.active}`]: {
      color: sidebarTheme.menu.active.color,
      backgroundColor: sidebarTheme.menu.active.backgroundColor,
    },
    '&:hover': {
      backgroundColor: sidebarTheme.menu.hover.backgroundColor,
      color: sidebarTheme.menu.hover.color,
      '& svg': {
        color: sidebarTheme.menu.hover.icon,
      },
    },
  },
  label: ({ open }) => ({
    fontWeight: open ? 600 : undefined,
  }),
};

export const isObjectEmpty = (obj = {}) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

export const getEmptyData = (sum = 1) => {
  const render = [];
  for (let i = 0; i < sum; i++) {
    render.push(
      <td key={i} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        -
      </td>
    );
  }

  return render;
};

export const getCurrentDateTime = () => {
  const currentDate = new Date();

  const day = currentDate.getDate().toString().padStart(2, '0');
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Perhatikan penambahan 1 karena indeks bulan dimulai dari 0
  const year = currentDate.getFullYear().toString();
  const hours = currentDate.getHours().toString().padStart(2, '0');
  const minutes = currentDate.getMinutes().toString().padStart(2, '0');

  const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}`;

  return formattedDateTime;
};
