export default function SubColumn1Kraepelin({ format }) {
  return (
    <>
      <th colSpan={format.perColRows} rowSpan={format.rowSpanValue}>
        PRESTASI KERJA
      </th>
      <th colSpan={format.perColRows} rowSpan={format.rowSpanValue}>
        TEMPO KERJA
      </th>
      <th colSpan={format.perColRows} rowSpan={format.rowSpanValue}>
        KETELITIAN
      </th>
      <th colSpan={format.perColRows} rowSpan={format.rowSpanValue}>
        KONSISTENSI
      </th>
      <th colSpan={format.perColRows} rowSpan={format.rowSpanValue}>
        KETAHANAN
      </th>
    </>
  );
}
