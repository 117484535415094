import { useEffect } from 'react';
import { connect } from 'react-redux';
import { generateEmptyJawabans } from '../Utils';
import { mapDispatchToPropsTes, mapStateToPropsTes } from '../Utils/reduxMap';
import Petunjuk from './Petunjuk';
import Soal from './Soal';

const EngagementTestTalenta = ({ currentPetunjuk, currentTes, replaceJawabans }) => {
  useEffect(() => generateEmptyJawabans(replaceJawabans, '[]'), [replaceJawabans]);

  return (
    <>
      {currentPetunjuk === 191 && <Petunjuk />}
      {currentPetunjuk !== 191 && currentTes.tesId === 191 && <Soal />}
    </>
  );
};

export default connect(mapStateToPropsTes, mapDispatchToPropsTes)(EngagementTestTalenta);
