import { connect } from "react-redux";
import { useEffect, useState } from "react";
import PetunjukModal from "../../../../Components/PetunjukModal";
import Petunjuk from "../Petunjuk";
import SoalHeader from "../../../../Components/SoalHeader";
import ButtonSoal from "../../../../Components/ButtonSoal";
import Navigation from "../../../../Components/Navigation";
import { setAnswerLS, setJawabansLS, setSisaWaktuLS } from "../../../../Utils";
import SoalContainer from "../../../../Containers/SoalContainer";
import SoalContent from "../../../../Containers/SoalContent";
import {
  mapDispatchToPropsSoal,
  mapStateToPropsSoal,
} from "../../../../Utils/reduxMap";
import CheckboxGambar from "../../../../Components/CheckboxGambar";
import PilihanWrapper from "../../../../Components/PilihanWrapper";

const Soal = ({ currentSoal, currentTes, replaceJawabans }) => {
  const [answer, setAnswer] = useState([]);
  const [sisaWaktu, setSisaWaktu] = useState(-1);
  const [isShowSelesaiModal, setIsShowSelesaiModal] = useState(false);
  const [isShowPetunjukModal, setIsShowPetunjukModal] = useState(false);

  const imagePath = "/images/tes/cfit/cfitkpk/cfita2kpk";

  useEffect(() => {
    setSisaWaktuLS(currentTes?.tes?.waktu, setSisaWaktu);
  }, [currentTes?.tes?.waktu]);

  useEffect(() => {
    setAnswer([]);
    setAnswerLS(currentSoal, setAnswer);
  }, [currentSoal]);

  useEffect(() => {
    setJawabansLS(answer, replaceJawabans);
  }, [answer, replaceJawabans]);

  const pointArray = [1, 2, 4, 8, 16, 32];

  return (
    <SoalContainer
      isShowSelesaiModal={isShowSelesaiModal}
      setIsShowSelesaiModal={setIsShowSelesaiModal}
    >
      <SoalHeader
        setIsShowPetunjukModal={setIsShowPetunjukModal}
        sisaWaktu={sisaWaktu}
        type="multiple"
      />
      <SoalContent>
        <PilihanWrapper col={5}>
          {currentSoal?.pilihan?.map((option, index) => (
            <CheckboxGambar
              key={option}
              fileName={option}
              point={pointArray[index]}
              setAnswer={setAnswer}
              answer={answer}
              imagePath={imagePath}
              alt="Pilihan CFIT A2 KPK"
            />
          ))}
        </PilihanWrapper>
        <ButtonSoal setIsShowSelesaiModal={setIsShowSelesaiModal} />
        <Navigation type="multiple" />
      </SoalContent>
      <PetunjukModal
        isShow={isShowPetunjukModal}
        onHide={() => setIsShowPetunjukModal(false)}
      >
        <Petunjuk
          isModal
          tutupModalHandler={() => setIsShowPetunjukModal(false)}
        />
      </PetunjukModal>
    </SoalContainer>
  );
};

export default connect(mapStateToPropsSoal, mapDispatchToPropsSoal)(Soal);
