import { Link } from "react-router-dom";
import TopSection from "../TopSection";
import styles from "./LaporanPesertaContent.module.css";
import SectionEight from "./SectionEight";
import SectionEleven from "./SectionEleven";
import SectionFive from "./SectionFive";
import SectionFour from "./SectionFour";
import SectionFourteen from "./SectionFourteen";
import SectionNine from "./SectionNine";
import SectionOne from "./SectionOne";
import SectionSeven from "./SectionSeven";
import SectionSix from "./SectionSix";
import SectionTen from "./SectionTen";
import SectionThirteen from "./SectionThirteen";
import SectionThree from "./SectionThree";
import SectionTwelve from "./SectionTwelve";
import SectionTwo from "./SectionTwo";

const LaporanPesertaContent = () => {
  return (
    <div className={styles.container}>
      <TopSection />

      <div className={styles.mainSection}>
        <p className={styles.path}>
          <Link className={styles.pathLink} to="/">
            Beranda /
          </Link>{" "}
          <Link className={styles.pathLink} to="/manajemen-peserta">
            Manajemen Peserta /
          </Link>{" "}
          <span className={styles.pathNow}>Laporan Peserta</span>
        </p>

        <div className={styles.body}>
          <SectionOne />
          <SectionTwo />
          <SectionThree />
          <SectionFour />
          <SectionFive />

          <SectionSix />
          <SectionSeven />
          <SectionEight />
          <SectionNine />
          <SectionTen />

          <SectionEleven />
          <SectionTwelve />
          <SectionThirteen />
          <SectionFourteen />
        </div>
      </div>
    </div>
  );
};

export default LaporanPesertaContent;
