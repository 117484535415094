import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihanOne = ["kucing", "tupai", "terwelu", "rubah", "landak"];
  const arrPilihanTwo = ["duka", "putus asa", "sengsara", "cinta", "benci"];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Soal-soal ini terdiri atas kalimat yang tidak lengkap. Untuk setiap
          kalimat disediakan 5 (lima) kemungkinan jawaban. Anda harus memilih
          satu kata yang cocok dari kelima jawaban yang ada tersebut.
        </p>
        <br />
        <p>Pilihlah kata yang tepat yang dapat menyempurnakan kalimat itu !</p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="Seekor kelinci pada umumnya mempunyai kesamaan dengan seekor"
        arrPilihan={arrPilihanOne}
        kunci="terwelu"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban yang benar <strong>terwelu</strong> karena kelinci paling banyak persamaan dengan binatang tersebut."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="Harapan</strong> memiliki lawan kata:"
        arrPilihan={arrPilihanTwo}
        kunci="putus asa"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Lawan dari Harapan adalah <strong>putus asa</strong> yang paling tepat."
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
