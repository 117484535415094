import React from 'react';

const HeaderSecond = () => {
  return (
    <React.Fragment>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        N
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        G
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        A
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        L
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        P
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        I
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        T
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        V
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        X
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        S
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        B
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        O
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        R
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        D
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        C
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        Z
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        E
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        K
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        F
      </th>
      <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
        W
      </th>
    </React.Fragment>
  );
};

export default HeaderSecond;
