import { useState } from "react";
import { connect } from "react-redux";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import { mapDispatchToPropsPetunjuk } from "../../../Utils/reduxMap";
import styles from "./Petunjuk.module.css";

const Petunjuk = ({
  changeCurrentPetunjuk,
  jumlahMenit,
  jumlahSoal,
  isModal,
  tutupModalHandler,
}) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);
  const tutupHandler = () => {
    const psikotesLS = JSON.parse(localStorage.getItem("psikotesTalenta"));
    psikotesLS.currentPetunjuk = 0;
    localStorage.setItem("psikotesTalenta", JSON.stringify(psikotesLS));

    changeCurrentPetunjuk(0);
  };

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Pada tes kali ini pada tiap persoalan diawali dengan petunjuk singkat.
          Baca dengan baik dan pahami petunjuk tersebut kemudian pilihlah salah
          satu jawaban yang dianggap sesuai. Tugas Anda hanya memilih satu
          jawaban yang dianggap benar
        </p>
        <br />
        <p>Tes memiliki batas waktu bekerja dengan cepat dan teliti.</p>
        <br />

        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <div
        className={styles.petunjukLast}
        style={{ display: index === 1 ? "flex" : "none" }}
      >
        <p>
          Tiap persoalan hanya terdapat satu jawaban yang dianggap benar. Waktu
          yang tersedia untuk mengerjakan tes ini {jumlahMenit} menit. Tes ini
          terdiri dari {jumlahSoal} soal.
        </p>
        <br />
        <p>
          Ketika menjawab persoalan terakhir, maka tombol selesai akan tampak.
          Tekan tombol tersebut untuk menyelesaikan tes ini.
        </p>
        <br />
        <p>Apabila sudah siap, tutup petunjuk ini dan waktu mulai berjalan.</p>

        <div className={styles.footer}>
          <button onClick={previousHandler}>Kembali</button>
          {isModal ? (
            <button onClick={tutupModalHandler}>Tutup</button>
          ) : (
            <button onClick={tutupHandler}>Tutup</button>
          )}
        </div>
      </div>
    </PetunjukContainer>
  );
};

export default connect(null, mapDispatchToPropsPetunjuk)(Petunjuk);
