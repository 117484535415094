import { useState } from "react";
import { connect } from "react-redux";
import InputGroup from "../Components/DataDiri/InputGroup";
import { nextTesHandlerUtil } from "../Utils";
import ButtonKirim from "../Components/DataDiri/ButtonKirim";
import { mapDispatchToPropsDataDiri } from "../Utils/reduxMap";
import DataDiriTitle from "../Components/DataDiri/DataDiriTitle";
import DataDiriContainer from "../Components/DataDiri/DataDiriContainer";
import DataDiriForm from "../Components/DataDiri/DataDiriForm";
import SectionWrapper from "../Components/DataDiri/SectionWrapper";
import HalfSection from "../Components/DataDiri/HalfSection";
import { API } from "../../../services/API";

const DataDiriSd = ({
  changeCurrentPetunjuk,
  changeCurrentSoal,
  changeCurrentTes,
}) => {
  //peserta
  const [nomorTes, setNomorTes] = useState("");
  const [namaLengkap, setNamaLengkap] = useState("");
  const [namaPanggilan, setNamaPanggilan] = useState("");
  const [asalTk, setAsalTk] = useState("");
  const [tanggalLahir, setTanggalLahir] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    submitToAPI();
  };

  const submitToAPI = async () => {
    const data = {
      nama: namaLengkap,
      tglLahir: tanggalLahir,
      namaPanggilan,
      dataDiriSd: {
        nomorTes,
        namaLengkap,
        namaPanggilan,
        asalTk,
        tanggalLahir,
      },
    };

    try {
      // console.log(data);
      // Upload to API
      const psikotesLS = JSON.parse(localStorage.getItem("psikotesTalenta"));
      await API.patch(`/peserta/${psikotesLS.peserta.id}`, data);

      nextTesHandlerUtil(
        changeCurrentTes,
        changeCurrentSoal,
        changeCurrentPetunjuk
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DataDiriContainer>
      <DataDiriTitle title="DATA PESERTA TES" />
      <DataDiriForm onSubmit={handleSubmit}>
        <SectionWrapper>
          <HalfSection>
            <InputGroup
              label="Nomor Tes*(Isi terserah jika tidak ada)"
              id="nomorTes"
              type="text"
              state={nomorTes}
              setState={setNomorTes}
            />
            <InputGroup
              label="Nama Lengkap"
              id="namaLengkap"
              type="text"
              state={namaLengkap}
              setState={setNamaLengkap}
            />
            <InputGroup
              label="Nama Panggilan"
              id="namaPanggilan"
              type="text"
              state={namaPanggilan}
              setState={setNamaPanggilan}
            />
          </HalfSection>
          <HalfSection>
            <InputGroup
              label="Tanggal Lahir"
              id="tanggal-lahir"
              type="date"
              state={tanggalLahir}
              setState={setTanggalLahir}
            />
            <InputGroup
              label="Asal TK / PAUD"
              id="asalTk"
              type="text"
              state={asalTk}
              setState={setAsalTk}
            />
          </HalfSection>
        </SectionWrapper>
        <ButtonKirim />
      </DataDiriForm>
    </DataDiriContainer>
  );
};

export default connect(null, mapDispatchToPropsDataDiri)(DataDiriSd);
