import React, { useState } from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import styles from './styles.module.css';

export default function Modal({
  isVisible,
  content,
  func,
  showClose = true,
  onCloseMask = true,
}) {
  const [modalVisible, setModalVisible] = useState(isVisible);
  return (
    <div className={styles.rodal_}>
      <Rodal
        visible={modalVisible}
        onClose={(e) => {
          setModalVisible(false);
          func[0](0);
          func[1](false);
        }}
        onClick={() => func()}
        animation="rotate"
        showCloseButton={showClose}
        width={340}
        closeMaskOnClick={onCloseMask}
      >
        <div className={styles.contentModal}>{content}</div>
      </Rodal>
    </div>
  );
}
