import { useState } from "react";
import PetunjukLast from "../../Components/PetunjukLast";
import PetunjukZero from "../../Containers/PetunjukZero";
import PetunjukContainer from "../../Containers/PetunjukContainer";
import styles from "./Petunjuk.module.css";
import SoalImage from "../../Components/SoalImage";
import PetunjukParagraph from "../../Components/PetunjukMiddle/PetunjukParagraph";
import Footer from "../../Components/PetunjukMiddle/Footer";
import Pilihan from "../Pilihan";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const [answerOne, setAnswerOne] = useState("");
  const [answerTwo, setAnswerTwo] = useState("");

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>Perhatikan petunjuk untuk memahami soal yang akan Anda kerjakan.</p>
        <br />
        <p>
          Tes memiliki batas waktu, bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum mengerjakan.
        </p>
        <br />
        <p style={{ color: "red" }}>
          Jangan ditutup dulu petunjuk ini, perhatikan contoh, Apabila petunjuk
          ini ditutup maka tes dimulai dan waktu berjalan.
        </p>

        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>
      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 1 ? "flex" : "none" }}
      >
        <br />
        <SoalImage src="/images/tes/cact/cact-contoh.png" />
        <PetunjukParagraph>
          Perhatikan pasangan simbol-angka di atas, tiap simbol masing-masing
          mewakili angka tertentu. Dalam persoalan yang akan disajikan,
          simbol-simbol itu akan ditempatkan di bawah huruf A, B dan C.
        </PetunjukParagraph>
        <Footer condition next={nextHandler} previous={previousHandler} />
      </div>

      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 2 ? "flex" : "none" }}
      >
        <br />
        <SoalImage src="/images/tes/cact/cact-contoh.png" />

        <section className={styles.grid}>
          <p>A</p>
          <p></p>
          <p>B</p>
          <p></p>
          <p>C</p>

          <img src="/images/tes/cact/cact6.png" alt="cact" />
          <font color="#FB0606">
            <b>14</b>
          </font>
          <img src="/images/tes/cact/cact8.png" alt="cact" />
          <font color="#FB0606">
            <b>13</b>
          </font>
          <img src="/images/tes/cact/cact3.png" alt="cact" />

          <p></p>
          <section style={{ display: "flex", gap: "7px" }}>
            <Pilihan
              label="<"
              onClick={() => setAnswerOne("<")}
              answer={answerOne}
            />
            <Pilihan
              label="="
              onClick={() => setAnswerOne("=")}
              answer={answerOne}
            />
            <Pilihan
              label=">"
              onClick={() => setAnswerOne(">")}
              answer={answerOne}
            />
          </section>
          <p></p>
          <section style={{ display: "flex", gap: "7px" }}>
            <Pilihan
              label="<"
              onClick={() => setAnswerTwo("<")}
              answer={answerTwo}
            />
            <Pilihan
              label="="
              onClick={() => setAnswerTwo("=")}
              answer={answerTwo}
            />
            <Pilihan
              label=">"
              onClick={() => setAnswerTwo(">")}
              answer={answerTwo}
            />
          </section>
          <p></p>
        </section>
        <PetunjukParagraph>
          {
            "Di antara huruf A dengan B, B dengan C terdapat Angka Berwarna Merah (ABM),  kemudian di bawahnya terdapat 3 (tiga) tanda yaitu LEBIH KECIL (<), SAMA DENGAN (=) dan LEBIH BESAR (>)."
          }
        </PetunjukParagraph>

        <Footer condition next={nextHandler} previous={previousHandler} />
      </div>

      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 3 ? "flex" : "none" }}
      >
        <br />
        <SoalImage src="/images/tes/cact/cact-contoh.png" />

        <section className={styles.grid}>
          <p>A</p>
          <p></p>
          <p>B</p>
          <p></p>
          <p>C</p>

          <img src="/images/tes/cact/cact6.png" alt="cact" />
          <font color="#FB0606">
            <b>14</b>
          </font>
          <img src="/images/tes/cact/cact8.png" alt="cact" />
          <font color="#FB0606">
            <b>13</b>
          </font>
          <img src="/images/tes/cact/cact3.png" alt="cact" />

          <p></p>
          <section style={{ display: "flex", gap: "7px" }}>
            <Pilihan
              label="<"
              onClick={() => setAnswerOne("<")}
              answer={answerOne}
            />
            <Pilihan
              label="="
              onClick={() => setAnswerOne("=")}
              answer={answerOne}
            />
            <Pilihan
              label=">"
              onClick={() => setAnswerOne(">")}
              answer={answerOne}
            />
          </section>
          <p></p>
          <section style={{ display: "flex", gap: "7px" }}>
            <Pilihan
              label="<"
              onClick={() => setAnswerTwo("<")}
              answer={answerTwo}
            />
            <Pilihan
              label="="
              onClick={() => setAnswerTwo("=")}
              answer={answerTwo}
            />
            <Pilihan
              label=">"
              onClick={() => setAnswerTwo(">")}
              answer={answerTwo}
            />
          </section>
          <p></p>
        </section>
        <PetunjukParagraph>
          {
            "Tugas Anda adalah sebagai berikut: <br><strong>Mengidentifikasi nilai angka</strong> dari simbol-simbol yang ada, yaitu simbol A, B dan C sesuai ketentuan pada pasangan simbol-simbol angka di atas. <br><br><strong>Jumlahkan kedua nilai</strong> angka itu yaitu A dengan B atau B dengan C. Hasil A dengan B atau B dengan C disebut Angka Penjumlahan (AP). <br><strong>Bandingkan</strong> ABM (angka berwarna merah) dan AP (angka penjumlahan)."
          }
        </PetunjukParagraph>

        <Footer condition next={nextHandler} previous={previousHandler} />
      </div>

      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 4 ? "flex" : "none" }}
      >
        <br />
        <SoalImage src="/images/tes/cact/cact-contoh.png" />

        <section className={styles.grid}>
          <p>A</p>
          <p></p>
          <p>B</p>
          <p></p>
          <p>C</p>

          <img src="/images/tes/cact/cact6.png" alt="cact" />
          <font color="#FB0606">
            <b>14</b>
          </font>
          <img src="/images/tes/cact/cact8.png" alt="cact" />
          <font color="#FB0606">
            <b>13</b>
          </font>
          <img src="/images/tes/cact/cact3.png" alt="cact" />

          <p></p>
          <section style={{ display: "flex", gap: "7px" }}>
            <Pilihan
              label="<"
              onClick={() => setAnswerOne("<")}
              answer={answerOne}
            />
            <Pilihan
              label="="
              onClick={() => setAnswerOne("=")}
              answer={answerOne}
            />
            <Pilihan
              label=">"
              onClick={() => setAnswerOne(">")}
              answer={answerOne}
            />
          </section>
          <p></p>
          <section style={{ display: "flex", gap: "7px" }}>
            <Pilihan
              label="<"
              onClick={() => setAnswerTwo("<")}
              answer={answerTwo}
            />
            <Pilihan
              label="="
              onClick={() => setAnswerTwo("=")}
              answer={answerTwo}
            />
            <Pilihan
              label=">"
              onClick={() => setAnswerTwo(">")}
              answer={answerTwo}
            />
          </section>
          <p></p>
        </section>
        <PetunjukParagraph>
          {
            "<br>Jika ABM lebih kecil dari AP maka pilihlah tanda <. <br>Jika ABM sama dengan AP maka pilihlah tanda = <br>Jika ABM lebih besar dari AP maka pilihlah tanda >"
          }
        </PetunjukParagraph>

        <Footer condition next={nextHandler} previous={previousHandler} />
      </div>

      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 5 ? "flex" : "none" }}
      >
        <br />
        <SoalImage src="/images/tes/cact/cact-contoh.png" />

        <section className={styles.grid}>
          <p>A</p>
          <p></p>
          <p>B</p>
          <p></p>
          <p>C</p>

          <img src="/images/tes/cact/cact6.png" alt="cact" />
          <font color="#FB0606">
            <b>14</b>
          </font>
          <img src="/images/tes/cact/cact8.png" alt="cact" />
          <font color="#FB0606">
            <b>13</b>
          </font>
          <img src="/images/tes/cact/cact3.png" alt="cact" />

          <p></p>
          <section style={{ display: "flex", gap: "7px" }}>
            <Pilihan label="<" onClick={() => {}} answer="" />
            <Pilihan label="=" onClick={() => {}} answer="" />
            <Pilihan label=">" onClick={() => {}} answer=">" />
          </section>
          <p></p>
          <section style={{ display: "flex", gap: "7px" }}>
            <Pilihan label="<" onClick={() => {}} answer="" />
            <Pilihan label="=" onClick={() => {}} answer="" />
            <Pilihan label=">" onClick={() => {}} answer="" />
          </section>
          <p></p>
        </section>
        <PetunjukParagraph>
          {
            "<br>Contoh pertama A dengan B. <br>Simbol A bernilai 1 dan simbol B bernilai 6, dijumlahkan 7. <br>Maka nilai AP=7 dan nilai ABM=14. Karena ABM (angka berwarna merah) lebih besar dari AP (angka penjumlahan) sehingga: Jawaban yang benar adalah memilih tanda ></br>"
          }
        </PetunjukParagraph>

        <Footer condition next={nextHandler} previous={previousHandler} />
      </div>

      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 6 ? "flex" : "none" }}
      >
        <br />
        <SoalImage src="/images/tes/cact/cact-contoh.png" />

        <section className={styles.grid}>
          <p>A</p>
          <p></p>
          <p>B</p>
          <p></p>
          <p>C</p>

          <img src="/images/tes/cact/cact6.png" alt="cact" />
          <font color="#FB0606">
            <b>14</b>
          </font>
          <img src="/images/tes/cact/cact8.png" alt="cact" />
          <font color="#FB0606">
            <b>13</b>
          </font>
          <img src="/images/tes/cact/cact3.png" alt="cact" />

          <p></p>
          <section style={{ display: "flex", gap: "7px" }}>
            <Pilihan label="<" onClick={() => {}} answer="" />
            <Pilihan label="=" onClick={() => {}} answer="" />
            <Pilihan label=">" onClick={() => {}} answer=">" />
          </section>
          <p></p>
          <section style={{ display: "flex", gap: "7px" }}>
            <Pilihan label="<" onClick={() => {}} answer="" />
            <Pilihan label="=" onClick={() => {}} answer="=" />
            <Pilihan label=">" onClick={() => {}} answer="" />
          </section>
          <p></p>
        </section>
        <PetunjukParagraph>
          {
            "<br>Contoh kedua B dengan C. <br>Simbol B bernilai 6 dan simbol C bernilai 7, dijumlahkan 13. Maka nilai AP=13 dan nilai ABM=13. Karena ABM (angka berwarna merah) sama dengan AP (angka penjumlahan) sehingga: Jawaban yang benar adalah memilih tanda ="
          }
        </PetunjukParagraph>

        <Footer condition next={nextHandler} previous={previousHandler} />
      </div>

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={7}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
