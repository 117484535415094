import React from 'react';

import Collapsible from 'react-collapsible';
import NavMenuLink from './NavMenuLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';

const Trigger = ({ title, icon, url, isActive }) => {
  return (
    <div
      className={`text-start ps-5 ps-md-4 py-3 custom-nav-link ${isActive ? 'active' : 'inactive'}`}
    >
      <span className="icon-wrapper">
        <FontAwesomeIcon size="lg" icon={icon} />
      </span>
      <span className="ms-2">{title}</span>
    </div>
  );
};

const NavMenuGroup = ({ open, title, url, icon, menuGroups }) => {
  const location = useLocation();
  const isActive = location.pathname.includes(url);

  return (
    <Collapsible
      open={isActive}
      className="my-1"
      trigger={<Trigger open={open} title={title} url={url} icon={icon} isActive={isActive} />}
    >
      <div className="d-flex flex-column">
        {menuGroups.map((mg, idx) => {
          return <NavMenuLink key={idx} title={mg.title} url={mg.url} level={2} />;
        })}
      </div>
    </Collapsible>
  );
};

export default NavMenuGroup;
