import React, { useState, useEffect, useRef } from 'react';
import { useFaceDetection } from 'react-use-face-detection';
import FaceDetection from '@mediapipe/face_detection';
import { Camera } from '@mediapipe/camera_utils';
import styles from './Layout.module.css';
import logo from '../../../assets/psikotes/login/form_logo.png';
import { useHistory } from 'react-router';
import Webcam from 'react-webcam';
import Modal from '../../Modal';
import Rodal from 'rodal';
// import { API } from '../../../services/API';

const Layout = ({
  children,
  proctoring,
  pesertaId,
  camPeserta,
  currentTesId,
}) => {
  const history = useHistory();

  // if (proctoring && navigator.onLine === false) {
  //   history.push('/');
  // }

  const [timeUserGone, setTimeUserGone] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalTrigger, setModalTrigger] = useState(false);
  const webcamRef = useRef(null);

  const content =
    'Maaf wajah anda tidak terdeteksi, pastikan pencahayaan cukup dan wajah berada di posisi tengah kamera';
  const contentTrigger =
    'Tes ini mewajibkan peserta mengaktifkan kameranya. Sistem sedang mendeteksi wajah Anda, silakan untuk mengaktifkan kamera Anda dan pastikan pencahayaan cukup';
  const [photos, setPhotos] = useState([]);
  const [cam, setCam] = useState(true);

  const closeModal = () => {
    setModal(false);
    setTimeUserGone(0);
  };

  const { detected, handleFaceDetection, isLoading, onResults } =
    useFaceDetection({
      faceDetectionOptions: {
        model: 'short',
      },
      faceDetection: new FaceDetection.FaceDetection({
        locateFile: (file) =>
          `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
      }),
      camera: ({ mediaSrc, onFrame }) =>
        new Camera(mediaSrc, {
          onFrame,
          width: 200,
          height: 300,
        }),
    });

  const videoConstraints = {
    width: 200,
    height: 300,
    facingMode: 'user',
  };

  useEffect(async () => {
    // navigator.mediaDevices
    //   .getUserMedia({ video: { width: 300 } })
    //   .then((stream) => {})
    //   .catch((err) => {
    //     setCam(false);
    //   });

    if (proctoring && !cam) {
      window.location.href = `${process.env.REACT_APP_URL}/psikotes`;
    }
  }, [[], modal]);

  const detectKey = (e) => {
    if (
      e.key === 'Meta' ||
      e.key === 'Alt' ||
      e.key === 'Control' ||
      e.key === 'PrintScreen'
    ) {
      navigator.clipboard.writeText(
        'Jangan mencoba melakukan screenshot atau copy paste soal!'
      );
      setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_URL}/psikotes`;
      }, 1000);
    }
  };

  useEffect(() => {
    const photosLS = JSON.parse(localStorage.getItem('photos'));
    if (photosLS) {
      photosLS.forEach((f) => setPhotos((data) => [...data, photosLS[0]]));
    }

    document.addEventListener('keyup', detectKey, true);
  }, []);

  const addPhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPhotos((data) => [...data, imageSrc]);
  };

  useEffect(() => {
    if (
      (proctoring && camPeserta === 'Mengikuti project') ||
      (proctoring && camPeserta === 'Ya')
    ) {
      setModalTrigger(true);
    }
  }, [proctoring]);

  useEffect(() => {
    if (proctoring) {
      // API.get(`/jawabans/${pesertaId}`).then((res) => {
      //   if (res.data.peserta.foto?.length) {
      //     setPhotos(res.data.peserta.foto);
      //   }
      // });

      const capture = setInterval(() => {
        addPhoto();
        localStorage.setItem('photos', JSON.stringify(photos));
      }, 600000 /* 10 menit */);

      return () => clearInterval(capture);
    }
  }, [photos, proctoring]);

  useEffect(() => {
    let detectionTimeout = setTimeout(() => {
      if (webcamRef.current && webcamRef.current.video) {
        handleFaceDetection(webcamRef.current.video);
      }
    }, 5000);

    return () => clearTimeout(detectionTimeout);
  }, [isLoading, handleFaceDetection, onResults]);

  useEffect(() => {
    // console.log({ proctoring, detected, camPeserta });
    // Versi aman
    // if (!proctoring && camPeserta === 'Mengikuti project') {
    //   setModalTrigger(true);
    // }

    if (detected) {
      setModalTrigger(false);
    }

    if (proctoring) {
      // versi aman
      // setModalTrigger(false);
      if (timeUserGone == 25) {
        setModal(true);
      } else {
        if (modal !== true) {
          let timer1 = setTimeout(() => {
            detected ? setTimeUserGone(0) : setTimeUserGone(timeUserGone + 1);
          }, 1000);
          return () => clearTimeout(timer1);
        }
      }
    }
  }, [timeUserGone, detected, camPeserta]);

  return (
    <div className={styles.root}>
      {/* <p>{`Face Detected: ${detected ? 'Yes' : 'No'}`}</p> */}
      <header className={styles.layoutHeader}>
        {modalTrigger && (
          <Rodal
            visible={modalTrigger}
            animation="zoom"
            closeMaskOnClick={false}
            showCloseButton={false}
            height={200}
            onClose={() => {}}
          >
            <p className={styles.contentModal}>{contentTrigger}</p>
          </Rodal>
        )}

        {modal && (
          <Modal
            isVisible={modal}
            func={[setTimeUserGone, setModal]}
            content={content}
          />
        )}

        <img
          src={logo}
          alt="talenta"
          className={styles.logo}
          onClick={() => history.push('/psikotes')}
        />
      </header>
      {proctoring && <p>{timeUserGone}</p>}
      <div
        className={currentTesId == 117 ? styles.childrenVak : styles.children}
      >
        {children}
      </div>
      {proctoring && (
        <Webcam
          className={styles.cam}
          audio={false}
          height={100}
          ref={webcamRef}
          forceScreenshotSourceSize
          screenshotFormat="image/jpeg"
          width={200}
          videoConstraints={videoConstraints}
          onUserMediaError={() => setCam(false)}
        />
      )}
    </div>
  );
};

export default Layout;
