import React from 'react';

// ** Components
import * as Cfit3b from './components/cfit3b';
import * as Disc from './components/disc';
import * as Holland from './components/holland';
import * as Kostick from './components/kostick';
import * as Kraepelin from './components/kraepelin';
import * as PersonalInfo from './components/personal-info';
import * as SelfAssessment from './components/self-assessment';
import * as Tiki from './components/tiki';
import * as IST from './components/ist';

const getHeader = {
  standar: {
    cfit3b: Cfit3b.DefaultHeaderFirst,
    disc: Disc.DefaultHeaderFirst,
    holland: Holland.DefaultHeaderFirst,
    kostick: Kostick.DefaultHeaderFirst,
    kraepelin: Kraepelin.DefaultHeaderFirst,
    personal_info: PersonalInfo.DefaultHeaderFirst,
    self_assessment: SelfAssessment.DefaultHeaderFirst,
    tiki: Tiki.DefaultHeaderFirst,
  },
  taf: {
    cfit3b: Cfit3b.DefaultHeaderFirst,
    disc: Disc.DefaultHeaderFirst,
    holland: Holland.DefaultHeaderFirst,
    kostick: Kostick.DefaultHeaderFirst,
    kraepelin: Kraepelin.TafHeaderFirst,
    personal_info: PersonalInfo.DefaultHeaderFirst,
    self_assessment: SelfAssessment.DefaultHeaderFirst,
    tiki: Tiki.TafHeaderFirst,
  },
  egatek: {
    cfit3b: Cfit3b.DefaultHeaderFirst,
    disc: Disc.DefaultHeaderFirst,
    holland: Holland.DefaultHeaderFirst,
    kostick: Kostick.DefaultHeaderFirst,
    kraepelin: Kraepelin.EgatekHeaderFirst,
    personal_info: PersonalInfo.DefaultHeaderFirst,
    self_assessment: SelfAssessment.DefaultHeaderFirst,
    tiki: Tiki.DefaultHeaderFirst,
  },
  edu: {
    cfit3b: Cfit3b.EduHeaderFirst,
    disc: Disc.DefaultHeaderFirst,
    holland: Holland.DefaultHeaderFirst,
    kostick: Kostick.DefaultHeaderFirst,
    kraepelin: Kraepelin.EduHeaderFirst,
    personal_info: PersonalInfo.EduHeaderFirst,
    self_assessment: SelfAssessment.DefaultHeaderFirst,
    tiki: Tiki.DefaultHeaderFirst,
  },
  lppi: {
    cfit3b: Cfit3b.DefaultHeaderFirst,
    disc: Disc.DefaultHeaderFirst,
    holland: Holland.DefaultHeaderFirst,
    kostick: Kostick.DefaultHeaderFirst,
    kraepelin: Kraepelin.DefaultHeaderFirst,
    personal_info: PersonalInfo.LppiHeaderFirst,
    self_assessment: SelfAssessment.LppiHeaderFirst,
    tiki: Tiki.LppiHeaderFirst,
  },
  komatsu: {
    personal_info: PersonalInfo.DefaultHeaderFirst,
    ist: IST.KomatsuHeaderFirst,
    kraepelin: Kraepelin.DefaultHeaderFirst,
    disc: Disc.DefaultHeaderFirst,
  },
};

const HeaderFirst = ({ type = 'standar', format = [] }) => {
  return (
    <tr className="th-first">
      {format.map((f) => {
        const Header = getHeader[type][f];

        if (!Header) return <React.Fragment key={f} />;

        return <Header key={f} />;
      })}
    </tr>
  );
};

export default HeaderFirst;
