import { useState } from 'react';
import { connect } from 'react-redux';
import { pesertaLogin } from '../../../redux/Peserta/peserta-actions';
import { API } from '../../../services/API';
import styles from './DataDiri.module.css';
import InputGroup from './InputGroup';
import SelectGroup from './SelectGroup';
import cautionLogo from '../../../assets/psikotes/caution-logo.png';
import SoalContainer from '../Containers/SoalContainer';

const DataDiri = ({ pesertaLogin }) => {
  const [index, setIndex] = useState(0);
  const [isShowSelesaiModal, setIsShowSelesaiModal] = useState(false);

  //

  const [nama, setNama] = useState('');
  const [pendidikan, setPendidikan] = useState('');
  const [tanggalLahir, setTanggalLahir] = useState('');
  const [telepon, setTelepon] = useState('');
  const [gender, setGender] = useState('');
  const [posisi, setPosisi] = useState('');
  const [perusahaan, setPerusahaan] = useState('');
  const [namaPanggilan, setNamaPanggilan] = useState('');
  const [tempatLahir, setTempatLahir] = useState('');
  const [nik, setNik] = useState('');
  const [email, setEmail] = useState('');
  const [jurusan, setJurusan] = useState('');
  const [penempatan, setPenempatan] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setIndex(1);
  };

  const submitToAPI = async () => {
    let data = {
      nama,
      pendidikan,
      tglLahir: tanggalLahir,
      telp: telepon,
      gender,
      posisi,
      namaPanggilan,
      tmptLahir: tempatLahir,
      nik,
      email,
      jurusan,
      penempatan,
    };

    data = {
      ...data,
      dataDiri: { peserta: { perusahaan } },
    };

    try {
      const psikotesLS = JSON.parse(localStorage.getItem('psikotesTalenta'));
      await API.patch(`/peserta/${psikotesLS.peserta.id}`, data);

      setIsShowSelesaiModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  if (index === 1) {
    return (
      <div className={styles.popupContainer}>
        <SoalContainer
          isShowSelesaiModal={isShowSelesaiModal}
          setIsShowSelesaiModal={setIsShowSelesaiModal}
        >
          <div className={styles.popup}>
            <img src={cautionLogo} alt="warning" width="60" height="60" />
            <p>
              Persiapkan diri Anda. Perhatikan petunjuk yang disediakan pada
              setiap soal yang akan Anda kerjakan. Kerjakan dengan cepat dan
              teliti.
            </p>
            <button onClick={submitToAPI}>Lanjut</button>
          </div>
        </SoalContainer>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h1>DATA PESERTA TES</h1>
      <form onSubmit={handleSubmit}>
        <div className={styles.left}>
          <InputGroup
            label="Nama Peserta"
            id="nama"
            type="text"
            state={nama}
            setState={setNama}
            maxLength={60}
          />

          <SelectGroup
            label="Jenis Kelamin"
            state={gender}
            setState={setGender}
          >
            <option value=""></option>
            <option value="L">Laki-laki</option>
            <option value="P">Perempuan</option>
          </SelectGroup>

          <InputGroup
            label="Tanggal Lahir"
            id="tanggal-lahir"
            type="date"
            state={tanggalLahir}
            setState={setTanggalLahir}
          />
          <InputGroup
            label="No. Telepon/HP"
            id="telepon"
            type="text"
            state={telepon}
            setState={setTelepon}
            maxLength={16}
          />

          <SelectGroup
            label="Pendidikan Terakhir"
            state={pendidikan}
            setState={setPendidikan}
          >
            <option value=""></option>
            <option value="SLTP atau sederajat">SLTP atau sederajat</option>
            <option value="SLTA atau sederajat">SLTA atau sederajat</option>
            <option value="D3">D3</option>
            <option value="S1 atau sederajat">S1 atau sederajat</option>
            <option value="S2 atau diatasnya">S2 atau diatasnya</option>
          </SelectGroup>

          <InputGroup
            label="Posisi yang dilamar"
            type="text"
            id="posisi"
            state={posisi}
            setState={setPosisi}
          />
          <InputGroup
            label="Nama Perusahaan"
            type="text"
            id="perusahaan"
            state={perusahaan}
            setState={setPerusahaan}
            maxLength={60}
          />
        </div>

        <div className={styles.right}>
          <InputGroup
            label="Nama Panggilan"
            type="text"
            id="nama-panggilan"
            state={namaPanggilan}
            setState={setNamaPanggilan}
            maxLength={30}
          />
          <InputGroup
            label="Tempat Lahir"
            type="text"
            id="tempat-lahir"
            state={tempatLahir}
            setState={setTempatLahir}
            maxLength={40}
          />
          <InputGroup
            type="text"
            label="NIK"
            id="nik"
            state={nik}
            setState={setNik}
            maxLength={25}
          />
          <InputGroup
            type="email"
            label="Email"
            id="email"
            state={email}
            setState={setEmail}
            maxLength={50}
          />
          <InputGroup
            type="text"
            label="Nama Jurusan Pendidikan Terakhir"
            id="jurusan"
            state={jurusan}
            setState={setJurusan}
            maxLength={40}
          />
          <InputGroup
            label="Kota Penempatan"
            id="penempatan"
            type="text"
            state={penempatan}
            setState={setPenempatan}
            maxLength={40}
          />

          <button type="submit">Kirim</button>
        </div>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    pesertaLogin: (
      peserta,
      currentTes,
      currentSoal,
      currentPetunjuk,
      jawabans
    ) =>
      dispatch(
        pesertaLogin(
          peserta,
          currentTes,
          currentSoal,
          currentPetunjuk,
          jawabans
        )
      ),
  };
};

export default connect(null, mapDispatchToProps)(DataDiri);
