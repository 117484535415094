import { useState } from "react";
import PetunjukLast from "../../Components/PetunjukLast";
import PetunjukContainer from "../../Containers/PetunjukContainer";
import PetunjukZero from "../../Containers/PetunjukZero";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>
          Pada tes ini tidak terdapat jawaban benar atau salah. Silahkan pilih
          pernyataan yang sesuai dengan diri sendiri.
        </p>

        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={1}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
