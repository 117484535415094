import { useState } from 'react';
import PetunjukLast from '../../../Components/PetunjukLast';
import PetunjukContainer from '../../../Containers/PetunjukContainer';
import styles from './Petunjuk.module.css';
import Footer from '../../../Components/PetunjukMiddle/Footer';

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <div
        className={styles.petunjukZero}
        style={{ display: index === 0 ? 'flex' : 'none' }}
      >
        <center>
          <h2>PT ASURANSI SOSIAL KARYAWAN</h2>
        </center>
        <br />
        <br />
        <span style={{ textDecoration: 'underline' }}>
          <h4>Situasi Anda</h4>
        </span>
        <span>
          <b>
            Anda adalah Dani Kesuma, Direktur Utama, PT Asuransi Sosial Karyawan
            (PT ASOKA)
          </b>{' '}
          yang baru ditunjuk dan dilantik untuk menggantikan Hari Widjanarko –
          Pejabat Lama yang mendadak sakit dan harus dirawat intensif. Kondisi
          kesehatan Hari Widjanarko yang kian memburuk membuat pengelolaan
          perusahaan menjadi terbengkalai. Sementara pejabat Direktur
          Restrukturisasi Bisnis tengah kosong karena pejabat sebelumnya – Edi
          Kahuripan – baru saja pensiun dan menunggu pejabat baru dari Kantor
          Pusat.
          <br />
          <br />
          Hari ini adalah hari <b>Minggu, 4 Juli 20xx, pukul 10.00,</b> saat ini
          Anda berada di kantor untuk menyelesaikan pekerjaan karena tiga jam
          lagi Anda harus berangkat ke luar negeri, untuk mengikuti pelatihan
          Good Cooperate Governance tingkat internasional di Jepang selama 2
          minggu penuh. Anda adalah salah satu perwakilan resmi, utusan dari
          pemerintah Indonesia, <b>juga harus datang pada pelatihan</b> itu.
          Mengingat padatnya rencana kegiatan saat itu, Anda tidak dapat
          menghubungi ataupun dihubungi oleh rekan Anda di kantor. Anda baru
          akan kembali berada di kantor pada hari Senin tanggal 19 Juli 20xx.
          <br />
          <br />
          Dihadapan Anda, terdapat tumpukan surat/dokumen/nota yang harus Anda
          tuntaskan sebellum Anda berangkat. Tugas Anda ialah mengambil tindakan
          atau merespon setiap surat/dokumen/memo dan <b>menuliskannya</b> pada
          lembar kerja. Anda hanya memiliki waktu efektif <b>60 menit</b> untuk
          menyelesaikan tugas tersebut. Sulit bagi Anda untuk berkomunikasi
          dengan bawahan dan rekan kerja Anda menfontgingat hari ini adalah hari
          libur dan waktu yang Anda miliki sangat sempit, sehingga perlu Anda
          gunakan secara maksimal untuk berkonsentrasi pada tugas Anda hari ini.
          Saudara dibolehkan untuk mendelegasikan tugas, karena{' '}
          <b>tidak ada Pejabat Sementara (PJS).</b>
          <br />
          <br />
          Anda diharapkan dapat menulis{' '}
          <b>
            tindakan spesifik yang Anda ambil pada setiap surat/dokumen/memo
            yang tersedia mengingat Anda tidak memiliki kesempatan untuk
            mengkomunikasikan setiap penugasan secara lisan.
          </b>{' '}
          Tulislah kepada siapa memo itu ditunjukkan, sehingga staff Anda mudah
          menindaklanjutinya dan pekerjaan di kantor Anda dapat tetap berjalan
          lancar sementara Anda tidak berada di tempat.
          <br />
          <br />
          Untuk membantu Anda dalam memahami kondisi perusahaan, telah
          disediakan sejumlah dokumen yang dapat Anda pelajari, meliputi:
        </span>
        <br />
        <ol type="a">
          <li>Profil PT ASOKA</li>
          <li>Struktur Organisasi</li>
          <li>Kalender Kerja</li>
        </ol>
        <p>Selamat bekerja!</p>
        <br />
        <br />
        <br />
        <span style={{ textDecoration: 'underline' }}>
          <h4>Profil PT Asuransi Sosial Karyawan (PT ASOKA)</h4>
        </span>
        <br />

        <p>
          <b>Sejarah</b>
        </p>
        <span>
          Penyelenggara program asuransi sosial merupakan salah satu tanggung
          jawab dari kewajiban Negara untuk memberikan perlindungan sosial
          ekonomi kepada masyarakat. Sesuai dengan kondisi kemampuan keuangan
          Negara, Indonesia seperti halnya berbagai Negara berkembang lainnya,
          mengembangkan program asuransi sosial berdasarkan funded social
          security, yaitu asuransi sosial yang didanai oleh peserta dan masih
          terbatas pada masyarakat pekerja di sector formal.
          <br />
          <br />
          Didirikan pada tahun 1980 sebagai PT Asuransi Jiwa Sakti, pada dekade
          pertama kami dengan cermat mengembangkan fondasi yang kuat demi
          kesuksesan berikutnya melalui riset di pasar local. Pada tahu 1989 PT
          Asuransi Jiwa Sakti berubah nama menjadi PT Asuransi Sosial Karyawan
          (PT ASOKA). Perubahan ini juga dilandasi oleh dikeluarkannya Peraturan
          Pemerintah tentang pelaksanaan programasuransi sosial tenaga kerja,
          yang mewajibkan setiap pemberi kerja/pengusaha swasta dan BUMN untuk
          mengikuti program asuransi sosial ini. Terbit pula PP tentang
          pembentukan wadah penyelenggara asuransi sosial yaitu PT Asuransi
          Sosial Karyawan.
          <br />
          <br />
          Program Asuransi Sosial Karyawan memberikan perlilndungan dasar untuk
          memenuhi kebutuhan minimal bagi karyawan dan keluarganya, dengan
          memberikan kepastian berlangsungnya arus penerimaan penghasilan
          keluarga sebagai pengganti sebagian atau seluruhnya penghasilan yang
          hilang, akibat resiko sosial. Manfaat perlindungan tersebut dapat
          memberikan rasa aman kepada karyawan sehingga lebih berkonsentrasi
          dalam meningkatkan motivasu maupun produktivitas kerja.
          <br />
          <br />
          PT ASOKA memberikan perlindungan 4 program, yang mencangkup Asuransi
          Jiwa, Perlindungan Kecelakaan Kerja, Dana Pensiun, dan Santunan
          Kesehatan dan Rawat Inap bagi seluruh karyawan dan keluarganya. Dengan
          penyelenggaraan yang makin maju, program asuransi sosial tidak hanya
          bermanfaat kepada karyawan dan pengusaha tetapi juga berperan aktif
          dalam meningkatkan pertumbuhan perekonomian bagi kesejahteraan
          masyarakat dan perkembangan masa depan bangsa. PT ASOKA sudah
          menerapkan system manajemen mutu internasional ISO 9001 versi 2000,
          melayani masyarakat di lebih dari 70 kota di seluruh Indonesia.
          <br />
          <br />
        </span>

        <p>
          <b>Visi</b>
        </p>
        <span>
          Menjadi penyelenggara asuransi sosial karyawan yang paling dibutuhkan
          dan terpercaya di Indonesia. Kami akan menjadi pemimpin di setiap
          jalur usaha dengan menawarkan beragam produk & layanan yang fleksibel,
          inovatif dan barnilai tinggi, malalui berbagai jalur distribusi yang
          terbaik di Indonesia.
        </span>
        <br />

        <p>
          <b>Misi</b>
        </p>
        <span>
          Untuk meningkatkan kehidupan yang lebih baik bagi masyarakat Indonesia
          dengan memenuhi kebutuhan financial mereka yang senantiasa berubah
          serta memehami harapan para pemegang polis dan mitra perusahaan.
        </span>
        <br />

        <div className={styles.buttonWrapper}>
          <button onClick={nextHandler}>Tutup</button>
        </div>
      </div>

      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 1 ? 'flex' : 'none' }}
      >
        <p>
          <b>Produk dan Layanan</b>
        </p>
        <span>
          Program Asuransi Sosial merupakan perlindungan yang bersifat dasar
          bagi karyawan yang bertujuan untuk menjamin adanya keamanan dan
          kepastian terhadap resiko sosial ekonomi, dan merupakan sarana
          penjamin arus penerimaan penghasilan bagi karyawan dan keluarganya
          akibat dari terjadinya resiko-resiko sosial dengan pembiayaan yang
          terjangkau oleh pengusaha dan karyawan. Resiko sosial ekonomi yang
          ditanggulangi oleh program tersebut terbatas saat terjadi peristiwa
          kecelakaan, sakit, hamil, bersalin, cacat, hari tua dan meninggal
          dunia, yang mengakibatkan berkurangnya atau terputusnya penghasilan
          karyawan dan/atau membutuhkan perawatan medis.
          <br />
          <br />
        </span>
        <ol>
          <li>
            Asuransi Jiwa (ASWA)
            <br />
            Asuransi Jiwa diperuntukkan bagi ahli waris karyawan yang menjadi
            peserta yang meninggal bukan karena kecelakaan kerja. Asuransi Jiwan
            diperlukan sebagai upaya meringankan beban keluarga baik dalam
            bentuk biaya pemakaman maupun santunan barupa uang. Pengusaha wajib
            menanggung iuran Program ASWA sebesar 0,3 % dengan jaminan kematian
            yang diberikan adalah Rp 7,5 Juta terdiri dari Rp 6 Juta santunan
            kematian dan Rp 1,5 Juta uang pemakaman dan santunan berkala.
          </li>
          <li>
            Perlindungan Kecelakaan Kerja (PKK)
            <br />
            Kecelakaan kerja termasuk penyakit akibat kerja merupakan resiko
            yang harus dihadapi oleh karyawan dalam melakukan pekerjaannya.
            Untuk menanggulangi hilangnya sebagian atau seluruh penghasilan yang
            diakibatkan oleh adanya resiko-resiko sosial seperti kematian atau
            cacat karena kecelakaan kerja baik fisik maupun mental, maka
            diperlukan adanya asuransi kecelakaan kerja. Kesehatan dan
            keselamatan karyawan merupakan tanggungjawab pengusaha sehingga
            pengusaha memiliki kewajiban untuk membayar iuran asuransi
            kecelakaan kerja yang berkisar antara0,24 % s/d 1,74 % sesuai
            kelompok jenis usaha. Program PKK memberikan kompensasi dan
            rehabilitasi bagi tenaga kerja yang mengalami kecelakaan pada saat
            dimulai berangkat kerja sampai tiba kembali dirumah atau menderita
            penyakit akibat hubungan kerja.
          </li>
          <li>
            Santunann Kesehatan dan RAwat Inap (SKRI)
            <br />
            Pemeliharaan kesehatan adalah hak karyawan. Santunan Kesehatan dan
            Rawat Inap adalah salah satu program yang membantu karyawan dan
            keluarganya mengatasi masalah kesehatan. Mulai dari pencegahan,
            pelayanan di klinik sesehatan, rumah sakit, kebutuhan alat bantu
            peningkatan pengetahuan, dan pengobatan, secara efektif dan efisien.
            Setiap karyawan yang telah mengikuti program SKRI akan diberikan KPK
            (Kartu Pemeliharaan Kesehatan) sevagai bukti diri untuk mendapatkan
            pelayanan kesehatan. Manfaat SKRI bagi perusahaan yakni perusahaan
            dapat memiliki karyawan yang sehat, dapat konsentrasi dalam bekerja
            sehingga lebih produktif.
          </li>
          <li>
            Dana Pensiun (DAPEN)
            <br />
            Program Dana Pensiun ditunjukkan sebagai pengganti terputusnya
            penghasilan karyawan karena meninggal, cacat, atau hari tua dan
            diselenggarakan dengan system tabungan hari tua. Program DAPEN
            memberikan kepastian penerimaan penghasilan yang dibayarkan pada
            saat karyawan mencapai usia 55 tahun atau telah memenuhi persyaratan
            tertentu. Iuran Program DAPEN ditanggung Perusahaan = 3,7 % dan
            ditanggung Karyawan = 2 %. Kemanfaatan DAPEN adalah sebesar
            akumulasi iuran ditambah hasil pengembangamnnya.
          </li>
        </ol>

        <br />
        <br />
        <span style={{ textDecoration: 'underline' }}>
          <h4>Struktur Organisasi PT ASOKA</h4>
        </span>
        <br />
        <center>
          <img
            src={`${process.env.REACT_APP_SERVER_URL}/in-basket/pt-asuransi-sosial-karyawan/ib_pt_asuransi_sosial_karyawan1.png`}
            style={{ width: '80%' }}
          />
        </center>
        <br />
        <center>
          <img
            src={`${process.env.REACT_APP_SERVER_URL}/in-basket/pt-asuransi-sosial-karyawan/ib_pt_asuransi_sosial_karyawan2.png`}
            style={{ width: '80%' }}
          />
        </center>
        <br />

        <br />
        <br />
        <span style={{ textDecoration: 'underline' }}>
          <h4>Kalender Kegiatan</h4>
        </span>
        <br />
        <font>JUNI 20XX</font>
        <div style={{ overflowX: 'scroll' }} className="w-75">
          <table className="table table-responsive">
            <thead>
              <tr>
                <th>MINGGU</th>
                <th>SENIN</th>
                <th>SELASA</th>
                <th>RABU</th>
                <th>KAMIS</th>
                <th>JUMAT</th>
                <th>SABTU</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
              </tr>
              <tr>
                <td>6</td>
                <td>7</td>
                <td>8</td>
                <td>9</td>
                <td>10</td>
                <td>11</td>
                <td>12</td>
              </tr>
              <tr>
                <td>13</td>
                <td>14</td>
                <td>15</td>
                <td>16</td>
                <td>17</td>
                <td>18</td>
                <td>19</td>
              </tr>
              <tr>
                <td>20</td>
                <td>21</td>
                <td>22</td>
                <td>23</td>
                <td>24</td>
                <td>25</td>
                <td>26</td>
              </tr>
              <tr>
                <td>27</td>
                <td>28</td>
                <td>29</td>
                <td>30</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <br />
        <br />
        <font>JULI 20XX</font>
        <div style={{ overflowX: 'auto' }} className="w-75">
          <table className="table table-responsive">
            <thead>
              <tr>
                <th>MINGGU</th>
                <th>SENIN</th>
                <th>SELASA</th>
                <th>RABU</th>
                <th>KAMIS</th>
                <th>JUMAT</th>
                <th>SABTU</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
              </tr>
              <tr>
                <td>4</td>
                <td>5</td>
                <td>6</td>
                <td>7</td>
                <td>8</td>
                <td>9</td>
                <td>10</td>
              </tr>
              <tr>
                <td>11</td>
                <td>12</td>
                <td>13</td>
                <td>14</td>
                <td>15</td>
                <td>16</td>
                <td>17</td>
              </tr>
              <tr>
                <td>18</td>
                <td>19</td>
                <td>20</td>
                <td>21</td>
                <td>22</td>
                <td>23</td>
                <td>24</td>
              </tr>
              <tr>
                <td>25</td>
                <td>26</td>
                <td>27</td>
                <td>28</td>
                <td>29</td>
                <td>30</td>
                <td>31</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <span>
          Keterangan Kalender JULI 20XX:
          <br />
          - Tanggal 4 Juli 20XX = HARI INI Tugas LN s/d tgl 17 JULI 20XX
          <br />- Tanggal 18 Juli 20XX = Kembali dari LN
        </span>

        <br />
        <br />
        <Footer previous={previousHandler} next={nextHandler} condition />
      </div>

      <div
        className={styles.petunjukMiddle}
        style={{ display: index === 2 ? 'flex' : 'none' }}
      >
        <p>
          Pada persoalan berikut, Anda akan menghadapi beberapa kasus yang
          terdiri dari memo/ dokumen kerja. Tugas anda adalah membuat respon
          atas memo/ dokumen tersebut dan membagi permasalahan-permasalahan
          tersebut ke dalam 3 kategori :
        </p>
        <ol>
          <b>
            <li>High Priority</li>
          </b>
          <b>
            <li>Moderate Priority</li>
          </b>
          <b>
            <li>Low Priority</li>
          </b>
        </ol>

        <p>
          Anda bebas untuk mengerjakan dokumen mana yang akan anda selesaikan
          terlebih dahulu. Yang terpenting berikan kategori pada setiap
          permasalahan dan tuliskan urutan permasalahan menurut masalah yang
          paling prioritas untuk diselesaikan.
        </p>

        <p>
          Tulislah respon kamu untuk setiap permasalahan tadi pada kolom
          jawaban, setelah yakin tekan <strong>Lanjut</strong> untuk mengisi
          jawabannya
        </p>

        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>

        <p>
          <font color="red">
            Jangan ditutup terlebih dulu petunjuk ini, baca dan pahami
            instruksi. Apabila petunjuk ini ditutup maka tes dimulai dan waktu
            berjalan.
          </font>
        </p>
        <Footer previous={previousHandler} next={nextHandler} condition />
      </div>

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
