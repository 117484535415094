import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useAsyncDebounce } from "react-table";
import styles from "./GlobalFilter.module.css";

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  // const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span className={styles.section}>
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="Cari"
        className={styles.inputSearch}
      />
      <div className={styles.iconSearch}>
        <FontAwesomeIcon icon={faSearch} color="#0963A7" size="2x" />
      </div>
    </span>
  );
};

export default GlobalFilter;
