import { useState } from "react";
import PetunjukLast from "../../../Components/PetunjukLast";
import PetunjukContainer from "../../../Containers/PetunjukContainer";
import PetunjukZero from "../../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  const arrPilihanOne = ["Meja", "Kursi", "Burung", "Lemari", "Tempat Tidur"];
  const arrPilihanTwo = ["Duduk", "Berbaring", "Berdiri", "Berjalan", "Berjongkok"];
  const arrPilihanThree = ["Gigi","Kumis","Bulu mata", "Alis", "Rambut"];
  const arrPilihanFour = ["Panjang", "Berat", "Badan", "Tinggi", "Besar"];

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>											
        <p>Setiap soal terdiri dari 5 kata.</p>
        <br />
        <p>Diantara kelima kata tersebut terdapat satu kata yang tidak termasuk atau tidak menunjukkan suatu kesamaan dengan empat kata lainnya</p>
        <br />
        <p>
        Carilah kata itu dan apabila Anda sudah menemukannya, pilihlah kata tersebut.
        </p>
        <br />
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="Carilah kata yang tidak memiliki kesamaan dari kata lainnya:"
        arrPilihan={arrPilihanOne}
        kunci="Burung"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban Anda benar! <b>Burung</b> bukan perabot rumah, sedangkan jawaban yang lainnya adalah perabot rumah (meubel)."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="Carilah kata yang tidak memiliki kesamaan dari kata lainnya:"
        arrPilihan={arrPilihanTwo}
        kunci="Berjalan"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban Anda benar! <b>Berjalan</b> adalah kondisi orang dalam keadaan bergerak, sedangkan pilihan jawaban yang lainnya adalah kondisi orang berada dalam keadaan tidak bergerak."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={3}
        soal="Carilah kata yang tidak memiliki kesamaan dari kata lainnya:"
        arrPilihan={arrPilihanThree}
        kunci="Gigi"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban Anda benar!"
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={4}
        soal="Carilah kata yang tidak memiliki kesamaan dari kata lainnya:"
        arrPilihan={arrPilihanFour}
        kunci="Badan"
        next={nextHandler}
        previous={previousHandler}
        pesanBenar="Jawaban Anda benar!"
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={5}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
