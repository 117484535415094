import { useState } from "react";
import PetunjukLast from "../../Components/PetunjukLast";
import PetunjukContainer from "../../Containers/PetunjukContainer";
import PetunjukZero from "../../Containers/PetunjukZero";
import PetunjukMiddle from "./PetunjukMiddle";

const Petunjuk = ({ isModal, tutupModalHandler }) => {
  const [index, setIndex] = useState(0);

  const nextHandler = () => setIndex(index + 1);
  const previousHandler = () => setIndex(index - 1);

  return (
    <PetunjukContainer isModal={isModal} tutupModalHandler={tutupModalHandler}>
      <PetunjukZero selectedIndex={index}>
        <p>Terdapat beragam persoalan yang harus Anda jawab.</p>
        <br />
        <p>
          Tes memiliki batas waktu bekerja dengan cepat dan teliti. Perhatikan
          contoh soal sebelum Anda mengerjakan.
        </p>
        <br />
        <p style={{ color: "red" }}>
          Jangan ditutup terlebih dulu petunjuk ini, perhatikan contoh. Apabila
          petunjuk ini ditutup maka tes dimulai dan waktu berjalan.
        </p>
        <button onClick={nextHandler}>Lanjut</button>
      </PetunjukZero>

      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={1}
        soal="Menuai adalah lawan kata dari: 1. Mendapat 2. Bersorak 3. Melanjutkan 4. Berada 5. Menabur"
        kunci={"5"}
        next={nextHandler}
        previous={previousHandler}
        isModal={isModal}
        pesanBenar="Jawaban yang tepat adalah menabur (diisi dengan angka 5). Anda cukup mengisikan angka 5 seperti contoh."
      />
      <PetunjukMiddle
        selectedIndex={index}
        showWhenIndex={2}
        soal="Harga setiap celana renang adalah Rp. 23.000,-. Berapa harga empat celana renang tanpa diskon sama sekali?"
        kunci={"92000"}
        next={nextHandler}
        previous={previousHandler}
        isModal={isModal}
        pesanBenar="Perhatikan cara menjawab, cukup diisi angkanya saja. Tidak perlu mengisi Rp. 92.000,- atau 92.000. <strong>Perhatikan mengenai hal ini.</strong>"
      />

      <PetunjukLast
        isModal={isModal}
        previous={previousHandler}
        selectedIndex={index}
        showWhenIndex={3}
        tutupModalHandler={tutupModalHandler}
      />
    </PetunjukContainer>
  );
};

export default Petunjuk;
